import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { SvgIcon } from 'common/react/components/SvgIcon';

import { playersSelector } from 'store/modules/statspro/player-comparison/selectors';
import {
    ADD_PLAYER,
    REMOVE_PLAYER
} from 'store/modules/statspro/player-comparison/actions';

export const PlayerSelectionSearchResult = ({ result, query, isFixed }) => {
    const dispatch = useDispatch();

    const playerDetails = result.playerDetails
        ? result.playerDetails
        : result.playerName
        ? result.playerName
        : null;
    const photoURL = result.photoURL
        ? result.photoURL
        : result.playerDetails.photoURL;
    const jumperNumber = result?.jumperNumber
        ? result?.jumperNumber
        : result?.playerDetails?.jumperNumber;

    const teamAbbreviation = result.team.teamAbbr.toLowerCase();
    const fullName = `${playerDetails.givenName} ${playerDetails.surname}`;

    const currentPlayers = useSelector(playersSelector);
    const playerId = result.playerId;
    const isSelected =
        // eslint-disable-next-line no-constant-binary-expression
        Object.values(currentPlayers).indexOf(playerId) !== -1 ?? true;

    const highlight = (queryString, string) => {
        return {
            __html: string.replace(
                new RegExp(queryString, 'gi'),
                (str) => `<strong>${str}</strong>`
            )
        };
    };

    const handleClick = () => {
        // toggle add/player to the store
        if (isSelected) {
            dispatch(REMOVE_PLAYER.request({ id: playerId }));
        } else {
            dispatch(ADD_PLAYER.request({ id: playerId }));
        }
    };

    const [imgMissing, addPlaceholder] = useState(false);

    const onErrored = () => {
        //event.target.parentNode.classList.add('has-placeholder');
        addPlaceholder((current) => !current);
    };

    if (isFixed || (!isSelected && !isFixed)) {
        return (
            <li
                className={'search-result ' + (isSelected ? 'is-selected' : '')}
            >
                <button
                    onClick={() => handleClick()}
                    className="search-result__link"
                    role="button"
                    aria-pressed="false"
                    tabIndex={0}
                >
                    <div className="search-result__info-container">
                        <div
                            className={
                                'search-result__image' +
                                (imgMissing ? ' has-placeholder' : '')
                            }
                        >
                            <img
                                loading="lazy"
                                src={PULSE.app.common.image.migratePlayerPhotoURL(
                                    photoURL,
                                    0.1
                                )}
                                alt={`Headshot of ${fullName}`}
                                onError={(event) => onErrored(event)}
                            />
                        </div>
                        <div className="search-result__details">
                            <p
                                className="search-result__title"
                                dangerouslySetInnerHTML={highlight(
                                    query,
                                    fullName
                                )}
                            />
                            <div className="search-result__details-team">
                                <SvgIcon
                                    icon={`aflc-${teamAbbreviation}-flag`}
                                    className={`search-result__details-flag`}
                                />
                                <span>#{jumperNumber}</span>
                                <span>|</span>
                                <span>{result.team.teamName}</span>
                            </div>
                        </div>
                    </div>
                    {!isSelected ? (
                        <div className="search-result__add-button">
                            <SvgIcon
                                icon="close-hover"
                                className="search-result__add-icon"
                            />
                        </div>
                    ) : (
                        <div className="search-result__remove-button">
                            <SvgIcon
                                icon="accordion-close"
                                className="search-result__remove-icon"
                            />
                        </div>
                    )}
                </button>
            </li>
        );
    }
    return <></>;
};

PlayerSelectionSearchResult.propTypes = {
    result: PropTypes.object.isRequired,
    query: PropTypes.string,
    isFixed: PropTypes.bool
};
