import React from 'react';
import PropTypes from 'prop-types';

export const BodyCellWinRatio = ({ cell, cellProps }) => {
    const winRatio = cell?.value || '0';

    return (
        <td
            {...cellProps}
            className="stats-table__cell stats-table__cell--win-ratio"
        >
            <span className="career-and-season-stats__position-cell-label">
                {winRatio}
            </span>
        </td>
    );
};

BodyCellWinRatio.propTypes = {
    cell: PropTypes.object,
    cellProps: PropTypes.object
};
