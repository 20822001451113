import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { CardHeader } from './CardHeader';
import { Broadcasters } from './Broadcasters';
import { Empty } from './Empty';
import { TeamWatermarkBackground } from 'widgets/statspro/js/components/TeamWatermarkBackground';
import { useGetMatchEventsQuery } from 'common/store/modules/api/content-api';
import { navSelector } from 'common/store/modules/competition-nav/selectors';
import { BroadcasterSkeleton } from './BroadcasterSkeleton';

export const Card = ({ fixture, location, competitionId, seasonId }) => {
    const nav = useSelector(navSelector);
    const round = nav?.round.roundNumber;

    const homeTeamAbbr = PULSE.app.common.team.getTeamAbbr(
        fixture.home.team.providerId
    );
    const awayTeamAbbr = PULSE.app.common.team.getTeamAbbr(
        fixture.away.team.providerId
    );
    const status = PULSE.app.common.match.getMatchStatus(fixture.status);

    const broadcastersVisible =
        status === PULSE.app.common.CONSTANTS.MATCH_STATUS.TBC ||
        status === PULSE.app.common.CONSTANTS.MATCH_STATUS.CANCELLED ||
        status === PULSE.app.common.CONSTANTS.MATCH_STATUS.POSTPONED
            ? false
            : true;

    const { matchEvents, isEventsFetching } = useGetMatchEventsQuery(
        {
            competition: competitionId,
            compseason: seasonId,
            round: round,
            pageSize: 100
        },
        {
            selectFromResult: ({ data, isFetching }) => ({
                matchEvents:
                    data && data.content
                        ? data.content.filter(
                              (item) => item.contentReference.id === fixture.id
                          )
                        : [],
                isEventsFetching: isFetching
            }),
            skip: !competitionId || !seasonId || (!round && round !== 0)
        }
    );

    return (
        <>
            <div className="broadcast-guide__item">
                <div className="broadcast-guide__header">
                    <TeamWatermarkBackground
                        teamAbbreviation={homeTeamAbbr}
                        className="broadcast-guide__header-team-background"
                        side="left"
                    />

                    <TeamWatermarkBackground
                        teamAbbreviation={awayTeamAbbr}
                        className="broadcast-guide__header-team-background"
                        side="right"
                    />

                    <CardHeader
                        status={status}
                        fixture={fixture}
                        timezone={location.timezone}
                    />
                </div>

                {broadcastersVisible && (
                    <div className="broadcast-guide__schedule-container">
                        {isEventsFetching ? (
                            <BroadcasterSkeleton />
                        ) : (
                            <div
                                className={`broadcast-guide--${status}`}
                                data-match-id={fixture.id}
                            >
                                {!matchEvents?.length ? (
                                    <Empty />
                                ) : broadcastersVisible ? (
                                    <Broadcasters
                                        fixture={fixture}
                                        event={matchEvents[0]}
                                        location={location}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

Card.propTypes = {
    fixture: PropTypes.object.isRequired,
    location: PropTypes.object,
    competitionId: PropTypes.string,
    seasonId: PropTypes.string
};
