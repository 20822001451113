import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { PlaceholderImage } from './PlaceholderImage';
import { Details } from './Details';
import { TeamWatermarkBackground } from '../../TeamWatermarkBackground';

import { useGetPlayerProfileQuery } from 'store/modules/api/statsPro-api';
import { PlayerProfileLink } from '../../common/PlayerProfileLink';

export const Player = ({
    seasonId,
    playerId,
    playerNumber,
    isAFLW = false
}) => {
    const {
        data: playerProfile,
        isFetching,
        isLoading
    } = useGetPlayerProfileQuery({
        seasonId,
        playerId
    });

    const [imgMissing, addPlaceholder] = useState(false);

    const onErrored = () => {
        //event.target.parentNode.classList.add('has-placeholder');
        addPlaceholder((current) => !current);
    };

    return (
        <>
            {playerProfile ? (
                <TeamWatermarkBackground
                    teamAbbreviation={playerProfile.team.teamAbbr}
                    className={`compare-header__watermark-bg compare-header__watermark-bg--${playerNumber}`}
                    side={playerNumber === '1' ? 'left' : 'right'}
                />
            ) : null}
            <div
                className={`compare-header__player compare-header__player--${playerNumber} ${
                    playerProfile
                        ? `compare-header__player--${playerProfile?.team?.teamAbbr?.toLowerCase()}`
                        : ''
                } ${
                    imgMissing || (isFetching && !isLoading)
                        ? 'has-placeholder'
                        : ''
                }`}
            >
                {playerProfile && (
                    <PlayerProfileLink
                        classes="stats-head-to-head__player-link"
                        isAFLW={isAFLW}
                        player={{
                            playerId: playerProfile.playerId,
                            firstName: playerProfile.playerDetails.givenName,
                            surname: playerProfile.playerDetails.surname
                        }}
                    >
                        <img
                            className="compare-header__player-image"
                            alt={`${PULSE.I18N.lookup(
                                'label.stats.headshot.alt'
                            )} ${playerProfile.playerDetails.givenName} ${
                                playerProfile.playerDetails.surname
                            }`}
                            src={PULSE.app.common.image.migratePlayerPhotoURL(
                                playerProfile.playerDetails.photoURL,
                                0.5
                            )}
                            onError={(event) => onErrored(event)}
                        />
                    </PlayerProfileLink>
                )}

                {!playerProfile && <PlaceholderImage />}

                <Details
                    cssClass="compare-header__player-details"
                    floatingIcon="swap-action"
                >
                    {playerProfile ? (
                        <>
                            <span className="compare-header__team">
                                <span className="compare-header__team-label u-hide-until-tablet">
                                    {playerProfile.team.teamName}
                                </span>
                                <span className="compare-header__team-label u-hide-from-tablet">
                                    {playerProfile.team.teamAbbr}
                                </span>
                            </span>
                            <span className="compare-header__fname">
                                {playerProfile.playerDetails.givenName}
                            </span>
                            <span className="compare-header__lname">
                                {playerProfile.playerDetails.surname}
                            </span>
                            <span className="compare-header__swap-label">
                                {PULSE.I18N.lookup(
                                    'label.statsCompare.swapPlayer'
                                )}
                            </span>
                        </>
                    ) : null}
                </Details>
            </div>
        </>
    );
};

Player.propTypes = {
    seasonId: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
    playerNumber: PropTypes.string.isRequired,
    isAFLW: PropTypes.bool
};
