import React from 'react';
import PropTypes from 'prop-types';

export const HeaderCellClubName = () => {
    return (
        <th
            className="stats-table__header-cell stats-table__header-cell--club-name"
            scope="col"
        ></th>
    );
};

HeaderCellClubName.propTypes = {
    column: PropTypes.object,
    keyProp: PropTypes.string
};
