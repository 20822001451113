import { put } from 'redux-saga/effects';
import { setFilterItem } from '../actions';

/**
 * @typedef {object} UpdateFilterItemAction
 * @property {string} type
 * @property {object} payload
 * @property {string} payload.filterItem
 * @property {string} payload.value
 */

/**
 * @param {UpdateFilterItemAction} action - The Redux action.
 */
export function* updateFilterItem(action) {
    yield put(setFilterItem(action.payload));
}
