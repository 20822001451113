import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../../setup-react';

// ===== Signals ============================================================ //

// Search Results
export const FETCH_SEARCH_RESULTS = createSignalAction(
    'statspro',
    'FETCH_SEARCH_RESULTS'
);

// ===== Deltas ============================================================= //

// Search Results
export const SET_SEARCH_RESULTS = createDeltaActionType(
    'statspro',
    'SET_SEARCH_RESULTS'
);
export const setSearchResults = createActionCreator(SET_SEARCH_RESULTS);
