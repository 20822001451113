import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param props.children
 * @returns {JSX.Element} Component.
 */
export const StatsSectionAdditionalNavItems = ({ children }) => {
    return (
        <>
            {children && (
                <>
                    {React.Children.map(children, (child) => (
                        <>{React.cloneElement(child)}</>
                    ))}
                </>
            )}
        </>
    );
};

StatsSectionAdditionalNavItems.defaultProps = {
    name: 'StatsSectionAdditionalNavItems'
};

StatsSectionAdditionalNavItems.propTypes = {
    children: PropTypes.any.isRequired
};
