import React from 'react';
import PropTypes from 'prop-types';

import { FixtureLinkButton } from './FixtureLinkButton';
import { getTranslation } from 'common/react/utils/translations';

export const LiveFixtureLinks = ({
    broadcastGuideUrl,
    shouldShowBroadcaster,
    streamUrl,
    radioStream,
    match
}) => {
    return (
        <>
            {/* only show a broadcast guide link if the match is assigned to a broadcaster match-event
            show Watch Live button if match is live and stream exists */}
            {broadcastGuideUrl &&
                shouldShowBroadcaster &&
                (streamUrl || radioStream) && (
                    <>
                        {radioStream && (
                            <FixtureLinkButton
                                icon="headphones"
                                label={getTranslation(
                                    'label.fixtures.listen-live'
                                )}
                                theme="live"
                                onClick={(event) => {
                                    event.preventDefault();
                                    window.open(
                                        PULSE.app.common.url.getMatchRadioURL(
                                            match.id
                                        ),
                                        'AFLRadio',
                                        'width=300,height=322'
                                    );
                                }}
                            />
                        )}

                        <FixtureLinkButton
                            icon="live-broadcast"
                            label={getTranslation(
                                'label.fixtures.broadcastGuide'
                            )}
                            href={broadcastGuideUrl}
                            target="_blank"
                            theme="live"
                            cssClass={
                                streamUrl && radioStream
                                    ? 'u-hide-until-desktop'
                                    : ''
                            }
                        />

                        {streamUrl && (
                            <FixtureLinkButton
                                icon="video-playback-new"
                                label={getTranslation('label.watch-live')}
                                href={streamUrl}
                                target="_blank"
                                cssClass={`u-hide-from-desktop ${
                                    streamUrl && radioStream ? 'has-border' : ''
                                }`}
                                theme="live"
                            />
                        )}
                    </>
                )}

            {/* show Watch Live button if no broadcast guide, stream exists and match is live */}
            {(!broadcastGuideUrl || !shouldShowBroadcaster) && streamUrl && (
                <FixtureLinkButton
                    icon="video-playback-new"
                    label={getTranslation('label.watch-live')}
                    href={streamUrl}
                    target="_blank"
                    cssClass="u-hide-from-desktop"
                    theme="live"
                />
            )}
        </>
    );
};

LiveFixtureLinks.propTypes = {
    broadcastGuideUrl: PropTypes.string,
    shouldShowBroadcaster: PropTypes.bool,
    streamUrl: PropTypes.string,
    radioStream: PropTypes.object,
    match: PropTypes.object
};
