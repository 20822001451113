import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Header } from './Header';
import { VideoListing } from './VideoListing';
import { Loader } from 'common/react/components/Loader';
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { viewByMatchSelector } from 'store/modules/video-hub/view-by-match/selectors';
import { getTranslation } from 'common/react/utils/translations';
import { useGetCurrentCompFilters } from 'common/react/components/competition-nav/hooks/use-get-current-comp-filters';
import { Pill } from './Pill';
import { EmptyState } from 'common/react/components/EmptyState';

let links = [
    {
        filter: 'ProgramCategory:Match Highlights',
        label: getTranslation('label.match.highlights')
    },
    {
        filter: 'ProgramCategory:Mini-Matches',
        label: getTranslation('label.match.mini.matches')
    },
    {
        filter: 'ProgramCategory:Match Replays',
        label: getTranslation('label.match.fullReplays')
    },
    {
        filter: 'ProgramCategory:Press Conference',
        label: getTranslation('label.match.press.conference')
    }
];

const findFilterInLinks = (filter) =>
    links.findIndex((link) => link.filter === filter);

export const VideoListContainer = ({ defaultFilter, defaultFilterText }) => {
    const defaultIndex = findFilterInLinks(defaultFilter);
    const [visibleFilters, setVisibleFilters] = useState([]);

    if (defaultFilter && defaultIndex !== -1) {
        if (
            defaultFilterText &&
            links[defaultIndex].label !== defaultFilterText
        ) {
            links[defaultIndex].label = defaultFilterText;
        }
    } else if (defaultFilter) {
        links.unshift({
            filter: defaultFilter,
            label: defaultFilterText || defaultFilter
        });
    }

    const [activeFilter, setActiveFilter] = useState(
        findFilterInLinks(defaultFilter)
    );

    const nav = useSelector(navSelector);
    const { competitionId, compSeason, round, roundLoadError } =
        useGetCurrentCompFilters();
    const matchID = useSelector(viewByMatchSelector)?.matchId;

    // if default has results we can render,
    // set the activeFilter to be default
    useEffect(() => {
        if (
            defaultFilter &&
            visibleFilters.includes(findFilterInLinks(defaultFilter))
        ) {
            setActiveFilter(findFilterInLinks(defaultFilter));
        } else if (
            !visibleFilters.includes(activeFilter) &&
            visibleFilters.length
        ) {
            // if default doesn't have results we can set to a tag with results
            setActiveFilter(visibleFilters[0]);
        }
    }, [nav, visibleFilters]);

    return (
        <div className="media-list media-list--video video-hub-video-listing">
            <div className="wrapper">
                {competitionId &&
                compSeason?.id &&
                !isNaN(round?.roundNumber) ? (
                    <Header
                        competitionId={competitionId}
                        compSeason={compSeason}
                        round={round}
                        matchID={matchID}
                    />
                ) : null}

                {roundLoadError ? (
                    <div className="key-stats-and-performance__empty-state">
                        <EmptyState />
                    </div>
                ) : !competitionId ||
                  !compSeason?.id ||
                  (!round?.roundNumber && round?.roundNumber !== 0) ? (
                    <Loader />
                ) : (
                    <>
                        {!matchID && (
                            <ul className="video-list__filters video-hub-video-listing__filters js-video-filters">
                                {links.map((link, index) => (
                                    <Pill
                                        link={link}
                                        index={index}
                                        key={index}
                                        updateActiveFilterHandler={
                                            setActiveFilter
                                        }
                                        activeFilter={activeFilter}
                                        seasonId={compSeason?.id}
                                        roundId={round.id}
                                        matchID={matchID}
                                        setVisibleFilters={setVisibleFilters}
                                    />
                                ))}
                            </ul>
                        )}

                        <VideoListing
                            seasonId={compSeason?.id}
                            roundId={round.id}
                            matchID={matchID}
                            tagName={
                                links[activeFilter]
                                    ? links[activeFilter].filter
                                    : ''
                            }
                        />
                    </>
                )}
            </div>
        </div>
    );
};

VideoListContainer.propTypes = {
    defaultFilter: PropTypes.string,
    defaultFilterText: PropTypes.string
};
