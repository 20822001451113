/* eslint-disable react/prop-types */
import React from 'react';

import { getTranslation } from 'common/react/utils/translations';
import { STATS_CATEGORY_IDS } from 'widgets/statspro/js/components/common/config/performance-stats';

const CLASS_BLOCK = 'performance-chart-tooltip';

export const ComparisonTooltip = ({
    active,
    payload,
    player,
    playerTwo,
    stat
}) => {
    const statTranslation = getTranslation(
        'label.stats.' + STATS_CATEGORY_IDS[stat]
    );
    const lineOne = payload.find(
        (payloadItem) => payloadItem.name === 'playerOneLine'
    );
    const lineTwo = payload.find(
        (payloadItem) => payloadItem.name === 'playerTwoLine'
    );
    const composedPlayers = [player, playerTwo];

    if (active && payload.length) {
        return (
            <div className={`${CLASS_BLOCK} ${CLASS_BLOCK}--comparison`}>
                <div className={`${CLASS_BLOCK}__row`}>
                    <div>
                        <div className={`${CLASS_BLOCK}__stat-title`}>
                            {statTranslation}
                        </div>
                        <div className={`${CLASS_BLOCK}__subtext`}>
                            {payload[0].payload.subtext ||
                                payload[0].payload.playerTwoSubtext ||
                                payload[1]?.payload.playerTwoSubtext}
                        </div>
                    </div>
                </div>

                <div className={`${CLASS_BLOCK}__player-details-container`}>
                    {composedPlayers.map(
                        (playerItem, i) =>
                            playerItem?.playerDetails && (
                                <div
                                    key={i}
                                    className={`${CLASS_BLOCK}__player-details`}
                                >
                                    <div className={`${CLASS_BLOCK}__name`}>
                                        <span
                                            className={`${CLASS_BLOCK}__color-label ${CLASS_BLOCK}__color-label--p${
                                                i === 0 ? '1' : '2'
                                            }`}
                                        ></span>
                                        {`${playerItem.playerDetails.givenName} ${playerItem.playerDetails.surname}`}
                                    </div>
                                    <div
                                        className={`${CLASS_BLOCK}__stat-container`}
                                    >
                                        <span
                                            className={`${CLASS_BLOCK}__stat-value`}
                                        >
                                            {i === 0
                                                ? lineOne?.value ?? '-'
                                                : lineTwo?.value ?? '-'}
                                        </span>
                                    </div>
                                </div>
                            )
                    )}
                </div>
            </div>
        );
    }

    return null;
};
