import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const NavItem = ({ isActive, isLink, label, onClick, navItemRef }) => {
    return (
        <li
            ref={navItemRef}
            className={classnames(
                { 'is-active': isActive },
                'stats-glossary__modal-nav-item'
            )}
        >
            {isLink && (
                <button
                    className={classnames(
                        { 'is-active': isActive },
                        'stats-glossary__modal-nav-item-link'
                    )}
                    onClick={(event) => onClick(event, label)}
                    tabIndex="0"
                >
                    {label}
                </button>
            )}
            {!isLink && (
                <span className="stats-glossary__modal-nav-item-inactive">
                    {label}
                </span>
            )}
        </li>
    );
};

NavItem.propTypes = {
    isActive: PropTypes.bool.isRequired,
    isLink: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    navItemRef: PropTypes.any.isRequired
};
