import { put } from 'redux-saga/effects';
import {
    ADD_PLAYER,
    REMOVE_PLAYER,
    setPlayer,
    setRemovePlayer
} from '../actions';

/**
 *
 * @param action
 */
export function* addPlayer(action) {
    const { payload } = action;
    const player = {};

    if (typeof payload === 'string') {
        player.id = payload;
    } else {
        const { id, key } = payload;
        player.id = id !== 'null' ? id : null;
        player.key = key;
    }

    yield put(setPlayer(player));
    yield put(ADD_PLAYER.success());
}

/**
 *
 * @param action
 */
export function* removePlayer(action) {
    yield put(setRemovePlayer(action.payload));
    yield put(REMOVE_PLAYER.success());
}
