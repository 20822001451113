import { put } from 'redux-saga/effects';
import { setTemporaryFields } from '../actions';

/**
 * @typedef {object} UpdateTempoaryFieldsAction
 * @property {string} type
 * @property {object} payload
 */

/**
 * @param {UpdateTempoaryFieldsAction} action - The Redux action.
 */
export function* updateTemporaryFields(action) {
    yield put(setTemporaryFields(action.payload));
}
