import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';

export const ModalLink = ({ icon, label, link, btnLabel, onClick }) => {
    return (
        <div className="modal-link">
            {icon ? (
                <SvgIcon icon={icon} className="icon modal-link__icon" />
            ) : null}
            {link ? (
                <a href={link} className="modal-link__label" onClick={onClick}>
                    {label}
                    {btnLabel ? (
                        <span className="filter-button">
                            {btnLabel}
                            <SvgIcon
                                icon="arrow-right"
                                className="icon modal-link__icon"
                            />
                        </span>
                    ) : null}
                </a>
            ) : null}
        </div>
    );
};

ModalLink.propTypes = {
    icon: PropTypes.string,
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    btnLabel: PropTypes.string,
    onClick: PropTypes.func
};
