import React from 'react';
import PropTypes from 'prop-types';

// Note: This is a `td` instead of a `th` for accessibility purposes.
// It's clearer to use the player's name as the only row header and leave the
// rank as a standard cell.
export const StatsLeadersRankCell = ({ rank, ...cellProps }) => (
    <td {...cellProps} className="stats-table__row-header-cell">
        {rank}
    </td>
);

StatsLeadersRankCell.propTypes = {
    rank: PropTypes.number.isRequired
};
