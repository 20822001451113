import React from 'react';
import { useSelector } from 'react-redux';

import { STATS_COLUMNS } from 'widgets/statspro/js/components/common/config/columns';
import {
    statsLeadersSortSelector,
    seasonSelector,
    roundSelector
} from 'store/modules/statspro/stats-leaders/selectors';
import { Loader } from 'common/react/components/Loader';
import { getTranslation } from 'common/react/utils/translations';

/**
 * @typedef {object} Props
 * @property {object} sort
 * @property {object} season
 * @property {object} round
 */

/**
 * @returns {JSX.Element} Loader or heading.
 */
export const Heading = () => {
    const sort = useSelector(statsLeadersSortSelector);
    const season = useSelector(seasonSelector);
    const round = useSelector(roundSelector);

    if (!season || !sort.columnId) {
        return <Loader />;
    }

    const sortByColumn = STATS_COLUMNS[sort.columnId]?.name;

    // Regular expression pattern to match four-digit years starting with 2 from 2000 to 2100
    const regexPattern = /(20[0-9]{2}|2100)/;
    const match = season?.name.match(regexPattern);

    return (
        <>
            <strong>
                {sortByColumn ||
                    getTranslation('label.statspro.defaultSortTitle')}
            </strong>{' '}
            - {match[0]} Season {round && `(Round ${round.roundNumber})`}
        </>
    );
};
