import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders the club colour chip.
 *
 * @param homeTeam.homeTeam
 * @param {object} homeTeam - The home team data.
 * @param {object} awayTeam - The away team data.
 * @param homeTeam.awayTeam
 * @returns {React.JSX.Element} - The rendered team chip component.
 */
export const TAClubChip = ({ homeTeam, awayTeam }) => {
    return (
        <>
            <div className="team-announcements-field__club-chip-wrapper">
                <div className="team-announcements-field__club-chip">
                    <span
                        className={`team-announcements-field__club-chip-badge team-announcements-field__player-tag-badge-number team-lineups__position-meta-label--home ${homeTeam?.teamId}`}
                    >
                        •
                    </span>
                    <span className="team-announcements-field__club-chip-title">
                        {homeTeam.teamName}
                    </span>
                </div>
                <div className="team-announcements-field__club-chip">
                    <span
                        className={`team-announcements-field__club-chip-badge team-announcements-field__club-chip-badge--away team-announcements-field__player-tag-badge-number team-lineups__position-meta-label--away ${awayTeam?.teamId}`}
                    >
                        •
                    </span>
                    <span className="team-announcements-field__club-chip-title">
                        {awayTeam.teamName}
                    </span>
                </div>
            </div>
        </>
    );
};

TAClubChip.propTypes = {
    homeTeam: PropTypes.object,
    awayTeam: PropTypes.object
};
