import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { DraftTrackerLeaderboard } from '../components/DraftTrackerLeaderboard';

export const DraftTrackerLeaderboardProvider = ({ store, data }) => (
    <Provider store={store}>
        <DraftTrackerLeaderboard {...data} />
    </Provider>
);

DraftTrackerLeaderboardProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
