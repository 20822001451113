import { PropTypes } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTranslation } from 'common/react/utils/translations';
import {
    careerAndSeasonStatsNavSelector,
    careerAndSeasonStatsSortSelector
} from 'store/modules/statspro/career-and-season-stats/selectors';

/**
 * @param root0
 * @param root0.isProspect - true if the Heading is used inside a draft prospect table.
 * @param root0.isProspectModal
 * @returns {JSX.Element} Component.
 */
export const Heading = ({ isProspect, isProspectModal }) => {
    const nav = useSelector(careerAndSeasonStatsNavSelector);
    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );
    const season = seasons.find((item) => item.id === nav.season.id);
    const sort = useSelector(careerAndSeasonStatsSortSelector);

    return (
        <>
            {nav.season.id === -1 ? (
                <>
                    <strong>
                        {isProspectModal
                            ? getTranslation('label.stats')
                            : sort.columnId === 'year' ||
                              sort.columnId === 'round'
                            ? getTranslation('label.stats.by') +
                              ' ' +
                              getTranslation('label.stats.year')
                            : getTranslation(
                                  `label.statsLeaders.${sort.columnId}.name`
                              )}
                    </strong>{' '}
                    {!isProspect
                        ? '- ' + getTranslation('label.stats.career')
                        : null}
                </>
            ) : (
                <>
                    <strong>
                        {sort.columnId === 'round' || sort.columnId === 'year'
                            ? getTranslation('label.stats.by') +
                              ' ' +
                              getTranslation('label.stats.round')
                            : getTranslation(
                                  `label.statsLeaders.${sort.columnId}.name`
                              )}
                    </strong>{' '}
                    - {season.name}
                </>
            )}
        </>
    );
};

Heading.propTypes = {
    isProspect: PropTypes.bool,
    isProspectModal: PropTypes.bool
};
