import React from 'react';

import DefaultTableHeadRenderer from './DefaultTableHeadRenderer';

const TableHead = ({
    field,
    multipleTableIndex,
    tableName,
    sortBy,
    handleClick,
    getTranslation,
    lastColumn,
    lastColumnRef
}) => {
    const tableHeaderClass = field.tableHeadCustomClass
        ? field.tableHeadCustomClass
        : '';

    const sortIcon = PULSE.app.templating.render(
        {
            cssClass: `custom-table__header-cell-sort-icon`,
            name: 'dropdown'
        },
        'common.svg-icon'
    );

    const sortButtonActiveClass =
        field.fieldName === sortBy.fieldName
            ? `is-active is-active--${sortBy.direction.toLowerCase()}`
            : '';
    const noDescriptionClass =
        field.description &&
        getTranslation(field.description) !== field.description
            ? ''
            : 'custom-table__header-cell--no-description';

    let tableHeadMarkup = '';

    if (field.customTableHeadRenderer) {
        // use the custom renderer; the 2nd argument can also be used to pass custom props
        tableHeadMarkup = React.createElement(field.customTableHeadRenderer, {
            field: field,
            multipleTableIndex: multipleTableIndex,
            tableName: tableName,
            sortBy: sortBy,
            handleClick: handleClick,
            getTranslation: getTranslation,
            lastColumn: lastColumn,
            lastColumnRef: lastColumnRef
        });
    } else {
        // use the default renderer
        tableHeadMarkup = (
            <DefaultTableHeadRenderer
                className={`custom-table__header-cell ${tableHeaderClass} ${noDescriptionClass}`}
                field={field}
                multipleTableIndex={multipleTableIndex}
                tableName={tableName}
                sortBy={sortBy}
                handleClick={handleClick}
                getTranslation={getTranslation}
                lastColumn={lastColumn}
                lastColumnRef={lastColumnRef}
            />
        );
    }

    return tableHeadMarkup;
};

export default TableHead;
