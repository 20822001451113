import { all, takeEvery } from 'redux-saga/effects';
import { UPDATE_NAV_ITEM, UPDATE_FILTER_ITEM } from '../actions';
import { updateNavItem } from './update-nav-item';
import { updateFilterItem } from './update-filter-item';

/**
 *
 */
export function* navRootSaga() {
    yield all([
        takeEvery(UPDATE_NAV_ITEM.REQUEST, updateNavItem),
        takeEvery(UPDATE_FILTER_ITEM.REQUEST, updateFilterItem)
    ]);
}
