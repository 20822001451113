import { put } from 'redux-saga/effects';
import { setSort } from '../actions';

/**
 * @typedef {object} UpdateSortAction
 * @property {string} type
 * @property {object} payload
 * @property {string} payload.columnId
 * @property {'ascending'|'descending'} payload.sortDirection
 */

/**
 * @param {UpdateSortAction} action - The Redux action.
 */
export function* updateSort(action) {
    yield put(setSort(action.payload));
}
