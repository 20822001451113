import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FilterButton } from 'common/react/components/FilterButton';

/**
 * @param {object} props - Props.
 * @returns {JSX.Element} Component.
 */
export const StatsSectionNav = (props) => {
    const [areFilterOptionsVisible, setAreFilterOptionsVisible] =
        useState(false);

    const [resetFilterBtnStyles, setResetFilterBtnStyles] = useState(false);

    const isFiltersVisibleHandler = () => {
        setAreFilterOptionsVisible((prev) => !prev);
    };

    const children = React.Children.toArray(props.children);

    if (!children?.length) {
        return <></>;
    }

    const NavItems = children.find(
        (child) => child.props.name === 'StatsSectionNavItems'
    );
    const FilterItems = children.find(
        (child) => child.props.name === 'StatsSectionNavFilterItems'
    );
    const AdditionalNavItems = children.find(
        (child) => child.props.name === 'StatsSectionAdditionalNavItems'
    );
    const RoundButtons = children.find(
        (child) => child.props.name === 'StatsSectionRoundButtons'
    );
    const SettingsButton = children.find(
        (child) => child.props.name === 'StatsSectionSettingsButton'
    );
    const SearchBarMobile = children.find(
        (child) => child.props.name === 'StatsSectionSearchMobile'
    );

    return (
        <div className="u-stats-shadow" style={{ marginBottom: '1.6rem' }}>
            <div className="stats-leaders-nav">
                {NavItems}

                {!!FilterItems && (
                    <div className="stats-leaders-nav__filter-button-wrapper">
                        <FilterButton
                            onChange={isFiltersVisibleHandler}
                            areFilterOptionsVisible={areFilterOptionsVisible}
                            resetFilterBtnStyles={resetFilterBtnStyles}
                            setResetFilterBtnStyles={setResetFilterBtnStyles}
                        />
                    </div>
                )}

                {!!SettingsButton && (
                    <div className="stats-leaders-nav__filter-button-wrapper">
                        {SettingsButton}
                    </div>
                )}

                {!!AdditionalNavItems && (
                    <div className="stats-leaders-nav__filter-button-wrapper">
                        {AdditionalNavItems}
                    </div>
                )}
                {!!RoundButtons && !areFilterOptionsVisible && (
                    <div className="stats-leaders-nav__round-buttons-wrapper">
                        {RoundButtons}
                    </div>
                )}
            </div>

            <div
                className={`${
                    !areFilterOptionsVisible && !props.showFilterItems
                        ? 'u-hide'
                        : ''
                }`}
            >
                {FilterItems &&
                    React.cloneElement(FilterItems, {
                        isFiltersVisibleHandler: isFiltersVisibleHandler,
                        setResetFilterBtnStyles: setResetFilterBtnStyles
                    })}
            </div>

            {!!SearchBarMobile && (
                <div className="stats-leaders-nav__search-bar--mobile">
                    {SearchBarMobile}
                </div>
            )}
        </div>
    );
};

StatsSectionNav.defaultProps = {
    name: 'StatsSectionNav'
};

StatsSectionNav.propTypes = {
    children: PropTypes.any,
    onclick: PropTypes.func,
    isOpen: PropTypes.bool,
    showFilterItems: PropTypes.bool
};
