import React from 'react';
import PropTypes from 'prop-types';

export const FixtureScore = ({ score }) => {
    return (
        <div className="fixtures__match-score-total">{score?.totalScore}</div>
    );
};

FixtureScore.propTypes = {
    score: PropTypes.object
};
