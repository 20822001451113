import produce from 'immer';

// eslint-disable-next-line no-unused-vars
import { CareerAndSeasonStatsState } from '../types';
// eslint-disable-next-line no-unused-vars
import { Action } from '../../../../types';
import {
    SET_NAV_ITEM,
    SET_SORT,
    SET_BENCHMARKING,
    SET_DATA_TYPE
} from '../actions';
import {
    setNavItem,
    setSort,
    setBenchmarking,
    setDataType
} from '../../shared/reducer';
import {} from './';
import { DATA_TYPE } from 'widgets/statspro/js/components/common/config/data-type';
/**
 * @type {CareerAndSeasonStatsState}
 */
const initialState = {
    nav: {
        category: 'Key Stats',
        competition: {
            id: null
        },
        season: {
            id: -1
        }
    },
    sort: {
        columnId: null,
        sortDirection: 'descending',
        force: false
    },
    benchmarking: {
        isActive: false
    },
    dataType: {
        type: DATA_TYPE.AVERAGES
    }
};

/**
 * @param {CareerAndSeasonStatsState} state - The Redux state.
 * @param {Action} action - The Redux action.
 * @returns {CareerAndSeasonStatsState} The updated Redux state.
 */
export const careerAndSeasonStatsReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_NAV_ITEM:
                setNavItem(draft, action.payload);
                break;
            case SET_SORT:
                setSort(draft, action.payload);
                break;
            case SET_BENCHMARKING:
                setBenchmarking(draft, action.payload);
                break;
            case SET_DATA_TYPE:
                setDataType(draft, action.payload);
                break;
            default:
                draft;
        }
    });
};
