// eslint-disable-next-line no-unused-vars
import { PlayerModalState } from '../types';
import '../../types';

/**
 * @param {PlayerModalState} draft - Draft state.
 * @param {object} payload - Action payload.
 * @param payload.data
 */
export const setPredictedData = (draft, { data }) => {
    draft.predicted = data ?? {};
};
