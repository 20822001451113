import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { FixturesList } from './fixtures-list/FixturesList';
import { EmptyState } from 'common/react/components/EmptyState';
import { useGetAllContentQuery } from 'common/store/modules/api/content-api';
import { FixturesListCustom } from './fixtures-list/FixturesListCustom';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { SettingsButton } from 'common/react/components/SettingsButton';

export const NoFilterFixtures = (data) => {
    const title = data.title ? data.title : false;
    const linkTo = data.linkTo ? data.linkTo : false;
    const specialRound = data?.specialRound !== 'None' ? data.specialRound : '';
    const competitionId = data.competitionId;
    const competitionPid = data.competitionPid;
    const compSeason = {
        providerId: data.seasonPid,
        id: Number(data.seasonId)
    };
    const roundNumber = Number(data.noFilterRound);
    const customMatchId = data.customMatchId ? data.customMatchId : false;
    const matchesVisibleNumber = data.matchesVisibleNumber;

    // contents param should be in format ?contents=PROMO:{reference},PROMO:{reference}
    const broadcasterPromoIds = data?.broadcasterPromos
        ?.split(',')
        .map((id) => `PROMO:${id}`);

    const { data: broadcasterPromoData = [] } = useGetAllContentQuery(
        {
            params: {
                contents: broadcasterPromoIds?.join(',')
            }
        },
        { skip: !broadcasterPromoIds }
    );

    const broadcasterPromo = useMemo(() => {
        const found = broadcasterPromoData.find((promo) => {
            let compReference = promo.references?.find(
                (ref) => ref.type === 'AFL_COMPETITION'
            );
            return compReference.id === competitionId;
        });

        return found;
    }, [broadcasterPromoData, competitionId]);

    return (
        <>
            <div className="wrapper">
                {!compSeason?.providerId ||
                !compSeason?.id ||
                isNaN(roundNumber) ? (
                    <EmptyState cssClass="competition-nav__empty-state" />
                ) : (
                    <>
                        <div className="fixtures__header-wrapper">
                            {linkTo ? (
                                <a
                                    href={`${linkTo}`}
                                    className="widget-header__title-link"
                                >
                                    <h2 className="fixtures__header-wrapper__title widget-header__title">
                                        {title}
                                    </h2>
                                    <SvgIcon
                                        icon="arrow-right"
                                        className="icon"
                                    />
                                </a>
                            ) : (
                                <h2 className="fixtures__header-wrapper__title widget-header__title">
                                    {title}
                                </h2>
                            )}
                            <SettingsButton />
                        </div>
                        {customMatchId ? (
                            <FixturesListCustom
                                competitionId={competitionId}
                                competitionPid={competitionPid}
                                compSeason={compSeason}
                                roundNumber={roundNumber}
                                customMatchId={customMatchId}
                                specialRound={specialRound}
                                broadcasterPromo={broadcasterPromo}
                            />
                        ) : (
                            <FixturesList
                                competitionId={competitionId}
                                competitionPid={competitionPid}
                                compSeason={compSeason}
                                roundNumber={roundNumber}
                                broadcasterPromo={broadcasterPromo}
                                specialRound={specialRound}
                                matchesVisibleNumber={matchesVisibleNumber}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};

NoFilterFixtures.propTypes = {
    data: PropTypes.object
};
