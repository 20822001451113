// eslint-disable-next-line no-unused-vars
import { LeaderboardState } from '../types';
import '../../types';

/**
 * @param {LeaderboardState} draft - Draft state.
 * @param {object} payload - Action payload.
 */
export const setFavouritePlayer = (draft, payload) => {
    // if already favourite, remove from state
    if (draft.favourites.some((e) => e.providerId === payload.providerId)) {
        let index = draft.favourites.findIndex(
            (e) => e.providerId === payload.providerId
        );
        draft.favourites = draft.favourites.filter((val, i) => i !== index);
    } else {
        draft.favourites = [...draft.favourites, payload];
    }

    let favourites = '[]';

    if (draft.favourites === null) {
        favourites = '[]';
    } else {
        favourites = JSON.stringify(draft.favourites);
    }

    localStorage.setItem('brownlow-predictor-favourites', favourites);
};
