import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';

import {
    Date,
    EventTypeFilter,
    TeamFilter,
    EventLocationFilter
} from './nav-items';

export const EventsListingNav = ({
    compseasonId,
    tags,
    locationTags,
    localTimezone,
    eventId
}) => {
    const tagItems = useMemo(() => {
        return tags
            ? tags.split(',').map((tag) => ({
                  value: tag,
                  name: tag.replace('EventType:', '')
              }))
            : [];
    }, [tags]);

    const locationTagItems = useMemo(() => {
        return locationTags
            ? locationTags.split(',').map((tag) => ({
                  value: tag,
                  name: tag.replace('EventLocation:', '')
              }))
            : [];
    }, [locationTags]);

    const windowSize = useWindowSize();
    const isResponsive = windowSize.width < PULSE.app.measurements.desktop;

    const [resetFns, setResetFns] = useState([]);

    const resetHandler = (resetFunctions) => {
        resetFunctions.forEach((func) => func());
    };

    return (
        <div className="events-listing-nav">
            <StatsSection.Nav showFilterItems={isResponsive}>
                <StatsSection.NavItems>
                    {eventId && (
                        <Date
                            localTimezone={localTimezone}
                            eventId={eventId}
                            resetFilters={() => resetHandler(resetFns)}
                        />
                    )}
                </StatsSection.NavItems>
                <StatsSection.NavFilterItems
                    hideReset={true}
                    showFilterItems={isResponsive}
                    setResetFnsProp={setResetFns}
                >
                    {tagItems.length && (
                        <EventTypeFilter
                            tags={tagItems}
                            addOnResetCallback={() => {
                                /* */
                            }}
                        />
                    )}
                    {compseasonId && (
                        <TeamFilter
                            compseasonId={compseasonId}
                            addOnResetCallback={() => {
                                /* */
                            }}
                        />
                    )}
                    {locationTagItems.length ? (
                        <EventLocationFilter
                            tags={locationTagItems}
                            addOnResetCallback={() => {
                                /* */
                            }}
                        />
                    ) : null}
                </StatsSection.NavFilterItems>
            </StatsSection.Nav>
        </div>
    );
};

EventsListingNav.propTypes = {
    compseasonId: PropTypes.string,
    tags: PropTypes.string,
    locationTags: PropTypes.string,
    localTimezone: PropTypes.string,
    eventId: PropTypes.string.isRequired
};
