import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../../setup-react';

// ===== Signals ============================================================ //

// Player Selection Modal
export const CHANGE_PLAYER_SELECTION_MODAL_VISIBILITY = createSignalAction(
    'stats/player-comparison',
    'CHANGE_PLAYER_SELECTION_MODAL_VISIBILITY'
);

// Filters
export const UPDATE_FILTER_ITEM = createSignalAction(
    'stats/player-comparison',
    'UPDATE_FILTER_ITEM'
);
export const RESET_FILTERS = createSignalAction(
    'stats/player-comparison',
    'RESET_FILTERS'
);

// Setting compared players
export const ADD_PLAYER = createSignalAction(
    'stats/player-comparison',
    'ADD_PLAYER'
);

export const REMOVE_PLAYER = createSignalAction(
    'stats/player-comparison',
    'REMOVE_PLAYER'
);

// ===== Deltas ============================================================= //

// Player Selection Modal
export const SET_PLAYER_SELECTION_MODAL_VISIBLE = createDeltaActionType(
    'stats/player-comparison',
    'SET_PLAYER_SELECTION_MODAL_VISIBLE'
);

export const setPlayerSelectionModalVisible = createActionCreator(
    SET_PLAYER_SELECTION_MODAL_VISIBLE
);

// Filters
export const SET_FILTER_ITEM = createDeltaActionType(
    'stats/player-comparison',
    'SET_FILTER_ITEM'
);
export const setFilterItem = createActionCreator(SET_FILTER_ITEM);

export const SET_RESET_FILTERS = createDeltaActionType(
    'stats/player-comparison',
    'SET_RESET_FILTERS'
);
export const setResetFilters = createActionCreator(SET_RESET_FILTERS);

// Setting compared players
export const SET_PLAYER = createDeltaActionType(
    'stats/player-comparison',
    'SET_PLAYER'
);
export const setPlayer = createActionCreator(SET_PLAYER);

export const SET_REMOVE_PLAYER = createDeltaActionType(
    'stats/player-comparison',
    'SET_REMOVE_PLAYER'
);
export const setRemovePlayer = createActionCreator(SET_REMOVE_PLAYER);
