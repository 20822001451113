import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card } from './cards';
import { EmptyState } from 'common/react/components/EmptyState';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { lookupTranslation } from 'common/react/utils/translations';

export const EventsListingByDate = ({
    eventsArray,
    localTimezone,
    compseasonId,
    activeStartDate,
    showTimeDisclaimer = true
}) => {
    const [activeSharePopup, setActiveSharePopup] = useState('');

    return (
        <>
            {eventsArray.length ? (
                <>
                    <div className="events-listing__header">
                        <p className="events-listing__header-text">
                            {moment(activeStartDate).format('dddd MMMM D')}
                        </p>
                    </div>

                    {showTimeDisclaimer ? (
                        <div className="events-listing__sub-header">
                            <SvgIcon
                                icon="clock"
                                className="events-listing__sub-header-icon icon"
                            />
                            <p className="events-listing__sub-header-text">
                                {lookupTranslation(
                                    'label.eventsListing.timeDisclaimer'
                                )}
                            </p>
                        </div>
                    ) : null}

                    {eventsArray.map((event) => (
                        <Card
                            key={event.id}
                            localTimezone={localTimezone}
                            event={event}
                            compseasonId={compseasonId}
                            activeSharePopup={activeSharePopup}
                            setActiveSharePopup={setActiveSharePopup}
                        />
                    ))}
                </>
            ) : (
                <EmptyState />
            )}
        </>
    );
};

EventsListingByDate.propTypes = {
    eventsArray: PropTypes.array.isRequired,
    localTimezone: PropTypes.string,
    compseasonId: PropTypes.string,
    activeStartDate: PropTypes.string,
    showTimeDisclaimer: PropTypes.bool
};
