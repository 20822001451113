import React from 'react';
import PropTypes from 'prop-types';

import { getTranslation } from 'common/react/utils/translations';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { PlayerProfileLink } from '../../common/PlayerProfileLink';

export const OverviewTableLinkCell = ({ player, isAFLW }) => {
    return (
        <div
            role="cell"
            className={`overview-table__data overview-table__data--link ${
                player?.playerId
                    ? `overview-table__mobile`
                    : `overview-table__empty`
            }`}
        >
            {player?.playerId ? (
                <PlayerProfileLink
                    classes="overview-table__link"
                    isAFLW={isAFLW}
                    player={{
                        playerId: player.playerId,
                        firstName: player.playerDetails.givenName,
                        surname: player.playerDetails.surname
                    }}
                >
                    <span className="u-hide-until-tablet">
                        {getTranslation('label.statsCompare.profileLink')}
                    </span>
                    <span className="u-hide-from-tablet">
                        {getTranslation('label.statsCompare.profileLink.short')}
                    </span>
                    <SvgIcon
                        icon="arrow-right"
                        className="overview-table__icon"
                    />
                </PlayerProfileLink>
            ) : (
                '-'
            )}
        </div>
    );
};

OverviewTableLinkCell.propTypes = {
    player: PropTypes.object,
    isAFLW: PropTypes.bool
};
