import { put } from 'redux-saga/effects';
import { setBenchmarking } from '../actions';

/**
 * @typedef {object} UpdateBenchmarkingAction
 * @property {string} type
 * @property {object} payload
 * @property {boolean} payload.isActive
 */

/**
 * @param {UpdateBenchmarkingAction} action - The Redux action.
 */
export function* updateBenchmarking(action) {
    yield put(setBenchmarking(action.payload));
}
