import React from 'react';
import PropTypes from 'prop-types';

/**
 * @callback SetPageSizeCallback
 * @param {number} pageSize
 */

/**
 * @param {object} props - Props.
 * @param {boolean} props.canNextPage - True if there are more pages to show, otherwise false.
 * @param {number} props.pageSize - Number of rows in a page.
 * @param {number} props.currentPageSize - Current page size.
 * @param {number} props.totalRowCount - Total row count.
 * @param {SetPageSizeCallback} props.setPageSize - Set page size.
 * @param {string} props.customLabel - Custom button text.
 * @returns {JSX.Element} Component.
 */
export const NextPageButton = ({
    canNextPage,
    pageSize,
    currentPageSize,
    totalRowCount,
    setPageSize,
    customLabel
}) => {
    if (!canNextPage) {
        return <></>;
    }

    // @ts-ignore
    const {
        I18N: { translations },
        app: { templating }
    } = PULSE;

    const pagesToLoadCount = Math.min(
        pageSize,
        totalRowCount - currentPageSize
    );

    return (
        <button
            className="stats-table-load-more-button"
            onClick={() => {
                if (!canNextPage) {
                    return;
                }
                setPageSize(
                    Math.min(currentPageSize + pageSize, totalRowCount)
                );
            }}
        >
            <span
                dangerouslySetInnerHTML={{
                    __html: translations[
                        customLabel ?? 'label.statsLeaders.paginationNext'
                    ]?.replace('{0}', pagesToLoadCount)
                }}
            ></span>
            <span
                dangerouslySetInnerHTML={{
                    __html: templating.render(
                        {
                            cssClass: 'stats-table-load-more-button__icon',
                            name: 'arrow-right'
                        },
                        'common.svg-icon'
                    )
                }}
            ></span>
        </button>
    );
};

NextPageButton.propTypes = {
    canNextPage: PropTypes.bool.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPageSize: PropTypes.number.isRequired,
    totalRowCount: PropTypes.number.isRequired,
    setPageSize: PropTypes.func.isRequired,
    customLabel: PropTypes.string
};
