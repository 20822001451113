/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import { TeamAnnouncementsFieldGroup } from './TeamAnnouncementsFieldGroup';
import { createPlayerModel } from '../util';
import { getTranslation } from 'common/react/utils/translations';
import { PosMarker } from './PosMarker';
import { TeamAnnouncementsFieldPlayer } from './TeamAnnouncementsFieldPlayer';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { ToggleInput } from 'common/react/components/ToggleInput';
import { TAClubChip } from './TAClubChip';

/**
 * Renders the player formation on a field graphic.
 *
 * @param {object} props - The props
 * @param {object} props.homeData - The home team's player data.
 * @param {object} props.awayData - The away team's player data.
 * @param {Array} props.matchStatus - The match's status
 * @param {string} props.initialTeamDisplay - The preset team view display.
 * @param {string} props.teamDisplay - Which team to display (both, home or away).
 * @param {Function} props.updateTeamDisplay - The function to change the actual team display value.
 * @returns {React.JSX.Element} - The rendered component
 */
export const TeamAnnouncementsField = ({
    homeData,
    awayData,
    matchStatus,
    initialTeamDisplay,
    teamDisplay,
    updateTeamDisplay
}) => {
    const groupedFollowers =
        teamDisplay === 'home'
            ? createPlayerModel(homeData?.followers)
            : teamDisplay === 'away'
            ? createPlayerModel([], awayData?.followers)
            : createPlayerModel(homeData?.followers, awayData?.followers);

    const groupedInterchanges =
        teamDisplay === 'home'
            ? createPlayerModel(homeData?.interchanges)
            : teamDisplay === 'away'
            ? createPlayerModel([], awayData?.interchanges)
            : createPlayerModel(homeData?.interchanges, awayData?.interchanges);

    const groupedEmergencies =
        teamDisplay === 'home'
            ? createPlayerModel(homeData?.emergencies)
            : teamDisplay === 'away'
            ? createPlayerModel([], awayData?.emergencies)
            : createPlayerModel(homeData?.emergencies, awayData?.emergencies);

    const groupedSubstitutes =
        teamDisplay === 'home'
            ? createPlayerModel(homeData?.substitutes)
            : teamDisplay === 'away'
            ? createPlayerModel([], awayData?.substitutes)
            : createPlayerModel(homeData?.substitutes, awayData?.substitutes);

    // Conditions required to show the team icon
    const showTeamIcon =
        teamDisplay === 'home' ||
        initialTeamDisplay === 'home' ||
        teamDisplay === 'away' ||
        initialTeamDisplay === 'away';

    return (
        <>
            <div className="team-announcements-field">
                <div className="team-announcements-field__panel-wrapper">
                    {initialTeamDisplay === 'both' ? (
                        <>
                            <div className="team-announcements__toggle-wrapper team-announcements__toggle-wrapper--team-display">
                                <ToggleInput
                                    id="data-type-toggle"
                                    value={teamDisplay}
                                    onChange={(value) =>
                                        updateTeamDisplay(value)
                                    }
                                    options={[
                                        {
                                            label: getTranslation(
                                                'label.location.all'
                                            ),
                                            value: 'both'
                                        },
                                        {
                                            label: homeData?.teamId?.toUpperCase(),
                                            value: getTranslation(
                                                'label.location.home'
                                            ).toLowerCase()
                                        },
                                        {
                                            label: awayData?.teamId?.toUpperCase(),
                                            value: getTranslation(
                                                'label.location.away'
                                            ).toLowerCase()
                                        }
                                    ]}
                                />
                            </div>
                        </>
                    ) : null}
                    {showTeamIcon ? (
                        <div className="team-announcements-field__team-title">
                            <TeamIcon
                                className="team-announcements-field__team-title-icon"
                                teamAbbreviation={
                                    teamDisplay === 'home'
                                        ? homeData?.teamId
                                        : awayData?.teamId
                                }
                            />
                            <h3 className="team-announcements-field__team-title-name">
                                {teamDisplay === 'home'
                                    ? homeData?.teamName
                                    : awayData?.teamName}
                            </h3>
                        </div>
                    ) : (
                        <TAClubChip homeTeam={homeData} awayTeam={awayData} />
                    )}

                    <div className="team-announcements-field__panel">
                        <div className="team-announcements-field__followers">
                            {homeData.followers || awayData.followers ? (
                                <>
                                    <h3 className="team-announcements-field__header">
                                        {PULSE.I18N.lookup(
                                            'label.playingposition.group.followers'
                                        )}
                                    </h3>
                                    <div className="team-announcements-field__list team-announcements-field__list--followers">
                                        <TeamAnnouncementsFieldGroup
                                            playerGroup={groupedFollowers}
                                            homeData={homeData}
                                            awayData={awayData}
                                            teamDisplay={teamDisplay}
                                        />
                                    </div>
                                </>
                            ) : null}
                        </div>
                        <div className="team-announcements-field__starters">
                            <div
                                className={`team-announcements-field__starters-graphic team-announcements-field__starters-graphic--${teamDisplay}`}
                            >
                                {/* Home positions */}
                                <PosMarker
                                    pos="B"
                                    homeOrAway="home"
                                    labelKey="label.playingposition.group.abbr.backs"
                                ></PosMarker>
                                <PosMarker
                                    pos="HB"
                                    homeOrAway="home"
                                    labelKey="label.playingposition.group.abbr.halfbacks"
                                ></PosMarker>
                                <PosMarker
                                    pos="C"
                                    homeOrAway="home"
                                    labelKey="label.playingposition.group.abbr.centres"
                                ></PosMarker>
                                <PosMarker
                                    pos="HF"
                                    homeOrAway="home"
                                    labelKey="label.playingposition.group.abbr.halfforwards"
                                ></PosMarker>
                                <PosMarker
                                    pos="F"
                                    homeOrAway="home"
                                    labelKey="label.playingposition.group.abbr.forwards"
                                ></PosMarker>

                                {/* Only show away positions if we're showing both teams */}
                                {teamDisplay === 'both' ? (
                                    <>
                                        <PosMarker
                                            pos="B"
                                            homeOrAway="away"
                                            labelKey="label.playingposition.group.abbr.backs"
                                        ></PosMarker>
                                        <PosMarker
                                            pos="HB"
                                            homeOrAway="away"
                                            labelKey="label.playingposition.group.abbr.halfbacks"
                                        ></PosMarker>
                                        <PosMarker
                                            pos="C"
                                            homeOrAway="away"
                                            labelKey="label.playingposition.group.abbr.centres"
                                        ></PosMarker>
                                        <PosMarker
                                            pos="HF"
                                            homeOrAway="away"
                                            labelKey="label.playingposition.group.abbr.halfforwards"
                                        ></PosMarker>
                                        <PosMarker
                                            pos="F"
                                            homeOrAway="away"
                                            labelKey="label.playingposition.group.abbr.forwards"
                                        ></PosMarker>
                                    </>
                                ) : null}

                                {/* Show home team in home positions */}
                                {teamDisplay === 'home' ||
                                teamDisplay === 'both'
                                    ? homeData?.fieldPlayers?.map((player) => (
                                          <div
                                              className={`team-announcements-field__field-player-wrapper team-announcements-field__field-player-wrapper--home-${player.player.position.toLowerCase()}`}
                                              key={`fieldPlayer-${player.player.player.playerId}}`}
                                          >
                                              <TeamAnnouncementsFieldPlayer
                                                  player={player}
                                                  homeOrAway="home"
                                                  teamAbbr={homeData.teamId}
                                                  showPhoto={
                                                      teamDisplay === 'home'
                                                  }
                                              ></TeamAnnouncementsFieldPlayer>
                                          </div>
                                      ))
                                    : null}

                                {/* Show away team in away positions when all showing */}
                                {teamDisplay === 'both'
                                    ? awayData?.fieldPlayers?.map((player) => (
                                          <div
                                              className={`team-announcements-field__field-player-wrapper team-announcements-field__field-player-wrapper--away-${player.player.position.toLowerCase()}`}
                                              key={`fieldPlayer-${player.player.player.playerId}}`}
                                          >
                                              <TeamAnnouncementsFieldPlayer
                                                  player={player}
                                                  homeOrAway="away"
                                                  teamAbbr={awayData.teamId}
                                                  showPhoto={false}
                                              ></TeamAnnouncementsFieldPlayer>
                                          </div>
                                      ))
                                    : null}

                                {/* Show away team in home positions when showing only away */}
                                {teamDisplay === 'away'
                                    ? awayData?.fieldPlayers?.map((player) => (
                                          <div
                                              className={`team-announcements-field__field-player-wrapper team-announcements-field__field-player-wrapper--home-${player.player.position.toLowerCase()}`}
                                              key={`fieldPlayer-${player.player.player.playerId}}`}
                                          >
                                              <TeamAnnouncementsFieldPlayer
                                                  player={player}
                                                  homeOrAway="away"
                                                  teamAbbr={awayData.teamId}
                                                  showPhoto={true}
                                              ></TeamAnnouncementsFieldPlayer>
                                          </div>
                                      ))
                                    : null}
                            </div>
                        </div>
                        <div className="team-announcements-field__interchange">
                            {homeData.interchanges || awayData.interchanges ? (
                                <>
                                    <h3 className="team-announcements-field__header">
                                        {PULSE.I18N.lookup(
                                            'label.playingposition.group.interchanges'
                                        )}
                                    </h3>
                                    <div className="team-announcements-field__list team-announcements-field__list--interchange">
                                        <TeamAnnouncementsFieldGroup
                                            playerGroup={groupedInterchanges}
                                            teamDisplay={teamDisplay}
                                            homeData={homeData}
                                            awayData={awayData}
                                        />
                                    </div>
                                </>
                            ) : null}
                            {PULSE.app.common.CONSTANTS.DISPLAY_MATCH_STATUSES.includes(
                                matchStatus
                            ) &&
                            (homeData.substitutes || awayData.substitutes) ? (
                                <>
                                    <h3 className="team-announcements-field__header">
                                        {PULSE.I18N.lookup(
                                            'label.playingposition.group.substitutes'
                                        )}
                                    </h3>
                                    <div className="team-announcements-field__list team-announcements-field__list--substitutes">
                                        <TeamAnnouncementsFieldGroup
                                            playerGroup={groupedSubstitutes}
                                            teamDisplay={teamDisplay}
                                            homeData={homeData}
                                            awayData={awayData}
                                        />
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                    {homeData.emergencies || awayData.emergencies ? (
                        <div className="team-announcements-field__emergencies">
                            <h3 className="team-announcements-field__header">
                                {PULSE.I18N.lookup(
                                    'label.playingposition.group.emergencies'
                                )}
                            </h3>
                            <div className="team-announcements-field__emergencies-list-wrapper">
                                <div className="team-announcements-field__list team-announcements-field__list--emergencies">
                                    <TeamAnnouncementsFieldGroup
                                        playerGroup={groupedEmergencies}
                                        teamDisplay={teamDisplay}
                                        homeData={homeData}
                                        awayData={awayData}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

TeamAnnouncementsField.propTypes = {
    homeData: PropTypes.object,
    awayData: PropTypes.object,
    matchStatus: PropTypes.string,
    initialTeamDisplay: PropTypes.oneOf(['both', 'home', 'away']),
    teamDisplay: PropTypes.oneOf(['both', 'home', 'away']),
    updateTeamDisplay: PropTypes.func
};
