import produce from 'immer';

// @ts-ignore
import { PerformanceChartState } from '../../types';
// eslint-disable-next-line no-unused-vars
import { Action } from '../../../../types';
import {
    SET_PERFORMANCE_CHART_STATS,
    SET_PERFORMANCE_CHART_SEASONS_STATS,
    SET_PERFORMANCE_CHART_STATS_REQUEST,
    SET_NAV_ITEM,
    REMOVE_PERFORMANCE_CHART_STATS_REQUEST,
    SET_DATA_TYPE,
    SET_PLAYER_TWO_CHART_STATS,
    SET_PLAYER_TWO_CHART_SEASONS_STATS
} from '../actions';
import {
    setNavItem,
    setDataType,
    setRequest,
    removeRequest
} from '../../shared/reducer';
import { DATA_TYPE } from 'widgets/statspro/js/components/common/config/data-type';
import { setPerformanceChartStats } from './set-performance-chart-stats';
import { setPerformanceChartSeasonsStats } from './set-performance-chart-seasons-stats';
import { setPlayerTwoChartStats } from './set-player-two-chart-stats';
import { setPlayerTwoChartSeasonsStats } from './set-player-two-chart-seasons-stats';

/**
 * @type {PerformanceChartState}
 */
const initialState = {
    nav: {
        category: 'AFL Fantasy Points',
        competition: {
            id: null
        },
        season: {
            id: -1
        },
        round: {
            id: null,
            number: null
        }
    },
    dataType: {
        type: DATA_TYPE.TOTALS
    },
    requests: [],
    data: {
        season: {},
        yearly: []
    },
    playerTwoData: {
        season: {},
        yearly: []
    }
};

/**
 * @param {PerformanceChartState} state - The Redux state.
 * @param {Action} action - The Redux action.
 * @returns {PerformanceChartState} The updated Redux state.
 */
export const performanceChartReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_PERFORMANCE_CHART_STATS:
                setPerformanceChartStats(draft, action.payload);
                break;
            case SET_PERFORMANCE_CHART_SEASONS_STATS:
                setPerformanceChartSeasonsStats(draft, action.payload);
                break;
            case SET_PLAYER_TWO_CHART_STATS:
                setPlayerTwoChartStats(draft, action.payload);
                break;
            case SET_PLAYER_TWO_CHART_SEASONS_STATS:
                setPlayerTwoChartSeasonsStats(draft, action.payload);
                break;
            case SET_PERFORMANCE_CHART_STATS_REQUEST:
                setRequest(draft, action.payload);
                break;
            case REMOVE_PERFORMANCE_CHART_STATS_REQUEST:
                removeRequest(draft, action.payload);
                break;
            case SET_NAV_ITEM:
                setNavItem(draft, action.payload);
                break;
            case SET_DATA_TYPE:
                setDataType(draft, action.payload);
                break;
            default:
                draft;
        }
    });
};
