import React, { useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

export const PlayerSelectionModal = ({
    icon,
    title,
    isVisible,
    hideModal,
    children
}) => {
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === PULSE.app.common.CONSTANTS.KEY_CODES.ESCAPE) {
                hideModal();
            }
        };
        window.addEventListener('keydown', handleEsc);

        document.body.style.overflowY = 'hidden';

        return () => {
            window.removeEventListener('keydown', handleEsc);
            document.body.style.overflowY = 'auto';
        };
    }, []);

    return (
        <>
            <div
                className={classnames('player-selection__overlay', {
                    'is-open': isVisible
                })}
            ></div>
            <div
                className={classnames('player-selection__modal', {
                    'is-open': isVisible
                })}
            >
                <div className="player-selection__modal-header">
                    <div className="player-selection__modal-title-wrapper">
                        <SvgIcon
                            icon={icon}
                            className="player-selection__modal-header-icon"
                            folder="elements"
                            subfolder="stats"
                        />
                        <h2 className="player-selection__modal-header-title">
                            {title}
                        </h2>
                    </div>
                    <button
                        data-modal-action="close"
                        className="player-selection__modal-close"
                        onClick={hideModal}
                    >
                        <SvgIcon
                            icon="close-hover"
                            className="player-selection__modal-close-icon"
                        />
                        <span className="u-screen-reader">
                            {getTranslation('label.close')}
                        </span>
                    </button>
                </div>
                <div className="player-selection__modal-content">
                    {children}
                </div>
            </div>
        </>
    );
};

PlayerSelectionModal.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};
