import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { Header } from '../components/Header';

import { configureReduxQuerySync } from '../utils/configure-redux-query-sync';
import { usePathname } from '../utils/use-pathname';

/**
 * 1. Recompose Redux Query Sync whenever the URL changes to ensure the values
 *    are maintained whenever the tabs are changed.
 *
 * @param {any} props - Props.
 * @returns {JSX.Element} Component.
 */
export const StatsProHeaderProvider = ({
    store,
    data: { baseRoute, compseasonId, showCompare }
}) => {
    const pathname = usePathname();
    const showCompareBool = showCompare === 'true' ? true : false;

    // [1]
    useEffect(() => {
        const sync = configureReduxQuerySync(store);
        return () => sync();
    }, [pathname]);

    return (
        <Provider store={store}>
            <BrowserRouter basename={baseRoute}>
                <Switch>
                    <Route path="/:active_tab?">
                        <Header
                            compseason={compseasonId}
                            showCompare={showCompareBool}
                        />
                    </Route>
                </Switch>
            </BrowserRouter>
        </Provider>
    );
};

StatsProHeaderProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
};
