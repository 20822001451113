import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const NavItem = ({
    path,
    icon,
    translationLabel,
    shortTranslationLabel,
    tabName
}) => {
    // @ts-ignore
    const text = PULSE.I18N.lookup(translationLabel);
    const shortText = shortTranslationLabel
        ? PULSE.I18N.lookup(shortTranslationLabel)
        : null;

    return (
        <li>
            <NavLink
                exact
                to={path}
                className="stats-nav-item"
                activeClassName="active"
                title={text}
                data-tab={tabName}
            >
                <div className="stats-nav-item__content-wrapper">
                    <SvgIcon
                        icon={icon}
                        className="stats-nav-item__icon"
                        folder="elements"
                        subfolder="stats"
                    />
                    {shortText ? (
                        <>
                            <span className="u-hide-until-desktop">{text}</span>
                            <span className="u-hide-from-desktop">
                                {shortText}
                            </span>
                        </>
                    ) : (
                        text
                    )}
                </div>
            </NavLink>
        </li>
    );
};

NavItem.propTypes = {
    path: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    translationLabel: PropTypes.string.isRequired,
    shortTranslationLabel: PropTypes.string,
    tabName: PropTypes.string.isRequired
};
