import { combineReducers } from 'redux';
import { navRootSaga } from './sagas';
import { navReducer } from './reducers';

const sagas = [navRootSaga];

const rootReducer = combineReducers({
    nav: navReducer
});

// @ts-expect-error
if (window.PULSE.app.redux) {
    // @ts-expect-error
    window.PULSE.app.redux.sagas.eventsListing = { root: sagas };
    // @ts-expect-error
    window.PULSE.app.redux.reducers.eventsListing = { root: rootReducer };
} else {
    // @ts-expect-error
    window.PULSE.app.redux = {
        reducers: {
            eventsListing: {
                root: rootReducer
            }
        },
        sagas: {
            eventsListing: {
                root: sagas
            }
        }
    };
}
