import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import { PlaceholderPlayer } from './PlaceholderPlayer';
import { Player } from './Player';
import { SvgIcon } from 'common/react/components/SvgIcon';

// Stats navbar height changes on tablet, so set it dynamically
const TOP_MARGIN = window.matchMedia(
    `(min-width:${PULSE.app.measurements.tablet}px)`
).matches
    ? '-160px'
    : '-112px';

/**
 * @param root0
 * @param root0.seasonId
 * @param root0.playerIds
 * @returns {JSX.Element} Component.
 */
export const CompareHeader = ({ seasonId, playerIds }) => {
    const isAFLW = PULSE.app.common.match.getCompAbbr(seasonId) === 'AFLW';
    const { ref, inView } = useInView({
        rootMargin: `${TOP_MARGIN} 0px 0px 0px`
    });

    return (
        <section
            className={`compare-header ${!inView ? 'is-stuck' : ''}`}
            ref={ref}
        >
            <div className="compare-header__bg">
                {playerIds[1] ? (
                    <Player
                        seasonId={seasonId}
                        playerId={playerIds[1]}
                        playerNumber="1"
                        isAFLW={isAFLW}
                    />
                ) : (
                    <PlaceholderPlayer playerNumber="1" />
                )}
                <SvgIcon
                    icon="versus-white"
                    className="compare-header__versus-icon"
                    folder="elements"
                    subfolder="stats"
                />
                {playerIds[2] ? (
                    <Player
                        seasonId={seasonId}
                        playerId={playerIds[2]}
                        playerNumber="2"
                        isAFLW={isAFLW}
                    />
                ) : (
                    <PlaceholderPlayer playerNumber="2" />
                )}
            </div>
        </section>
    );
};

CompareHeader.propTypes = {
    seasonId: PropTypes.string.isRequired,
    playerIds: PropTypes.object.isRequired
};
