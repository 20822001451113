import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { STATS_LEADERS_CATEGORY_COLUMN_MAP } from '../../stats-leaders/config/category-column-map';
import { StatsCard } from '../../common/StatsCard';
import { getTranslation } from 'common/react/utils/translations';
import { STATS_COLUMNS } from 'widgets/statspro/js/components/common/config/columns';
import { TeamWatermarkBackground } from '../../TeamWatermarkBackground';

import { PlayerProfileLink } from '../../common/PlayerProfileLink';
import { ConditionalWrapper } from 'common/react/components/ConditionalWrapper';

/**
 * @param {object} props - Props.
 * @param {Array} props.players - Player details.
 * @param {string} props.compseasonId - Compseason ID.
 * @param {boolean} props.isAFLW - isAFLW
 * @param props.external
 * @returns {JSX.Element} Component.
 */
export const SeasonLeadersCard = ({ players, compseasonId, isAFLW }) => {
    const [firstPlayer, ...otherPlayers] = players;
    const fullTableInfo =
        STATS_LEADERS_CATEGORY_COLUMN_MAP[firstPlayer.category];
    const isSeasonAverage = firstPlayer.type === 'SEASON_AVERAGE';
    const translations = {
        category: STATS_COLUMNS[fullTableInfo.column].name,
        perGame: getTranslation('label.statspro.perGame'),
        fullTable: getTranslation('label.statspro.fullTable')
    };

    const linkToFullTable = PULSE.app.common.url.getStatsLeadersURL(
        fullTableInfo.column,
        parseInt(compseasonId),
        isSeasonAverage ? 'averages' : 'totals',
        isAFLW
    );

    return (
        <StatsCard>
            <ConditionalWrapper
                condition={firstPlayer?.player?.playerId}
                wrapper={(children) => (
                    <PlayerProfileLink
                        classes="stats-card-table__player-link"
                        player={{
                            playerId: firstPlayer.player.playerId,
                            firstName: firstPlayer.player.givenName,
                            surname: firstPlayer.player.surname
                        }}
                        isAFLW={isAFLW}
                    >
                        {children}
                    </PlayerProfileLink>
                )}
            >
                <div className={'stats-card-featured-player'}>
                    <div className="stats-card-featured-player__inner-container">
                        <TeamWatermarkBackground
                            teamAbbreviation={firstPlayer.team.teamAbbr}
                            className="stats-card-featured-player__bg stats-card-featured-player__bg--no-border-radius-bottom"
                        />
                        <div className="stats-card-featured-player__category-container">
                            <p className="stats-card-featured-player__category">
                                {translations.category}
                            </p>
                        </div>
                        <div className="stats-card-featured-player__details">
                            <p className="stats-card-featured-player__value">
                                {firstPlayer.value}
                                {isSeasonAverage && (
                                    <span> {translations.perGame}</span>
                                )}
                            </p>
                            <div className="stats-card-featured-player__details-bottom">
                                <div className="stats-card-featured-player__name">
                                    {firstPlayer.player.givenName}{' '}
                                    <span>{firstPlayer.player.surname}</span>
                                </div>
                            </div>
                        </div>
                        <div className="stats-card-featured-player__photo-container">
                            <img
                                className="stats-card-featured-player__image"
                                alt={`${getTranslation(
                                    'label.stats.headshot.alt'
                                )} ${firstPlayer.player.givenName} ${
                                    firstPlayer.player.surname
                                }`}
                                src={PULSE.app.common.image.migratePlayerPhotoURL(
                                    firstPlayer.player.photoURL,
                                    0.6
                                )}
                            />
                        </div>
                    </div>
                </div>
            </ConditionalWrapper>

            <div className="stats-card-table">
                {otherPlayers.map((player, i) => {
                    return (
                        <div
                            key={i}
                            className={
                                'stats-card-table__player team-' +
                                player.team.teamAbbr.toLowerCase()
                            }
                        >
                            <div className="stats-card-table__photo-container">
                                <img
                                    className="stats-card-table__image"
                                    alt={`${getTranslation(
                                        'label.stats.headshot.alt'
                                    )} ${player.player.givenName} ${
                                        player.player.surname
                                    }`}
                                    src={PULSE.app.common.image.migratePlayerPhotoURL(
                                        player.player.photoURL
                                    )}
                                />
                            </div>
                            {player?.player?.playerId ? (
                                <PlayerProfileLink
                                    player={{
                                        firstName: player.player.givenName,
                                        surname: player.player.surname,
                                        playerId: player.player.playerId
                                    }}
                                    isAFLW={isAFLW}
                                    classes="stats-card-table__name"
                                >
                                    {player.player.givenName}{' '}
                                    <span>{player.player.surname}</span>
                                </PlayerProfileLink>
                            ) : (
                                <>
                                    {player.player.givenName}{' '}
                                    <span>{player.player.surname}</span>
                                </>
                            )}

                            <div className="stats-card-table__value">
                                {player.value}
                            </div>
                        </div>
                    );
                })}

                <div className="stats-card-table__link-container">
                    <a
                        href={`${linkToFullTable}`}
                        className="stats-card-table__link"
                        title={translations.fullTable}
                    >
                        {translations.fullTable}
                        <span className="stats-card-table__link-icon">
                            <SvgIcon icon="arrow-right" className="icon" />
                        </span>
                    </a>
                </div>
            </div>
        </StatsCard>
    );
};

SeasonLeadersCard.propTypes = {
    players: PropTypes.array.isRequired,
    compseasonId: PropTypes.string.isRequired,
    isAFLW: PropTypes.bool,
    external: PropTypes.string.isRequired
};
