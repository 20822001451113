import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { roundByRoundNavSelector } from 'store/modules/brownlow-tracker/round-by-round/selectors/nav-selector';
import { useSelector } from 'react-redux';

export const NavItem = ({ value, onClick }) => {
    const nav = useSelector(roundByRoundNavSelector);
    const isActive = nav.round === value;

    const label = value === 0 ? 'OR' : value;

    return (
        <li
            className={classnames(
                'brownlow-round-by-round__round-list-nav-item',
                { 'is-active': isActive }
            )}
        >
            <button
                className="brownlow-round-by-round__round-list-nav-button"
                onClick={(event) => onClick(event, value)}
                tabIndex={0}
            >
                {label}
            </button>
        </li>
    );
};

NavItem.propTypes = {
    value: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired
};
