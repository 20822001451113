import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';

const { app } = PULSE;
const { MIS_TOKEN_HEADER_PROP } = app.common.CONSTANTS;

export const misBaseQuery = ({ baseUrl }) =>
    fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().token;
            if (token) {
                headers.set(MIS_TOKEN_HEADER_PROP, token);
                return headers;
            }
            throw new Error('Token not available, cancelling request');
        }
    });
