import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Select } from 'common/react/components/select';
import { getTranslation } from 'common/react/utils/translations';
import { UPDATE_NAV_ITEM } from 'store/modules/brownlow-tracker/leaderboard/actions';
import { leaderboardNavSelector } from 'store/modules/brownlow-tracker/leaderboard/selectors';

export const SeasonNavItem = ({ seasonsToShow }) => {
    const dispatch = useDispatch();
    const nav = useSelector(leaderboardNavSelector);
    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );

    const filteredSeasons = useMemo(() => {
        const seasonsToShowArray = seasonsToShow
            ? seasonsToShow.replace(/ /g, '').split(',')
            : '';
        if (seasonsToShowArray?.length) {
            return seasons.filter((season) => {
                return seasonsToShow.includes(season.providerId);
            });
        }

        return [];
    }, [seasons, seasonsToShow]);

    return (
        <Select
            items={filteredSeasons}
            itemToString={(season) => season?.name ?? ''}
            initialSelectedItem={
                filteredSeasons.length && nav.season.id
                    ? [filteredSeasons.find((s) => s.id === nav.season.id)]
                    : ''
            }
            activeItem={
                filteredSeasons.length && nav.season.id
                    ? filteredSeasons.find((s) => s.id === nav.season.id)
                    : ''
            }
            isReadyToRender={() => filteredSeasons?.length && nav.season.id}
            label={getTranslation('label.stats.season')}
            onSelectedItemChange={({ selectedItem }) => {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'season',
                        value: { id: selectedItem.id }
                    })
                );
                dispatch(
                    // @ts-expect-error
                    window.PULSE.app.redux.actions.rounds.API__FETCH_ROUNDS.request(
                        selectedItem.id
                    )
                );
            }}
        />
    );
};

SeasonNavItem.propTypes = {
    seasonsToShow: PropTypes.string
};
