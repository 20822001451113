import React from 'react';
import PropTypes from 'prop-types';
import { StatsCard } from '../../common/StatsCard';
import { STATS_LEADERS_CATEGORY_COLUMN_MAP } from '../../stats-leaders/config/category-column-map';
import { STATS_COLUMNS } from 'widgets/statspro/js/components/common/config/columns';
import { getTranslation } from 'common/react/utils/translations';
import SwiperCore, { EffectCoverflow } from 'swiper';
import { TeamWatermarkBackground } from '../../TeamWatermarkBackground';
import { PlayerProfileLink } from '../../common/PlayerProfileLink';
import { ConditionalWrapper } from 'common/react/components/ConditionalWrapper';

SwiperCore.use([EffectCoverflow]);

/**
 * @param {object} props - Props.
 * @param {object} props.player - Player details.
 * @param {string} props.type - Category of slider.
 * @param {boolean} props.isAFLW - isAFLW
 * @returns {JSX.Element} Component.
 */
// @ts-ignore
export const LastRoundHighsCard = ({ player, type, isAFLW }) => {
    const fullTableInfo = STATS_LEADERS_CATEGORY_COLUMN_MAP[player.category];
    const translations = {
        category: STATS_COLUMNS[fullTableInfo.column].name,
        averageAbbr: getTranslation('label.statspro.averageAbbr'),
        roundAbbr: getTranslation('label.statspro.roundAbbr')
    };

    return (
        <StatsCard>
            <ConditionalWrapper
                condition={player?.player.playerId}
                wrapper={(children) => (
                    <PlayerProfileLink
                        classes="stats-card-table__player-link"
                        isAFLW={isAFLW}
                        player={{
                            playerId: player?.player?.playerId,
                            firstName: player?.player?.givenName,
                            surname: player?.player?.surname
                        }}
                    >
                        {children}
                    </PlayerProfileLink>
                )}
            >
                <div className="stats-card-featured-player">
                    <div className="stats-card-featured-player__inner-container">
                        <TeamWatermarkBackground
                            teamAbbreviation={player.team.teamAbbr}
                            className="stats-card-featured-player__bg"
                        />
                        <div className="stats-card-featured-player__category-container">
                            <p className="stats-card-featured-player__category">
                                {translations.category}
                            </p>
                        </div>
                        <div className="stats-card-featured-player__details">
                            <p className="stats-card-featured-player__value">
                                {player.value}
                            </p>
                            {type === 'season' && !!player.seasonAverage && (
                                <span className="stats-card-featured-player__average-value">
                                    <span className="stats-card-featured-player__average-value-text">
                                        {translations.averageAbbr}.{' '}
                                    </span>
                                    {player.seasonAverage}
                                </span>
                            )}
                            <div className="stats-card-featured-player__details-bottom">
                                <div className="stats-card-featured-player__name">
                                    {player.player.givenName}{' '}
                                    <span>{player.player.surname}</span>
                                </div>
                                <p className="stats-card-featured-player__match-label">
                                    {player.matchLabel
                                        .split(' ')
                                        .map((x) => {
                                            return x === 'v'
                                                ? x
                                                : x.toUpperCase();
                                        })
                                        .join(' ')}
                                </p>
                            </div>
                        </div>
                        <div className="stats-card-featured-player__photo-container">
                            <img
                                className="stats-card-featured-player__image"
                                alt={`${getTranslation(
                                    'label.stats.headshot.alt'
                                )} ${player.player.givenName} ${
                                    player.player.surname
                                }`}
                                src={PULSE.app.common.image.migratePlayerPhotoURL(
                                    player.player.photoURL,
                                    false,
                                    360,
                                    true
                                )}
                            />
                        </div>
                    </div>
                </div>
            </ConditionalWrapper>
        </StatsCard>
    );
};

LastRoundHighsCard.propTypes = {
    player: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    isAFLW: PropTypes.bool
};
