import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getTranslation } from 'common/react/utils/translations';

export const PlayerModalVideoPlayer = ({
    anchorName,
    videoItem,
    policyKey,
    playerId,
    accountId,
    isModalOpen
}) => {
    const playerWidgetRef = useRef(null);
    const videoJsElement = useRef(null);

    /**
     *  Runs on every render to check if there are any video players
     *  that haven't been disposed, and disposes them before we create
     *  the new one.
     */
    if (videoJsElement?.current !== null) {
        bc(videoJsElement?.current?.querySelector('video')).dispose();
    }

    useEffect(() => {
        if (isModalOpen) {
            new PULSE.app.common.Video(playerWidgetRef.current, [], {});
        }
    }, [isModalOpen]);

    return (
        <div
            className="modal-centered__draft-player-video inline-player js-video-container is-active"
            data-widget="video-player"
            ref={playerWidgetRef}
        >
            <div className="inline-player__player-container">
                {isModalOpen ? (
                    <video-js
                        ref={videoJsElement}
                        id={`draft-prospect-player-modal_${anchorName}`}
                        data-video-id={videoItem.mediaId}
                        data-account={accountId}
                        data-policy-key={policyKey}
                        data-player={playerId}
                        class={`inline-player__video video-js vjs-16-9 js-video-player`}
                        controls
                        playsinline
                        poster={videoItem?.thumbnailUrl}
                        // Set by translation label as component_name can be editor-controlled
                        componentName={getTranslation(
                            'label.draftTracker.modal'
                        )}
                        mediaTitle={
                            PULSE.app.common.CONSTANTS.TRACKING.VALUES
                                .DRAFT_TRACKER.MEDIA_TITLE
                        }
                    ></video-js>
                ) : null}
            </div>
        </div>
    );
};

PlayerModalVideoPlayer.propTypes = {
    pulsePlayerId: PropTypes.number,
    accountId: PropTypes.string.isRequired,
    policyKey: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
    anchorName: PropTypes.string,
    videoItem: PropTypes.object,
    isModalOpen: PropTypes.bool
};
