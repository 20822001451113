/**
 * @param {any} draft - Draft state.
 * @param {any} payload - Action payload.
 */
export const setBenchmarking = (draft, payload) => {
    draft.benchmarking = {
        ...draft.benchmarking,
        ...payload
    };
};
