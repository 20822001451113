import { useSelector } from 'react-redux';
import { getSingleSetting } from 'common/store/modules/site-settings/init';

/**
 * A custom hook to use when you want to access the value of a single website
 * setting. If you are working outside of react, have a look at
 * `site-settings-helpers.js` instead!
 *
 * @param {string} key - The key to access the setting from, should match a
 * top-level config property in SITE_SETTINGS_CONFIG
 * @returns {any} The value of the setting in the store
 */

export const useSingleSiteSetting = (key) => {
    return useSelector((state) => getSingleSetting(state, key));
};
