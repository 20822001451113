import React from 'react';
import { Chip } from 'common/react/components/Chip';
import { getTranslation } from 'common/react/utils/translations';

export const BPLeaderboardTooltip = () => {
    return (
        <div className="chip__list">
            <Chip
                bgColour="rgb(0 64 153 / 8%)"
                symbolColour="rgb(0 64 153)"
                symbol="●"
                label={getTranslation('label.votesPredicted')}
            />
            <Chip
                bgColour="rgb(153 153 153)"
                symbolColour="rgb(255 255 255)"
                symbol="●"
                label={getTranslation('label.ineligible')}
            />
            <Chip
                bgColour="rgb(0 0 0 / 6%)"
                label={getTranslation('label.noVotes')}
            />
            <Chip
                bgColour="rgb(0 0 0 / 12%)"
                label={getTranslation('label.upcoming')}
                hollow
            />
            <Chip
                bgColour="rgb(0 0 0 / 0%)"
                symbolColour="rgb(0 0 0 / 12%)"
                symbol="-"
                label={getTranslation('label.didNotPlay')}
            />
            <Chip
                bgColour="rgb(0 0 0 / 6%)"
                symbolColour="rgb(118 118 118)"
                symbol="B"
                label={getTranslation('label.fixtures.group.title.bye')}
                className="chip__square-symbol--large"
            />
        </div>
    );
};
