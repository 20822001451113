import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { LazyImage } from 'common/react/components/LazyImage';
import { getTranslation } from 'common/react/utils/translations';

import { ShareCopiedPopup } from './ShareCopiedPopup';

export const InformationCardHeader = ({ selectedLocation }) => {
    const imageURL = `${selectedLocation?.promoItem?.onDemandUrl}?width=500`;
    const [showMessage, setShowMessage] = useState(false);
    const venueId = PULSE.core.localStorage.getStorage('mapSelectedVenueId');
    const POPUP_TIMEOUT = 4000;
    const markerLatitude = selectedLocation?.coordinates[0];
    const markerLongitude = selectedLocation?.coordinates[1];
    const directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${markerLatitude},${markerLongitude}`;

    const formShareLink = () => {
        if (window.location.search.indexOf('location') !== -1) {
            return `${
                window.location.origin + window.location.pathname
            }?location=${venueId}&id=${selectedLocation.id}`;
        }
        return `${window.location}?location=${venueId}&id=${selectedLocation.id}`;
    };

    const shareLink = selectedLocation ? formShareLink() : null;
    const shareBtnRef = useRef(null);

    const canShare = navigator.canShare;

    const handleShareClick = () => {
        if (!showMessage) {
            // if device supports https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share

            if (canShare) {
                let data = {
                    url: shareLink
                };

                navigator.share(data);
            } else {
                navigator.clipboard.writeText(shareLink);

                setShowMessage(true);

                setTimeout(() => {
                    setShowMessage(false);
                }, POPUP_TIMEOUT);
            }
        }
    };

    useEffect(() => {
        const shareBtn = shareBtnRef.current;

        shareBtn.addEventListener('click', handleShareClick);

        return () => {
            shareBtn.removeEventListener('click', handleShareClick);
        };
    }, [shareLink]);

    return (
        <>
            <section className="interactive-event-map__location-header js-interactive-event-map__location-header interactive-event-map__section">
                <div className="interactive-event-map__location-top">
                    {selectedLocation?.title && (
                        <div className="interactive-event-map__location-header-content">
                            {selectedLocation?.promoItem ? (
                                <LazyImage
                                    url={imageURL}
                                    useImg={true}
                                    objectFit={false}
                                    cssClass="interactive-event-map__location-img"
                                />
                            ) : null}
                            <div className="interactive-event-map__location-text">
                                <h2 className="interactive-event-map__location-title">
                                    {selectedLocation?.title}
                                </h2>
                                {selectedLocation?.metadata?.map_event_type && (
                                    <p className="interactive-event-map__location-description">
                                        {
                                            selectedLocation.metadata
                                                .map_event_type
                                        }
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="interactive-event-map__location-buttons">
                        <a
                            href={directionsUrl}
                            target="_blank"
                            className="interactive-event-map__button interactive-event-map__button--directions"
                            rel="noreferrer"
                        >
                            <SvgIcon icon="directions" className="icon" />
                            {getTranslation('label.directions')}
                        </a>
                        <button
                            ref={shareBtnRef}
                            className="interactive-event-map__button interactive-event-map__button--share js-interactive-event-map__button--share"
                        >
                            <SvgIcon icon="share" className="icon" />
                            {getTranslation('label.share')}
                        </button>
                    </div>
                </div>
                <button
                    value="Reset filter"
                    className="interactive-event-map__filter-reset interactive-event-map__location-reset js-interactive-event-map__location-reset"
                >
                    <SvgIcon icon="close" className="icon" />
                </button>
            </section>
            <ShareCopiedPopup showMessage={showMessage} />
        </>
    );
};

InformationCardHeader.propTypes = {
    selectedLocation: PropTypes.shape({
        title: PropTypes.string,
        promoItem: PropTypes.shape({
            onDemandUrl: PropTypes.string
        }),
        metadata: PropTypes.shape({
            map_event_type: PropTypes.string // eslint-disable-line camelcase
        }),
        coordinates: PropTypes.array,
        id: PropTypes.number
    })
};
