import React from 'react';
import cx from 'classnames';

import DefaultCellRendererContainer from 'custom-table/js/components/DefaultCell/DefaultCellRendererContainer';

/*
    PositionCellRenderer

    Custom cell renderer for position cell
    Displays position value and position change rather than just value
*/
const PositionCellRenderer = ({ className, value, ...additionalProps }) => {
    const positionChange = PULSE.app.templating.render(
        {
            cssClass: `pos-change`,
            name: `pos-change-${value.positionChange.toLowerCase()}`
        },
        'common.svg-icon'
    );

    const isSorted =
        additionalProps.field.fieldName ===
        additionalProps.customTable.sortBy.fieldName
            ? true
            : false;

    return (
        <DefaultCellRendererContainer
            className={cx(className, 'custom-table__cell--position', {
                'custom-table__cell--sorted': isSorted
            })}
            {...additionalProps}
        >
            <span>{value.position}</span>
            <span
                className="custom-table__position-icon"
                dangerouslySetInnerHTML={{ __html: positionChange }}
            ></span>
        </DefaultCellRendererContainer>
    );
};

export default PositionCellRenderer;
