import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { SelectionCalloutWrapper } from '../components/SelectionCalloutWrapper';

export const SelectionCalloutProvider = ({ store, data }) => {
    return (
        <Provider store={store}>
            <SelectionCalloutWrapper {...data} />
        </Provider>
    );
};
SelectionCalloutProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
