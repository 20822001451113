import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param {string} props.icon - Icon name.
 * @param {string} [props.className] - Class(es).
 * @param {object} [props.style] - Style object.
 * @param {any} props.folder - the icon folder.
 * @param {any} props.subfolder - the icon subfolder.
 * @returns {JSX.Element} Component.
 *
 * @info When used to display SVGs with a folder/subfolder path, the SVGs must include an 'id' property
 *  with the value of "icn" followed by the file name, like so: id="icn-file-name"
 */
export const SvgIcon = ({
    icon,
    className = '',
    style = {},
    folder,
    subfolder
}) => (
    <svg className={className} style={style}>
        {!folder || folder === 'state-league' ? (
            <use
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xlinkHref={`${PULSE.app.environment.cdn}i/svg-output/${
                    folder ? `${folder}/` : ''
                }icons.svg#icn-${icon}`}
            ></use>
        ) : (
            <use
                xmlns="http://www.w3.org/2000/svg"
                href={`${PULSE.app.environment.cdn}i/${folder}/${
                    subfolder ? `${subfolder}/` : ''
                }${icon}.svg#icn-${icon}`}
            ></use>
        )}
    </svg>
);

SvgIcon.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
    style: PropTypes.object,
    folder: PropTypes.any,
    subfolder: PropTypes.any
};
