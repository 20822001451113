import React from 'react';
import PropTypes from 'prop-types';

import { FixtureLinks } from './FixtureLinks';
import { MobileFixtureMediaButton } from '../fixture-media/MobileFixtureMediaButton';

export const ResponsiveFixtureLinks = ({
    match,
    matchStatus,
    metadata,
    broadcastGuideUrl,
    reportLabel
}) => {
    return (
        <div
            className={`fixtures__responsive-links ${
                matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE
                    ? 'fixtures__responsive-links--live'
                    : ''
            }`}
        >
            {matchStatus ===
                PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED && (
                <MobileFixtureMediaButton match={match} />
            )}

            <FixtureLinks
                match={match}
                matchStatus={matchStatus}
                metadata={metadata}
                broadcastGuideUrl={broadcastGuideUrl}
                reportLabel={reportLabel}
            />
        </div>
    );
};

ResponsiveFixtureLinks.propTypes = {
    match: PropTypes.object,
    matchStatus: PropTypes.string,
    metadata: PropTypes.object,
    broadcastGuideUrl: PropTypes.string,
    reportLabel: PropTypes.string
};
