import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders the milestones data.
 *
 * @param {object} props - The props
 * @param {Array} props.homeMilestones - The home milestones data.
 * @param {Array} props.awayMilestones - The away milestones data.
 * @param {string} props.teamDisplay - Which team to display (both, home or away).
 * @returns {React.JSX.Element} - The rendered component
 */
export const TeamAnnouncementsFieldMilestones = ({
    homeMilestones,
    awayMilestones,
    teamDisplay
}) => {
    const milestonesArray =
        teamDisplay === 'home'
            ? homeMilestones
            : teamDisplay === 'away'
            ? awayMilestones
            : null;

    return (
        <div className="team-announcements-field__footer-section team-announcements-field__footer-section--milestones">
            {(teamDisplay === 'home' || teamDisplay === 'away') && (
                <span className="team-announcements-field__footer-section-label">
                    Milestones
                </span>
            )}

            {(teamDisplay === 'home' || teamDisplay === 'away') && (
                <span className="team-announcements-field__footer-section-values team-announcements-field__footer-section-values">
                    {milestonesArray?.length > 0 ? (
                        milestonesArray.map((milestone, index) => (
                            <span key={`subIn_${index}`}>
                                {milestone?.player?.playerName?.givenName}{' '}
                                {milestone?.player?.playerName?.surname}
                                {milestone?.milestoneEvent
                                    ? ` (${milestone?.milestoneEvent})`
                                    : null}
                                {index + 1 !== milestonesArray.length
                                    ? `, `
                                    : null}{' '}
                            </span>
                        ))
                    ) : (
                        <span>-</span>
                    )}
                </span>
            )}

            {teamDisplay === 'both' && (
                <>
                    <span className="team-announcements-field__footer-section-values team-announcements-field__footer-section-values--home">
                        {homeMilestones?.length > 0 ? (
                            homeMilestones.map((milestone, index) => (
                                <span key={`subIn_${index}`}>
                                    {milestone?.player?.playerName?.givenName}{' '}
                                    {milestone?.player?.playerName?.surname}
                                    {milestone?.milestoneEvent
                                        ? ` (${milestone?.milestoneEvent})`
                                        : null}
                                    {index + 1 !== homeMilestones.length
                                        ? `, `
                                        : null}{' '}
                                </span>
                            ))
                        ) : (
                            <span>-</span>
                        )}
                    </span>

                    <span className="team-announcements-field__footer-section-label">
                        Milestones
                    </span>

                    <span className="team-announcements-field__footer-section-values team-announcements-field__footer-section-values--away">
                        {awayMilestones?.length > 0 ? (
                            awayMilestones.map((milestone, index) => (
                                <span key={`subIn_${index}`}>
                                    {milestone?.player?.playerName?.givenName}{' '}
                                    {milestone?.player?.playerName?.surname}
                                    {milestone?.milestoneEvent
                                        ? ` (${milestone?.milestoneEvent})`
                                        : null}
                                    {index + 1 !== awayMilestones.length
                                        ? `, `
                                        : null}{' '}
                                </span>
                            ))
                        ) : (
                            <span>-</span>
                        )}
                    </span>
                </>
            )}
        </div>
    );
};

TeamAnnouncementsFieldMilestones.propTypes = {
    homeMilestones: PropTypes.array,
    awayMilestones: PropTypes.array,
    teamDisplay: PropTypes.oneOf(['both', 'home', 'away'])
};
