import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param {number} props.seasonAverage - Season average value.
 * @param {number} props.careerAverage - Career average value.
 * @param {string} props.teamAbbr - Team abbreviation.
 * @returns {JSX.Element} Component.
 */
export const KeyStatsAndPerformanceGraph = ({
    seasonAverage,
    careerAverage,
    teamAbbr
}) => {
    const seasonPercentage =
        seasonAverage > careerAverage
            ? 100
            : ((seasonAverage / careerAverage) * 100).toFixed(2);
    const careerPercentage =
        careerAverage > seasonAverage
            ? 100
            : ((careerAverage / seasonAverage) * 100).toFixed(2);

    return (
        <div className="key-stats-and-performance__average-stats-graph">
            <div
                className={`key-stats-and-performance__average-stats-graph-bar ${
                    seasonAverage >= careerAverage ? teamAbbr : ''
                }`}
                style={{ maxHeight: seasonPercentage + '%' }}
            ></div>
            <div
                className={`key-stats-and-performance__average-stats-graph-bar ${
                    careerAverage >= seasonAverage ? teamAbbr : ''
                }`}
                style={{ maxHeight: careerPercentage + '%' }}
            ></div>
        </div>
    );
};

KeyStatsAndPerformanceGraph.propTypes = {
    seasonAverage: PropTypes.number.isRequired,
    careerAverage: PropTypes.number.isRequired,
    teamAbbr: PropTypes.string.isRequired
};
