import React from 'react';
import PropTypes from 'prop-types';

import { MatchStatusTime } from './MatchStatusTime';
import { ErrorBoundary } from 'react-error-boundary';

export const MatchStatusText = ({
    match,
    matchStatuses,
    byes,
    utcStartTime
}) => {
    let matchStatus = match
        ? PULSE.app.common.match.getMatchStatus(
              match.status ? match.status : match.match.status
          )
        : '';

    if (byes && byes.length) {
        return <>{PULSE.I18N.lookup('label.fixtureBar.byes')}</>;
    } else if (matchStatus === matchStatuses.LIVE) {
        return (
            <>
                <ErrorBoundary
                    fallback={
                        <div>
                            {luxon.DateTime.fromISO(utcStartTime, {
                                zone: 'utc'
                            })
                                .toLocal()
                                .toFormat('ccc h:mm a')}
                        </div>
                    }
                >
                    <MatchStatusTime matchInfo={match}></MatchStatusTime>
                </ErrorBoundary>
            </>
        );
    } else if (matchStatus === matchStatuses.UPCOMING) {
        return luxon.DateTime.fromISO(utcStartTime, { zone: 'utc' })
            .toLocal()
            .toFormat('ccc h:mm a');
    }

    const matchStatusText =
        PULSE.app.common.match.returnMatchStatusSuffix(matchStatus);

    return <>{PULSE.I18N.lookup(`label.fixtures.${matchStatusText}`)}</>;
};

MatchStatusText.propTypes = {
    match: PropTypes.object,
    matchStatuses: PropTypes.object,
    byes: PropTypes.array,
    utcStartTime: PropTypes.string
};
