import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { PlayerProfileBio } from '../components/player-profile-bio';

export const PlayerProfileBioProvider = ({ store, data }) => (
    <Provider store={store}>
        <PlayerProfileBio {...data} />
    </Provider>
);

PlayerProfileBioProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
};
