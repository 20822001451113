import { Navigation, A11y } from 'swiper';

export const sliderSettings = (name) => {
    return {
        modules: [Navigation, A11y],
        navigation: {
            nextEl: '.swiper-button-next-unique-' + name,
            prevEl: '.swiper-button-prev-unique-' + name
        },
        slidesPerView: 'auto',
        watchSlidesProgress: true
    };
};
