import { put } from 'redux-saga/effects';
import { setFavouritePlayer } from '../actions';

/**
 * @typedef {object} UpdateFavouritePlayerAction
 * @property {string} type
 * @property {object} payload
 * @property {string} payload.id
 * @property {string} payload.providerId
 */

/**
 * @param {UpdateFavouritePlayerAction} action - The Redux action.
 */
export function* updateFavouritePlayer(action) {
    yield put(setFavouritePlayer(action.payload));
}
