import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { InformationCategoryHeader } from './InformationCategoryHeader';
import { InformationCard } from './InformationCard';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { MapLocationSelector } from './MapLocationSelector';
import { getCategory, filterItems } from '../util';

export const InformationBottomDrawer = ({
    selectedLocation,
    googlePlacesData,
    selectedCategory,
    locations,
    timezone
}) => {
    const [selectedVenue, setSelectedVenue] = useState({
        name: PULSE.core.localStorage.getStorage('mapSelectedVenue') || null,
        imgRef: null
    });
    const [locationsMobile, setLocationsMobile] = useState(locations);

    useEffect(() => {
        const mapContainer = document.querySelector('.interactive-event-map');
        const startingVenue = mapContainer.dataset.startingVenue;
        let additionalLocationsArray = [];

        if (selectedVenue.name === startingVenue) {
            setLocationsMobile(PULSE.mapPins);
        }

        PULSE.additionalLocations.forEach((additionalVenue) => {
            if (selectedVenue.name === additionalVenue.title) {
                additionalVenue.items.forEach((venueMarker) => {
                    additionalLocationsArray.push(venueMarker.response);
                });

                setLocationsMobile(additionalLocationsArray);
            }
        });
    }, [selectedVenue]);

    return (
        <div className="cupertino-pane">
            {selectedCategory === null && selectedLocation === null ? (
                <MapLocationSelector setVenue={setSelectedVenue} />
            ) : null}

            <InformationCard
                selectedLocation={selectedLocation}
                googlePlacesData={googlePlacesData}
                timezone={timezone}
            />

            <InformationCategoryHeader
                selectedCategory={selectedCategory}
                selectedLocation={selectedLocation}
            />

            <ul
                className={`interactive-event-map__information-list js-interactive-event-map__information-list ${
                    selectedLocation !== null ? 'u-hide' : ''
                }`}
            >
                {locationsMobile.map((location) => (
                    <li
                        className={`interactive-event-map__information-item js-interactive-event-map__information-item ${filterItems(
                            getCategory(location).category,
                            selectedCategory
                        )}`}
                        key={location.id}
                        data-item-id={location.id}
                    >
                        <div
                            className={`interactive-event-map__information-item-icon interactive-event-map__information-item-icon--${
                                getCategory(location).category
                            } `}
                        >
                            <SvgIcon
                                icon={getCategory(location).filterIcon}
                                className="icon"
                                folder="elements"
                                subfolder="events-and-locations"
                            />
                        </div>
                        <div className="interactive-event-map__information-item-copy">
                            {location.title && (
                                <h3 className="interactive-event-map__information-item-title">
                                    {location.title}
                                </h3>
                            )}
                            {location.metadata?.map_event_type && (
                                <p className="interactive-event-map__information-item-description">
                                    {location.metadata?.map_event_type}
                                </p>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

InformationBottomDrawer.propTypes = {
    selectedLocation: PropTypes.object,
    googlePlacesData: PropTypes.object,
    selectedCategory: PropTypes.string,
    locations: PropTypes.array,
    timezone: PropTypes.string
};
