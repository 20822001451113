import { STATS_CATEGORY_IDS } from 'widgets/statspro/js/components/common/config/performance-stats';

export const composeCareerData = (playerData, nav) => {
    let composedData = [];

    if (playerData.yearly) {
        composedData = playerData.yearly.map((item) => ({
            name:
                item.seasonId === 'CD_S2101264'
                    ? '2022'
                    : item.seasonId?.substr(4, 4),
            playerOneValue: item.seasonTotals[STATS_CATEGORY_IDS[nav.category]],
            benchmark:
                item.benchmarkedSeasonAverages[STATS_CATEGORY_IDS[nav.category]]
                    .benchmark ?? '',
            seasonId: item.seasonId
        }));
    }

    return composedData;
};
