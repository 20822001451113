import classNames from 'classnames';
import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from './SvgIcon';

/**
 * @callback onChange
 * @param {boolean} isChecked - True if the checkbox is checked, otherwise
 *    false.
 */

/**
 * A switch UI component for a single checkbox.
 *
 * @param {object} props - Props.
 * @param {string} props.id - ID.
 * @param {string} props.label - Label.
 * @param {boolean} props.isChecked - True if checkbox is checked, otherwise
 *    false.
 * @param {boolean} props.isDisabled - True if checkbox is checked, otherwise
 *    false.
 * @param {onChange} props.onChange - Callback function executed when the
 *    checked state of the checkbox changes.
 * @param {onClick} props.onClick - Callback function executed on when the click event is triggered.
 * @returns {JSX.Element} Component.
 */
export const SwitchInput = (props) => {
    const [isChecked, setIsChecked] = useState(props.isChecked);

    const onChange = (checked) => {
        setIsChecked(checked);
        props.onChange(checked);
    };

    const onClick = () => {
        if (props.onClick) {
            props.onClick();
        } else {
            null;
        }
    };

    useLayoutEffect(() => {
        onChange(props.isChecked);
    }, [props.isChecked]);

    return (
        <div
            className={classNames('switch-input', {
                'switch-input--checked': isChecked,
                'switch-input--disabled': props.isDisabled
            })}
        >
            {props.icon ? (
                <SvgIcon
                    icon={props.icon}
                    className="icon modal-toggle__icon"
                />
            ) : null}
            <label
                className="switch-input__label"
                htmlFor={props.id}
                tabIndex={0}
                onKeyPress={(evt) => {
                    if ([' ', 'Enter'].includes(evt.key)) {
                        evt.preventDefault();
                        onChange(!isChecked);
                    }
                }}
            >
                {props.label}

                <input
                    className="switch-input__input"
                    type="checkbox"
                    id={props.id}
                    checked={isChecked}
                    onChange={(evt) => onChange(evt.target.checked)}
                    onClick={onClick}
                    disabled={props.isDisabled}
                />
            </label>
        </div>
    );
};

SwitchInput.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    icon: PropTypes.string
};
