import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { BioAwards } from './components/BioAwards';
import { useGetPlayerProfileQuery } from 'store/modules/api/statsPro-api';
import { useGetCompSeasonQuery } from 'common/store/modules/api/afl-api';

/**
 * @param root0
 * @param root0.data
 * @param root0.seasonId
 * @param root0.playerProviderId
 * @param root0.aflwSeasonId
 * @returns {JSX.Element} Component.
 */
export const PlayerProfileBio = ({ seasonId, playerProviderId }) => {
    const totalGamesRef = useRef(null);

    const { data: compData } = useGetCompSeasonQuery(
        { id: seasonId },
        {
            skip: !seasonId
        }
    );

    useEffect(() => {
        const totalGamesEl = document.querySelector('.js-total-games');

        if (totalGamesEl) {
            totalGamesRef.current = totalGamesEl;
        }

        return () => {
            totalGamesRef.current = null;
        };
    }, []);

    const { data } = useGetPlayerProfileQuery(
        {
            seasonId: compData?.providerId,
            playerId: playerProviderId
        },
        {
            skip: !playerProviderId || !compData?.providerId
        }
    );

    if (data?.careerTotals?.matchesPlayed && totalGamesRef.current) {
        totalGamesRef.current.innerHTML =
            data.careerTotals.matchesPlayed.toString();
    }

    return data ? <BioAwards data={data} /> : <></>;
};

PlayerProfileBio.propTypes = {
    seasonId: PropTypes.string,
    playerProviderId: PropTypes.string.isRequired
};
