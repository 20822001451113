export const composeData = (rows, data, type) => {
    return [
        ...rows.map((row) => ({
            name: row.name,
            stat:
                type === 'averages' || type === 'benchmarkedAverages'
                    ? {
                          value:
                              data?.careerAverages?.[row.accessor]?.value ||
                              data?.benchmarkedSeasonAverages?.[row.accessor]
                                  ?.value,
                          benchmark:
                              data?.careerAverages?.[row.accessor]?.benchmark ||
                              data?.benchmarkedSeasonAverages?.[row.accessor]
                                  ?.benchmark
                      }
                    : {
                          value:
                              data?.careerTotals?.[row.accessor] ||
                              data?.seasonTotals?.[row.accessor] ||
                              data?.seasonStats?.[row.accessor]
                      },
            teamAbbr: data?.team?.teamAbbr.toLowerCase()
        }))
    ];
};
