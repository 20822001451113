// eslint-disable-next-line no-unused-vars
import { StatsLeadersState } from '../../stats-leaders/types';
// eslint-disable-next-line no-unused-vars
import { ReduxState } from '../../../../types';

/**
 * @param {ReduxState} state - The Redux state.
 * @returns {StatsLeadersState} The Stats Leaders state.
 */
export const statsLeadersSelector = (state) => state.statspro.statsLeaders;
