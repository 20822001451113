import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders a duotone chip component with a label.
 *
 * @param {string} symbol - The symbol.
 * @param {string} symbolColour - The symbol colour.
 * @param {string} bgColour - The background colour.
 * @param {string} label - The label.
 * @param {boolean} hollow - If there should only be an outline and no background colour.
 * @param {string} className - Additional class name for specific uses.
 * @returns {React.JSX.Element} - The rendered chip component.
 */
export const Chip = ({
    symbol,
    symbolColour,
    bgColour,
    label,
    hollow,
    className
}) => {
    return (
        <div className="chip">
            <div
                className="chip__square"
                style={{
                    background: hollow ? 'none' : bgColour,
                    outline: `0.2rem solid ${bgColour}`
                }}
            >
                <span
                    className={`chip__square-symbol ${
                        className ? className : ''
                    }`}
                    style={{ color: symbolColour }}
                >
                    {/* A dash ('-') is rendered as a slightly wider horizontal bar to match the design */}
                    {symbol === '-' ? (
                        <span
                            style={{
                                display: 'block',
                                width: '1rem',
                                height: '0.2rem',
                                background: `${symbolColour}`
                            }}
                        />
                    ) : (
                        symbol
                    )}
                </span>
            </div>
            <span className="chip__label">{label}</span>
        </div>
    );
};

Chip.propTypes = {
    symbol: PropTypes.string,
    symbolColour: PropTypes.string,
    bgColour: PropTypes.string,
    label: PropTypes.string,
    hollow: PropTypes.bool,
    className: PropTypes.string
};
