/**
 * Different values for the status cookie and what they mean
 *
 * @constant {object} - an object mapping a key name to a value to be stored in the cookie
 */
export const STATUS_KEYS = Object.freeze({
    /**
     * No cookie set/this is the hasn't been seen before so no status
     */
    EMPTY: '0',
    /**
     * The bar has been seen, but not closed
     */
    BAR_SEEN: '1',
    /**
     * The bar has been closed, msg has not been yet
     */
    BAR_CLOSED: '2',
    /**
     * The bar has been closed and the message has been rendered
     */
    MSG_SEEN: '3',
    /**
     * The bar has been closed and the msg has been closed
     */
    MSG_CLOSED: '4'
});
