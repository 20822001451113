import React from 'react';
import PropTypes from 'prop-types';
import { TeamCell } from './TeamCell';

/**
 * Round By Round table body cell content
 *
 * @param {object} params
 * @param {object} params.row
 * @param {number} params.rowIndex
 *
 * @returns
 */
export const TableCellContent = ({ row, rowIndex }) => {
    return (
        <div className="brownlow-player-modal-table__cell-inner">
            {row.cells.map((cell, cellIndex) => {
                const cellProps = cell.getCellProps();

                if (cell.column.id === 'opponent') {
                    return (
                        <TeamCell
                            cellProps={cellProps}
                            cell={cell}
                            team={cell.value}
                            key={`brownlow-player-modal-table__row-${rowIndex}_cell-${cellIndex}`}
                        />
                    );
                }

                return (
                    <div
                        {...cellProps}
                        role={null}
                        className={`stats-table__body-cell stats-table__body-cell--${cell.column.id}`}
                        key={`brownlow-player-modal-table__row-${rowIndex}_cell-${cellIndex}`}
                    >
                        {cell.column.id === 'roundNumber' && cell.value === 0
                            ? 'OR'
                            : cell.value}
                    </div>
                );
            })}
        </div>
    );
};

TableCellContent.propTypes = {
    row: PropTypes.object.isRequired,
    rowIndex: PropTypes.number.isRequired
};
