import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { TooltipContent } from '../TooltipContent';
import { TooltipButton } from 'common/react/components/tooltip-button';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

export const BodyCellForm = ({ cell, cellProps }) => {
    const maxItems = 5;
    const form = cell?.value || '';
    const formLetters = form.split('') || [];
    const hideArrow = isMobile ? true : false;
    const showOnHover = isMobile ? false : true;

    while (formLetters.length < maxItems) {
        formLetters.push('-');
    }

    return (
        <td
            {...cellProps}
            className="stats-table__cell stats-table__cell--form"
        >
            <ul className="stats-table__form">
                {formLetters.slice(-maxItems).map((formLetter, index) => (
                    <li
                        key={index}
                        className={`stats-table__form-item stats-table__form-item--${formLetter.toLowerCase()}`}
                    >
                        {formLetter === '-' ? (
                            <span>{formLetter}</span>
                        ) : (
                            <TooltipButton
                                id={`ladder-tooltip-${index.toString()}`}
                                title={getTranslation(
                                    'label.ladder.tooltipTitle'
                                )}
                                classNames={{
                                    component: 'ladder-tooltip__tooltip',
                                    button: 'ladder-tooltip__trigger'
                                }}
                                direction="below"
                                primaryTooltipContent={
                                    <TooltipContent
                                        cell={cell}
                                        formStringIndex={index.toString()}
                                    />
                                }
                                showOnHover={showOnHover}
                                noPadding
                                externalTooltip={true}
                                hideArrow={hideArrow}
                            >
                                <SvgIcon
                                    icon={`ladder-form-icon-${formLetter.toLowerCase()}`}
                                    className="icon"
                                />
                            </TooltipButton>
                        )}
                    </li>
                ))}
            </ul>
        </td>
    );
};

BodyCellForm.propTypes = {
    cell: PropTypes.object,
    cellProps: PropTypes.object
};
