import { STATS_CATEGORY_IDS } from 'widgets/statspro/js/components/common/config/performance-stats';

export const composePlayerTwoCareerData = (
    composedChartData,
    playerTwoData,
    nav
) => {
    let composedData = composedChartData;

    if (playerTwoData.yearly) {
        const reversedPlayerTwoData = [
            ...(playerTwoData.yearly ?? null)
        ].reverse();

        reversedPlayerTwoData.forEach((item) => {
            let seasonName =
                item.seasonId === 'CD_S2101264'
                    ? '2022'
                    : item.seasonId?.substr(4, 4);

            let playerOneItem = composedData?.find(
                (composedItem) => composedItem.seasonId === item.seasonId
            );

            if (playerOneItem) {
                playerOneItem.playerTwoValue =
                    item.seasonTotals[STATS_CATEGORY_IDS[nav.category]];
                playerOneItem.playerTwoBenchmark =
                    item.benchmarkedSeasonAverages[
                        STATS_CATEGORY_IDS[nav.category]
                    ].benchmark ?? '';
            } else {
                composedData = [
                    ...composedData,
                    {
                        name: seasonName,
                        playerOneValue: null,
                        playerTwoValue:
                            item.seasonTotals[STATS_CATEGORY_IDS[nav.category]],
                        benchmark: null,
                        playerTwoBenchmark:
                            item.benchmarkedSeasonAverages[
                                STATS_CATEGORY_IDS[nav.category]
                            ].benchmark ?? '',
                        seasonId: item.seasonId
                    }
                ];
            }
        });
    }
    return composedData;
};
