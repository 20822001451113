import React, { useState, useEffect } from 'react';

import { TeamWatermarkBackground } from 'widgets/statspro/js/components/TeamWatermarkBackground';
import {
    getTranslation,
    lookupTranslation
} from 'common/react/utils/translations';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const ModalAccount = () => {
    const [aflIdUserData, setAflIdUserData] = useState(null);

    useEffect(() => {
        PULSE.app.authHelpers
            .getUserInfo()
            .then((userData) => {
                setAflIdUserData(userData);
            })
            .catch((error) => {
                console.error('Error fetching user info:', error);
            });
    }, []);

    return (
        <>
            {aflIdUserData !== null ? (
                <div
                    className={`modal-account aflid-user__logged-in--${aflIdUserData.teamAbbr}`}
                >
                    <TeamWatermarkBackground
                        teamAbbreviation={aflIdUserData.teamAbbr}
                    />
                    <div className="aflid-user__avatar">
                        <span className="aflid-user__initials">
                            {aflIdUserData.userInitials}
                        </span>
                    </div>
                    <a
                        className="aflid-user__info"
                        href={aflIdUserData.accountUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className="aflid-user__user">
                            <div className="aflid-user__name">
                                {lookupTranslation('label.aflId.greeting', {
                                    firstName: aflIdUserData.given_name,
                                    lastName: aflIdUserData.family_name,
                                    userName: aflIdUserData.name
                                })}
                            </div>
                            <div className="aflid-user__manage">
                                {getTranslation('label.aflid.manage')}
                            </div>
                        </div>
                        <div className="aflid-user__arrow">
                            <SvgIcon className="icon" icon="arrow-right" />
                        </div>
                    </a>
                </div>
            ) : null}
        </>
    );
};
