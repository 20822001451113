import React, { useLayoutEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFallbackTranslation } from 'common/react/utils/translations';
import { UPDATE_MODAL } from 'store/modules/match-centre/player-stats/actions';
import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { playerStatsCustomFieldsSelector } from 'store/modules/match-centre/player-stats/selectors';

/**
 * Manage Stats overlay bar
 *
 * @returns {JSX.Element} Component.
 */
export const ManageFieldsBar = () => {
    const dispatch = useDispatch();
    const [isShowing, setIsShowing] = useState(true);
    const [leftOffset, setLeftOffset] = useState('50%');
    const windowSize = useWindowSize();
    const customFields = useSelector(playerStatsCustomFieldsSelector);

    const hideMangageFieldsBar = () => {
        setIsShowing(false);
    };

    const openModal = () => {
        dispatch(UPDATE_MODAL.request({ isOpen: true }));
    };

    useLayoutEffect(() => {
        // Hide the bar if there are many columns
        // eslint-disable-next-line no-magic-numbers
        if (customFields?.length > 10) {
            setIsShowing(false);
        } else {
            // Update the bar to stay in the centre of the empty column
            const emptyCell = document.querySelector(
                '.js-player-stats-empty-cell'
            );
            if (emptyCell) {
                setLeftOffset(
                    emptyCell.offsetLeft + emptyCell.clientWidth / 2 + 'px'
                );
            }
        }
    }, [windowSize, customFields]);

    return (
        <div
            className={`custom-table__manage-fields-bar u-hide-until-phablet ${
                isShowing ? '' : 'u-hide'
            }`}
            style={{
                left: leftOffset
            }}
        >
            <button
                className="custom-table__manage-fields-bar-button"
                onClick={() => openModal()}
            >
                <span className="custom-table__manage-fields-bar-button-icon"></span>
            </button>
            <h4 className="custom-table__manage-fields-bar-title">
                {getFallbackTranslation(
                    `label.customTable.mcPlayerStats.table.manageFields`,
                    'Manage Fields',
                    ''
                )}
            </h4>
            <button
                className="custom-table__manage-fields-bar-button-hide"
                onClick={() => hideMangageFieldsBar()}
            >
                {getFallbackTranslation(
                    `label.customTable.mcPlayerStats.table.manageFields.hide`,
                    'HIDE',
                    ''
                )}
            </button>
        </div>
    );
};
