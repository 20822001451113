export const navLink = (tab = 'home') => {
    const navTabs = document.querySelectorAll('.stats-nav-item');
    navTabs.forEach((item) => {
        // @ts-ignore
        const tabName = item.dataset.tab;
        if (location.pathname.indexOf('/' + tabName) > -1) {
            // eslint-disable-next-line no-param-reassign
            tab = tabName;
        }
    });
    const newActiveTab = document.querySelector(
        `.stats-nav-item[data-tab="${tab}"]`
    );
    if (newActiveTab) {
        if (!newActiveTab.classList.contains('active')) {
            navTabs.forEach((item) => {
                item.classList.remove('active');
            });
        }

        newActiveTab.classList.add('active');
    }

    document.body.classList.add('disable-nav-fix');
    document.body.classList.add('remove-sticky-nav');

    const currentlyActive = document.querySelector(
        '.js-statspro-container.active'
    );
    if (currentlyActive) {
        currentlyActive.classList.remove('active');
        currentlyActive.classList.add('u-hide');
    }

    const newActive = document.querySelector(
        `.js-statspro-container[data-tab="${tab}"]`
    );
    if (newActive) {
        newActive.classList.add('active');
        newActive.classList.remove('u-hide');
    }

    const { baseRoute } = document.querySelector(
        '.js-react-statspro-nav-provider'
    )?.dataset;
    const url = new URL(window.location);
    url.pathname = baseRoute + '/' + (tab === 'home' ? '' : tab);
    window.history.replaceState({}, '', url);
};
