/* eslint-disable max-lines */
import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import TouchBackend from 'react-dnd-touch-backend';

import {
    getFallbackTranslation,
    getTranslation
} from 'common/react/utils/translations';
import TableModal from '../../../../custom-table/js/components/DefaultTableModal/TableModal';
import { CUSTOM_TABLE_CONFIG } from '../config/custom-table-config';
import { useDispatch, useSelector } from 'react-redux';
import {
    UPDATE_CUSTOM_FIELDS,
    UPDATE_TEMPORARY_FIELDS
} from 'store/modules/match-centre/player-stats/actions';
import { playerStatsCustomFieldsSelector } from 'store/modules/match-centre/player-stats/selectors';

export const ManageStatsModal = ({ isModalOpen, toggleModal }) => {
    const dispatch = useDispatch();
    const [customTable, setCustomTable] = useState(CUSTOM_TABLE_CONFIG);
    const TABLE_NAME = 'mcPlayerStats';
    const customFields = useSelector(playerStatsCustomFieldsSelector);

    useEffect(() => {
        // Set initial custom fields
        let initialLocalStorage = localStorage.getItem(TABLE_NAME);
        if (initialLocalStorage) {
            initialLocalStorage = JSON.parse(initialLocalStorage);

            setCustomTable({
                ...customTable,
                displayedFields: initialLocalStorage
            });
            dispatch(
                UPDATE_CUSTOM_FIELDS.request({ data: [...initialLocalStorage] })
            );
            dispatch(
                UPDATE_TEMPORARY_FIELDS.request({
                    data: [...initialLocalStorage]
                })
            );
        }
    }, []);

    useEffect(() => {
        // Update custom fields when items are added or removed and the modal is open
        if (isModalOpen) {
            localStorage.setItem(
                TABLE_NAME,
                JSON.stringify(customTable.displayedFields)
            );
            dispatch(
                UPDATE_CUSTOM_FIELDS.request({
                    data: [...customTable.displayedFields]
                })
            );
        } else if (customTable.displayedFields.length !== customFields.length) {
            setCustomTable({
                ...customTable,
                displayedFields: customFields
            });
        }
    }, [customTable]);

    const handleAddRemoveFieldClick = (field) => {
        const hasField = customTable.displayedFields.find(
            (displayedField) => displayedField.fieldName === field.fieldName
        );
        let newFields;

        if (!hasField) {
            newFields = [...customTable.displayedFields];
            newFields.push(field);
        } else {
            newFields = customTable.displayedFields.filter((displayedField) => {
                return displayedField.fieldName !== field.fieldName;
            });
        }

        setCustomTable({
            ...customTable,
            displayedFields: newFields
        });
    };

    const handleRemoveAllFieldsClick = () => {
        setCustomTable({
            ...customTable,
            displayedFields: []
        });
    };

    const moveCard = (tableName, dragIndex, hoverIndex) => {
        let newFields = customTable.displayedFields;
        let dragCard = newFields[dragIndex];

        newFields.splice(dragIndex, 1);
        newFields.splice(hoverIndex, 0, dragCard);

        setCustomTable({ ...customTable, displayedFields: newFields });
    };

    const confirmFields = () => {
        toggleModal({ confirm: true });
    };

    const setAccordionItem = (accordionName) => {
        let accordionItems = customTable.accordionItems;

        if (!accordionItems[accordionName]) {
            accordionItems[accordionName] = {
                opened: false
            };
        }

        accordionItems[accordionName].opened =
            !accordionItems[accordionName].opened;

        setCustomTable({ ...customTable, accordionItems });
    };

    const getAccordionItem = (accordionName) => {
        if (customTable.accordionItems[accordionName]) {
            return customTable.accordionItems[accordionName].opened;
        }

        return false;
    };

    const setDescriptionShowing = (event, field) => {
        const elementHasClass = event.target.classList.contains('js-close-btn');
        const parentElement = event.target.closest('.js-close-btn');

        if (!elementHasClass && !parentElement) {
            setCustomTable({
                ...customTable,
                descriptionShowing: field.fieldName
            });
        } else {
            setCustomTable({ ...customTable, descriptionShowing: '' });
        }
    };

    const getDescriptionShowing = (field) => {
        return field.fieldName === customTable.descriptionShowing;
    };

    const bodyClick = (event) => {
        const elementHasClass = event.target.classList.contains(
            'js-stats-item-description'
        );
        const parentElement = event.target.closest(
            '.js-stats-item-description'
        );
        const buttonElement = event.target.closest(
            '.custom-table-modal__add-remove-button'
        );
        const removeAllButtonElement = event.target.closest(
            '.custom-table-modal__sidebar-button'
        );

        if (
            !elementHasClass &&
            !parentElement &&
            !buttonElement &&
            !removeAllButtonElement
        ) {
            setCustomTable({ ...customTable, descriptionShowing: '' });
        } else {
            return;
        }
    };

    const closeDescription = () => {
        setCustomTable({ ...customTable, descriptionShowing: '' });
    };

    const backendOptions = {
        enableMouseEvents: true
    };

    return (
        <DndProvider backend={TouchBackend} options={backendOptions}>
            <div className="custom-default-table__modal">
                <TableModal
                    state={customTable}
                    customTable={customTable}
                    tableName={TABLE_NAME}
                    toggleModal={toggleModal}
                    modalOpen={isModalOpen}
                    handleAddRemoveFieldClick={handleAddRemoveFieldClick}
                    handleRemoveAllFieldsClick={handleRemoveAllFieldsClick}
                    moveCard={moveCard}
                    getTranslation={getTranslation}
                    getFallbackTranslation={getFallbackTranslation}
                    confirmFields={confirmFields}
                    setAccordionItem={setAccordionItem}
                    getAccordionItem={getAccordionItem}
                    setDescriptionShowing={setDescriptionShowing}
                    getDescriptionShowing={getDescriptionShowing}
                    bodyClick={bodyClick}
                    closeDescription={closeDescription}
                />
            </div>
        </DndProvider>
    );
};

ManageStatsModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired
};
