import React from 'react';
import PropTypes from 'prop-types';

import { LiveTrackerSlider } from './live-tracker-slider';
import { LiveTrackerEmbeddable } from './live-tracker-embeddable';

export const LiveTracker = ({
    title,
    subtitle,
    embeddable,
    linkTo,
    linkToLabel,
    seasonPid,
    seasonId,
    playerModalEnabled,
    anchorName
}) => {
    return (
        <>
            {!embeddable ? (
                <LiveTrackerSlider
                    seasonPid={seasonPid}
                    seasonId={seasonId ?? null}
                    linkTo={linkTo}
                    playerModalEnabled={playerModalEnabled}
                    anchorName={anchorName}
                />
            ) : (
                <LiveTrackerEmbeddable
                    seasonPid={seasonPid}
                    seasonId={seasonId ?? null}
                    title={title}
                    subtitle={subtitle}
                    linkTo={linkTo}
                    linkToLabel={linkToLabel}
                    anchorName={anchorName}
                />
            )}
        </>
    );
};

LiveTracker.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    embeddable: PropTypes.string,
    linkTo: PropTypes.string,
    linkToLabel: PropTypes.string,
    playerModalEnabled: PropTypes.bool,
    seasonPid: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    anchorName: PropTypes.string
};
