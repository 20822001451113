import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { PlayerProfileLink } from '../../common/PlayerProfileLink';
import { ConditionalWrapper } from 'common/react/components/ConditionalWrapper';

export const SearchResult = ({ result, query, compseason }) => {
    const teamAbbreviation = result.team.teamAbbr.toLowerCase();

    const fullName = `${result.playerDetails.givenName} ${result.playerDetails.surname}`;
    const isAFLW = PULSE.app.common.match.getCompAbbr(compseason) === 'AFLW';

    const highlight = (searchQuery, string) => {
        return {
            __html: string.replace(
                new RegExp(searchQuery, 'gi'),
                (str) => `<strong>${str}</strong>`
            )
        };
    };

    const [imgMissing, addPlaceholder] = useState(false);

    const onErrored = () => {
        addPlaceholder((current) => !current);
    };

    return (
        <li className="search-result">
            <ConditionalWrapper
                condition={result?.playerId}
                wrapper={(children) => (
                    <PlayerProfileLink
                        classes="search-result__link"
                        isAFLW={isAFLW}
                        player={{
                            playerId: result.playerId,
                            firstName: result.playerDetails.givenName,
                            surname: result.playerDetails.surname
                        }}
                    >
                        {children}
                    </PlayerProfileLink>
                )}
            >
                <div
                    className={
                        'search-result__image' +
                        (imgMissing ? ' has-placeholder' : '')
                    }
                >
                    <img
                        loading="lazy"
                        src={PULSE.app.common.image.migratePlayerPhotoURL(
                            result.playerDetails.photoURL,
                            0.1
                        )}
                        alt={`Headshot of ${fullName}`}
                        onError={(event) => onErrored(event)}
                    />
                </div>
                <div className="search-result__details">
                    <p
                        className="search-result__title"
                        dangerouslySetInnerHTML={highlight(query, fullName)}
                    />
                    <div className="search-result__details-team">
                        <SvgIcon
                            icon={`aflc-${teamAbbreviation}-flag`}
                            className={`search-result__details-flag`}
                        />
                        <span>#{result.playerDetails.jumperNumber}</span>
                        <span>|</span>
                        <span>{result.team.teamName}</span>
                    </div>
                </div>
            </ConditionalWrapper>
        </li>
    );
};

SearchResult.propTypes = {
    result: PropTypes.object.isRequired,
    query: PropTypes.string,
    compseason: PropTypes.string.isRequired
};
