// eslint-disable-next-line no-unused-vars
import { PlayerModalState } from '../types';
import '../../types';

/**
 * @param {PlayerModalState} draft - Draft state.
 * @param {object} payload - Action payload.
 * @param payload.visible
 * @param payload.playerId
 * @param payload.seasonPid
 * @param payload.leaderBoardData
 * @param payload.seasonId
 * @param payload.player
 */
export const setPlayerModal = (
    draft,
    { visible, playerId, seasonPid, seasonId, leaderBoardData, player }
) => {
    draft.playerId = playerId;
    draft.seasonPid = seasonPid;
    draft.seasonId = seasonId;
    draft.visible = visible;
    draft.leaderBoardData = leaderBoardData;
    draft.player = player;
};
