import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'common/react/components/select';
import { getTranslation } from 'common/react/utils/translations';
import { statComparisonNavSelector } from 'store/modules/statspro/stat-comparison/selectors/nav-selector';
import { UPDATE_NAV_ITEM } from 'store/modules/statspro/stat-comparison/actions';

export const SeasonNavItem = () => {
    const dispatch = useDispatch();
    const nav = useSelector(statComparisonNavSelector);

    const seasonsList = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );

    /**
     * Compare 2 compseasons ids. To be used as a comparator function inside of
     * an Array.prototype.sort call
     *
     * @param {object} compSeasonA - A compseason object with an id property
     * @param {object} compSeasonB - A compseason object with an id property
     * @returns {number} - The return number consumed by .sort
     */
    const _compareCompSeasonId = (compSeasonA, compSeasonB) =>
        compSeasonA.id > compSeasonB.id
            ? -1
            : compSeasonA.id < compSeasonB.id
            ? 1
            : 0;

    seasonsList.sort(_compareCompSeasonId);

    const seasons = [
        { id: -1, name: getTranslation('label.stats.career') },
        ...seasonsList
    ];

    return (
        <Select
            items={seasons}
            itemToString={(season) => season.name}
            initialSelectedItem={
                seasons.length && nav.season.id
                    ? seasons.find((s) => s.id === nav.season.id)
                    : ''
            }
            activeItem={
                seasons.length && nav.season.id
                    ? seasons.find((s) => s.id === nav.season.id)
                    : ''
            }
            isReadyToRender={() => seasons?.length && nav.season.id}
            label={`${getTranslation('label.stats.career')}/${getTranslation(
                'label.stats.season'
            )}`}
            onSelectedItemChange={({ selectedItem }) => {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'season',
                        value: { id: selectedItem.id }
                    })
                );
            }}
        />
    );
};
