import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// component imports
import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import {
    RoundListNavItem,
    SeasonNavItem,
    CompNavItem,
    ResponsiveSeasonNavItem
} from 'common/react/components/competition-nav/nav-items';

//store imports
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import { useNavSync } from 'common/react/components/competition-nav/hooks/use-nav-sync';
import { useWindowSize } from 'common/react/hooks/use-window-resize';

export const VideoHub = ({ competitionId, seasonId, title }) => {
    const windowSize = useWindowSize();

    const isResponsive = () =>
        windowSize.width < PULSE.app.measurements.desktop;
    const [showResponsiveItems, setShowResponsiveItems] = useState(
        isResponsive()
    );

    useEffect(() => {
        setShowResponsiveItems(isResponsive());
    }, [windowSize.width]);

    const initialValues = { competitionId, seasonId };

    const nav = useSelector(navSelector);
    const options = useCompetitionNavigationOptions(nav);
    useNavSync({ ...options, initialValues });

    const competitions = options.competitions;

    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );

    return (
        <div className="competition-nav">
            <StatsSection.Main>
                <StatsSection.Heading title={title} />
                <StatsSection.Nav>
                    <StatsSection.NavItems>
                        <CompNavItem competitions={competitions} />
                        {!showResponsiveItems ? (
                            <SeasonNavItem seasons={seasons} />
                        ) : null}
                    </StatsSection.NavItems>
                    <StatsSection.RoundButtons>
                        <RoundListNavItem
                            classes={'competition-nav__round-filter-wrapper'}
                        />
                    </StatsSection.RoundButtons>
                    {showResponsiveItems ? (
                        <StatsSection.NavFilterItems>
                            <ResponsiveSeasonNavItem seasons={seasons} />
                        </StatsSection.NavFilterItems>
                    ) : null}
                </StatsSection.Nav>
            </StatsSection.Main>
        </div>
    );
};

VideoHub.propTypes = {
    seasonPid: PropTypes.string,
    competitionId: PropTypes.string,
    seasonId: PropTypes.string,
    round: PropTypes.string,
    title: PropTypes.string
};
