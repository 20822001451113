import React from 'react';
import PropTypes from 'prop-types';
import { TeamWatermarkBackground } from '../../TeamWatermarkBackground';
import { getTranslation } from 'common/react/utils/translations';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { PlayerProfileLink } from '../../common/PlayerProfileLink';

/**
 * @param {object} props - Props.
 * @param {Array} props.players - Players details.
 * @param {string} props.position - Players position.
 * @param {boolean} props.isAFLW - if this is an AFLW card
 * @returns {JSX.Element} Component.
 */
export const FeaturedHeadToHeadCardTop = ({
    players,
    position,
    isAFLW = false
}) => (
    <div className="stats-card-featured-player">
        <div className="stats-card-featured-player__inner-container">
            {players.map((player, i) => (
                <div
                    className="stats-card-featured-player__image-container"
                    key={i}
                >
                    <TeamWatermarkBackground
                        teamAbbreviation={player.team.teamAbbr}
                        className="stats-card-featured-player__bg stats-card-featured-player__bg--no-border-radius"
                        side={i === 0 ? 'left' : 'right'}
                    />
                    <div className="stats-card-featured-player__photo-container">
                        {player?.playerId && (
                            <PlayerProfileLink
                                classes="stats-head-to-head__player-link"
                                isAFLW={isAFLW}
                                player={{
                                    playerId: player.playerId,
                                    firstName: player.playerDetails.givenName,
                                    surname: player.playerDetails.surname
                                }}
                            >
                                <img
                                    className="stats-card-featured-player__image"
                                    alt={`${getTranslation(
                                        'label.stats.headshot.alt'
                                    )} ${player.playerDetails.givenName} ${
                                        player.playerDetails.surname
                                    }`}
                                    src={PULSE.app.common.image.migratePlayerPhotoURL(
                                        player.playerDetails.photoURL
                                    )}
                                />
                            </PlayerProfileLink>
                        )}
                    </div>
                </div>
            ))}

            <div className="stats-card-featured-player__versus">
                <SvgIcon
                    icon="versus-white"
                    className="icon"
                    folder="elements"
                    subfolder="stats"
                />
            </div>

            <div className="stats-card-featured-player__category-outer-wrapper">
                <div className="stats-card-featured-player__category-container">
                    <p className="stats-card-featured-player__category">
                        {getTranslation('label.stats.' + position)}
                    </p>
                </div>
            </div>
        </div>
    </div>
);

FeaturedHeadToHeadCardTop.propTypes = {
    players: PropTypes.array.isRequired,
    position: PropTypes.string.isRequired,
    isAFLW: PropTypes.bool
};
