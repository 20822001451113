import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../../setup-react';

// ===== Signals ============================================================ //

// Nav
export const UPDATE_NAV_ITEM = createSignalAction(
    'match-centre/player-stats',
    'UPDATE_NAV_ITEM'
);

// Sort
export const UPDATE_SORT = createSignalAction(
    'match-centre/player-stats',
    'UPDATE_SORT'
);

// Data Type
export const UPDATE_DATA_TYPE = createSignalAction(
    'match-centre/player-stats',
    'UPDATE_DATA_TYPE'
);

// Custom Fields
export const UPDATE_CUSTOM_FIELDS = createSignalAction(
    'match-centre/player-stats',
    'UPDATE_CUSTOM_FIELDS'
);

// Temporary Fields
export const UPDATE_TEMPORARY_FIELDS = createSignalAction(
    'match-centre/player-stats',
    'UPDATE_TEMPORARY_FIELDS'
);

// Modal
export const UPDATE_MODAL = createSignalAction(
    'match-centre/player-stats',
    'UPDATE_MODAL'
);

// ===== Deltas ============================================================= //

// Nav
export const SET_NAV_ITEM = createDeltaActionType(
    'match-centre/player-stats',
    'SET_NAV_ITEM'
);
export const setNavItem = createActionCreator(SET_NAV_ITEM);

// Sort
export const SET_SORT = createDeltaActionType(
    'match-centre/player-stats',
    'SET_SORT'
);
export const setSort = createActionCreator(SET_SORT);

// Data Type
export const SET_DATA_TYPE = createDeltaActionType(
    'match-centre/player-stats',
    'SET_DATA_TYPE'
);
export const setDataType = createActionCreator(SET_DATA_TYPE);

// Custom Fields
export const SET_CUSTOM_FIELDS = createDeltaActionType(
    'match-centre/player-stats',
    'SET_CUSTOM_FIELDS'
);
export const setCustomFields = createActionCreator(SET_CUSTOM_FIELDS);

// Temporary Fields
export const SET_TEMPORARY_FIELDS = createDeltaActionType(
    'match-centre/player-stats',
    'SET_TEMPORARY_FIELDS'
);
export const setTemporaryFields = createActionCreator(SET_TEMPORARY_FIELDS);

// Modal
export const SET_MODAL = createDeltaActionType(
    'match-centre/player-stats',
    'SET_MODAL'
);
export const setModal = createActionCreator(SET_MODAL);
