import React from 'react';
import PropTypes from 'prop-types';

import { LazyImage } from 'common/react/components/LazyImage';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { useGetContentQuery } from 'common/store/modules/api/content-api';

export const ModalAflIdPromo = ({ text, buttons }) => {
    const requestConfig = {
        type: 'PHOTO',
        params: {
            pageSize: 1,
            tagNames: 'settings-modal-afl-id-promo'
        }
    };

    const { data: modalImage } = useGetContentQuery(requestConfig);
    const imageHasTag =
        modalImage?.content[0]?.tags[0]?.label === 'settings-modal-afl-id-promo'
            ? true
            : false;

    return (
        <div className="modal-afl-id-promo">
            <div className="modal-afl-id-promo__content">
                <SvgIcon icon="afl-id-logo" className="icon" />
                <p className="modal-afl-id-promo__text">{text}</p>
                <div className="modal-afl-id-promo__buttons-container">
                    {buttons.map((button) => (
                        <button
                            onClick={button.onClick}
                            className="modal-afl-id-promo__button button-new button-new--highlight"
                            key={button.label}
                        >
                            {button.label}
                        </button>
                    ))}
                </div>
            </div>
            <div className="modal-afl-id-promo__image-container">
                {modalImage && imageHasTag ? (
                    <LazyImage
                        url={`${modalImage.content[0].onDemandUrl}?width=270&height=480`}
                        cssClass="modal-afl-id-promo__image"
                    />
                ) : null}
            </div>
        </div>
    );
};

ModalAflIdPromo.propTypes = {
    text: PropTypes.string,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired
        })
    )
};
