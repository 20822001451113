import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { BPLeaderboard } from '../components/BPLeaderboard';
import { configureReduxQuerySync } from 'widgets/brownlow-tracker/js/utils/configure-redux-query-sync';

export const BrownlowPredictorLeaderboardProvider = ({ store, data }) => {
    useEffect(() => {
        configureReduxQuerySync(store);
    }, []);

    return (
        <Provider store={store}>
            <BPLeaderboard {...data} />
        </Provider>
    );
};

BrownlowPredictorLeaderboardProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
