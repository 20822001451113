/**
 * @param {object} item - Item..
 * @param {object[]} columns - Column data.
 * @param {Array} rounds - rounds list.
 * @param { boolean } isPredictor - Different data structure when set to true
 * @param predicted
 * @param showPredictedData
 * @returns {object[]} Row data for the given player.
 */
export const composeRowData = (
    item,
    columns,
    rounds,
    isPredictor = false,
    predicted
) => {
    const byeRounds =
        rounds
            ?.filter((round) =>
                round.byes?.some((bye) => bye.providerId === item?.team.teamId)
            )
            ?.map((round) => round.roundNumber) ?? [];

    const predictedVotes = predicted?.find(
        (predictedItem) =>
            item.player.playerId === predictedItem.providerId ||
            item.player.providerId === predictedItem.providerId
    );

    return {
        player: {
            eligible: item?.eligible ?? false,
            givenName: isPredictor
                ? item?.player.firstName ?? ''
                : item?.player.givenName ?? '',
            surname: item?.player.surname ?? '',
            photoURL: item?.player.photoURL ?? '',
            playerId: isPredictor
                ? item?.player.providerId ?? ''
                : item?.player.playerId ?? '',
            winner: item?.player.winner ?? false,
            position: item?.position ?? null
        },
        ...columns.reduce((prev, curr) => {
            let roundData = {
                actual: '',
                predicted: '',
                played: true
            };

            if (
                byeRounds.includes(parseInt(curr.accessor.replace('round', '')))
            ) {
                roundData.actual = 'B';
            } else {
                item?.roundByRoundVotes?.forEach((round) => {
                    if (curr.accessor === 'round' + round.roundNumber) {
                        roundData.actual = round.votes;
                        return;
                    }
                });
            }

            if (predictedVotes) {
                Object.keys(predictedVotes.rounds).forEach((key) => {
                    if (
                        parseInt(curr.accessor.replace('round', '')) ===
                        parseInt(key)
                    ) {
                        const data = predictedVotes.rounds[key];

                        roundData.predicted = data.points || '';

                        if (data.played === false && !data.bye) {
                            roundData.played = false;
                            roundData.actual = 'NP';
                        }
                    }
                });
            }

            return {
                ...prev,
                [curr.accessor]: roundData
            };
        }, {}),
        totalVotes: item?.totalVotes ?? 0,
        bettingOdds: item?.bettingOdds ?? 0,
        totalPredictedVotes: predictedVotes?.totalVotes ?? 0
    };
};
