import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { MapSponsor } from '../components/MapSponsor';

export const MapSponsorProvider = ({ store }) => {
    return (
        <>
            {PULSE.venueSponsor ? (
                <Provider store={store}>
                    <MapSponsor />
                </Provider>
            ) : null}
        </>
    );
};

MapSponsorProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
