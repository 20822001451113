import { Navigation, A11y } from 'swiper';

export const sliderSettings = (name) => {
    return {
        modules: [Navigation, A11y],
        slidesPerView: 8,
        navigation: {
            nextEl: '.swiper-button-next-unique-' + name,
            prevEl: '.swiper-button-prev-unique-' + name
        },

        centerInsufficientSlides: true
    };
};

export const BREAKPOINTS = {
    breakpoints: {
        1024: {
            slidesPerView: 6,
            slidesPerGroup: 6
        },
        1300: {
            slidesPerView: 9,
            slidesPerGroup: 9
        },
        1500: {
            slidesPerView: 10,
            slidesPerGroup: 10
        }
    }
};

export const BREAKPOINTS_FINALS = {
    breakpoints: {
        1024: {
            slidesPerView: 4,
            slidesPerGroup: 4
        },
        1600: {
            slidesPerView: 6,
            slidesPerGroup: 6
        },
        2560: {
            slidesPerView: 8,
            slidesPerGroup: 8
        }
    }
};
