// eslint-disable-next-line no-unused-vars
import { LeaderboardState } from '../types';
// eslint-disable-next-line no-unused-vars
import { ReduxState } from '../../../../types';

/**
 * @param {ReduxState} state - The Redux state.
 * @returns {ViewByMatchState} The Video Hub View By Match State
 */
export const viewByMatchSelector = (state) => state.videoHub.viewByMatch;
