import React from 'react';
import PropTypes from 'prop-types';

export const SponsorWrapper = ({ link, children }) => {
    return link ? (
        <a
            className="trade-radio__sponsor"
            href={link.promoUrl}
            target={link.external ? '_blank' : null}
            rel={link.external ? 'noreferrer' : null}
        >
            {children}
        </a>
    ) : (
        <div className="trade-radio__sponsor">{children}</div>
    );
};

SponsorWrapper.propTypes = {
    link: PropTypes.object.isRequired,
    children: PropTypes.node
};
