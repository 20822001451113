import React from 'react';
import PropTypes from 'prop-types';
import { getTranslation } from 'common/react/utils/translations';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const HeaderCellForm = ({ column, keyProp }) => {
    return (
        <th
            className="stats-table__header-cell stats-table__header-cell--form"
            scope="col"
            key={keyProp}
        >
            <button className="stats-table__header-cell-button" disabled={true}>
                <div className="stats-table__header-cell-content stats-table__header-cell-content--form">
                    <span>{column.render('Header')}</span>
                    <SvgIcon icon="arrow-right" className="icon" />
                    <span className="stats-table__latest-label">{getTranslation(`label.ladder.latest`)}</span>
                </div>
            </button>
        </th>
    );
};

HeaderCellForm.propTypes = {
    column: PropTypes.object,
    keyProp: PropTypes.string
};
