import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../../setup-react';

// ===== Signals ============================================================ //

// Data
export const FETCH_MATCH_BY_MATCH_DATA = createSignalAction(
    'brownlow/match-by-match',
    'FETCH_MATCH_BY_MATCH_DATA'
);

export const POLL_MATCH_BY_MATCH_DATA = createSignalAction(
    'brownlow/match-by-match',
    'POLL_MATCH_BY_MATCH_DATA'
);

// ===== Deltas ============================================================= //

// Data
export const SET_MATCH_BY_MATCH_DATA = createDeltaActionType(
    'brownlow/match-by-match',
    'SET_MATCH_BY_MATCH_DATA'
);
export const setMatchByMatchData = createActionCreator(SET_MATCH_BY_MATCH_DATA);

export const SET_MATCH_BY_MATCH_DATA_REQUEST = createDeltaActionType(
    'brownlow/match-by-match',
    'SET_MATCH_BY_MATCH_DATA_REQUEST'
);
export const setMatchByMatchDataRequest = createActionCreator(
    SET_MATCH_BY_MATCH_DATA_REQUEST
);

export const REMOVE_MATCH_BY_MATCH_DATA_REQUEST = createDeltaActionType(
    'brownlow/match-by-match',
    'REMOVE_MATCH_BY_MATCH_DATA_REQUEST'
);
export const removeMatchByMatchDataRequest = createActionCreator(
    REMOVE_MATCH_BY_MATCH_DATA_REQUEST
);
