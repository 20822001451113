import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const NavButton = ({ icon, text, onClick }) => (
    <button className="stats-nav-button" onClick={onClick} aria-label={text}>
        {icon && <SvgIcon icon={icon} className="stats-nav-button__icon" />}
        <span className="u-hide-until-desktop">{text}</span>
    </button>
);

NavButton.propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};
