import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { SwitchInput } from '../../SwitchInput';
import { getTranslation } from '../../../utils/translations';

import { liveSelector } from 'store/modules/competition-nav/selectors';
import { UPDATE_LIVE } from 'store/modules/competition-nav/actions';
import { useGetLiveLadderQuery } from 'store/modules/api/cfs-api';

export const LiveToggleNavItem = ({ roundId }) => {
    const dispatch = useDispatch();

    const live = useSelector(liveSelector);

    const { data: liveLadderData = [] } = useGetLiveLadderQuery({
        roundId: roundId
    });

    const showLiveToggle =
        liveLadderData?.liveLadderPositions?.find((item) => item?.playing) ||
        false;

    if (!showLiveToggle) {
        return <></>;
    }

    return (
        <div className={`competition-nav__live-toggle`}>
            <SwitchInput
                id="ladder-live-toggle"
                label={getTranslation('label.Live')}
                isChecked={live.isActive}
                onChange={(isActive) =>
                    dispatch(UPDATE_LIVE.request({ isActive }))
                }
            />
        </div>
    );
};

LiveToggleNavItem.propTypes = {
    roundId: PropTypes.string
};
