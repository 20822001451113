/* eslint-disable react/prop-types */
import React from 'react';

import DefaultCellRenderer from 'custom-table/js/components/DefaultCell/DefaultCellRenderer';
import AllClubsCellRenderer from './AllClubsCellRenderer';

const TotalTableRow = ({
    displayedFields,
    sortedTableData,
    totalTableRows,
    customTable,
    getTranslation,
    // eslint-disable-next-line no-unused-vars
    ...additionalProps
}) => {
    if (!sortedTableData || !customTable.config.totalTableRow) {
        return null;
    }

    // Set the first column (clubs) to be the all clubs for the totals row
    if (totalTableRows[0].fieldName === 'club') {
        totalTableRows[0].customCellRenderer = AllClubsCellRenderer;
    }

    return totalTableRows.map((field, index) => {
        if (field.customCellRenderer) {
            // use the custom renderer; the 2nd argument can also be used to pass custom props
            return React.createElement(field.customCellRenderer, {
                value: field.value,
                key: `tableRow_Total_${displayedFields[index].fieldName}`,
                customTable: customTable,
                field: field,
                getTranslation: getTranslation
            });
        }
        // use the default renderer
        return (
            <DefaultCellRenderer
                className={field.tableCellClass}
                value={field.value}
                key={`tableRow_Total_${displayedFields[index].fieldName}`}
            />
        );
    });
};

export default TotalTableRow;
