import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

export const PlayerModalPickBar = ({ pickOrder, draftedBy, roundPicked }) => {
    return (
        <div className="modal-centered__draft-player-pick-container">
            <div className="modal-centered__draft-player-pick-data">
                <div className="modal-centered__draft-player-pick-round">
                    <p className="modal-centered__draft-player-pick-title">
                        {getTranslation('label.draftTracker.round')}
                    </p>
                    <p className="modal-centered__draft-player-pick-info">
                        {roundPicked}
                    </p>
                </div>
                <div className="modal-centered__draft-player-pick-order">
                    <p className="modal-centered__draft-player-pick-title">
                        {getTranslation('label.draftTracker.pick')}
                    </p>
                    <p className="modal-centered__draft-player-pick-info">
                        {pickOrder}
                    </p>
                </div>
            </div>

            <div className="modal-centered__draft-player-pick-club">
                <div className="modal-centered__draft-player-pick-club-by">
                    <p className="modal-centered__draft-player-pick-title">
                        {getTranslation('label.draftTracker.draftedBy')}
                    </p>
                    <p className="modal-centered__draft-player-pick-info--club">
                        {PULSE.app.common.team.getTeamNameFromAbbr(draftedBy)}
                    </p>
                </div>
                <div className="modal-centered__draft-player-pick-club-logo-container">
                    <SvgIcon icon={`aflc-${draftedBy}`} className="icon" />
                </div>
            </div>
        </div>
    );
};

PlayerModalPickBar.propTypes = {
    pickOrder: PropTypes.number,
    draftedBy: PropTypes.string,
    roundPicked: PropTypes.number
};
