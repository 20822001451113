import React from 'react';
import PropTypes from 'prop-types';
import { getTranslation } from 'common/react/utils/translations';
import { SvgIcon } from 'common/react/components/SvgIcon';

/**
 * @param {object} props - Props.
 * @param {string} props.type - Type of stat.
 * @param {number} props.stat - Top performance value.
 * @param {string} props.teamAbbr - Opponent team abbreviation.
 * @param {string} props.teamName - Opponent team name.
 * @param {string} props.roundName - Round name.
 * @param {string} props.roundYear - Round year.
 * @returns {JSX.Element} Component.
 */
export const KeyStatsAndPerformanceTop = ({
    type,
    stat,
    teamAbbr,
    teamName,
    roundName,
    roundYear
}) => (
    <div className="key-stats-and-performance__top-performance-details">
        {stat ? (
            <>
                <p className="key-stats-and-performance__top-performance-title u-hide-from-desktop">
                    {getTranslation(
                        'label.stats.top' + type + 'Performance.short'
                    )}
                </p>
                <p className="key-stats-and-performance__top-performance-value">
                    {stat}
                </p>
                <div className="key-stats-and-performance__top-performance-opponent">
                    {teamAbbr ? (
                        <div className="key-stats-and-performance__top-performance-opponent-flag">
                            <SvgIcon icon={`aflc-${teamAbbr}-flag`} />
                        </div>
                    ) : null}
                    <div>
                        {teamName ? (
                            <div className="key-stats-and-performance__top-performance-opponent-name">
                                <span>v</span> {teamName}
                            </div>
                        ) : null}
                        {roundName && (
                            <div className="key-stats-and-performance__top-performance-opponent-round">
                                {roundName}
                                {roundYear ? ', ' + roundYear : ''}
                            </div>
                        )}
                    </div>
                </div>
            </>
        ) : (
            <div className="key-stats-and-performance__top-performance-error">
                {getTranslation('label.stats.noStatsError')}
            </div>
        )}
    </div>
);

KeyStatsAndPerformanceTop.propTypes = {
    type: PropTypes.string.isRequired,
    stat: PropTypes.number,
    teamAbbr: PropTypes.string,
    teamName: PropTypes.string,
    roundName: PropTypes.string,
    roundYear: PropTypes.string
};
