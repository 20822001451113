import { getTranslation } from 'common/react/utils/translations';

/**
 * @param {object} item - Draft pick data.
 * @param {object} currentPick - Current draft number.
 * @param {boolean} isLive - True if live
 * @param {string} year - Year parameter
 * @returns {object[]} Row data for the given pick.
 */
export const composeRowData = (item, currentPick, isLive, year) => {
    if (!isLive) {
        let fullName = '';

        fullName = createFullName(item.firstName, item.surname, fullName);

        return createPlayerModelNonLive(item, fullName, year);
    }

    return createPlayerModelLive(item, currentPick, year);
};

const createPlayerModelLive = (item, currentPick, year) => {
    return {
        pick: item.pickNumber ?? '',
        club: PULSE.app.common.team.getTeamAbbr(item.teamId) ?? '',
        player: {
            fullName: item.fullName ?? '-',
            selecting:
                item.pickNumber === currentPick
                    ? getTranslation('label.draftTracker.selecting')
                    : false,
            passed: item.passed ?? false,
            fatherSon: item.fatherSon ?? false,
            academy: item.academy ?? false,
            photoUrl: item.photoUrl ?? false,
            profileUrl: PULSE.app.common.url.getProspectProfileURL(
                item.playerId,
                year
            )
        },
        playerId: item.playerId ?? '-',
        position: item.position ? composePositionAbbr(item.position) : '-',
        positionFull: item.position ?? '-',
        roundPicked: item.roundNumber ?? '-',
        height: item.height
            ? item.height +
              ' ' +
              getTranslation('label.centimetre.abbreviation')
            : '-',
        weight: item.weight
            ? item.weight + ' ' + getTranslation('label.kilogram.abbreviation')
            : '-',
        dob:
            (item.dob?.split('/')[0].length === 1
                ? '0' + item.dob
                : item.dob) ?? '-',
        from: item.juniorClub ?? '-'
    };
};

const createPlayerModelNonLive = (item, fullName, year) => {
    return {
        player: {
            fullName: fullName,
            selecting: false,
            passed: item.passed ?? false,
            fatherSon: item.fatherSon ?? false,
            academy: item.academy ?? false,
            photoUrl: item.photoUrl ?? false,
            profileUrl: PULSE.app.common.url.getProspectProfileURL(
                item.playerId,
                year
            )
        },
        playerId: item.playerId ?? '-',
        position: item.position ? composePositionAbbr(item.position) : '-',
        positionFull: item.position ?? '-',
        roundPicked: item.roundNumber ?? '-',
        height: item.height
            ? item.height +
              ' ' +
              getTranslation('label.centimetre.abbreviation')
            : '-',
        weight: item.weight
            ? item.weight + ' ' + getTranslation('label.kilogram.abbreviation')
            : '-',
        dob:
            (item.dob?.split('/')[0].length === 1
                ? '0' + item.dob
                : item.dob) ?? '-',
        from: item.juniorClub ?? '-'
    };
};

const createFullName = (firstName, surname, fullName) => {
    if (firstName && surname) {
        return firstName + ' ' + surname;
    } else if (fullName) {
        return fullName;
    }
    return '-';
};

/**
 *
 * @param {string} position - Full name of the player's position
 * @returns {string} - Abbreviation of the player's position
 */
function composePositionAbbr(position) {
    switch (position) {
        case 'KEY_DEFENDER':
            return getTranslation(
                'label.statsLeaders.position.abbreviation.keyDefender'
            );
        case 'MEDIUM_DEFENDER':
            return getTranslation(
                'label.statsLeaders.position.abbreviation.mediumDefender'
            );
        case 'MIDFIELDER':
            return getTranslation(
                'label.statsLeaders.position.abbreviation.midfielder'
            );
        case 'MIDFIELDER_FORWARD':
            return getTranslation(
                'label.statsLeaders.position.abbreviation.midfielderForward'
            );
        case 'MEDIUM_FORWARD':
            return getTranslation(
                'label.statsLeaders.position.abbreviation.mediumForward'
            );
        case 'KEY_FORWARD':
            return getTranslation(
                'label.statsLeaders.position.abbreviation.keyForward'
            );
        case 'RUCK':
            return getTranslation(
                'label.statsLeaders.position.abbreviation.ruck'
            );
        default:
            return '-';
    }
}
