import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param props.children
 * @param props.isSingleModifier
 * @returns {JSX.Element} Component.
 */
export const StatsSectionContentHeaderBelow = ({
    children,
    isSingleModifier = false
}) => {
    if (!React.Children.toArray(children).find((child) => !!child)) {
        return <></>;
    }

    return (
        <>
            {children && (
                <div
                    className={`stats-section-header-below ${
                        isSingleModifier
                            ? 'stats-section-header-below--single'
                            : ''
                    }`}
                >
                    {React.Children.map(children, (child) => (
                        <div className="stats-section-header-below__item">
                            {child}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

StatsSectionContentHeaderBelow.defaultProps = {
    name: 'StatsSectionContentHeaderBelow'
};

StatsSectionContentHeaderBelow.propTypes = {
    children: PropTypes.any,
    isSingleModifier: PropTypes.bool
};
