import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { PlayerModal } from '../components/player-modal';

const BrownlowPlayerModalProvider = ({ store }) => (
    <Provider store={store}>
        <PlayerModal />
    </Provider>
);

BrownlowPlayerModalProvider.propTypes = {
    store: PropTypes.object.isRequired
};

export default BrownlowPlayerModalProvider;
