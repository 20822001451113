/**
 * A sort function helper for dates
 *
 * @see https://stackoverflow.com/a/65740084
 * @param {*} a - The first item to compare
 * @param {*} b - The second item to compare
 * @returns {number} - A number to be used in array.sort
 */

export const sortDateTimes = (a, b) => (a < b ? -1 : a > b ? 1 : 0);
