import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { StatsLeaders } from '../components/stats-leaders';

/**
 * @param {any} props - Props.
 * @returns {JSX.Element} Component.
 */
export const StatsLeadersProvider = ({ store, data }) => {
    return (
        <ErrorBoundary fallback={<></>}>
            <Provider store={store}>
                <BrowserRouter>
                    <StatsLeaders {...data} />
                </BrowserRouter>
            </Provider>
        </ErrorBoundary>
    );
};

StatsLeadersProvider.propTypes = {
    store: PropTypes.object,
    data: PropTypes.object
};
