import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

/**
 * A UI component replicating the media thumbnail markup as data is fetched.
 *
 * @returns {JSX.Element} The Skeleton Loader.
 */
export const ClubNewsSkeletonLoader = () => {
    return (
        <div className="club-news__item-skeleton">
            <Skeleton className="club-news__item-skeleton-image" />
            <div className="club-news__item-skeleton-content">
                <Skeleton className="club-news__item-skeleton-header" />
                <Skeleton className="club-news__item-skeleton-description" />
                <Skeleton className="club-news__item-skeleton-footer" />
            </div>
        </div>
    );
};
