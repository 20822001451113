import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @returns {JSX.Element} Component.
 */
export const StatsCard = (props) => {
    return (
        <div className="stats-card">
            {props.children}
            <div className="stats-card__background"></div>
        </div>
    );
};

StatsCard.propTypes = {
    children: PropTypes.node.isRequired
};
