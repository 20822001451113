import { normalize, schema } from 'normalizr';

const team = new schema.Entity('teams', {}, { idAttribute: 'teamId' });

const playerDetail = new schema.Entity(
    'players',
    {},
    { idAttribute: (_value, parent) => parent.playerId }
);

const stat = new schema.Entity(
    'stats',
    {
        playerDetails: playerDetail,
        team: team
    },
    {
        idAttribute: (value, parent) => {
            return `${value.playerId}_${parent.search.context.identifier}_${
                value.__originalIndex + 1
            }`;
        }
    }
);

/**
 * @param {object} apiData - API response body.
 * @returns {object} The normalized response.
 */
export const normalizeStatsLeaders = (apiData) => {
    const processedData = {
        ...apiData,
        players: apiData.players.map((player, index) => ({
            ...player,
            __originalIndex: index
        }))
    };

    return normalize(processedData, { players: [stat] });
};
