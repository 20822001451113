import React from 'react';
import PropTypes from 'prop-types';
import { TeamAnnouncementsFieldPlayer } from './TeamAnnouncementsFieldPlayer';

/**
 * Provides a unique wrapper element for each player group and renders the
 * TeamAnnouncementsFieldPlayer component for each player in the group.
 *
 * @param {object} props - The props
 * @param {Array} props.playerGroup - The grouped player data.
 * @param {object} props.homeData - The home team's player data.
 * @param {object} props.awayData - The away team's player data.
 * @param {string} props.teamDisplay - The team(s) to display.
 * @returns {React.JSX.Element} - The rendered component
 */
export const TeamAnnouncementsFieldGroup = ({
    playerGroup,
    homeData,
    awayData,
    teamDisplay
}) => {
    return playerGroup?.map((player) => {
        const homePlayer = player?.home;
        const awayPlayer = player?.away;

        return teamDisplay !== 'both' ? (
            <div
                className="team-announcements-field__field-player-wrapper"
                key={`fieldPlayer_${
                    teamDisplay === 'home'
                        ? homePlayer?.player.player.playerId
                        : awayPlayer?.player.player.playerId
                }`}
            >
                <TeamAnnouncementsFieldPlayer
                    player={teamDisplay === 'home' ? homePlayer : awayPlayer}
                    homeOrAway={teamDisplay}
                    teamAbbr={
                        teamDisplay === 'home'
                            ? homeData?.teamId.toLowerCase()
                            : awayData?.teamId.toLowerCase()
                    }
                    showPhoto={true}
                ></TeamAnnouncementsFieldPlayer>
            </div>
        ) : (
            <div
                className="team-announcements-field__player-tag-badge-wrapper"
                key={`fieldPlayer_${homePlayer?.player.player.playerId}-${awayPlayer?.player.player.playerId}`}
            >
                <TeamAnnouncementsFieldPlayer
                    player={homePlayer}
                    homeOrAway={'home'}
                    teamAbbr={homeData?.teamId.toLowerCase()}
                    showPhoto={false}
                ></TeamAnnouncementsFieldPlayer>
                <TeamAnnouncementsFieldPlayer
                    player={awayPlayer}
                    homeOrAway={'away'}
                    teamAbbr={awayData?.teamId.toLowerCase()}
                    showPhoto={false}
                ></TeamAnnouncementsFieldPlayer>
            </div>
        );
    });
};

TeamAnnouncementsFieldGroup.propTypes = {
    playerGroup: PropTypes.array,
    homeData: PropTypes.object,
    awayData: PropTypes.object,
    teamDisplay: PropTypes.string
};
