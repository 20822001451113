import { createSelector } from 'reselect';
import { playerComparisonSelector } from './simple-selectors';

export const playersSelector = createSelector(
    playerComparisonSelector,
    (playerComparison) => ({
        1: playerComparison.playerOne,
        2: playerComparison.playerTwo
    })
);
