/**
 * @param {any} draft - Draft state.
 * @param {any} payload - Action payload.
 */
export const setLive = (draft, payload) => {
    draft.live = {
        ...draft.live,
        ...payload
    };
};
