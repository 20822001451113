import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { HighlightsPlayer } from './HighlightsPlayer';
import { useGetContentQuery } from 'common/store/modules/api/content-api';

export const Highlights = ({
    accountId,
    policyKey,
    videoPlayerId,
    anchorName,
    playerId
}) => {
    const [playerPulseId, setPlayerPulseId] = useState();

    useEffect(() => {
        PULSE.app.common.pulseId
            .getPlayerPulseId(playerId)
            .then((res) => setPlayerPulseId(res));
    }, [playerId]);

    const { videoItem } = useGetContentQuery(
        {
            type: 'VIDEO',
            params: {
                pageSize: 1,
                references: `AFL_PLAYER:${playerPulseId}`,
                tagExpression: `(%"ProgramCategory:Draft")`
            }
        },
        {
            selectFromResult: ({ data: videoData }) => ({
                videoItem: videoData?.content[0]
            }),
            skip: !playerPulseId
        }
    );

    return (
        <>
            {videoItem && (
                <div className="pp-bio-awards">
                    <div className="pp-bio-awards__row">
                        <h3 className="pp-bio-awards__title pp-title">
                            {PULSE.I18N.lookup('label.highlights')}
                        </h3>
                        <div className="pp-bio-awards__info-container">
                            <div className="pp-bio-awards__info-row">
                                <HighlightsPlayer
                                    videoItem={videoItem}
                                    accountId={accountId}
                                    policyKey={policyKey}
                                    videoPlayerId={videoPlayerId}
                                    anchorName={anchorName}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

Highlights.propTypes = {
    accountId: PropTypes.string.isRequired,
    policyKey: PropTypes.string.isRequired,
    videoPlayerId: PropTypes.string.isRequired,
    anchorName: PropTypes.string,
    playerId: PropTypes.string
};
