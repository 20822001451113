import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../../setup-react';

// ===== Signals ============================================================ //

// Data
export const FETCH_PLAYER_MODAL_DATA = createSignalAction(
    'brownlow/player-modal',
    'FETCH_PLAYER_MODAL_DATA'
);

// Player Modal
export const UPDATE_PLAYER_MODAL = createSignalAction(
    'brownlow/player-modal',
    'UPDATE_PLAYER_MODAL'
);

// Leaderboard
export const FETCH_PLAYER_MODAL_LEADERBOARD_DATA = createSignalAction(
    'brownlow/player-modal',
    'FETCH_PLAYER_MODAL_LEADERBOARD_DATA'
);

// Predicted
export const FETCH_PREDICTED_DATA = createSignalAction(
    'brownlow/player-modal',
    'FETCH_PREDICTED_DATA'
);

// ===== Deltas ============================================================= //

// Data
export const SET_PLAYER_MODAL_DATA = createDeltaActionType(
    'brownlow/player-modal',
    'SET_PLAYER_MODAL_DATA'
);

export const setPlayerModalData = createActionCreator(SET_PLAYER_MODAL_DATA);

export const SET_PLAYER_MODAL_DATA_REQUEST = createDeltaActionType(
    'brownlow/match-by-match',
    'SET_PLAYER_MODAL_DATA_REQUEST'
);
export const setPlayerModalDataRequest = createActionCreator(
    SET_PLAYER_MODAL_DATA_REQUEST
);

export const REMOVE_PLAYER_MODAL_DATA_REQUEST = createDeltaActionType(
    'brownlow/match-by-match',
    'REMOVE_PLAYER_MODAL_DATA_REQUEST'
);
export const removePlayerModalDataRequest = createActionCreator(
    REMOVE_PLAYER_MODAL_DATA_REQUEST
);

// Player Modal
export const SET_PLAYER_MODAL = createDeltaActionType(
    'brownlow/player-modal',
    'SET_PLAYER_MODAL'
);
export const setPlayerModal = createActionCreator(SET_PLAYER_MODAL);

// Leaderboard
export const SET_PLAYER_MODAL_LEADERBOARD_DATA = createDeltaActionType(
    'brownlow/player-modal',
    'SET_PLAYER_MODAL_LEADERBOARD_DATA'
);
export const setModalLeaderboardData = createActionCreator(
    SET_PLAYER_MODAL_LEADERBOARD_DATA
);

export const SET_PLAYER_MODAL_LEADERBOARD_DATA_REQUEST = createDeltaActionType(
    'brownlow/player-modal',
    'SET_PLAYER_MODAL_LEADERBOARD_DATA_REQUEST'
);
export const setModalLeaderboardDataRequest = createActionCreator(
    SET_PLAYER_MODAL_LEADERBOARD_DATA_REQUEST
);

export const REMOVE_LEADERBOARD_DATA_REQUEST = createDeltaActionType(
    'brownlow/player-modal',
    'REMOVE_LEADERBOARD_REQUEST'
);
export const removeModalLeaderboardDataRequest = createActionCreator(
    REMOVE_LEADERBOARD_DATA_REQUEST
);

// Predicted
export const SET_PREDICTED_DATA = createDeltaActionType(
    'brownlow/player-modal',
    'SET_PREDICTED_DATA'
);
export const setPredictedData = createActionCreator(SET_PREDICTED_DATA);

export const SET_PREDICTED_DATA_REQUEST = createDeltaActionType(
    'brownlow/player-modal',
    'SET_PREDICTED_DATA_REQUEST'
);
export const setPredictedDataRequest = createActionCreator(
    SET_PREDICTED_DATA_REQUEST
);

export const REMOVE_PREDICTED_DATA_REQUEST = createDeltaActionType(
    'brownlow/player-modal',
    'REMOVE_PREDICTED_DATA_REQUEST'
);
export const removePredictedDataRequest = createActionCreator(
    REMOVE_PREDICTED_DATA_REQUEST
);
