import { createSelector } from 'reselect';
import { performanceChartNavSelector } from '.';

/**
 * @returns {( object ) => object|null} Round.
 */
export const roundSelector = createSelector(
    performanceChartNavSelector,
    (state) => state.rounds,
    (nav, rounds) => {
        try {
            return rounds[`season_${nav.season.id}`][`round_${nav.round.id}`];
        } catch (err) {
            return null;
        }
    }
);
