export const assignPositions = (data) => {
    if (!data.length) {
        return;
    }

    let position = 1;
    let recentVotes = data[0].totalVotes;

    // sort just incase
    data.sort((a, b) => b.totalVotes - a.totalVotes);

    data.forEach((item, index) => {
        if (!item.eligible) {
            item.position = null;
        } else {
            if (item.totalVotes !== recentVotes) {
                position =
                    index +
                    1 -
                    data.slice(0, index).filter((i) => !i.eligible).length;
            }
            item.position = position;
            recentVotes = item.totalVotes;
        }
    });

    return data;
};
