import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from '../../../../../common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';

export const MainMenuRegionalDisplay = ({ regionsPage }) => {
    const selectedRegion = useSingleSiteSetting('region');

    const displayedRegion = selectedRegion
        ? selectedRegion.name
        : getTranslation(`region.selector.selectACountryOrRegion`);
    return (
        <div className="sub-menu__item">
            <a
                href={regionsPage}
                className="sub-menu__link sub-menu__regional-display-link-wrapper"
            >
                <SvgIcon
                    className="sub-menu__regional-display-icon"
                    icon="globe"
                />
                <div>
                    <p className="">{displayedRegion}</p>
                    <p className="sub-menu__regional-display-link">
                        {getTranslation(`region.selector.changeLocation`)}
                    </p>
                </div>
            </a>
        </div>
    );
};

MainMenuRegionalDisplay.propTypes = {
    regionsPage: PropTypes.string
};
