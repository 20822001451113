/* eslint-disable react/prop-types */
import React from 'react';

import DefaultCellRenderer from 'custom-table/js/components/DefaultCell/DefaultCellRenderer';

const TableRow = ({
    tableRow,
    multipleTableIndex,
    state,
    customTable,
    displayedFields,
    getTranslation,
    config
}) => {
    if (
        !tableRow ||
        (typeof multipleTableIndex !== 'undefined' &&
            typeof tableRow.multipleTableIndex !== 'undefined' &&
            multipleTableIndex !== tableRow.multipleTableIndex)
    ) {
        return null;
    }

    const fieldsToDisplay = displayedFields.reduce((accum, field) => {
        const value = tableRow[field.fieldName];
        let tableCellClass = field.tableCellCustomClass
            ? field.tableCellCustomClass
            : '';
        tableCellClass =
            customTable.sortBy.fieldName === field.fieldName
                ? tableCellClass + ' custom-table__cell--sorted'
                : tableCellClass;

        return [
            ...accum,
            {
                customCellRenderer: field.customCellRenderer,
                value: value,
                fieldName: field.fieldName,
                tableCellClass: tableCellClass,
                visibiltyConditionFunc: field.visibiltyConditionFunc
            }
        ];
    }, []);

    // Check for visibility condition functions and fire any found
    const filtereredFields = fieldsToDisplay.filter((field) => {
        if (typeof field.visibiltyConditionFunc !== 'function') {
            return true;
        }

        return field.visibiltyConditionFunc(customTable.dataJson);
    });

    const tableRowMarkup = filtereredFields.map((field, index) => {
        if (field.customCellRenderer) {
            // use the custom renderer; the 2nd argument can also be used to pass custom props
            return React.createElement(field.customCellRenderer, {
                value: field.value,
                key: `${tableRow.rowIdentifier}_${displayedFields[index].fieldName}`,
                customTable: customTable,
                field: field,
                getTranslation: getTranslation,
                config,
                tableRow
            });
        }
        // use the default renderer
        return (
            <DefaultCellRenderer
                className={field.tableCellClass}
                value={field.value}
                key={`${tableRow.rowIdentifier}_${displayedFields[index].fieldName}`}
            />
        );
    });

    return (
        <tr className="custom-table__row custom-table__row--body">
            {tableRowMarkup}
            <td
                className={`custom-table__cell custom-table__cell--empty ${
                    state.manageFieldsBarDisplayed === true ? '' : 'u-hide'
                }`}
            ></td>
        </tr>
    );
};

export default TableRow;
