import { put } from 'redux-saga/effects';
import { setDataType } from '../actions';

/**
 * @typedef {object} UpdateDataTypeAction
 * @property {string} type
 * @property {object} payload
 * @property {boolean} payload.isActive
 */

/**
 * @param {UpdateDataTypeAction} action - The Redux action.
 */
export function* updateDataType(action) {
    yield put(setDataType(action.payload));
}
