import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { DraftProspectBio } from '../components/draft-prospect-bio/DraftProspectBio';

export const DraftProspectBioProvider = ({ store, data }) => (
    <Provider store={store}>
        <DraftProspectBio {...data} />
    </Provider>
);

DraftProspectBioProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
