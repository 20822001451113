import { createApi } from '@reduxjs/toolkit/query/react';
import { misBaseQuery } from './base-queries/mis-request';

import { fetchWithRetryBQ } from './base-queries/fetch-with-retry';

// RTK Query update: Define an api service with a base URL and retry attempts
// RTK Query update: Endpoints can be added here or injected in components
export const cfsCommentaryApi = createApi({
    reducerPath: 'cfsCommentaryAPI',
    baseQuery: fetchWithRetryBQ(
        PULSE.app.environment.cfsCommentary,
        misBaseQuery
    ),
    endpoints: (build) => ({
        getComms: build.query({
            query: (id) => `/${id}`,
            transformResponse: (response) => {
                if (response.lastUpdated) {
                    delete response.lastUpdated;
                }
                return response;
            }
        })
    })
});
