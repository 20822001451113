/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';

import { TableCaption } from './TableCaption';
import { TableBody } from './TableBody';
import { TableFoot } from './TableFoot';
import { useTableSortData } from '../hooks';
import { UPDATE_SORT } from 'store/modules/statspro/career-and-season-stats/actions';
import { FIRST_SORTABLE_COLUMN_INDEX } from '../../../config/columns';
import { TableHead } from './TableHead';
import { StatsTable } from 'widgets/statspro/js/components/common/stats-table/StatsTable';
import { careerAndSeasonStatsSortSelector } from 'store/modules/statspro/career-and-season-stats/selectors/sort-selector';

const PAGE_SIZE = 20;
export const Table = ({
    columns,
    data,
    modifier,
    navSeasonId,
    cssClass,
    isProspect,
    isProspectModal,
    hideClubLogos
}) => {
    const dispatch = useDispatch();
    const sort = useSelector(careerAndSeasonStatsSortSelector);

    const stickyOffset = 96;

    const sortByProspect = isProspect
        ? []
        : [
              {
                  id:
                      sort.columnId ??
                      columns[FIRST_SORTABLE_COLUMN_INDEX].accessor,
                  desc: sort.sortDirection === 'descending'
              }
          ];

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data,
                initialState: {
                    // @ts-ignore
                    pageSize: PAGE_SIZE,
                    sortBy: sortByProspect
                },
                disableSortRemove: true
            },
            useFilters,
            useSortBy,
            usePagination
        );

    const { headerGroup, sortedColumn, sortDirection } = useTableSortData(
        headerGroups,
        isProspect
    );

    // ===== Effects ======================================================== //

    useEffect(() => {
        if (sortedColumn) {
            dispatch(
                UPDATE_SORT.request({
                    columnId: sortedColumn.id,
                    sortDirection,
                    force: false
                })
            );
        } else if (sort.force) {
            dispatch(
                UPDATE_SORT.request({
                    force: false
                })
            );
        }
    }, [sortedColumn, sortDirection]);

    // ===== Render ========================================================= //

    return (
        <StatsTable
            modifier={modifier}
            getTableProps={getTableProps}
            cssClass={cssClass}
            stickyOffset={stickyOffset}
            stickyHeader={<TableHead headerGroup={headerGroup} />}
        >
            <TableCaption
                sortedColumnId={sortedColumn.id}
                sortDirection={sortDirection}
            />
            <TableHead
                headerGroup={headerGroup}
                isProspectModal={isProspectModal}
            />
            <TableBody
                rows={rows}
                prepareRow={prepareRow}
                getTableBodyProps={getTableBodyProps}
                isProspect={isProspect}
                hideClubLogos={hideClubLogos}
            />
            <TableFoot
                columns={columns}
                rows={rows}
                navSeasonId={navSeasonId}
                isProspectModal={isProspectModal}
            />
        </StatsTable>
    );
};

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    modifier: PropTypes.string,
    navSeasonId: PropTypes.number.isRequired,
    cssClass: PropTypes.string,
    isProspect: PropTypes.bool,
    isProspectModal: PropTypes.bool,
    hideClubLogos: PropTypes.bool.isRequired
};
