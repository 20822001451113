import React from 'react';
import PropTypes from 'prop-types';

import { getTranslation } from 'common/react/utils/translations';
import { getLeadingScore } from 'common/react/utils/get-leading-score';

export const FixtureSummary = ({ home, away, isLive = false, matchStatus }) => {
    const leaderObj = getLeadingScore(
        home,
        away,
        home.liveScore || home.liveScore === 0
            ? home.liveScore
            : home.score?.totalScore,
        away.liveScore || away.liveScore === 0
            ? away.liveScore
            : away.score?.totalScore
    );

    if (
        matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.TBC ||
        matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.POSTPONED ||
        matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.CANCELLED
    ) {
        return (
            <div className="fixtures__match-summary">
                {getTranslation(
                    `label.fixtures.secondaryStatus.${matchStatus.toLowerCase()}`
                )}
            </div>
        );
    }

    if (leaderObj.draw) {
        return (
            <div className="fixtures__match-summary">
                {isLive
                    ? getTranslation('label.fixtures.drawText')
                    : getTranslation('label.fixtures.drawTextEnded')}
            </div>
        );
    }

    if (leaderObj && leaderObj.score) {
        return (
            <div className="fixtures__match-summary">
                {isLive ? (
                    <>
                        {`${leaderObj.team.name} ${getTranslation(
                            'label.fixtures.leadBy'
                        )} ${leaderObj.score}`}
                    </>
                ) : (
                    <>
                        {`${leaderObj.team.name} ${getTranslation(
                            'label.fixtures.wonBy'
                        )} ${leaderObj.score}`}
                    </>
                )}
            </div>
        );
    }

    return <></>;
};

FixtureSummary.propTypes = {
    home: PropTypes.object,
    away: PropTypes.object,
    isLive: PropTypes.bool,
    matchStatus: PropTypes.string
};
