import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { lookupTranslation } from 'common/react/utils/translations';

const SCROLL_DISTANCE_PX = 150;
const SCROLL_DEBOUNCE_MS = 100;

export const TableScrollButtons = ({
    scrollWrapperRef,
    scrollPosition,
    wrapperRef,
    leftButtonRef,
    rightButtonRef
}) => {
    const [canScroll, setCanScroll] = useState({
        left: false,
        right: true
    });

    //useRef - see where this component exists and where the scrollable exists. Have a useRef from scrollable container to the buttons
    // or pass a function that finds the highest shared parent - then have a function in that that

    const windowSize = useWindowSize();

    const isLadderTable =
        wrapperRef?.current?.closest('.js-ladder')?.firstChild; // special case as the ladder can have 2 tables for aflw conferences

    const scrollableContainerList = document.querySelectorAll(
        '.js-scrollable-container'
    );
    const scrollableContainer = isLadderTable
        ? isLadderTable
        : scrollableContainerList[scrollableContainerList.length - 1];

    // @ts-ignore
    const recalculateCanScrollValues = _.debounce(() => {
        if (!scrollWrapperRef.current) {
            return;
        }

        const { scrollWidth } = scrollWrapperRef.current;
        const { clientWidth } = wrapperRef.current;

        setCanScroll({
            left: scrollPosition !== 0,
            right: scrollPosition !== scrollWidth - clientWidth
        });
    }, SCROLL_DEBOUNCE_MS);

    useEffect(() => {
        recalculateCanScrollValues();
    }, [scrollPosition, windowSize]);

    return (
        <div className="stats-table-scroll-buttons">
            <button
                ref={leftButtonRef}
                disabled={!canScroll.left}
                className="stats-table-scroll-button stats-table-scroll-button--left"
                onClick={() => scroll(scrollableContainer, -SCROLL_DISTANCE_PX)}
                aria-label={lookupTranslation('label.scrollLeft')}
            >
                <SvgIcon
                    icon="chevron-v2-left"
                    className="stats-table-scroll-button__icon"
                />
            </button>
            <button
                ref={rightButtonRef}
                disabled={!canScroll.right}
                className="stats-table-scroll-button stats-table-scroll-button--right"
                onClick={() => scroll(scrollableContainer, SCROLL_DISTANCE_PX)}
                aria-label={lookupTranslation('label.scrollRight')}
            >
                <SvgIcon
                    icon="chevron-v2-right"
                    className="stats-table-scroll-button__icon"
                />
            </button>
        </div>
    );
};

const scroll = (elem, left) => {
    elem.scrollBy({ left, behavior: 'smooth' });
};

TableScrollButtons.propTypes = {
    scrollWrapperRef: PropTypes.object.isRequired,
    scrollPosition: PropTypes.number.isRequired,
    wrapperRef: PropTypes.object,
    leftButtonRef: PropTypes.any,
    rightButtonRef: PropTypes.any
};
