import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { SeasonLeadersSlider } from '../components/season-leaders';
import { BrowserRouter } from 'react-router-dom';

/**
 * @param {any} props - Props.
 * @returns {JSX.Element} Component.
 */
export const SeasonLeadersProvider = ({ store, data }) => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <div className="wrapper">
                    <SeasonLeadersSlider {...data} />
                </div>
            </BrowserRouter>
        </Provider>
    );
};

SeasonLeadersProvider.propTypes = {
    store: PropTypes.object,
    data: PropTypes.object
};
