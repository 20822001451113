import React from 'react';
import PropTypes from 'prop-types';

import { useGetMatchesQuery } from 'common/store/modules/api/afl-api';

export const Header = ({ competitionId, compSeason, round, matchID }) => {
    const { match } = useGetMatchesQuery(
        {
            competitionId: competitionId,
            compSeasonId: compSeason.id,
            roundNumber: round.roundNumber
        },
        {
            selectFromResult: ({ data }) => ({
                match: data?.find((item) => item.id === matchID)
            })
        }
    );

    const roundTitle = `${round.name}${
        match ? ` - ${match.home?.team?.name} v ${match.away?.team?.name}` : ''
    }`;

    return (
        <header className="video-hub-video-listing__header js-round-header">
            <h2 className="video-hub-video-listing__title">{roundTitle}</h2>
        </header>
    );
};

Header.propTypes = {
    competitionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    compSeason: PropTypes.object,
    round: PropTypes.object,
    matchID: PropTypes.any
};
