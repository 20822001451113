import { hasRoundFinished } from './hasRoundFinished';

/**
 * Given an array of rounds and the "currentRound" of a compseaosn, find out
 * what the last round is we should display by looking at the round that ended
 * most recently.
 *
 * @param {object[]} rounds - An array of round objects from a rounds api query
 * @param {number} compSeasonCurrRound - The current round of the compseason, work
 * backwards from here
 * @returns {object|undefined} The round that most recently finished or undefined if no rounds have finished yet
 */
export function findLastRound(rounds, compSeasonCurrRound) {
    // Reverse the rounds so we start with the last one
    const reversedRounds = [...rounds].reverse();

    // Find where our current round is
    const currentRoundIndex = reversedRounds.findIndex(
        (round) => round.roundNumber === compSeasonCurrRound
    );

    // Slice the array into just the current round and it's previous rounds
    const slicedRounds = reversedRounds.slice(currentRoundIndex);

    // Work through the rounds until we find one that's finished
    const foundLastRound = slicedRounds.find((round) =>
        hasRoundFinished(round)
    );

    // If we find one that's finished, return it
    if (foundLastRound) {
        return foundLastRound;
    }

    // Otherwise, return round 1
    return slicedRounds[slicedRounds.length - 1];
}
