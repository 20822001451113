import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useGetContentQuery } from 'store/modules/api/content-api';

/**
 * @param root0
 * @param root0.data
 * @returns {JSX.Element} Bio Content section.
 */
export const BioContent = ({ data }) => {
    const playerId = data?.playerId;

    const [pulsePlayerId, setPulsePlayerId] = useState(null);

    const { bioText } = useGetContentQuery(
        {
            type: 'BIO',
            params: {
                pageSize: 1,
                references: `AFL_PLAYER:${pulsePlayerId}`
            }
        },
        {
            selectFromResult: ({ data: bioData }) => ({
                bioText: bioData?.content[0]?.content
            }),
            skip: !pulsePlayerId
        }
    );

    useEffect(() => {
        PULSE.app.common.pulseId.getPlayerPulseId(playerId).then((res) => {
            setPulsePlayerId(res);
        });
    }, [playerId]);

    return (
        <>
            {bioText && (
                <>
                    <div className="pp-bio-awards">
                        <div className="pp-bio-awards__row">
                            <h3 className="pp-bio-awards__title pp-title">
                                {PULSE.I18N.lookup('label.player.biography')}
                            </h3>
                            <div className="pp-bio-awards__info-container">
                                {bioText && (
                                    <div className="pp-bio-awards__info-row">
                                        <p
                                            className="pp-bio-awards__text"
                                            dangerouslySetInnerHTML={{
                                                __html: bioText
                                            }}
                                        ></p>
                                    </div>
                                )}
                                {data?.juniorClub && (
                                    <div className="pp-bio-awards__info-row">
                                        <h4 className="pp-bio-awards__heading">
                                            {PULSE.I18N.lookup(
                                                'label.stat.juniorClub'
                                            )}
                                        </h4>
                                        <p
                                            className="pp-bio-awards__text"
                                            dangerouslySetInnerHTML={{
                                                __html: data?.juniorClub
                                            }}
                                        ></p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

BioContent.propTypes = {
    data: PropTypes.object
};
