import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { TeamIcon } from 'common/react/components/TeamIcon';
import { MatchStatus } from '../MatchStatus';
import { MatchCardEvent } from './MatchCardEvent';
import { useAutoPollingMatchItem } from 'store/modules/api/hooks/useAutoPollingMatchItem';

export const MatchCardRegular = ({ matchInfo }) => {
    const [fetchLiveMatch, setFetchLiveMatch] = useState(false);
    const { data: liveMatch } = useAutoPollingMatchItem(matchInfo.providerId, {
        skip: !fetchLiveMatch
    });

    useEffect(() => {
        const matchInfoStatus = PULSE.app.common.match.getMatchStatus(
            matchInfo.status
        );

        // to be set up only for live matches, matches starting soon, or past start time but not yet complete
        if (
            matchInfoStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE ||
            PULSE.app.common.match.startsWithinAnHour(
                matchInfoStatus,
                matchInfo.utcStartTime
            ) ||
            (PULSE.app.common.match.isPastStartTime(matchInfo.utcStartTime) &&
                matchInfoStatus !==
                    PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED)
        ) {
            setFetchLiveMatch(true);
        }
    }, [matchInfo.status, matchInfo.utcStartTime]);

    // Model either the liveMatch or matchInfo to have the same properties, no matter where it came from
    let match = !liveMatch
        ? {
              ...matchInfo,
              abbr: `${matchInfo.home.team.nickname} v ${matchInfo.away.team.nickname}`,
              homeScore: matchInfo.home.score?.totalScore,
              awayScore: matchInfo.away.score?.totalScore,
              homeTeamAbbr: matchInfo.home.team.abbreviation.toLowerCase(),
              homeTeamPid: matchInfo.home.team.providerId,
              awayTeamAbbr: matchInfo.away.team.abbreviation.toLowerCase(),
              awayTeamPid: matchInfo.away.team.providerId
          }
        : {
              ...liveMatch,
              homeScore: liveMatch.score?.homeTeamScore.matchScore.totalScore,
              awayScore: liveMatch.score?.awayTeamScore.matchScore.totalScore,
              homeTeamAbbr: liveMatch.match.homeTeam.abbr.toLowerCase(),
              homeTeamPid: liveMatch.match.homeTeamId,
              awayTeamAbbr: liveMatch.match.awayTeam.abbr.toLowerCase(),
              awayTeamPid: liveMatch.match.awayTeamId,
              status: liveMatch.match.status
          };

    const genericStatus = PULSE.app.common.match.getMatchStatus(match.status);
    const isCompleted =
        genericStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED;

    return (
        <a
            href={PULSE.app.common.url.getMatchURL(matchInfo)}
            className="fb-match"
            aria-label={`${match.abbr}`}
        >
            <div
                className={`fb-match__row ${
                    isCompleted && match.homeScore < match.awayScore
                        ? 'fb-match__row-lost'
                        : ''
                }`}
            >
                <div className="fb-match__team-container">
                    <span className="fb-match__logo">
                        <TeamIcon
                            className="fb-match__logo-icon"
                            providerId={match.homeTeamPid}
                        />
                    </span>
                    <span>{match.homeTeamAbbr}</span>
                </div>
                <span className="fb-match__score-home">{match.homeScore}</span>
            </div>
            <div
                className={`fb-match__away fb-match__row ${
                    isCompleted && match.awayScore < match.homeScore
                        ? 'fb-match__row-lost'
                        : ''
                }`}
            >
                <div className="fb-match__team-container">
                    <span className="fb-match__logo">
                        <TeamIcon
                            className="fb-match__logo-icon"
                            providerId={match.awayTeamPid}
                        />
                    </span>
                    <span>{match.awayTeamAbbr}</span>
                </div>
                <span className="fb-match__score-away">{match.awayScore}</span>
            </div>
            <MatchStatus
                utcStartTime={matchInfo.utcStartTime}
                match={match}
            ></MatchStatus>
            {matchInfo && (
                <MatchCardEvent matchInfo={matchInfo}></MatchCardEvent>
            )}
        </a>
    );
};

MatchCardRegular.propTypes = {
    matchInfo: PropTypes.object.isRequired
};
