import React, { Fragment } from 'react';
import { ImageElement } from './ImageElement';

const getSourceConfig = (conf, imageUrls) => {
    let srcset = '';
    imageUrls.forEach((imageUrl, index) => {
        const imageUrlMultiplier = ` ${index + 1}x`;
        srcset = srcset + imageUrl + imageUrlMultiplier;

        if (index !== imageUrls.length - 1) {
            srcset = `${srcset}, `;
        }
    });

    let mediaQuery = [];

    if (typeof conf.minWidth !== 'undefined') {
        mediaQuery.push(`(min-width: ${conf.minWidth}px)`);
    }

    if (typeof conf.maxWidth !== 'undefined') {
        mediaQuery.push(`(max-width: ${conf.maxWidth}px)`);
    }

    return {
        srcset,
        mediaQuery
    };
};

export const PictureElement = ({
    image,
    mediaQueryConfig,
    pictureCssClass = '',
    objectFit = false,
    renderAsPortrait = false,
    lazyload = 'lazy'
}) => {
    let pictureClass = pictureCssClass;
    pictureClass = objectFit
        ? `${pictureClass} object-fit-cover-picture`
        : `${pictureClass} picture`;

    if (renderAsPortrait) {
        pictureClass = `${pictureClass} object-fit-cover-picture--is-portrait`;
    }

    const sourceMarkup = [];

    mediaQueryConfig.forEach((conf) => {
        if (conf.name === 'default') {
            const imageUrl = PULSE.app.common.image.getOnDemandImageUrl(
                image,
                conf.size.width,
                conf.size.height
            );
            const objectFitClass = 'picture__img';

            sourceMarkup.push(
                <ImageElement
                    url={imageUrl}
                    title={image.title}
                    cssClass={`${conf.cssClass} ${objectFitClass}`}
                    lazyload={lazyload}
                    objectFit={objectFit}
                />
            );
        } else {
            let imageUrls = [];
            let multipliers = conf.multipliers ? conf.multipliers : [1, 2];

            multipliers.forEach((multiplier) => {
                imageUrls.push(
                    PULSE.app.common.image.getOnDemandImageUrl(
                        image,
                        conf.size.height * multiplier,
                        conf.size.width * multiplier
                    )
                );
            });

            const sourceConfig = getSourceConfig(conf, imageUrls);
            sourceMarkup.push(
                <source
                    className={conf.cssClass}
                    srcSet={sourceConfig.srcset}
                    media={sourceConfig.mediaQuery}
                />
            );
        }
    });

    const pictureMarkup = sourceMarkup?.length ? (
        <picture className={pictureClass}>
            {sourceMarkup.map((source, index) => (
                <Fragment key={`source_${image.id}_${index}`}>
                    {source}
                </Fragment>
            ))}
        </picture>
    ) : null;

    return pictureMarkup;
};
