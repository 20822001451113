import { useSelector } from 'react-redux';
import { FIRST_SORTABLE_COLUMN_INDEX } from '../../../../../statspro/js/components/career-and-season-stats/config/columns';
import { playerStatsSortSelector } from 'store/modules/match-centre/player-stats/selectors';

/**
 * 1. Sort the intended sort column (defined by what we've set in the redux
 *    state). Normally we should be able to rely on the sorted column from
 *    react-table (see [2]) but in some situations it can be too slow to update
 *    and cause this logic to act unexpectedly and end up returning the fallback
 *    result (see [3]).
 * 2. If for whatever reason we cannot sort by the intended sorted column
 *    continue to sort by the currently sorted column (according to what
 *    react-table tells us) if one is present.
 * 3. If all else fails, sort the first column.
 *
 * @param {object[]} headerGroups - Header groups.
 * @returns {{
 *    headerGroup: any,
 *    sortedColumn: any,
 *    sortDirection: ('ascending'|'descending'),
 * }} Data.
 */
export const useTableSortData = (headerGroups) => {
    const sort = useSelector(playerStatsSortSelector);
    const headerGroup = headerGroups[0];

    /** @type {{ sortedColumn: object, sortDirection: 'descending'|'ascending' }} */
    const { sortedColumn, sortDirection } = (() => {
        const sortedColumnAccordingToReactTable = headerGroup.headers.find(
            (column) => column.isSorted
        );

        // [1]
        const sortedColumnAccordingToState = headerGroup.headers.find(
            (column) => column.id === sort.columnId
        );

        if (
            (!sortedColumnAccordingToReactTable &&
                sortedColumnAccordingToState) ||
            sort.force
        ) {
            return {
                sortedColumn: sortedColumnAccordingToState,
                sortDirection: (() => {
                    switch (sortedColumnAccordingToState.isSortedDesc) {
                        case false:
                            return 'ascending';
                        case true:
                        default:
                            return 'descending';
                    }
                })()
            };
        }

        // [2]
        if (sortedColumnAccordingToReactTable) {
            return {
                sortedColumn: sortedColumnAccordingToReactTable,
                sortDirection: sortedColumnAccordingToReactTable.isSortedDesc
                    ? 'descending'
                    : 'ascending'
            };
        }

        // [3]
        return {
            sortedColumn: headerGroup.headers[FIRST_SORTABLE_COLUMN_INDEX],
            sortDirection: 'descending'
        };
    })();

    return { headerGroup, sortedColumn, sortDirection };
};
