/**
 * @typedef {object} Setting
 * @property {string} key The key to use to access the setting
 * @property {string} type The type of setting
 * @property {string} label The label to display for the setting
 * @property {string|boolean} defaultValue The default value for the setting
 * @property {boolean} syncToSalesForce Whether to sync the setting to salesforce
 */

/**
 * Works out what the default value for display wagering setting should be if we
 * don't have anything set yet. This is to handle situations where we're loading
 * pages inside webviews in the app and the app has told us the user has betting
 * odds disabled in the app so we should use that as the initial value check
 * here.
 *
 * @returns {boolean} - True if the default initial value for display wagering
 * setting
 */
function getDefaultDisplayWagering() {
    if (PULSE.core.localStorage.getStorage('displayOdds', true) === 'false') {
        return false;
    }

    const params = new URLSearchParams(document.location.search);
    if (params.get('disableBettingOdds') === 'true') {
        return false;
    }

    return true;
}

/**
 * @typedef {object} SiteSettingConfig
 * @property {Setting} displayWagering The config for the displayWagering setting
 * @property {Setting} over18 The config for the over18 setting
 * @property {Setting} region The config for the region setting
 */

/**
 * @constant {SiteSettingConfig} SITE_SETTINGS_CONFIG The config for the settings system
 */
export const SITE_SETTINGS_CONFIG = {
    displayWagering: {
        key: 'displayWagering',
        type: 'boolean',
        labelKey: 'label.siteSettings.hideBetting',
        defaultValue: getDefaultDisplayWagering(),
        syncToMis: true
    },
    over18: {
        key: 'over18',
        type: 'boolean',
        labelKey: 'label.siteSettings.over18',
        defaultValue: true,
        syncToMis: true
    },
    region: {
        key: 'region',
        type: 'select',
        labelKey: 'label.siteSettings.region',
        defaultValue: {
            timezone: moment.tz.guess(true),
            name: '',
            id: '',
            location: '',
            region: ''
        },
        syncToMis: false
    },
    syncExpiry: {
        key: 'syncExpiry',
        type: 'string',
        defaultValue: null,
        syncToMis: false
    },
    modalType: {
        key: 'modalType',
        type: 'array',
        defaultValue: [],
        syncToMis: false
    }
};

/**
 * Attach the config to the window object so we can access outside of the
 * webpack/module system
 */
PULSE.app.common.SITE_SETTINGS_CONFIG = SITE_SETTINGS_CONFIG;
