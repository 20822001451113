import React from 'react';
import cx from 'classnames';

import DefaultTableHeadRendererContainer from './DefaultTableHeadRendererContainer';

/*
    This is the default component to use when rendering a table cell, if no custom renderer is defined.
    It can take a custom className and a value to render, any additional props will also be passed onto the
    <td> element.
*/

const DefaultTableHeadRenderer = ({ className, field, ...additionalProps }) => {
    const sortIcon = PULSE.app.templating.render(
        {
            cssClass: 'custom-table__header-cell-sort-icon',
            name: 'dropdown'
        },
        'common.svg-icon'
    );

    let getTranslation = additionalProps.getTranslation;
    let fieldDescription = field.description
        ? getTranslation(field.description)
        : false;
    fieldDescription =
        fieldDescription && fieldDescription !== field.description
            ? fieldDescription
            : false;

    const description = fieldDescription ? (
        <div className="custom-table__description">
            <h4 className="custom-table__description-title">
                {getTranslation(field.fieldLabel)}
            </h4>
            <p className="custom-table__description-text">
                {getTranslation(field.description)}
            </p>
        </div>
    ) : null;

    const sortBy = additionalProps.sortBy;
    const sortIsActive = field.fieldName === sortBy.fieldName;
    const sortIsActiveKey = `is-active--${sortBy.direction.toLowerCase()}`;
    let sortDirection = sortIsActive ? sortBy.direction : 'DESC';

    const showSortButtons =
        !field.noSort && additionalProps.multipleTableIndex === 0;
    const sortButtons = showSortButtons ? (
        <div
            className={cx('custom-table__header-cell-sort-buttons', {
                'is-active': sortIsActive,
                [sortIsActiveKey]: sortIsActive
            })}
        >
            <button
                className="custom-table__header-cell-sort-button custom-table__header-cell-sort-button--asc u-hide-until-tablet"
                onClick={() =>
                    additionalProps.handleClick(
                        field,
                        additionalProps.tableName,
                        'ASC'
                    )
                }
            >
                <span dangerouslySetInnerHTML={{ __html: sortIcon }}></span>
            </button>
            <button
                className="custom-table__header-cell-sort-button custom-table__header-cell-sort-button--desc  u-hide-until-tablet"
                onClick={() =>
                    additionalProps.handleClick(
                        field,
                        additionalProps.tableName,
                        'DESC'
                    )
                }
            >
                <span dangerouslySetInnerHTML={{ __html: sortIcon }}></span>
            </button>
            <button
                className={`custom-table__header-cell-sort-button custom-table__header-cell-sort-button--single u-hide-from-tablet custom-table__header-cell-sort-button--${sortDirection.toLowerCase()}`}
                onClick={(event) => {
                    const parentElement = event.target.closest('.is-active');

                    if (
                        event.target.classList.contains('is-active') ||
                        parentElement
                    ) {
                        sortDirection =
                            sortDirection === 'DESC' ? 'ASC' : 'DESC';
                    }

                    return additionalProps.handleClick(
                        field,
                        additionalProps.tableName,
                        sortDirection
                    );
                }}
            >
                <span dangerouslySetInnerHTML={{ __html: sortIcon }}></span>
            </button>
        </div>
    ) : null;

    return (
        <DefaultTableHeadRendererContainer
            className={className}
            {...additionalProps}
        >
            <span
                className={cx('custom-table__header-cell-text', {
                    'custom-table__header-cell-text--no-sort': !showSortButtons
                })}
            >
                {getTranslation(field.fieldLabelShort)}
            </span>
            {description}
            {sortButtons}
        </DefaultTableHeadRendererContainer>
    );
};

export default DefaultTableHeadRenderer;
