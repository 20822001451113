import React from 'react';
import cx from 'classnames';

/*
    We can use this as a default way to render the <td> and still allow a custom component inside it.
    Useful for reducing duplication.
*/

const DefaultTableHeadRendererContainer = ({
    className,
    children,
    ...additionalProps
}) => {
    if (additionalProps.lastColumn) {
        return (
            <th className={className} ref={additionalProps.lastColumnRef}>
                {children}
            </th>
        );
    }
    return <th className={className}>{children}</th>;
};

export default DefaultTableHeadRendererContainer;
