import { sortDateTimes } from './sortDateTimes';
import { hasMatchingEventType } from './hasMatchingEventType';

/**
 * Function that takes in all the incoming events from the broadcast/events api
 * call and filters it down to just the ones we're interested in and runs it
 * through the common helper to model events and fitler out blocked regions.
 *
 * @param {object} data - The raw data back from the broadcast/events api call
 * @param {string} eventType - The value we should be looking for when filtering
 * by the eventType-web custom attribute.
 * @returns {object|null} - The modelled event ready to use or null if there are no relevant events
 */
export function filterAndModelEvents(data, eventType) {
    const { UPCOMING, LIVE } = PULSE.app.common.broadcast.status;

    // There's nothing back so return an empty array
    if (!data?.content) {
        return null;
    }

    /**
     * Pull out just the events with channels that have the right trade
     * attributes
     */
    const relevantEvents = eventType
        ? data.content.filter((event) =>
              event?.channels.some((channel) =>
                  hasMatchingEventType(channel, eventType)
              )
          )
        : data.content;

    /**
     * Grab the first either live, or upcoming event
     */
    let eventStatus;
    const liveOrNextEvent = relevantEvents.find((event) => {
        eventStatus =
            PULSE.app.common.broadcast.getEventStatusFromChannel(event);

        if (eventStatus === UPCOMING || eventStatus === LIVE) {
            return true;
        }

        return false;
    });

    // No live or next events found, stop here
    if (!liveOrNextEvent) {
        return null;
    }

    /**
     * Use the helper to filter to events with channels available in the users
     * region
     */
    const region = PULSE.app.common.siteSettings.getSingleSetting('region');
    const filteredChannels =
        PULSE.app.common.broadcast.filterOutRestrictedItems(
            liveOrNextEvent.channels,
            region.id
        );

    /**
     * If there are no channels left after filtering, we don't have an event to
     * show so return null
     */
    if (!filteredChannels) {
        return null;
    }

    /**
     * Add a status to each channel
     */
    const modelledChannels = filteredChannels.map((channel) => {
        return {
            ...channel,
            status: PULSE.app.common.broadcast.getChannelStatus(channel)
        };
    });

    /**
     * If there are no live channels, then find the next upcoming channel and set
     * that as the featured channel in the model as that's all we care about and
     * then return the model
     */
    if (eventStatus === UPCOMING) {
        const upcomingChannels = modelledChannels.filter(
            (channel) => channel.status === UPCOMING
        );

        upcomingChannels.sort((a, b) =>
            sortDateTimes(
                luxon.DateTime.fromISO(a.startDateTime),
                luxon.DateTime.fromISO(b.startDateTime)
            )
        );

        return {
            ...liveOrNextEvent,
            featureChannel: upcomingChannels[0],
            status: eventStatus
        };
    }

    /**
     * Since the event is live, we only care about the live channels, so filter
     * down all the channels to just the live ones, group them by type, add them
     * to the model and return
     */
    const liveChannels = modelledChannels.filter(
        (channel) => channel.status === LIVE
    );
    const liveChannelsByType =
        PULSE.app.common.broadcast.groupChannelsByMediaType(liveChannels);

    return {
        ...liveOrNextEvent,
        featureChannel:
            liveChannelsByType.VIDEO?.[0] || liveChannelsByType.AUDIO?.[0],
        liveChannels: liveChannelsByType,
        status: eventStatus
    };
}
