import produce from 'immer';
// import './types';
import { SET_SEARCH_RESULTS } from '../actions';

export const searchReducer = (state = {}, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_SEARCH_RESULTS:
                return action.payload;
            default:
                draft;
        }
    });
};
