/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';

import { StatsLeadersTableCaption } from './StatsLeadersTableCaption';
import { StatsLeadersTableBody } from './StatsLeadersTableBody';
import { NextPageButton } from 'widgets/statspro/js/components/common/stats-table/NextPageButton';
import { useTableSortData } from '../hooks';
import { UPDATE_SORT } from 'store/modules/statspro/stats-leaders/actions';
import { statsLeadersSelector } from 'store/modules/statspro/stats-leaders/selectors/simple-selectors';
import { statsLeadersFiltersSelector } from 'store/modules/statspro/stats-leaders/selectors';
import { FIRST_SORTABLE_COLUMN_INDEX } from '../../../config/columns';
import { StatsLeadersTableHead } from './StatsLeadersTableHead';
import { StatsTable } from 'widgets/statspro/js/components/common/stats-table/StatsTable';

const PAGE_SIZE = 20;
export const StatsLeadersTable = ({
    columns,
    data,
    players,
    modifier,
    isAFLW
}) => {
    const dispatch = useDispatch();
    const sort = useSelector(statsLeadersSelector).sort;
    const filters = useSelector(statsLeadersFiltersSelector);
    const stickyPageNav = document.querySelector(
        '.js-react-statspro-nav-provider'
    );
    const stickyMainNav = document.querySelector('.js-main-navigation');

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // @ts-ignore
        preFilteredRows,
        // @ts-ignore
        page,
        // @ts-ignore
        canNextPage,
        // @ts-ignore
        setPageSize,
        // @ts-ignore
        state: { pageSize }
    } = useTable(
        {
            columns,
            data,
            initialState: {
                // @ts-ignore
                pageSize: PAGE_SIZE,
                sortBy: [
                    {
                        id:
                            sort.columnId ??
                            columns[FIRST_SORTABLE_COLUMN_INDEX].accessor,
                        desc: sort.sortDirection === 'descending'
                    }
                ],
                filters: [
                    {
                        id: 'position',
                        value: filters.positions
                    },
                    {
                        id: 'team',
                        value: filters.teams
                    }
                ]
            },
            disableSortRemove: true
        },
        useFilters,
        useSortBy,
        usePagination
    );

    const { headerGroup, sortedColumn, sortDirection } =
        useTableSortData(headerGroups);

    // ===== Effects ======================================================== //

    useEffect(() => {
        if (sortedColumn) {
            dispatch(
                UPDATE_SORT.request({
                    columnId: sortedColumn.id,
                    sortDirection,
                    force: false
                })
            );
        } else if (sort.force) {
            dispatch(
                UPDATE_SORT.request({
                    force: false
                })
            );
        }
    }, [sortedColumn, sortDirection]);

    /**
     * Apply filters on change
     */
    useEffect(() => {
        const headers = headerGroups[0].headers;

        headers
            .find((col) => col.id === 'position')
            // @ts-ignore
            .setFilter(filters.positions);

        headers
            .find((col) => col.id === 'team')
            // @ts-ignore
            .setFilter(filters.teams);
    }, [filters, headerGroups]);

    // ===== Render ========================================================= //

    return (
        <StatsTable
            modifier={modifier}
            getTableProps={getTableProps}
            cssClass="stats-table__selector"
            stickyHeader={<StatsLeadersTableHead headerGroup={headerGroup} />}
            stickyPageNav={stickyPageNav}
            stickyMainNav={stickyMainNav}
            nextPageButton={
                <NextPageButton
                    canNextPage={canNextPage}
                    currentPageSize={pageSize}
                    pageSize={PAGE_SIZE}
                    totalRowCount={rows.length}
                    setPageSize={setPageSize}
                />
            }
        >
            <StatsLeadersTableCaption
                sortedColumnId={sortedColumn.id}
                sortDirection={sortDirection}
            />
            <StatsLeadersTableHead headerGroup={headerGroup} />
            <StatsLeadersTableBody
                preFilteredRows={preFilteredRows}
                page={page}
                prepareRow={prepareRow}
                getTableBodyProps={getTableBodyProps}
                players={players}
                isAFLW={isAFLW}
            />
        </StatsTable>
    );
};

StatsLeadersTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    players: PropTypes.array.isRequired,
    modifier: PropTypes.string,
    isAFLW: PropTypes.bool
};
