import React from 'react';
import PropTypes from 'prop-types';
import { getTranslation } from 'common/react/utils/translations';

export const QualifiersBanner = ({ qualifierRow }) => {
    const qualiferRowString = (qualifierRow + 1).toString();

    return (
        <div className="stats-table__qualifiers-wrapper">
            <div className="stats-table__qualifiers-banner">
                <span className="stats-table__qualifiers-banner-tab">
                    {getTranslation('label.ladder.top')} {qualiferRowString}
                </span>
            </div>
        </div>
    );
};

QualifiersBanner.propTypes = {
    qualifierRow: PropTypes.number
};
