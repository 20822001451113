import { createApi } from '@reduxjs/toolkit/query/react';

import { bearerTokenCFSQuery } from './base-queries/bearer-token-cfs-query';
import { set } from 'store/modules/site-settings/init';

const { app } = PULSE;

const SYNC_EXPIRY_TIME_MINS = 5;

const formatPreferencesBody = ({ preferences, guid, groupId }) => ({
    applicationKey: 'afl',
    uid: guid,
    group: groupId,
    preferences: preferences
});

// RTK Query update: Define an api service with a base URL and retry attempts
// RTK Query update: Endpoints can be added here or injected in components
export const misPreferencesApi = createApi({
    reducerPath: 'misPreferencesApi',
    baseQuery: bearerTokenCFSQuery,
    endpoints: (build) => ({
        getPreferences: build.query({
            query: ({
                guid = app.authHelpers.getUserUid(),
                groupId = 'consents'
            } = {}) => `/users/${guid}/preferences/${groupId}`,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    // Since we've just refetched, now update our local system to match
                    if (data?.preferences) {
                        dispatch(
                            set({
                                ...data.preferences,
                                syncExpiry: luxon.DateTime.now()
                                    .plus({ minutes: SYNC_EXPIRY_TIME_MINS })
                                    .toISO()
                            })
                        );
                    }
                } catch (err) {
                    console.error('MIS Preferences Sync Error', { err });
                }
            }
        }),
        updatePreferences: build.mutation({
            query: ({
                preferences,
                guid = app.authHelpers.getUserUid(),
                groupId = 'consents'
            } = {}) => ({
                url: `/users/${guid}/preferences/${groupId}`,
                method: 'PUT',
                body: formatPreferencesBody({ preferences, guid, groupId })
            })
        })
    })
});

export const { useGetPreferencesQuery } = misPreferencesApi;
