import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../../setup-react';

// ===== Signals ============================================================ //

// Nav
export const UPDATE_NAV_ITEM = createSignalAction(
    'stats/stats-leaders',
    'UPDATE_NAV_ITEM'
);

// Filters
export const UPDATE_FILTER_ITEM = createSignalAction(
    'stats/stats-leaders',
    'UPDATE_FILTER_ITEM'
);
export const RESET_FILTERS = createSignalAction(
    'stats/stats-leaders',
    'RESET_FILTERS'
);

// Sort
export const UPDATE_SORT = createSignalAction(
    'stats/stats-leaders',
    'UPDATE_SORT'
);

// Benchmarking
export const UPDATE_BENCHMARKING = createSignalAction(
    'stats/stats-leaders',
    'UPDATE_BENCHMARKING'
);

// Data Type
export const UPDATE_DATA_TYPE = createSignalAction(
    'stats/stats-leaders',
    'UPDATE_DATA_TYPE'
);

// ===== Deltas ============================================================= //

// Nav
export const SET_NAV_ITEM = createDeltaActionType(
    'stats/stats-leaders',
    'SET_NAV_ITEM'
);
export const setNavItem = createActionCreator(SET_NAV_ITEM);

// Filters
export const SET_FILTER_ITEM = createDeltaActionType(
    'stats/stats-leaders',
    'SET_FILTER_ITEM'
);
export const setFilterItem = createActionCreator(SET_FILTER_ITEM);

export const SET_RESET_FILTERS = createDeltaActionType(
    'stats/stats-leaders',
    'SET_RESET_FILTERS'
);
export const setResetFilters = createActionCreator(SET_RESET_FILTERS);

// Sort
export const SET_SORT = createDeltaActionType(
    'stats/stats-leaders',
    'SET_SORT'
);
export const setSort = createActionCreator(SET_SORT);

// Benchmarking
export const SET_BENCHMARKING = createDeltaActionType(
    'stats/stats-leaders',
    'SET_BENCHMARKING'
);
export const setBenchmarking = createActionCreator(SET_BENCHMARKING);

// Data Type
export const SET_DATA_TYPE = createDeltaActionType(
    'stats/stats-leaders',
    'SET_DATA_TYPE'
);
export const setDataType = createActionCreator(SET_DATA_TYPE);
