import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DATA_TYPE } from '../config/data-type';

/**
 * Aims to ensure that the data type being used is always valid for the
 * situation (e.g. you can't have a data type of `totals` when benchmarking is
 * active).
 *
 * @param {object} object ~ Arg.
 * @param {object} object.initialDataType ~ The data type currently in the redux
 *    store.
 * @param {object} object.onUpdateActionCreator ~ An action creator which
 *    returns the action to be dispatched when updating the data type.
 * @param {object} object.isBenchmarkingActive ~ True if active, else false.
 * @returns {object} Data Type and update function.
 */
export const useDataType = ({
    initialDataType,
    onUpdateActionCreator,
    isBenchmarkingActive = false
}) => {
    const dispatch = useDispatch();

    const getValidType = (newType) => {
        // Ensure the type is `{totals|averages}`
        if (isBenchmarkingActive) {
            if (
                [
                    DATA_TYPE.BENCHMARKED_AVERAGES,
                    DATA_TYPE.BENCHMARKED_TOTALS
                ].includes(newType)
            ) {
                return newType;
            }

            return newType === DATA_TYPE.TOTALS
                ? DATA_TYPE.BENCHMARKED_TOTALS
                : DATA_TYPE.BENCHMARKED_AVERAGES;
        }

        // Ensure the type is `{totals|averages|perGame}`
        if (
            [DATA_TYPE.AVERAGES, DATA_TYPE.TOTALS, DATA_TYPE.PER_GAME].includes(
                newType
            )
        ) {
            return newType;
        }

        return newType === DATA_TYPE.BENCHMARKED_TOTALS
            ? DATA_TYPE.TOTALS
            : DATA_TYPE.AVERAGES;
    };

    const updateDataType = (newType) => {
        const validType = getValidType(newType);
        if (validType === initialDataType.type) {
            return;
        }
        dispatch(onUpdateActionCreator({ type: validType }));
    };

    useEffect(() => {
        updateDataType(initialDataType.type);
    }, [isBenchmarkingActive]);

    return { dataType: initialDataType, updateDataType };
};
