import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SKELETON_COUNT = 3;

export const MatchSkeleton = () => (
    <>
        <h2 className="fixtures__date-header">
            <Skeleton width={200} />
        </h2>
        <Skeleton
            className="broadcast-guide__item-skeleton"
            count={SKELETON_COUNT}
        />
    </>
);
