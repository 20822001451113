import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { MapLocationSelector } from '../../../interactive-event-map/js/components/MapLocationSelector';

export const MapLocationSelectorProvider = ({ store }) => {
    return (
        <Provider store={store}>
            <MapLocationSelector />
        </Provider>
    );
};

MapLocationSelectorProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
