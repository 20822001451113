import React from 'react';
import PropTypes from 'prop-types';
import { zeroPad } from 'react-countdown';

// Custom Countdown Renderer
export const CountdownRenderer = ({ days, hours, minutes, seconds }) => {
    return (
        <span>
            {days ? `${zeroPad(days)}:` : null}
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
    );
};

CountdownRenderer.propTypes = {
    days: PropTypes.number,
    hours: PropTypes.number,
    minutes: PropTypes.number,
    seconds: PropTypes.number
};
