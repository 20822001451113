/**
 * Checks whether the given value is within the max and min values and returns an appropriate value.
 *
 * @param {number} value - The target value.
 * @param {number} min - The minimum value to be returned.
 * @param {number} max - The maximum value to be returned.
 * @returns {number} If value is within max and min, value is returned.
 *    If value is amove max, max is returned.
 *    If value if below min, min is returned.
 */
export const getValueWithinBounds = (value, min, max) => {
    return Math.min(Math.max(value, min), max);
};
