import React from 'react';
import PropTypes from 'prop-types';
import { batch, useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { CompIcon } from 'common/react/components/CompIcon';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { TeamWatermarkBackground } from '../../../../statspro/js/components/TeamWatermarkBackground';
import { UPDATE_ITEM } from 'store/modules/video-hub/view-by-match/actions';
import { viewByMatchSelector } from 'store/modules/video-hub/view-by-match/selectors';
import { getTranslation } from 'common/react/utils/translations';

export const MatchCard = ({
    matchData,
    compAbbreviation,
    index,
    isVisible
}) => {
    const dispatch = useDispatch();
    const activeIndex = useSelector(viewByMatchSelector)?.activeIndex;
    const homeTeamAbbr = matchData?.home.team.abbreviation.toLowerCase();
    const awayTeamAbbr = matchData?.away.team.abbreviation.toLowerCase();

    const buttonClickHandler = () => {
        batch(() => {
            // update active button index
            dispatch(
                UPDATE_ITEM.request({
                    item: 'activeIndex',
                    value: index
                })
            );

            // update matchId
            dispatch(
                UPDATE_ITEM.request({
                    item: 'matchId',
                    value: matchData?.id
                })
            );
        });
    };

    return (
        <div className="video-hub-view-by-match__match-card">
            <button
                className={classNames(
                    'video-hub-view-by-match__card-link stats-slider__card',
                    { 'is-active': activeIndex === index }
                )}
                onClick={() => buttonClickHandler()}
                aria-label={`${matchData?.home.team.name} ${getTranslation(
                    'label.stats.vs'
                )} ${matchData?.away.team.name}`}
                tabIndex={isVisible ? 0 : -1}
            >
                <TeamWatermarkBackground
                    teamAbbreviation={homeTeamAbbr}
                    className="stats-card-featured-player__bg stats-card-featured-player__bg--no-border-radius"
                    side="left"
                />
                <TeamWatermarkBackground
                    teamAbbreviation={awayTeamAbbr}
                    className="stats-card-featured-player__bg stats-card-featured-player__bg--no-border-radius"
                    side="right"
                />

                <div className="video-hub-view-by-match__inner-wrapper">
                    <TeamIcon
                        className={'icon video-hub-view-by-match__club-icon'}
                        teamAbbreviation={homeTeamAbbr}
                        theme={PULSE.app.common.team.getTeamLogoTheme(
                            homeTeamAbbr,
                            'b'
                        )}
                    />
                    <div className="video-hub-view-by-match__comp-wrapper">
                        <div
                            className={`video-hub-view-by-match__comp video-hub-view-by-match__comp--${compAbbreviation}`}
                        >
                            <CompIcon
                                code={compAbbreviation}
                                className={classNames(
                                    'icon video-hub-view-by-match__comp-icon fade-in-on-load',
                                    { 'is-loaded': compAbbreviation }
                                )}
                            />
                        </div>
                    </div>
                    <TeamIcon
                        className={'icon video-hub-view-by-match__club-icon'}
                        teamAbbreviation={awayTeamAbbr}
                        theme={PULSE.app.common.team.getTeamLogoTheme(
                            awayTeamAbbr,
                            'b'
                        )}
                    />
                </div>
            </button>
        </div>
    );
};

MatchCard.propTypes = {
    matchData: PropTypes.object.isRequired,
    compAbbreviation: PropTypes.string,
    index: PropTypes.number.isRequired,
    isVisible: PropTypes.bool.isRequired
};
