import { useSelector } from 'react-redux';

import { performanceChartNavSelector } from 'store/modules/statspro/performance-chart/selectors';

export const useSeasonYear = () => {
    const nav = useSelector(performanceChartNavSelector);
    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );

    // The season ID contains the year, so we can pull it out of that.
    // Follows the format CD_SYYYYXXX where YYYY is the year.
    // If this fails for whatever reason `seasonYear` will be null/undefined and
    // the headshot logic will use whichever headshot year has been set in the
    // `PLAYER_HEADSHOT_YEAR` config variable.
    return seasons
        ?.find((season) => season.id === nav.season.id)
        ?.providerId // eslint-disable-next-line no-magic-numbers
        .substr(4, 4);
};
