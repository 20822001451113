import React from 'react';
import PropTypes from 'prop-types';
import { getTranslation } from '../../../../../common/react/utils/translations';
import { SvgIcon } from '../../../../../common/react/components/SvgIcon';

export const LadderLegend = ({ qualifiers, compLabel, isWinRatio }) => {
    return (
        <aside className="ladder-legend">
            <p className="ladder-legend__title">
                <SvgIcon icon="badge-premiership" className="icon" />
                {compLabel}
            </p>

            <dl>
                {/* top X */}
                {!isNaN(qualifiers) && qualifiers !== 0 ? (
                    <>
                        <dt className="ladder-legend__item ladder-legend__item--key">
                            {getTranslation(`label.ladder.top`)} {qualifiers}:
                        </dt>

                        <dd className="ladder-legend__item">
                            {getTranslation(`label.ladder.qualifyTo`)}
                        </dd>
                    </>
                ) : null}

                {/* played */}
                <dt className="ladder-legend__item ladder-legend__item--key">
                    {getTranslation(`label.ladder.played.short`)}:
                </dt>
                <dd className="ladder-legend__item">
                    {getTranslation(`label.ladder.legend.played`)}
                </dd>

                {/* points */}
                <dt className="ladder-legend__item ladder-legend__item--key">
                    {getTranslation(`label.ladder.points.short`)}:
                </dt>

                <dd className="ladder-legend__item">
                    {getTranslation(`label.ladder.points`)}
                </dd>

                {/* percentage */}
                <dt className="ladder-legend__item ladder-legend__item--key">
                    %:
                </dt>

                <dd className="ladder-legend__item">
                    {getTranslation(`label.ladder.legend.percentage`)}
                </dd>

                {/* win ratio */}
                {isWinRatio && (
                    <>
                        <dt className="ladder-legend__item ladder-legend__item--key">
                            {getTranslation(`label.ladder.winRatio.short`)}:
                        </dt>
                        <dd className="ladder-legend__item">
                            {getTranslation(`label.ladder.winRatio.long`)}
                        </dd>
                    </>
                )}

                {/* won */}
                <dt className="ladder-legend__item ladder-legend__item--key">
                    {getTranslation(`label.ladder.won.short`)}:
                </dt>
                <dd className="ladder-legend__item">
                    {getTranslation(`label.ladder.won`)}
                </dd>

                {/* lost */}
                <dt className="ladder-legend__item ladder-legend__item--key">
                    {getTranslation(`label.ladder.lost.short`)}:
                </dt>

                <dd className="ladder-legend__item">
                    {getTranslation(`label.ladder.lost`)}
                </dd>

                {/* drawn */}
                <dt className="ladder-legend__item ladder-legend__item--key">
                    {getTranslation(`label.ladder.drawn.short`)}:
                </dt>
                <dd className="ladder-legend__item">
                    {getTranslation(`label.ladder.drawn`)}
                </dd>

                {/* points for */}
                <dt className="ladder-legend__item ladder-legend__item--key">
                    {getTranslation(`label.ladder.pointsFor.short`)}:
                </dt>
                <dd className="ladder-legend__item">
                    {getTranslation(`label.ladder.pointsFor`)}
                </dd>

                {/* points against */}
                <dt className="ladder-legend__item ladder-legend__item--key">
                    {getTranslation(`label.ladder.pointsAgainst.short`)}:
                </dt>
                <dd className="ladder-legend__item">
                    {getTranslation(`label.ladder.pointsAgainst`)}
                </dd>

                {/* last five (form) */}
                <dt className="ladder-legend__item ladder-legend__item--key">
                    {getTranslation(`label.form`)}:
                </dt>
                <dd className="ladder-legend__item">
                    {getTranslation(`label.ladder.lastFiveGames`)}
                </dd>
            </dl>
        </aside>
    );
};

LadderLegend.propTypes = {
    qualifiers: PropTypes.number,
    compLabel: PropTypes.string,
    isWinRatio: PropTypes.bool
};
