import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

/**
 * A UI component that gives the user a visual prompt that content is loading.
 *
 * @param className.className
 * @param {object} className - To allow for custom styles, e.g. skeleton height.
 * @param {number} count - The number of skeleton keys to be rendered.
 * @param {number} width - The width of the skeleton, in pixels.
 * @param {number} height - The height of the skeleton, in pixels.
 * @param {boolean} showAsColumns - Display skeleton parts as columns, instead of rows.
 * @param {string} baseColor - The colour of the skeleton.
 * @param className.count
 * @param className.showAsColumns
 * @param className.width
 * @param className.height
 * @param className.baseColor
 * @param className.highlightColor
 * @returns {JSX.Element} Component.
 */
export const SkeletonLoader = ({
    className,
    count = 1,
    showAsColumns = false,
    width,
    height,
    baseColor,
    highlightColor
}) => {
    return (
        <div
            className={`skeleton-loader ${className} ${
                // Not sure we need className var here
                showAsColumns ? `skeleton-loader--columns` : `skeleton-loader`
            }`}
        >
            <Skeleton
                className={className}
                count={count}
                width={width}
                height={height}
                baseColor={baseColor}
                highlightColor={highlightColor}
            />
        </div>
    );
};

SkeletonLoader.propTypes = {
    className: PropTypes.string,
    count: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    showAsColumns: PropTypes.bool,
    baseColor: PropTypes.string,
    highlightColor: PropTypes.string
};
