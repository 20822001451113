import produce from 'immer';
import {
    SET_PLAYER_MODAL_DATA,
    SET_PLAYER_MODAL_LEADERBOARD_DATA,
    SET_PLAYER_MODAL,
    SET_PREDICTED_DATA
} from '../actions';
import { PlayerModalState } from '../types';
import { Action } from '../../../../types';
import { setPlayerModal } from './set-player-modal';
import { setPlayerModalData } from './set-player-modal-data';
import { setLeaderboardData } from './set-leaderboard-data';
import { setPredictedData } from './set-predicted-data';

/**
 * @type {PlayerModalState}
 */
const initialState = {
    visible: false,
    playerId: null,
    seasonPid: null,
    seasonId: null,
    data: null,
    player: {},
    leaderBoardData: [],
    predicted: null
};

/**
 * @param {PlayerModalState} state - The Redux state.
 * @param {Action} action - The Redux action.
 * @returns {PlayerModalState} The updated Redux state.
 */
export const playerModalReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_PLAYER_MODAL_DATA:
                setPlayerModalData(draft, action.payload);
                break;
            case SET_PLAYER_MODAL:
                setPlayerModal(draft, action.payload);
                break;
            case SET_PLAYER_MODAL_LEADERBOARD_DATA:
                setLeaderboardData(draft, action.payload);
                break;
            case SET_PREDICTED_DATA:
                setPredictedData(draft, action.payload);
                break;
            default:
                draft;
        }
    });
};
