import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @returns {JSX.Element} Component.
 */
export const StatsSectionContentHeader = (props) => {
    const children = React.Children.toArray(props.children);

    if (!children?.length) {
        return <div className="stats-section-content-header"></div>;
    }

    const Heading = children.find(
        (child) => child.props.name === 'StatsSectionContentHeaderHeading'
    );
    const Options = children.find(
        (child) => child.props.name === 'StatsSectionContentHeaderOptions'
    );
    const Below = children.find(
        (child) => child.props.name === 'StatsSectionContentHeaderBelow'
    );

    return (
        <>
            <div className="stats-section-content-header">
                {Heading}
                {Options}
            </div>

            {Below}
        </>
    );
};

StatsSectionContentHeader.defaultProps = {
    name: 'StatsSectionContentHeader'
};

StatsSectionContentHeader.propTypes = {
    children: PropTypes.any
};
