/**
 * Take a channel and use it's startTime to create a readable string for when
 * the channel starts
 *
 * @param {object} channel - A channel object with a startDateTime property
 * @returns {string} the formatted string
 */
export function getTimeString(channel) {
    const startTime = luxon.DateTime.fromISO(channel.startDateTime).setLocale(
        'en-AU'
    );
    return `${startTime.toRelativeCalendar()} at ${startTime.toLocaleString(
        luxon.DateTime.TIME_SIMPLE
    )}`;
}
