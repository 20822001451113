// eslint-disable no-lone-blocks

import React from 'react';
import PropTypes from 'prop-types';
import { useGetClubContentQuery } from 'common/store/modules/api/aflc-content-api';
import { ClubNewsListing } from './ClubNewsListing';
import { EmptyState } from 'common/react/components/EmptyState';
import { formatTagsForTagExpression } from 'common/react/utils/format-tag-expression';
import { ClubNewsSkeletonLoader } from './ClubNewsSkeleton';

/**
 * Renders the loading state and content for the ClubNewsListing component.
 *
 * @param {string} title - The widget title.
 * @param {string} clubPid - The club Provider ID, e.g.'CD_T70'.
 * @param {string} clubAbbr - The club abbreviation, e.g.'geel'.
 * @param {string} articlesToShow - The number of articles to show.
 * @param {string} tags - The tags to filter on.
 * @returns {JSX.Element} Component.
 */
export const ClubNews = ({
    title,
    clubPid,
    clubAbbr,
    articlesToShow,
    tags
}) => {
    const { app } = PULSE;
    const tagsArray = tags.split(',');

    // Flexible base query as the base query changes for each club
    const baseQuery = app.environment[clubAbbr].api;
    const baseUrl = `${app.environment[clubAbbr].site}/news`;

    const { data: articlesData, isLoading } = useGetClubContentQuery({
        baseQuery: `${baseQuery}`,
        clubAbbr: clubAbbr,
        type: 'TEXT',
        params: {
            pageSize: articlesToShow,
            tagExpression: tags ? formatTagsForTagExpression(tagsArray) : null
        }
    });

    return (
        <>
            {isLoading ? (
                <div className="club-news__skeleton">
                    <ClubNewsSkeletonLoader className="club-news__item-skeleton" />
                    <ClubNewsSkeletonLoader className="club-news__item-skeleton" />
                    <ClubNewsSkeletonLoader className="club-news__item-skeleton" />
                    <ClubNewsSkeletonLoader className="club-news__item-skeleton" />
                </div>
            ) : !articlesData || !articlesData.length ? (
                <div className="key-stats-and-performance__empty-state">
                    <EmptyState
                        showIcon={false}
                        showSummary={false}
                        darkMode={true}
                    />
                </div>
            ) : (
                <ClubNewsListing
                    title={title}
                    articlesData={articlesData}
                    clubPid={clubPid}
                    clubAbbr={clubAbbr}
                    baseUrl={baseUrl}
                />
            )}
        </>
    );
};

ClubNews.propTypes = {
    title: PropTypes.string,
    clubAbbr: PropTypes.string,
    clubPid: PropTypes.string,
    articlesToShow: PropTypes.string,
    tags: PropTypes.string
};
