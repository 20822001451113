import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * @param root0
 * @param root0.data
 * @returns {JSX.Element} Bio and Awards section.
 */
export const BioAwards = ({ data }) => {
    const playerStats = data;
    const playerId = data.playerId;

    const [bioText, setBioText] = useState('');
    const [awardsText, setAwardsText] = useState('');

    const setBioAwardsText = function getBioAwardsText(bioData) {
        setBioText(bioData.content[0]?.description);
        setAwardsText(bioData.content[0]?.content);
    };

    useEffect(() => {
        PULSE.app.common.pulseId.getPlayerPulseId(playerId).then((res) => {
            const params = {
                pageSize: '1',
                references: `AFL_PLAYER:${res}`
            };
            const config = {
                url: PULSE.app.common.url.createContentPath(
                    'bio',
                    params,
                    'en'
                ),
                method: 'GET',
                callback: setBioAwardsText.bind(this),
                target: this
            };
            PULSE.core.data.request(config.url, config.method, config.callback);
        });
    }, []);

    return (
        <>
            {playerStats && (
                <>
                    <div className="pp-bio-awards">
                        <div className="pp-bio-awards__row">
                            <h3 className="pp-bio-awards__title pp-title">
                                {PULSE.I18N.lookup('label.player.biography')}
                            </h3>
                            <div className="pp-bio-awards__info-container">
                                {bioText && (
                                    <div className="pp-bio-awards__info-row">
                                        <p className="pp-bio-awards__text">
                                            {bioText}
                                        </p>
                                    </div>
                                )}
                                {playerStats.playerDetails.draftPosition && (
                                    <div className="pp-bio-awards__info-row">
                                        <h4 className="pp-bio-awards__heading">
                                            {PULSE.I18N.lookup(
                                                'label.stat.draft'
                                            )}
                                        </h4>
                                        <p className="pp-bio-awards__text">
                                            #
                                            {
                                                playerStats.playerDetails
                                                    .draftPosition
                                            }{' '}
                                            {
                                                playerStats.playerDetails
                                                    .draftYear
                                            }{' '}
                                            {PULSE.I18N.lookup(
                                                `label.stat.${playerStats.playerDetails.draftType}`
                                            )}
                                        </p>
                                    </div>
                                )}
                                {playerStats.playerDetails.recruitedFrom && (
                                    <div className="pp-bio-awards__info-row">
                                        <h4 className="pp-bio-awards__heading">
                                            {PULSE.I18N.lookup(
                                                'label.stat.recruitedFrom'
                                            )}
                                        </h4>
                                        <p
                                            className="pp-bio-awards__text"
                                            dangerouslySetInnerHTML={{
                                                __html: playerStats
                                                    .playerDetails.recruitedFrom
                                            }}
                                        ></p>
                                    </div>
                                )}
                            </div>
                        </div>

                        {(awardsText ||
                            playerStats.playerDetails.aflAwards) && (
                            <div className="pp-bio-awards__row">
                                <h3 className="pp-bio-awards__title pp-title">
                                    {PULSE.I18N.lookup('label.player.awards')}
                                </h3>
                                <div className="pp-bio-awards__info-container">
                                    {awardsText && (
                                        <div className="pp-bio-awards__info-row">
                                            <p
                                                className="pp-bio-awards__text"
                                                dangerouslySetInnerHTML={{
                                                    __html: awardsText
                                                }}
                                            ></p>
                                        </div>
                                    )}
                                    {playerStats.playerDetails.aflAwards && (
                                        <div className="pp-bio-awards__info-row">
                                            <h4 className="pp-bio-awards__heading">
                                                {PULSE.I18N.lookup(
                                                    'label.stat.brownlowMedal'
                                                )}
                                            </h4>
                                            <p className="pp-bio-awards__text">
                                                {
                                                    playerStats.playerDetails
                                                        .aflAwards
                                                }
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

BioAwards.propTypes = {
    data: PropTypes.object.isRequired
};
