/**
 * @typedef {object} EventsListingFilterItemPayload
 * @property {string} filterItem
 * @property {string[]} value
 */

/**
 * @param {any} draft - Draft state.
 * @param {EventsListingFilterItemPayload} payload - Action payload.
 */
export const setFilterItem = (draft, payload) => {
    const { filterItem, value } = payload;
    draft.filters[filterItem] = value;
};
