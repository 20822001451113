import { all, takeEvery } from 'redux-saga/effects';
import {
    FETCH_LEADERBOARD_DATA,
    UPDATE_NAV_ITEM,
    UPDATE_BETTING_TOGGLE,
    UPDATE_LEADERBOARD_FILTER,
    UPDATE_FAVOURITE_PLAYER
} from '../actions';
import { fetchLeaderboardData } from './fetch-leaderboard-data';
import { updateNavItem } from './update-nav-item';
import { updateBettingToggle } from './update-betting-odds';
import { updateLeaderboardFilter } from './update-leaderboard-filter';
import { updateFavouritePlayer } from './update-favourite-player';

/**
 *
 */
export function* leaderboardRootSaga() {
    yield all([
        takeEvery(UPDATE_FAVOURITE_PLAYER.REQUEST, updateFavouritePlayer),
        takeEvery(FETCH_LEADERBOARD_DATA.REQUEST, fetchLeaderboardData),
        takeEvery(UPDATE_NAV_ITEM.REQUEST, updateNavItem),
        takeEvery(UPDATE_BETTING_TOGGLE.REQUEST, updateBettingToggle),
        takeEvery(UPDATE_LEADERBOARD_FILTER.REQUEST, updateLeaderboardFilter)
    ]);
}
