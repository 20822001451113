import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param props.children
 * @returns {JSX.Element} Component.
 */
export const StatsSectionSettingsButton = ({ children }) => {
    return (
        <>
            {React.Children.map(children, (child) => (
                <>{child}</>
            ))}
        </>
    );
};

StatsSectionSettingsButton.defaultProps = {
    name: 'StatsSectionSettingsButton'
};

StatsSectionSettingsButton.propTypes = {
    children: PropTypes.any.isRequired
};
