import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param { object } stat - Stat object
 * @returns { Element } Loader or heading.
 */
export const BioStatItem = (stat) => {
    return stat.stat.value === '0kg' ? (
        ''
    ) : (
        <div className="pp-bio-stats__stat-item">
            <div className="pp-bio-stats__stat-name">{stat.stat.name}</div>
            <div className="pp-bio-stats__stat-value">{stat.stat.value}</div>
        </div>
    );
};

BioStatItem.propTypes = {
    stat: PropTypes.object.isRequired
};
