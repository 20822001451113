import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { navLink } from '../../../utils/nav-link';
import { NavItem } from './NavItem';

export const Nav = ({ showCompare }) => {
    const { active_tab: activeTab } = useParams();

    useEffect(() => {
        navLink(activeTab);
    });

    return (
        <nav className="stats-nav">
            <ul className="stats-nav__list">
                <NavItem
                    path="/"
                    icon="chart-v2"
                    translationLabel="label.statspro.statsHome"
                    shortTranslationLabel="label.statspro.statsHome.short"
                    tabName="home"
                />
                <NavItem
                    path="/leaders"
                    icon="list-number"
                    translationLabel="label.statspro.statsLeaders"
                    shortTranslationLabel="label.statspro.statsLeaders.short"
                    tabName="leaders"
                />
                {showCompare ? (
                    <NavItem
                        path="/compare"
                        icon="compare"
                        translationLabel="label.statspro.comparePlayers"
                        shortTranslationLabel="label.statspro.comparePlayers.short"
                        tabName="compare"
                    />
                ) : null}
            </ul>
        </nav>
    );
};

Nav.propTypes = {
    showCompare: PropTypes.bool.isRequired
};
