import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const Media = ({
    featureChannel,
    brightcoveProps,
    videoPlayerEleId,
    config = {},
    title
}) => {
    const [hasInitialised, setHasInitialised] = useState(false);

    const playerWidgetRef = useRef(null);
    const { accountId, policyKey, playerId } = brightcoveProps;

    const brightcoveId = featureChannel.customAttributes.find(
        (attr) => attr.name === 'brightcove_videoid_web'
    )?.value;

    const mediaImageUrl = featureChannel.customAttributes.find(
        (attr) => attr.name === 'thumbnailURL'
    )?.value;

    const mediaType =
        featureChannel?.info?.channelTypes?.[0]?.mediaType || 'VIDEO';

    const customAdTagUrl =
        mediaType === 'AUDIO' ? config?.audioAdTagUrl : config?.videoAdTagUrl;

    const disableAds = featureChannel.customAttributes.find(
        (attr) => attr.name === 'disableAds'
    )?.value;

    useEffect(() => {
        if (playerWidgetRef.current) {
            const additionalProperties = {
                popoutPlayerModel: {
                    mediaImageUrl,
                    title,
                    ...config,
                    featureChannel
                },
                playsinline: mediaType === 'AUDIO'
            };

            if (customAdTagUrl) {
                additionalProperties.adTagUrl = customAdTagUrl;
            }

            if (disableAds) {
                additionalProperties.disableAds = disableAds;
            }

            if (!hasInitialised) {
                if (mediaType === 'AUDIO') {
                    new PULSE.app.common.AudioPlayer(
                        playerWidgetRef.current,
                        [PULSE.app.common.middlewares.popoutAudio],
                        additionalProperties
                    );
                } else {
                    new PULSE.app.common.Video(
                        playerWidgetRef.current,
                        [],
                        additionalProperties
                    );
                }
                setHasInitialised(true);
            }
        }
    }, [
        featureChannel,
        videoPlayerEleId,
        mediaType,
        customAdTagUrl,
        disableAds,
        mediaImageUrl,
        config,
        title
    ]);

    return (
        <div
            className="inline-player inline-player--modal js-video-container is-active"
            data-widget="video-player"
            ref={playerWidgetRef}
        >
            <div className="inline-player__player-container">
                <video-js
                    id={videoPlayerEleId}
                    data-video-id={brightcoveId}
                    data-account={accountId}
                    data-policy-key={policyKey}
                    data-player={playerId}
                    class={`inline-player__video video-js vjs-16-9 js-video-player ${
                        mediaType === 'AUDIO'
                            ? 'inline-player--no-fullscreen inline-player--audio-only'
                            : ''
                    }`}
                    controls
                    poster={mediaImageUrl}
                    autoplay={featureChannel.autoplay}
                    playsinline={mediaType === 'AUDIO'}
                ></video-js>
            </div>
        </div>
    );
};

Media.propTypes = {
    featureChannel: PropTypes.object.isRequired,
    brightcoveProps: PropTypes.object.isRequired,
    videoPlayerEleId: PropTypes.string.isRequired,
    config: PropTypes.object,
    title: PropTypes.string.isRequired
};
