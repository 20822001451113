import { all, takeEvery } from 'redux-saga/effects';
import { FETCH_SEARCH_RESULTS } from '../actions';
import { fetchSearchResults } from './fetch-search-results';

/**
 *
 */
export function* searchRootSaga() {
    yield all([
        // @ts-ignore
        takeEvery(FETCH_SEARCH_RESULTS.REQUEST, fetchSearchResults)
    ]);
}
