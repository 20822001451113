import { put, call } from 'redux-saga/effects';
import { fetchWithMisToken } from '../../../../utils/fetch-with-mis-token';
import {
    FETCH_PERFORMANCE_CHART_STATS,
    removePerformanceChartStatsRequest,
    setPerformanceChartStats,
    setPerformanceChartSeasonsStats,
    setPerformanceChartStatsRequest,
    setPlayerTwoChartStats,
    setPlayerTwoChartSeasonsStats
} from '../actions';

/**
 * @typedef {object} FetchPerformanceChartStatsAction
 * @property {object} payload
 * @property {string} payload.type
 * @property {string} payload.playerId
 * @property {string} payload.seasonId
 * @property {number} payload.index
 * @property {boolean} payload.reset
 */

/**
 * @param {FetchPerformanceChartStatsAction} action - The Redux action.
 */

/**
 *
 * @param action
 */
export function* fetchPerformanceChartStats(action) {
    const { type, playerId, seasonId, index, reset } = action.payload;

    yield put(setPerformanceChartStatsRequest(action.payload));

    let data = {};

    if (type === 'career' && playerId) {
        data = yield call(
            fetchWithMisToken,
            `${PULSE.app.environment.statsPro}/playerCareerSeasonStats/${playerId}/benchmarked`
        );

        if (index === 1) {
            yield put(setPerformanceChartStats({ ...data }));
        } else {
            yield put(setPlayerTwoChartStats({ ...data }));
        }
    } else if (type === 'season') {
        if (!reset && playerId) {
            data = yield call(
                fetchWithMisToken,
                `${PULSE.app.environment.statsPro}/playerSeasonRoundStats/${playerId}/benchmarked?seasonId=${seasonId}`
            );

            if (data.roundStats?.length) {
                if (index === 1) {
                    yield put(setPerformanceChartSeasonsStats({ ...data }));
                } else {
                    yield put(setPlayerTwoChartSeasonsStats({ ...data }));
                }
            } else if (index === 1) {
                // Error handling for when there is no data
                yield put(
                    setPerformanceChartSeasonsStats({
                        seasonId: seasonId,
                        roundStats: [{ error: 'no data available' }]
                    })
                );
            } else {
                yield put(
                    setPlayerTwoChartSeasonsStats({
                        seasonId: seasonId,
                        roundStats: [{ error: 'no data available' }]
                    })
                );
            }
        } else if (index === 1) {
            yield put(setPerformanceChartSeasonsStats({ reset }));
        } else {
            yield put(setPlayerTwoChartSeasonsStats({ reset }));
        }
    }

    yield put(removePerformanceChartStatsRequest(action.payload));

    yield put(FETCH_PERFORMANCE_CHART_STATS.success(data));
}
