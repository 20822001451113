import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchWithRetryBQ } from './base-queries/fetch-with-retry';

const { app } = PULSE;

// RTK Query update: Define an api service with a base URL and retry attempts
// RTK Query update: Endpoints can be added here or injected in components
export const aflcContentApi = createApi({
    reducerPath: 'aflcContentApi',
    baseQuery: fetchWithRetryBQ(),
    endpoints: (builder) => ({
        // GET /content/{accountName}/{type}/{lang} - Get Content by type/lang
        getClubContent: builder.query({
            query: ({ baseQuery, clubAbbr, type, params }) => {
                return `${baseQuery}/content/aflc-${clubAbbr}/${type}/${
                    app.language
                }/${app.common.prepareParams(params)}`;
            },
            transformResponse: (response) => response?.content
        })
    })
});

export const { useGetClubContentQuery } = aflcContentApi;
