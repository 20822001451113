import { createSelector } from 'reselect';
import { navSelector } from '.';

/**
 * @returns {( object ) => object|null} Compseason.
 */
export const compSeasonSelector = createSelector(
    navSelector,
    (state) => state.seasons,
    (nav, compSeasons) => {
        try {
            return compSeasons[`competition_${nav.competition.id}`][
                `season_${nav.season.id}`
            ];
        } catch (err) {
            return null;
        }
    }
);
