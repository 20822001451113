import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { StatComparison } from '../components/stat-comparison';

export const StatComparisonProvider = ({ store, data }) => (
    <Provider store={store}>
        <StatComparison {...data} />
    </Provider>
);

StatComparisonProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
