/**
 * @typedef {object} Payload
 * @property {string} navItem
 * @property {string|number|object} value
 */

/**
 * @param {any} draft - Draft state.
 * @param {Payload} payload - Action payload.
 */
export const setNavItem = (draft, payload) => {
    const { navItem, value } = payload;

    if (draft.nav[navItem] === null) {
        draft.nav[navItem] = value;
        return;
    }

    if (typeof draft.nav[navItem] === 'object') {
        draft.nav[navItem] = {
            ...draft.nav[navItem],
            ...value
        };
        return;
    }

    draft.nav[navItem] = value;
};
