import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

import {
    sliderSettings,
    BREAKPOINTS,
    BREAKPOINTS_FINALS
} from '../components/Slider/config/slider';
import { Match } from './Match';

export const MatchContainer = ({ matches, isFinals, allMatchesLink, isStaticButton }) => {
    const allMatchesText = PULSE.I18N.lookup('label.fixtureBar.allMatches');

    const breakpoints = isFinals ? BREAKPOINTS_FINALS : BREAKPOINTS;
    const byes = !isFinals ? matches[0].round.byes : false;
    const minMatchesForSwiper = 3;
    const sliderName = 'fixture-bar';

    return (
        <div
            className={`fixture-bar__matches-container ${
                isFinals ? 'fixture-bar__matches-container--static' : ''
            } ${
                isStaticButton ? 'is-static-button' : ''
            }` }
        >
            {isFinals &&
                matches.length < minMatchesForSwiper &&
                matches.map((match, index) => (
                    <ErrorBoundary
                        key={`match-error-${index}`}
                        fallback={<></>}
                    >
                        <Match
                            match={match}
                            key={index}
                            isFinals={isFinals}
                        ></Match>
                    </ErrorBoundary>
                ))}
            {isFinals && matches.length < minMatchesForSwiper && (
                <a
                    href={allMatchesLink}
                    className="fixture-bar__all-matches"
                    target="_blank"
                    aria-label={allMatchesText}
                    rel="noreferrer"
                >
                    {allMatchesText}
                </a>
            )}
            {(!isFinals || matches.length >= minMatchesForSwiper) && (
                <>
                    <Swiper {...sliderSettings(sliderName)} {...breakpoints}>
                        {matches.map((match, index) => (
                            <SwiperSlide key={index}>
                                <ErrorBoundary fallback={<></>}>
                                    <Match
                                        match={match}
                                        isFinals={isFinals}
                                    ></Match>
                                </ErrorBoundary>
                            </SwiperSlide>
                        ))}
                        {/* method for detecting byes to be updated when we use api data instead of mock */}
                        {byes.length && (
                            <SwiperSlide>
                                <ErrorBoundary fallback={<></>}>
                                    <Match byes={byes}></Match>
                                </ErrorBoundary>
                            </SwiperSlide>
                        )}
                        {!isStaticButton && 
                            <SwiperSlide>
                                <a
                                    href={allMatchesLink}
                                    className="fixture-bar__all-matches"
                                    target="_blank"
                                    aria-label={allMatchesText}
                                    rel="noreferrer"
                                >
                                    {allMatchesText}
                                </a>
                            </SwiperSlide>
                        }
                    </Swiper>
                    {isStaticButton && 
                        <a
                            href={allMatchesLink}
                            className="fixture-bar__all-matches"
                            target="_blank"
                            aria-label={allMatchesText}
                            rel="noreferrer"
                        >
                            {allMatchesText}
                        </a>
                    }
                </>
            )}
        </div>
    );
};

MatchContainer.propTypes = {
    matches: PropTypes.array.isRequired,
    isFinals: PropTypes.bool.isRequired,
    allMatchesLink: PropTypes.string.isRequired,
    isStaticButton: PropTypes.bool.isRequired
};
