import React from 'react';
import { useSelector } from 'react-redux';

import { useDataType } from '../../../common/hooks/use-data-type';
import {
    roundSelector,
    seasonSelector,
    performanceChartDataTypeSelector
} from 'store/modules/statspro/performance-chart/selectors';
import { getTranslation } from 'common/react/utils/translations';
import { ToggleInput } from 'common/react/components/ToggleInput';
import { UPDATE_DATA_TYPE } from 'store/modules/statspro/performance-chart/actions';
import { DATA_TYPE } from '../../../common/config/data-type';

export const DataTypeToggle = () => {
    const round = useSelector(roundSelector);
    const season = useSelector(seasonSelector);
    const initialDataType = useSelector(performanceChartDataTypeSelector);

    const { dataType, updateDataType } = useDataType({
        initialDataType,
        onUpdateActionCreator: UPDATE_DATA_TYPE.request
    });

    return (
        <ToggleInput
            id="performance-chart-data-type-toggle"
            value={
                dataType.type.toLowerCase().includes(DATA_TYPE.TOTALS)
                    ? DATA_TYPE.TOTALS
                    : DATA_TYPE.PER_GAME
            }
            onChange={updateDataType}
            options={[
                {
                    label: getTranslation('label.stats.totals'),
                    value: DATA_TYPE.TOTALS
                },
                {
                    label: getTranslation('label.statspro.perGame'),
                    value: DATA_TYPE.PER_GAME,
                    disabled: (!round && !season) || !!round
                }
            ]}
        />
    );
};
