import reduxQuerySync from 'redux-query-sync';

import { UPDATE_NAV_ITEM } from 'store/modules/competition-nav/actions';
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';

export const configureReduxQuerySync = (store) => {
    return reduxQuerySync({
        store,
        params: {
            comp: {
                selector: (state) => navSelector(state)?.competition?.id,
                action: (competitionId) => {
                    if (!competitionId) {
                        return { type: '' };
                    }

                    return UPDATE_NAV_ITEM.request({
                        navItem: 'competition',
                        value: {
                            id: competitionId ? competitionId : ''
                        }
                    });
                },
                stringToValue: (compId) => Number(compId)
            }
        },
        initialTruth: 'location'
    });
};
