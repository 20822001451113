/**
 * Format an array of tags into a string to be used as a tagExpression
 *
 * @param tagValues
 * @returns {string} The formatted string to use in the param
 */
export const formatTagsForTagExpression = (tagValues = []) => {
    if (!tagValues.length) {
        return [];
    }
    return `(${tagValues.map((item) => `"${item}"`).join('or')})`;
};
