import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from '../Loader';
import { SelectMultiple } from './SelectMultiple';

/**
 * @param {object} props - Props.
 * @param {string} props.label - Label.
 * @param {any[]} props.items - Items.
 * @param {Function} [props.itemToString] - Item to string.
 * @param {Function} [props.isReadyToRender] - Determines whether the select has
 *    all the info it needs to render.
 * @param {Function} [props.getInitialSelectedItems]- Get initially selected
 *    item(s).
 * @param {Function} props.onSelectedItemsChange - On change callback.
 * @param {Function} props.addOnResetCallback - On reset callback.
 * @param {Function} props.getItemText - Given an item, get the text that
 *    represents the item.
 * @param {Function} props.getItemLabel - Given an item, get the label for the
 *    item. It can be text or some JSX.
 *  @param {Function} props.getItemDisabled - Get disabled state for item if required.
 * @returns {JSX.Element} Component.
 */
export const LoadableSelectMultiple = (props) => {
    const { isReadyToRender, ...otherProps } = props;

    const shouldShowLoader = (() => {
        if (isReadyToRender) {
            return !isReadyToRender();
        }

        return !props.items;
    })();

    if (shouldShowLoader) {
        return (
            <div className="select-multiple">
                <div className="select-multiple__loader-wrapper">
                    <Loader />
                </div>
            </div>
        );
    }

    return <SelectMultiple {...otherProps} />;
};

LoadableSelectMultiple.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    items: PropTypes.arrayOf(PropTypes.any),
    itemToString: PropTypes.func,
    isReadyToRender: PropTypes.func,
    getInitialSelectedItems: PropTypes.func,
    onSelectedItemsChange: PropTypes.func.isRequired,
    addOnResetCallback: PropTypes.func.isRequired,
    getItemText: PropTypes.func.isRequired,
    getItemLabel: PropTypes.func.isRequired,
    getItemDisabled: PropTypes.func
};
