/* eslint-disable complexity */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { CompIcon } from 'common/react/components/CompIcon';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { useBettingVis } from 'common/react/hooks/use-betting-vis';

import { FixtureTeam } from './FixtureTeam';
import { FixtureScore } from './FixtureScore';
import { FixtureVenue } from './FixtureVenue';
import { FixturePosition } from './FixturePosition';

import { useGetLadderQuery } from 'store/modules/api/afl-api';
import { FixtureSummary } from './FixtureSummary';

import { getLadderPosition } from '../../utils/get-ladder-position';
import { FixtureBettingOdds } from './FixtureBettingOdds';
import { FixtureFreeEntry } from './FixtureFreeEntry';
import { FixturePrematchLabel } from './FixturePrematchLabel';
import { FixtureSoldOut } from './FixtureSoldOut';

const getConferenceLabel = (match, isAway = false) => {
    const conference = isAway
        ? match?.away?.conference
        : match?.home?.conference;

    if (conference?.toUpperCase().includes('CONFERENCE')) {
        return conference.replace(/\s+/g, '-').toLowerCase();
    }

    return null;
};

export const FixtureDetails = ({
    match,
    specialRound,
    liveMatch,
    link,
    matchStatus
}) => {
    const compAbbreviation = PULSE.app.common.match
        .getCompAbbr(match?.compSeason?.providerId)
        ?.toLowerCase();

    const home = { ...match?.home };
    const away = { ...match?.away };
    const hasFreeEntry = match?.metadata?.free_entry === 'true' ? true : false;
    const isSoldOut = match?.metadata?.sold_out === 'true' ? true : false;
    const prematchLabel = match?.metadata?.prematch_label
        ? match.metadata.prematch_label
        : '';
    const hasHeaderBadges = hasFreeEntry || prematchLabel || isSoldOut;

    const isLive =
        matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE
            ? true
            : false;

    if (liveMatch?.score) {
        home.liveScore = liveMatch.score.homeTeamScore?.matchScore?.totalScore;
        away.liveScore = liveMatch.score.awayTeamScore?.matchScore?.totalScore;
    }

    const showPosition =
        matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING ||
        matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.TBC ||
        matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.POSTPONED ||
        matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.CANCELLED;

    const { ladderData } = useGetLadderQuery(
        {
            compSeasonId: match.compSeason.id
        },
        {
            selectFromResult: ({ data }) => ({
                ladderData: data?.ladders
            }),
            skip:
                !match ||
                matchStatus !== PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING
        }
    );

    const homePosition = useMemo(() => {
        if (ladderData && ladderData[0].entries) {
            return getLadderPosition(
                ladderData[0].entries,
                home.team.providerId
            );
        }
    }, [home, ladderData]);

    const awayPosition = useMemo(() => {
        if (ladderData && ladderData[0].entries) {
            return getLadderPosition(
                ladderData[0].entries,
                away.team.providerId
            );
        }
    }, [away, ladderData]);

    const isBettingVisible = useBettingVis();

    return (
        <div className="fixtures__details">
            <a
                href={link}
                className="fixtures__absolute-link"
                aria-label={`${home?.team.nickname} vs ${away?.team.nickname} match centre`}
            ></a>

            <div className="fixtures__details-main">
                <div className="fixtures__responsive-team-versus">
                    <span>
                        {home?.team.name} v {away?.team.name}
                    </span>
                </div>

                <div className="fixtures__match-team fixtures__match-team--home">
                    <FixtureTeam
                        team={home?.team}
                        theme={isLive ? 'light' : ''}
                        conferenceLabel={getConferenceLabel(match)}
                    />
                </div>
                <div className="fixtures__match-scoreboard">
                    {isLive && (
                        <FixtureScore
                            score={liveMatch?.score?.homeTeamScore?.matchScore}
                        />
                    )}

                    {/* This also checks if live match has transitioned to completed */}
                    {matchStatus ===
                        PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED && (
                        <FixtureScore
                            score={
                                liveMatch?.score?.homeTeamScore?.matchScore
                                    ? liveMatch.score.homeTeamScore.matchScore
                                    : home?.score
                            }
                        />
                    )}

                    {showPosition && (
                        <FixturePosition position={homePosition} />
                    )}

                    {specialRound === 'Gather Round' ? (
                        <SvgIcon
                            icon="gather-round-flag"
                            className={
                                'fixtures__match-score-divider__gather-round'
                            }
                        />
                    ) : specialRound === 'Finals Hub' ? (
                        <SvgIcon
                            icon="trophy"
                            className={
                                'fixtures__match-score-divider__finals-hub'
                            }
                        />
                    ) : (
                        <div className="fixtures__match-score-divider-outline">
                            <div
                                className={`fixtures__match-score-divider fixtures__match-score-divider--${compAbbreviation}`}
                            >
                                {compAbbreviation === 'afl' ? (
                                    <img
                                        src={`${PULSE.app.environment.cdn}i/elements/afl-logo.png`}
                                        className="icon fixtures__match-score-divider-icon fixtures__match-score-divider-icon--afl"
                                        alt=""
                                    />
                                ) : (
                                    <CompIcon
                                        code={compAbbreviation}
                                        className="icon fixtures__match-score-divider-icon"
                                    />
                                )}
                            </div>
                        </div>
                    )}

                    {isLive && (
                        <FixtureScore
                            score={liveMatch?.score?.awayTeamScore?.matchScore}
                        />
                    )}

                    {/* This also checks if live match has transitioned to completed */}
                    {matchStatus ===
                        PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED && (
                        <FixtureScore
                            score={
                                liveMatch?.score?.awayTeamScore?.matchScore
                                    ? liveMatch.score.awayTeamScore.matchScore
                                    : away?.score
                            }
                        />
                    )}

                    {showPosition && (
                        <FixturePosition position={awayPosition} />
                    )}
                </div>

                <div className="fixtures__match-team fixtures__match-team--away">
                    <FixtureTeam
                        team={away?.team}
                        theme={isLive ? 'light' : ''}
                        conferenceLabel={getConferenceLabel(match, true)}
                    />
                </div>
            </div>

            {matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING &&
                isBettingVisible && (
                    <div className="fixtures__betting-odds">
                        <FixtureBettingOdds match={match} />
                    </div>
                )}

            {matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING &&
            hasHeaderBadges ? (
                <div className="fixtures__badges-container">
                    {prematchLabel ? (
                        <FixturePrematchLabel prematchLabel={prematchLabel} />
                    ) : null}
                    {hasFreeEntry ? (
                        <FixtureFreeEntry competition={compAbbreviation} />
                    ) : null}
                    {isSoldOut ? <FixtureSoldOut /> : null}
                </div>
            ) : null}

            {matchStatus !==
                PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING && (
                <FixtureSummary
                    home={home}
                    away={away}
                    isLive={isLive}
                    matchStatus={matchStatus}
                />
            )}

            {match.venue && <FixtureVenue venue={match.venue} />}
        </div>
    );
};

FixtureDetails.propTypes = {
    match: PropTypes.object.isRequired,
    specialRound: PropTypes.string,
    liveMatch: PropTypes.object,
    matchStatus: PropTypes.string,
    link: PropTypes.string
};
