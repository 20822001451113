export const CUSTOM_TABLE_FIELDS = {
    General: {
        groupName: 'General',
        groupLabel: 'label.stats.general',
        fields: [
            {
                fieldName: 'ratingPoints',
                fieldLabel: 'label.stats.rating',
                fieldLabelShort: 'label.stats.rating.short'
            },
            {
                fieldName: 'freesAgainst',
                fieldLabel: 'label.stats.freeAgainst',
                fieldLabelShort: 'label.stats.freeAgainst.short'
            },
            {
                fieldName: 'bounces',
                fieldLabel: 'label.stats.bounces',
                fieldLabelShort: 'label.stats.bounces.short'
            },
            {
                fieldName: 'timeOnGroundPercentage',
                fieldLabel: 'label.stats.timeOnGround',
                fieldLabelShort: 'label.stats.timeOnGround.short'
            },
            {
                fieldName: 'freesFor',
                fieldLabel: 'label.stats.freeFor',
                fieldLabelShort: 'label.stats.freeFor.short'
            },
            {
                fieldName: 'dreamTeamPoints',
                fieldLabel: 'label.mc.stats.dreamteampoints',
                fieldLabelShort: 'label.mc.stats.dreamteampoints.short'
            },
            {
                fieldName: 'onePercenters',
                fieldLabel: 'label.stats.onePercenters',
                fieldLabelShort: 'label.stats.onePercenters.short'
            },
            {
                fieldName: 'gamesPlayed',
                fieldLabel: 'label.stats.matchesPlayed',
                fieldLabelShort: 'label.stats.matchesPlayed.short'
            }
        ]
    },
    Disposals: {
        groupName: 'Disposals',
        groupLabel: 'label.stats.disposals',
        fields: [
            {
                fieldName: 'disposals',
                fieldLabel: 'label.stats.disposals',
                fieldLabelShort: 'label.stats.disposals.short'
            },
            {
                fieldName: 'kicks',
                fieldLabel: 'label.stats.kicks',
                fieldLabelShort: 'label.stats.kicks.short'
            },
            {
                fieldName: 'handballs',
                fieldLabel: 'label.stats.handballs',
                fieldLabelShort: 'label.stats.handballs.short'
            },
            {
                fieldName: 'inside50s',
                fieldLabel: 'label.stats.inside50',
                fieldLabelShort: 'label.stats.inside50s.short'
            },
            {
                fieldName: 'disposalEfficiency',
                fieldLabel: 'label.stats.disposalEfficiency',
                fieldLabelShort: 'label.stats.disposalEfficiency.short'
            },
            {
                fieldName: 'clangers',
                fieldLabel: 'label.stats.clangers',
                fieldLabelShort: 'label.stats.clangers.short'
            },
            {
                fieldName: 'rebound50s',
                fieldLabel: 'label.stats.rebound50s',
                fieldLabelShort: 'label.stats.rebound50s.short'
            },
            {
                fieldName: 'metresGained',
                fieldLabel: 'label.stats.metresGained',
                fieldLabelShort: 'label.stats.metresGained.short'
            },
            {
                fieldName: 'effectiveKicks',
                fieldLabel: 'label.stats.effectiveKicks',
                fieldLabelShort: 'label.stats.effectiveKicks.short'
            },
            {
                fieldName: 'kickEfficiency',
                fieldLabel: 'label.stats.kickEffeciency',
                fieldLabelShort: 'label.stats.kickEffeciency.short'
            },
            {
                fieldName: 'kickToHandballRatio',
                fieldLabel: 'label.stats.kickToHandballRatio',
                fieldLabelShort: 'label.stats.kickToHandballRatio.short'
            },
            {
                fieldName: 'effectiveDisposals',
                fieldLabel: 'label.stats.effectiveDisposals',
                fieldLabelShort: 'label.stats.effectiveDisposals.short'
            }
        ]
    },
    Possesions: {
        groupName: 'Possesions',
        groupLabel: 'label.stats.possesions',
        fields: [
            {
                fieldName: 'contestedPossessions',
                fieldLabel: 'label.stats.contestedPossessions',
                fieldLabelShort: 'label.stats.contestedPossessions.short'
            },
            {
                fieldName: 'uncontestedPossessions',
                fieldLabel: 'label.stats.uncontestedPossessions',
                fieldLabelShort: 'label.stats.uncontestedPossessions.short'
            },
            {
                fieldName: 'intercepts',
                fieldLabel: 'label.stats.intercepts',
                fieldLabelShort: 'label.stats.intercepts.short'
            },
            {
                fieldName: 'turnovers',
                fieldLabel: 'label.stats.turnovers',
                fieldLabelShort: 'label.stats.turnovers.short'
            },
            {
                fieldName: 'contestedPossessionRate',
                fieldLabel: 'label.stats.contestedPossessionRate',
                fieldLabelShort: 'label.stats.contestedPossessionRate.short'
            },
            {
                fieldName: 'groundBallGets',
                fieldLabel: 'label.stats.groundBallGets',
                fieldLabelShort: 'label.stats.groundBallGets.short'
            },
            {
                fieldName: 'f50GroundBallGets',
                fieldLabel: 'label.stats.f50GroundBallGets',
                fieldLabelShort: 'label.stats.f50GroundBallGets.short'
            }
        ]
    },
    Stoppages: {
        groupName: 'Stoppages',
        groupLabel: 'label.stats.stoppages',
        fields: [
            {
                fieldName: 'hitouts',
                fieldLabel: 'label.stats.hitouts',
                fieldLabelShort: 'label.stats.hitouts.short'
            },
            {
                fieldName: 'centreClearances',
                fieldLabel: 'label.stats.centreClearances',
                fieldLabelShort: 'label.stats.centreClearances.short'
            },
            {
                fieldName: 'stoppageClearances',
                fieldLabel: 'label.stats.stoppageClearances',
                fieldLabelShort: 'label.stats.stoppageClearances.short'
            },
            {
                fieldName: 'totalClearances',
                fieldLabel: 'label.stats.totalClearances',
                fieldLabelShort: 'label.stats.totalClearances.short'
            },
            {
                fieldName: 'hitoutsToAdvantage',
                fieldLabel: 'label.stats.hitoutsToAdvantage',
                fieldLabelShort: 'label.stats.hitoutsToAdvantage.short'
            },
            {
                fieldName: 'hitoutWinPercentage',
                fieldLabel: 'label.stats.hitoutWinPercentage',
                fieldLabelShort: 'label.stats.hitoutWinPercentage.short'
            },
            {
                fieldName: 'hitoutToAdvantageRate',
                fieldLabel: 'label.stats.hitoutToAdvantageRate',
                fieldLabelShort: 'label.stats.hitoutToAdvantageRate.short'
            },
            {
                fieldName: 'ruckContests',
                fieldLabel: 'label.stats.ruckContests',
                fieldLabelShort: 'label.stats.ruckContests.short'
            }
        ]
    },
    Marks: {
        groupName: 'Marks',
        groupLabel: 'label.stats.marks',
        fields: [
            {
                fieldName: 'marks',
                fieldLabel: 'label.stats.marks',
                fieldLabelShort: 'label.stats.marks.short'
            },
            {
                fieldName: 'marksInside50',
                fieldLabel: 'label.stats.marksInside50',
                fieldLabelShort: 'label.stats.marksInside50.short'
            },
            {
                fieldName: 'marksOnLead',
                fieldLabel: 'label.stats.marksOnLead',
                fieldLabelShort: 'label.stats.marksOnLead.short'
            },
            {
                fieldName: 'interceptMarks',
                fieldLabel: 'label.stats.interceptMarks',
                fieldLabelShort: 'label.stats.interceptMarks.short'
            },
            {
                fieldName: 'contestedMarks',
                fieldLabel: 'label.stats.contestedMarks',
                fieldLabelShort: 'label.stats.contestedMarks.short'
            }
        ]
    },
    Scoring: {
        groupName: 'Scoring',
        groupLabel: 'label.stats.scoring',
        fields: [
            {
                fieldName: 'goals',
                fieldLabel: 'label.stats.goals',
                fieldLabelShort: 'label.stats.goals.short'
            },
            {
                fieldName: 'behinds',
                fieldLabel: 'label.stats.behinds',
                fieldLabelShort: 'label.stats.behinds.short'
            },
            {
                fieldName: 'goalAssists',
                fieldLabel: 'label.stats.goalAssists',
                fieldLabelShort: 'label.stats.goalAssists.short'
            },
            {
                fieldName: 'goalAccuracy',
                fieldLabel: 'label.stats.goalAccuracy',
                fieldLabelShort: 'label.stats.goalAccuracy.short'
            },
            {
                fieldName: 'shotsAtGoal',
                fieldLabel: 'label.stats.shotsAtGoal',
                fieldLabelShort: 'label.stats.shotsAtGoal.short'
            },
            {
                fieldName: 'scoreInvolvements',
                fieldLabel: 'label.stats.scoreInvolvements',
                fieldLabelShort: 'label.stats.scoreInvolvements.short'
            },
            {
                fieldName: 'scoreLaunches',
                fieldLabel: 'label.stats.scoreLaunches',
                fieldLabelShort: 'label.stats.scoreLaunches.short'
            }
        ]
    },
    Defense: {
        groupName: 'Defense',
        groupLabel: 'label.stats.defense',
        fields: [
            {
                fieldName: 'tackles',
                fieldLabel: 'label.stats.tackles',
                fieldLabelShort: 'label.stats.tackles.short'
            },
            {
                fieldName: 'tacklesInside50',
                fieldLabel: 'label.stats.tacklesInside50',
                fieldLabelShort: 'label.stats.tacklesInside50.short'
            },
            {
                fieldName: 'pressureActs',
                fieldLabel: 'label.stats.pressureActs',
                fieldLabelShort: 'label.stats.pressureActs.short'
            },
            {
                fieldName: 'defHalfPressureActs',
                fieldLabel: 'label.stats.defHalfPressureActs',
                fieldLabelShort: 'label.stats.defHalfPressureActs.short'
            },
            {
                fieldName: 'spoils',
                fieldLabel: 'label.stats.spoils',
                fieldLabelShort: 'label.stats.spoils.short'
            },
            {
                fieldName: 'contestDefOneOnOnes',
                fieldLabel: 'label.stats.contestDefOneOnOnes',
                fieldLabelShort: 'label.stats.contestDefOneOnOnes.short'
            },
            {
                fieldName: 'contestDefLosses',
                fieldLabel: 'label.stats.contestDefLosses',
                fieldLabelShort: 'label.stats.contestDefLosses.short'
            }
        ]
    }
};
