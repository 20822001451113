import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useGetContentItemQuery } from 'common/store/modules/api/content-api';
import { LazyImage } from 'common/react/components/LazyImage';

export const MapLocationSelectorOptionImage = ({
    location,
    handleImageUrl
}) => {
    const venueLogo = useGetContentItemQuery({
        type: location?.imgRef?.contentType,
        id: location?.imgRef?.reference
    });
    const imageURL = `${venueLogo.data?.onDemandUrl}?width=50`;

    useEffect(() => {
        handleImageUrl({
            name: location.name,
            imgRef: imageURL,
            id: location.id
        });
    }, []);
    return (
        <LazyImage
            url={imageURL}
            useImg={true}
            objectFit={false}
            cssClass="map-location-selector__options-item-logo"
        />
    );
};

MapLocationSelectorOptionImage.propTypes = {
    location: PropTypes.shape({
        name: PropTypes.string,
        imgRef: PropTypes.object,
        id: PropTypes.string
    }),
    selectedVenue: PropTypes.shape({
        name: PropTypes.string,
        imgRef: PropTypes.string
    }),
    handleImageUrl: PropTypes.func
};
