import React from 'react';
import cx from 'classnames';

import { DefaultCellRendererContainer } from 'custom-table/js/components/DefaultCell';

/*
    This is a simple example of a custom cell renderer, just to show how we could override the default.
*/

const PlayerJumperCellRenderer = ({
    className,
    value,
    config,
    ...additionalProps
}) => {
    const getTeamClass = (teamId) => {
        if (config.homeTeamId === teamId) {
            return `home ${config.homeTeamAbbr}`;
        }

        return `away ${config.awayTeamAbbr}`;
    };

    const playerId = value.playerId ? value.playerId.replace('CD_I', '') : '';

    return (
        <DefaultCellRendererContainer
            className={cx(
                className,
                'custom-table__cell--bold custom-table__cell--fixed-jumper'
            )}
        >
            {
                <div className="mc-player-stats__player-info mc-player-stats__player-info--custom-table">
                    <div
                        className="mc-player-stats__headshot u-hide-until-phablet"
                        data-widget="player-headshot"
                    >
                        <div
                            className="js-player-image"
                            data-player={`${playerId}`}
                            data-picture-classes="mc-player-stats__headshot-picture"
                            data-wrapper-class="mc-player-stats__headshot-wrapper"
                            data-size="STAT_TABLE"
                            data-picture-in-view="false"
                        ></div>
                    </div>
                    <span
                        className={`mc-player-stats__jumper-number mc-player-stats__jumper-number--${getTeamClass(
                            additionalProps.tableRow.teamId
                        )} `}
                    >
                        {value.jumperNumber}
                    </span>
                </div>
            }
        </DefaultCellRendererContainer>
    );
};

export default PlayerJumperCellRenderer;
