import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { SwitchInput } from 'common/react/components/SwitchInput';
import { CheckboxInput } from 'common/react/components/CheckboxInput';
import { getTranslation } from 'common/react/utils/translations';

export const MarketingPreferences = ({
    title,
    subtitle,
    marketingPrefs,
    onToggleAllClick,
    onToggleClick,
    onSaveClick
}) => {
    const [viewModalEventRecorded, setViewModalEventRecorded] = useState(false);

    if (!viewModalEventRecorded) {
        PULSE.app.tracking.recordEvent('view_modal', {
            eventData: {
                name: getTranslation(
                    'settingsModal.communicationPreferences.title'
                )
            }
        });
        setViewModalEventRecorded(true);
    }

    const [privacyAgreed, setPrivacyAgreed] = useState(
        marketingPrefs.consents.privacy
    );

    const showPrivacyAgreement = useMemo(() => {
        const anyTrueSubscription = marketingPrefs.subscriptions.some(
            (setting) => {
                return setting.status === true;
            }
        );

        // If any subs are true, then show the privacy agreement
        if (anyTrueSubscription) {
            return true;
        }

        // If not un checked privacy agreement and hide it
        setPrivacyAgreed(false);
        return false;
    }, [marketingPrefs.subscriptions]);

    const areAllEnabled = useMemo(() => {
        return !marketingPrefs.subscriptions.some((setting) => {
            return setting.status === false;
        });
    }, [marketingPrefs.subscriptions]);

    const isBtnDisabled = useMemo(() => {
        if (showPrivacyAgreement && !privacyAgreed) {
            return true;
        }

        return false;
    }, [showPrivacyAgreement, privacyAgreed]);

    return (
        <div className={`modal-marketing-preferences`}>
            {title ? (
                <p className="modal-marketing-preferences__title">{title}</p>
            ) : null}
            {subtitle ? (
                <p className="modal-marketing-preferences__subtitle">
                    {subtitle}
                </p>
            ) : null}

            <SwitchInput
                id={'enableAll'}
                label={getTranslation('communication.preferences.enableAll')}
                isChecked={areAllEnabled}
                onChange={(newValue) => {
                    if (newValue === true) {
                        onToggleAllClick(newValue);
                    }
                }}
                onClick={() =>
                    areAllEnabled
                        ? onToggleAllClick(false)
                        : onToggleAllClick(true)
                }
            />
            {marketingPrefs.subscriptions.map((setting) => (
                <SwitchInput
                    id={setting.name}
                    label={setting.name}
                    isChecked={setting.status}
                    onChange={(newValue) =>
                        onToggleClick(setting.name, newValue)
                    }
                    key={setting.name}
                />
            ))}
            {showPrivacyAgreement ? (
                <div className="modal-marketing-preferences__privacy-agreement">
                    <CheckboxInput
                        id={'privacy-agreement'}
                        label={getTranslation(
                            'settingsModal.PrivacyAgreementNote'
                        )}
                        isChecked={privacyAgreed}
                        onChange={(e) => setPrivacyAgreed(e)}
                    />
                </div>
            ) : null}

            <div className="modal-marketing-preferences__confirmation-container">
                <button
                    disabled={isBtnDisabled}
                    onClick={() => {
                        onSaveClick(privacyAgreed);
                        PULSE.app.common.siteSettings.closeSettingsModal();
                    }}
                    className="button-new button-new--primary modal-marketing-preferences-confirmation"
                >
                    {getTranslation('label.done')}
                </button>
            </div>
        </div>
    );
};

MarketingPreferences.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    marketingPrefs: PropTypes.object.isRequired,
    onToggleAllClick: PropTypes.func.isRequired,
    onToggleClick: PropTypes.func.isRequired,
    onSaveClick: PropTypes.func.isRequired
};
