import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { EventsListing } from '../components/EventsListing';

export const EventsListingProvider = ({ store, data }) => {
    return (
        <Provider store={store}>
            <EventsListing {...data} />
        </Provider>
    );
};

export default EventsListingProvider;

EventsListingProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
