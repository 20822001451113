import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { useCareerAndSeasonStatsNavSync } from './hooks/use-career-and-season-stats-nav-sync';
import { CategoryNavItem, SeasonNavItem } from './components/nav-items';
import { CareerAndSeasonStatsTable } from './components/table';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import {
    careerAndSeasonStatsBenchmarkingSelector,
    careerAndSeasonStatsDataTypeSelector,
    careerAndSeasonStatsNavSelector
} from 'store/modules/statspro/career-and-season-stats/selectors';
import {
    BenchmarkingLegend,
    BenchmarkingExplainer
} from 'common/react/components/benchmarking';
import {
    BenchmarkingSwitch,
    DataTypeToggle,
    Heading
} from './components/header';
import { useGetCompSeasonQuery } from 'common/store/modules/api/afl-api';

export const CareerAndSeasonStats = ({
    title,
    compseasonId,
    playerProviderId,
    showBenchmarkingAflw,
    hideClubLogos
}) => {
    const isAFLW = PULSE.app.common.url.checkBaseRoute('aflw');

    const { data: compData } = useGetCompSeasonQuery(
        { id: compseasonId },
        {
            skip: !compseasonId
        }
    );

    const benchmarking = useSelector(careerAndSeasonStatsBenchmarkingSelector);
    const nav = useSelector(careerAndSeasonStatsNavSelector);
    const dataType = useSelector(careerAndSeasonStatsDataTypeSelector);
    const options = useCompetitionNavigationOptions(nav);

    useCareerAndSeasonStatsNavSync(
        options,
        compData?.competition.id,
        compData?.providerId
    );

    const showBenchmarkingAFLWBool = showBenchmarkingAflw === 'true';
    const showBenchmarking = !isAFLW || (isAFLW && showBenchmarkingAFLWBool);

    const isAFLM =
        compData?.competition.providerId ===
        PULSE.app.common.CONSTANTS.PROVIDER_IDS.PREMIERSHIP;

    const renderAveragesBenchmarking =
        showBenchmarking &&
        (dataType.type === 'averages' ||
            dataType.type === 'benchmarkedAverages');

    return (
        <StatsSection.Main>
            <StatsSection.Heading title={title} />
            <StatsSection.Nav>
                <StatsSection.NavItems>
                    <CategoryNavItem />
                    <SeasonNavItem />
                </StatsSection.NavItems>
            </StatsSection.Nav>
            <StatsSection.Content>
                <StatsSection.ContentHeader>
                    <StatsSection.ContentHeaderHeading>
                        <Heading />
                    </StatsSection.ContentHeaderHeading>
                    {nav.season.id === -1 && (
                        <StatsSection.ContentHeaderOptions>
                            {renderAveragesBenchmarking && (
                                <BenchmarkingSwitch />
                            )}
                            <DataTypeToggle />
                        </StatsSection.ContentHeaderOptions>
                    )}
                    <StatsSection.ContentHeaderBelow>
                        {benchmarking?.isActive && <BenchmarkingLegend />}
                        {benchmarking?.isActive && <BenchmarkingExplainer />}
                    </StatsSection.ContentHeaderBelow>
                </StatsSection.ContentHeader>
                <StatsSection.ContentBody>
                    <CareerAndSeasonStatsTable
                        isAFLW={isAFLW}
                        isAFLM={isAFLM}
                        hideClubLogos={hideClubLogos === 'true' ? true : false}
                        playerId={playerProviderId}
                    />
                </StatsSection.ContentBody>
            </StatsSection.Content>
        </StatsSection.Main>
    );
};

CareerAndSeasonStats.propTypes = {
    title: PropTypes.string.isRequired,
    compseasonId: PropTypes.string.isRequired,
    playerProviderId: PropTypes.string.isRequired,
    showBenchmarkingAflw: PropTypes.string,
    hideClubLogos: PropTypes.string
};
