import React, { useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_NAV_ITEM } from 'store/modules/brownlow-tracker/round-by-round/actions';
import { NavItem } from './NavItem';
import { roundByRoundNavSelector } from 'store/modules/brownlow-tracker/round-by-round/selectors';
import { Loader } from 'common/react/components/Loader';

export const RoundListNavItem = () => {
    const dispatch = useDispatch();
    const nav = useSelector(roundByRoundNavSelector);
    const roundsList = useSelector(
        (state) => state.rounds[`season_${nav.season.id}`]?.list
    );

    const [isLoading, setIsLoading] = useState(true);

    // Filter to only home and away rounds (not finals)
    const filteredRoundList = useMemo(() => {
        if (roundsList) {
            setIsLoading(false);
            return roundsList.filter((round) => {
                return round.name.includes('Round');
            });
        }

        setIsLoading(true);
        return [];
    }, [roundsList]);

    const onNavItemClick = (event, value) => {
        dispatch(
            UPDATE_NAV_ITEM.request({
                navItem: 'round',
                value: value
            })
        );
    };

    return (
        <ul className="brownlow-round-by-round__round-list-nav">
            {isLoading ? (
                <div className="brownlow-round-by-round__round-loader">
                    <Loader />
                </div>
            ) : filteredRoundList.length ? (
                filteredRoundList.map((round, index) => (
                    <NavItem
                        key={index}
                        value={round.roundNumber}
                        onClick={onNavItemClick}
                    />
                ))
            ) : null}
        </ul>
    );
};
