export const KEY_STATS_CATEGORIES = {
    DEFENDER: [
        'disposals',
        'kicks',
        'handballs',
        'marks',
        'tackles',
        'metresGained',
        'dreamTeamPoints'
    ],
    MEDIUM_DEFENDER: [
        'disposals',
        'kicks',
        'handballs',
        'marks',
        'tackles',
        'metresGained',
        'dreamTeamPoints'
    ],
    MIDFIELDER: [
        'disposals',
        'kicks',
        'handballs',
        'marks',
        'tackles',
        'totalClearances',
        'dreamTeamPoints'
    ],
    MIDFIELDER_FORWARD: [
        'disposals',
        'kicks',
        'handballs',
        'marks',
        'tackles',
        'totalClearances',
        'dreamTeamPoints'
    ],
    FORWARD: [
        'disposals',
        'kicks',
        'handballs',
        'marks',
        'tackles',
        'goals',
        'dreamTeamPoints'
    ],
    MEDIUM_FORWARD: [
        'disposals',
        'kicks',
        'handballs',
        'marks',
        'tackles',
        'goals',
        'dreamTeamPoints'
    ],
    RUCK: [
        'disposals',
        'kicks',
        'handballs',
        'totalClearances',
        'hitouts',
        'hitoutsToAdvantage',
        'dreamTeamPoints'
    ],
    KEY_DEFENDER: [
        'disposals',
        'kicks',
        'handballs',
        'tackles',
        'spoils',
        'interceptMarks',
        'dreamTeamPoints'
    ],
    KEY_FORWARD: [
        'disposals',
        'kicks',
        'goals',
        'goalAccuracy',
        'marks',
        'tackles',
        'dreamTeamPoints'
    ]
};
