import React from 'react';

import { BENCHMARKING } from './config/benchmarking';
import { BenchmarkLabel } from './BenchmarkLabel';

export const BenchmarkingLegend = () => (
    <div className="benchmarking-legend">
        <span className="benchmarking-legend__label">
            <BenchmarkLabel benchmarkKey={BENCHMARKING.ELITE.KEY} />
        </span>
        <span className="benchmarking-legend__label">
            <BenchmarkLabel benchmarkKey={BENCHMARKING.ABOVE_AVERAGE.KEY} />
        </span>
        <span className="benchmarking-legend__label">
            <BenchmarkLabel benchmarkKey={BENCHMARKING.AVERAGE.KEY} />
        </span>
        <span className="benchmarking-legend__label">
            <BenchmarkLabel benchmarkKey={BENCHMARKING.BELOW_AVERAGE.KEY} />
        </span>
    </div>
);
