import { createSlice } from '@reduxjs/toolkit';

import { LeaderboardState } from './types';
import { updateNav } from './reducers/update-nav';
import { updateModal } from './reducers/update-modal';

/**
 * @type {LeaderboardState}
 */
const initialState = {
    leaderboard: {
        nav: {
            round: -1,
            club: -1,
            position: -1,
            filter: ''
        }
    },
    playerModal: {
        visible: false,
        data: null
    }
};

// RTK Query update: RTK slices can be used to update state in the Redux store (https://redux-toolkit.js.org/api/createSlice)
const draftTracker = createSlice({
    name: 'update-round',
    initialState,
    reducers: {
        updateNav,
        updateModal
    }
});

// RTK Query update: Export the slice reducer and actions
if (PULSE.app.redux) {
    PULSE.app.redux.reducers.draftTracker = {
        root: draftTracker.reducer
    };
    PULSE.app.redux.actions = {
        ...PULSE.app.redux.actions,
        draftTracker: draftTracker.actions
    };
} else {
    PULSE.app.redux = {
        actions: {
            drafttracker: draftTracker.actions
        },
        reducers: {
            root: {
                drafttracker: draftTracker.reducer
            }
        }
    };
}
