import React from 'react';
import PropTypes from 'prop-types';

const PAGE_SIZE = 1;

import { useGetRoundsQuery } from 'store/modules/api/afl-api';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { getTranslation } from 'common/react/utils/translations';

export const ByesGroup = ({ compseasonId, roundNumber }) => {
    const { byesData } = useGetRoundsQuery(
        {
            compseasonId: compseasonId,
            params: {
                roundNumber: roundNumber,
                pageSize: PAGE_SIZE
            }
        },
        {
            selectFromResult: ({ data }) => ({
                byesData: data?.[0]?.byes || []
            })
        }
    );

    const renderByes = () => {
        return byesData.map((item) => (
            <TeamIcon
                key={item.id}
                className="fixtures__bye-fixture"
                providerId={item.providerId}
            />
        ));
    };

    return (
        <>
            {byesData.length > 0 && (
                <>
                    <h2 className="fixtures__date-header">
                        {getTranslation('label.fixtures.byeRound')}
                    </h2>
                    <div className="fixtures__bye-fixtures">{renderByes()}</div>
                </>
            )}
        </>
    );
};

ByesGroup.propTypes = {
    compseasonId: PropTypes.number.isRequired,
    roundNumber: PropTypes.number.isRequired
};
