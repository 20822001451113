import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getTranslation } from 'common/react/utils/translations';

import { bettingOddsSelector } from 'store/modules/competition-nav/selectors';

import { useGetBettingOddsQuery } from 'store/modules/api/cfs-api';

export const FixtureBettingOdds = ({ match }) => {
    const bettingOdds = useSelector(bettingOddsSelector);

    const { data, isError } = useGetBettingOddsQuery({
        competitionType: PULSE.app.common.match.getCompAbbr(
            match?.compSeason?.providerId
        )
    });

    const isBettingMatch = PULSE.app.common.betting.isBettingMatch(
        match.providerId,
        data?.competition?.books
    );

    const renderOddsItem = (team) => {
        if (!team) {
            return null;
        }

        const proposition = isBettingMatch.propositions.find(
            (item) => item.clubId === team.providerId
        );

        return (
            <a
                href={proposition?.contextualUrls?.fixture || proposition?.url}
                className="fixtures__betting-odds-value"
            >
                ${proposition.h2h.toFixed(2)}
            </a>
        );
    };

    useEffect(() => {
        // Loads gambling message above footer if odds are displayed
        if (bettingOdds.isActive && isBettingMatch) {
            PULSE.app.common.betting.cgmDisplay();
        } else {
            PULSE.app.common.betting.cgmHide();
        }
    }, [bettingOdds.isActive, isBettingMatch]);

    // render empty state

    if (
        !bettingOdds.isActive || // Filter unchecked
        !data?.competition?.books || // Wagering books empty
        isError || // Wagering Api error
        !isBettingMatch // No book found for match
    ) {
        return <></>;
    }

    return (
        <>
            <div className="fixtures__betting-odds-value-wrapper">
                {renderOddsItem(match.home?.team)}
            </div>
            <img
                className="fixtures__sportsbet-logo"
                src={`/resources/${window.RESOURCES_VERSION}/i/elements/sports-bet-on-white.png`}
                alt={getTranslation('label.sportsbet.logo')}
            />
            <div className="fixtures__betting-odds-value-wrapper">
                {renderOddsItem(match.away?.team)}
            </div>
        </>
    );
};

FixtureBettingOdds.propTypes = {
    match: PropTypes.object.isRequired
};
