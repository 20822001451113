import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param props.children
 * @returns {JSX.Element} Component.
 */
export const StatsSectionRoundButtons = ({ children }) => {
    return (
        <>
            {React.Children.map(children, (child) => (
                <div
                    className={`stats-leaders-nav__item-wrapper ${
                        child.props.classes ? child.props.classes : ''
                    }`}
                >
                    {child}
                </div>
            ))}
        </>
    );
};

StatsSectionRoundButtons.defaultProps = {
    name: 'StatsSectionRoundButtons'
};

StatsSectionRoundButtons.propTypes = {
    children: PropTypes.any.isRequired
};
