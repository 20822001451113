import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { STATS_COLUMNS } from 'widgets/statspro/js/components/common/config/columns';
import {
    statsLeadersNavSelector,
    seasonSelector,
    roundSelector
} from 'store/modules/statspro/stats-leaders/selectors';
import { useGetCompetitionsQuery } from 'store/modules/api/afl-api';

/**
 * @param {object} props - Props.
 * @param {string} props.sortedColumnId - The column ID.
 * @param {'ascending'|'descending'} props.sortDirection - The sort direction.
 * @returns {JSX.Element} Component.
 */
export const StatsLeadersTableCaption = ({ sortedColumnId, sortDirection }) => {
    const nav = useSelector(statsLeadersNavSelector);
    const { data: competitions } = useGetCompetitionsQuery();
    const competition = competitions.find(
        (comp) => comp.id.toString() === nav.competition.id.toString()
    );

    const season = useSelector(seasonSelector);
    const round = useSelector(roundSelector);
    const sortedColumnName = STATS_COLUMNS[sortedColumnId]?.name;

    return (
        <caption className="u-screen-reader">
            {competition.name} players ranked by {sortedColumnName} (
            {sortDirection})
            {round
                ? ` for ${round.name} of the ${season.name} season.`
                : ` for the ${season.name} season.`}
        </caption>
    );
};

StatsLeadersTableCaption.propTypes = {
    sortedColumnId: PropTypes.string.isRequired,
    sortDirection: PropTypes.oneOf(['ascending', 'descending'])
};
