import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetCompetitionsQuery } from 'store/modules/api/afl-api';

/**
 * @param {object} arg
 * @param {object} arg.competition
 * @param {object} arg.competition.id
 * @param {object} arg.season
 * @param {number|null} arg.season.id
 * @returns {{
 *    competitions: object,
 *    seasons: object,
 *    rounds: object,
 *    teams: object
 * }}
 */
export const useCompetitionNavigationOptions = ({ competition, season }) => {
    const dispatch = useDispatch();

    // Get comps from RTK
    const { data: competitions } = useGetCompetitionsQuery();

    // @ts-ignore
    const seasons = useSelector(
        (state) => state.seasons[`competition_${competition.id}`]?.list
    );

    // @ts-ignore
    const rounds = useSelector(
        (state) => state.rounds[`season_${season.id}`]?.list
    );

    /**
     * Fetch season data if missing.
     */
    useEffect(() => {
        if (competitions && competition.id && !seasons) {
            dispatch(
                // @ts-expect-error
                window.PULSE.app.redux.actions.seasons.API__FETCH_SEASONS.request(
                    competition.id
                )
            );
        }
    }, [competitions, competition, seasons, dispatch]);

    /**
     * Fetch round data if missing.
     */
    useEffect(() => {
        if (seasons && season.id && !rounds) {
            dispatch(
                // @ts-expect-error
                window.PULSE.app.redux.actions.rounds.API__FETCH_ROUNDS.request(
                    season.id
                )
            );
        }
    }, [seasons, season, rounds, dispatch]);

    return { competitions, seasons, rounds };
};
