import React from 'react';
import PropTypes from 'prop-types';
import { TeamAnnouncementsTooltip } from './TeamAnnouncementsTooltip';

/**
 * Renders the late changes data.
 *
 * @param {object} props - The props
 * @param {string} props.homeLateChanges - The home late changes data.
 * @param {string} props.awayLateChanges - The away late changes data.
 * @param {string} props.matchStatus - The matches status
 * @param {string} props.teamDisplay - Which team to display (both, home or away).
 * @returns {React.JSX.Element} - The rendered component
 */
export const TeamAnnouncementsFieldLateChanges = ({
    homeLateChanges,
    awayLateChanges,
    matchStatus,
    teamDisplay
}) => {
    const displayStatusMet =
        PULSE.app.common.CONSTANTS.DISPLAY_MATCH_STATUSES.includes(matchStatus);

    const lateChangesArray =
        teamDisplay === 'home'
            ? homeLateChanges
            : teamDisplay === 'away'
            ? awayLateChanges
            : null;

    return (
        displayStatusMet && (
            <div className="team-announcements-field__footer-section team-announcements-field__footer-section--late-changes">
                {(teamDisplay === 'home' || teamDisplay === 'away') && (
                    <span className="team-announcements-field__footer-section-label">
                        {PULSE.I18N.lookup(
                            'label.teamlineups.lateChanges.short'
                        )}
                        <TeamAnnouncementsTooltip />
                    </span>
                )}

                {(teamDisplay === 'home' || teamDisplay === 'away') && (
                    <>
                        {lateChangesArray && displayStatusMet ? (
                            <>
                                <span className="team-announcements-field__footer-section-values">
                                    {lateChangesArray}
                                </span>
                            </>
                        ) : (
                            <>
                                <span className="team-announcements-field__footer-section-values">
                                    {PULSE.I18N.lookup(
                                        'label.teamlineups.noLateChanges'
                                    )}
                                </span>
                            </>
                        )}
                    </>
                )}

                {teamDisplay === 'both' && (
                    <>
                        <span className="team-announcements-field__footer-section-values team-announcements-field__footer-section-values--home">
                            {homeLateChanges && displayStatusMet
                                ? homeLateChanges
                                : PULSE.I18N.lookup(
                                      'label.teamlineups.noLateChanges'
                                  )}
                        </span>

                        <span className="team-announcements-field__footer-section-label">
                            {PULSE.I18N.lookup(
                                'label.teamlineups.lateChanges.short'
                            )}
                            <TeamAnnouncementsTooltip />
                        </span>

                        <span className="team-announcements-field__footer-section-values team-announcements-field__footer-section-values--away">
                            {awayLateChanges && displayStatusMet
                                ? awayLateChanges
                                : PULSE.I18N.lookup(
                                      'label.teamlineups.noLateChanges'
                                  )}
                        </span>
                    </>
                )}
            </div>
        )
    );
};

TeamAnnouncementsFieldLateChanges.propTypes = {
    homeLateChanges: PropTypes.string,
    awayLateChanges: PropTypes.string,
    matchStatus: PropTypes.string,
    teamDisplay: PropTypes.oneOf(['both', 'home', 'away'])
};
