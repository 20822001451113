import React from 'react';
import PropTypes from 'prop-types';

import { LazyImage } from 'common/react/components/LazyImage';
import { getTranslation } from 'common/react/utils/translations';
import { useGetContentItemQuery } from 'common/store/modules/api/content-api';

export const InformationCardSponsor = ({ sponsorItem }) => {
    const sponsor = useGetContentItemQuery({
        type: sponsorItem.contentType,
        id: sponsorItem.reference
    });
    const imageURL = `${sponsor.currentData?.promoItem?.onDemandUrl}?width=500`;

    return (
        <section className="interactive-event-map__location-sponsor interactive-event-map__section">
            <p className="interactive-event-map__location-sponsor-text">
                {getTranslation('label.sponsor-strip-intro')}
            </p>
            {sponsor.currentData?.promoItem ? (
                <LazyImage
                    url={imageURL}
                    useImg={true}
                    objectFit={false}
                    cssClass="interactive-event-map__location-sponsor-img"
                />
            ) : null}
        </section>
    );
};

InformationCardSponsor.propTypes = {
    sponsorItem: PropTypes.object
};
