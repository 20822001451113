import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { OverviewComparison } from '../components/overview-comparison';

export const OverviewComparisonProvider = ({ store, data }) => (
    <Provider store={store}>
        <OverviewComparison data={data} />
    </Provider>
);

OverviewComparisonProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
};
