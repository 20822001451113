import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UPDATE_NAV_ITEM } from 'store/modules/statspro/performance-chart/actions';
import { performanceChartNavSelector } from 'store/modules/statspro/performance-chart/selectors';

export const usePerformanceChartNavSync = (
    { competitions, seasons, rounds },
    competitionId
) => {
    const dispatch = useDispatch();
    const nav = useSelector(performanceChartNavSelector);

    /**
     * Set initial nav values
     */
    useEffect(() => {
        if (competitionId && !nav.competition.id) {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'competition',
                    value: { id: competitionId }
                })
            );
        }

        if (rounds && nav.round.id === null) {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'round',
                    value: {
                        id: -1,
                        number: 0
                    }
                })
            );
        }
    }, [competitions, seasons, rounds, competitionId]);

    useEffect(() => {
        if (
            !rounds ||
            !seasons ||
            nav.round.id === null ||
            nav.round.id === -1
        ) {
            return;
        }

        // If rounds is empty, reset to Entire season
        if (!rounds?.length) {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'round',
                    value: {
                        id: -1,
                        number: 0
                    }
                })
            );
        } else if (!rounds.find((r) => r.id === nav.round.id)) {
            const targetRound =
                rounds.find((r) => r.roundNumber === nav.round.number) ??
                rounds[0];

            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'round',
                    value: {
                        id: targetRound.id,
                        number: targetRound.roundNumber
                    }
                })
            );
        }
    }, [nav.round, rounds, seasons]);
};
