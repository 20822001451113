import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { SwitchInput } from '../../SwitchInput';
import { getTranslation } from '../../../utils/translations';

import { bettingOddsSelector } from 'store/modules/competition-nav/selectors';
import { UPDATE_BETTING_ODDS } from 'store/modules/competition-nav/actions';
import { useGetBettingOddsQuery } from 'store/modules/api/cfs-api';

/**
 * Renders a toggle item to show betting odds, will check if we have odds to
 * show before rendering. Checks for a match book in a round if passed a round,
 * otherwise check the season matches.
 *
 * @param {object} param0 An object containing the props on this react component
 * @param {string} param0.compSeasonPid - The providerId of the
 * compSeason we want to show a betting toggle for
 * @param {number|string|undefined} param0.roundNumber The round number to check
 * for a book for, if we don't pass one, assume we're displaying the premiership
 * winner odds instead
 * @param param0.cssClass
 * @returns {React.ReactElement} - The toggle element
 */
export const BettingOddsNavItem = ({
    compSeasonPid,
    roundNumber,
    cssClass
}) => {
    const dispatch = useDispatch();

    const bettingOdds = useSelector(bettingOddsSelector);

    const { data: bettingData } = useGetBettingOddsQuery({
        competitionType: PULSE.app.common.match.getCompAbbr(compSeasonPid)
    });

    let activeRounds = [];
    activeRounds = [
        ...new Set(
            bettingData?.competition?.books?.map((item) => parseInt(item.round))
        )
    ];

    // Check for a book for Premiership Winner and that the comp id matches
    const hasPremiershipWinBook =
        bettingData?.competition?.books.find((book) => {
            return book.name.includes('Premiership Winner');
        }) && bettingData?.competition?.seasonId === compSeasonPid;

    if (
        // If there's a round number, make sure we have a book for that round
        (!isNaN(roundNumber) &&
            bettingData?.competition?.seasonId === compSeasonPid &&
            activeRounds.includes(roundNumber)) ||
        // If not, assume we're looking for a prem winner and check the seasonId matches
        (isNaN(roundNumber) && hasPremiershipWinBook)
    ) {
        return (
            <div className={`competition-nav__betting-odds-toggle ${cssClass}`}>
                <img
                    className="icon"
                    src={`/resources/${window.RESOURCES_VERSION}/i/elements/sports-bet-mini.png`}
                    alt={getTranslation('label.sportsbet.logo')}
                />

                <SwitchInput
                    id="brownlow-tracker-betting-odds-toggle"
                    label={getTranslation('label.brownlow.bettingOdds.toggle')}
                    isChecked={bettingOdds.isActive}
                    onChange={(isActive) => {
                        PULSE.app.common.betting.cgmHide();
                        dispatch(UPDATE_BETTING_ODDS.request({ isActive }));
                    }}
                />
            </div>
        );
    }

    return null;
};

BettingOddsNavItem.propTypes = {
    compSeasonPid: PropTypes.string.isRequired,
    roundNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cssClass: PropTypes.string
};
