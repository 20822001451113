/**
 * @typedef {object} Payload
 * @property {string} navItem
 * @property {string|number|object} value
 */

/**
 * @param {any} nav - Nav state.
 * @param {Payload} payload - Action payload.
 */

export const setNavItem = (nav, payload) => {
    const { navItem, value } = payload;

    if (nav[navItem] === null) {
        nav[navItem] = value;
        return;
    }

    if (typeof nav[navItem] === 'object') {
        nav[navItem] = {
            ...nav[navItem],
            ...value
        };
        return;
    }

    nav[navItem] = value;
};
