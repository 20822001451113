import React from 'react';
import PropTypes from 'prop-types';

export const ModalNote = ({ text, isEmbedded }) => {
    return (
        <div
            className={`modal-note ${
                isEmbedded ? 'modal-note--is-embedded' : ''
            }`}
        >
            {text ? <p className="modal-note__text">{text}</p> : null}
        </div>
    );
};

ModalNote.propTypes = {
    text: PropTypes.string.isRequired,
    isEmbedded: PropTypes.bool
};
