import React from 'react';
import PropTypes from 'prop-types';

export const ImageElement = ({ ImageUrl, ImageTitle }) => {
    return (
        <picture className="object-fit-cover-picture">
            <source
                className=""
                src={`${ImageUrl}?width=50&amp;height=28, ${ImageUrl}?width=100&amp;height=56 2x, ${ImageUrl}?width=150&amp;height=84 3x`}
                media="(min-width: 50px)"
            />
            <img
                className="js-faded-image fade-in-on-load object-fit-cover-picture__img is-loaded"
                src={`${ImageUrl}?width=50&amp;height=28`}
                alt={`${ImageTitle}`}
            />
        </picture>
    );
};

ImageElement.propTypes = {
    ImageUrl: PropTypes.string,
    ImageTitle: PropTypes.string
};
