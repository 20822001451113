import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { ProspectCareerAndSeasonStats } from '../components/career-and-season-stats/ProspectCareerAndSeasonStats';

export const ProspectCareerAndSeasonStatsProvider = ({ store, data }) => (
    <Provider store={store}>
        <ProspectCareerAndSeasonStats {...data} />
    </Provider>
);

ProspectCareerAndSeasonStatsProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
