import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { configureReduxQuerySync } from 'common/react/components/competition-nav/utils/configure-redux-query-sync';
import { Ladder } from '../components/Ladder';

export const LadderProvider = ({ store, data }) => {
    useEffect(() => {
        configureReduxQuerySync(store);
    }, [store]);

    return (
        <Provider store={store}>
            <Ladder {...data} />
        </Provider>
    );
};

export default LadderProvider;

LadderProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
