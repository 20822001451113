import { Navigation, A11y } from 'swiper';

export const sliderSettings = (name) => {
    return {
        modules: [Navigation, A11y],
        slidesPerView: 1.15,
        navigation: {
            nextEl: '.swiper-button-next-unique-' + name,
            prevEl: '.swiper-button-prev-unique-' + name
        }
    };
};

export const BREAKPOINTS_FOR_FOUR_SLIDES = {
    breakpoints: {
        640: {
            slidesPerView: 2.16,
            slidesPerGroup: 2
        },
        840: {
            slidesPerView: 2,
            slidesPerGroup: 2
        },
        1024: {
            slidesPerView: 3,
            slidesPerGroup: 3
        },
        1300: {
            slidesPerView: 4,
            slidesPerGroup: 4
        }
    }
};

export const BREAKPOINTS_FOR_THREE_SLIDES = {
    breakpoints: {
        640: {
            slidesPerView: 2.16,
            slidesPerGroup: 2
        },
        840: {
            slidesPerView: 2,
            slidesPerGroup: 2
        },
        1300: {
            slidesPerView: 3,
            slidesPerGroup: 3
        }
    }
};
