import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';

import { drafTrackerModalSelector } from 'common/store/modules/draft-tracker/leaderboard/selectors/player-modal-selector';
import { PlayerModalHeader } from './PlayerModalHeader';
import { PlayerModalDetails } from './PlayerModalDetails';
import { ProspectCareerAndSeasonStats } from 'widgets/draft/js/components/career-and-season-stats';
const selectedPlayers = [];

export const PlayerModal = ({ accountId, policyKey, playerId, anchorName }) => {
    const dispatch = useDispatch();
    const modal = useSelector(drafTrackerModalSelector);
    const player = modal?.data?.player;
    const isVisible = modal.visible;
    const [playerPulseId, setPlayerPulseId] = useState();
    const [playerData, setPlayerData] = useState(null);

    /**
     * The below state is needed to give us time to dispose of the
     * video player when the modal closes, as the redux state
     * closes the modal too quickly for us to react to it.
     */
    const [isModalOpen, setIsModalOpen] = useState(isVisible);

    useEffect(() => {
        setIsModalOpen(isVisible);

        // Get player data if modal is opened
        if (isVisible) {
            selectedPlayers.push(player);
            setPlayerData(selectedPlayers.pop());
        }

        PULSE.app.common.pulseId
            .getPlayerPulseId(modal?.data?.playerId)
            .then((res) => setPlayerPulseId(res));

        if (isVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style['overflow-y'] = 'auto';
        }
        
        if (!isVisible && playerData) {
            PULSE.app.tracking.recordEvent(
                PULSE.app.common.CONSTANTS.TRACKING.EVENTS.MODAL.CLOSE,
                {
                    eventData: {
                        /* eslint-disable-next-line camelcase */
                        modal_name: `${playerData?.fullName} draft tracker modal`
                    }
                }
            );
        }
    }, [isVisible]);

    const onOverlayClick = useCallback((event) => {
        if (event.target === event.currentTarget) {
            dispatch(
                PULSE.app.redux.actions.draftTracker.updateModal({
                    visible: false,
                    data: null
                })
            );
        }
    }, []);

    return isModalOpen && modal ? (
        <FocusTrap>
            <div className="modal-centered" onClick={onOverlayClick}>
                <div className="modal-centered__modal-wrapper">
                    <PlayerModalHeader
                        playerName={player?.fullName}
                        photoUrl={player?.photoUrl}
                        profileUrl={player?.profileUrl}
                        pickOrder={modal?.data?.pick}
                        draftedBy={modal?.data?.club}
                        roundPicked={modal?.data?.roundPicked}
                    />

                    <PlayerModalDetails
                        key={isModalOpen}
                        pulsePlayerId={playerPulseId}
                        playerDetails={modal?.data}
                        accountId={accountId}
                        policyKey={policyKey}
                        playerId={playerId}
                        anchorName={anchorName}
                        isModalOpen={isModalOpen}
                    />

                    <section className="modal-centered__draft-player-stats-table">
                        <ProspectCareerAndSeasonStats
                            hideClubLogos={'false'}
                            playerIdData={modal?.data?.playerId}
                            isProspectModal={true}
                        />
                    </section>
                </div>
            </div>
        </FocusTrap>
    ) : null;
};

PlayerModal.propTypes = {
    accountId: PropTypes.string.isRequired,
    policyKey: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
    anchorName: PropTypes.string
};
