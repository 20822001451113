export const STATS_CATEGORY = {
    BASIC: 'Basic',
    ADVANCED: 'Advanced',
    MY_STATS: 'My Stats'
};

export const STATS_CATEGORY_ARRAY = [
    STATS_CATEGORY.BASIC,
    STATS_CATEGORY.ADVANCED,
    STATS_CATEGORY.MY_STATS
];

export const STATS_CATEGORY_LABELS = {
    Basic: 'disposals',
    Advanced: 'advanced',
    'My Stats': 'myStats'
};
