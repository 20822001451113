import React from 'react';
import PropTypes from 'prop-types';

import { MatchCard } from './match-card';
import { useGetMatchItemQuery } from 'store/modules/api/cfs-api';

export const MatchCardScoreFetcher = ({
    voteData,
    totalVotes,
    seasonPid,
    seasonId,
    modifier,
    isVisible,
    linkTo,
    playerModalEnabled
}) => {
    const { data: matchData } = useGetMatchItemQuery(voteData.matchId, {
        skip: !isVisible
    });

    return (
        <MatchCard
            voteData={voteData}
            matchData={matchData}
            totalVotes={totalVotes}
            seasonPid={seasonPid}
            seasonId={seasonId}
            modifier={modifier}
            linkTo={linkTo}
            playerModalEnabled={playerModalEnabled}
        />
    );
};

MatchCardScoreFetcher.propTypes = {
    voteData: PropTypes.object,
    totalVotes: PropTypes.object,
    seasonPid: PropTypes.string,
    seasonId: PropTypes.string,
    modifier: PropTypes.string,
    isVisible: PropTypes.bool.isRequired,
    linkTo: PropTypes.string,
    playerModalEnabled: PropTypes.bool
};
