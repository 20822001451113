/**
 * @param {object} item - Item..
 * @param {object[]} columns - Column data.
 * @param {'totals'|'averages'|'benchmarkedTotals'|'benchmarkedAverages'} dataType - Data type.
 * @param {boolean} noPlayerData True is there is only averages data.
 * @param {string} homeAbbr Home team abbreviation.
 * @param {string} awayAbbr Away team abbreviation.
 * @param {string} homePid Home team provider ID.
 * @returns {object[]} Row data for the given player.
 */
export const composeRowData = (
    item,
    columns,
    dataType,
    noPlayerData,
    homeAbbr,
    awayAbbr,
    homePid
) => {
    if (dataType === 'totals' && !noPlayerData) {
        return {
            jumperNumber: {
                jumperNumber: item.player.jumperNumber,
                givenName: item.player.player.player.playerName.givenName,
                surname: item.player.player.player.playerName.surname,
                photoURL: item.player.photoURL,
                teamAbbr: (item.teamId === homePid
                    ? homeAbbr
                    : awayAbbr
                )?.toLowerCase(),
                teamLocation: item.teamId === homePid ? 'home' : 'away',
                teamId: item.teamId
            },
            player: {
                givenName: item.player.player.player.playerName.givenName,
                surname: item.player.player.player.playerName.surname,
                playerId: item.player.player.player.playerId
            },
            ...columns.reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr.accessor]:
                        item.playerStats.stats?.[curr.accessor] ||
                        item.playerStats.stats?.clearances?.[curr.accessor] ||
                        item.playerStats.stats?.extendedStats?.[
                            curr.accessor
                        ] ||
                        (curr.accessor === 'gamesPlayed'
                            ? 1
                            : item.playerStats?.[curr.accessor])
                }),
                {}
            )
        };
    }

    return {
        jumperNumber: {
            jumperNumber: item.player.jumper,
            givenName: item.player.givenName,
            surname: item.player.surname,
            photoURL: item.player.photoURL,
            teamAbbr: (item.team?.teamId === homePid
                ? homeAbbr
                : awayAbbr
            )?.toLowerCase(),
            teamLocation: item.team?.teamId === homePid ? 'home' : 'away',
            teamId: item.team.teamId
        },
        player: {
            givenName: item.player.givenName,
            surname: item.player.surname,
            playerId: item.player.playerId
        },
        ...columns.reduce(
            (prev, curr) => ({
                ...prev,
                [curr.accessor]:
                    item.stats?.averages[curr.accessor] ||
                    item.stats?.averages.clearances?.[curr.accessor] ||
                    item.stats?.averages.extendedStats?.[curr.accessor] ||
                    item.stats?.[curr.accessor]
            }),
            {}
        )
    };
};
