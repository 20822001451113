export const getEventTagProperties = (tag) => {
    return EVENT_TAG_PROPERTIES.find((item) => item.name === tag);
};

const EVENT_TAG_PROPERTIES = [
    {
        name: 'Appearances',
        colourVar: '--color-brand-gather-round-golden-rays',
        icon: 'appearances'
    },
    {
        name: 'Matches',
        colourVar: '--color-brand-gather-round-golden-rays',
        icon: 'matches'
    },
    {
        name: 'Music',
        colourVar: '--color-brand-gather-round-sparkling-shiraz',
        icon: 'live-music'
    },
    {
        name: 'Live Music',
        colourVar: '--color-brand-gather-round-sparkling-shiraz',
        icon: 'live-music'
    },
    {
        name: 'Food',
        colourVar: '--color-brand-gather-round-outback-orange',
        icon: 'food'
    },
    {
        name: 'Drinks',
        colourVar: '--color-brand-gather-round-outback-orange',
        icon: 'drinks'
    },
    {
        name: 'Seating',
        colourVar: '--color-purple-primary',
        icon: 'seating'
    },
    {
        name: 'Big Screens',
        colourVar: '--color-purple-primary',
        icon: 'big-screens'
    },
    {
        name: 'Toilets',
        colourVar: '--color-purple-primary',
        icon: 'toilets'
    },
    {
        name: 'Activations',
        colourVar: '--color-brand-gather-round-sparkling-shiraz',
        icon: 'activations'
    },
    {
        name: 'Misc',
        colourVar: '--color-brand-gather-round-sparkling-shiraz',
        icon: 'misc'
    },
    {
        name: 'Transport',
        colourVar: '--color-brand-gather-round-matchday-blue',
        icon: 'transport'
    },
    {
        name: 'First Aid',
        colourVar: '--color-brand-gather-round-forest-green',
        icon: 'first-aid'
    },
    {
        name: 'Merchandise',
        colourVar: '--color-brand-gather-round-peninsula-green',
        icon: 'merchandise'
    },
    {
        name: 'Other',
        colourVar: '--color-brand-gather-round-horizon-blue',
        icon: 'other'
    },
    {
        name: 'Clinics',
        colourVar: '--color-brand-gather-round-green-hills',
        icon: 'clinics'
    },
    {
        name: 'Free Entry',
        colourVar: '--color-brand-gather-round-green-hills',
        icon: 'free-entry'
    },
    {
        name: 'Coffee',
        colourVar: '--color-brand-gather-round-outback-orange',
        icon: 'coffee'
    },
    {
        name: 'Hospitality',
        colourVar: '--color-brand-gather-round-sparkling-shiraz',
        icon: 'hospitality'
    },
    {
        name: 'Information',
        colourVar: '--color-brand-gather-round-matchday-blue',
        icon: 'information'
    },
    {
        name: 'Entry',
        colourVar: '--color-brand-gather-round-forest-green',
        icon: 'entry'
    },
    {
        name: 'Exit',
        colourVar: '--color-brand-gather-round-forest-green',
        icon: 'exit'
    },
    {
        name: 'Ticket Sales',
        colourVar: '--color-brand-gather-round-peninsula-green',
        icon: 'ticket-sales'
    },
    {
        name: 'Water Fountain',
        colourVar: '--color-brand-gather-round-matchday-blue',
        icon: 'water-fountain'
    },
    {
        name: 'Parking',
        colourVar: '--color-brand-gather-round-matchday-blue',
        icon: 'parking'
    },
    {
        name: 'Events',
        colourVar: '--color-brand-gather-round-sparkling-shiraz',
        icon: 'events'
    }
];
