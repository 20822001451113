/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param {string} props.photoURL - Player photo URL.
 * @param {string} props.photoName - Player name.
 * @returns {JSX.Element} Component.
 */
export const PlayerImage = ({ photoURL, photoName }) => {
    const migratedPhotoURL =
        PULSE.app.common.image.migratePlayerPhotoURL(photoURL);

    const onLoaded = (event) => {
        event.target.parentNode.classList.remove('has-placeholder');
    };

    const onErrored = (event) => {
        event.target.parentNode.classList.add('has-placeholder');
    };

    return (
        <div className="mc-player-stats-table__headshot-container has-placeholder">
            <img
                className="mc-player-stats-table__headshot"
                src={migratedPhotoURL}
                onLoad={(event) => onLoaded(event)}
                onError={(event) => onErrored(event)}
                alt={`${photoName} profile picture`}
            />
        </div>
    );
};

PlayerImage.propTypes = {
    photoURL: PropTypes.string.isRequired,
    photoName: PropTypes.string
};
