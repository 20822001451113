import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { LazyImage } from 'common/react/components/LazyImage';

/**
 * Renders the MediaThumbnail for each club news article.
 *
 * @param {string} title - The widget title.
 * @param {string} clubPid - The club Provider ID, e.g.'CD_T70'.
 * @param {string} clubAbbr - The club abbreviation, e.g.'geel'.
 * @param {object} article - The club news article data.
 * @param {string} baseUrl - The root URL to the news articles, e.g. 'https://dev.aflc-geel.pulselive.com/news'.
 * @returns {JSX.Element} Component.
 */
export const MediaThumbnail = ({
    title,
    clubPid,
    clubAbbr,
    article,
    baseUrl
}) => {
    const tagLabel = article.tags
        .find((item) => item.label.includes('tag:'))
        ?.label.replace('tag:', '');

    return (
        <article className={`media-thumbnail`}>
            <div className={`media-thumbnail__link`}>
                <a
                    className={`media-thumbnail__absolute-link`}
                    href={`${baseUrl}/${article?.id}/${article?.titleUrlSegment}`}
                    target={`_blank`}
                    title={article?.title}
                    aria-label={article?.title}
                    onClick={() => {
                        /* eslint-disable camelcase */
                        PULSE.app.tracking.recordEvent('select_link', {
                            link_text: `${article?.title}`,
                            link_url: `${baseUrl}/${article?.id}/${article?.titleUrlSegment}`,
                            outbound: true,
                            component_name: title
                        });
                        /* eslint-enable camelcase */
                    }}
                ></a>
                <header
                    className={`media-thumbnail__image-container placeholder placeholder--small placeholder--dark`}
                >
                    <div
                        className={`js-lazy-load lazy-image-wrapper is-loaded`}
                    >
                        <LazyImage
                            url={article?.imageUrl}
                            cssClass="js-faded-image fade-in-on-load object-fit-cover-picture__img is-loaded"
                        />
                    </div>
                </header>
                <div className={`media-thumbnail__caption`}>
                    <h3 className={`media-thumbnail__title`}>
                        {article?.title}
                    </h3>
                    <p className={`media-thumbnail__description`}>
                        {article?.description}
                    </p>
                    <div className={`media-thumbnail__footer`}>
                        <div className={`media-thumbnail__reference`}>
                            <SvgIcon
                                icon={`aflc-${clubAbbr}`}
                                className={`media-thumbnail__team-logo`}
                            />
                            <span className={`media-thumbnail__team-label`}>
                                {PULSE.app.common.CONSTANTS.TEAM_ABBR[clubPid]}
                            </span>
                            {tagLabel ? (
                                <>
                                    <SvgIcon
                                        icon={`chevron-right`}
                                        className={`icon media-thumbnail__chevron`}
                                    />
                                    <div
                                        className={`media-thumbnail__tag-label media-thumbnail__tag-label--capitalize`}
                                    >
                                        {tagLabel}
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </article>
    );
};

MediaThumbnail.propTypes = {
    title: PropTypes.string,
    clubAbbr: PropTypes.string,
    clubPid: PropTypes.string,
    article: PropTypes.object,
    baseUrl: PropTypes.string
};
