import React from 'react';
import PropTypes from 'prop-types';
import { getTranslation } from 'common/react/utils/translations';

export const HeaderCellPosition = ({ column }) => {
    return (
        <th
            className="stats-table__header-cell stats-table__header-cell--position"
            scope="col"
        >
            <button className="stats-table__header-cell-button" disabled={true}>
                <span className="stats-table__header-cell-position-label">
                    {column.render('Header')}
                </span>
                <span className="stats-table__header-cell-club-label">
                    {getTranslation(`label.ladder.club`)}
                </span>
            </button>
        </th>
    );
};

HeaderCellPosition.propTypes = {
    column: PropTypes.object,
    keyProp: PropTypes.string
};
