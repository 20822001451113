import React from 'react';
import PropTypes from 'prop-types';

import { ImageElement } from 'common/react/components/ImageElement';
import { SponsorWrapper } from './SponsorWrapper';

export const Sponsor = ({ promo }) => {
    const link = promo?.links?.[0];

    return (
        <SponsorWrapper link={link}>
            <ImageElement
                url={`${promo.promoItem.onDemandUrl}?height=64`}
                title={promo.title}
                cssClass={'trade-radio__sponsor-image'}
                width={promo.promoItem?.originalDetails?.width}
                height={promo.promoItem?.originalDetails?.height}
            ></ImageElement>
        </SponsorWrapper>
    );
};

Sponsor.propTypes = {
    promo: PropTypes.object.isRequired
};
