import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import classnames from 'classnames';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

export const Modal = ({ icon, title, isVisible, hideModal, children }) => {
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === PULSE.app.common.CONSTANTS.KEY_CODES.ESCAPE) {
                hideModal();
            }
        };
        window.addEventListener('keydown', handleEsc);

        document.body.style.overflowY = 'hidden';

        return () => {
            window.removeEventListener('keydown', handleEsc);
            document.body.style.overflowY = 'auto';
        };
    }, []);

    return (
        <>
            <div
                className={classnames('stats-glossary__overlay', {
                    'is-open': isVisible
                })}
            ></div>
            <FocusTrap>
                <div
                    className={classnames('stats-glossary__modal', {
                        'is-open': isVisible
                    })}
                >
                    <div className="stats-glossary__modal-header">
                        <div className="stats-glossary__modal-title-wrapper">
                            <SvgIcon
                                icon={icon}
                                className="stats-glossary__modal-header-icon"
                            />
                            <h2 className="stats-glossary__modal-header-title">
                                {title}
                            </h2>
                        </div>
                        <button
                            data-modal-action="close"
                            className="stats-glossary__modal-close"
                            onClick={hideModal}
                        >
                            <SvgIcon
                                icon="close-hover"
                                className="stats-glossary__modal-close-icon"
                            />
                            <span className="u-screen-reader">
                                {getTranslation('label.modalClose')}
                            </span>
                        </button>
                    </div>
                    <div className="stats-glossary__modal-content">
                        {children}
                    </div>
                </div>
            </FocusTrap>
        </>
    );
};

Modal.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};
