import React from 'react';
import PropTypes from 'prop-types';
import { TeamIcon } from 'common/react/components/TeamIcon';

export const BodyCellNextOpponent = ({ cell, cellProps }) => {
    const teamAbbr = cell?.value?.abbreviation || '';
    const teamId = cell?.value?.providerId || false;

    return (
        <td
            {...cellProps}
            className="stats-table__cell stats-table__cell--next-opponent"
        >
            <div className="stats-table__team">
                {teamId && (
                    <TeamIcon
                        className={'icon stats-table__team-icon'}
                        providerId={teamId}
                    />
                )}
                {teamAbbr && <span>{teamAbbr}</span>}
            </div>
        </td>
    );
};

BodyCellNextOpponent.propTypes = {
    cell: PropTypes.object,
    cellProps: PropTypes.object
};
