// eslint-disable-next-line no-unused-vars
import { LeaderboardState } from '../types';
// eslint-disable-next-line no-unused-vars
import { ReduxState } from '../../../../types';

/**
 * @param {ReduxState} state - The Redux state.
 * @returns {LeaderboardState} The Brownlow Tracker State
 */
export const leaderboardSelector = (state) => state.brownlowTracker.leaderboard;

/**
 * @param {ReduxState} state - The Redux state.
 * @returns {LeaderboardState} The Brownlow Tracker State
 */
export const leaderboardNavSelector = (state) =>
    state.brownlowTracker.leaderboard.nav;
