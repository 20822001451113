import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { MatchesSlider } from './MatchesSlider';
import { useResetSelection } from './hooks';
import { HeaderButton } from './HeaderButton';
import { UPDATE_ITEM } from 'store/modules/video-hub/view-by-match/actions';
import { viewByMatchSelector } from 'store/modules/video-hub/view-by-match/selectors';
import { useGetCurrentCompFilters } from 'common/react/components/competition-nav/hooks/use-get-current-comp-filters';

export const VideoHubViewByMatch = ({ title, anchorName }) => {
    const dispatch = useDispatch();
    const activeIndex = useSelector(viewByMatchSelector)?.activeIndex;
    const buttonClickHandler = () => {
        // reset active button index
        dispatch(
            UPDATE_ITEM.request({
                item: 'activeIndex',
                value: -1
            })
        );
    };

    useResetSelection();

    const { competitionId, compSeason, round } = useGetCurrentCompFilters();

    return (
        <div className="video-hub-view-by-match">
            <div className="wrapper mb-0">
                <header className="widget-header">
                    <div className="widget-header__content">
                        <div className="widget-header__title-link">
                            <h2 className="widget-header__title">{title}</h2>
                            <HeaderButton
                                activeIndex={activeIndex}
                                buttonClickHandler={buttonClickHandler}
                            />
                        </div>
                    </div>
                </header>
                {competitionId &&
                compSeason?.id &&
                !isNaN(round?.roundNumber) ? (
                    <MatchesSlider
                        anchorName={anchorName}
                        competitionId={competitionId}
                        compSeason={compSeason}
                        round={round}
                    />
                ) : null}
            </div>
        </div>
    );
};

VideoHubViewByMatch.propTypes = {
    title: PropTypes.string,
    anchorName: PropTypes.string
};
