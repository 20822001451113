import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { TooltipContent } from './Tooltip/TooltipContent';
import { TooltipButton } from 'common/react/components/tooltip-button';

export const RoundVotes = ({ data, rounds, seasonPid, tooltips = false }) => {
    const maxRounds =
        rounds?.filter((item) => item.name.includes('Round')).length ?? 0;

    const seasonHasOr =
        rounds?.filter((item) => item.name.includes('Opening')).length ?? 0;

    return (
        <div className="brownlow-round-votes">
            {data.roundByRoundVotes &&
                [...Array(Number(maxRounds))].map((round, i) => {
                    const roundData = data.roundByRoundVotes.find((item) => {
                        return (
                            item.roundNumber === (seasonHasOr === 0 ? i + 1 : i)
                        );
                    });
                    const votes = roundData?.votes;
                    const bye = rounds
                        ?.find(
                            (item) =>
                                item.roundNumber ===
                                (seasonHasOr === 0 ? i + 1 : i)
                        )
                        ?.byes.find(
                            (item) => item.providerId === data?.team.teamId
                        );

                    return (
                        <div
                            key={i}
                            className={classNames(
                                'brownlow-round-votes__round-container',
                                {
                                    'brownlow-round-votes__round-container--bye':
                                        bye,
                                    'brownlow-round-votes__round-container--ineligible':
                                        !bye && !data.eligible && votes,
                                    'brownlow-round-votes__round-container--empty':
                                        !bye && !votes
                                }
                            )}
                        >
                            {tooltips && !bye && votes ? (
                                <TooltipButton
                                    id="brownlow-player-modal-rounds-tooltip"
                                    title=""
                                    classNames={{
                                        component:
                                            'brownlow-player-modal__rounds-tooltip',
                                        button: 'brownlow-player-modal__rounds-tooltip-button'
                                    }}
                                    direction="above"
                                    primaryTooltipContent={
                                        <TooltipContent
                                            row={{}}
                                            cell={{
                                                value: votes,
                                                column: {
                                                    id: 'round' + (i + 1)
                                                }
                                            }}
                                            player={data.player.playerId}
                                            seasonPid={seasonPid}
                                        />
                                    }
                                    showOnHover={false}
                                    noPadding
                                    hideArrow
                                    externalTooltip={true}
                                >
                                    {votes}
                                </TooltipButton>
                            ) : bye ? (
                                'B'
                            ) : (
                                votes ?? ''
                            )}
                        </div>
                    );
                })}
        </div>
    );
};

RoundVotes.propTypes = {
    data: PropTypes.any.isRequired,
    rounds: PropTypes.array.isRequired,
    seasonPid: PropTypes.string.isRequired,
    tooltips: PropTypes.bool
};
