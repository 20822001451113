import React from 'react';
import { TooltipButton } from 'common/react/components/tooltip-button';
import { getTranslation } from 'common/react/utils/translations';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const TeamAnnouncementsTooltip = () => {
    return (
        <TooltipButton
            id={`late-changes-disclamer`}
            title={'late changes disclaimer'}
            direction="below"
            primaryTooltipContent={getTranslation(
                'label.lateChangesDisclaimer'
            )}
            showOnHover={true}
            hideArrow={false}
            noTransform={true}
            theme={'dark'}
        >
            <SvgIcon icon={`info-circle`} className="icon" />
        </TooltipButton>
    );
};

TeamAnnouncementsTooltip.propTypes = {};
