import { all, takeEvery } from 'redux-saga/effects';
import { SETUP_GLOSSARY, CHANGE_GLOSSARY_MODAL_VISIBILITY } from '../actions';
import { setupGlossaryTerms } from './setup-glossary-terms';
import { changeGlossaryModalVisibility } from './change-modal-visibility';

/**
 *
 */
export function* glossaryRootSaga() {
    yield all([
        takeEvery(SETUP_GLOSSARY.REQUEST, setupGlossaryTerms),
        takeEvery(
            CHANGE_GLOSSARY_MODAL_VISIBILITY.REQUEST,
            changeGlossaryModalVisibility
        )
    ]);
}
