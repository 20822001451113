import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'common/react/components/select';
import { getTranslation } from 'common/react/utils/translations';
import { careerAndSeasonStatsNavSelector } from 'store/modules/statspro/career-and-season-stats/selectors/nav-selector';
import { UPDATE_NAV_ITEM } from 'store/modules/statspro/career-and-season-stats/actions';
import { useGetCompSeasonsQuery } from 'common/store/modules/api/afl-api';

export const SeasonNavItem = () => {
    const dispatch = useDispatch();
    const nav = useSelector(careerAndSeasonStatsNavSelector);
    const { seasons } = useGetCompSeasonsQuery(
        { competitionId: nav.competition.id },
        {
            skip: !nav.competition.id ? true : false,
            selectFromResult: ({ data = [] }) => ({
                seasons: [
                    { id: -1, name: getTranslation('label.stats.career') },
                    ...data
                ]
            })
        }
    );

    return (
        <Select
            items={seasons}
            itemToString={(season) => season.name}
            initialSelectedItem={
                seasons.length && nav.season.id
                    ? seasons.find((s) => s.id === nav.season.id)
                    : ''
            }
            activeItem={
                seasons.length && nav.season.id
                    ? seasons.find((s) => s.id === nav.season.id)
                    : ''
            }
            isReadyToRender={() => seasons?.length && nav.season.id}
            label={`${getTranslation('label.stats.career')}/${getTranslation(
                'label.stats.season'
            )}`}
            onSelectedItemChange={({ selectedItem }) => {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'season',
                        value: { id: selectedItem.id }
                    })
                );
            }}
        />
    );
};
