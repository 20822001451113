import React from 'react';
import PropTypes from 'prop-types';
import { MediaThumbnail } from './MediaThumbnail';

/**
 * Renders the unordered list for each MediaThumbnail component.
 *
 * @param {string} title - The widget title.
 * @param {Array} articlesData - The club news articles.
 * @param {string} clubPid - The club Provider ID, e.g.'CD_T70'.
 * @param {string} clubAbbr - The club abbreviation, e.g.'geel'.
 * @param {string} baseUrl - The root URL to the club news articles, e.g. 'https://dev.aflc-geel.pulselive.com/news'.
 * @returns {JSX.Element} Component.
 */
export const ClubNewsListing = ({
    title,
    articlesData,
    clubPid,
    clubAbbr,
    baseUrl
}) => {
    return (
        <ul className="media-list__list block-list-4 block-list-3-tablet block-list-1-phablet">
            {articlesData?.map((article) => {
                return (
                    <li className="media-list__item" key={article.id}>
                        <MediaThumbnail
                            title={title}
                            article={article}
                            clubPid={clubPid}
                            clubAbbr={clubAbbr}
                            baseUrl={baseUrl}
                        />
                    </li>
                );
            })}
        </ul>
    );
};

ClubNewsListing.propTypes = {
    title: PropTypes.string,
    articlesData: PropTypes.array,
    clubPid: PropTypes.string,
    clubAbbr: PropTypes.string,
    baseUrl: PropTypes.string
};
