import React, { useState } from 'react';

import { ModalTitle } from './ModalTitle';
import { MarketingPreferences } from './ModalMarketingPreferences';
import { getTranslation } from 'common/react/utils/translations';
import { useCreateMarketingPrefsMutation } from 'store/modules/api/salesforce-sit-api';
import {
    getCreateUpdateBody,
    DEFAULT_CONSENTS,
    modelSubsciptions
} from 'common/store/modules/api/util/salesforce-helpers';

export const CreateCommPrefModalWrapper = () => {
    const [marketingPrefs, setMarketingPrefs] = useState({
        subscriptions: modelSubsciptions(),
        consents: DEFAULT_CONSENTS,
        preferences: []
    });

    /**
     * Callback for when a toggle all is changed. This update all the subs in
     * our local state to have a status of true
     *
     * @param {boolean} value - Is toggle checked?
     */
    function handleToggleAllClick(value) {
        setMarketingPrefs((prevMarketingPref) => {
            // Copy subscriptions
            const newSubscriptions = [...prevMarketingPref.subscriptions];

            // Update all to true
            newSubscriptions.forEach((sub) => {
                sub.status = value;
            });

            // Inject back into the marketing preferences object and return
            return {
                ...prevMarketingPref,
                subscriptions: newSubscriptions
            };
        });
    }

    /**
     * Callback for when a toggle is changed. This updates the relevant changed
     * subscription in the subscriptions array of the marketingPreferences state
     *
     * @param {string} key - What preference is being toggled?
     * @param {boolean} value - Is toggle checked?
     */
    function handleToggleClick(key, value) {
        setMarketingPrefs((prevMarketingPref) => {
            // Copy subscriptions
            const newSubscriptions = [...prevMarketingPref.subscriptions];

            // Update value of our key
            const subToUpdate = newSubscriptions.find(
                (sub) => sub.name === key
            );

            if (subToUpdate) {
                subToUpdate.status = value;
            }

            // Inject back into the marketing preferences object and return
            return {
                ...prevMarketingPref,
                subscriptions: newSubscriptions
            };
        });
    }

    const [createPreferences] = useCreateMarketingPrefsMutation();

    /**
     * Callback for when the save button is clicked, fires of the mutation call
     * to create preferences with salesforce
     *
     * @param {boolean} privacyAgreed - Is the privacy checkbox ticked?
     */
    function handleSaveClick(privacyAgreed) {
        const body = getCreateUpdateBody({
            preferences: marketingPrefs.preferences,
            subscriptions: marketingPrefs.subscriptions,
            consents: {
                privacy: privacyAgreed,
                marketing: privacyAgreed
            }
        });

        createPreferences(body);
    }

    return (
        <>
            <ModalTitle
                title={getTranslation('settingsModal.aflidSettings')}
                closeBtn={false}
            />

            <MarketingPreferences
                title={getTranslation(
                    'settingsModal.communicationPreferences.title'
                )}
                subtitle={getTranslation(
                    'settingsModal.communicationPreferences.subtitle'
                )}
                marketingPrefs={marketingPrefs}
                onToggleAllClick={handleToggleAllClick}
                onToggleClick={handleToggleClick}
                onSaveClick={handleSaveClick}
            />
        </>
    );
};
