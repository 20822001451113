import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders the ins and outs data.
 *
 * @param {object} props - The props
 * @param {Array} props.playersIn - The players in data.
 * @param {Array} props.playersOut - The players out data.
 * @returns {React.JSX.Element} - The formatted substitutes data.
 */
export const TeamAnnouncementsInsOuts = ({ playersIn, playersOut }) => {
    return (
        <p
            className={`team-announcements__substitutes team-announcements__substitutes`}
        >
            <span className="team-announcements__substitutes-team">
                <span className="team-announcements__substitutes-title team-announcements__substitutes-title--in content-tag">
                    {PULSE.I18N.lookup('label.teamlineups.playerIn')}
                </span>

                <span className="team-announcements__substitutes-values">
                    {playersIn?.length > 0 ? (
                        playersIn?.map((subIn, index) => {
                            return (
                                <span key={`subIn_${index}`}>
                                    {subIn?.player?.playerName?.givenName}{' '}
                                    {subIn?.player?.playerName?.surname}
                                    {subIn?.reason
                                        ? ` (${subIn?.reason})`
                                        : null}
                                    {index + 1 !== playersIn.length
                                        ? `, `
                                        : null}{' '}
                                </span>
                            );
                        })
                    ) : (
                        <span>-</span>
                    )}
                </span>
            </span>

            <span className="team-announcements__substitutes-team">
                <span className="team-announcements__substitutes-title team-announcements__substitutes-title--out content-tag">
                    {PULSE.I18N.lookup('label.teamlineups.playerOut')}
                </span>

                <span className="team-announcements__substitutes-values">
                    {playersOut?.length > 0 ? (
                        playersOut?.map((subOut, index) => {
                            return (
                                <span key={`subOut_${index}`}>
                                    {subOut?.player?.playerName?.givenName}{' '}
                                    {subOut?.player?.playerName?.surname}
                                    {subOut?.reason
                                        ? ` (${subOut?.reason})`
                                        : null}
                                    {index + 1 !== playersOut.length
                                        ? `, `
                                        : null}{' '}
                                </span>
                            );
                        })
                    ) : (
                        <span>-</span>
                    )}
                </span>
            </span>
        </p>
    );
};

TeamAnnouncementsInsOuts.propTypes = {
    playersIn: PropTypes.array,
    playersOut: PropTypes.array
};
