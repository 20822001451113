import React from 'react';
import PropTypes from 'prop-types';

export const HeaderCellBettingOdds = () => {
    return (
        <th
            className="stats-table__header-cell stats-table__header-cell--betting-odds"
            scope="col"
        >
            <button className="stats-table__header-cell-button" disabled={true}>
                <img
                    className="icon"
                    src={`/resources/${window.RESOURCES_VERSION}/i/elements/betting-cell-banner.svg`}
                />
            </button>
        </th>
    );
};

HeaderCellBettingOdds.propTypes = {
    keyProp: PropTypes.string
};
