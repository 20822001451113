import { Navigation, A11y, Pagination } from 'swiper';

export const sliderSettings = (name) => {
    return {
        modules: [Navigation, A11y, Pagination],
        slidesPerView: 1.15,
        navigation: {
            nextEl: '.swiper-button-next-unique-' + name,
            prevEl: '.swiper-button-prev-unique-' + name
        },
        pagination: {
            el: '.js-video-hub-view-by-match-pagination',
            clickable: true,
            renderBullet: (index, className) =>
                `<span class="${className}"></span>`
        },
        watchSlidesProgress: true
    };
};

export const BREAKPOINTS_FOR_SIX_SLIDES = {
    breakpoints: {
        360: {
            slidesPerView: 2.16,
            slidesPerGroup: 2
        },
        570: {
            slidesPerView: 3.21,
            slidesPerGroup: 3
        },
        840: {
            slidesPerView: 4,
            slidesPerGroup: 4
        },
        1300: {
            slidesPerView: 6,
            slidesPerGroup: 6
        }
    }
};
