import React from 'react';

const MIN_HEIGHT = '35rem';
const MIN_WIDTH = '29rem';

const CLASS_BLOCK = 'player-stats-tooltip-loader';

import { Loader } from 'common/react/components/Loader';

/**
 * @returns {JSX.Element} Component.
 */
export const TooltipLoading = () => {
    return (
        <div
            className={CLASS_BLOCK}
            style={{ minHeight: MIN_HEIGHT, minWidth: MIN_WIDTH }}
        >
            <Loader />
        </div>
    );
};
