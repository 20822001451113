import { createSelector } from 'reselect';

/**
 * @param {ReduxState} state - The Redux state.
 * @returns {object} The Stats Glossary state.
 */
export const statsGlossarySelector = (state) => state.statspro.glossary;

export const statsGlossaryTermsSelector = createSelector(
    statsGlossarySelector,
    (statsGlossary) => statsGlossary.terms
);

export const statsGlossaryVisibleSelector = createSelector(
    statsGlossarySelector,
    (statsGlossary) => statsGlossary.modalVisible
);
