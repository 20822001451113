import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param props.children
 * @returns {JSX.Element} Component.
 */
export const StatsSectionContentHeaderOptions = ({ children }) => {
    return (
        <>
            {children && (
                <div className="stats-section-content-header__options-wrapper">
                    {React.Children.map(children, (child) => (
                        <div className="stats-section-content-header__option">
                            {child}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

StatsSectionContentHeaderOptions.defaultProps = {
    name: 'StatsSectionContentHeaderOptions'
};

StatsSectionContentHeaderOptions.propTypes = {
    children: PropTypes.any
};
