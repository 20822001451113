import React from 'react';
import PropTypes from 'prop-types';

import { PlaceholderImage } from './PlaceholderImage';
import { AddPlayerButton } from './AddPlayerButton';

export const PlaceholderPlayer = ({ playerNumber }) => {
    return (
        <div
            className={`compare-header__player compare-header__player--${playerNumber}`}
        >
            <PlaceholderImage />
            <AddPlayerButton playerNumber={playerNumber} />
        </div>
    );
};

PlaceholderPlayer.propTypes = {
    playerNumber: PropTypes.string.isRequired
};
