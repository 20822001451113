import React from 'react';
import PropTypes from 'prop-types';

export const FixturePrematchLabel = ({ prematchLabel }) => {
    return (
        <div className={`fixtures__badge fixtures__badge--prematch-label`}>
            <p className="fixtures__badge-text">{prematchLabel}</p>
        </div>
    );
};

FixturePrematchLabel.propTypes = {
    prematchLabel: PropTypes.string
};
