import { STATS_COLUMNS } from 'widgets/statspro/js/components/common/config/columns';
import { STATS_COLUMNS_FOR_CATEGORY } from '../config/columns';

export const composeColumnData = (category, customFields) => {
    // Custom My Stats fields
    let customStats =
        category === 'My Stats' && customFields?.length
            ? customFields.map((field) => {
                  const customStatCol = STATS_COLUMNS[field.fieldName];

                  return {
                      Header: customStatCol.abbreviation,
                      accessor: customStatCol.id,
                      sortDescFirst: true
                  };
              })
            : [];

    customStats = [
        ...customStats,
        {
            Header: '',
            accessor: 'empty',
            disableSortBy: true
        }
    ];

    // Columns exluding jumperNumber and player name
    const statsColumns =
        category === 'My Stats'
            ? [...customStats]
            : STATS_COLUMNS_FOR_CATEGORY[category].map((col) => ({
                  Header: col.abbreviation,
                  accessor: col.id,
                  sortDescFirst: true
              }));

    return [
        {
            Header: '#',
            accessor: 'jumperNumber',
            sortDescFirst: true,
            sortType: (a, b) =>
                b.values.jumperNumber.jumperNumber -
                a.values.jumperNumber.jumperNumber
        },
        {
            Header: 'Player',
            accessor: 'player'
        },
        ...statsColumns
    ];
};
