/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { useStatComparisonNavSync } from './hooks/use-stat-comparison-nav-sync';
import { CategoryNavItem, SeasonNavItem } from './components/nav-items';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import {
    statComparisonDataTypeSelector,
    statComparisonNavSelector
} from 'store/modules/statspro/stat-comparison/selectors';
import {
    BenchmarkingLegend,
    BenchmarkingExplainer
} from 'common/react/components/benchmarking';
import { DataTypeToggle, Heading } from './components/header';
import { StatComparisonMain } from './components/StatComparisonMain';
import { playersSelector } from 'store/modules/statspro/player-comparison/selectors';
import classNames from 'classnames';
import { BenchmarkingPlaceholder } from 'common/react/components/benchmarking/BenchmarkingPlaceholder';

export const StatComparison = ({
    title,
    seasonId,
    compseasonId,
    competitionId,
    competitionPid,
    showBenchmarkingAflw
}) => {
    const nav = useSelector(statComparisonNavSelector);
    const dataType = useSelector(statComparisonDataTypeSelector);
    const options = useCompetitionNavigationOptions(nav);
    useStatComparisonNavSync(options, competitionId, compseasonId);
    const playerIds = useSelector(playersSelector);

    const showBenchmarkingAFLWBool = showBenchmarkingAflw === 'true';
    const { WOMENS } = PULSE.app.common.CONSTANTS.PROVIDER_IDS;
    const isAFLW = competitionPid === WOMENS;
    const showBenchmarking = !isAFLW || (isAFLW && showBenchmarkingAFLWBool);

    const isAFLM =
        competitionPid === PULSE.app.common.CONSTANTS.PROVIDER_IDS.PREMIERSHIP;

    const renderAveragesBenchmarking =
        showBenchmarking &&
        (dataType.type === 'averages' ||
            dataType.type === 'benchmarkedAverages');

    const renderTotalsBenchmarking =
        showBenchmarking &&
        (dataType.type === 'totals' || dataType.type === 'benchmarkedTotals');

    return (
        <div
            className={classNames(
                'fade-in-on-load fade-in-on-load--stats widget--stats',
                {
                    'is-loaded': playerIds[1] || playerIds[2]
                }
            )}
        >
            {playerIds[1] || playerIds[2] ? (
                <StatsSection.Main>
                    <StatsSection.Heading title={title} />
                    <StatsSection.Nav>
                        <StatsSection.NavItems>
                            <CategoryNavItem />
                            <SeasonNavItem />
                        </StatsSection.NavItems>
                    </StatsSection.Nav>
                    <StatsSection.Content>
                        <StatsSection.ContentHeader>
                            <StatsSection.ContentHeaderHeading>
                                <Heading />
                            </StatsSection.ContentHeaderHeading>
                            <StatsSection.ContentHeaderOptions>
                                <DataTypeToggle />
                            </StatsSection.ContentHeaderOptions>

                            <StatsSection.ContentHeaderBelow>
                                {renderAveragesBenchmarking && (
                                    <BenchmarkingLegend />
                                )}
                                {renderAveragesBenchmarking && (
                                    <BenchmarkingExplainer />
                                )}
                            </StatsSection.ContentHeaderBelow>

                            <StatsSection.ContentHeaderBelow
                                isSingleModifier={true}
                            >
                                {renderTotalsBenchmarking && (
                                    <BenchmarkingPlaceholder />
                                )}
                            </StatsSection.ContentHeaderBelow>
                        </StatsSection.ContentHeader>
                        <StatsSection.ContentBody>
                            <StatComparisonMain
                                playerIds={playerIds}
                                seasonId={seasonId}
                                isAFLW={isAFLW}
                                isAFLM={isAFLM}
                                showBenchmarking={showBenchmarking}
                            />
                        </StatsSection.ContentBody>
                    </StatsSection.Content>
                </StatsSection.Main>
            ) : null}
        </div>
    );
};

StatComparison.propTypes = {
    title: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    compseasonId: PropTypes.string.isRequired,
    competitionId: PropTypes.string.isRequired,
    competitionPid: PropTypes.string.isRequired,
    showBenchmarkingAflw: PropTypes.string
};
