import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../../setup-react';

// ===== Signals ============================================================ //

// Nav
export const UPDATE_NAV_ITEM = createSignalAction(
    'brownlow/round-by-round',
    'UPDATE_NAV_ITEM'
);

// ===== Deltas ============================================================= //

// Nav
export const SET_NAV_ITEM = createDeltaActionType(
    'brownlow/round-by-round',
    'SET_NAV_ITEM'
);
export const setNavItem = createActionCreator(SET_NAV_ITEM);
