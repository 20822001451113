import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useGetContentItemQuery } from 'common/store/modules/api/content-api';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

/**
 * Takes a promo ID and renders a travel icon based on the supplied tag.
 *
 * @param {number} id           - The promo ID.
 * @returns {React.JSX.Element} - The rendered SVG icon matching the travel tag.
 */
export const TravelIcon = ({ id }) => {
    const promo = useGetContentItemQuery({
        type: 'PROMO',
        id: id
    });

    const tags = promo?.data?.tags,
        promoId = promo?.data?.id,
        promoLink = promo?.data?.links;

    return tags?.some((tag) => tag.label.startsWith('travel:')) ? (
        <Fragment key={`travel_promo_${promoId}`}>
            {promoLink.length > 0 ? (
                <a
                    href={promoLink[0]?.promoUrl}
                    className="interactive-event-map-small__transport-icon-container interactive-event-map-small__transport-icon-container--link"
                    target={promoLink[0].external ? '_blank' : '_self'}
                    aria-label={`${getTranslation(
                        'label.onwardJourney'
                    )} ${promoLink[0]?.linkText.toLowerCase()}`}
                    rel="noreferrer"
                >
                    <SvgIcon
                        key={`small_map_label_${promoId}`}
                        icon={tags[0]?.label?.replace('travel:', '')}
                        className="icon"
                    />
                </a>
            ) : (
                <div className="interactive-event-map-small__transport-icon-container">
                    <SvgIcon
                        key={`small_map_label_${promoId}`}
                        icon={tags[0]?.label?.replace('travel:', '')}
                        className="icon"
                    />
                </div>
            )}
        </Fragment>
    ) : null;
};

TravelIcon.propTypes = {
    id: PropTypes.number
};
