import React from 'react';
import cx from 'classnames';

import DefaultCellRendererContainer from 'custom-table/js/components/DefaultCell/DefaultCellRendererContainer';

/*
    This is a simple example of a custom cell renderer, just to show how we could override the default.
    Shows how we can support selecting multiple attributes from the API response into a single table field.
*/

const AllClubsCellRenderer = ({
    className,
    value,
    getTranslation,
    ...additionalProps
}) => {
    const aflIcon = PULSE.app.templating.render(
        {
            cssClass: ``,
            name: `afl-logo`
        },
        'common.svg-icon'
    );

    return (
        <DefaultCellRendererContainer
            className={cx(className, 'custom-table__cell--club', {})}
            {...additionalProps}
        >
            <span
                className="custom-table__club-icon"
                dangerouslySetInnerHTML={{ __html: aflIcon }}
            ></span>
            <span>{getTranslation('label.stats.allClubs')}</span>
        </DefaultCellRendererContainer>
    );
};

export default AllClubsCellRenderer;
