import { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { UPDATE_ITEM } from 'store/modules/video-hub/view-by-match/actions';
import { viewByMatchSelector } from 'store/modules/video-hub/view-by-match/selectors';

export const useResetSelection = () => {
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);
    const viewByMatch = useSelector(viewByMatchSelector);

    useEffect(() => {
        // when the nav changes, reset the selected item
        batch(() => {
            dispatch(
                UPDATE_ITEM.request({
                    item: 'activeIndex',
                    value: -1
                })
            );

            dispatch(
                UPDATE_ITEM.request({
                    item: 'matchId',
                    value: ''
                })
            );
        });
    }, [dispatch, nav.competition?.id, nav.season?.id, nav.round]);

    useEffect(() => {
        // when the selected index is reset, also remove the matchId
        if (viewByMatch.activeIndex === -1 && viewByMatch.matchId !== '') {
            dispatch(
                UPDATE_ITEM.request({
                    item: 'matchId',
                    value: ''
                })
            );
        }
    }, [dispatch, viewByMatch.activeIndex, viewByMatch.matchId]);
};
