import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { FtdEventsListing } from '../components/FtdEventsListing';

export const FtdEventsListingProvider = ({ store, data }) => {
    return (
        <Provider store={store}>
            <FtdEventsListing {...data} />
        </Provider>
    );
};

FtdEventsListingProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
