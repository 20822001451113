export const STATS_CATEGORY = {
    KEY_STATS: 'Key Stats',
    GENERAL_PLAY: 'General Play',
    DISPOSALS: 'Disposals',
    POSSESSIONS: 'Possessions',
    STOPPAGES: 'Stoppages',
    MARKS: 'Marks',
    SCORING: 'Scoring',
    DEFENCE: 'Defence'
};

export const STATS_CATEGORY_ARRAY = [
    STATS_CATEGORY.KEY_STATS,
    STATS_CATEGORY.GENERAL_PLAY,
    STATS_CATEGORY.DISPOSALS,
    STATS_CATEGORY.POSSESSIONS,
    STATS_CATEGORY.STOPPAGES,
    STATS_CATEGORY.MARKS,
    STATS_CATEGORY.SCORING,
    STATS_CATEGORY.DEFENCE
];

export const STATS_CATEGORY_LABELS = {
    'Key Stats': 'keyStats',
    'General Play': 'generalPlay',
    Disposals: 'disposals',
    Possessions: 'possessions',
    Stoppages: 'stoppages',
    Marks: 'marks',
    Scoring: 'scoring',
    Defence: 'defence'
};
