import produce from 'immer';
import {
    SET_TOOLTIP_PLAYER_STATS,
    SET_TOOLTIP_PLAYER_STATS_REQUEST,
    REMOVE_TOOLTIP_PLAYER_STATS_REQUEST
} from '../actions';
import { TooltipState } from '../types';
import { Action } from '../../../../types';
import { setTooltipPlayerStats } from './set-tooltip-player-stats';
import { setRequest, removeRequest } from '../../shared/reducer';

/**
 * @type {TooltipState}
 */
const initialState = {
    data: null,
    requests: []
};

/**
 * @param {TooltipState} state - The Redux state.
 * @param {Action} action - The Redux action.
 * @returns {TooltipState} The updated Redux state.
 */
export const tooltipReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_TOOLTIP_PLAYER_STATS:
                setTooltipPlayerStats(draft, action.payload);
                break;
            case SET_TOOLTIP_PLAYER_STATS_REQUEST:
                setRequest(draft, action.payload);
                break;
            case REMOVE_TOOLTIP_PLAYER_STATS_REQUEST:
                removeRequest(draft, action.payload);
                break;
            default:
                draft;
        }
    });
};
