import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTranslation } from 'common/react/utils/translations';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { ManageStatsModal } from '../modal/ManageStatsModal';
import {
    playerStatsCustomFieldsSelector,
    playerStatsModalSelector
} from 'store/modules/match-centre/player-stats/selectors';
import {
    UPDATE_CUSTOM_FIELDS,
    UPDATE_MODAL,
    UPDATE_TEMPORARY_FIELDS
} from 'store/modules/match-centre/player-stats/actions';
import { playerStatsTemporaryFieldsSelector } from 'store/modules/match-centre/player-stats/selectors/temporary-fields-selector';

export const ManageStatsButton = () => {
    const dispatch = useDispatch();
    const isModalOpen = useSelector(playerStatsModalSelector)?.isOpen;
    const ref = useRef();
    const TABLE_NAME = 'mcPlayerStats';
    const initialFields = useSelector(playerStatsTemporaryFieldsSelector);
    const customFields = useSelector(playerStatsCustomFieldsSelector);

    const toggleModal = ({ confirm = false } = {}) => {
        if (isModalOpen) {
            if (confirm) {
                // Update initial fields
                dispatch(
                    UPDATE_TEMPORARY_FIELDS.request({
                        data: [...customFields]
                    })
                );
            } else {
                // Reset the table to the initial fields when the user closes the modal instead of confirming
                localStorage.setItem(TABLE_NAME, JSON.stringify(initialFields));
                dispatch(
                    UPDATE_CUSTOM_FIELDS.request({
                        data: [...initialFields]
                    })
                );
            }
        }

        dispatch(UPDATE_MODAL.request({ isOpen: !isModalOpen }));
    };

    useEffect(() => {
        // Set the z-index of the player stats tab
        // which avoids the modal appearing below other elements on the page
        const tab = ref.current?.closest('.js-mc-tab');

        if (tab) {
            if (isModalOpen && !tab.classList.contains('is-modal-open')) {
                tab.classList.add('is-modal-open');
            } else if (
                !isModalOpen &&
                tab.classList.contains('is-modal-open')
            ) {
                tab.classList.remove('is-modal-open');
            }
        }
    }, [isModalOpen]);

    return (
        <>
            <button
                ref={ref}
                className="filter-button"
                onClick={() => toggleModal()}
            >
                <SvgIcon icon="edit" className="icon manage-stats-icon" />
                {getTranslation('label.customTable.manageStatsButton')}
            </button>

            <ManageStatsModal
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
            />
        </>
    );
};
