import React from 'react';
import cx from 'classnames';

import DefaultTableHeadRendererContainer from 'custom-table/js/components/DefaultTable/DefaultTableHeadRendererContainer';

/*
    MultipleTableHeadRenderer

    Custom header cell renderer for multiple table header cells for the same field
*/
const MultipleTableHeadRenderer = ({
    className,
    field,
    ...additionalProps
}) => {
    if (!field) {
        return (
            <DefaultTableHeadRendererContainer
                className={cx(className, 'custom-table__cell')}
                {...additionalProps}
            >
                <span> - </span>
            </DefaultTableHeadRendererContainer>
        );
    }

    const sortBy = additionalProps.sortBy;

    const sortIcon = PULSE.app.templating.render(
        {
            cssClass: `custom-table__header-cell-sort-icon`,
            name: 'dropdown'
        },
        'common.svg-icon'
    );

    const tableHeadMarkup = Object.keys(field.tableHeadLabels).map(
        (item, index) => {
            const tableHeadValue = field.tableHeadLabels[item];
            const isActive =
                sortBy &&
                field.fieldName === sortBy.fieldName &&
                sortBy.additionalSortProps === item;

            let sortDirection = isActive ? sortBy.direction : 'DESC';
            const sortIsActiveKey = `is-active--${sortBy.direction.toLowerCase()}`;

            const showSortButtons =
                !field.noSort && additionalProps.multipleTableIndex === 0;
            const sortButtons = showSortButtons ? (
                <div
                    className={cx('custom-table__header-cell-sort-buttons', {
                        'is-active': isActive,
                        [sortIsActiveKey]: isActive
                    })}
                >
                    <button
                        className={`custom-table__header-cell-sort-button custom-table__header-cell-sort-button--single custom-table__header-cell-sort-button--${sortDirection.toLowerCase()}`}
                        onClick={(event) => {
                            const parentElement =
                                event.target.closest('.is-active');

                            if (
                                event.target.classList.contains('is-active') ||
                                parentElement
                            ) {
                                sortDirection =
                                    sortDirection === 'DESC' ? 'ASC' : 'DESC';
                            }

                            return additionalProps.handleClick(
                                field,
                                additionalProps.tableName,
                                sortDirection,
                                item
                            );
                        }}
                    >
                        <span
                            dangerouslySetInnerHTML={{ __html: sortIcon }}
                        ></span>
                    </button>
                </div>
            ) : null;

            let fieldDescription = field.description
                ? additionalProps.getTranslation(field.description)
                : false;
            fieldDescription =
                fieldDescription && fieldDescription !== field.description
                    ? fieldDescription
                    : false;

            const description = fieldDescription ? (
                <div className="custom-table__description">
                    <h4 className="custom-table__description-title">
                        {additionalProps.getTranslation(field.fieldLabel)}
                    </h4>
                    <p className="custom-table__description-text">
                        {additionalProps.getTranslation(field.description)}
                    </p>
                </div>
            ) : null;

            const isFirstField = index === 0;
            const isLastField =
                index === Object.keys(field.tableHeadLabels).length - 1;
            const tableHeadTitle = field.tableHeadTitle
                ? field.tableHeadTitle
                : field.fieldLabel;

            const titleMarkup = isFirstField ? (
                <span className="custom-table__header-cell-text custom-table__header-cell-text--multiple-fields">
                    <span>
                        {additionalProps.getTranslation(tableHeadTitle)}
                    </span>
                </span>
            ) : null;

            return (
                <DefaultTableHeadRendererContainer
                    className={cx(
                        className,
                        'custom-table__header-cell custom-table__header-cell--multiple-fields',
                        {
                            'custom-table__header-cell--multiple-fields-first':
                                isFirstField,
                            'custom-table__header-cell--multiple-fields-last':
                                isLastField
                        }
                    )}
                    key={`thead_${field.fieldName}_${item}`}
                    {...additionalProps}
                >
                    {titleMarkup}
                    {description}
                    <span className="custom-table__header-cell-secondary-text">
                        {additionalProps.getTranslation(tableHeadValue)}
                    </span>
                    {sortButtons}
                </DefaultTableHeadRendererContainer>
            );
        }
    );

    return tableHeadMarkup;
};

export default MultipleTableHeadRenderer;
