// eslint-disable-next-line no-unused-vars
import { PlayerStatsCustomFieldsState } from '../../types';
import '../../types';

/**
 * @param {PlayerStatsCustomFieldsState} draft - Draft state.
 * @param {object} payload - Action payload.
 * @param payload.data
 */
export const setCustomFields = (draft, { data }) => {
    draft.customFields = data ?? [];
};
