import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../../setup-react';

// ===== Signals ============================================================ //

// Stats
export const FETCH_STAT_COMPARISON = createSignalAction(
    'stats/stat-comparison',
    'FETCH_STAT_COMPARISON'
);

// Nav
export const UPDATE_NAV_ITEM = createSignalAction(
    'stats/stat-comparison',
    'UPDATE_NAV_ITEM'
);

// Data Type
export const UPDATE_DATA_TYPE = createSignalAction(
    'stats/stat-comparison',
    'UPDATE_DATA_TYPE'
);

// ===== Deltas ============================================================= //

// Stats
export const SET_STAT_COMPARISON = createDeltaActionType(
    'stats/stat-comparison',
    'SET_STAT_COMPARISON'
);
export const setStatComparison = createActionCreator(SET_STAT_COMPARISON);

export const SET_STAT_COMPARISON_REQUEST = createDeltaActionType(
    'stats/stat-comparison',
    'SET_STAT_COMPARISON_REQUEST'
);
export const setStatComparisonRequest = createActionCreator(
    SET_STAT_COMPARISON_REQUEST
);

export const REMOVE_STAT_COMPARISON_REQUEST = createDeltaActionType(
    'stats/stat-comparison',
    'REMOVE_STAT_COMPARISON_REQUEST'
);
export const removeStatComparisonRequest = createActionCreator(
    REMOVE_STAT_COMPARISON_REQUEST
);

// Nav
export const SET_NAV_ITEM = createDeltaActionType(
    'stats/stat-comparison',
    'SET_NAV_ITEM'
);
export const setNavItem = createActionCreator(SET_NAV_ITEM);

// Data Type
export const SET_DATA_TYPE = createDeltaActionType(
    'stats/stat-comparison',
    'SET_DATA_TYPE'
);
export const setDataType = createActionCreator(SET_DATA_TYPE);
