/**
 * @param {any} draft - Draft state.
 * @param {any} payload - Action payload.
 */
export const setDataType = (draft, payload) => {
    draft.dataType = {
        ...draft.dataType,
        ...payload
    };
};
