import { createSlice } from '@reduxjs/toolkit';

const initialState = true;

const bettingVis = createSlice({
    name: 'betting-vis',
    initialState,
    reducers: {
        setVis: (state, action) => action.payload
    }
});

// RTK Query update: Export the slice reducer and actions
if (PULSE.app.redux) {
    PULSE.app.redux.reducers.bettingVis = {
        root: bettingVis.reducer
    };
    PULSE.app.redux.actions = {
        ...PULSE.app.redux.actions,
        bettingVis: bettingVis.actions
    };
} else {
    PULSE.app.redux = {
        actions: {
            bettingVis: bettingVis.actions
        },
        reducers: {
            root: {
                bettingVis: bettingVis.reducer
            }
        }
    };
}
