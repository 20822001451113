import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getTranslation } from 'common/react/utils/translations';
import { PredictorCurrentRoundContext } from '../BrownlowPredictor';

export const MobileVotes = ({ data, rounds }) => {
    const currentRoundNumber = useContext(PredictorCurrentRoundContext);

    const columns = useMemo(() => {
        let columnsCreated = [
            ...rounds
                .filter((col) => {
                    return col.name.toLowerCase().includes('round');
                })
                .map((col) => {
                    return {
                        Header: col.roundNumber === 0 ? 'OR' : col.roundNumber,
                        accessor: 'round' + col.roundNumber
                    };
                })
        ];

        return columnsCreated;
    }, [rounds]);

    return (
        <section className="brownlow-tracker-leaderboard-mobile brownlow-tracker-leaderboard-mobile__hidden-section">
            <table className="brownlow-tracker-leaderboard-mobile__hidden-section-table">
                <thead>
                    <tr>
                        <th className="brownlow-tracker-leaderboard-mobile__row-header">
                            {' '}
                            {getTranslation('label.stats.round')}
                        </th>
                        {columns.map((cell) => {
                            return (
                                <th
                                    key={cell.Header}
                                    className="brownlow-tracker-leaderboard-mobile__round-header"
                                >
                                    {cell.Header}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className="brownlow-tracker-leaderboard-mobile__row-header">
                            {' '}
                            {getTranslation('label.votes')}
                        </th>
                        {data.map((cell, index) => {
                            const cellProps = cell.getCellProps();
                            let value = cell.value.actual ?? 0;

                            const isUpcoming =
                                parseInt(cell.column.Header) >
                                currentRoundNumber;

                            return (
                                <td key={index}>
                                    <ConditionalWrapper
                                        condition={true}
                                        {...cellProps}
                                        wrapper={() => (
                                            <div
                                                className={classnames(
                                                    'stats-table__cell-inner-wrapper',
                                                    {
                                                        'stats-table__cell-inner-wrapper--empty':
                                                            !value
                                                    },
                                                    {
                                                        'stats-table__cell-inner-wrapper--bye':
                                                            value === 'B'
                                                    },
                                                    {
                                                        'stats-table__cell-inner-wrapper--not-played':
                                                            value === 'NP'
                                                    },
                                                    {
                                                        'stats-table__cell-inner-wrapper--upcoming':
                                                            isUpcoming
                                                    }
                                                )}
                                            >
                                                {!isUpcoming &&
                                                    value !== 'NP' &&
                                                    value}
                                            </div>
                                        )}
                                    >
                                        <>{value}</>
                                    </ConditionalWrapper>
                                </td>
                            );
                        })}
                    </tr>
                </tbody>
            </table>
        </section>
    );
};

MobileVotes.propTypes = {
    data: PropTypes.array,
    rounds: PropTypes.array
};

const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;
