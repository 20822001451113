import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';

import { TeamIcon } from 'common/react/components/TeamIcon';

export const FixtureTeam = ({ team, theme, conferenceLabel }) => {
    return (
        <>
            {conferenceLabel && (
                <div className="fixtures__conference-icon">
                    <SvgIcon icon={conferenceLabel} className={'icon'} />
                </div>
            )}
            <TeamIcon
                className="fixtures__match-team-logo"
                providerId={team.providerId}
                theme={theme}
            />
            <span className="fixtures__match-team-name">{team.name}</span>
        </>
    );
};

FixtureTeam.propTypes = {
    team: PropTypes.object.isRequired,
    theme: PropTypes.string,
    conferenceLabel: PropTypes.string
};
