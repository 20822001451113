import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

export const MatchStatusTime = ({ matchInfo }) => {
    const matchClock = useRef();
    const timeRef = useRef();
    const matchId = matchInfo?.match?.matchId
        ? matchInfo.match.matchId
        : matchInfo.providerId;
    const matchTime = useSelector((state) =>
        window.PULSE.app.redux.selectors.match.timeString(state, matchId)
    );

    useEffect(() => {
        if (matchTime?.string) {
            if (matchClock.current) {
                matchClock.current.updateTime();
            } else {
                matchClock.current = new PULSE.app.MatchClock(timeRef.current);
            }
        }
    }, [matchTime?.string]);

    return (
        <div
            className={`fb-match__status-text--time ${
                matchTime.countdown ? 'is-countdown' : ''
            }`}
            data-provider-id={matchId}
            ref={timeRef}
            dangerouslySetInnerHTML={{ __html: matchTime.string }}
        />
    );
};

MatchStatusTime.propTypes = {
    matchInfo: PropTypes.object
};
