/**
 * @param {object} wageringData - Wagering api response
 * @param {string} teamProviderId - The teams provider id
 * @returns {object|null} - The found premiership winning prop or null
 */
export function getPremiershipWinProp(wageringData, teamProviderId) {
    if (!wageringData?.competition?.books?.length) {
        return null;
    }

    const premiershipBets = wageringData.competition?.books.find((book) => {
        return book.name.includes('Premiership Winner');
    });

    if (!premiershipBets || !premiershipBets.propositions.length) {
        return null;
    }

    return premiershipBets.propositions.find(
        (proposition) => proposition.clubId === teamProviderId
    );
}
