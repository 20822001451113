export const groupMatchesByDate = (matches, timezone) => {
    return PULSE.app.common.arrayHelper.groupBy(matches, (match) => {
        // Group cancelled/postponed matches under their status keys
        if (PULSE.app.common.match.cancelledOrPostponed(match.status)) {
            return PULSE.app.common.CONSTANTS.UNCONFIRMED_GROUP;
        }

        return moment(match.utcStartTime).tz(timezone).format('YYYYMMDD');
    });
};
