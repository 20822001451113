import React, { useEffect } from 'react';

import { TeamIcon } from 'common/react/components/TeamIcon';

export const MatchCardEventOneTeam = ({ event, removeEvent }) => {
    useEffect(() => {
        setTimeout(function () {
            removeEvent(event);
        }, 4000);
    }, []);

    return (
        <div
            className={`fb-match__event ${
                event.shown === false ? 'fb-match__event__goal' : ''
            }`}
            style={{
                '--team': `var(--${event.latestGoal.teamName.teamAbbr.toLowerCase()})`
            }}
        >
            <img
                className="fb-match__event__goal-bg"
                src={PULSE.app.common.team.getTeamWatermarkUrl(
                    event.latestGoal.teamId,
                    'left-dark'
                )}
                alt=""
            />
            <div className="fb-match__event__goal-team-container">
                <TeamIcon
                    className="fb-match__logo-icon"
                    providerId={event.latestGoal.teamId}
                    theme="light"
                />
            </div>
            <div className="fb-match__event__goal-text">
                {event.latestGoal.scoreType === 'GOAL' &&
                    PULSE.I18N.lookup('label.mc.stats.goal')}
                {event.latestGoal.scoreType === 'BEHIND' &&
                    PULSE.I18N.lookup('label.mc.stats.behind')}
                {event.latestGoal.scoreType === 'RUSHED_BEHIND' &&
                    PULSE.I18N.lookup('label.mc.stats.rushed_behind')}
            </div>
            <div className="fb-match__event-arrow fb-match__event-arrow-left fb-match__event-arrow-1"></div>
            <div className="fb-match__event-arrow fb-match__event-arrow-left fb-match__event-arrow-2"></div>
            <div className="fb-match__event-arrow fb-match__event-arrow-left fb-match__event-arrow-3"></div>
            <div className="fb-match__event-arrow fb-match__event-arrow-left fb-match__event-arrow-4"></div>
            <div className="fb-match__event-arrow fb-match__event-arrow-left fb-match__event-arrow-5"></div>
            <div className="fb-match__event-arrow fb-match__event-arrow-left fb-match__event-arrow-6"></div>
        </div>
    );
};
