import { STATUS_KEYS } from './status-keys';

/**
 * Works out if we need to adjust the status cookie before first render
 *
 * @param {string} cookieName - The name of the cookie we're looking for
 * @returns {number} - What we should use as the initial value for the status state
 */
export function getInitialCookieValue(cookieName) {
    const currentStatusCookie = PULSE.core.localStorage.getStorage(
        cookieName,
        true
    );

    if (
        typeof currentStatusCookie === 'undefined' ||
        !Object.values(STATUS_KEYS).includes(currentStatusCookie)
    ) {
        return STATUS_KEYS.EMPTY;
    }

    /**
     * If status is BAR_SEEN or MSG_SEEN on page load, that means this is the
     * second load where they have been seen and we instead want to update
     * status to be the relevant closed status so the right component renders on
     * first render.
     */
    if (currentStatusCookie === STATUS_KEYS.BAR_SEEN) {
        return STATUS_KEYS.BAR_CLOSED;
    } else if (currentStatusCookie === STATUS_KEYS.MSG_SEEN) {
        return STATUS_KEYS.MSG_CLOSED;
    }

    return currentStatusCookie;
}
