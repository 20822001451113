import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ConditionalWrapper } from 'common/react/components/ConditionalWrapper';

export const StatsTablePredictedCell = ({
    cellProps,
    condition,
    value,
    tooltipButton,
    row,
    isUpcoming,
    showPredictedData
}) => {
    return (
        <td {...cellProps} className="stats-table__cell">
            <ConditionalWrapper
                condition={condition}
                wrapper={(children) => (
                    <div className="stats-table__cell-predicted-wrapper">
                        {children}
                    </div>
                )}
            >
                <div
                    className={classnames(
                        'stats-table__cell-inner-wrapper',
                        {
                            'stats-table__cell-inner-wrapper--empty':
                                !value.actual
                        },
                        {
                            'stats-table__cell-inner-wrapper--bye':
                                value.actual === 'B'
                        },
                        {
                            'stats-table__cell-inner-wrapper--not-played':
                                !value.played
                        },
                        {
                            'stats-table__cell-inner-wrapper--upcoming':
                                isUpcoming
                        }
                    )}
                >
                    {tooltipButton
                        ? tooltipButton
                        : value.actual !== 'NP' && !isUpcoming && value.actual}
                </div>
                {(value.predicted || row.original.totalPredictedVotes) &&
                showPredictedData ? (
                    <div
                        className={`stats-table__cell-predicted ${
                            !value.predicted
                                ? 'stats-table__cell-predicted--hidden'
                                : ''
                        }`}
                    >
                        {value.predicted}
                    </div>
                ) : (
                    ''
                )}
            </ConditionalWrapper>
        </td>
    );
};

StatsTablePredictedCell.propTypes = {
    cellProps: PropTypes.object,
    condition: PropTypes.bool,
    value: PropTypes.object.isRequired,
    tooltipButton: PropTypes.any,
    row: PropTypes.object,
    isUpcoming: PropTypes.bool,
    showPredictedData: PropTypes.bool
};
