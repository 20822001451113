import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { ConditionalWrapper } from 'common/react/components/ConditionalWrapper';
import { LazyImage } from 'common/react/components/LazyImage';

export const BroadcasterItem = ({ item, type, timezone, index }) => {
    return (
        <div
            className={`broadcast-guide__broadcaster-item broadcast-guide__broadcaster-item--${type} ${
                index === 0 ? 'is-first' : ''
            }`}
            data-media={type}
            data-media-type={type}
        >
            {item.externalLink ? (
                <a
                    href={item.externalLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="broadcast-guide__broadcaster-type broadcast-guide__broadcaster-type--link"
                >
                    {item.info.channelTypes[0].name}
                    <SvgIcon
                        icon="link-arrow"
                        className="broadcast-guide__broadcaster-external"
                    />
                </a>
            ) : (
                <span className="broadcast-guide__broadcaster-type">
                    {item.info.channelTypes[0].name}
                </span>
            )}

            <ConditionalWrapper
                condition={item.externalLink}
                wrapper={(children) => (
                    <a
                        href={item.externalLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {children}
                    </a>
                )}
            >
                {item.info.logo && (
                    <LazyImage
                        cssClass="broadcast-guide__broadcaster-logo"
                        url={item.info.logo.info?.original?.url}
                        objectFit={true}
                    />
                )}
            </ConditionalWrapper>

            <span className="broadcast-guide__broadcaster-channel-name">
                <strong>
                    {item.info.abbreviation
                        ? item.info.abbreviation
                        : item.info.name}
                </strong>
            </span>

            {item.startDateTime && (
                <time
                    className="broadcast-guide__broadcaster-time"
                    data-timestamp={
                        luxon.DateTime.fromISO(item.startDateTime, {
                            zone: timezone
                        }).ts
                    }
                    data-format="h:mm a"
                >
                    {luxon.DateTime.fromISO(item.startDateTime, {
                        zone: timezone
                    }).toFormat('h:mm')}
                    <span className="broadcast-guide__broadcaster-time-ampm">
                        {luxon.DateTime.fromISO(item.startDateTime, {
                            zone: timezone
                        }).toFormat('a')}
                    </span>
                </time>
            )}
        </div>
    );
};

BroadcasterItem.propTypes = {
    item: PropTypes.object.isRequired,
    type: PropTypes.string,
    timezone: PropTypes.string,
    index: PropTypes.number
};
