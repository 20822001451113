import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const MatchStatusCountdown = ({ match }) => {
    const countdownRef = useRef();
    const countdownInner = useRef();
    const MILLIS = 1000;
    const countDownTimeInSeconds = Date.parse(match.utcStartTime) / MILLIS;
    const countdownId = `fb-${match.id}`;
    const ONE_SECOND = 1000;

    useEffect(() => {
        const isCountdownConstructorAvailable = () =>
            typeof PULSE !== 'undefined' &&
            typeof PULSE.app !== 'undefined' &&
            typeof PULSE.app.Countdown === 'function';

        const initializeCountdown = () => {
            if (isCountdownConstructorAvailable()) {
                countdownInner.current = new PULSE.app.Countdown(
                    countdownRef.current
                );
            } else {
                setTimeout(initializeCountdown, ONE_SECOND); // Retry after 1 second
            }
        };

        initializeCountdown();
    }, []);

    return (
        <div
            className="fb-match-countdown-container"
            ref={countdownRef}
            data-countdown-to={countDownTimeInSeconds}
            data-countdown-id={countdownId}
        >
            <div className="fb-match-countdown js-countdown-here"></div>
        </div>
    );
};

MatchStatusCountdown.propTypes = {
    match: PropTypes.object
};
