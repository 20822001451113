import { put } from 'redux-saga/effects';
import { setModal } from '../actions';

/**
 * @typedef {object} UpdateModalAction
 * @property {object} payload
 * @property {boolean} payload.isOpen
 */

/**
 * @param {UpdateModalAction} action - The Redux action.
 */
export function* updateModal(action) {
    yield put(setModal(action.payload));
}
