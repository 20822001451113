import { put, call } from 'redux-saga/effects';
import { fetchWithAccount } from '../../../../utils/fetch-with-account';
import {
    FETCH_PREDICTED_DATA,
    removePredictedDataRequest,
    setPredictedData,
    setPredictedDataRequest
} from '../actions';

/**
 * @typedef {object} FetchPredictedDataAction
 * @property {object} payload
 * @property {string} [payload.season]
 * @property {string} [payload.playerName]
 */

/**
 * @param {FetchPredictedDataAction} action - The Redux action.
 */
export function* fetchPredictedData(action) {
    const { season, playerName } = action.payload;

    yield put(setPredictedDataRequest(action.payload));

    let data = {};

    if (season && playerName) {
        data = yield call(
            fetchWithAccount,
            `${
                PULSE.app.environment.api
            }compseasons/${season}/award/brownlow/player-search?page=0&pageSize=25&term=${encodeURIComponent(
                playerName
            )}`
        );

        if (data) {
            yield put(setPredictedData({ data: data }));
        }
    }

    yield put(removePredictedDataRequest(action.payload));

    yield put(FETCH_PREDICTED_DATA.success(data));
}
