import { put } from 'redux-saga/effects';
import { setBettingToggle } from '../actions';

/**
 * @typedef {object} UpdateBettingToggleAction
 * @property {string} type
 * @property {object} payload
 * @property {boolean} payload.isActive
 */

/**
 * @param {UpdateBettingToggleAction} action - The Redux action.
 */
export function* updateBettingToggle(action) {
    yield put(setBettingToggle(action.payload));
}
