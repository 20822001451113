import { useState, useEffect } from 'react';

/**
 * Fetch Matches for a particular comp, compseaon and round from the Pulse
 * Matches API. Store them in state and return them.
 *
 * @param {object} param0 An object containing the params required to query for round matches
 * @param {number|string} param0.competitionId The pulse ID for the comp to fetch matches for
 * @param {number|string} param0.compSeasonId The pulse ID for the compseason to fetch matches for
 * @param {number|string} param0.roundNumber The number for the round to fetch matches for
 * @returns {object} Returns an object with a isLoading flag and an array of matches once the api is finished
 */
export function useRoundMatches({ competitionId, compSeasonId, roundNumber }) {
    const [isLoading, setIsLoading] = useState(true);
    const [matches, setMatches] = useState([]);

    useEffect(() => {
        if (competitionId && compSeasonId && roundNumber !== null) {
            setIsLoading(true);

            fetch(
                `${
                    PULSE.app.environment.api
                }matches${PULSE.app.common.prepareParams({
                    competitionId,
                    compSeasonId,
                    roundNumber,
                    pageSize: 50
                })}`
            )
                .then((response) => response.json())
                .then((responseData) => {
                    if (responseData?.matches) {
                        setMatches(responseData.matches);
                    }

                    setIsLoading(false);
                });
        }
    }, [compSeasonId, competitionId, roundNumber]);

    return { isLoading, matches };
}
