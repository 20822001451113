/**
 * @param {object} item - Item..
 * @param {object[]} columns - Column data.
 * @param {number} seasonNavId - Season nav ID.
 * @param {'totals'|'averages'|'benchmarkedTotals'|'benchmarkedAverages'} dataType - Data type.
 * @param isProspect
 * @returns {object[]} Row data for the given player.
 */
export const composeRowData = (
    item,
    columns,
    seasonNavId,
    dataType,
    isProspect
) => {
    const averagesValue = (curr) =>
        isProspect
            ? item[1].averages?.[curr.accessor]
            : item[1].averages?.[curr.accessor]?.value;

    if (typeof item === 'string') {
        return {
            year: null,
            club: {
                teamAbbr: '',
                teamId: '',
                teamName: item,
                teamNickname: '',
                isFullWidth: true
            },
            opponent: null,
            round: null,
            result: null,
            isProspect: isProspect
        };
    }

    const roundNaming =
        item[1]?.round?.roundNumber === 0
            ? item[1]?.round?.roundAbbreviation
            : item[1]?.round?.roundNumber;

    return {
        year: item[0] === 'CD_S2101264' ? '2022' : item[0]?.substr(4, 4),
        club: item[1]?.club ?? '',
        gamesPlayed: item[1]?.gamesPlayed ?? '',
        opponent: item[1]?.opponent ?? '',
        round: roundNaming,
        result: item[1]?.result ?? '',
        ...columns.reduce(
            (prev, curr) => ({
                ...prev,
                [curr.accessor]:
                    seasonNavId === -1 &&
                    (dataType === 'averages' ||
                        dataType === 'benchmarkedAverages')
                        ? averagesValue(curr)
                        : item[1].totals?.[curr.accessor]
            }),
            {}
        ),
        benchmarks: {
            ...columns.reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr.accessor]:
                        item[1].averages?.[curr.accessor]?.benchmark ?? ''
                }),
                {}
            )
        }
    };
};
