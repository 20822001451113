import { navLink } from "../../../utils/nav-link";

export const compareAllListener = callback => {
    const button = document.querySelector('.js-stats-featured-compare-button');
    if (button) {
        PULSE.app.common.addAriaClickListener(
            button,
            event => {
                event.preventDefault();
                scrollTo({ top: 0 });
                navLink('compare');
                callback();
            }
        );
    }
}
