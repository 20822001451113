import reduxQuerySync from 'redux-query-sync';

import { UPDATE_BETTING_TOGGLE } from 'store/modules/brownlow-tracker/leaderboard/actions';
import { leaderboardBettingToggleSelector } from 'store/modules/brownlow-tracker/leaderboard/selectors';

export const configureReduxQuerySync = (store) => {
    return reduxQuerySync({
        store,
        params: {
            bettingOdds: {
                selector: (state) =>
                    leaderboardBettingToggleSelector(state)?.isActive,
                action: (isActive) => {
                    return UPDATE_BETTING_TOGGLE.request({ isActive });
                },
                stringToValue: (isActive) => isActive === 'true',
                defaultValue: false
            }
        },

        // Initially set the store's state to the current location.
        initialTruth: 'location'
    });
};
