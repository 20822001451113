import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'common/react/components/select';
import { getTranslation } from 'common/react/utils/translations';
import {
    UPDATE_NAV_ITEM,
    UPDATE_BENCHMARKING
} from 'store/modules/statspro/stats-leaders/actions';
import { statsLeadersNavSelector } from 'store/modules/statspro/stats-leaders/selectors/nav-selector';

const entireSeasonTranslation = getTranslation(
    'label.statsLeaders.nav.round.entireSeason'
);

export const RoundNavItem = () => {
    const dispatch = useDispatch();
    const nav = useSelector(statsLeadersNavSelector);
    const roundsList = useSelector(
        (state) => state.rounds[`season_${nav.season.id}`]?.list
    );
    const rounds = roundsList
        ? [
              { id: -1, name: entireSeasonTranslation, roundNumber: 0 },
              ...roundsList
          ]
        : [];

    return (
        <Select
            items={rounds}
            itemToString={(round) => round.name}
            initialSelectedItem={
                rounds.length && nav.round.id
                    ? rounds.find((s) => s.id === nav.round.id) ?? ''
                    : ''
            }
            activeItem={
                rounds.length && nav.round.id
                    ? rounds.find((s) => s.id === nav.round.id) ?? ''
                    : ''
            }
            isReadyToRender={() =>
                rounds.length &&
                nav.round.id &&
                rounds.find((s) => s.id === nav.round.id)
            }
            label={getTranslation('label.statsLeaders.nav.round')}
            onSelectedItemChange={({ selectedItem }) => {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'round',
                        value: {
                            id: selectedItem.id,
                            number: selectedItem.roundNumber
                        }
                    })
                );

                if (selectedItem?.id !== -1) {
                    dispatch(UPDATE_BENCHMARKING.request({ isActive: false }));
                }
            }}
        />
    );
};
