import { combineReducers } from 'redux';
import { viewByMatchRootSaga } from './view-by-match/sagas';
import { viewByMatchReducer } from './view-by-match/reducer';
import { videoListRootSaga } from './video-list/sagas';
import { videoListReducer } from './video-list/reducer';

const sagas = [viewByMatchRootSaga, videoListRootSaga];

const rootReducer = combineReducers({
    viewByMatch: viewByMatchReducer,
    videoList: videoListReducer
});

// @ts-expect-error
if (window.PULSE.app.redux) {
    // @ts-expect-error
    window.PULSE.app.redux.sagas.videoHub = { root: sagas };
    // @ts-expect-error
    window.PULSE.app.redux.reducers.videoHub = { root: rootReducer };
} else {
    // @ts-expect-error
    window.PULSE.app.redux = {
        reducers: {
            videoHub: {
                root: rootReducer
            }
        },
        sagas: {
            videoHub: {
                root: sagas
            }
        }
    };
}
