import React, { useRef } from 'react';

import { DefaultCellRenderer } from 'custom-table/js/components/DefaultCell';

const TableLegends = ({ state, displayedFields, fields, getTranslation }) => {
    const legendIcon = PULSE.app.templating.render(
        {
            cssClass: '',
            name: 'info'
        },
        'common.svg-icon'
    );

    return (
        <aside className="legend">
            <h3 className="legend__title">
                <span dangerouslySetInnerHTML={{ __html: legendIcon }}></span>
                {getTranslation('label.legend')}
            </h3>
            <ul className="legend__list">
                {fields.map(
                    (field, i) =>
                        field.fieldLabelShort &&
                        field.fieldLabel && (
                            <li
                                key={`legend__list__${i}`}
                                className="legend__item"
                            >
                                <strong>
                                    {getTranslation(field.fieldLabelShort)}:
                                </strong>{' '}
                                {getTranslation(field.fieldLabel)}
                            </li>
                        )
                )}
            </ul>
        </aside>
    );
};

export default TableLegends;
