import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const HighlightsPlayer = ({
    accountId,
    policyKey,
    videoPlayerId,
    anchorName,
    videoItem
}) => {
    const playerWidgetRef = useRef(null);
    const videoJsElement = useRef(null);

    /**
     *  Runs on every render to check if there are any video players
     *  that haven't been disposed, and disposes them before we create
     *  the new one.
     */
    if (videoJsElement?.current !== null) {
        bc(videoJsElement?.current?.querySelector('video')).dispose();
    }

    useEffect(() => {
        new PULSE.app.common.Video(playerWidgetRef.current, [], {});
    }, []);

    return (
        <div
            className="pp-bio-awards__draft-player-video inline-player js-video-container is-active"
            data-widget="video-player"
            ref={playerWidgetRef}
        >
            <div className="inline-player__player-container">
                <video-js
                    ref={videoJsElement}
                    id={`draft-prospect-player-modal_${anchorName}`}
                    data-video-id={videoItem.mediaId}
                    data-account={accountId}
                    data-policy-key={policyKey}
                    data-player={videoPlayerId}
                    class={`inline-player__video video-js vjs-16-9 js-video-player`}
                    controls
                    playsinline
                    poster={videoItem?.thumbnailUrl}
                ></video-js>
            </div>
        </div>
    );
};

HighlightsPlayer.propTypes = {
    accountId: PropTypes.string.isRequired,
    policyKey: PropTypes.string.isRequired,
    videoPlayerId: PropTypes.string.isRequired,
    anchorName: PropTypes.string,
    videoItem: PropTypes.object
};
