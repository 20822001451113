export const STATS_CATEGORY = {
    AFL_FANTASY_POINTS: 'AFL Fantasy Points',
    GOALS: 'Goals',
    DISPOSALS: 'Disposals',
    MARKS: 'Marks',
    TACKLES: 'Tackles',
    CLEARANCES: 'Clearances',
    KICKS: 'Kicks',
    HANDBALLS: 'Handballs'
};

export const STATS_CATEGORY_ARRAY = [
    STATS_CATEGORY.AFL_FANTASY_POINTS,
    STATS_CATEGORY.GOALS,
    STATS_CATEGORY.DISPOSALS,
    STATS_CATEGORY.MARKS,
    STATS_CATEGORY.TACKLES,
    STATS_CATEGORY.CLEARANCES,
    STATS_CATEGORY.KICKS,
    STATS_CATEGORY.HANDBALLS
];

export const STATS_CATEGORY_IDS = {
    [STATS_CATEGORY.AFL_FANTASY_POINTS]: 'dreamTeamPoints',
    [STATS_CATEGORY.GOALS]: 'goals',
    [STATS_CATEGORY.DISPOSALS]: 'disposals',
    [STATS_CATEGORY.MARKS]: 'marks',
    [STATS_CATEGORY.TACKLES]: 'tackles',
    [STATS_CATEGORY.CLEARANCES]: 'totalClearances',
    [STATS_CATEGORY.KICKS]: 'kicks',
    [STATS_CATEGORY.HANDBALLS]: 'handballs'
};
