import React from 'react';
import PropTypes from 'prop-types';

import { CompIcon } from 'common/react/components/CompIcon';
import { getTranslation } from 'common/react/utils/translations';

export const FixtureFreeEntry = ({ competition }) => {
    const compModifier = competition === 'aflw' ? 'aflw-m' : competition;

    return (
        <div className={`fixtures__badge fixtures__badge--free-entry`}>
            <CompIcon
                code={compModifier}
                className="icon fixtures__free-entry-icon"
            />
            <p className="fixtures__badge-text">
                {getTranslation('label.free_entry')}
            </p>
        </div>
    );
};

FixtureFreeEntry.propTypes = {
    competition: PropTypes.string.isRequired
};
