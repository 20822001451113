import React from 'react';

import { BENCHMARKING } from './config/benchmarking';
import { BenchmarkLabel } from './BenchmarkLabel';
import { SvgIcon } from '../SvgIcon';
import { TooltipButton } from '../tooltip-button';
import { composeGetTranslation } from '../../utils/translations';

export const BenchmarkingExplainer = () => {
    const getTranslation = composeGetTranslation(
        'label.statsLeaders.benchmarking.explainer.'
    );

    return (
        <div className="benchmarking-explainer">
            <TooltipButton
                id="benchmarking-explainer"
                title={getTranslation('tooltipTargetText')}
                classNames={{
                    component: 'benchmarking-explainer__wrapper',
                    button: 'benchmarking-explainer__button'
                }}
                direction="below"
                primaryTooltipContent={
                    <div className="benchmarking-explainer-tooltip-content">
                        <h2 className="benchmarking-explainer-tooltip-content__heading">
                            {getTranslation('heading')}
                        </h2>
                        <p>{getTranslation('description')}</p>
                        <ul className="benchmarking-explainer-tooltip-content__list">
                            <li className="benchmarking-explainer-tooltip-content__list-item">
                                <div className="benchmarking-explainer-tooltip-content__label">
                                    <BenchmarkLabel
                                        benchmarkKey={BENCHMARKING.ELITE.KEY}
                                    />
                                </div>
                                <strong className="benchmarking-explainer-tooltip-content__value">
                                    {BENCHMARKING.ELITE.PERCENTAGE}
                                </strong>
                            </li>
                            <li className="benchmarking-explainer-tooltip-content__list-item">
                                <div className="benchmarking-explainer-tooltip-content__label">
                                    <BenchmarkLabel
                                        benchmarkKey={
                                            BENCHMARKING.ABOVE_AVERAGE.KEY
                                        }
                                    />
                                </div>
                                <strong className="benchmarking-explainer-tooltip-content__value">
                                    {BENCHMARKING.ABOVE_AVERAGE.PERCENTAGE}
                                </strong>
                            </li>
                            <li className="benchmarking-explainer-tooltip-content__list-item">
                                <div className="benchmarking-explainer-tooltip-content__label">
                                    <BenchmarkLabel
                                        benchmarkKey={BENCHMARKING.AVERAGE.KEY}
                                    />
                                </div>
                                <strong className="benchmarking-explainer-tooltip-content__value">
                                    {BENCHMARKING.AVERAGE.PERCENTAGE}
                                </strong>
                            </li>
                            <li className="benchmarking-explainer-tooltip-content__list-item">
                                <div className="benchmarking-explainer-tooltip-content__label">
                                    <BenchmarkLabel
                                        benchmarkKey={
                                            BENCHMARKING.BELOW_AVERAGE.KEY
                                        }
                                    />
                                </div>
                                <strong className="benchmarking-explainer-tooltip-content__value">
                                    {BENCHMARKING.BELOW_AVERAGE.PERCENTAGE}
                                </strong>
                            </li>
                        </ul>
                    </div>
                }
            >
                <SvgIcon
                    icon="help-circle"
                    className="benchmarking-explainer__icon"
                />
                {getTranslation('tooltipTargetText')}
            </TooltipButton>
        </div>
    );
};
