import { useEffect, useState } from 'react';

export const usePathname = () => {
    const [pathname, setPathname] = useState(location.pathname);

    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach(() => {
                if (pathname === location.pathname) {
                    return;
                }
                setPathname(location.pathname);
            });
        });

        observer.observe(document.querySelector('body'), {
            childList: true,
            subtree: true,
            attributes: true
        });

        return () => observer.disconnect();
    });

    return pathname;
};
