import { composeGetTranslation } from 'common/react/utils/translations';
import { STATS_CATEGORY } from './categories';

export const STATS_COLUMN_IDS = {
    AFL_FANTASY_POINTS: 'dreamTeamPoints',
    GOALS: 'goals',
    DISPOSALS: 'disposals',
    MARKS: 'marks',
    TACKLES: 'tackles',
    CLEARANCES: 'totalClearances',
    KICKS: 'kicks',
    HANDBALLS: 'handballs',
    HIT_OUTS: 'hitouts',
    INSIDE_50S: 'inside50s',
    REBOUND_50S: 'rebound50s',
    SPOILS: 'spoils',
    GAMES_PLAYED: 'gamesPlayed'
};

/**
 * @typedef {object} Stat
 * @property {string} id
 * @property {string} name
 * @property {string} abbreviation
 */

/**
 * @type {object<string, Stat>}
 */
export const STATS_COLUMNS = {};

// eslint-disable-next-line guard-for-in
for (let key in STATS_COLUMN_IDS) {
    const id = STATS_COLUMN_IDS[key];
    const getTranslation = composeGetTranslation(`label.statsLeaders.${id}.`);

    STATS_COLUMNS[id] = {
        id,
        name: getTranslation('name') ?? '',
        abbreviation: getTranslation('abbreviation') ?? ''
    };
}

Object.freeze(STATS_COLUMNS);

export const STATS_COLUMNS_FOR_CATEGORY_PROSPECT = {
    [STATS_CATEGORY.KEY_STATS]: [
        STATS_COLUMNS[STATS_COLUMN_IDS.GAMES_PLAYED],
        STATS_COLUMNS[STATS_COLUMN_IDS.AFL_FANTASY_POINTS],
        STATS_COLUMNS[STATS_COLUMN_IDS.DISPOSALS],
        STATS_COLUMNS[STATS_COLUMN_IDS.KICKS],
        STATS_COLUMNS[STATS_COLUMN_IDS.HANDBALLS],
        STATS_COLUMNS[STATS_COLUMN_IDS.MARKS],
        STATS_COLUMNS[STATS_COLUMN_IDS.TACKLES],
        STATS_COLUMNS[STATS_COLUMN_IDS.HIT_OUTS],
        STATS_COLUMNS[STATS_COLUMN_IDS.CLEARANCES],
        STATS_COLUMNS[STATS_COLUMN_IDS.INSIDE_50S],
        STATS_COLUMNS[STATS_COLUMN_IDS.REBOUND_50S],
        STATS_COLUMNS[STATS_COLUMN_IDS.SPOILS],
        STATS_COLUMNS[STATS_COLUMN_IDS.GOALS]
    ]
};

Object.freeze(STATS_COLUMNS_FOR_CATEGORY_PROSPECT);
