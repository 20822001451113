// eslint-disable-next-line no-unused-vars
import { PlayerModalState, PlayerModal } from '../types';

/**
 * @param { PlayerModalState } state - The Redux state.
 * @returns { PlayerModal } The Draft Tracker State
 */

export const drafTrackerModalSelector = (state) =>
    state.draftTracker.playerModal;
