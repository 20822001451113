import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../../setup-react';

// ===== Signals ============================================================ //

// Nav
export const UPDATE_ITEM = createSignalAction(
    'video-hub/view-by-match',
    'UPDATE_ITEM'
);

// ===== Deltas ============================================================= //

// Nav
export const SET_ITEM = createDeltaActionType(
    'video-hub/view-by-match',
    'SET_ITEM'
);
export const setItem = createActionCreator(SET_ITEM);
