/**
 * @typedef {object} TeamScore
 * @property {object} score - An object containing a teams score
 * @property {number} score.goals - The number of goals scored
 * @property {number} score.behinds - The number of behinds scored
 * @property {number} score.totalScore - Their total score
 * @property {number} score.superGoals - Super Goals (not used)
 */

/**
 * @typedef {object} Team
 * @property {object} team - A object containing information about a team in the matches api
 * @property {number} team.id - The teams pulse id
 * @property {string} team.providerId - The teams champion id
 * @property {string} team.name - The teams name
 * @property {string} team.abbreviation - The abbreivation
 * @property {string} team.nickname - Their nickname
 * @property {object} team.club - Information about the teams parent club
 * @property {string} team.teamType - What team type are they
 * @property {TeamScore} score - An object containing a teams score
 */

/**
 * Works out who won the match
 *
 * @param {Team} home - The home team object
 * @param {Team} away - The away team object
 * @param {TeamScore} homeScore - Passed in seperately as it might come from cfs or pulse
 * @param {TeamScore} awayScore - Passed in seperately as it might come from cfs or pulse
 * @returns {Team} - The winning team
 */
export const getLeadingScore = (home, away, homeScore, awayScore) => {
    let obj = {
        home: {},
        away: {},
        draw: false
    };

    if (homeScore > awayScore) {
        obj = {
            team: home.team,
            score: homeScore - awayScore
        };
    } else if (homeScore < awayScore) {
        obj = {
            team: away.team,
            score: awayScore - homeScore
        };
    } else if (
        typeof homeScore !== 'undefined' &&
        typeof awayScore !== 'undefined' &&
        homeScore === awayScore
    ) {
        obj = {
            draw: true
        };
    }

    return obj;
};
