import { createSlice } from '@reduxjs/toolkit';

import { SITE_SETTINGS_CONFIG } from './config';

/**
 * @constant {string} LOCAL_STORAGE_KEY - The key we use to store preferences
 * locally
 */
const LOCAL_STORAGE_KEY = 'pulse_site_settings';

/**
 * Returns the preferred region name (this needs to live here as well as
 * broadcasters.js in common helpers as that doesn't exist when this is run)
 *
 * @param  { string } regionValue Region Value
 * @returns { string } either Australia or International
 */
const modifyCountryDisplay = (regionValue) =>
    regionValue === 'Australia' ? regionValue : 'International';

/**
 * Returns "Location" from a region's timezone string (this needs to live here
 * as well as broadcasters.js in common helpers as that doesn't exist when this
 * is run)
 *
 * @param  { string } timezone A regions timezone property
 * @returns { string } either Australia or International
 */
const getLocationFromTimezone = (timezone) =>
    modifyCountryDisplay(timezone?.split('/')?.[0]);

/**
 * Overrides the SITE_SETTINGS_CONFIG with APP_SETTINGS_CONFIG values
 * Please note: the APP_SETTINGS_CONFIG values are replaced with the app's config
 * in the article-api.html template (which is returned in the article body from api)
 * and rendered in the web view
 *
 * @returns { object } settings keys with values from the app
 */
const getAppSettings = () => {
    const appSettings = {};

    if (window.APP_SETTINGS_CONFIG) {
        for (const key in SITE_SETTINGS_CONFIG) {
            // checking if the value for the site settings key exists in the app settings config
            // and if the value has been overwritten by the app - if includes the '{{' string it means it wasn't replaced
            if (
                Object.prototype.hasOwnProperty.call(
                    window.APP_SETTINGS_CONFIG,
                    key
                ) &&
                window.APP_SETTINGS_CONFIG[key].indexOf('{{') === -1
            ) {
                if (key === 'displayWagering' || key === 'over18') {
                    appSettings[key] =
                        window.APP_SETTINGS_CONFIG[key] === 'true';
                }
            }
        }
    }
    return appSettings;
};

/**
 * Build out a default state from the settings config and each settings default
 * value
 *
 * @returns {object} The settings state to use with all the default values set
 */
function buildDefaultSettingsState() {
    const defaultSettings = {};

    for (const key in SITE_SETTINGS_CONFIG) {
        if (Object.prototype.hasOwnProperty.call(SITE_SETTINGS_CONFIG, key)) {
            const setting = SITE_SETTINGS_CONFIG[key];
            defaultSettings[setting.key] = setting.defaultValue;
        }
    }

    return defaultSettings;
}

/**
 * Works if we have a local store of a users settings and combines it with any
 * new defaults we might not have stored to give us an initialState to use
 *
 * @returns {object} The initial state of the 'settings' slice to use in redux
 */
function getInitialSettings() {
    let localSettings;
    try {
        localSettings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    } catch (error) {
        console.error(
            '[SiteSettings] Issue parsing locally stored settings, resetting to defaults.'
        );
        return buildDefaultSettingsState();
    }

    // If there are no local settings, start with default
    if (!localSettings) {
        return buildDefaultSettingsState();
    }

    // Check we have a setting for every default stored locally, if not add default
    for (const key in SITE_SETTINGS_CONFIG) {
        if (Object.prototype.hasOwnProperty.call(SITE_SETTINGS_CONFIG, key)) {
            const setting = SITE_SETTINGS_CONFIG[key];

            if (
                typeof getAppSettings()[setting.key] !== 'undefined' &&
                localSettings[setting.key] !== getAppSettings()[setting.key]
            ) {
                localSettings[setting.key] = getAppSettings()[setting.key];
            }

            if (typeof localSettings[setting.key] === 'undefined') {
                localSettings[setting.key] = setting.defaultValue;
                /**
                 * If we already have a region stored, check if it's international
                 * and if so, reset the timezone to device time
                 */
            } else if (
                setting.key === 'region' &&
                localSettings[setting.key] !== 'undefined' &&
                getLocationFromTimezone(localSettings[setting.key].timezone) !==
                    'Australia'
            ) {
                localSettings[setting.key].timezone = moment.tz.guess(true);
            }
        }
    }

    // Sync local store with what we're about to kick off the store with
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localSettings));

    return localSettings;
}

/**
 * The reducer function for when a setting is updated, can handle an object with
 * 1 or many keys and merges it with the existing settings in state
 *
 * @param {object} state The state of the settings slice of the store
 * @param {object} action The action object for the dispatched action
 * @param {object} [action.payload] The object of new settings to apply
 * @returns {object} The complete set of new settings to set back in the store
 */
function setReducer(state, action) {
    let newSettings = { ...state, ...action.payload };

    /**
     * If the incoming new setting is for region and we're setting a location
     * not in Australia, then ignore the given timezone and instead use the
     * users timezone instead.
     */
    const incomingRegionSetting =
        action.payload[SITE_SETTINGS_CONFIG.region.key];
    if (incomingRegionSetting) {
        const incomingLocation = getLocationFromTimezone(
            incomingRegionSetting.timezone
        );
        if (incomingLocation !== 'Australia') {
            newSettings = {
                ...newSettings,
                region: {
                    ...newSettings.region,
                    location: incomingLocation,
                    timezone: moment.tz.guess(true)
                }
            };
        } else {
            newSettings = {
                ...newSettings,
                region: { ...newSettings.region, location: incomingLocation }
            };
        }
    }

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newSettings));

    return newSettings;
}

/**
 * The reducer function for when we want to wipe out the settings we have in the
 * store and reset them all to default.
 *
 * @returns {object} The complete set of default settings to set back in the
 * store
 */
function resetReducer() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    return buildDefaultSettingsState();
}

// Create the slice to be used for settings in the store
const siteSettings = createSlice({
    name: 'siteSettings',
    initialState: getInitialSettings(),
    reducers: {
        set: setReducer,
        reset: resetReducer
    }
});

// Export actions so we can call them elsewhere in modules
export const { set, reset } = siteSettings.actions;

/**
 * A selector function to use when you want to access settings, you must use
 * this when accessing settings as it triggers a sync with salesforce if
 * appropriate.
 *
 * @param {object} state - The redux store state
 * @returns {object} The current state of the site settings
 */
export function get(state) {
    return state.siteSettings;
}

/**
 * A selector function to use when you want to access a single setting from site
 * settings, you must use this (or get for all settings) when accessing settings
 * as it triggers a sync with salesforce if appropriate.
 *
 * @param {object} state - The redux store state
 * @param {string} key - The key to access the setting from, should match a
 * top-level config property in SITE_SETTINGS_CONFIG
 * @returns {object} The current state of the setting
 */
export function getSingleSetting(state, key) {
    return state.siteSettings[SITE_SETTINGS_CONFIG[key]?.key];
}

/**
 * Attach the reducers and actions to the window object so we can access outside
 * of the webpack/module system
 */
if (PULSE.app.redux) {
    PULSE.app.redux.reducers.siteSettings = {
        root: siteSettings.reducer
    };
    PULSE.app.redux.actions = {
        ...PULSE.app.redux.actions,
        siteSettings: siteSettings.actions
    };
    PULSE.app.redux.selectors.siteSettings = {
        get,
        getSingleSetting
    };
} else {
    PULSE.app.redux = {
        actions: {
            siteSettings: siteSettings.actions
        },
        reducers: {
            root: {
                siteSettings: siteSettings.reducer
            }
        },
        selectors: {
            siteSettings: {
                get,
                getSingleSetting
            }
        }
    };
}
