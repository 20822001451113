import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { RoundListNavItem, SeasonNavItem } from './nav-items';
import { RoundByRoundCards } from './round-by-round-cards';
import { roundByRoundNavSelector } from 'store/modules/brownlow-tracker/round-by-round/selectors';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import { useRoundByRoundNavSync } from './hooks/use-round-by-round-nav-sync';

export const RoundByRound = ({ seasonsToShow, seasonPid }) => {
    const nav = useSelector(roundByRoundNavSelector);
    const options = useCompetitionNavigationOptions(nav);

    options.defaultSeason = nav.season.providerId ?? seasonPid;
    options.seasonsToShow = seasonsToShow ?? seasonsToShow;
    useRoundByRoundNavSync(options);

    return (
        <StatsSection.Main>
            <StatsSection.Nav>
                <StatsSection.NavItems>
                    <SeasonNavItem seasonsToShow={seasonsToShow} />
                    <RoundListNavItem />
                </StatsSection.NavItems>
            </StatsSection.Nav>
            <StatsSection.Content>
                <StatsSection.ContentBody>
                    <RoundByRoundCards />
                </StatsSection.ContentBody>
            </StatsSection.Content>
        </StatsSection.Main>
    );
};

RoundByRound.propTypes = {
    seasonsToShow: PropTypes.string,
    seasonPid: PropTypes.string
};
