import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../../setup-react';

// ===== Signals ============================================================ //

// Stats
export const FETCH_PERFORMANCE_CHART_STATS = createSignalAction(
    'stats/performance-chart',
    'FETCH_PERFORMANCE_CHART_STATS'
);

// Nav
export const UPDATE_NAV_ITEM = createSignalAction(
    'stats/performance-chart',
    'UPDATE_NAV_ITEM'
);

// Data Type
export const UPDATE_DATA_TYPE = createSignalAction(
    'stats/performance-chart',
    'UPDATE_DATA_TYPE'
);

// ===== Deltas ============================================================= //

// Stats
export const SET_PERFORMANCE_CHART_STATS = createDeltaActionType(
    'stats/performance-chart',
    'SET_PERFORMANCE_CHART_STATS'
);
export const setPerformanceChartStats = createActionCreator(
    SET_PERFORMANCE_CHART_STATS
);
export const SET_PLAYER_TWO_CHART_STATS = createDeltaActionType(
    'stats/performance-chart',
    'SET_PLAYER_TWO_CHART_STATS'
);
export const setPlayerTwoChartStats = createActionCreator(
    SET_PLAYER_TWO_CHART_STATS
);

export const SET_PERFORMANCE_CHART_SEASONS_STATS = createDeltaActionType(
    'stats/performance-chart',
    'SET_PERFORMANCE_CHART_SEASONS_STATS'
);
export const setPerformanceChartSeasonsStats = createActionCreator(
    SET_PERFORMANCE_CHART_SEASONS_STATS
);
export const SET_PLAYER_TWO_CHART_SEASONS_STATS = createDeltaActionType(
    'stats/performance-chart',
    'SET_PLAYER_TWO_CHART_SEASONS_STATS'
);
export const setPlayerTwoChartSeasonsStats = createActionCreator(
    SET_PLAYER_TWO_CHART_SEASONS_STATS
);


// Stats Request Tracking
export const SET_PERFORMANCE_CHART_STATS_REQUEST = createDeltaActionType(
    'stats/performance-chart',
    'SET_PERFORMANCE_CHART_STATS_REQUEST'
);
export const setPerformanceChartStatsRequest = createActionCreator(
    SET_PERFORMANCE_CHART_STATS_REQUEST
);

export const REMOVE_PERFORMANCE_CHART_STATS_REQUEST = createDeltaActionType(
    'stats/performance-chart',
    'REMOVE_PERFORMANCE_CHART_STATS_REQUEST'
);
export const removePerformanceChartStatsRequest = createActionCreator(
    REMOVE_PERFORMANCE_CHART_STATS_REQUEST
);

// Nav
export const SET_NAV_ITEM = createDeltaActionType(
    'stats/performance-chart',
    'SET_NAV_ITEM'
);
export const setNavItem = createActionCreator(SET_NAV_ITEM);

// Data Type
export const SET_DATA_TYPE = createDeltaActionType(
    'stats/performance-chart',
    'SET_DATA_TYPE'
);
export const setDataType = createActionCreator(SET_DATA_TYPE);
