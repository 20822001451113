import React from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SKELETON_COUNT = 9;

export const FixturesSkeleton = () => {
    return (
        <>
            <h2 className="fixtures__date-header">
                <Skeleton width={200} />
            </h2>
            <Skeleton
                className="fixtures__item-skeleton"
                count={SKELETON_COUNT}
            />
        </>
    );
};
