import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import FixtureBar from '../components/FixtureBar';
import PropTypes from 'prop-types';
import { useWindowSize } from 'common/react/hooks/use-window-resize';

/**
 * A Provider is used to wrap the "entry point" component for a React widget with a Redux Provider,
 * which is used to provide access to the Redux store.
 *
 * The "data" prop will contain any "data-" attributes added to the DOM element that the React widget will be rendered into.
 *
 * The Provider can also contain any "react-router" logic that may be required by a component.
 */

const FixtureBarProvider = ({ store, data }) => {
    const windowSize = useWindowSize();
    const isDesktop = () => windowSize.width >= PULSE.app.measurements.desktop;
    const [showFixtureBar, setShowFixtureBar] = useState(isDesktop());

    useEffect(() => {
        setShowFixtureBar(isDesktop());
    }, [windowSize.width]);

    return (
        <Provider store={store}>
            {showFixtureBar && <FixtureBar data={data} />}
        </Provider>
    );
};

FixtureBarProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
};

export default FixtureBarProvider;
