import React from 'react';

import { getTranslation } from 'common/react/utils/translations';

export const Empty = () => {
    return (
        <div className="broadcast-guide__not-available">
            {getTranslation('label.broadcastGuide.notAvailable')}
        </div>
    );
};
