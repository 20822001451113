import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from 'common/react/components/SvgIcon';

import { StatsTableRowHeader } from '../../../../statspro/js/components/common/stats-table/StatsTableRowHeader';

export const BodyCellClubName = ({ cell, cellProps }) => {
    const rowIndex = cell?.row?.index;

    const isConference = cell?.row?.original?.isConference;

    const displayName = cell?.row?.values?.club?.name || '';
    const teamName =
        PULSE.app.common.team.getTeamName(
            cell?.row?.values?.club?.providerId
        ) || '';

    const isMainCompetition =
        cell?.row?.original?.competitionType === 'AFL' ||
        cell?.row?.original?.competitionType === 'AFLW';
    const isAFLW = cell?.row?.original?.competitionType === 'AFLW';
    const teamUrl = PULSE.app.common.url.generateTeamLink(teamName, isAFLW);

    const isPremWinner =
        (cell?.row?.original?.premWinner?.team &&
            cell?.row?.original?.premWinner?.team?.providerId ===
                cell?.row?.values?.club?.providerId) ||
        false;

    const isConferenceRow =
        rowIndex === 1 && isConference ? 'is-conference-row' : '';

    return (
        <StatsTableRowHeader
            cellProps={cellProps}
            className={`stats-table__row-header-cell stats-table__cell--club-name ${isConferenceRow}`}
        >
            {isMainCompetition ? (
                <a
                    href={teamUrl}
                    className="stats-table__club-name-wrapper stats-table__club-name-link"
                >
                    {displayName && (
                        <span className="stats-table__club-name">
                            {displayName}
                        </span>
                    )}
                    {isPremWinner && (
                        <div className="stats-table__prem-icon">
                            <SvgIcon
                                icon="badge-premiership"
                                className="icon"
                            />
                        </div>
                    )}
                </a>
            ) : (
                <div className="stats-table__club-name-wrapper">
                    {displayName && (
                        <span className="stats-table__club-name">
                            {displayName}
                        </span>
                    )}
                    {isPremWinner && (
                        <div className="stats-table__prem-icon">
                            <SvgIcon
                                icon="badge-premiership"
                                className="icon"
                            />
                        </div>
                    )}
                </div>
            )}
        </StatsTableRowHeader>
    );
};

BodyCellClubName.propTypes = {
    cell: PropTypes.object,
    cellProps: PropTypes.object
};
