import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

export const HeaderButton = ({ activeIndex, buttonClickHandler }) => (
    <>
        <button
            onClick={() => buttonClickHandler()}
            className={classNames('video-hub-view-by-match__clear-btn', {
                'is-active': activeIndex !== -1
            })}
        >
            <SvgIcon
                icon="close"
                className="icon video-hub-view-by-match__clear-icon"
            />
            {getTranslation('label.clear')}
        </button>
    </>
);

HeaderButton.propTypes = {
    activeIndex: PropTypes.number,
    buttonClickHandler: PropTypes.func
};
