import React from 'react';
import { SkeletonLoader } from 'common/react/components/SkeletonLoader';

const SKELETON_COUNT = 3;

export const BroadcasterSkeleton = () => {
    return (
        <div
            className="broadcast-guide__broadcaster-list"
            style={{ justifyContent: 'center' }}
        >
            {(() => {
                const skeletons = [];

                for (let i = 0; i < SKELETON_COUNT; i++) {
                    skeletons.push(
                        <div
                            key={i}
                            className="broadcast-guide__broadcaster-skeleton"
                        >
                            <p className="broadcast-guide__broadcaster-type">
                                <SkeletonLoader width={80} />
                            </p>
                            <SkeletonLoader height={72} />
                            <p className="broadcast-guide__broadcaster-channel-name">
                                <SkeletonLoader width={80} />
                            </p>
                        </div>
                    );
                }

                return skeletons;
            })()}
        </div>
    );
};
