import React from 'react';
import cx from 'classnames';

import { DefaultCellRendererContainer } from 'custom-table/js/components/DefaultCell';

/*
    This is a simple example of a custom cell renderer, just to show how we could override the default.
    Shows how we can support selecting multiple attributes from the API response into a single table field.
*/

const PlayerCellRenderer = ({ className, value, ...additionalProps }) => {
    const isSorted =
        additionalProps.field.fieldName ===
        additionalProps.customTable.sortBy.fieldName
            ? true
            : false;

    return (
        <DefaultCellRendererContainer
            className={cx(
                className,
                'custom-table__cell--player custom-table__cell--fixed-player',
                {
                    'custom-table__cell--sorted': isSorted
                }
            )}
            {...additionalProps}
        >
            <div className="custom-table__player-name-container">
                <span className="custom-table__player-name">
                    {`${value.givenName}`}{' '}
                    <strong className="custom-table__player-name--surname">{`${value.surname}`}</strong>
                </span>
            </div>
        </DefaultCellRendererContainer>
    );
};

export default PlayerCellRenderer;
