import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { RoundByRound } from '../components/round-by-round';
import { configureReduxQuerySyncRbr } from '../utils/configure-redux-query-sync-rbr';

const BrownlowRoundByRoundProvider = ({ store, data }) => {
    useEffect(() => {
        configureReduxQuerySyncRbr(store);
    }, []);

    return (
        <Provider store={store}>
            <RoundByRound {...data} />
        </Provider>
    );
};

BrownlowRoundByRoundProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};

export default BrownlowRoundByRoundProvider;
