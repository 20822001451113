import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { PerformanceChart } from '../components/performance-chart';

export const ComparisonPerformanceChartProvider = ({ store, data }) => (
    <Provider store={store}>
        <PerformanceChart {...data} type="comparison" />
    </Provider>
);

ComparisonPerformanceChartProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
