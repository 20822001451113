import { createSelector } from 'reselect';
import { statComparisonNavSelector } from '.';

/**
 * @returns {( object ) => object|null} Season.
 */
export const seasonSelector = createSelector(
    statComparisonNavSelector,
    (state) => state.seasons,
    (nav, seasons) => {
        try {
            return seasons[`competition_${nav.competition.id}`][
                `season_${nav.season.id}`
            ];
        } catch (err) {
            return null;
        }
    }
);
