import produce from 'immer';

// @ts-ignore
import { LeaderboardState } from '../types';
// eslint-disable-next-line no-unused-vars
import { Action } from '../../../../types';
import {
    SET_NAV_ITEM,
    SET_BETTING_TOGGLE,
    SET_LEADERBOARD_DATA,
    SET_LEADERBOARD_FILTER,
    SET_FAVOURITE_PLAYER
} from '../actions';
import { setNavItem } from '../../shared/reducer';
import {} from './';
import { setLeaderboardData } from './set-leaderboard-data';
import { setLeaderboardFilter } from './set-leaderboard-filter';
import { setBettingToggle } from './set-betting-toggle';
import { setFavouritePlayer } from './set-favourite-player';

const localFavourites = JSON.parse(
    localStorage.getItem('brownlow-predictor-favourites')
);

/**
 * @type {LeaderboardState}
 */
const initialState = {
    nav: {
        competition: {
            id: null
        },
        season: {
            id: null
        },
        team: {
            id: -1
        },
        showPredicted: false
    },
    favourites: localFavourites ? localFavourites : [],
    bettingToggle: {
        isActive: false
    },
    requests: [],
    data: {},
    filter: {
        query: null
    }
};

/**
 * @param {LeaderboardState} state - The Redux state.
 * @param {Action} action - The Redux action.
 * @returns {LeaderboardState} The updated Redux state.
 */
export const leaderboardReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_LEADERBOARD_DATA:
                setLeaderboardData(draft, action.payload);
                break;
            case SET_LEADERBOARD_FILTER:
                setLeaderboardFilter(draft, action.payload);
                break;
            case SET_NAV_ITEM:
                setNavItem(draft, action.payload);
                break;
            case SET_BETTING_TOGGLE:
                setBettingToggle(draft, action.payload);
                break;
            case SET_FAVOURITE_PLAYER:
                setFavouritePlayer(draft, action.payload);
                break;
            default:
                draft;
        }
    });
};
