import React from 'react';
import PropTypes from 'prop-types';

import { MatchCardRegular } from './match-card-types/MatchCardRegular';
import { MatchCardFinal } from './match-card-types/MatchCardFinal';
import { MatchCardByes } from './match-card-types/MatchCardByes';

export const Match = ({ match, byes, isFinals }) => {
    return (
        <>
            {match && !isFinals && (
                <MatchCardRegular matchInfo={match}></MatchCardRegular>
            )}
            {match && isFinals && (
                <MatchCardFinal matchInfo={match}></MatchCardFinal>
            )}
            {byes && <MatchCardByes byes={byes}></MatchCardByes>}
        </>
    );
};

Match.propTypes = {
    match: PropTypes.object,
    byes: PropTypes.array,
    isFinals: PropTypes.bool
};
