/**
 * @param {any} draft - Draft state.
 * @param {any} payload - Action payload.
 */
export const setBettingToggle = (draft, payload) => {
    draft.bettingToggle = {
        ...draft.bettingToggle,
        ...payload
    };
};
