import React from 'react';
import cx from 'classnames';

import DefaultCellRendererContainer from 'custom-table/js/components/DefaultCell/DefaultCellRendererContainer';

/*
    ClubCellRenderer

    Custom cell renderer for club cell using club name and logo
*/
const ClubCellRenderer = ({ className, value, ...additionalProps }) => {
    if (!value.teamName) {
        return (
            <DefaultCellRendererContainer
                className={cx(className, 'custom-table__cell--club')}
                {...additionalProps}
            >
                <span> - </span>
            </DefaultCellRendererContainer>
        );
    }

    const isSorted =
        additionalProps.field.fieldName ===
        additionalProps.customTable.sortBy.fieldName
            ? true
            : false;

    return (
        <DefaultCellRendererContainer
            className={cx(className, 'custom-table__cell--club', {
                'custom-table__cell--sorted': isSorted
            })}
            {...additionalProps}
        >
            <span>{value.teamName}</span>
        </DefaultCellRendererContainer>
    );
};

export default ClubCellRenderer;
