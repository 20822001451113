// eslint-disable-next-line no-unused-vars
import { PlayerStatsState } from '../../types';
import '../../types';

/**
 * @param {PlayerStatsState} draft - Draft state.
 * @param {object} payload - Action payload.
 * @param payload.data
 * @param payload.isOpen
 */
export const setModal = (draft, { isOpen }) => {
    draft.modal = {
        isOpen: isOpen ?? false
    };
};
