import React from 'react';
import PropTypes from 'prop-types';
import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { BenchmarkingExplainer } from 'common/react/components/benchmarking';
import { KeyStatsAndPerformanceMain } from './components/KeyStatsAndPerformanceMain';
import {
    getTranslation,
    lookupTranslation
} from 'common/react/utils/translations';
import { EmptyState } from 'common/react/components/EmptyState';
import { Loader } from 'common/react/components/Loader';

import { useGetPlayerProfileQuery } from 'store/modules/api/statsPro-api';
import {
    useGetPlayerBestSeasonQuery,
    useGetPlayerBestCareerQuery
} from 'store/modules/api/cfs-api';
import { useGetCompSeasonQuery } from 'common/store/modules/api/afl-api';

export const KeyStatsAndPerformance = ({
    compseasonId,
    playerProviderId,
    showBenchmarkingAflw
}) => {
    const isAFLW = PULSE.app.common.url.checkBaseRoute('aflw');

    const { data: compData } = useGetCompSeasonQuery(
        {
            id: compseasonId
        },
        { skip: !compseasonId }
    );

    const showBenchmarkingAFLWBool = showBenchmarkingAflw === 'true';
    const showBenchmarking = !isAFLW || (isAFLW && showBenchmarkingAFLWBool);

    const {
        data: playerData,
        isFetching,
        isError
    } = useGetPlayerProfileQuery(
        {
            seasonId: compData?.providerId,
            playerId: playerProviderId
        },
        {
            skip: !compData?.providerId || !playerProviderId
        }
    );

    // Fetch top season performance data
    const { data: topSeasonData } = useGetPlayerBestSeasonQuery({
        playerId: playerProviderId,
        competitionId: compData?.providerId
    });

    // Fetch top career performance data
    const { data: topCareerData } = useGetPlayerBestCareerQuery({
        playerId: playerProviderId,
        competitionId: compData?.competition.providerId
    });

    const statsYear = compData?.providerId.match(/S(\d{4})/)[1];

    return (
        <StatsSection.Main>
            <StatsSection.Content>
                <StatsSection.ContentHeader>
                    <StatsSection.ContentHeaderHeading>
                        <div className="key-stats-and-performance__header-text-wrapper">
                            <strong>
                                {playerData?.seasonTotals?.matchesPlayed ?? '-'}{' '}
                                {getTranslation('label.stats.matches')}
                            </strong>{' '}
                            {lookupTranslation(
                                'label.statsAndPerformance.forSeason',
                                {
                                    year: statsYear
                                }
                            )}
                        </div>
                        <span className="key-stats-and-performance__header-text-divider"></span>
                        <div className="key-stats-and-performance__header-text-wrapper">
                            <strong>
                                {playerData?.careerTotals?.matchesPlayed ?? '-'}{' '}
                                {getTranslation('label.stats.matches')}
                            </strong>{' '}
                            {getTranslation('label.stats.inCareer')}
                        </div>
                    </StatsSection.ContentHeaderHeading>
                    <StatsSection.ContentHeaderOptions>
                        {showBenchmarking ? <BenchmarkingExplainer /> : null}
                    </StatsSection.ContentHeaderOptions>
                </StatsSection.ContentHeader>
                <StatsSection.ContentBody>
                    {isFetching || isError ? (
                        <div className="key-stats-and-performance__loader">
                            <Loader />
                        </div>
                    ) : (
                        <>
                            {playerData?.seasonAverages &&
                            playerData?.careerAverages ? (
                                <KeyStatsAndPerformanceMain
                                    playerData={playerData}
                                    topSeasonData={topSeasonData}
                                    topCareerData={topCareerData}
                                    showBenchmarking={showBenchmarking}
                                />
                            ) : (
                                <div className="key-stats-and-performance__empty-state">
                                    <EmptyState />
                                </div>
                            )}
                        </>
                    )}
                </StatsSection.ContentBody>
            </StatsSection.Content>
        </StatsSection.Main>
    );
};

KeyStatsAndPerformance.propTypes = {
    compseasonId: PropTypes.string,
    playerProviderId: PropTypes.string.isRequired,
    showBenchmarkingAflw: PropTypes.string
};
