/**
 * Take a match model from either CFS or a Pulse API and model it to work in the
 * Match Card component.
 *
 * @param {object} match A match model from either the CFS or pulse apis
 * @returns {object} The formatted match model
 */
export function useMatchModel(match) {
    // Pulselive API Match model
    if (match?.id) {
        return {
            home: {
                abbr: match.home.team.abbreviation,
                name: match.home.team.name,
                totalScore: match.home?.score?.totalScore
            },
            away: {
                abbr: match.away.team.abbreviation,
                name: match.away.team.name,
                totalScore: match.away?.score?.totalScore
            }
        };
        // Else is it a CFS Match model?
    } else if (match?.match) {
        return {
            home: {
                abbr: match.match.homeTeam.abbr,
                name: match.match.homeTeam.name,
                totalScore: match.score?.homeTeamScore?.matchScore?.totalScore
            },
            away: {
                abbr: match.match.awayTeam.abbr,
                name: match.match.awayTeam.name,
                totalScore: match.score?.awayTeamScore?.matchScore?.totalScore
            }
        };
    }

    return null;
}
