/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';

import { TableCaption } from './TableCaption';
import { TableBody } from './TableBody';
import { useTableSortData } from '../hooks';
import { TableHead } from './TableHead';
import { UPDATE_SORT } from 'store/modules/match-centre/player-stats/actions';
import { playerStatsSortSelector } from 'store/modules/match-centre/player-stats/selectors';
import { StatsTable } from 'widgets/statspro/js/components/common/stats-table/StatsTable';
import { FIRST_SORTABLE_COLUMN_INDEX } from '../config/columns';
import { ManageFieldsBar } from './ManageFieldsBar';

const PAGE_SIZE = 20;
export const Table = ({ columns, data, modifier, cssClass, compPid }) => {
    const dispatch = useDispatch();
    const sort = useSelector(playerStatsSortSelector);
    const stickyPageNav = document.querySelector('.js-match-centre-tabs');
    const stickyMainNav = document.querySelector('.js-main-navigation');
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data,
                initialState: {
                    // @ts-ignore
                    pageSize: PAGE_SIZE,
                    sortBy: [
                        {
                            id:
                                sort.columnId ??
                                columns[FIRST_SORTABLE_COLUMN_INDEX].accessor,
                            desc: sort.sortDirection === 'descending'
                        }
                    ]
                },
                disableSortRemove: true
            },
            useFilters,
            useSortBy,
            usePagination
        );

    const { headerGroup, sortedColumn, sortDirection } =
        useTableSortData(headerGroups);

    // ===== Effects ======================================================== //

    useEffect(() => {
        if (sortedColumn) {
            dispatch(
                UPDATE_SORT.request({
                    columnId: sortedColumn.id,
                    sortDirection,
                    force: false
                })
            );
        } else if (sort.force) {
            dispatch(
                UPDATE_SORT.request({
                    force: false
                })
            );
        }
    }, [sortedColumn, sortDirection]);

    // ===== Render ========================================================= //

    return (
        <>
            <StatsTable
                modifier={modifier}
                getTableProps={getTableProps}
                cssClass={cssClass}
                stickyHeader={<TableHead headerGroup={headerGroup} />}
                stickyPageNav={stickyPageNav}
                stickyMainNav={stickyMainNav}
            >
                <TableCaption
                    sortedColumnId={sortedColumn.id}
                    sortDirection={sortDirection}
                />
                <TableHead headerGroup={headerGroup} />
                <TableBody
                    rows={rows}
                    prepareRow={prepareRow}
                    getTableBodyProps={getTableBodyProps}
                    compPid={compPid}
                />
            </StatsTable>
            <ManageFieldsBar />
        </>
    );
};

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    modifier: PropTypes.string,
    cssClass: PropTypes.string,
    compPid: PropTypes.string
};
