// eslint-disable-next-line no-unused-vars
import { RoundByRoundState } from '../types';
// eslint-disable-next-line no-unused-vars
import { ReduxState } from '../../../../types';

/**
 * @param {ReduxState} state - The Redux state.
 * @returns {RoundByRoundState} The Stats Leaders state.
 */
export const roundByRoundSelector = (state) =>
    state.brownlowTracker.roundByRound;
