import React from 'react';
import PropTypes from 'prop-types';
import { TeamIcon } from 'common/react/components/TeamIcon';

export const StatsLeadersOpponentCell = ({ opponent, ...cellProps }) => {
    return (
        <td
            {...cellProps}
            className="stats-table__cell stats-table__cell--opponent"
        >
            <div className="stats-leaders-table-opponent">
                <TeamIcon providerId={opponent?.teamId} />
                <span className="stats-leaders-table-opponent__name">
                    {PULSE.app.common.team.getTeamAbbr(opponent?.teamId)}
                </span>
            </div>
        </td>
    );
};

StatsLeadersOpponentCell.propTypes = {
    opponent: PropTypes.object.isRequired
};
