import React from 'react';
import PropTypes from 'prop-types';

import { TeamIcon } from 'common/react/components/TeamIcon';
import { MatchStatus } from '../MatchStatus';

export const MatchCardByes = ({ byes }) => {
    return (
        <a href="#" className="fb-match" aria-label="byes">
            <div className={'fb-match__row'}>
                <div className="fb-match__byes-container">
                    {byes.map((bye, index) => {
                        return (
                            <span
                                className="fb-match__logo"
                                title={bye.name}
                                key={index}
                            >
                                <TeamIcon
                                    className="fb-match__logo-icon fb-match__logo-icon--bye"
                                    providerId={bye.providerId}
                                />
                            </span>
                        );
                    })}
                </div>
            </div>
            <MatchStatus byes={byes}></MatchStatus>
        </a>
    );
};

MatchCardByes.propTypes = {
    byes: PropTypes.array.isRequired
};
