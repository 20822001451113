import React from 'react';
import PropTypes from 'prop-types';

export const MatchCardElement = ({ linkTo, playerModalEnabled, children }) => {
    const hasLink =
        !playerModalEnabled && typeof linkTo !== 'undefined' && linkTo !== '';

    return hasLink ? (
        <a href={linkTo} className="brownlow-match-card__card-link">
            {children}
        </a>
    ) : (
        <>{children}</>
    );
};

MatchCardElement.propTypes = {
    linkTo: PropTypes.string,
    playerModalEnabled: PropTypes.bool,
    children: PropTypes.node.isRequired
};
