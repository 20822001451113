import React from 'react';
import { useDispatch } from 'react-redux';

import { SwitchInput } from 'common/react/components/SwitchInput';
import { getTranslation } from 'common/react/utils/translations';
import { UPDATE_NAV_ITEM } from 'common/store/modules/brownlow-tracker/leaderboard/actions';

export const ShowPredicted = () => {
    const dispatch = useDispatch();

    return (
        <div className="brownlow-leaderboard__show-predicted-toggle">
            <SwitchInput
                id={getTranslation('label.showPredicted')}
                label={getTranslation('label.showPredicted')}
                isChecked={false}
                onChange={(inputValue) => {
                    dispatch(
                        UPDATE_NAV_ITEM.request({
                            navItem: 'showPredicted',
                            value: inputValue
                        })
                    );
                }}
            />
        </div>
    );
};
