import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    UPDATE_DATA_TYPE,
    UPDATE_NAV_ITEM
} from 'store/modules/statspro/stats-leaders/actions';
import {
    statsLeadersBenchmarkingSelector,
    statsLeadersDataTypeSelector,
    statsLeadersNavSelector
} from 'store/modules/statspro/stats-leaders/selectors';
import { useDataType } from '../../common/hooks/use-data-type';

export const useStatsLeadersNavSync = (
    { competitions, seasons, rounds },
    competitionId,
    compseasonId
) => {
    const dispatch = useDispatch();
    const nav = useSelector(statsLeadersNavSelector);
    const benchmarking = useSelector(statsLeadersBenchmarkingSelector);
    const initialDataType = useSelector(statsLeadersDataTypeSelector);

    // eslint-disable-next-line no-unused-vars
    const { dataType, updateDataType } = useDataType({
        initialDataType,
        onUpdateActionCreator: UPDATE_DATA_TYPE.request,
        isBenchmarkingActive: benchmarking.isActive
    });

    /**
     * Ensure stats leaders nav data is correct.
     */
    useEffect(() => {
        if (competitionId && !nav.competition.id) {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'competition',
                    value: { id: competitionId }
                })
            );
        }

        if (compseasonId && !nav.season.id) {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'season',
                    value: { id: compseasonId }
                })
            );
        } else if (seasons && !nav.season.id) {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'season',
                    value: { id: seasons[0].id }
                })
            );
        }

        if (rounds && nav.round.id === null) {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'round',
                    value: {
                        id: -1,
                        number: 0
                    }
                })
            );
        }
    }, [competitions, seasons, rounds, competitionId]);

    useEffect(() => {
        if (
            !rounds ||
            !seasons ||
            nav.round.id === null ||
            nav.round.id === -1
        ) {
            return;
        }

        // If rounds is empty, reset to Entire season
        if (!rounds?.length) {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'round',
                    value: {
                        id: -1,
                        number: 0
                    }
                })
            );
        } else if (!rounds.find((r) => r.id === nav.round.id)) {
            // reset Data Type to Totals
            updateDataType('totals');

            // update round with round number
            const targetRound =
                rounds.find((r) => r.roundNumber === nav.round.number) ??
                rounds[0];

            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'round',
                    value: {
                        id: targetRound.id,
                        number: targetRound.roundNumber
                    }
                })
            );
        } else {
            // reset Data Type to Totals for rounds data
            updateDataType('totals');
        }
    }, [nav.round, rounds, seasons]);
};
