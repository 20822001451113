import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param props.children
 * @param props.cssClass
 * @returns {JSX.Element} Component.
 */
export const StatsSectionContentHeaderHeading = ({ children, cssClass }) => (
    <>
        {children && (
            <h3
                className={`stats-section-content-header__heading ${
                    cssClass ?? ''
                }`}
            >
                {children}
            </h3>
        )}
    </>
);

StatsSectionContentHeaderHeading.defaultProps = {
    name: 'StatsSectionContentHeaderHeading'
};

StatsSectionContentHeaderHeading.propTypes = {
    children: PropTypes.any.isRequired,
    cssClass: PropTypes.string
};
