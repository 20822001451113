import { getTranslation } from 'common/react/utils/translations';

// const EXCEPTIONS = {};

/**
 * 1. If we have a manual exception for this ID, use it.
 * 2. If the word ends in an 's' we assume it's a plural version of the singular
 *    word which will is used for the glossary, so we drop the 's' to make it
 *    singular. Of course this doesn't work for all words, which is why any that
 *    don't match this pattern should make use of the `EXCEPTIONS` object to
 *    define the singular manually.
 * 3. If all else fails just return the original ID and hope for the best.
 *
 * @param {string} columnId - Column ID.
 * @returns {string} Glossary ID.
 */
const getGlossaryIdFromColumnId = (columnId) => {
    // [1]
    // const exception = EXCEPTIONS[ columnId ];
    // if ( exception ) { return exception; }

    // [2]
    const indexOfLastChar = columnId.length - 1;
    if (columnId[indexOfLastChar] === 's') {
        return columnId.substring(0, indexOfLastChar);
    }

    // [3]
    return columnId;
};

/**
 * 1. Return the override definition if available. These override definitions
 *    can be used as and when the client needs different text for stats leaders
 *    vs the glossary.
 * 2. Return the glossary definition.
 *
 * @param {string} columnId - Column ID.
 * @returns {string} Glossary definition.
 */
export const getGlossaryDefinitionFromColumnId = (columnId) => {
    // [1]
    const overrideDefinition = getTranslation(
        `label.statsLeaders.${columnId}.description`
    );

    if (overrideDefinition) {
        return overrideDefinition;
    }

    // [2]
    return getTranslation(
        'label.statspro.glossary.description.' +
            getGlossaryIdFromColumnId(columnId)
    );
};
