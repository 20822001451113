import { all, takeEvery } from 'redux-saga/effects';
import {
    UPDATE_NAV_ITEM,
    UPDATE_FILTER_ITEM,
    UPDATE_BETTING_ODDS,
    UPDATE_LIVE
} from '../actions';
import { updateNavItem } from './update-nav-item';
import { updateFilterItem } from './update-filter-item';
import { updateBettingOdds } from './update-betting-odds';
import { updateLive } from './update-live';

/**
 *
 */
export function* navRootSaga() {
    yield all([
        takeEvery(UPDATE_NAV_ITEM.REQUEST, updateNavItem),
        takeEvery(UPDATE_FILTER_ITEM.REQUEST, updateFilterItem),
        takeEvery(UPDATE_BETTING_ODDS.REQUEST, updateBettingOdds),
        takeEvery(UPDATE_LIVE.REQUEST, updateLive)
    ]);
}
