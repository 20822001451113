import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';

import {
    RoundListNavItem,
    SeasonNavItem,
    CompNavItem,
    ResponsiveSeasonNavItem
} from 'common/react/components/competition-nav/nav-items';

import {
    TeamFilterItem,
    VenueFilterItem,
    CityFilterItem
} from 'common/react/components/competition-nav/filter-items';

import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import { useNavSync } from 'common/react/components/competition-nav/hooks/use-nav-sync';
import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { useGetCurrentCompFilters } from 'common/react/components/competition-nav/hooks/use-get-current-comp-filters';
import { SettingsButton } from 'common/react/components/SettingsButton';

export const FixturesNav = ({
    competitionId,
    seasonId,
    competitionProviderId,
    seasonPid,
    onRoundClick
}) => {
    const windowSize = useWindowSize();

    const isResponsive = () =>
        windowSize.width < PULSE.app.measurements.desktop;
    const [showResponsiveItems, setShowResponsiveItems] = useState(
        isResponsive()
    );

    useEffect(() => {
        setShowResponsiveItems(isResponsive());
    }, [windowSize.width]);

    const initialValues = {
        competitionId,
        seasonId,
        competitionProviderId,
        seasonPid
    };

    const nav = useSelector(navSelector);
    const options = useCompetitionNavigationOptions(nav);

    useNavSync({ ...options, initialValues });

    const competitions = options.competitions;

    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );

    const currentFilters = useGetCurrentCompFilters();

    return (
        <div className="competition-nav">
            <StatsSection.Main>
                <StatsSection.Nav>
                    <StatsSection.NavItems>
                        <CompNavItem competitions={competitions} />
                        {!showResponsiveItems ? (
                            <SeasonNavItem
                                seasons={seasons}
                                classes={'u-margin-right-auto'}
                            />
                        ) : null}
                    </StatsSection.NavItems>
                    {currentFilters.competitionId &&
                    currentFilters.compSeason?.id ? (
                        <StatsSection.NavFilterItems>
                            {showResponsiveItems ? (
                                <ResponsiveSeasonNavItem seasons={seasons} />
                            ) : null}

                            <TeamFilterItem currentFilters={currentFilters} />
                            <VenueFilterItem currentFilters={currentFilters} />
                            <CityFilterItem currentFilters={currentFilters} />
                        </StatsSection.NavFilterItems>
                    ) : null}
                    <StatsSection.SettingsButton>
                        <SettingsButton />
                    </StatsSection.SettingsButton>
                    <StatsSection.RoundButtons>
                        <RoundListNavItem
                            classes={'competition-nav__round-filter-wrapper'}
                            onRoundClick={onRoundClick}
                        />
                    </StatsSection.RoundButtons>
                </StatsSection.Nav>
            </StatsSection.Main>
        </div>
    );
};

FixturesNav.propTypes = {
    competitionId: PropTypes.string,
    competitionProviderId: PropTypes.string,
    seasonPid: PropTypes.string,
    seasonId: PropTypes.string,
    onRoundClick: PropTypes.func
};
