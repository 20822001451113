import React from 'react';
import PropTypes from 'prop-types';
import { useGetLiveLadderQuery } from 'store/modules/api/cfs-api';
import { LadderTableFetcher } from './LadderTableFetcher';

export const LiveLadderDataFetcher = ({ competitionId, compSeason, round }) => {
    // Fetch the liveLadderData using rtk query
    const { data: liveLadderData } = useGetLiveLadderQuery(
        {
            roundId: round?.providerId || ''
        },
        { pollingInterval: PULSE.app.common.CONSTANTS.TIME.THIRTY_SECS_IN_MS }
    );

    // ensure the ladder actually has live data
    const isLive =
        liveLadderData?.liveLadderPositions?.find((item) => item?.playing) ||
        false;

    // if it does then we can render
    const liveLadder =
        liveLadderData?.liveLadderPositions && isLive ? liveLadderData : false;

    // ===== Render ==== //

    return (
        <LadderTableFetcher
            competitionId={competitionId}
            compSeason={compSeason}
            round={round}
            liveLadderData={liveLadder}
        />
    );
};

LiveLadderDataFetcher.propTypes = {
    competitionId: PropTypes.number,
    compSeason: PropTypes.object,
    round: PropTypes.object
};
