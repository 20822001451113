import React from 'react';
import PropTypes from 'prop-types';

import { getTranslation } from 'common/react/utils/translations';

export const ShareCopiedPopup = ({ showMessage }) => {
    return (
        <>
            {showMessage && (
                <div className="events-listing__clipboard-message">
                    {getTranslation('label.urlcopied')}
                </div>
            )}
        </>
    );
};

ShareCopiedPopup.propTypes = {
    showMessage: PropTypes.bool
};
