import React from 'react';
import PropTypes from 'prop-types';

import { Details } from './Details';

export const AddPlayerButton = ({ playerNumber }) => {
    const textLabel = PULSE.I18N.lookup('label.statsCompare.addPlayer', {
        playerNumber
    });

    return (
        <Details
            cssClass="compare-header__player-add"
            floatingIcon="add-action"
        >
            <span className="compare-header__player-add-label">
                {textLabel}
            </span>
        </Details>
    );
};

AddPlayerButton.propTypes = {
    playerNumber: PropTypes.string.isRequired
};
