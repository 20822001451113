import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { InformationLocationPanel } from '../components/InformationLocationPanel';

export const InformationLocationPanelProvider = ({ store, data }) => {
    return (
        <Provider store={store}>
            <InformationLocationPanel {...data} />
        </Provider>
    );
};

InformationLocationPanelProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
