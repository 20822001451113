import React from 'react';
import PropTypes from 'prop-types';

import { TeamIcon } from 'common/react/components/TeamIcon';
import { FixturesRoundHeader } from './FixturesRoundHeader';
import { getTranslation } from 'common/react/utils/translations';

export const FilteredByesGroup = ({ teams, roundName }) => {
    return (
        <>
            {teams.length ? (
                <div className="fixtures__fixture-byes-group">
                    <div>
                        <h2 className="fixtures__date-header">
                            {getTranslation('label.fixtures.byeRound')}
                        </h2>
                        <FixturesRoundHeader roundLabel={roundName} />
                    </div>
                    {teams.map((team) => (
                        <TeamIcon
                            key={team.id}
                            className="fixtures__bye-fixture"
                            providerId={team.providerId}
                        />
                    ))}
                </div>
            ) : null}
        </>
    );
};

FilteredByesGroup.propTypes = {
    teams: PropTypes.array,
    roundName: PropTypes.string
};
