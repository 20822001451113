import produce from 'immer';

// eslint-disable-next-line no-unused-vars
import { MatchByMatchState } from '../types';
// eslint-disable-next-line no-unused-vars
import { Action } from '../../../../types';
import {
    REMOVE_MATCH_BY_MATCH_DATA_REQUEST,
    SET_MATCH_BY_MATCH_DATA,
    SET_MATCH_BY_MATCH_DATA_REQUEST
} from '../actions';
import { setMatchByMatchData } from './set-match-by-match-data';
import { removeRequest, setRequest } from '../../shared/reducer';

/**
 * @type {MatchByMatchState}
 */
const initialState = {
    requests: []
};

/**
 * @param {MatchByMatchState} state - The Redux state.
 * @param {Action} action - The Redux action.
 * @returns {MatchByMatchState} The updated Redux state.
 */
export const matchByMatchReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_MATCH_BY_MATCH_DATA:
                setMatchByMatchData(draft, action.payload);
                break;
            case SET_MATCH_BY_MATCH_DATA_REQUEST:
                setRequest(draft, action.payload);
                break;
            case REMOVE_MATCH_BY_MATCH_DATA_REQUEST:
                removeRequest(draft, action.payload);
                break;
            default:
                draft;
        }
    });
};
