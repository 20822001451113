import React from 'react';
import PropTypes from 'prop-types';

import { getPositionNameByKey } from '../../stats-leaders/utils/positions';
import { getTranslation } from 'common/react/utils/translations';
import { OverviewTableCell } from './OverviewTableCell';
import { OverviewTableRow } from './OverviewTableRow';
import { OverviewTableDraftCell } from './OverviewTableDraftCell';
import { OverviewTableLinkCell } from './OverviewTableLinkCell';

import { useGetPlayerProfileQuery } from 'store/modules/api/statsPro-api';

export const OverviewTable = ({ seasonId, playerIds, isAFLW }) => {
    const player1Id = playerIds[1];
    const player2Id = playerIds[2];

    const passValueIfPlayerSelected = (selectedPlayer, playerDataId, value) =>
        selectedPlayer && selectedPlayer === playerDataId ? value : null;

    const { data: playerOne } = useGetPlayerProfileQuery(
        { seasonId, playerId: player1Id },
        { skip: !player1Id }
    );
    const { data: playerTwo } = useGetPlayerProfileQuery(
        { seasonId, playerId: player2Id },
        { skip: !player2Id }
    );

    const showPlayerWeight =
        playerOne?.playerDetails?.weightKg && playerTwo?.playerDetails?.weightKg
            ? true
            : false;

    return (
        <div role="table" className="overview-table">
            <OverviewTableRow>
                <OverviewTableCell
                    value={passValueIfPlayerSelected(
                        player1Id,
                        playerOne?.playerId,
                        playerOne?.playerDetails?.position
                    )}
                    displayFunction={getPositionNameByKey}
                />
                <div role="rowheader" className="overview-table__header">
                    {getTranslation('label.statsCompare.position')}
                </div>
                <OverviewTableCell
                    value={passValueIfPlayerSelected(
                        player2Id,
                        playerTwo?.playerId,
                        playerTwo?.playerDetails?.position
                    )}
                    displayFunction={getPositionNameByKey}
                />
            </OverviewTableRow>
            <OverviewTableRow>
                <OverviewTableCell
                    value={passValueIfPlayerSelected(
                        player1Id,
                        playerOne?.playerId,
                        playerOne?.playerDetails?.age
                    )}
                />
                <div role="rowheader" className="overview-table__header">
                    {getTranslation('label.statsCompare.age')}
                </div>
                <OverviewTableCell
                    value={passValueIfPlayerSelected(
                        player2Id,
                        playerTwo?.playerId,
                        playerTwo?.playerDetails?.age
                    )}
                />
            </OverviewTableRow>
            <OverviewTableRow>
                <OverviewTableCell
                    value={passValueIfPlayerSelected(
                        player1Id,
                        playerOne?.playerId,
                        playerOne?.playerDetails?.heightCm
                    )}
                    suffix={'cm'}
                />
                <div role="rowheader" className="overview-table__header">
                    {getTranslation('label.statsCompare.height')}
                </div>
                <OverviewTableCell
                    value={passValueIfPlayerSelected(
                        player2Id,
                        playerTwo?.playerId,
                        playerTwo?.playerDetails?.heightCm
                    )}
                    suffix={'cm'}
                />
            </OverviewTableRow>

            {showPlayerWeight && (
                <OverviewTableRow>
                    <OverviewTableCell
                        value={passValueIfPlayerSelected(
                            player1Id,
                            playerOne?.playerId,
                            playerOne?.playerDetails?.weightKg
                        )}
                        suffix={'kg'}
                    />
                    <div role="rowheader" className="overview-table__header">
                        {getTranslation('label.statsCompare.weight')}
                    </div>
                    <OverviewTableCell
                        value={passValueIfPlayerSelected(
                            player2Id,
                            playerTwo?.playerId,
                            playerTwo?.playerDetails?.weightKg
                        )}
                        suffix={'kg'}
                    />
                </OverviewTableRow>
            )}

            <OverviewTableRow>
                <OverviewTableCell
                    value={passValueIfPlayerSelected(
                        player1Id,
                        playerOne?.playerId,
                        playerOne?.careerTotals?.matchesPlayed
                    )}
                />
                <div role="rowheader" className="overview-table__header">
                    <span className="u-hide-until-tablet">
                        {getTranslation('label.statsCompare.totalGames')}
                    </span>
                    <span className="u-hide-from-tablet">
                        {getTranslation('label.statsCompare.totalGames.short')}
                    </span>
                </div>
                <OverviewTableCell
                    value={passValueIfPlayerSelected(
                        player2Id,
                        playerTwo?.playerId,
                        playerTwo?.careerTotals?.matchesPlayed
                    )}
                />
            </OverviewTableRow>
            <OverviewTableRow>
                <OverviewTableCell
                    value={passValueIfPlayerSelected(
                        player1Id,
                        playerOne?.playerId,
                        playerOne?.playerDetails?.debutYear
                    )}
                />
                <div role="rowheader" className="overview-table__header">
                    {getTranslation('label.statsCompare.debut')}
                </div>
                <OverviewTableCell
                    value={passValueIfPlayerSelected(
                        player2Id,
                        playerTwo?.playerId,
                        playerTwo?.playerDetails?.debutYear
                    )}
                />
            </OverviewTableRow>
            <OverviewTableRow>
                <OverviewTableDraftCell
                    player={
                        player1Id && player1Id === playerOne?.playerId
                            ? playerOne
                            : null
                    }
                />
                <div role="rowheader" className="overview-table__header">
                    {getTranslation('label.statsCompare.draft')}
                </div>
                <OverviewTableDraftCell
                    player={
                        player2Id && player2Id === playerTwo?.playerId
                            ? playerTwo
                            : null
                    }
                />
            </OverviewTableRow>
            <OverviewTableRow>
                <OverviewTableLinkCell
                    player={
                        player1Id && player1Id === playerOne?.playerId
                            ? playerOne
                            : null
                    }
                    isAFLW={isAFLW}
                />
                <OverviewTableLinkCell
                    player={
                        player2Id && player2Id === playerTwo?.playerId
                            ? playerTwo
                            : null
                    }
                    isAFLW={isAFLW}
                />
            </OverviewTableRow>
        </div>
    );
};

OverviewTable.propTypes = {
    seasonId: PropTypes.string.isRequired,
    playerIds: PropTypes.object.isRequired,
    isAFLW: PropTypes.bool
};
