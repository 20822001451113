import { createSelector } from 'reselect';
import { leaderboardSelector } from './simple-selectors';

export const leaderboardFavouriteSelector = createSelector(
    leaderboardSelector,
    (leaderboard) => leaderboard.favourites
);

/**
 * Returns boolean if player has been favourited
 *
 * @param {ReduxState} state - The Redux state.
 * @param {string} playerId - The player id of the player
 * @returns {boolean} - returns boolean if player favourited
 */
export const isFavouritePlayerSelector = (state, playerId) => {
    const favourites = state.brownlowTracker.leaderboard?.favourites;

    if (!favourites) {
        return false;
    }

    return favourites.some((player) => player.providerId === playerId);
};
