import reduxQuerySync from 'redux-query-sync';

import { UPDATE_NAV_ITEM } from 'store/modules/brownlow-tracker/round-by-round/actions';
import { roundByRoundNavSelector } from 'store/modules/brownlow-tracker/round-by-round/selectors';

export const configureReduxQuerySyncRbr = (store) => {
    return reduxQuerySync({
        store,
        params: {
            rbrSeasonId: {
                selector: (state) => roundByRoundNavSelector(state)?.season?.id,
                action: (seasonId) => {
                    return UPDATE_NAV_ITEM.request({
                        navItem: 'season',
                        value: {
                            id: seasonId
                        }
                    });
                },
                stringToValue: (seasonId) => Number(seasonId)
            },
            rbrSeasonPid: {
                selector: (state) =>
                    roundByRoundNavSelector(state)?.season?.providerId,
                action: (seasonPid) => {
                    return UPDATE_NAV_ITEM.request({
                        navItem: 'season',
                        value: {
                            providerId: seasonPid
                        }
                    });
                }
            },
            rbrRound: {
                selector: (state) => roundByRoundNavSelector(state)?.round,
                action: (round) => {
                    return UPDATE_NAV_ITEM.request({
                        navItem: 'round',
                        value: round
                    });
                },
                stringToValue: (round) => Number(round)
            }
        },

        // Initially set the store's state to the current location.
        initialTruth: 'location'
    });
};
