import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getTranslation } from 'common/react/utils/translations';

/**
 * @param {object} props - Props.
 * @param props.children
 * @param props.isFiltersVisibleHandler
 * @param props.setResetFilterBtnStyles
 * @returns {JSX.Element} Component.
 */
export const PlayerSelectionFilterItems = ({
    children,
    isFiltersVisibleHandler,
    setResetFilterBtnStyles
}) => {
    const [resetFns, setResetFns] = useState([]);

    const addOnResetCallback = (resetFn) => {
        setResetFns((fns) => [...fns, resetFn]);
    };

    const resetHandler = (resetFunctions) => {
        setResetFilterBtnStyles(true);
        resetFunctions.forEach((func) => func());
        isFiltersVisibleHandler();
    };

    return (
        <>
            {children && (
                <div className="stats-leaders-filters">
                    {React.Children.map(children, (child) => (
                        <div className="stats-leaders-filters__item-wrapper">
                            {React.cloneElement(child, { addOnResetCallback })}
                        </div>
                    ))}
                    <div className="stats-leaders-filters__item-wrapper stats-leaders-filters__item-wrapper--reset">
                        <button
                            className="stats-leaders-filter-reset-button"
                            onClick={() => resetHandler(resetFns)}
                        >
                            {getTranslation(
                                'label.statsLeaders.filters.resetButtonText'
                            )}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

PlayerSelectionFilterItems.defaultProps = {
    name: 'PlayerSelectionFilterItems'
};

PlayerSelectionFilterItems.propTypes = {
    children: PropTypes.any.isRequired,
    isFiltersVisibleHandler: PropTypes.func,
    setResetFilterBtnStyles: PropTypes.func
};
