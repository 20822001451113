import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'common/react/components/select';
import { getTranslation } from 'common/react/utils/translations';
import { statsLeadersNavSelector } from 'store/modules/statspro/stats-leaders/selectors/nav-selector';
import { UPDATE_NAV_ITEM } from 'store/modules/statspro/stats-leaders/actions';

export const SeasonNavItem = () => {
    const dispatch = useDispatch();
    const nav = useSelector(statsLeadersNavSelector);
    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );

    return (
        <Select
            items={seasons}
            itemToString={(season) => season?.name}
            initialSelectedItem={
                seasons.length && nav.season.id
                    ? seasons.find((s) => s.id === nav.season.id)
                    : ''
            }
            activeItem={
                seasons.length && nav.season.id
                    ? seasons.find(
                          (s) => s.id.toString() === nav.season.id.toString()
                      )
                    : ''
            }
            isReadyToRender={() => seasons?.length && nav.season.id}
            label={getTranslation('label.statsLeaders.nav.season')}
            onSelectedItemChange={({ selectedItem }) => {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'season',
                        value: { id: selectedItem.id }
                    })
                );
            }}
        />
    );
};
