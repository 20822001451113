import React from 'react';
import cx from 'classnames';

import DefaultCellRendererContainer from 'custom-table/js/components/DefaultCell/DefaultCellRendererContainer';

const depsLoaded = (resolve) => {
    if (typeof PULSE.app.MatchPopup !== 'undefined') {
        resolve();
    } else {
        document.addEventListener(
            PULSE.app.common.CONSTANTS.MatchPopupWidget.READY,
            resolve
        );
    }
};
const DEPS_LOADED = new Promise(depsLoaded);

/*
    FormCellRenderer
    Custom cell renderer for Form of a team - using the form value ie. 'WWWLD'
*/
const FormCellRenderer = ({ className, value, ...additionalProps }) => {
    if (!value.form || value.form === '-') {
        return (
            <DefaultCellRendererContainer
                className={cx(
                    className,
                    'custom-table__form-item custom-table__form-item--empty'
                )}
                {...additionalProps}
            >
                <span> - </span>
            </DefaultCellRendererContainer>
        );
    }

    const loadingIcon = PULSE.app.templating.render(
        {
            cssClass: ``,
            name: 'loading-dark'
        },
        'common.svg-icon'
    );

    const caretDown = PULSE.app.templating.render(
        {
            cssClass: `caret`,
            name: 'caret-down'
        },
        'common.svg-icon'
    );

    let formValue = value.form.substring(
        value.form.length - 5,
        value.form.length
    );
    let filterOptions = {};

    if (
        additionalProps.customTable &&
        additionalProps.customTable.filterOptions
    ) {
        filterOptions = additionalProps.customTable.filterOptions;
    }

    const formCellMarkup = [...formValue].map((letter, index) => {
        return (
            <li
                key={`${value.teamId}_${letter}_${index}`}
                className={`custom-table__form-item custom-table__form-item--${letter.toLowerCase()} js-ladder-form`}
                data-form-index={value.teamId}
                onMouseOver={(event) => {
                    // match popup loaded
                    DEPS_LOADED.then(() => {
                        if (filterOptions) {
                            new PULSE.app.MatchPopup(event.target, {
                                teamId: value.teamId,
                                formIndex: index,
                                competition: filterOptions.competition,
                                compSeason: filterOptions.compSeason,
                                gameweek: filterOptions.gameweek,
                                byes: [],
                                scrollBoundary:
                                    event.target.closest('.js-scrolling-area'),
                                isMyLadder: true
                            });
                        }
                    })
                }}
            >
                {letter}
                <div className="match-popup js-match-popup">
                    <div className="js-match-popup-content"></div>
                    <div className="list-loader js-popup-loader">
                        <div className="list-loader__icon">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: loadingIcon
                                }}
                            ></span>
                        </div>
                        {additionalProps.getTranslation('label.loading')}
                    </div>
                    <span
                        dangerouslySetInnerHTML={{ __html: caretDown }}
                    ></span>
                </div>
            </li>
        );
    });

    return (
        <DefaultCellRendererContainer
            className={cx(className, 'custom-table__cell--form')}
            {...additionalProps}
        >
            <ul className="custom-table__form">{formCellMarkup}</ul>
        </DefaultCellRendererContainer>
    );
};

export default FormCellRenderer;
