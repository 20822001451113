/**
 * Given a channel object from the broadcasting api, check all of it's custom
 * attributes to find if it has a `eventType-web` of either 'trade event' or
 * 'trade radio' that marks that channel as a Trade Radio channel.
 *
 * @param {object} channel - A channel object from the broadcasting API
 * @param {string} eventType - The value of the eventType-web we're looking for
 * @returns {boolean} - True if this channel is a trade radio or stream
 */
export function hasMatchingEventType(channel, eventType) {
    return channel?.customAttributes.some(
        (attr) => attr.name === 'eventType-web' && attr.value === eventType
    );
}
