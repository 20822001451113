import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../../setup-react';

// ===== Signals ============================================================ //

// Leaderboard
export const FETCH_LEADERBOARD_DATA = createSignalAction(
    'brownlow/leaderboard',
    'FETCH_LEADERBOARD_DATA'
);
export const UPDATE_LEADERBOARD_FILTER = createSignalAction(
    'brownlow/leaderboard',
    'UPDATE_LEADERBOARD_FILTER'
);

// Nav
export const UPDATE_NAV_ITEM = createSignalAction(
    'brownlow/leaderboard',
    'UPDATE_NAV_ITEM'
);

export const UPDATE_BETTING_TOGGLE = createSignalAction(
    'brownlow/leaderboard',
    'UPDATE_BETTING_TOGGLE'
);

// Search Results
export const FETCH_SEARCH_RESULTS = createSignalAction(
    'brownlow/leaderboard',
    'FETCH_SEARCH_RESULTS'
);

// Favourite
export const UPDATE_FAVOURITE_PLAYER = createSignalAction(
    'brownlow/leaderboard',
    'UPDATE_FAVOURITE_PLAYER'
);

// ===== Deltas ============================================================= //

// Leaderboard
export const SET_LEADERBOARD_DATA = createDeltaActionType(
    'brownlow/leaderboard',
    'SET_LEADERBOARD_DATA'
);
export const setLeaderboardData = createActionCreator(SET_LEADERBOARD_DATA);

export const SET_LEADERBOARD_FILTER = createDeltaActionType(
    'brownlow/leaderboard',
    'SET_LEADERBOARD_FILTER'
);
export const setLeaderboardFilter = createActionCreator(SET_LEADERBOARD_FILTER);

// Leaderboard request tracking
export const SET_LEADERBOARD_DATA_REQUEST = createDeltaActionType(
    'brownlow/leaderboard',
    'SET_LEADERBOARD_DATA_REQUEST'
);
export const setLeaderboardDataRequest = createActionCreator(
    SET_LEADERBOARD_DATA_REQUEST
);

export const REMOVE_LEADERBOARD_DATA_REQUEST = createDeltaActionType(
    'brownlow/leaderboard',
    'REMOVE_LEADERBOARD_REQUEST'
);
export const removeLeaderboardDataRequest = createActionCreator(
    REMOVE_LEADERBOARD_DATA_REQUEST
);

// Nav
export const SET_NAV_ITEM = createDeltaActionType(
    'brownlow/leaderboard',
    'SET_NAV_ITEM'
);
export const setNavItem = createActionCreator(SET_NAV_ITEM);

// Betting odds
export const SET_BETTING_TOGGLE = createDeltaActionType(
    'brownlow/leaderboard',
    'SET_BETTING_TOGGLE'
);
export const setBettingToggle = createActionCreator(SET_BETTING_TOGGLE);

// Search Results
export const SET_SEARCH_RESULTS = createDeltaActionType(
    'brownlow/leaderboard',
    'SET_SEARCH_RESULTS'
);
export const setSearchResults = createActionCreator(SET_SEARCH_RESULTS);

// Player stats
export const SET_PLAYER_STATS = createDeltaActionType(
    'brownlow/leaderboard',
    'SET_PLAYER_STATS'
);
export const setPlayerStats = createActionCreator(SET_PLAYER_STATS);

// Player stats Request Tracking
export const SET_PLAYER_STATS_REQUEST = createDeltaActionType(
    'brownlow/leaderboard',
    'SET_PLAYER_STATS_REQUEST'
);
export const setPlayerStatsRequest = createActionCreator(
    SET_PLAYER_STATS_REQUEST
);

export const REMOVE_PLAYER_STATS_REQUEST = createDeltaActionType(
    'brownlow/leaderboard',
    'REMOVE_PLAYER_STATS_REQUEST'
);
export const removePlayerStatsRequest = createActionCreator(
    REMOVE_PLAYER_STATS_REQUEST
);

// Favourite Player
export const SET_FAVOURITE_PLAYER = createDeltaActionType(
    'brownlow/leaderboard',
    'SET_FAVOURITE_PLAYER'
);

export const setFavouritePlayer = createActionCreator(SET_FAVOURITE_PLAYER);
