import { createApi } from '@reduxjs/toolkit/query/react';

import { misBaseQuery } from './base-queries/mis-request';
import { fetchWithRetryBQ } from './base-queries/fetch-with-retry';

const { app } = PULSE;

/**
 * @constant {string[]} The values we allow to be passed as a competitionType to the
 * wagering API
 */
const ALLOWED_BETTING_COMP_TYPES = ['AFLW'];

// RTK Query update: Define an api service with a base URL and retry attempts
// RTK Query update: Endpoints can be added here or injected in components
export const cfsApi = createApi({
    reducerPath: 'cfsAPI',
    baseQuery: fetchWithRetryBQ(app.environment.cfsApi, misBaseQuery),
    endpoints: (build) => ({
        getMatchItem: build.query({
            query: (id) => `/matchItem/${id}`,
            transformResponse: modelMatchResponse
        }),
        getDraftProspectStats: build.query({
            query: ({ year, playerId }) =>
                `/draft/year/${year}/prospectProfile/${playerId}`
        }),
        getInterchange: build.query({
            query: (id) => `/matchInterchange/${id}`
        }),
        getPlayers: build.query({
            query: ({ seasonId, teamIds, position }) =>
                `/players?pageSize=20&pageNum=1&sortBy=name&seasonId=${seasonId}&teamIds=${teamIds}&playerPosition=${position}`,
            transformResponse: (response) => response ?? response
        }),
        getBettingOdds: build.query({
            query: ({ competitionType } = {}) => {
                const params = { application: 'Web' };
                // Only allow certain competitionType values
                if (ALLOWED_BETTING_COMP_TYPES.includes(competitionType)) {
                    params.competitionType = competitionType;
                }
                return `/wagering${app.common.prepareParams(params)}`;
            }
        }),
        getBrownlowBettingOdds: build.query({
            query: () => '/wagering/playerMarket/brownlow'
        }),
        getLiveLadder: build.query({
            query: ({ roundId }) => `/liveLadder/round/${roundId}`
        }),
        // GET /statsCentre/player/best/season - Get a players best stats for a season
        getPlayerBestSeason: build.query({
            query: (params) => {
                return `/statsCentre/player/best/season${app.common.prepareParams(
                    params
                )}`;
            }
        }),
        // GET /statsCentre/player/best/career - Get a players best stats for their career
        getPlayerBestCareer: build.query({
            query: (params) => {
                return `/statsCentre/player/best/career${app.common.prepareParams(
                    params
                )}`;
            }
        }),
        getMatchRosterFull: build.query({
            query: ({ matchId }) => `/matchRoster/full/${matchId}`
        })
    })
});

/**
 * Take in a response from the getMatchItem api call and model the match data
 * by sorting the matchClock and scores so they're ready to work with.
 *
 * @param {object} response - Response from the getMatchItem api call
 * @returns {object} - Response contained a modelled match with sorted data
 */
function modelMatchResponse(response) {
    // Sort the clock into the right order by periodNumber
    if (response.score?.matchClock?.periods?.length) {
        response.score.matchClock.periods.sort(
            (a, b) => b.periodNumber - a.periodNumber
        );
    }

    // Sort score data into the right order by periodNumber
    if (response.score?.homeTeamScore?.periodScore?.length) {
        response.score.homeTeamScore.periodScore.sort((a, b) => {
            return a.periodNumber - b.periodNumber;
        });
    }

    if (response.score?.awayTeamScore?.periodScore?.length) {
        response.score.awayTeamScore.periodScore.sort((a, b) => {
            return a.periodNumber - b.periodNumber;
        });
    }

    return response;
}

export const {
    useGetMatchItemQuery,
    useGetDraftProspectStatsQuery,
    useGetInterchangeQuery,
    useGetPlayersQuery,
    useGetBettingOddsQuery,
    useGetBrownlowBettingOddsQuery,
    useGetLiveLadderQuery,
    useGetPlayerBestSeasonQuery,
    useGetPlayerBestCareerQuery,
    useGetMatchRosterFullQuery
} = cfsApi;
