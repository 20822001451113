import React from 'react';
import PropTypes from 'prop-types';

export const GlossaryTableSection = ({
    glossaryTerms,
    sectionRef,
    sectionKey
}) => {
    return (
        <tbody ref={sectionRef}>
            {glossaryTerms.map((term, index) => (
                <tr
                    key={`glossary-table-row-${sectionKey}-${index}`}
                    className="stats-glossary__item"
                >
                    <td className="stats-glossary__item-label">{term.entry}</td>
                    <td className="stats-glossary__item-description">
                        {term.description}
                    </td>
                </tr>
            ))}
        </tbody>
    );
};

GlossaryTableSection.propTypes = {
    glossaryTerms: PropTypes.arrayOf(
        PropTypes.shape({
            entry: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired
        }).isRequired
    ).isRequired,
    sectionKey: PropTypes.string.isRequired,
    sectionRef: PropTypes.any
};
