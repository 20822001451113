import { call, select, take } from 'redux-saga/effects';
import '../types';

// @ts-expect-error
const { app } = window.PULSE;
const { MIS_TOKEN_HEADER_PROP } = app.common.CONSTANTS;

/**
 * @param {string} url - URL to fetch.
 * @returns {object} Json response.
 */
export function* fetchWithMisToken(url) {
    const { actions, selectors } = app.redux;

    let token = yield select(selectors.token.selectToken);

    if (!token) {
        const tokenSuccess = yield take(actions.token.GET_TOKEN.SUCCESS);
        if (tokenSuccess) {
            token = yield select(selectors.token.selectToken);
        }
    }

    return yield call(runFetch, url, token);
}

const runFetch = (url, token) => {
    return fetch(url, { headers: { [MIS_TOKEN_HEADER_PROP]: token } })
        .then((res) => res.json())
        .then((data) => data)
        .catch((error) => ({ error }));
};
