import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const TableModalSortableFieldItem = ({
    tableName,
    field,
    fieldId,
    index,
    handleAddRemoveFieldClick,
    moveCard,
    getTranslation
}) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: 'card',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(item.tableName, dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: 'card', fieldId, index, tableName },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const opacity = isDragging ? 0.3 : 1;

    drag(drop(ref));

    const moveIcon = PULSE.app.templating.render(
        {
            cssClass: ``,
            name: 'customise'
        },
        'common.svg-icon'
    );

    const transitionOptions = {
        classNames: 'order',
        key: fieldId,
        timeout: { enter: 500, exit: 500 }
    };

    if (field.isPersistent) {
        return '';
    }

    return (
        <CSSTransition {...transitionOptions}>
            <li
                className="custom-table-modal__field-list-item"
                ref={ref}
                style={{ opacity }}
            >
                <button
                    className="custom-table-modal__add-remove-button"
                    onClick={() =>
                        handleAddRemoveFieldClick(
                            field,
                            tableName,
                            field.groupName
                        )
                    }
                >
                    <span className="custom-table-modal__add-remove-button-icon custom-table-modal__add-remove-button-icon--remove"></span>
                </button>
                <span className="custom-table-modal__add-remove-short-title">
                    ({getTranslation(field.fieldLabelShort)})
                </span>
                <span className="custom-table-modal__field-list-item-label">
                    {getTranslation(field.fieldLabel)}
                </span>
                <span
                    className="custom-table-modal__field-list-item-icon"
                    dangerouslySetInnerHTML={{ __html: moveIcon }}
                ></span>
            </li>
        </CSSTransition>
    );
};

export default TableModalSortableFieldItem;
