/**
 * @param {any} draft - Draft state.
 * @param {any} payload - Action payload.
 */
export const setSort = (draft, payload) => {
    draft.sort = {
        ...draft.sort,
        ...payload
    };
};
