import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SvgIcon } from 'common/react/components/SvgIcon';
import {
    BREAKPOINTS_FOR_THREE_SLIDES,
    sliderSettings
} from '../../../config/slider';
import { LastRoundHighsSlide } from './LastRoundHighsSlide';
import { EmptyState } from 'common/react/components/EmptyState';

/**
 * @param {object} props - Props.
 * @param {object} props.data - Player data.
 * @param {string} props.type - Category of slider.
 * @param {bool} props.isAFLW - is AFLW comp
 * @returns {JSX.Element} Component.
 */
export const LastRoundHighsSlider = ({ data, type, isAFLW }) => {
    if (!data?.[data.displayOrder?.[0]]?.[0]?.player?.playerId) {
        return <EmptyState />;
    }

    return (
        <div>
            <Swiper {...sliderSettings(type)} {...BREAKPOINTS_FOR_THREE_SLIDES}>
                {data?.displayOrder?.map((category, i) => (
                    <SwiperSlide key={i}>
                        <LastRoundHighsSlide
                            category={category}
                            players={data[category]}
                            type={type}
                            isAFLW={isAFLW}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
            <div
                className={`swiper-button-prev swiper-button-prev-unique-${type}`}
            >
                <SvgIcon icon="chevron" className="icon" />
            </div>
            <div
                className={`swiper-button-next swiper-button-next-unique-${type}`}
            >
                <SvgIcon icon="chevron" className="icon" />
            </div>
        </div>
    );
};

LastRoundHighsSlider.propTypes = {
    data: PropTypes.object,
    type: PropTypes.string.isRequired,
    isAFLW: PropTypes.bool
};
