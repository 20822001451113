// eslint-disable-next-line no-unused-vars
import { StatComparisonState } from '../types';
// eslint-disable-next-line no-unused-vars
import { ReduxState } from '../../../../types';

/**
 * @param {ReduxState} state - The Redux state.
 * @returns {StatComparisonState} The Stats Leaders state.
 */
export const statComparisonSelector = (state) => state.statspro.statComparison;
