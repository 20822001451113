import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { FeaturedHeadToHead } from '../components/featured-head-to-head/FeaturedHeadToHead';

export const FeaturedHeadToHeadProvider = ({ store, data }) => (
    <Provider store={store}>
        <div className="wrapper">
            <FeaturedHeadToHead {...data} />
        </div>
    </Provider>
);

FeaturedHeadToHeadProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
