import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { PlayerSearchInput } from 'common/react/components/PlayerSearchInput';

export const Search = () => {
    const [query, setQuery] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState(query);

    const dispatch = useDispatch();

    const onSearchSubmit = async (newQuery) => {
        dispatch(
            PULSE.app.redux.actions.draftTracker.updateNav({
                name: 'filter',
                value: newQuery
            })
        );
    };

    useEffect(() => {
        const timer = setTimeout(() => setQuery(debouncedQuery.trim()), 1000);
        return () => clearTimeout(timer);
    }, [debouncedQuery]);

    useEffect(() => {
        if (query !== '' && query.length > 2) {
            onSearchSubmit(query);
        } else {
            onSearchSubmit('');
        }
    }, [query]);

    return <PlayerSearchInput setDebouncedQuery={setDebouncedQuery} />;
};
