import React from 'react';
import PropTypes from 'prop-types';

import { useSortedRegions } from '../util';
import { getTranslation } from 'common/react/utils/translations';
import { useSiteSettings } from 'common/react/hooks/use-site-settings';

export const RegionSelectionOptions = ({ title, subtitle }) => {
    const [australiaRegions, otherRegions] = useSortedRegions();
    const { setSiteSettings, settingsConfig } = useSiteSettings();

    const returnFromPage = (region) => {
        let pageReferrer = document.referrer;

        setSiteSettings({
            [settingsConfig.region.key]: region
        });

        if (!pageReferrer || pageReferrer === window.location.href) {
            pageReferrer = '/';
        }
        window.location.replace(pageReferrer);
    };

    return (
        <div className="region-selection-options">
            <div className="wrapper">
                {title && (
                    <section className="region-selection-options__title-container">
                        <h1 className="region-selection-options__title">
                            {title}
                        </h1>
                        {subtitle && (
                            <p className="region-selection-options__subtitle">
                                {subtitle}
                            </p>
                        )}
                    </section>
                )}

                <section className="region-selection-options__section">
                    <h2 className="region-selection-options__section-title">
                        {getTranslation(`region.selector.australiaRegions`)}
                    </h2>
                    <ul className="region-selection-options__list">
                        {australiaRegions &&
                            australiaRegions.map((region) => (
                                <li key={region.id}>
                                    <button
                                        className="region-selection-options__option"
                                        onClick={() => returnFromPage(region)}
                                    >
                                        {region.name}
                                    </button>
                                </li>
                            ))}
                    </ul>
                </section>

                <section className="region-selection-options__section">
                    <h2 className="region-selection-options__section-title">
                        {getTranslation(`region.selector.otherRegions`)}
                    </h2>
                    <ul className="region-selection-options__list">
                        {otherRegions &&
                            otherRegions.map((region) => (
                                <li key={region.id}>
                                    <button
                                        className="region-selection-options__option"
                                        onClick={() => returnFromPage(region)}
                                    >
                                        {region.name}
                                    </button>
                                </li>
                            ))}
                    </ul>
                </section>
            </div>
        </div>
    );
};

RegionSelectionOptions.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string
};
