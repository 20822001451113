import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'common/react/components/select';
import { useCreateRegions } from '../hooks/hooks';
import { getTranslation } from 'common/react/utils/translations';

export const RegionDropdown = ({
    items,
    dropdownData,
    handleDropdownChange
}) => {
    const regionItems = useCreateRegions(items, dropdownData.activeLocation);

    return (
        <div className="stats-leaders-nav__select competition-nav__competition-select">
            <Select
                label={getTranslation('label.region-selector.region')}
                items={regionItems}
                itemToString={(item) => item?.name}
                initialSelectedItem={regionItems?.find((item) => {
                    if (item.name) {
                        return item.name === dropdownData.activeRegion;
                    }
                    return false;
                })}
                activeItem={regionItems?.find((item) => {
                    if (item.name) {
                        return item.name === dropdownData.activeRegion;
                    }
                    return false;
                })}
                onSelectedItemChange={({ selectedItem }) => {
                    const newRegion = {
                        timezone: selectedItem.timezone,
                        id: selectedItem.id,
                        name: selectedItem.name
                    };

                    handleDropdownChange(newRegion);
                }}
            />
        </div>
    );
};

RegionDropdown.propTypes = {
    items: PropTypes.array,
    setDropdownData: PropTypes.func,
    dropdownData: PropTypes.object,
    handleDropdownChange: PropTypes.func
};
