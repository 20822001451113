import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { StatsNavItem, TeamsNavItem } from './nav-items';
import { DataTypeToggle, Heading } from './header';
import { PlayerStatsTable } from './table';
import { ManageStatsButton } from './nav-items/ManageStatsButton';
import { playerStatsNavSelector } from 'store/modules/match-centre/player-stats/selectors';

export const PlayerStats = ({
    pid,
    compPid,
    homePid,
    awayPid,
    homeNickname,
    awayNickname
}) => {
    const dispatch = useDispatch();
    const status = useSelector((state) =>
        PULSE.app.redux.selectors.match.status(state, pid)
    );
    const matchStatus = PULSE.app.common.match.getMatchStatus(status);
    const nav = useSelector(playerStatsNavSelector);

    const config = {
        matchId: pid,
        competitionId: compPid,
        homeTeamId: homePid,
        awayTeamId: awayPid
    };

    useEffect(() => {
        if (matchStatus) {
            dispatch(
                PULSE.app.redux.actions.playerstats.ADD_MATCH_PLAYER_STATS.request(
                    { config }
                )
            );
            dispatch(
                PULSE.app.redux.actions.playerstats.ADD_SEASON_PLAYER_STATS.request(
                    { config }
                )
            );
        }
    }, [matchStatus]);

    return (
        <StatsSection.Main>
            <StatsSection.Nav>
                <StatsSection.NavItems>
                    <StatsNavItem />
                    <TeamsNavItem
                        homeNickname={homeNickname}
                        awayNickname={awayNickname}
                    />
                </StatsSection.NavItems>
                {nav.stats === 'My Stats' && (
                    <StatsSection.AdditionalNavItems>
                        <ManageStatsButton />
                    </StatsSection.AdditionalNavItems>
                )}
            </StatsSection.Nav>
            <StatsSection.Content>
                <StatsSection.ContentHeader>
                    <StatsSection.ContentHeaderHeading>
                        <Heading />
                    </StatsSection.ContentHeaderHeading>
                    <StatsSection.ContentHeaderOptions>
                        <DataTypeToggle />
                    </StatsSection.ContentHeaderOptions>
                </StatsSection.ContentHeader>
                <StatsSection.ContentBody>
                    <PlayerStatsTable
                        matchId={pid}
                        homePid={homePid}
                        awayPid={awayPid}
                        homeNickname={homeNickname}
                        awayNickname={awayNickname}
                        compPid={compPid}
                    />
                </StatsSection.ContentBody>
            </StatsSection.Content>
        </StatsSection.Main>
    );
};

PlayerStats.propTypes = {
    pid: PropTypes.string.isRequired,
    compPid: PropTypes.string.isRequired,
    homePid: PropTypes.string.isRequired,
    awayPid: PropTypes.string.isRequired,
    homeNickname: PropTypes.string.isRequired,
    awayNickname: PropTypes.string.isRequired
};
