import { put } from 'redux-saga/effects';
import {
    CHANGE_PLAYER_SELECTION_MODAL_VISIBILITY,
    setPlayerSelectionModalVisible
} from '../actions';

/**
 *
 * @param action
 */
export function* changePlayerSelectionModalVisibility(action) {
    const { isVisible } = action.payload;

    yield put(setPlayerSelectionModalVisible(isVisible));
    yield put(CHANGE_PLAYER_SELECTION_MODAL_VISIBILITY.success());
}
