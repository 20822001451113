import React from 'react';
import PropTypes from 'prop-types';

export const BodyCellBettingOdds = ({ cell, cellProps }) => {
    const proposition = cell?.value;

    return (
        <td
            {...cellProps}
            className="stats-table__cell stats-table__cell--betting-odds"
        >
            {!isNaN(proposition?.premiership) ? (
                <a
                    className="stats-table__betting-cell-button button button--small button--sportsbet"
                    target="_blank"
                    rel="noreferrer"
                    href={PULSE.app.common.betting.getClickThroughUrl(
                        proposition
                    )}
                >
                    {new Intl.NumberFormat('en-AU', {
                        style: 'currency',
                        currency: 'AUD'
                    }).format(proposition.premiership)}
                </a>
            ) : null}
        </td>
    );
};

BodyCellBettingOdds.propTypes = {
    cell: PropTypes.object,
    cellProps: PropTypes.object
};
