import React from 'react';
import PropTypes from 'prop-types';

import { useGetContentItemQuery } from 'common/store/modules/api/content-api';
import { LazyImage } from 'common/react/components/LazyImage';
import { useBettingVis } from 'common/react/hooks/use-betting-vis';

const BETTING_TAG = 'sponsor:betting';

export const TASponsor = ({ promoId }) => {
    const isBettingVisible = useBettingVis();
    const { data } = useGetContentItemQuery({
        type: 'PROMO',
        id: promoId
    });

    if (!data) {
        return null;
    }

    const isBettingSponsor = data.tags.find((tag) => tag.label === BETTING_TAG);
    const promoLink = data.links[0];
    const target = promoLink?.external ? '_blank' : null;

    if (isBettingSponsor && !isBettingVisible) {
        return null;
    }

    return (
        <div className="content-sponsor content-sponsor--without-borders content-sponsor--without-padding">
            {data.title && data.title !== '' ? (
                <h2 className="content-sponsor__title">{data.title}</h2>
            ) : null}
            {promoLink?.promoUrl ? (
                <a
                    className="content-sponsor__image-link"
                    href={promoLink.promoUrl}
                    target={target}
                >
                    {data?.promoItem?.onDemandUrl ? (
                        <LazyImage
                            url={`${data.promoItem.onDemandUrl}?height=108`}
                            cssClass="content-sponsor__image"
                            objectFit={false}
                        />
                    ) : null}
                    {promoLink.linkText ? (
                        <span className="u-screen-reader">
                            {promoLink.linkText}
                        </span>
                    ) : null}
                </a>
            ) : (
                <div className="content-sponsor__image-link">
                    {data?.promoItem?.onDemandUrl ? (
                        <LazyImage
                            url={`${data.promoItem.onDemandUrl}?height=108`}
                            cssClass="content-sponsor__image"
                            objectFit={false}
                        />
                    ) : null}
                    {promoLink?.linkText ? (
                        <span className="u-screen-reader">
                            {promoLink.linkText}
                        </span>
                    ) : null}
                </div>
            )}
        </div>
    );
};

TASponsor.propTypes = {
    promoId: PropTypes.string
};
