import { all, call, put } from 'redux-saga/effects';
import { fetchWithMisToken } from '../../../../utils/fetch-with-mis-token';
import {
    setTooltipPlayerStats,
    setTooltipPlayerStatsRequest,
    removeTooltipPlayerStatsRequest,
    FETCH_TOOLTIP_PLAYER_STATS
} from '../actions';

/**
 * @typedef {object} FetchTooltipPlayerStatsAction
 * @property {object} payload
 * @property {string} payload.playerId
 * @property {string} payload.seasonPid
 * @property {number} payload.index
 * @property {boolean} payload.reset
 */

/**
 * @param {FetchTooltipPlayerStatsAction} action - The Redux action.
 */
export function* fetchTooltipPlayerStats(action) {
    const { playerId, seasonPid, reset } = action.payload;

    yield put(setTooltipPlayerStatsRequest(action.payload));

    let data = {
        player: {},
        rounds: {}
    };

    if (!reset && playerId) {
        const [playerData, roundsData] = yield all([
            call(
                fetchWithMisToken,
                `${PULSE.app.environment.statsPro}/playerProfile/${playerId}?seasonId=${seasonPid}`
            ),
            call(
                fetchWithMisToken,
                `${PULSE.app.environment.statsPro}/playerSeasonRoundStats/${playerId}?seasonId=${seasonPid}`
            )
        ]);

        data.player = playerData;
        data.rounds = roundsData;

        yield put(setTooltipPlayerStats({ ...data }));
    }

    yield put(removeTooltipPlayerStatsRequest(action.payload));

    yield put(FETCH_TOOLTIP_PLAYER_STATS.success({ data: data }));
}
