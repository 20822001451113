// eslint-disable-next-line no-unused-vars
import { PlayerComparisonState } from '../../player-comparison/types';
// eslint-disable-next-line no-unused-vars
import { ReduxState } from '../../../../types';

/**
 * @param {ReduxState} state - The Redux state.
 * @returns {PlayerComparisonState} The Stats Leaders state.
 */
export const playerComparisonSelector = (state) =>
    state.statspro.playerComparison;