import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { StatNavItem, SeasonNavItem } from './components/nav-items';
import { Heading, DataTypeToggle } from './components/header';
import { PerformanceChartContainer } from './components/chart';

import { usePerformanceChartNavSync } from './hooks/use-performance-chart-nav-sync';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';

import { performanceChartNavSelector } from 'store/modules/statspro/performance-chart/selectors';
import { playersSelector } from 'store/modules/statspro/player-comparison/selectors';
import { useGetCompSeasonQuery } from 'common/store/modules/api/afl-api';

export const PerformanceChart = ({
    title,
    type,
    seasonId,
    playerProviderId
}) => {
    const isAFLW = PULSE.app.common.url.checkBaseRoute('aflw');

    const { data: compData } = useGetCompSeasonQuery(
        { id: seasonId },
        {
            skip: !seasonId
        }
    );

    const nav = useSelector(performanceChartNavSelector);

    const options = useCompetitionNavigationOptions(nav);

    usePerformanceChartNavSync(options, compData?.competition.id);

    const playerId = playerProviderId;
    const playerIdsFromStore = useSelector(playersSelector);
    const ref = useRef();

    const playerIds = useMemo(
        () => (playerId ? { 1: playerId, 2: null } : playerIdsFromStore),
        [playerId, playerIdsFromStore]
    );

    return (
        <div
            className={classNames('fade-in-on-load', {
                'fade-in-on-load fade-in-on-load--stats comparison-chart__wrapper':
                    !playerId,
                'is-loaded': playerIds[1] || playerIds[2]
            })}
            ref={ref}
        >
            {playerIds[1] || playerIds[2] ? (
                <StatsSection.Main>
                    <StatsSection.Heading title={title} />
                    <StatsSection.Nav>
                        <StatsSection.NavItems>
                            <StatNavItem />
                            <SeasonNavItem />
                        </StatsSection.NavItems>
                    </StatsSection.Nav>
                    <StatsSection.Content>
                        <StatsSection.ContentHeader>
                            <StatsSection.ContentHeaderHeading>
                                <Heading />
                            </StatsSection.ContentHeaderHeading>
                            <StatsSection.ContentHeaderOptions>
                                <DataTypeToggle />
                            </StatsSection.ContentHeaderOptions>
                        </StatsSection.ContentHeader>
                        <StatsSection.ContentBody>
                            <PerformanceChartContainer
                                playerIds={playerIds}
                                type={type}
                                containerRef={ref}
                                isAFLW={isAFLW}
                                compseasonPid={compData?.providerId}
                            />
                        </StatsSection.ContentBody>
                    </StatsSection.Content>
                </StatsSection.Main>
            ) : null}
        </div>
    );
};

PerformanceChart.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    seasonId: PropTypes.string,
    playerProviderId: PropTypes.string
};
