// eslint-disable-next-line no-unused-vars
import { PlayerStatsState } from '../../types';
// eslint-disable-next-line no-unused-vars
import { ReduxState } from '../../../../types';

/**
 * @param {ReduxState} state - The Redux state.
 * @returns {PlayerStatsState} The Stats Leaders state.
 */
export const playerStatsSelector = (state) => state.matchCentre.playerStats;
