import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param props.message
 * @returns {JSX.Element} Component.
 */
export const StatsSectionContentError = ({ message }) => (
    <>{message && <p className="stats-section-error">{message}</p>}</>
);

StatsSectionContentError.defaultProps = {
    name: 'StatsSectionContentError'
};

StatsSectionContentError.propTypes = {
    message: PropTypes.string
};
