/**
 * Converts home and away data arrays into a single array of objects with
 * home and away keys.
 *
 * @param {*} homeData - The home data.
 * @param {*} awayData - The away data.
 * @returns {Array}    - The formatted data model.
 */
export const createPlayerModel = (homeData = [], awayData = []) => {
    const dataModel = [];
    const longestArray = (arr1, arr2) => {
        return arr1.length > arr2.length ? arr1.length : arr2.length;
    };

    // Map through longest array (home/away)
    for (let i = 0; i < longestArray(homeData, awayData); i++) {
        dataModel.push({
            home: homeData[i],
            away: awayData[i]
        });
    }
    return dataModel;
};

const {
    EMERGENCIES,
    SUBSTITUTES,
    INTERCHANGE,
    FOLLOWERS,
    FORWARDS,
    HALF_FORWARDS,
    CENTRES,
    HALF_BACKS,
    BACKS
} = PULSE.app.common.CONSTANTS.PLAYER_POSITION_GROUPS;

/**
 * Takes an array of player objects and groups them according to players'
 * positions groups, e.g. a home player with a forward position will be
 * stored in the homeForwards array.
 *
 * @param {Array} players   - The players array.
 * @param {string} team     - The team location, i.e. home/away.
 *
 * @param homeTeamModel
 * @param awayTeamModel
 */
export const sortPositionAreas = (
    players,
    team,
    homeTeamModel,
    awayTeamModel
) => {
    for (const player of players) {
        const playerPosition = player.player.position;

        const model = team === 'home' ? homeTeamModel : awayTeamModel;

        EMERGENCIES.includes(playerPosition)
            ? model.emergencies.push(player)
            : null;
        SUBSTITUTES.includes(playerPosition)
            ? model.substitutes.push(player)
            : null;
        INTERCHANGE.includes(playerPosition)
            ? model.interchanges.push(player)
            : null;
        FOLLOWERS.includes(playerPosition)
            ? model.followers.push(player)
            : null;
        FORWARDS.includes(playerPosition) ? model.forwards.push(player) : null;
        HALF_FORWARDS.includes(playerPosition)
            ? model.halfForwards.push(player)
            : null;
        CENTRES.includes(playerPosition) ? model.centres.push(player) : null;
        HALF_BACKS.includes(playerPosition)
            ? model.halfBacks.push(player)
            : null;
        BACKS.includes(playerPosition) ? model.backs.push(player) : null;
    }
};
