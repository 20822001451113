import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Select } from 'common/react/components/select';
import { getTranslation } from 'common/react/utils/translations';
import { playerStatsNavSelector } from 'store/modules/match-centre/player-stats/selectors';
import { UPDATE_NAV_ITEM } from 'store/modules/match-centre/player-stats/actions';

export const TeamsNavItem = ({ homeNickname, awayNickname }) => {
    const dispatch = useDispatch();
    const nav = useSelector(playerStatsNavSelector);

    return (
        <Select
            items={['Both', homeNickname, awayNickname]}
            initialSelectedItem={nav.teams}
            activeItem={nav.teams}
            label={getTranslation('label.teams')}
            onSelectedItemChange={({ selectedItem }) => {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'teams',
                        value: selectedItem
                    })
                );
            }}
        />
    );
};

TeamsNavItem.propTypes = {
    homeNickname: PropTypes.string.isRequired,
    awayNickname: PropTypes.string.isRequired
};
