import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders the milestones data.
 *
 * @param {object} props - The props
 * @param {Array} props.milestones - The milestones data.
 * @returns {React.JSX.Element}    - The formatted milestones component.
 */
export const TeamAnnouncementsMilestones = ({ milestones }) => {
    return (
        <p
            className={`team-announcements__milestones team-announcements__milestones`}
        >
            <span className="team-announcements__milestones-title team-announcements__list-title">
                Milestones
            </span>
            <span className="team-announcements__milestones-value team-announcements__list-values">
                {milestones.map(
                    (milestone, index) =>
                        `${milestone?.player?.playerName?.givenName} ${
                            milestone?.player?.playerName?.surname
                        } (${milestone?.milestoneEvent})${
                            index + 1 < milestones.length ? `, ` : ''
                        } `
                )}
            </span>
        </p>
    );
};

TeamAnnouncementsMilestones.propTypes = {
    milestones: PropTypes.array
};
