/**
 * Use a "container" component to contain any logic to map Redux store state into component props,
 * and map any Redux actions into component props.
 * Useful for separating business logic from rendering logic; makes it easier to re-use the rendering logic but apply different business logic.
 * Also useful for introducing test frameworks in future, as we can easily pass mock data to the rendering layer.
 */

import { connect } from 'react-redux';

import DefaultTableModal from '.';

/**
 * Use redux selector functions to map state from the redux store into component props
 *
 * @param state
 * @param ownProps
 */
const mapStateToProps = (state, ownProps) => ({
    customTable: PULSE.app.redux.selectors.customtable.item(
        state,
        ownProps.data.tablename
    ),
    sortedTableData: PULSE.app.redux.selectors.customtable.sortedTableData(
        state,
        ownProps.data.tablename
    ),
    displayedCustomTableFields:
        PULSE.app.redux.selectors.customtable.displayedCustomTableFields(
            state,
            ownProps.data.tablename
        ),
    modalOpen: PULSE.app.redux.selectors.customtable.modalOpen(
        state,
        ownProps.data.tablename
    ),
    manageFieldsBarShowing:
        PULSE.app.redux.selectors.customtable.manageFieldsBarShowing(
            state,
            ownProps.data.tablename
        )
});

/**
 * Need to use this form of "mapDispatchToProps" to ensure the PULSE namespace resolves correctly
 *
 * @param dispatch
 * @param ownProps
 */
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        addCustomTable: (config) =>
            dispatch(
                PULSE.app.redux.actions.customtable.ADD_CUSTOM_TABLE.request({
                    config
                })
            ),
        sortCustomTable: (
            tableName,
            field,
            sortDirection,
            additionalSortProps
        ) =>
            dispatch(
                PULSE.app.redux.actions.customtable.SORT_CUSTOM_TABLE.request({
                    tableName,
                    field,
                    sortDirection,
                    additionalSortProps
                })
            ),
        updateDisplayedCustomTableFields: (tableName, state) =>
            dispatch(
                PULSE.app.redux.actions.customtable.DISPLAYED_CUSTOM_TABLE_FIELDS.request(
                    {
                        tableName,
                        fields: state.displayedFields,
                        groups: state.tableFieldGroups
                    }
                )
            ),
        reorderDisplayedCustomTableFields: (tableName, newOrder) =>
            dispatch(
                PULSE.app.redux.actions.customtable.DISPLAYED_CUSTOM_TABLE_FIELDS_ORDER.request(
                    { tableName, newOrder }
                )
            ),
        removeAllDisplayedCustomTableFields: (tableName) =>
            dispatch(
                PULSE.app.redux.actions.customtable.DISPLAYED_CUSTOM_TABLE_FIELDS.request(
                    { tableName: tableName, removeAll: true }
                )
            ),
        toggleModal: (tableName) =>
            dispatch(
                PULSE.app.redux.actions.customtable.TOGGLE_MODAL.request({
                    tableName
                })
            ),
        toggleMangageFieldsBar: (tableName) =>
            dispatch(
                PULSE.app.redux.actions.customtable.TOGGLE_MANGAGE_FIELDS_BAR.request(
                    { tableName }
                )
            )
    };
};

export const connectTable = (Component) =>
    connect(mapStateToProps, mapDispatchToProps)(Component);
export default connectTable(DefaultTableModal);
