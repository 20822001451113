import { put } from 'redux-saga/effects';
import { setNavItem } from '../actions';

/**
 * @typedef {object} UpdateNavItemAction
 * @property {string} type
 * @property {object} payload
 * @property {string} payload.navItem
 * @property {string} payload.value
 */

/**
 * @param {UpdateNavItemAction} action - The Redux action.
 */
export function* updateNavItem(action) {
    yield put(setNavItem(action.payload));
}
