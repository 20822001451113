// eslint-disable-next-line no-unused-vars
import { LeaderboardState } from '../types';
import '../../types';

/**
 * @param {LeaderboardState} draft - Draft state.
 * @param {object} payload - Action payload.
 * @param payload.seasonPid
 * @param payload.data
 */
export const setLeaderboardData = (draft, { seasonPid, data }) => {
    draft.data[seasonPid] = { ...data } ?? [];
};
