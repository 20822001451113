import React from 'react';
import PropTypes from 'prop-types';

import { getPositionNameByKey } from '../../../../statspro/js/components/stats-leaders/utils/positions';
import { TeamWatermarkBackground } from '../../../../statspro/js/components/TeamWatermarkBackground';
import { getTranslation } from 'common/react/utils/translations';
import { TeamIcon } from 'common/react/components/TeamIcon';

const CLASS_BLOCK = 'player-stats-tooltip-header';

/**
 * @param {object} props - Props.
 * @param {object} props.player - Player.
 * @returns {JSX.Element} Component.
 */
export const TooltipHeader = ({ player }) => {
    const teamAbbreviation = player.team.teamAbbr.toLowerCase();

    return (
        <div className={CLASS_BLOCK}>
            <TeamWatermarkBackground teamAbbreviation={teamAbbreviation} />
            <div className={`${CLASS_BLOCK}__content`}>
                <div className={`${CLASS_BLOCK}__details-wrapper`}>
                    <div className={`${CLASS_BLOCK}__name`}>
                        <div className={`${CLASS_BLOCK}__first-name`}>
                            {player.playerDetails.givenName}
                        </div>
                        <div className={`${CLASS_BLOCK}__last-name`}>
                            {player.playerDetails.surname}
                        </div>
                    </div>
                    <div className={`${CLASS_BLOCK}__team-details`}>
                        <TeamIcon
                            className={`${CLASS_BLOCK}__team-icon`}
                            teamAbbreviation={teamAbbreviation}
                            theme="light"
                        />
                        <span
                            className={`${CLASS_BLOCK}__jumper-number`}
                        >{`#${player.playerDetails.jumperNumber}`}</span>
                        <span>
                            {getPositionNameByKey(
                                player.playerDetails.position
                            )}
                        </span>
                    </div>
                </div>
                <img
                    className={`${CLASS_BLOCK}__headshot-wrapper`}
                    src={PULSE.app.common.image.migratePlayerPhotoURL(
                        player?.playerDetails?.photoURL,
                        0.3
                    )}
                    alt={`${getTranslation('label.stats.headshot.alt')} ${
                        player.playerDetails.givenName
                    } ${player.playerDetails.surname}`}
                    width="1"
                    height="1"
                />
            </div>
        </div>
    );
};

TooltipHeader.propTypes = {
    player: PropTypes.object.isRequired
};
