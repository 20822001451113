/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';

import { StatsTableBody } from 'widgets/statspro/js/components/common/stats-table/StatsTableBody';
import classNames from 'classnames';
import { careerAndSeasonStatsBenchmarkingSelector } from 'store/modules/statspro/career-and-season-stats/selectors';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { useSelector } from 'react-redux';

/**
 * @param {object} props - Props.
 * @param {object[]} props.rows - Rows to render.
 * @param {Function} props.getTableBodyProps - Get table body props function.
 * @param {Function} props.prepareRow - Prepare row function.
 * @param {boolean} props.isProspect - True if it's a draft prospect table.
 * @param props.hideClubLogos
 * @returns {JSX.Element} Component.
 */
export const TableBody = ({
    rows,
    getTableBodyProps,
    prepareRow,
    isProspect,
    hideClubLogos
}) => {
    const benchmarking = useSelector(careerAndSeasonStatsBenchmarkingSelector);

    return (
        <StatsTableBody
            rows={rows}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            getBenchmark={(row, cell) => getBenchmark(cell, benchmarking)}
            isProspect={isProspect}
            customCells={
                (typeof rows[0].values.round === 'undefined' && {
                    year: (row, cell, cellProps) => {
                        if (cell.value !== null) {
                            return (
                                <th
                                    {...cellProps}
                                    className={classNames(
                                        'stats-table__row-header-cell',
                                        {
                                            'stats-table__cell--sorted':
                                                cell.column.isSorted
                                        }
                                    )}
                                >
                                    {cell.value}
                                </th>
                            );
                        }
                        return null;
                    },

                    club: (row, cell, cellProps) => {
                        return (
                            <td
                                {...cellProps}
                                colSpan={cell.value.isFullWidth ? `2` : '1'}
                                className={`stats-table__cell ${
                                    cell.value.isFullWidth
                                        ? `stats-table__cell--full-width`
                                        : ''
                                }`}
                            >
                                <div className="career-and-season-stats__team">
                                    {hideClubLogos ? null : (
                                        <TeamIcon
                                            providerId={cell.value.teamId}
                                        />
                                    )}
                                    <span
                                        className={`${
                                            cell.value.isFullWidth
                                                ? ``
                                                : 'u-hide-until-desktop'
                                        } `}
                                    >
                                        {cell.value.teamName}
                                    </span>
                                    {!cell.value.isFullWidth && (
                                        <span className="u-hide-from-desktop">
                                            {cell.value.teamAbbr
                                                ? cell.value.teamAbbr
                                                : cell.value.teamName}
                                        </span>
                                    )}
                                </div>
                            </td>
                        );
                    }
                }) || {
                    round: (row, cell, cellProps) => (
                        <th
                            {...cellProps}
                            className={classNames(
                                'stats-table__row-header-cell',
                                {
                                    'stats-table__cell--sorted':
                                        cell.column.isSorted
                                }
                            )}
                        >
                            {cell.value}
                        </th>
                    ),
                    opponent: (row, cell, cellProps) => (
                        <td {...cellProps} className="stats-table__cell">
                            <div className="career-and-season-stats__team">
                                <TeamIcon providerId={cell.value.teamId} />
                                <span className="u-hide-until-desktop">
                                    {cell.value.teamName}
                                </span>
                                <span className="u-hide-from-desktop">
                                    {cell.value.teamAbbr}
                                </span>
                            </div>
                        </td>
                    ),
                    result: (row, cell, cellProps) => (
                        <td {...cellProps} className="stats-table__cell">
                            {cell.value.score && (
                                <div className="career-and-season-stats__result">
                                    <span
                                        className={classNames(
                                            'career-and-season-stats__outcome',
                                            {
                                                'career-and-season-stats__outcome--win':
                                                    cell.value.outcome === 'W',
                                                'career-and-season-stats__outcome--loss':
                                                    cell.value.outcome === 'L',
                                                'career-and-season-stats__outcome--draw':
                                                    cell.value.outcome === 'D'
                                            }
                                        )}
                                    >
                                        {cell.value.outcome}
                                    </span>
                                    {cell.value.score}
                                </div>
                            )}
                        </td>
                    )
                }
            }
        />
    );
};

TableBody.propTypes = {
    rows: PropTypes.array.isRequired,
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired,
    isProspect: PropTypes.bool,
    hideClubLogos: PropTypes.bool.isRequired
};

const getBenchmark = (cell, benchmarking) => {
    return (
        benchmarking.isActive && cell.row.original.benchmarks[cell.column.id]
    );
};
