/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import {
    ResponsiveContainer,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    BarChart,
    Bar,
    Cell
} from 'recharts';
import { performanceChartNavSelector } from 'store/modules/statspro/performance-chart/selectors';
import { ComparisonTooltip, CustomTooltip } from '../components';

export const PerformanceChartBarChart = ({
    data,
    xLabel,
    yLabel,
    playerDetails,
    playerTwoDetails,
    type,
    hideTooltip
}) => {
    const nav = useSelector(performanceChartNavSelector);

    // Check if any entry contains the key "playerTwoValue"
    // if so we can 'compare' the data by rendering two bar charts
    const isDataComparable = data.some((entry) => 'playerTwoValue' in entry);

    let yLabelOptions = {},
        chartMargin = {};

    if (isMobile) {
        yLabelOptions = {
            value: yLabel,
            position: 'insideTopLeft',
            fontWeight: 500,
            dy: -35,
            dx: 20
        };
        chartMargin = {
            top: 50,
            bottom: 40,
            right: 10
        };
    } else {
        yLabelOptions = {
            value: yLabel,
            angle: -90,
            position: 'insideLeft',
            fontWeight: 500,
            dx: -10
        };
        chartMargin = {
            top: 40,
            bottom: 40,
            left: 30,
            right: 12
        };
    }

    return (
        <ResponsiveContainer width={isMobile ? '100%' : '98%'} height={500}>
            <BarChart data={data} margin={chartMargin} key={Math.random()}>
                <CartesianGrid vertical={false} stroke="#00000014" />
                <XAxis
                    dataKey="name"
                    label={{
                        value: xLabel,
                        position: 'outsideCenter',
                        fontWeight: 500,
                        dy: 20
                    }}
                    tickLine={false}
                    stroke="#00000014"
                    tick={{ fill: '#000' }}
                />
                <YAxis
                    label={yLabelOptions}
                    axisLine={false}
                    tickLine={false}
                    tickCount={10}
                    tick={{ fill: '#000' }}
                    allowDecimals={false}
                />
                {(playerDetails || playerTwoDetails) && !hideTooltip && (
                    <Tooltip
                        cursor={{ fill: 'transparent' }}
                        content={
                            type === 'comparison' &&
                            playerDetails &&
                            playerTwoDetails ? (
                                <ComparisonTooltip />
                            ) : (
                                <CustomTooltip />
                            )
                        }
                        player={playerDetails || playerTwoDetails}
                        playerTwo={playerTwoDetails}
                        stat={nav.category}
                    />
                )}
                <Bar dataKey="playerOneValue" barSize={10} name="playerOneLine">
                    {data.map((entry, index) => (
                        <Cell
                            key={index}
                            fill={PULSE.app.common.statsMap.colours.PLAYER_ONE}
                            radius={[10, 10, 10, 10]}
                        />
                    ))}
                </Bar>

                {isDataComparable && (
                    <Bar
                        dataKey="playerTwoValue"
                        barSize={10}
                        name="playerTwoLine"
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={index}
                                fill={
                                    PULSE.app.common.statsMap.colours.PLAYER_TWO
                                }
                                radius={[10, 10, 10, 10]}
                            />
                        ))}
                    </Bar>
                )}
            </BarChart>
        </ResponsiveContainer>
    );
};
