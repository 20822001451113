import { combineReducers } from 'redux';
import { statsLeadersRootSaga } from './stats-leaders/sagas';
import { glossaryRootSaga } from './glossary/sagas';
import { searchRootSaga } from './search/sagas';
import { statsLeadersReducer } from './stats-leaders/reducer';
import { statsGlossaryReducer } from './glossary/reducer';
import { careerAndSeasonStatsRootSaga } from './career-and-season-stats/sagas';
import { careerAndSeasonStatsReducer } from './career-and-season-stats/reducer';
import { performanceChartRootSaga } from './performance-chart/sagas';
import { performanceChartReducer } from './performance-chart/reducer';
import { searchReducer } from './search/reducer';
import { playerComparisonRootSaga } from './player-comparison/sagas';
import { playerComparisonReducer } from './player-comparison/reducer';
import { statComparisonRootSaga } from './stat-comparison/sagas';
import { statComparisonReducer } from './stat-comparison/reducer';

const sagas = [
    statsLeadersRootSaga,
    glossaryRootSaga,
    careerAndSeasonStatsRootSaga,
    performanceChartRootSaga,
    searchRootSaga,
    playerComparisonRootSaga,
    statComparisonRootSaga
];

const rootReducer = combineReducers({
    statsLeaders: statsLeadersReducer,
    glossary: statsGlossaryReducer,
    careerAndSeasonStats: careerAndSeasonStatsReducer,
    performanceChart: performanceChartReducer,
    searchResults: searchReducer,
    playerComparison: playerComparisonReducer,
    statComparison: statComparisonReducer
});

// @ts-expect-error
if (window.PULSE.app.redux) {
    // @ts-expect-error
    window.PULSE.app.redux.sagas.statspro = { root: sagas };
    // @ts-expect-error
    window.PULSE.app.redux.reducers.statspro = { root: rootReducer };
} else {
    // @ts-expect-error
    window.PULSE.app.redux = {
        reducers: {
            statspro: {
                root: rootReducer
            }
        },
        sagas: {
            statspro: {
                root: sagas
            }
        }
    };
}
