import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UPDATE_NAV_ITEM } from 'store/modules/statspro/stat-comparison/actions';
import { statComparisonNavSelector } from 'store/modules/statspro/stat-comparison/selectors';

export const useStatComparisonNavSync = (
    { competitions, seasons },
    competitionId,
    compseasonId
) => {
    const dispatch = useDispatch();
    const nav = useSelector(statComparisonNavSelector);

    /**
     * Set initial nav values
     */
    useEffect(() => {
        if (competitionId && !nav.competition.id) {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'competition',
                    value: { id: competitionId }
                })
            );
        }

        if (compseasonId && !nav.season.id) {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'season',
                    value: { id: compseasonId }
                })
            );
        } else if (seasons && !nav.season.id) {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'season',
                    value: { id: seasons[0].id }
                })
            );
        }
    }, [
        competitions,
        seasons,
        competitionId,
        compseasonId,
        nav.competition.id,
        nav.season.id
    ]);
};
