import { call, put } from 'redux-saga/effects';
import { fetchWithMisToken } from '../../../../utils/fetch-with-mis-token';
import {
    setPlayerModalData,
    FETCH_PLAYER_MODAL_DATA,
    setPlayerModalDataRequest,
    removePlayerModalDataRequest
} from '../actions';

/**
 * @param action
 */
export function* fetchPlayerModalData(action) {
    const { seasonPid } = action.payload;

    yield put(setPlayerModalDataRequest(action.payload));

    const data = yield call(
        fetchWithMisToken,
        `${PULSE.app.environment.cfsApi}/brownlow/season/${seasonPid}`
    );

    if (data) {
        yield put(setPlayerModalData({ seasonPid: seasonPid, data: data }));
    } else {
        // Error handling for when there is no data
        yield put(
            setPlayerModalData({
                error: 'no data available'
            })
        );
    }

    yield put(removePlayerModalDataRequest(action.payload));

    yield put(FETCH_PLAYER_MODAL_DATA.success({ data: data }));
}
