import { all, takeEvery } from 'redux-saga/effects';
import {
    FETCH_STAT_COMPARISON,
    UPDATE_DATA_TYPE,
    UPDATE_NAV_ITEM
} from '../actions';
import { fetchStatComparison } from './fetch-stat-comparison';
import { updateNavItem } from './update-nav-item';
import { updateDataType } from './update-data-type';

/**
 *
 */
export function* statComparisonRootSaga() {
    yield all([
        takeEvery(FETCH_STAT_COMPARISON.REQUEST, fetchStatComparison),
        takeEvery(UPDATE_NAV_ITEM.REQUEST, updateNavItem),
        takeEvery(UPDATE_DATA_TYPE.REQUEST, updateDataType)
    ]);
}
