import { cfsApi } from 'store/modules/api/cfs-api';

// RTK Query update: Inject endpoints within a component (https://redux-toolkit.js.org/rtk-query/usage/queries)
const draftTrackerApi = cfsApi.injectEndpoints({
    endpoints: (build) => ({
        getDraftByYear: build.query({
            query: (year) => `/draft/year/${year}`,
            transformResponse: (response) => response.draftFeeds?.[0]
        })
    })
});

export const { useGetDraftByYearQuery } = draftTrackerApi;
