import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'common/react/components/select';
import { getTranslation } from 'common/react/utils/translations';
import { draftTrackerNavSelector } from 'store/modules/draft-tracker/leaderboard/selectors';
import { useGetDraftByYearQuery } from '../api/draft-tracker-api';

export const PositionsNavItem = ({ year }) => {
    const dispatch = useDispatch();
    const nav = useSelector(draftTrackerNavSelector);
    
    const { data: draftData } = useGetDraftByYearQuery(year);

    return (
        <Select
            items={positions}
            itemToString={(position) => position?.label}
            initialSelectedItem={positions[0]}
            activeItem={positions.find(
                (item) => item.value === nav.position
            )}
            label={getTranslation('label.position')}
            isDisabled={
                draftData && draftData.selections?.length ? false : true
            }
            onSelectedItemChange={({ selectedItem }) => {
                dispatch(
                    PULSE.app.redux.actions.draftTracker.updateNav({
                        name: 'position',
                        value: selectedItem.value
                    })
                );
                PULSE.app.tracking.recordEvent(
                    PULSE.app.common.CONSTANTS.TRACKING.EVENTS.FILTERS.APPLY, {
                        eventData: {
                            /* eslint-disable-next-line camelcase */
                            component_name: PULSE.app.common.CONSTANTS.TRACKING.COMPONENT_NAMES.DRAFT_TRACKER.FILTERS.POSITION
                        }
                    }
                );
            }}
            className="select--rounded"
        />
    );
};

PositionsNavItem.propTypes = {
    year: PropTypes.string.isRequired
};

const positions = [
    {
        label: 'All Positions',
        value: -1
    },
    {
        label: 'Key Defender',
        value: 'KEY_DEFENDER'
    },
    {
        label: 'Medium Defender',
        value: 'MEDIUM_DEFENDER'
    },
    {
        label: 'Midfielder',
        value: 'MIDFIELDER'
    },
    {
        label: 'Midfielder Forward',
        value: 'MIDFIELDER_FORWARD'
    },
    {
        label: 'Medium Forward',
        value: 'MEDIUM_FORWARD'
    },
    {
        label: 'Key Forward',
        value: 'KEY_FORWARD'
    },
    {
        label: 'Ruck',
        value: 'RUCK'
    }
];
