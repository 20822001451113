import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param {number} props.stat The main stat value.
 * @param {string} props.benchmark The benchmark key.
 * @param {Array} props.compareValues The stats values to compare.
 * @param {string} props.teamAbbr The player's team abbreviation.
 * @param {number} props.index The index for the direction of the bar.
 * @param {number} props.showBenchmarking Should we show the benchmarked version.
 * @returns {JSX.Element} Component.
 */
export const BenchmarkBar = ({
    stat,
    benchmark,
    compareValues,
    teamAbbr = '',
    index = 0,
    showBenchmarking
}) => {
    const direction = index === 0 ? 'left' : 'right';
    return (
        <div className="benchmarking-bar__container">
            <div
                key={stat}
                className={`benchmarking-bar__stat benchmarking-bar__stat--${direction} ${
                    benchmark && showBenchmarking
                        ? 'benchmarking-bar__stat--' +
                          PULSE.app.common.statsBenchmarkingMap[benchmark]
                              .CLASS_TEXT
                        : stat >= (compareValues[0] + compareValues[1]) / 2 ||
                          !compareValues[0] ||
                          !compareValues[1]
                        ? teamAbbr
                        : ''
                }`}
                style={{
                    maxWidth:
                        (stat /
                            (((compareValues[0] &&
                                compareValues[0] > compareValues[1]) ||
                            !compareValues[1]
                                ? compareValues[0]
                                : compareValues[1]) *
                                (PULSE.app.common.statsBenchmarkingMap[
                                    benchmark
                                ]?.THRESHHOLD_BUFFER ?? 1))) *
                            100 +
                        '%'
                }}
            ></div>
        </div>
    );
};

BenchmarkBar.propTypes = {
    stat: PropTypes.number.isRequired,
    benchmark: PropTypes.string,
    compareValues: PropTypes.array.isRequired,
    teamAbbr: PropTypes.string,
    index: PropTypes.number,
    showBenchmarking: PropTypes.bool
};
