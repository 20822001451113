import { MEDIA_TAGS_BY_STATUS } from './media-tags';

/**
 * Loop through the tags in tagPriorities and look for a video with that tag, the
 * first video it finds with the tag will get returned.
 *
 * @param {Array} videos - An array of videos to look through
 * @param {Array} tagPriorities - The priority order for tags
 * @returns {object} - Object that contains the highest prio video along
 * the tag it was found for
 */
export function prioritiseVideo(
    videos = [],
    tagPriorities = MEDIA_TAGS_BY_STATUS[
        PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED
    ]
) {
    for (let tag of tagPriorities) {
        const foundVideo = videos.find((item) =>
            item.tags.find((itemTag) => itemTag.label === tag)
        );
        if (foundVideo) {
            return {
                video: foundVideo,
                category: tag
            };
        }
    }

    return {};
}
