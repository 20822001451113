import produce from 'immer';
import {
    SET_PLAYER_SELECTION_MODAL_VISIBLE,
    SET_FILTER_ITEM,
    SET_RESET_FILTERS,
    SET_PLAYER,
    SET_REMOVE_PLAYER
} from '../actions';
import { setFilterItem, setResetFilters } from '../../shared/reducer';

/**
 * @typedef {object} PlayerComparisonState
 * @property {object} playerComparison
 */

/**
 * @typedef {object} PlayerComparisonActions
 * @property {string} type
 * @property {any} payload
 */

/**
 * @type {StatsGlossaryState}
 */
const initialState = {
    modalVisible: false,
    filters: {
        positions: [],
        teams: []
    },
    playerOne: null,
    playerTwo: null
};

/**
 * @param {PlayerComparisonState} state - The Redux state.
 * @param {PlayerComparisonActions} action - The Redux action.
 * @returns {PlayerComparisonState} The updated Redux state.
 */
export const playerComparisonReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_PLAYER_SELECTION_MODAL_VISIBLE:
                draft.modalVisible = action.payload;
                break;
            case SET_FILTER_ITEM:
                setFilterItem(draft, action.payload);
                break;
            case SET_RESET_FILTERS:
                setResetFilters(draft);
                break;
            case SET_PLAYER: {
                const { id, key } = action.payload;
                // If supplied with a key, try and update that side
                if (key) {
                    if (key === 1) {
                        draft.playerOne = id;
                    } else if (key === 2) {
                        draft.playerTwo = id;
                    }
                    // Otherwise, check for empty players
                } else if (!state.playerOne) {
                    draft.playerOne = id;
                } else if (!state.playerTwo) {
                    draft.playerTwo = id;
                }
                break;
            }
            case SET_REMOVE_PLAYER:
                if (state.playerOne === action.payload.id) {
                    draft.playerOne = null;
                } else if (state.playerTwo === action.payload.id) {
                    draft.playerTwo = null;
                }
                break;
            default:
                draft;
        }
    });
};
