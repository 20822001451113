import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { SiteSettingsModalWrapper } from './SiteSettingsModalWrapper';

export const SiteSettingsModalProvider = ({ store, data }) => {
    return (
        <Provider store={store}>
            <SiteSettingsModalWrapper {...data} />
        </Provider>
    );
};

SiteSettingsModalProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
