import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getTranslation } from 'common/react/utils/translations';

/**
 * @param {object} props - Props.
 * @param props.children
 * @param props.hideReset
 * @param props.isFiltersVisibleHandler
 * @param props.setResetFilterBtnStyles
 * @param props.setResetFns
 * @param props.setResetFnsProp
 * @returns {JSX.Element} Component.
 */
export const StatsSectionNavFilterItems = ({
    children,
    hideReset = false,
    isFiltersVisibleHandler,
    setResetFilterBtnStyles,
    setResetFnsProp
}) => {
    const [resetFns, setResetFns] = useState([]);

    const addOnResetCallback = (resetFn) => {
        if (setResetFnsProp) {
            setResetFnsProp((fns) => [...fns, resetFn]);
        } else {
            setResetFns((fns) => [...fns, resetFn]);
        }
    };

    const resetHandler = (resetFunctions) => {
        setResetFilterBtnStyles(true);
        resetFunctions.forEach((func) => func());
        isFiltersVisibleHandler();
    };

    return (
        <>
            {children && (
                <div className="stats-leaders-filters">
                    {React.Children.map(children, (child) => {
                        if (child) {
                            return (
                                <div className="stats-leaders-filters__item-wrapper">
                                    {React.cloneElement(child, {
                                        addOnResetCallback
                                    })}
                                </div>
                            );
                        }
                    })}
                    {!hideReset && (
                        <div className="stats-leaders-filters__item-wrapper stats-leaders-filters__item-wrapper--reset">
                            <button
                                className="stats-leaders-filter-reset-button"
                                onClick={() =>
                                    resetHandler(
                                        setResetFnsProp
                                            ? setResetFnsProp
                                            : resetFns
                                    )
                                }
                            >
                                {getTranslation(
                                    'label.statsLeaders.filters.resetButtonText'
                                )}
                            </button>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

StatsSectionNavFilterItems.defaultProps = {
    name: 'StatsSectionNavFilterItems'
};

StatsSectionNavFilterItems.propTypes = {
    children: PropTypes.any,
    hideReset: PropTypes.bool,
    isFiltersVisibleHandler: PropTypes.func,
    setResetFilterBtnStyles: PropTypes.func,
    setResetFnsProp: PropTypes.func
};
