import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { LastRoundHighs } from '../components/last-round-highs';

/**
 * @param {any} props - Props.
 * @returns {JSX.Element} Component.
 */
export const LastRoundHighsProvider = ({ store, data }) => (
    <Provider store={store}>
        <div className="wrapper">
            <LastRoundHighs {...data} />
        </div>
    </Provider>
);

LastRoundHighsProvider.propTypes = {
    store: PropTypes.object,
    data: PropTypes.object
};
