import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import { ShareCopiedPopup } from 'widgets/interactive-event-map/js/components/ShareCopiedPopup';

export const SharePopup = ({ shareLink, ticketsLink, onBlur }) => {
    const [showMessage, setShowMessage] = useState(false);

    const canShare = navigator.canShare;

    const handleClick = () => {
        if (!showMessage) {
            // if device supports https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
            if (canShare) {
                let data = {
                    url: shareLink
                };

                navigator.share(data);
            } else {
                navigator.clipboard.writeText(shareLink);

                setShowMessage(true);

                setTimeout(() => {
                    setShowMessage(false);
                }, 4000);
            }
        }
    };

    return (
        <div className="events-listing__share-popup">
            {shareLink && (
                <>
                    <button
                        className="events-listing__share-popup-inner"
                        onClick={handleClick}
                        onBlur={onBlur}
                    >
                        <span>{getTranslation('label.copy-link')}</span>

                        <SvgIcon icon="link" className="icon" />
                    </button>

                    {showMessage && (
                        <ShareCopiedPopup showMessage={showMessage} />
                    )}
                </>
            )}

            {ticketsLink && (
                <a
                    href={ticketsLink}
                    target="_blank"
                    className="events-listing__share-popup-inner"
                    rel="noreferrer"
                    onBlur={onBlur}
                >
                    <span>{getTranslation('label.buy-tickets')}</span>

                    <SvgIcon icon="tickets" className="icon" />
                </a>
            )}
        </div>
    );
};

SharePopup.propTypes = {
    shareLink: PropTypes.string,
    ticketsLink: PropTypes.string,
    onBlur: PropTypes.func
};
