import React from 'react';
import PropTypes from 'prop-types';

import { TradeRadio } from './TradeRadio';
import { useGetAllBroadcastEventsQuery } from 'common/store/modules/api/content-api';
import { filterAndModelEvents } from './util/filterAndModelEvents';

export const TradeRadioLoader = ({
    config,
    widgetClass,
    anchorName,
    eventType,
    accountId,
    policyKey,
    playerId
}) => {
    let configObj = {};
    try {
        configObj = JSON.parse(config);
    } catch (error) {
        console.error(
            '[TradeRadioLoader] There was a problem parsing the incoming config object',
            error
        );
    }

    const brightcoveProps = {
        anchorName,
        accountId,
        policyKey,
        playerId
    };

    const { event } = useGetAllBroadcastEventsQuery(
        {
            fromDate: luxon.DateTime.utc()
                .minus({ day: 1 })
                .startOf('hour')
                .toISO({ suppressMilliseconds: true }),
            pageSize: 100
        },
        {
            selectFromResult: ({ data }) => ({
                event: filterAndModelEvents(data, eventType)
            })
        }
    );

    return event ? (
        <TradeRadio
            event={event}
            config={configObj}
            brightcoveProps={brightcoveProps}
            widgetClass={widgetClass}
        />
    ) : null;
};

TradeRadioLoader.propTypes = {
    config: PropTypes.string.isRequired,
    widgetClass: PropTypes.string,
    anchorName: PropTypes.string.isRequired,
    eventType: PropTypes.string,
    accountId: PropTypes.string.isRequired,
    policyKey: PropTypes.string,
    playerId: PropTypes.string.isRequired
};
