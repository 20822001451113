/**
 * Tags used to fetch media for fixtures
 *
 * @param {object}
 */
const PROGRAM_CATEGORIES = {
    highlights: 'ProgramCategory:Match Highlights',
    miniMatches: 'ProgramCategory:Mini-Matches',
    replays: 'ProgramCategory:Match Replays',
    preview: 'match-preview'
};

/**
 * Tags to use when fetching media for fixtures of different statuses and their
 * priority. For example if we find multiple videos for a game, we will show them
 * based on the order the tags are listed here.
 *
 * @param {object}
 */
export const MEDIA_TAGS_BY_STATUS = {
    [PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING]: [
        PROGRAM_CATEGORIES.preview
    ],
    [PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED]: [
        PROGRAM_CATEGORIES.highlights,
        PROGRAM_CATEGORIES.miniMatches,
        PROGRAM_CATEGORIES.replays
    ]
};
