import React from 'react';
import PropTypes from 'prop-types';

export const WidgetHeader = ({ title }) => (
    <header className={`widget-header`}>
        <div className="widget-header__content">
            <h2 className="widget-header__title">{title}</h2>
        </div>
    </header>
);

WidgetHeader.propTypes = {
    title: PropTypes.string
};
