import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import { useGetMappedMatchesQuery } from 'common/store/modules/api/afl-api';
import { PlayerProfileLink } from 'widgets/statspro/js/components/common/PlayerProfileLink';

const CLASS_BLOCK = 'player-stats-tooltip-link';

/**
 * @param {object} props - Props.
 * @param {object} props.player - Player.
 * @returns {JSX.Element} Component.
 */
export const TooltipLink = (props) => {
    // Get mapped Champion Match Ids to Pulse Match Ids
    const { data: matches } = useGetMappedMatchesQuery();

    // Get Pulse Match ID from round number data to build match link URL
    const roundNumber = props?.cell?.column?.id?.replace('round', '');

    const foundRound = props?.rounds?.roundStats?.find((round) => {
        return round.roundNumber === parseInt(roundNumber);
    });

    const pulseMatchId = useMemo(() => {
        return matches?.[foundRound?.matchId];
    }, [matches, foundRound]);

    return (
        <>
            <div className={`${CLASS_BLOCK}__wrapper`}>
                {props.player.playerId && (
                    <PlayerProfileLink
                        classes={CLASS_BLOCK}
                        player={{
                            playerId: props.player.playerId,
                            firstName: props?.player?.playerDetails?.givenName,
                            surname: props?.player?.playerDetails?.surname
                        }}
                    >
                        <div className={`${CLASS_BLOCK}__content`}>
                            <SvgIcon
                                className={`${CLASS_BLOCK}__icon`}
                                icon="person"
                                folder="elements"
                                subfolder="stats"
                            />
                            <span>{getTranslation('label.stats.profile')}</span>
                        </div>
                    </PlayerProfileLink>
                )}
                <a
                    href={
                        pulseMatchId ? `/matches/${pulseMatchId}` : `/matches`
                    }
                    className={`${CLASS_BLOCK}`}
                >
                    <div className={`${CLASS_BLOCK}__content`}>
                        <SvgIcon
                            className={`${CLASS_BLOCK}__icon`}
                            icon="football"
                        />
                        <span>{getTranslation('label.match-centre')}</span>
                    </div>
                </a>
            </div>
        </>
    );
};

TooltipLink.propTypes = {
    cell: PropTypes.object.isRequired,
    rounds: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired
};
