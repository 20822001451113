import { useSelector } from 'react-redux';

import { performanceChartNavSelector } from 'store/modules/statspro/performance-chart/selectors';

export const useCompSeasonProvider = () => {
    const nav = useSelector(performanceChartNavSelector);
    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );

    // If the nav.season.id is not defined (example: the stats are showing the career stats)
    // takes the latest season from the list (ordered by ID descending)
    let compSeason;

    if (typeof nav.season.id === 'undefined' || nav.season.id === -1) {
        compSeason = seasons
            .filter((season) => season.currentRoundNumber > 0)
            .sort((seasonA, seasonB) => (seasonA.id < seasonB.id ? 1 : -1))[0];
    } else {
        compSeason = seasons?.find((season) => season.id === nav.season.id);
    }

    return compSeason?.providerId;
};
