import { createSelector } from 'reselect';
import { statsLeadersNavSelector } from '.';

/**
 * @returns {( object ) => 'unknown'|'seasons'|'rounds'} Stats type.
 */
export const statsTypeSelector = createSelector(
    statsLeadersNavSelector,
    (nav) => {
        const { id } = nav.round;
        if (id === null) {
            return 'unknown';
        }
        if (id === -1) {
            return 'seasons';
        }
        return 'rounds';
    }
);
