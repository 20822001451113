import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const BroadcasterItem = ({ broadcaster }) => {
    const icon = `broadcaster-${broadcaster.name
        .toLowerCase()
        .replace(' ', '-')}`;

    return (
        <div className="fixtures__broadcaster-item">
            <SvgIcon
                icon={icon}
                className="fixtures__broadcaster-logo"
                folder="elements"
                subfolder="broadcaster"
            />
        </div>
    );
};

BroadcasterItem.propTypes = {
    broadcaster: PropTypes.object.isRequired
};
