import React from 'react';
import PropTypes from 'prop-types';

import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';
import { SiteSettingsModal } from '../components/SiteSettingsModal';

export const SiteSettingsModalWrapper = ({ regionsPage }) => {
    const modalType = useSingleSiteSetting('modalType');

    return modalType?.length ? (
        <SiteSettingsModal modalType={modalType[0]} regionsPage={regionsPage} />
    ) : null;
};

SiteSettingsModalWrapper.propTypes = {
    regionsPage: PropTypes.string.isRequired
};
