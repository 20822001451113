import React from 'react';
import { useSelector } from 'react-redux';

import {
    performanceChartNavSelector,
    seasonSelector,
    roundSelector
} from 'store/modules/statspro/performance-chart/selectors';
import { getTranslation } from 'common/react/utils/translations';

import { STATS_CATEGORY_IDS } from 'widgets/statspro/js/components/common/config/performance-stats';

/**
 * @typedef {object} Props
 * @property {object} season
 * @property {object} round
 */

/**
 * @returns {JSX.Element} Loader or heading.
 */
export const Heading = () => {
    const nav = useSelector(performanceChartNavSelector);

    const season = useSelector(seasonSelector);
    const round = useSelector(roundSelector);

    // eslint-disable-next-line no-magic-numbers
    const categoryId = [STATS_CATEGORY_IDS[nav.category]];

    // Regular expression pattern to match four-digit years starting with 2 from 2000 to 2100
    const regexPattern = /(20[0-9]{2}|2100)/;
    const match = season?.name.match(regexPattern);

    return (
        <>
            <strong>
                {getTranslation('label.performanceChart.' + categoryId)}
            </strong>{' '}
            -{' '}
            {!season
                ? getTranslation('label.stats.career')
                : `${match[0]} ${getTranslation('label.stats.season')} `}
            {round &&
                `(${getTranslation('label.match.round')} ${round.roundNumber})`}
        </>
    );
};
