import React from 'react';
import PropTypes from 'prop-types';

import { MapLocationSelectorOptionImage } from './MapLocationSelectorOptionImage';

export const MapLocationSelectorOption = ({
    location,
    selectedVenue,
    handleImageUrl
}) => {
    return (
        <li key={location.name}>
            <button
                className={`js-option-btn map-location-selector__options-item ${
                    selectedVenue.name === location.name
                        ? 'map-location-selector__options-item--selected'
                        : ''
                }`}
                data-venue-id={location.id}
            >
                <div className="map-location-selector__options-image-container">
                    {location.imgRef.reference.length > 0 ? (
                        <MapLocationSelectorOptionImage
                            location={location}
                            handleImageUrl={handleImageUrl}
                        />
                    ) : null}
                </div>
                <span className="js-option-btn__name">{location.name}</span>
            </button>
        </li>
    );
};

MapLocationSelectorOption.propTypes = {
    location: PropTypes.shape({
        name: PropTypes.string,
        imgRef: PropTypes.object,
        id: PropTypes.string
    }),
    selectedVenue: PropTypes.shape({
        name: PropTypes.string,
        imgRef: PropTypes.string
    }),
    handleImageUrl: PropTypes.func
};
