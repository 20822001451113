import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGetContentQuery } from 'store/modules/api/content-api';

export const Pill = ({
    link,
    index,
    activeFilter,
    updateActiveFilterHandler,
    seasonId,
    roundId,
    matchID,
    setVisibleFilters
}) => {
    const tag = link.filter;

    const requestConfig = {
        type: 'VIDEO',
        params: {
            pageSize: 50,
            referenceExpression: `AFL_COMPSEASON:${seasonId} and AFL_ROUND:${roundId} ${
                matchID ? `and AFL_MATCH:${matchID}` : ''
            }`,
            tagNames: tag
        }
    };

    const { data: requestData = [] } = useGetContentQuery(requestConfig);
    const tagData = requestData?.content;

    useEffect(() => {
        if (tagData?.length) {
            setVisibleFilters((prevState) =>
                prevState.includes(index) ? prevState : [...prevState, index]
            );
        } else {
            setVisibleFilters((prevState) =>
                prevState.includes(index)
                    ? prevState.filter((item) => item !== index)
                    : prevState
            );
        }
    }, [tagData, index, setVisibleFilters]);

    return tagData?.length ? (
        <li
            key={index}
            className="video-hub-video-listing__list js-video-filter-item"
        >
            <button
                onClick={() => updateActiveFilterHandler(index)}
                className={`js-filter-option video-list__filters-item js-default-filter
                        ${activeFilter === index ? ' is-filter-selected' : ''}
                    `}
            >
                {link.label}
            </button>
        </li>
    ) : null;
};

Pill.propTypes = {
    link: PropTypes.object,
    index: PropTypes.number,
    activeFilter: PropTypes.number,
    updateActiveFilterHandler: PropTypes.func,
    seasonId: PropTypes.number,
    roundId: PropTypes.number,
    matchID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setVisibleFilters: PropTypes.func
};
