import React from 'react';
import PropTypes from 'prop-types';
import { getTranslation } from '../../utils/translations';
import { SvgIcon } from '../SvgIcon';

/**
 * Component renders the translated benchmarking placeholder text
 *
 * @param {object} props - Props.
 * @param {string} props.translationId - [OPTIONAL] Translation id. Default 'label.statsLeaders.benchmarking.placeholder'
 * @returns {JSX.Element} Component.
 */
export const BenchmarkingPlaceholder = ({
    translationId = 'label.statsLeaders.benchmarking.placeholder'
}) => (
    <div className="benchmarking-explainer">
        <div className="benchmarking-explainer__element">
            <SvgIcon
                icon="help-circle"
                className="benchmarking-explainer__icon"
            />
            <span className="benchmarking-explainer__text">{`${
                getTranslation(translationId) ?? <>&nbsp;</>
            }`}</span>
        </div>
    </div>
);

BenchmarkingPlaceholder.propTypes = {
    translationId: PropTypes.string
};
