import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { groupMatchesByDate } from 'widgets/fixtures/js/utils/group-matches-by-date';
import { groupMatchesByRoundName } from 'widgets/fixtures/js/utils/group-matches-by-roundName';
import { filtersSelector } from 'common/store/modules/competition-nav/selectors';
import { useGetRoundsQuery } from 'store/modules/api/afl-api';
import {
    findLastInstanceAndInsert,
    filteredTeams
} from 'widgets/fixtures/js/utils/helpers';

const FLATTEN_DEPTH = 4;
const MAX_ROUNDS = 50;

/**
 * @param {Array} data the matches data array.
 * @param {string} timezone the timezone string.
 * @returns {Array} matchItems
 */
export const useGroupMatches = (data, timezone) => {
    /**
     * (1) Groups Fixtures first by their date or a generic TBCPC if they are
     * cancelled/TBC/postponded
     * (2) Then goes through each date in the grouped object and for each day
     * groups the matches by round name and then converts that object into an
     * array, e.g. ['Round 1', {match}, 'Round 2', {match}]
     * (3) Takes the complete object converts it to an array and flattens it
     * completely so we end up with something like this:
     * ["20240314", "Round 1", {match}, "20240315" ... etc]
     * (4) Goes through the flattened array from step 3 and adds a "bye" object
     * at the end of "round itteration". If the round doesn't exist due to filters
     * it creates it.
     */
    const filters = useSelector(filtersSelector);
    const skipRoundsQuery = !filters.teams.length || !data[0]?.compSeason?.id;

    const { data: roundsData } = useGetRoundsQuery(
        {
            compseasonId: data[0]?.compSeason?.id,
            params: {
                pageSize: MAX_ROUNDS
            }
        },
        {
            skip: skipRoundsQuery
        }
    );

    const matchItems = useMemo(() => {
        // Group results based on start day/month
        if (data) {
            // (1)
            const groupedByDate = groupMatchesByDate(data, timezone);
            // (2)
            for (const date in groupedByDate) {
                if (Object.prototype.hasOwnProperty.call(groupedByDate, date)) {
                    const groupedByRoundName = groupMatchesByRoundName(
                        groupedByDate[date]
                    );
                    groupedByDate[date] = Object.entries(groupedByRoundName);
                }
            }

            // (3)
            let flattenResult;

            flattenResult = Object.entries(groupedByDate).flat(FLATTEN_DEPTH);

            // (4)
            if (filters.teams.length && roundsData) {
                let arrayWithByes = [];

                for (const round of roundsData) {
                    if (
                        round.byes.length &&
                        round.byes.filter(
                            (bye) => bye.id === parseInt(filters.teams)
                        )
                    ) {
                        const newRound = { ...round };
                        newRound.isBye = true;
                        const teamsWithBye = filteredTeams(newRound, filters);

                        if (teamsWithBye.length) {
                            arrayWithByes = findLastInstanceAndInsert(
                                flattenResult,
                                newRound
                            );
                        }
                    }
                }

                if (arrayWithByes.length) {
                    return arrayWithByes;
                }
            }
            return flattenResult;
        }
    }, [data, timezone, filters, roundsData]);

    return matchItems;
};
