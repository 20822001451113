import React from 'react';
import PropTypes from 'prop-types';

export const BodyCellPoints = ({ cell, cellProps }) => {
    const points = cell?.value || '0';

    return (
        <td
            {...cellProps}
            className="stats-table__cell stats-table__cell--points"
        >
            <span className="career-and-season-stats__position-cell-label">
                {points}
            </span>
        </td>
    );
};

BodyCellPoints.propTypes = {
    cell: PropTypes.object,
    cellProps: PropTypes.object
};
