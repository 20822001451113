import React from 'react';
import PropTypes from 'prop-types';
import { WidgetHeader } from 'common/react/components/WidgetHeader';

/**
 * @param {object} props - Props.
 * @param {string} props.title - The text to show in the header
 * @returns {JSX.Element} Component.
 */
export const StatsSectionHeading = ({ title }) => (
    <>{title && <WidgetHeader title={title} />}</>
);

StatsSectionHeading.defaultProps = {
    name: 'StatsSectionHeading'
};

StatsSectionHeading.propTypes = {
    title: PropTypes.string
};
