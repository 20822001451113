// eslint-disable-next-line no-unused-vars
import { ViewByMatchState } from '../types';
import '../../types';

/**
 * @param {ViewByMatchState} draft - Draft state.
 * @param {object} payload - Action payload.
 */
export const setItem = (draft, payload) => {
    const { item, value } = payload;

    draft[item] = value;
};
