import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../setup-react';

// ===== Signals ============================================================ //

// Nav
export const UPDATE_NAV_ITEM = createSignalAction(
    'events-listing',
    'UPDATE_NAV_ITEM'
);

// Filters
export const UPDATE_FILTER_ITEM = createSignalAction(
    'events-listing',
    'UPDATE_FILTER_ITEM'
);

// ===== Deltas ============================================================= //

// Nav
export const SET_NAV_ITEM = createDeltaActionType(
    'events-listing',
    'SET_NAV_ITEM'
);
export const setNavItem = createActionCreator(SET_NAV_ITEM);

// Filters
export const SET_FILTER_ITEM = createDeltaActionType(
    'events-listing',
    'SET_FILTER_ITEM'
);
export const setFilterItem = createActionCreator(SET_FILTER_ITEM);
