import React from 'react';
import PropTypes from 'prop-types';

export const HeaderCellNextOpponent = ({ column }) => {
    return (
        <th
            className="stats-table__header-cell stats-table__header-cell--next-opponent"
            scope="col"
        >
            <button className="stats-table__header-cell-button" disabled={true}>
                <span>{column.render('Header')}</span>
            </button>
        </th>
    );
};

HeaderCellNextOpponent.propTypes = {
    column: PropTypes.object,
    keyProp: PropTypes.string
};
