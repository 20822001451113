import { combineReducers } from 'redux';
import { playerStatsReducer } from './player-stats/reducer';
import { playerStatsRootSaga } from './player-stats/sagas';

const sagas = [playerStatsRootSaga];

const rootReducer = combineReducers({
    playerStats: playerStatsReducer
});

// @ts-expect-error
if (window.PULSE.app.redux) {
    // @ts-expect-error
    window.PULSE.app.redux.sagas.mcPlayerStats = { root: sagas };
    // @ts-expect-error
    window.PULSE.app.redux.reducers.mcPlayerStats = { root: rootReducer };
} else {
    // @ts-expect-error
    window.PULSE.app.redux = {
        reducers: {
            mcPlayerStats: {
                root: rootReducer
            }
        },
        sagas: {
            mcPlayerStats: {
                root: sagas
            }
        }
    };
}
