import { useSelector } from 'react-redux';

import { navSelector } from 'common/store/modules/competition-nav/selectors';
import { compSeasonSelector } from 'common/store/modules/competition-nav/selectors/compseason-selector';
import { roundSelector } from 'common/store/modules/competition-nav/selectors/round-selector';
import { roundLoadErrorSelector } from 'common/store/modules/competition-nav/selectors/round-load-error-selector';

/**
 * Using the nav selector to target elements in the store, return the
 * competitionId, compseason object and round object that we should be using
 * when rendering information based upon the competition nav's current set
 * filters
 *
 * @returns {object} - Contains the comptitionId, compSeason object and round object
 */
export function useGetCurrentCompFilters() {
    const nav = useSelector(navSelector);
    const compSeason = useSelector(compSeasonSelector);
    const round = useSelector(roundSelector);
    const roundLoadError = useSelector(roundLoadErrorSelector);

    return {
        competitionId: nav?.competition?.id,
        competitionPid: nav?.competition?.providerId,
        compSeason,
        round,
        roundLoadError
    };
}
