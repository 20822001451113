import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import {
    ClubNavItem,
    Search,
    SeasonNavItem
} from 'widgets/brownlow-tracker/js/components/Leaderboard/components/nav-items';
import { SettingsButton } from 'common/react/components/SettingsButton';
import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { TeamFilterItem } from 'common/react/components/competition-nav/filter-items';
import { useGetCurrentCompFilters } from '../hooks/use-get-current-comp-filters';
import { useResetTeamFilter } from '../hooks/use-reset-team-filter';
import { BrownlowPredictor } from './BrownlowPredictor';
import { leaderboardNavSelector } from 'common/store/modules/brownlow-tracker/leaderboard/selectors';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import { useLeaderboardNavSync } from 'widgets/brownlow-tracker/js/components/Leaderboard/hooks/use-leaderboard-nav-sync';

export const BPLeaderboard = ({
    seasonsToShow,
    seasonId,
    compId,
    seasonPid
}) => {
    const classPrefix = 'brownlow-leaderboard-predictor brownlow-leaderboard';
    const windowSize = useWindowSize();

    const nav = useSelector(leaderboardNavSelector);
    const options = useCompetitionNavigationOptions(nav);

    options.defaultSeason = seasonPid ?? seasonPid;
    options.seasonsToShow = seasonsToShow ?? seasonsToShow;
    useLeaderboardNavSync(options);

    const isResponsive = () =>
        windowSize.width < PULSE.app.measurements.desktop;
    const [showResponsiveItems, setShowResponsiveItems] = useState(
        isResponsive()
    );

    const currentFilters = useGetCurrentCompFilters();

    useEffect(() => {
        setShowResponsiveItems(isResponsive());
    }, [windowSize.width]);

    return (
        <div className={classPrefix}>
            <StatsSection.Main>
                <StatsSection.Nav>
                    <StatsSection.NavItems>
                        <SeasonNavItem
                            seasonsToShow={seasonsToShow}
                            seasonId={seasonId}
                            compId={compId}
                        />
                        {!showResponsiveItems ? (
                            <ClubNavItem classes={'u-margin-right-auto'} />
                        ) : null}
                        {!showResponsiveItems ? (
                            <div className={`${classPrefix}-nav__extras`}>
                                <Search />
                            </div>
                        ) : null}
                    </StatsSection.NavItems>
                    {showResponsiveItems &&
                    currentFilters.competitionId &&
                    currentFilters.compSeason?.id ? (
                        <StatsSection.NavFilterItems>
                            <TeamFilterItem
                                currentFilters={currentFilters}
                                addOnResetCallback={useResetTeamFilter}
                                isBrownlow={true}
                            />
                        </StatsSection.NavFilterItems>
                    ) : null}

                    <StatsSection.SettingsButton>
                        <StatsSection.InfoButton tooltipContent="BPLeaderboardTooltip" />
                        <SettingsButton />
                    </StatsSection.SettingsButton>

                    {showResponsiveItems ? (
                        <StatsSection.SearchBarMobile>
                            <div className={`${classPrefix}-nav__extras`}>
                                <Search />
                            </div>
                        </StatsSection.SearchBarMobile>
                    ) : null}
                </StatsSection.Nav>
                <StatsSection.Content>
                    <StatsSection.ContentBody>
                        <BrownlowPredictor
                            seasonPid={seasonPid}
                            seasonId={seasonId}
                        />
                    </StatsSection.ContentBody>
                </StatsSection.Content>
            </StatsSection.Main>
        </div>
    );
};

BPLeaderboard.propTypes = {
    seasonsToShow: PropTypes.string,
    seasonId: PropTypes.string,
    compId: PropTypes.string,
    seasonPid: PropTypes.string
};
