import produce from 'immer';

// eslint-disable-next-line no-unused-vars
import { RoundByRoundState } from '../types';
// eslint-disable-next-line no-unused-vars
import { Action } from '../../../../types';
import { SET_NAV_ITEM } from '../actions';
import { setNavItem } from '../../shared/reducer';

/**
 * @type {RoundByRoundState}
 */
const initialState = {
    nav: {
        competition: {
            id: null
        },
        season: {
            id: null,
            providerId: null
        },
        round: null
    }
};

/**
 * @param {RoundByRoundState} state - The Redux state.
 * @param {Action} action - The Redux action.
 * @returns {RoundByRoundState} The updated Redux state.
 */
export const roundByRoundReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_NAV_ITEM:
                setNavItem(draft, action.payload);
                break;
            default:
                draft;
        }
    });
};
