import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

import { STATUS_KEYS } from '../utils/status-keys';

export const SelectionCalloutMsg = ({ setStatus }) => {
    /**
     * Update the status to MSG_SEEN after rendering this, so that it doesn't
     * appear on subsequent page reloads
     */
    useEffect(() => {
        setStatus(STATUS_KEYS.MSG_SEEN);
    }, []);

    return (
        <div className="selection-callout__message wrapper">
            <div className="selection-callout__message-container">
                <SvgIcon className="selection-callout__icon" icon="globe" />
                <p
                    className="selection-callout__message-text"
                    dangerouslySetInnerHTML={{
                        __html: getTranslation(`region.callout.message`)
                    }}
                ></p>
                <button
                    className="selection-callout__close"
                    onClick={() => setStatus(STATUS_KEYS.MSG_CLOSED)}
                    aria-label={getTranslation('label.region-selector.close')}
                >
                    <SvgIcon
                        className="icon selection-callout__close-icon"
                        icon="close"
                    />
                </button>
            </div>
        </div>
    );
};

SelectionCalloutMsg.propTypes = {
    setStatus: PropTypes.func.isRequired
};
