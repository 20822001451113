import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

import { TASponsor } from '../components/TASponsor';

export const TASponsorProvider = ({ store, data }) => {
    return (
        <Provider store={store}>
            <ErrorBoundary fallback={<></>}>
                <TASponsor {...data} />
            </ErrorBoundary>
        </Provider>
    );
};

export default TASponsorProvider;

TASponsorProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
