import { all, takeEvery } from 'redux-saga/effects';
import {
    FETCH_PERFORMANCE_CHART_STATS,
    UPDATE_DATA_TYPE,
    UPDATE_NAV_ITEM
} from '../actions';
import { fetchPerformanceChartStats } from './fetch-performance-chart-stats';
import { updateNavItem } from './update-nav-item';
import { updateDataType } from './update-data-type';

/**
 *
 */
export function* performanceChartRootSaga() {
    yield all([
        takeEvery(
            FETCH_PERFORMANCE_CHART_STATS.REQUEST,
            fetchPerformanceChartStats
        ),
        takeEvery(UPDATE_NAV_ITEM.REQUEST, updateNavItem),
        takeEvery(UPDATE_DATA_TYPE.REQUEST, updateDataType)
    ]);
}
