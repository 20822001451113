// eslint-disable-next-line no-unused-vars
import { PlayerModalState } from '../types';
import '../../types';

/**
 * @param {PlayerModalState} draft - Draft state.
 * @param {object} payload - Action payload.
 * @param payload.seasonId
 * @param payload.data
 */
export const setPlayerModalData = (draft, { data }) => {
    draft.data = data;
};
