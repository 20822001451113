import React from 'react';
import PropTypes from 'prop-types';

export const FixturePosition = ({ position }) => {
    return (
        <div className="fixtures__match-position">
            {!position ? (
                <div className="fixtures__match-position-empty" />
            ) : (
                PULSE.app.common.numberHelper.ordinalSuffix(position)
            )}
        </div>
    );
};

FixturePosition.propTypes = {
    position: PropTypes.number
};
