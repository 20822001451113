import React from 'react';
import PropTypes from 'prop-types';

import { getTranslation } from 'common/react/utils/translations';

export const TAEmpty = ({ notAnnouncedTitle, notAnnouncedDescription }) => {
    return (
        <div className="team-announcements__empty">
            <div className="team-announcements__empty-image-container">
                <img
                    src={`/resources/${window.RESOURCES_VERSION}/i/elements/empty-state.png`}
                    className="team-announcements__empty-image-large"
                />
            </div>
            <div className="team-announcements__empty-text-container">
                <h3 className="team-announcements__empty-heading">
                    {notAnnouncedTitle
                        ? notAnnouncedTitle
                        : getTranslation(
                              'label.teamannouncements.notannounced.title'
                          )}
                </h3>
                <p className="team-announcements__empty-text">
                    {notAnnouncedDescription
                        ? notAnnouncedDescription
                        : getTranslation(
                              'label.teamannouncements.notannounced.description'
                          )}
                </p>
            </div>
        </div>
    );
};

TAEmpty.propTypes = {
    notAnnouncedTitle: PropTypes.string,
    notAnnouncedDescription: PropTypes.string
};
