import React from 'react';
import PropTypes from 'prop-types';

import { getTranslation } from 'common/react/utils/translations';

export const MobileTableRoundHeaderRow = ({ cssClass, rowData }) => {
    return (
        <div className={`${cssClass}__body-row`}>
            <div className={`${cssClass}__row-header`}>
                {getTranslation('label.brownlow.predictor.round')}
            </div>

            {rowData.map((cell, cellIndex) => {
                return (
                    <div
                        className={`${cssClass}__round-header-cell`}
                        key={cellIndex}
                    >
                        {cell.column.Header}
                    </div>
                );
            })}
        </div>
    );
};

MobileTableRoundHeaderRow.propTypes = {
    cssClass: PropTypes.string,
    rowData: PropTypes.array
};
