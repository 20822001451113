import { put } from 'redux-saga/effects';
import { setItem } from '../actions';

/**
 * @typedef {object} UpdateItemAction
 * @property {string} type
 * @property {object} payload
 * @property {string} payload.value
 */

/**
 * @param {UpdateItemAction} action - The Redux action.
 */
export function* updateItem(action) {
    yield put(setItem(action.payload));
}
