import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useGetContentItemQuery } from 'common/store/modules/api/content-api';
import { getTranslation } from 'common/react/utils/translations';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { TravelIcon } from './TravelIcon';

export const InformationLocationPanel = ({ startingLocation }) => {
    const [selectedLocation, setSelectedLocation] = useState(startingLocation);

    useEffect(() => {
        /**
         * Wrapper for call to set the selected location based upon what's in
         * local storage.
         */
        function setLocation() {
            setSelectedLocation(
                PULSE.core.localStorage.getStorage('mapSelectedVenueId')
            );
        }

        window.addEventListener('mapLocationChange', setLocation);

        return () => {
            window.removeEventListener('mapLocationChange', setLocation);
        };
    }, []);

    const location = useGetContentItemQuery(
        {
            type: 'PLAYLIST',
            id: selectedLocation
        },
        {
            skip: !selectedLocation
        }
    );

    const promos = location?.data?.related.filter(
        (ref) => ref.type === 'PROMO'
    );

    const open = location?.data?.metadata?.active;
    const openingHours = location?.data?.description.split(',');

    return (
        <>
            <div className="interactive-event-map-small__panel">
                <div className="interactive-event-map-small__card interactive-event-map-small__card--key-info">
                    <div className="interactive-event-map-small__times">
                        <h3 className="interactive-event-map-small__title">
                            {getTranslation('label.openingTimes')}
                        </h3>
                        <div className="interactive-event-map-small__times-wrapper">
                            <div className="interactive-event-map-small__times-icon-wrapper">
                                <SvgIcon icon="clock" className="icon" />
                            </div>
                            <div className="interactive-event-map-small__times-text">
                                <p
                                    className={`interactive-event-map-small__status interactive-event-map-small__status--${
                                        open ? 'open' : 'closed'
                                    }`}
                                >
                                    {open ? 'Open' : 'Closed'}
                                </p>
                                <ul className="interactive-event-map-small__times-list">
                                    {openingHours?.map((item, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className="interactive-event-map-small__times-list-item"
                                                dangerouslySetInnerHTML={{
                                                    __html: item
                                                }}
                                            ></li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {location?.data?.metadata ? (
                        (location.data.metadata.linkOneText &&
                            location.data.metadata.linkOneUrl) ||
                        (location.data.metadata.linkTwoText &&
                            location.data.metadata.linkTwoUrl) ? (
                            <div className="interactive-event-map-small__actions">
                                {location.data.metadata.linkOneText &&
                                location.data.metadata.linkOneUrl ? (
                                    <a
                                        href={location.data.metadata.linkOneUrl}
                                        target="_blank"
                                        className="interactive-event-map-small__cta button-new button-new--primary"
                                        rel="noreferrer"
                                    >
                                        <SvgIcon
                                            icon="tickets-new"
                                            className="icon"
                                        />
                                        <span>
                                            {location.data.metadata.linkOneText}
                                        </span>
                                    </a>
                                ) : null}
                                {location.data.metadata.linkTwoText &&
                                location.data.metadata.linkTwoUrl ? (
                                    <a
                                        href={location.data.metadata.linkTwoUrl}
                                        target="_blank"
                                        className="interactive-event-map-small__cta button-new button-new--secondary"
                                        rel="noreferrer"
                                    >
                                        <SvgIcon icon="star" className="icon" />
                                        <span>
                                            {location.data.metadata.linkTwoText}
                                        </span>
                                    </a>
                                ) : null}
                            </div>
                        ) : null
                    ) : null}
                </div>
            </div>

            {promos?.length > 0 ? (
                <div className="interactive-event-map-small__card interactive-event-map-small__card--transport">
                    <h3 className="interactive-event-map-small__title">
                        {getTranslation('label.travelOptions')}
                    </h3>
                    <div className="interactive-event-map-small__transport-icons">
                        {promos?.map((promo) => (
                            <TravelIcon
                                key={`promo_id_${promo?.id}`}
                                id={promo?.id}
                            />
                        ))}
                    </div>
                </div>
            ) : null}
        </>
    );
};

InformationLocationPanel.prop;

InformationLocationPanel.propTypes = {
    startingLocation: PropTypes.string.isRequired
};
