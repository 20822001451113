import React from 'react';
import PropTypes from 'prop-types';

export const InformationCardDescription = ({
    selectedLocation,
    googlePlacesData
}) => {
    return (
        <section className="interactive-event-map__location-description-container interactive-event-map__section">
            <div className="interactive-event-map__location-description-text">
                {selectedLocation?.description && (
                    <p className="interactive-event-map__location-description">
                        {selectedLocation.description}
                    </p>
                )}
            </div>
            {googlePlacesData?.mainPhoto && (
                <img
                    className="interactive-event-map__location-description-img"
                    src={googlePlacesData?.mainPhoto}
                    alt={googlePlacesData?.name}
                />
            )}
        </section>
    );
};

InformationCardDescription.propTypes = {
    selectedLocation: PropTypes.shape({
        description: PropTypes.string
    }),
    googlePlacesData: PropTypes.shape({
        mainPhoto: PropTypes.string,
        name: PropTypes.string
    })
};
