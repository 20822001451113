import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { ModalTitle } from './ModalTitle';
import { MarketingPreferences } from './ModalMarketingPreferences';
import { getTranslation } from 'common/react/utils/translations';
import {
    salesforceSitApi,
    useRetrieveMarketingPrefsQuery,
    useUpdateMarketingPrefsMutation
} from 'store/modules/api/salesforce-sit-api';
import { getCreateUpdateBody } from 'common/store/modules/api/util/salesforce-helpers';

export const UpdateCommPrefModalWrapper = () => {
    const dispatch = useDispatch();
    const {
        data: marketingPrefs,
        isLoading: isLoadingPreferences,
        isError
    } = useRetrieveMarketingPrefsQuery();

    // If we've opened the update one when they don't have prefs some how, then open the create one instead
    useEffect(() => {
        if (isError) {
            PULSE.app.common.siteSettings.openSettingsModal('createComms');
        }
    });

    /**
     * Callback for when a toggle all is changed. This updates the rtk query
     * data so that the UI updates properly before we actually send of the new
     * preferences. This means we don't need to mirror preferences in our own
     * state. The changes will be wiped on page reload unless they are saved and
     * the mutation is fired.
     *
     * @param {boolean} value - Is toggle checked?
     */
    function handleToggleAllClick(value) {
        dispatch(
            salesforceSitApi.util.updateQueryData(
                'retrieveMarketingPrefs',
                // eslint-disable-next-line no-undefined
                undefined,
                (draftPrefs) => {
                    draftPrefs.subscriptions.forEach((sub) => {
                        sub.status = value;
                    });
                }
            )
        );
    }

    /**
     * Callback for when a toggle is changed. This updates the rtk query data so
     * that the UI updates properly before we actually send of the new
     * preferences. This means we don't need to mirror preferences in our own
     * state. The changes will be wiped on page reload unless they are saved and
     * the mutation is fired.
     *
     * @param {string} key - What preference is being toggled?
     * @param {boolean} value - Is toggle checked?
     */
    function handleToggleClick(key, value) {
        dispatch(
            salesforceSitApi.util.updateQueryData(
                'retrieveMarketingPrefs',
                // eslint-disable-next-line no-undefined
                undefined,
                (draftPrefs) => {
                    const subToUpdate = draftPrefs.subscriptions.find(
                        (sub) => sub.name === key
                    );
                    if (subToUpdate) {
                        subToUpdate.status = value;
                    }
                }
            )
        );
    }

    const [updatePreferences] = useUpdateMarketingPrefsMutation();

    /**
     * Callback for when the save button is clicked, fires of the mutation call
     * to update preferences with salesforce
     *
     * @param {boolean} privacyAgreed - Is the privacy checkbox ticked?
     */
    function handleSaveClick(privacyAgreed) {
        const body = getCreateUpdateBody({
            preferences: marketingPrefs.preferences,
            subscriptions: marketingPrefs.subscriptions,
            consents: {
                privacy: privacyAgreed,
                marketing: privacyAgreed
            }
        });

        updatePreferences(body);
    }

    return (
        <>
            <ModalTitle
                title={getTranslation('settingsModal.aflidSettings')}
                closeBtn={false}
            />

            {isLoadingPreferences ? (
                <Skeleton
                    baseColor="var(--color-neutral-white)"
                    // highlightColor="var(--fill-600-a08)"
                    height={600}
                />
            ) : marketingPrefs?.subscriptions ? (
                <MarketingPreferences
                    title={getTranslation(
                        'settingsModal.communicationPreferences.title'
                    )}
                    subtitle={getTranslation(
                        'settingsModal.communicationPreferences.subtitle'
                    )}
                    marketingPrefs={marketingPrefs}
                    onToggleAllClick={handleToggleAllClick}
                    onToggleClick={handleToggleClick}
                    onSaveClick={handleSaveClick}
                />
            ) : null}
        </>
    );
};
