import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { CompareHeader } from './components/CompareHeader';
import { ADD_PLAYER } from 'store/modules/statspro/player-comparison/actions';
import { playersSelector } from 'store/modules/statspro/player-comparison/selectors';

import { PlayerSelection } from './components/player-selection';

/**
 * @param root0
 * @param root0.data
 * @returns {JSX.Element} Component.
 */
export const PlayerComparison = ({ data }) => {
    const dispatch = useDispatch();
    const playerIds = useSelector(playersSelector);

    useEffect(() => {
        let playerIdFromURL = new URLSearchParams(location.search).get('playerOneId');
        let playerTwoIdFromURL = new URLSearchParams(location.search).get('playerTwoId');

        if (playerIdFromURL && playerIdFromURL !== 'null') {
            dispatch(ADD_PLAYER.request({ id: playerIdFromURL, key: 1 }));
        } else if (data['player-1'] && data['player-1'] !== '') {
            dispatch(ADD_PLAYER.request({ id: data['player-1'], key: 1 }));
        }

        if (playerTwoIdFromURL && playerTwoIdFromURL !== 'null') {
            dispatch(ADD_PLAYER.request({ id: playerTwoIdFromURL, key: 2 }));
        } else if (data['player-2'] && data['player-2'] !== '') {
            dispatch(ADD_PLAYER.request({ id: data['player-2'], key: 2 }));
        }
    }, []);

    return (
        <>
            <CompareHeader
                seasonId={data.compseasonPid}
                playerIds={playerIds}
            />
            <PlayerSelection
                compseason={data.compseason}
                compseasonPid={data.compseasonPid}
            />
        </>
    );
};

PlayerComparison.propTypes = {
    data: PropTypes.object.isRequired
};
