import React from 'react';
import PropTypes from 'prop-types';

import { BENCHMARKING } from './config/benchmarking';
import { SvgIcon } from '../SvgIcon';

/**
 * @param {object} props - Props.
 * @param {'ELITE'|'ABOVE_AVERAGE'|'AVERAGE'|'BELOW_AVERAGE'} props.benchmarkKey - Benchmarking key.
 * @returns {JSX.Element} Component.
 */
export const BenchmarkLabel = ({ benchmarkKey }) => {
    const { CLASS_TEXT, TEXT } = BENCHMARKING[benchmarkKey];
    return (
        <span
            className={`benchmarking-label benchmarking-label--${CLASS_TEXT}`}
        >
            <SvgIcon
                icon={`benchmarking-${CLASS_TEXT}`}
                className="benchmarking-label__icon"
            />
            <span className="benchmarking-label__text">{TEXT}</span>
        </span>
    );
};

BenchmarkLabel.propTypes = {
    benchmarkKey: PropTypes.oneOf([
        'ELITE',
        'ABOVE_AVERAGE',
        'AVERAGE',
        'BELOW_AVERAGE'
    ])
};
