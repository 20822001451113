import produce from 'immer';

// eslint-disable-next-line no-unused-vars
import { StatsLeadersState } from '../types';
// eslint-disable-next-line no-unused-vars
import { Action } from '../../../../types';
import {
    SET_NAV_ITEM,
    SET_SORT,
    SET_FILTER_ITEM,
    SET_RESET_FILTERS,
    SET_BENCHMARKING,
    SET_DATA_TYPE
} from '../actions';
import {
    setNavItem,
    setFilterItem,
    setSort,
    setBenchmarking,
    setDataType,
    setResetFilters
} from '../../shared/reducer';
import { DATA_TYPE } from 'widgets/statspro/js/components/common/config/data-type';

/**
 * @type {StatsLeadersState}
 */
const initialState = {
    nav: {
        playerCount: 20,
        category: 'Key Stats',
        competition: {
            id: null
        },
        season: {
            id: null
        },
        round: {
            id: null,
            number: null
        }
    },
    sort: {
        columnId: null,
        sortDirection: 'descending',
        force: false
    },
    filters: {
        positions: [],
        teams: []
    },
    benchmarking: {
        isActive: false
    },
    dataType: {
        type: DATA_TYPE.TOTALS
    }
};

/**
 * @param {StatsLeadersState} state - The Redux state.
 * @param {Action} action - The Redux action.
 * @returns {StatsLeadersState} The updated Redux state.
 */
export const statsLeadersReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_NAV_ITEM:
                setNavItem(draft, action.payload);
                break;
            case SET_FILTER_ITEM:
                setFilterItem(draft, action.payload);
                break;
            case SET_RESET_FILTERS:
                setResetFilters(draft);
                break;
            case SET_SORT:
                setSort(draft, action.payload);
                break;
            case SET_BENCHMARKING:
                setBenchmarking(draft, action.payload);
                break;
            case SET_DATA_TYPE:
                setDataType(draft, action.payload);
                break;
            default:
                draft;
        }
    });
};
