import { all, takeEvery } from 'redux-saga/effects';
import { UPDATE_NAV_ITEM } from '../actions';
import { updateNavItem } from './update-nav-item';

/**
 *
 */
export function* roundByRoundRootSaga() {
    yield all([takeEvery(UPDATE_NAV_ITEM.REQUEST, updateNavItem)]);
}
