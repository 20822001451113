import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { BioStatItem } from './BioStatItem';

/**
 * @param root0
 * @param root0.data
 * @returns {JSX.Element} Bio Stats section.
 */
export const BioStats = ({ data }) => {
    const dateFormat = 'DD MMM YYYY';

    const [bioStatsList, setBioStatsList] = useState([]);

    useEffect(() => {
        if (data?.careerTotals) {
            setBioStatsList([
                {
                    name: PULSE.I18N.lookup('label.stat.totalGames'),
                    value: data.careerTotals.matchesPlayed
                },
                {
                    name: PULSE.I18N.lookup('label.stat.debut'),
                    value: data.playerDetails.debutYear
                },
                {
                    name: PULSE.I18N.lookup('label.stat.height'),
                    value: data.playerDetails.heightCm + 'cm'
                },
                {
                    name: PULSE.I18N.lookup('label.stat.weight'),
                    value: data.playerDetails.weightKg + 'kg'
                },
                {
                    name: PULSE.I18N.lookup('label.stat.dateOfBirth'),
                    value: PULSE.app.common.date.momentCustomFormat(
                        data.playerDetails.dateOfBirth,
                        dateFormat,
                        true
                    )
                }
            ]);
        }
    }, [data?.careerTotals]);

    return (
        <div className="pp-bio-stats">
            {bioStatsList.map((stat, index) => (
                <BioStatItem stat={stat} key={index}></BioStatItem>
            ))}
        </div>
    );
};

BioStats.propTypes = {
    data: PropTypes.object.isRequired
};
