import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { POSITIONS } from '../../config/positions';
import { playerComparisonFiltersSelector } from 'store/modules/statspro/player-comparison/selectors/filters-selector';
import { UPDATE_FILTER_ITEM } from 'store/modules/statspro/player-comparison/actions';
import { getTranslation } from 'common/react/utils/translations';
import { SelectMultiple } from 'common/react/components/select-multiple';

export const PlayerSelectionPositionsFilters = ({ addOnResetCallback }) => {
    const dispatch = useDispatch();

    const filters = useSelector(playerComparisonFiltersSelector);
    const updateFilterItem = (filterItem, value) => {
        dispatch(UPDATE_FILTER_ITEM.request({ filterItem, value }));
    };

    return (
        <SelectMultiple
            // @ts-ignore
            items={POSITIONS}
            getInitialSelectedItems={() => {
                return POSITIONS.filter((p) =>
                    filters.positions.includes(p.keys)
                );
            }}
            label={getTranslation('label.statsLeaders.filters.position')}
            itemToString={(pos) => pos.name}
            onSelectedItemsChange={(pos) => {
                updateFilterItem(
                    'positions',
                    pos.map((p) => p.keys)
                );
            }}
            addOnResetCallback={addOnResetCallback}
            getItemText={(item) => item.name}
            getItemLabel={(item) => item.name}
        />
    );
};

PlayerSelectionPositionsFilters.propTypes = {
    addOnResetCallback: PropTypes.func
};
