// eslint-disable-next-line no-unused-vars
import { PerformanceChartStatsState } from '../types';
import '../../types';

/**
 * @param {PerformanceChartStatsState} draft - Draft state.
 * @param {object} payload - Action payload.
 * @param payload.yearlySeasonStats
 * @param payload.index
 */
export const setPlayerTwoChartStats = (draft, { yearlySeasonStats }) => {
    draft.playerTwoData.yearly = yearlySeasonStats ?? [];
};
