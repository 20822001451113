import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useInterval } from 'common/react/hooks/use-interval';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { FETCH_LEADERBOARD_DATA } from 'store/modules/brownlow-tracker/leaderboard/actions';
import { leaderboardDataSelector } from 'store/modules/brownlow-tracker/leaderboard/selectors/data-selector';
import { getTranslation } from 'common/react/utils/translations';
import { Loader } from 'common/react/components/Loader';
import { EmptyState } from 'common/react/components/EmptyState';
import { RoundVotes } from './round-votes';
import { TeamWatermarkBackground } from '../../../statspro/js/components/TeamWatermarkBackground';
import { PlayerPhoto } from 'common/react/components/PlayerPhoto';

const POLLING_DELAY = 30000;

export const LeadderboardEmbeddable = ({
    title,
    subtitle,
    linkTo,
    linkToLabel,
    seasonPid,
    seasonId,
    numberOfPlayers,
    clubPid
}) => {
    const isWebView =
        PULSE.core.url.getParam('webview') === 'true'
            ? true
            : window.IS_ARTICLE_API === true;
    const dispatch = useDispatch();
    const data = useSelector((state) =>
        leaderboardDataSelector(state, seasonPid)
    );
    const [noDataAvailable, setNoDataAvailable] = useState(false);
    const rounds = useSelector(
        (state) => state.rounds[`season_${seasonId}`]?.list
    );
    const clubFilter =
        clubPid && clubPid !== 'all'
            ? PULSE.app.common.CONSTANTS.TEAM_ABBR[clubPid]?.toLowerCase()
            : 'all';
    const status = data?.status
        ? PULSE.app.common.match.getMatchStatus(data.status)
        : '';

    useEffect(() => {
        // Fetch votes data
        dispatch(FETCH_LEADERBOARD_DATA.request({ seasonPid }));

        // Fetch rounds list
        dispatch(
            window.PULSE.app.redux.actions.rounds.API__FETCH_ROUNDS.request(
                seasonId
            )
        );
    }, [dispatch, seasonId, seasonPid]);

    useEffect(() => {
        // Set no data available to show the empty state
        if (data?.status && !isNaN(data.status)) {
            setNoDataAvailable(true);
        } else {
            setNoDataAvailable(false);
        }
    }, [data, data?.status]);

    // ===== Poll when not concluded ========================================== //

    useInterval(() => {
        if (status !== PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED) {
            dispatch(
                FETCH_LEADERBOARD_DATA.request({
                    type: 'season',
                    seasonPid: seasonPid
                })
            );
        }
    }, POLLING_DELAY);

    return (
        <div
            className={
                isWebView ? 'brownlow-embeddable--static-dimensions' : ''
            }
        >
            <div className="brownlow-embeddable__header">
                {clubFilter && clubFilter !== 'all' && (
                    <>
                        <TeamWatermarkBackground
                            teamAbbreviation={clubFilter}
                            className="stats-card-featured-player__bg stats-card-featured-player__bg--no-border-radius"
                            side="right"
                        />
                        <TeamIcon
                            className={'icon brownlow-embeddable__club-icon'}
                            teamAbbreviation={clubFilter}
                            theme={PULSE.app.common.team.getTeamLogoTheme(
                                clubFilter,
                                'b'
                            )}
                        />
                    </>
                )}
                <div className="brownlow-embeddable__logo"></div>
                <div className="brownlow-embeddable__header-text-container">
                    <h3 className="brownlow-embeddable__title">
                        {title ?? ''}
                    </h3>
                    <p className="brownlow-embeddable__subtitle">
                        {subtitle ?? ''}
                    </p>
                </div>
            </div>

            {!data?.leaderboard && !noDataAvailable && (
                <div className="brownlow-leaderboard-embeddable__loader">
                    <Loader />
                </div>
            )}

            {noDataAvailable && (
                <EmptyState
                    titleTranslation="label.stats.error.noStatsAvailable"
                    summaryTranslation="label.stats.error.noStatsAvailable.summary"
                />
            )}

            {data?.leaderboard && !noDataAvailable && (
                <table className="brownlow-leaderboard-embeddable__table">
                    <tbody>
                        {(!clubFilter || clubFilter === 'all'
                            ? data.leaderboard
                            : data.leaderboard?.filter(
                                  (item) =>
                                      item.team.teamAbbr.toLowerCase() ===
                                      clubFilter
                              )
                        )
                            ?.slice(0, numberOfPlayers)
                            .map((item, i) => (
                                <tr
                                    key={i}
                                    className={`brownlow-leaderboard-embeddable__row ${
                                        item?.eligible === false
                                            ? 'brownlow-leaderboard-embeddable__row--ineligible'
                                            : ''
                                    }`}
                                >
                                    <td className="brownlow-leaderboard-embeddable__cell brownlow-leaderboard-embeddable__cell--name">
                                        <div className="brownlow-leaderboard-embeddable__name-block">
                                            <PlayerPhoto
                                                className={
                                                    'brownlow-leaderboard-embeddable__photo'
                                                }
                                                photoUrl={item.player.photoURL}
                                                scale={'0.1'}
                                                alt={`${getTranslation(
                                                    'label.stats.headshot.alt'
                                                )} ${item.player.givenName} ${
                                                    item.player.surname
                                                }}`}
                                            />
                                            <div className="brownlow-leaderboard-embeddable__name-wrapper">
                                                <span className="brownlow-leaderboard-embeddable__name">
                                                    {item.player.givenName}{' '}
                                                    {item.player.surname}
                                                </span>
                                                {item?.eligible === false ? (
                                                    <span className="brownlow-leaderboard-embeddable__ineligible">
                                                        {getTranslation(
                                                            'label.brownlow.predictor.ineligible'
                                                        )}
                                                    </span>
                                                ) : item.winner ? (
                                                    <div className="brownlow-leaderboard-embeddable__winner-badge"></div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="brownlow-leaderboard-embeddable__cell brownlow-leaderboard-embeddable__cell--rounds">
                                        <RoundVotes
                                            data={item}
                                            rounds={rounds}
                                            seasonPid={seasonPid}
                                        />
                                    </td>
                                    <td className="brownlow-leaderboard-embeddable__cell brownlow-leaderboard-embeddable__cell--total-votes">
                                        {item.totalVotes ?? '0'}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}

            {linkTo && linkToLabel && (
                <div className="brownlow-embeddable__footer">
                    <a
                        href={linkTo}
                        className="brownlow-embeddable__footer-link"
                    >
                        {linkToLabel}
                        <SvgIcon icon="arrow-right" className="icon" />
                    </a>
                </div>
            )}
        </div>
    );
};

LeadderboardEmbeddable.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    linkTo: PropTypes.string,
    linkToLabel: PropTypes.string,
    seasonPid: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    numberOfPlayers: PropTypes.string.isRequired,
    clubPid: PropTypes.string
};
