const MIN_HERO_YEAR = 2018;

/**
 * We have to scale the images for older years differently because the original
 * images are smaller then those in more recent years.
 *
 * @param {string|number} seasonYear - The season year.
 * @returns {'HERO'|'CARD'} The desired player headshot size.
 */
export const getPlayerHeadshotSize = (seasonYear) => {
    const year =
        typeof seasonYear === 'string' ? parseInt(seasonYear) : seasonYear;

    return year <= MIN_HERO_YEAR ? 'HERO' : 'CARD';
};
