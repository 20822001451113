import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../../setup-react';

// ===== Signals ============================================================ //

// Nav
export const UPDATE_NAV_ITEM = createSignalAction(
    'stats/career-and-season-stats',
    'UPDATE_NAV_ITEM'
);

// Sort
export const UPDATE_SORT = createSignalAction(
    'stats/career-and-season-stats',
    'UPDATE_SORT'
);

// Benchmarking
export const UPDATE_BENCHMARKING = createSignalAction(
    'stats/career-and-season-stats',
    'UPDATE_BENCHMARKING'
);

// Data Type
export const UPDATE_DATA_TYPE = createSignalAction(
    'stats/career-and-season-stats',
    'UPDATE_DATA_TYPE'
);

// ===== Deltas ============================================================= //

// Nav
export const SET_NAV_ITEM = createDeltaActionType(
    'stats/career-and-season-stats',
    'SET_NAV_ITEM'
);
export const setNavItem = createActionCreator(SET_NAV_ITEM);

// Sort
export const SET_SORT = createDeltaActionType(
    'stats/career-and-season-stats',
    'SET_SORT'
);
export const setSort = createActionCreator(SET_SORT);

// Benchmarking
export const SET_BENCHMARKING = createDeltaActionType(
    'stats/career-and-season-stats',
    'SET_BENCHMARKING'
);
export const setBenchmarking = createActionCreator(SET_BENCHMARKING);

// Data Type
export const SET_DATA_TYPE = createDeltaActionType(
    'stats/career-and-season-stats',
    'SET_DATA_TYPE'
);
export const setDataType = createActionCreator(SET_DATA_TYPE);
