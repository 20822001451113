/* eslint-disable react/prop-types */
import React from 'react';
import LadderTable from 'custom-table/js/components/LadderTable/container';

import CustomTableProvider from './CustomTableProvider';

/**
 * A Provider is used to wrap the "entry point" component for a React widget with a Redux Provider,
 * which is used to provide access to the Redux store.
 *
 * The "data" prop will contain any "data-" attributes added to the DOM element that the React widget will be rendered into.
 *
 * The Provider can also contain any "react-router" logic that may be required by a component.
 */

const LadderTableProvider = ({ store, data }) => {
    return (
        <CustomTableProvider store={store} data={data}>
            <LadderTable data={data} />
        </CustomTableProvider>
    );
};

export default LadderTableProvider;
