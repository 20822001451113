import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from '../../select';
import { UPDATE_NAV_ITEM } from 'store/modules/competition-nav/actions';
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { getTranslation } from '../../../utils/translations';
import { useCreateRegions } from 'widgets/selection-callout/js/hooks/hooks';
import { useSiteSettings } from 'common/react/hooks/use-site-settings';

export const RegionNavItem = ({ regions }) => {
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);
    const { setSiteSettings, settingsConfig } = useSiteSettings();

    const regionParent = nav.location.location;

    const items = useCreateRegions(regions, regionParent);

    return (
        <div className="stats-leaders-nav__select competition-nav__competition-select">
            <Select
                items={items}
                itemToString={(item) => item?.name}
                initialSelectedItem={regions?.find(
                    (r) => r.id === nav.location.id
                )}
                activeItem={regions?.find((r) => r.id === nav.location.id)}
                label={getTranslation('label.filters.region')}
                onSelectedItemChange={({ selectedItem }) => {
                    const newRegion = {
                        timezone: selectedItem.timezone,
                        id: selectedItem.id,
                        name: selectedItem.name
                    };

                    dispatch(
                        UPDATE_NAV_ITEM.request({
                            navItem: 'location',
                            value: newRegion
                        })
                    );

                    setSiteSettings({
                        [settingsConfig.region.key]: newRegion
                    });
                }}
            />
        </div>
    );
};

RegionNavItem.propTypes = {
    regions: PropTypes.array
};
