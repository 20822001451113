import React, { useMemo } from 'react';
import { TeamIcon } from 'common/react/components/TeamIcon';
import PropTypes from 'prop-types';

/**
 * Renders Opponent team cell
 * If the round was a Bye, instead of the opponent, the cell wil render "Bye"
 *
 * @param {object} props
 * @param {object} props.cellProps
 * @param {object} props.cell
 * @param {object} props.team
 * @returns
 */
export const TeamCell = ({ cellProps, cell, team }) => {
    // get team abbreviation
    const teamAbbr = useMemo(() => {
        return (
            team?.abbreviation ??
            PULSE.app.common.team.getTeamAbbr(team?.providerId) ??
            team?.name ??
            ''
        );
    }, [team]);

    return (
        <div
            {...cellProps}
            role={null}
            className="stats-table__body-cell stats-table__body-cell--opponent"
        >
            <div className="brownlow-player-modal-table__team-cell">
                {team && !cell?.row?.original?.bye ? (
                    <>
                        <TeamIcon
                            providerId={team.providerId}
                            className="brownlow-player-modal-table__team-logo"
                        />
                        <div className="brownlow-player-modal-table__team-text">
                            <span className="brownlow-player-modal-table__team-name brownlow-player-modal-table__team-name--full-name">
                                {team.name}
                            </span>
                            <span className="brownlow-player-modal-table__team-name brownlow-player-modal-table__team-name--abbr-name">
                                {teamAbbr}
                            </span>
                        </div>
                    </>
                ) : (
                    <div className="brownlow-player-modal-table__team-text">
                        <span className="brownlow-player-modal-table__team-name">
                            {cell?.row?.original?.bye && 'Bye'}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

TeamCell.propTypes = {
    cellProps: PropTypes.object,
    team: PropTypes.object,
    cell: PropTypes.object
};
