import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'common/react/components/select';
import { getTranslation } from 'common/react/utils/translations';
import { UPDATE_NAV_ITEM } from 'store/modules/statspro/performance-chart/actions';
import { performanceChartNavSelector } from 'store/modules/statspro/performance-chart/selectors/nav-selector';
import { STATS_CATEGORY_ARRAY } from '../../../common/config/performance-stats';

export const StatNavItem = () => {
    const dispatch = useDispatch();
    const nav = useSelector(performanceChartNavSelector);

    return (
        <Select
            items={STATS_CATEGORY_ARRAY}
            initialSelectedItem={nav.category}
            activeItem={nav.category}
            label={getTranslation('label.performanceChart.nav.stat')}
            onSelectedItemChange={({ selectedItem }) => {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'category',
                        value: selectedItem
                    })
                );
            }}
        />
    );
};
