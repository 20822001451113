import React from 'react';

import { getTranslation } from 'common/react/utils/translations';

export const FixtureSoldOut = () => {
    return (
        <div className={`fixtures__badge fixtures__badge--sold-out`}>
            <p className="fixtures__badge-text fixtures__badge-text--bold">
                {getTranslation('label.sold_out').toUpperCase()}
            </p>
        </div>
    );
};
