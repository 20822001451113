import { all, takeEvery } from 'redux-saga/effects';
import { FETCH_TOOLTIP_PLAYER_STATS } from '../actions';
import { fetchTooltipPlayerStats } from './fetch-tooltip-player-stats';

/**
 *
 */
export function* tooltipRootSaga() {
    yield all([
        takeEvery(FETCH_TOOLTIP_PLAYER_STATS.REQUEST, fetchTooltipPlayerStats)
    ]);
}
