import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getPlayerHeadshotSize } from 'widgets/statspro/js/components/common/utils';
import { useSeasonYear, useCompSeasonProvider } from '../hooks';

/**
 * @param {object} props - Props.
 * @param {Array} props.players - Players details
 * @returns {JSX.Element} Component.
 */
export const ComparisonPlayerImages = ({ players }) => {
    const seasonYear = useSeasonYear();
    const compSeasonProvider = useCompSeasonProvider();
    const ref = useRef();

    useEffect(() => {
        if (ref.current && typeof compSeasonProvider !== 'undefined') {
            new PULSE.app.PlayerHeadshot(ref.current, compSeasonProvider);
        }
    }, [players, compSeasonProvider]);

    return (
        <div ref={ref} className="comparison-chart__player-images">
            {players.map((player, i) => {
                if (!player) {
                    return null;
                }

                return (
                    <div
                        key={player.playerId + seasonYear + i}
                        className="comparison-chart__player-image-container"
                    >
                        <div
                            className={`comparison-chart__headshot-wrapper comparison-chart__headshot-wrapper--${
                                i === 0 ? 'p1' : 'p2'
                            }`}
                        >
                            <div
                                className="js-player-image"
                                data-player={player.playerId.replace(
                                    'CD_I',
                                    ''
                                )}
                                data-size={getPlayerHeadshotSize(seasonYear)}
                                data-picture-in-view="false"
                                data-year={seasonYear}
                            />
                        </div>
                        <div className={`comparison-chart__name`}>
                            {player.playerDetails.givenName}{' '}
                            <span>{player.playerDetails.surname}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

ComparisonPlayerImages.propTypes = {
    players: PropTypes.array.isRequired
};
