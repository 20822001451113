import React from 'react';
import PropTypes from 'prop-types';
import Drawer from 'rc-drawer';
import { SvgIcon } from '../SvgIcon';
import { getTranslation } from '../../utils/translations';

/**
 * @param {object} props - Props.
 * @param {any} props.children - Child component(s).
 * @param {boolean} props.isOpen - True if the drawer should be open, else
 *    false.
 * @param {Function} props.onClose - On close callback.
 * @param {string} props.heading - Heading text.
 * @returns {JSX.Element} Component.
 */
export const DrawerBottom = ({ children, isOpen, onClose, heading }) => (
    <Drawer
        open={isOpen}
        onClose={() => onClose()}
        placement="bottom"
        handler={<></>}
        className="drawer-bottom"
        role="dialog"
        title={heading}
    >
        <div className="drawer-bottom__inner-wrapper">
            <div className="drawer-bottom__header">
                <h2 className="drawer-bottom__heading">{heading}</h2>
                <button
                    className="drawer-bottom__close-button"
                    onClick={() => onClose()}
                >
                    <span className="u-screen-reader">
                        {getTranslation('label.close')}
                    </span>
                    <SvgIcon
                        className="drawer-bottom__close-icon"
                        icon="close-circle-fill"
                    />
                </button>
            </div>
            <div className="drawer-bottom__content">{children}</div>
        </div>
    </Drawer>
);

DrawerBottom.propTypes = {
    children: PropTypes.any.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
