import React from 'react';
import PropTypes from 'prop-types';
import { LastRoundHighsCard } from './LastRoundHighsCard';
import { LastRoundHighsVerticalSlider } from './LastRoundHighsVerticalSlider';

/**
 * @param {object} props - Props.
 * @param {string} props.category - Player category.
 * @param {Array} props.players - Player details.
 * @param {string} props.type - Category of slider.
 * @param {boolean} props.isAFLW - isAFLW
 * @returns {JSX.Element} Component.
 */
export const LastRoundHighsSlide = ({ category, players, type, isAFLW }) => {
    return (
        <div>
            {players.length === 1 ? (
                <div className="last-round-highs__card-wrapper">
                    <LastRoundHighsCard
                        player={players[0]}
                        type={type}
                        isAFLW={isAFLW}
                    />
                </div>
            ) : (
                <LastRoundHighsVerticalSlider
                    category={category}
                    players={players}
                    type={type}
                    isAFLW={isAFLW}
                />
            )}
        </div>
    );
};

LastRoundHighsSlide.propTypes = {
    category: PropTypes.string.isRequired,
    players: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    isAFLW: PropTypes.bool
};
