import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

export const FixtureSummaryCountdown = ({ match, liveMatch }) => {
    const matchClock = useRef();
    const timeRef = useRef();

    const matchId = liveMatch?.match.matchId
        ? liveMatch.match.matchId
        : match.providerId;

    const matchTime = useSelector((state) =>
        window.PULSE.app.redux.selectors.match.timeString(state, matchId)
    );

    useEffect(() => {
        if (matchTime.secondaryStatus.useClock) {
            if (matchClock.current) {
                matchClock.current.updateTime(true);
            } else {
                matchClock.current = new PULSE.app.MatchClock(timeRef.current);
            }
        }
    }, [matchTime.secondaryStatus]);

    return (
        <div
            className="fixtures__match-summary is-countdown"
            ref={timeRef}
            dangerouslySetInnerHTML={{
                __html: matchTime.secondaryStatus.string
            }}
        />
    );
};

FixtureSummaryCountdown.propTypes = {
    match: PropTypes.object,
    liveMatch: PropTypes.object
};
