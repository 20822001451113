import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @returns {JSX.Element} Component.
 */
export const StatsSectionContent = (props) => {
    const children = React.Children.toArray(props.children);

    if (!children?.length) {
        return <></>;
    }

    const Header = children.find(
        (child) => child.props.name === 'StatsSectionContentHeader'
    );
    const Body = children.find(
        (child) => child.props.name === 'StatsSectionContentBody'
    );

    return (
        <div className="u-stats-shadow">
            {Header}
            {Body}
        </div>
    );
};

StatsSectionContent.defaultProps = {
    name: 'StatsSectionContent'
};

StatsSectionContent.propTypes = {
    children: PropTypes.any.isRequired
};
