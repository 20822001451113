import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { TeamIcon } from 'common/react/components/TeamIcon';

import { QualifiersBanner } from '../QualifiersBanner';

import { StatsTableRowHeader } from '../../../../statspro/js/components/common/stats-table/StatsTableRowHeader';

export const BodyCellPosition = ({ cell, cellProps }) => {
    const position = cell.value;
    const positionChange = cell?.row?.values?.positionChange || 'none';
    const svgName = `pos-change-${positionChange.toLowerCase()}`;
    const rowIndex = cell?.row?.index;

    const isConference = cell?.row?.original?.isConference;
    const qualiferRow = cell?.row?.original?.qualiferRow;

    const teamId = cell?.row?.values?.club?.providerId || false;

    const isConferenceRow =
        rowIndex === 1 && isConference ? 'is-conference-row' : '';

    const teamName =
        PULSE.app.common.team.getTeamName(
            cell?.row?.values?.club?.providerId
        ) || '';

    const isAFLW = cell?.row?.original?.competitionType === 'AFLW';
    const teamUrl = PULSE.app.common.url.generateTeamLink(teamName, isAFLW);

    const isMainCompetition =
        cell?.row?.original?.competitionType === 'AFL' ||
        cell?.row?.original?.competitionType === 'AFLW';

    return (
        <StatsTableRowHeader
            cellProps={cellProps}
            className={`stats-table__row-header-cell stats-table__cell--position ${isConferenceRow}`}
        >
            <div className="stats-table__position-club-wrapper">
                <div className="stats-table__position">
                    <span className="stats-table__position-cell-label">
                        {position}
                    </span>
                    <SvgIcon icon={svgName} className="icon" />
                </div>
                {isMainCompetition ? (
                    <a
                        href={teamUrl}
                        aria-label={cell?.row?.values?.club?.name}
                    >
                        <div className="stats-table__club">
                            {teamId ? <TeamIcon providerId={teamId} /> : null}
                        </div>
                    </a>
                ) : (
                    <div className="stats-table__club">
                        {teamId ? <TeamIcon providerId={teamId} /> : null}
                    </div>
                )}
            </div>

            {rowIndex === qualiferRow && (
                <QualifiersBanner qualifierRow={qualiferRow} />
            )}
        </StatsTableRowHeader>
    );
};

BodyCellPosition.propTypes = {
    cell: PropTypes.object,
    cellProps: PropTypes.object
};
