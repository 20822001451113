import React from 'react';
import PropTypes from 'prop-types';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { BioStats } from './components/BioStats';
import { BioContent } from './components/BioContent';
import { useGetDraftProspectStatsQuery } from 'common/store/modules/api/cfs-api';
import { Highlights } from './components/Highlights';

export const DraftProspectBio = ({
    accountId,
    policyKey,
    videoPlayerId,
    anchorName
}) => {
    const playerId = new URLSearchParams(location.search).get('playerId');
    const urlParts = window.location.pathname.split('/');
    const year = urlParts[urlParts.length - 1];
    const { data } = useGetDraftProspectStatsQuery({ year, playerId });

    return data ? (
        <StatsSection.Main>
            <StatsSection.Heading
                title={PULSE.I18N.lookup('label.stats.playerProfile')}
            />
            <StatsSection.Content>
                <StatsSection.ContentHeader>
                    <StatsSection.ContentHeaderHeading cssClass="stats-section-content-header__heading--full-width">
                        <BioStats data={data} />
                    </StatsSection.ContentHeaderHeading>
                </StatsSection.ContentHeader>
                <StatsSection.ContentBody>
                    <BioContent data={data} />
                    <Highlights
                        accountId={accountId}
                        policyKey={policyKey}
                        videoPlayerId={videoPlayerId}
                        anchorName={anchorName}
                        playerId={playerId}
                    />
                </StatsSection.ContentBody>
            </StatsSection.Content>
        </StatsSection.Main>
    ) : (
        <></>
    );
};

DraftProspectBio.propTypes = {
    accountId: PropTypes.string.isRequired,
    policyKey: PropTypes.string.isRequired,
    videoPlayerId: PropTypes.string.isRequired,
    anchorName: PropTypes.string
};
