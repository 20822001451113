import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { daysFromNow } from 'widgets/region-selector/js/util';
import { STATUS_KEYS } from '../utils/status-keys';
import { SelectionCalloutBar } from './SelectionCalloutBar';
import { SelectionCalloutMsg } from './SelectionCalloutMsg';
import { getInitialCookieValue } from '../utils/getInitialCookieValue';

const { getStorage, setStorage } = PULSE.core.localStorage;

export const SelectionCalloutWrapper = ({
    title,
    calloutId = 'calloutText',
    cookieDays = '45'
}) => {
    const cookieName = `${calloutId}Status`;
    const cookieExpirationDays = parseInt(cookieDays, 10);

    const [status, setStatus] = useState(getInitialCookieValue(cookieName));

    //Update status cookie if it's different to the state in this component
    useEffect(() => {
        const currentStatusCookie = getStorage(cookieName, true);

        if (currentStatusCookie !== status) {
            setStorage(
                cookieName,
                status,
                daysFromNow(cookieExpirationDays),
                true
            );
        }
    }, [cookieExpirationDays, cookieName, status]);

    switch (status) {
        /**
         * For both the EMPTY and BAR_SEEN status's render the callout bar, this
         * is because the status will be flipped to BAR_SEEN as soon as it
         * renders and we don't want it to be removed immediately.
         */
        case STATUS_KEYS.EMPTY:
        case STATUS_KEYS.BAR_SEEN:
            return <SelectionCalloutBar title={title} setStatus={setStatus} />;

        /**
         * Same here as above but for the BAR_CLOSED and MSG_SEEN statuses
         */
        case STATUS_KEYS.BAR_CLOSED:
        case STATUS_KEYS.MSG_SEEN:
            return <SelectionCalloutMsg setStatus={setStatus} />;

        default:
            return null;
    }
};

SelectionCalloutWrapper.propTypes = {
    title: PropTypes.string,
    calloutId: PropTypes.string,
    cookieDays: PropTypes.string
};
