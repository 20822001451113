import React from 'react';
import PropTypes from 'prop-types';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { LocationDropdown } from './LocationDropdown';
import { RegionDropdown } from './RegionDropdown';

export const SelectionCallout = ({
    handleDropdownChange,
    locations,
    regions,
    selectedRegion
}) => {
    const dropdownData = {
        activeLocation: selectedRegion.location,
        activeRegion: selectedRegion.name
    };

    return (
        <div className="selection-callout__options-container">
            <StatsSection.Main>
                <StatsSection.Nav>
                    <StatsSection.NavItems>
                        <LocationDropdown
                            dropdownData={dropdownData}
                            items={locations}
                            handleDropdownChange={handleDropdownChange}
                            classes={'competition-nav__region-wrapper'}
                        />
                        <RegionDropdown
                            dropdownData={dropdownData}
                            items={regions}
                            handleDropdownChange={handleDropdownChange}
                            classes={'competition-nav__region-wrapper'}
                        />
                    </StatsSection.NavItems>
                </StatsSection.Nav>
            </StatsSection.Main>
        </div>
    );
};

SelectionCallout.propTypes = {
    locations: PropTypes.array,
    regions: PropTypes.array,
    handleDropdownChange: PropTypes.func,
    selectedRegion: PropTypes.object
};
