import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'common/react/components/select';
import { getTranslation } from 'common/react/utils/translations';

export const LocationDropdown = ({
    items,
    dropdownData,
    handleDropdownChange
}) => {
    return (
        <div className="stats-leaders-nav__select competition-nav__competition-select">
            <Select
                label={getTranslation('label.region-selector.location')}
                items={items}
                itemToString={(item) => item?.name}
                initialSelectedItem={items?.find((item) => {
                    if (item.name) {
                        return (
                            item.name ===
                            PULSE.app.common.broadcast.modifyCountryDisplay(
                                dropdownData.activeLocation
                            )
                        );
                    }
                    return false;
                })}
                activeItem={items?.find((item) => {
                    if (item.name) {
                        return (
                            item.name ===
                            PULSE.app.common.broadcast.modifyCountryDisplay(
                                dropdownData.activeLocation
                            )
                        );
                    }
                    return false;
                })}
                onSelectedItemChange={({ selectedItem }) => {
                    const newRegion = {
                        timezone: selectedItem.extra.timezone,
                        id: selectedItem.extra.id,
                        name: selectedItem.extra.name
                    };

                    handleDropdownChange(newRegion);
                }}
            />
        </div>
    );
};

LocationDropdown.propTypes = {
    items: PropTypes.array,
    setDropdownData: PropTypes.func,
    dropdownData: PropTypes.object,
    handleDropdownChange: PropTypes.func
};
