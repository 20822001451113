import React from 'react';
import PropTypes from 'prop-types';
import { STATS_COLUMNS } from 'widgets/statspro/js/components/common/config/columns';

/**
 * @param {object} props - Props.
 * @param {string} props.sortedColumnId - The column ID.
 * @param {'ascending'|'descending'} props.sortDirection - The sort direction.
 * @returns {JSX.Element} Component.
 */
export const TableCaption = ({ sortedColumnId, sortDirection }) => {
    const sortedColumnName = STATS_COLUMNS[sortedColumnId]?.name;

    return (
        <caption className="u-screen-reader">
            Ranked by {sortedColumnName} ({sortDirection})
        </caption>
    );
};

TableCaption.propTypes = {
    sortedColumnId: PropTypes.string.isRequired,
    sortDirection: PropTypes.oneOf(['ascending', 'descending'])
};
