import produce from 'immer';

// eslint-disable-next-line no-unused-vars
import { EventsListingState } from '../types';
// eslint-disable-next-line no-unused-vars
import { Action } from '../types';
import { SET_NAV_ITEM, SET_FILTER_ITEM } from '../actions';
import { setNavItem } from './set-nav-item';
import { setFilterItem } from './set-filter-item';

/**
 * @type {EventsListingState}
 */
const initialState = {
    date: '',
    filters: {
        eventTypes: [],
        teams: [],
        eventLocations: []
    }
};

/**
 * @param {EventsListingState} state - The Redux state.
 * @param {Action} action - The Redux action.
 * @returns {EventsListingState} The updated Redux state.
 */
export const navReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_NAV_ITEM:
                setNavItem(draft, action.payload);
                break;
            case SET_FILTER_ITEM:
                setFilterItem(draft, action.payload);
                break;
            default:
                draft;
        }
    });
};
