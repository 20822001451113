import React from 'react';
import cx from 'classnames';

import DefaultCellRendererContainer from 'custom-table/js/components/DefaultCell/DefaultCellRendererContainer';

/*
    This is a simple example of a custom cell renderer, just to show how we could override the default.
    Shows how we can support selecting multiple attributes from the API response into a single table field.
*/

const ClubCellRenderer = ({ className, value, ...additionalProps }) => {
    if (!value.teamName || !value.abbreviation) {
        return (
            <DefaultCellRendererContainer
                className={cx(className, 'custom-table__cell--club')}
                {...additionalProps}
            >
                <span> - </span>
            </DefaultCellRendererContainer>
        );
    }

    const clubIcon = PULSE.app.templating.render(
        {
            cssClass: ``,
            providerId: value.providerId
        },
        'common.team-icon'
    );

    const isSorted =
        additionalProps.field.fieldName ===
        additionalProps.customTable.sortBy.fieldName
            ? true
            : false;

    return (
        <DefaultCellRendererContainer
            className={cx(className, 'custom-table__cell--club', {
                'custom-table__cell--sorted': isSorted
            })}
            {...additionalProps}
        >
            <span
                className="custom-table__club-icon"
                dangerouslySetInnerHTML={{ __html: clubIcon }}
            ></span>
            <span>{value.teamName}</span>
        </DefaultCellRendererContainer>
    );
};

export default ClubCellRenderer;
