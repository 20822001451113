import { STATS_COLUMNS_FOR_CATEGORY_PROSPECT } from '../../common/config/columns';

export const composeColumnDataProspect = (seasonNavId, category, isAFLM) => {
    const resultHeader =
        seasonNavId !== -1
            ? [
                  {
                      Header: 'Result',
                      accessor: 'result',
                      disableSortBy: true
                  }
              ]
            : [];

    return [
        {
            Header: seasonNavId === -1 ? 'Year' : 'Round',
            accessor: seasonNavId === -1 ? 'year' : 'round',
            sortDescFirst: true
        },
        {
            Header: seasonNavId === -1 ? 'Club' : 'Opponent',
            accessor: seasonNavId === -1 ? 'club' : 'opponent',
            disableSortBy: true
        },
        ...resultHeader.map((col) => ({
            Header: col.Header,
            accessor: col.accessor,
            disableSortBy: true
        })),
        ...STATS_COLUMNS_FOR_CATEGORY_PROSPECT[category]
            .filter((col) => {
                if (!isAFLM) {
                    return col.id !== 'ratingPoints';
                }

                return col;
            })
            .map((col) => ({
                Header: col.abbreviation,
                accessor: col.id,
                sortDescFirst: true,
                sortType: 'basic' // this property ensures that negative values are considered lower than 0 in the sorting.
                // i.e an ascending sorting would be -1.2 -0.8 0 1 2
            }))
    ];
};
