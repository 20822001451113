import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UPDATE_DATA_TYPE } from 'store/modules/statspro/performance-chart/actions';

import { Select } from 'common/react/components/select';
import { getTranslation } from 'common/react/utils/translations';
import { performanceChartNavSelector } from 'store/modules/statspro/performance-chart/selectors/nav-selector';
import { UPDATE_NAV_ITEM } from 'store/modules/statspro/performance-chart/actions';

export const SeasonNavItem = () => {
    const dispatch = useDispatch();
    const nav = useSelector(performanceChartNavSelector);
    const seasonsList = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );
    const seasons = [
        { id: -1, name: getTranslation('label.stats.career') },
        ...seasonsList
    ];

    return (
        <Select
            items={seasons}
            itemToString={(season) => season.name}
            initialSelectedItem={
                seasons.length && nav.season.id
                    ? seasons.find((s) => s.id === nav.season.id)
                    : ''
            }
            activeItem={
                seasons.length && nav.season.id
                    ? seasons.find((s) => s.id === nav.season.id)
                    : ''
            }
            isReadyToRender={() => seasons?.length && nav.season.id}
            label={`${getTranslation('label.stats.career')}/${getTranslation(
                'label.stats.season'
            )}`}
            onSelectedItemChange={({ selectedItem }) => {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'season',
                        value: { id: selectedItem.id }
                    })
                );

                // set data tyoe to TOTALS if CAREER is selected
                if (selectedItem.id === -1) {
                    dispatch(UPDATE_DATA_TYPE.request({ type: 'totals' }));
                }
            }}
        />
    );
};
