import React from 'react';
import PropTypes from 'prop-types';

import { getTranslation } from 'common/react/utils/translations';
import { BioContent } from 'widgets/draft/js/components/draft-prospect-bio/components/BioContent';
import { PlayerModalVideo } from './PlayerModalVideo';

export const PlayerModalDetails = ({
    pulsePlayerId,
    playerDetails,
    accountId,
    policyKey,
    playerId,
    anchorName,
    isModalOpen
}) => {
    const formatedDob = moment(playerDetails?.dob, 'DD/MM/YYYY').format(
        'DD MMMM YYYY'
    );
    const formatedPosition = playerDetails?.positionFull
        .replaceAll('_', ' ')
        .toLowerCase();

    return (
        <section className="modal-centered__draft-player-details">
            <BioContent data={playerDetails} />

            {accountId && policyKey && playerId ? (
                <PlayerModalVideo
                    pulsePlayerId={pulsePlayerId}
                    accountId={accountId}
                    policyKey={policyKey}
                    playerId={playerId}
                    anchorName={anchorName}
                    isModalOpen={isModalOpen}
                />
            ) : null}

            {playerDetails?.positionFull ? (
                <article className="modal-centered__draft-player-details-container">
                    <article className="modal-centered__draft-player-details-item modal-centered__draft-player-details-item--position">
                        <span className="modal-centered__draft-player-details-title">
                            {getTranslation('label.position')}
                        </span>
                        <span className="modal-centered__draft-player-details-info modal-centered__draft-player-details-info">
                            {formatedPosition}
                        </span>
                    </article>

                    {playerDetails?.height ? (
                        <article className="modal-centered__draft-player-details-item">
                            <span className="modal-centered__draft-player-details-title">
                                {getTranslation('label.stat.height')}
                            </span>
                            <span className="modal-centered__draft-player-details-info">
                                {playerDetails.height}
                            </span>
                        </article>
                    ) : null}

                    {playerDetails?.dob ? (
                        <article className="modal-centered__draft-player-details-item">
                            <span className="modal-centered__draft-player-details-title">
                                {getTranslation('label.stat.dateOfBirth')}
                            </span>
                            <span className="modal-centered__draft-player-details-info">
                                {formatedDob}
                            </span>
                        </article>
                    ) : null}
                </article>
            ) : null}

            {playerDetails?.from ? (
                <article className="modal-centered__draft-player-details-item modal-centered__draft-player-details-item--from">
                    <span className="modal-centered__draft-player-details-title">
                        {getTranslation('label.from')}
                    </span>
                    <span className="modal-centered__draft-player-details-info">
                        {playerDetails.from}
                    </span>
                </article>
            ) : null}
        </section>
    );
};

PlayerModalDetails.propTypes = {
    pulsePlayerId: PropTypes.number,
    playerDetails: PropTypes.object,
    accountId: PropTypes.string.isRequired,
    policyKey: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
    anchorName: PropTypes.string,
    isModalOpen: PropTypes.bool
};
