import { useState } from 'react';

/**
 * Custom hook to manage pagination and fetching more data.
 *
 * @param {Array} data - The complete dataset to paginate through.
 * @param {number} pageSize - The number of items per page.
 * @returns {[Array, Function, number, Function, Function]} - The current page data, function to fetch more data, current page number, and setter functions for shownData and currentPage.
 */
export const usePagination = (data, pageSize) => {
    const [shownData, setShownData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const fetchMoreData = () => {
        const items = data.slice(shownData.length, shownData.length + pageSize);
        setShownData((prevState) => [...prevState, ...items]);
        setCurrentPage((prevPage) => prevPage + 1);
    };

    return [
        shownData,
        fetchMoreData,
        currentPage,
        setShownData,
        setCurrentPage
    ];
};
