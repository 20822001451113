import { composeGetTranslation } from 'common/react/utils/translations';

/**
 * @typedef {object} Position
 * @property {string} name - Human-readable position name.
 * @property {string[]} keys - The position keys we receive from the API
 *    responses that are attributable to the position.
 */

const getPositionNameTranslation = composeGetTranslation(
    'label.statsLeaders.filters.position.'
);

/**
 * @type {readonly Position[]}
 */
export const POSITIONS = Object.freeze([
    {
        name: getPositionNameTranslation('keyDefender'),
        keys: ['KEY_DEFENDER']
    },
    {
        name: getPositionNameTranslation('defender'),
        keys: ['MEDIUM_DEFENDER']
    },
    {
        name: getPositionNameTranslation('midfielder'),
        keys: ['MIDFIELDER', 'MIDFIELDER_FORWARD']
    },
    {
        name: getPositionNameTranslation('forward'),
        keys: ['MEDIUM_FORWARD', 'MIDFIELDER_FORWARD']
    },
    {
        name: getPositionNameTranslation('keyForward'),
        keys: ['KEY_FORWARD']
    },
    {
        name: getPositionNameTranslation('ruck'),
        keys: ['RUCK']
    }
]);

const getPositionAbbreviationTranslation = composeGetTranslation(
    'label.statsLeaders.position.abbreviation.'
);

export const POSITION_ABBREVIATIONS = Object.freeze({
    KEY_DEFENDER: getPositionAbbreviationTranslation('keyDefender'),
    MEDIUM_DEFENDER: getPositionAbbreviationTranslation('mediumDefender'),
    MIDFIELDER: getPositionAbbreviationTranslation('midfielder'),
    MIDFIELDER_FORWARD: getPositionAbbreviationTranslation('midfielderForward'),
    MEDIUM_FORWARD: getPositionAbbreviationTranslation('mediumForward'),
    KEY_FORWARD: getPositionAbbreviationTranslation('keyForward'),
    RUCK: getPositionAbbreviationTranslation('ruck')
});
