// eslint-disable-next-line no-unused-vars
import { PerformanceChartStatsState } from '../types';
import '../../types';

/**
 * @param {PerformanceChartStatsState} draft - Draft state.
 * @param {object} payload - Action payload.
 * @param payload.seasonId
 * @param payload.roundStats
 * @param payload.index
 * @param payload.reset
 */
export const setPerformanceChartSeasonsStats = (
    draft,
    { seasonId, roundStats, reset }
) => {
    if (!reset) {
        draft.data.season = {
            ...draft.data.season,
            [seasonId]: [...roundStats]
        };
    } else {
        draft.data.season = {};
        draft.data.yearly = [];
    }
};
