import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { RenderQuarterText } from './RenderQuarterText';
import { TeamIcon } from 'common/react/components/TeamIcon';

export const MatchCardEventTwoTeams = ({ match, event, removeEvent }) => {
    useEffect(() => {
        setTimeout(function () {
            removeEvent(event);
        }, 4000);
    });

    const teamStyles = {
        '--home-team': `var(--${match.home.team.abbreviation.toLowerCase()})`,
        '--away-team': `var(--${match.away.team.abbreviation.toLowerCase()})`
    };

    return (
        <div
            className={`fb-match__event ${
                event.shown === false ? 'fb-match__event__time' : ''
            }`}
            style={teamStyles}
        >
            <div className="fb-match__event__time-bg-left"></div>
            <div className="fb-match__event__time-bg-right"></div>
            <div className="fb-match__event__time-teams-container">
                <TeamIcon
                    className="fb-match__logo-icon fb-match__event__time-teams-container-logo"
                    providerId={match.home.team.providerId}
                    theme="light"
                />
                <div className="fb-match__versus">
                    {PULSE.I18N.lookup('label.fixtureBar.versus')}
                </div>
                <TeamIcon
                    className="fb-match__logo-icon fb-match__event__time-teams-container-logo"
                    providerId={match.away.team.providerId}
                    theme="light"
                />
            </div>
            <div className="fb-match__event__time-text">
                <RenderQuarterText period={event.latestTime} />
            </div>
            <div className="fb-match__event-arrow fb-match__event-arrow-top fb-match__event-arrow-1"></div>
            <div className="fb-match__event-arrow fb-match__event-arrow-top fb-match__event-arrow-2"></div>
            <div className="fb-match__event-arrow fb-match__event-arrow-top fb-match__event-arrow-3"></div>
            <div className="fb-match__event-arrow fb-match__event-arrow-top fb-match__event-arrow-4"></div>
            <div className="fb-match__event-arrow fb-match__event-arrow-top fb-match__event-arrow-5"></div>
            <div className="fb-match__event-arrow fb-match__event-arrow-top fb-match__event-arrow-6"></div>
        </div>
    );
};

MatchCardEventTwoTeams.propTypes = {
    match: PropTypes.object.isRequired
};
