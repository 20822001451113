import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FocusableTooltipContext } from './focusable-tooltip-context';
import { FOCUSABLE_TOOLTIP_CLOSE, FOCUSABLE_TOOLTIP_OPEN } from '.';

/**
 * Wrapper for Tooltip component providing natural focus order experience.
 * The FocusableTooltipContext.Provider component must be placed somwhere above in the tree
 *
 * @param {object} props
 * @param {object} props.triggerRef Reference to the element triggering the tooltip (Typically <TooltipButton>) - this is useRef() object
 * @param {object} props.children Tooltip markup to wrap around
 * @param {Function} props.onFocusOut Callback called when user focuses out of the tooltip
 *
 * @returns
 */
const FocusableTooltipWrapper = ({ triggerRef, children, onFocusOut }) => {
    // local state holding reference to the previous and next (after the tooltip) focusable element
    const [prevFocusableElement, setPrevFocusableElement] = useState();
    const [nextFocusableElement, setNextFocusableElement] = useState();

    const focusableTooltipRef = useRef();

    const focusableTooltipContext = useContext(FocusableTooltipContext);

    /**
     * focus handler
     * If user tabs back (Shift + Tab) and the "faux previous" element is in focus
     * Change the focus to the prevFocusableElement (triggerRef) and call the onFocusOut
     */
    const onPreviousFocus = () => {
        if (prevFocusableElement) {
            prevFocusableElement.focus();
        }

        if (typeof onFocusOut === 'function') {
            onFocusOut();
        }
    };

    /**
     * focus handler
     * If user tabs forward (Tab) and the "faux next" element is in focus
     * Change the focus to the nextFocusableElement (taken from the FocusableTooltipContext) and call the onFocusOut
     */
    const onNextFocus = () => {
        if (nextFocusableElement) {
            nextFocusableElement.focus();
        }

        if (typeof onFocusOut === 'function') {
            onFocusOut();
        }
    };

    /**
     * KeyUp event handler. Handling Escape key press to close the tooltip
     *
     * @param {KeyboardEvent} props
     * @param {string} props.key
     */
    const onKeyUp = ({ key }) => {
        if (key === 'Escape') {
            triggerRef.current?.focus();
            if (typeof onFocusOut === 'function') {
                onFocusOut();
            }
        }
    };

    /**
     * effect hook
     * setting prevFocusableElement based on the triggerRef
     */
    useEffect(() => {
        if (triggerRef?.current) {
            setPrevFocusableElement(triggerRef.current);
        }
    }, [triggerRef, prevFocusableElement]);

    /**
     * effect hook
     * setting nextFocusableElement:
     *  - search for next element after the triggerRef in the focusableTooltipContext list,
     *  - if next element doesn't exist, set the triggerRef as next (this will cause the focus to go back to the trigger element after the tooltip)
     */
    useEffect(() => {
        if (
            prevFocusableElement &&
            focusableTooltipContext?.length &&
            !nextFocusableElement
        ) {
            const index = focusableTooltipContext?.findIndex(
                (focussableElement) =>
                    focussableElement === prevFocusableElement
            );

            if (index && focusableTooltipContext[index + 1]) {
                setNextFocusableElement(focusableTooltipContext[index + 1]);
            } else {
                setNextFocusableElement(triggerRef.current);
            }
        }
    }, [focusableTooltipContext, prevFocusableElement]);

    /**
     * setting focus on the tooltip wrapper once rendered
     */
    useEffect(() => {
        if (focusableTooltipRef.current) {
            // dispatching FOCUSABLE_TOOLTIP_OPEN event.
            window.dispatchEvent(new CustomEvent(FOCUSABLE_TOOLTIP_OPEN));

            // Magic of timeouts: scheduling focus change to the end of stack
            setTimeout(() => focusableTooltipRef?.current?.focus());
        }

        return () => {
            // dispatching FOCUSABLE_TOOLTIP_CLOSE event on unmount
            window.dispatchEvent(new CustomEvent(FOCUSABLE_TOOLTIP_CLOSE));
        };
    }, [focusableTooltipRef]);

    /**
     * setting and removing keyup listener (listening for Escape key)
     */
    useEffect(() => {
        window.addEventListener('keyup', onKeyUp);

        return () => {
            window.removeEventListener('keyup', onKeyUp);
        };
    }, []);

    return (
        <div className="tooltip-button__focus-trap">
            <a
                href="#"
                className="tooltip-button__focus-trap-anchor"
                onFocus={onPreviousFocus}
            >
                Previous
            </a>
            <div
                tabIndex="-1"
                ref={focusableTooltipRef}
                className="tooltip-button__focus-trap-content"
            >
                {children}
            </div>
            <a
                href="#"
                className="tooltip-button__focus-trap-anchor"
                onFocus={onNextFocus}
            >
                Next
            </a>
        </div>
    );
};

FocusableTooltipWrapper.propTypes = {
    triggerRef: PropTypes.any,
    children: PropTypes.any,
    onFocusOut: PropTypes.func
};

export default FocusableTooltipWrapper;
