import React from 'react';
import PropTypes from 'prop-types';
import { TeamAnnouncementsTooltip } from './TeamAnnouncementsTooltip';

/**
 * Renders the late changes data.
 *
 * @param {object} props - The props
 * @param {object} props.lateChanges - The late changes data.
 * @param {string} props.matchStatus - The matchStatus.
 * @returns {React.JSX.Element} - The rendered late changes data.
 */
export const TeamAnnouncementsLateChanges = ({ lateChanges, matchStatus }) => {
    const displayStatusMet =
        PULSE.app.common.CONSTANTS.DISPLAY_MATCH_STATUSES.includes(matchStatus);

    return displayStatusMet ? (
        <div className="team-announcements__late-changes">
            <span className="team-announcements__late-changes-title team-announcements__list-title">
                {PULSE.I18N.lookup('label.teamlineups.lateChanges.short')}
                <TeamAnnouncementsTooltip />
            </span>
            {lateChanges ? (
                <span className="team-announcements__late-changes-value team-announcements__list-values">
                    {lateChanges}
                </span>
            ) : (
                <span className="team-announcements__late-changes-title team-announcements__list-values">
                    {PULSE.I18N.lookup('label.teamlineups.noLateChanges')}
                </span>
            )}
        </div>
    ) : null;
};

TeamAnnouncementsLateChanges.propTypes = {
    matchStatus: PropTypes.string,
    lateChanges: PropTypes.string
};
