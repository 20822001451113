import produce from 'immer';
import { SET_GLOSSARY_TERMS, SET_GLOSSARY_MODAL_VISIBLE } from '../actions';

/**
 * @typedef {object} StatsGlossaryState
 * @property {object} glossary
 */

/**
 * @typedef {object} StatsGlossaryActions
 * @property {string} type
 * @property {any} payload
 */

/**
 * @type {StatsGlossaryState}
 */
const initialState = {
    terms: {},
    modalVisible: false
};

/**
 * @param {StatsGlossaryState} state - The Redux state.
 * @param {StatsGlossaryActions} action - The Redux action.
 * @returns {StatsGlossaryState} The updated Redux state.
 */
export const statsGlossaryReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_GLOSSARY_TERMS:
                draft.terms = action.payload;
                break;
            case SET_GLOSSARY_MODAL_VISIBLE:
                draft.modalVisible = action.payload;
                break;
            default:
                draft;
        }
    });
};
