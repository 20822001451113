import { STATS_CATEGORY_IDS } from 'widgets/statspro/js/components/common/config/performance-stats';

export const composeSeasonBarData = (seasonArray, nav, type) => {
    let composedData = [];

    if (seasonArray[0]?.benchmarkedStats) {
        seasonArray
            .sort((a, b) => {
                if (a.roundNumber < b.roundNumber) {
                    return -1;
                } else if (a.roundNumber > b.roundNumber) {
                    return 1;
                }

                return a.matchId < b.matchId ? -1 : 1;
            })
            .forEach((item) => {
                const roundsRepeats = composedData.filter(
                    (round) => round.roundNumber === item.roundNumber
                )?.length;

                composedData.push({
                    id: `${item.roundNumber}_${roundsRepeats}`,
                    roundNumber: item.roundNumber,
                    name: item.roundNumber ?? '',
                    playerOneValue:
                        item.benchmarkedStats[STATS_CATEGORY_IDS[nav.category]]
                            ?.value,
                    benchmark:
                        item.benchmarkedStats[STATS_CATEGORY_IDS[nav.category]]
                            ?.benchmark ?? '',
                    subtext:
                        type !== 'comparison'
                            ? `${item.roundName} v ${item.opponent.teamAbbr}`
                            : item.roundName
                });
            });
    }

    return composedData;
};
