import { composeGetTranslation } from '../../../utils/translations';

const getTranslation = composeGetTranslation(
    'label.statsLeaders.benchmarking.'
);

export const BENCHMARKING = Object.freeze({
    ELITE: {
        KEY: 'ELITE',
        TEXT: getTranslation('rank.elite'),
        PERCENTAGE: getTranslation('explainer.percentage.elite'),
        CLASS_TEXT: 'elite'
    },
    ABOVE_AVERAGE: {
        KEY: 'ABOVE_AVERAGE',
        TEXT: getTranslation('rank.aboveAverage'),
        PERCENTAGE: getTranslation('explainer.percentage.aboveAverage'),
        CLASS_TEXT: 'above-average'
    },
    AVERAGE: {
        KEY: 'AVERAGE',
        TEXT: getTranslation('rank.average'),
        PERCENTAGE: getTranslation('explainer.percentage.average'),
        CLASS_TEXT: 'average'
    },
    BELOW_AVERAGE: {
        KEY: 'BELOW_AVERAGE',
        TEXT: getTranslation('rank.belowAverage'),
        PERCENTAGE: getTranslation('explainer.percentage.belowAverage'),
        CLASS_TEXT: 'below-average'
    }
});
