import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param props.cellProps
 * @param props.onCellRefChange
 * @param props.className
 * @param props.children
 * @returns {JSX.Element} Component.
 */
export const StatsTableRowHeader = ({
    cellProps,
    onCellRefChange,
    className,
    children
}) => {
    const cellRef = useRef();

    useEffect(() => {
        cellRef.current && onCellRefChange && onCellRefChange(cellRef.current);
    }, [cellRef]);

    return (
        <th
            {...cellProps}
            className={`stats-table__row-header-cell ${className}`}
            role="rowheader"
            scope="row"
            ref={cellRef}
        >
            {children}
        </th>
    );
};

StatsTableRowHeader.propTypes = {
    cellProps: PropTypes.any.isRequired,
    onCellRefChange: PropTypes.func,
    children: PropTypes.any.isRequired,
    className: PropTypes.string
};
