import React from 'react';
import PropTypes from 'prop-types';
import { STAT_CATEGORIES } from '../config/categories';
import { BenchmarkBar } from '../../common/BenchmarkBar';
import { FeaturedHeadToHeadStatTitle } from './FeaturedHeadToHeadStatTitle';

/**
 * @param {object} props - Props.
 * @param {Array} props.players - Players details.
 * @param {string} props.position - Players position.
 * @param {boolean} props.showBenchmarkingAflw - Whether to show benchmarking for AFLW.
 * @returns {JSX.Element} Component.
 */
export const FeaturedHeadToHeadCardStats = ({
    players,
    position,
    showBenchmarkingAflw
}) => {
    const statsContainers = STAT_CATEGORIES[position].map((category, i) => {
        const composedPlayerStats = [
            (category.type === 'average' &&
                players[0].benchmarkedAverages[category.key]) ||
                (category.type === 'total' &&
                    players[0].benchmarkedTotals[category.key]),
            (category.type === 'average' &&
                players[1].benchmarkedAverages[category.key]) ||
                (category.type === 'total' &&
                    players[1].benchmarkedTotals[category.key])
        ];

        return (
            <div className="stats-card-table__stat-container" key={i}>
                <FeaturedHeadToHeadStatTitle category={category} />

                {players.map((player, index) => (
                    <div className="stats-card-table__stat-values" key={index}>
                        {composedPlayerStats[index]?.value ? (
                            <>
                                {composedPlayerStats[index].value}

                                <BenchmarkBar
                                    stat={composedPlayerStats[index].value}
                                    benchmark={
                                        showBenchmarkingAflw
                                            ? composedPlayerStats[index]
                                                  .benchmark ?? null
                                            : null
                                    }
                                    compareValues={[
                                        composedPlayerStats[0].value,
                                        composedPlayerStats[1].value
                                    ]}
                                    index={index}
                                    teamAbbr={player?.team?.teamAbbr.toLowerCase()}
                                />
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                ))}
            </div>
        );
    });

    return (
        <div className="stats-card-table__stats-wrapper">{statsContainers}</div>
    );
};

FeaturedHeadToHeadCardStats.propTypes = {
    players: PropTypes.array.isRequired,
    position: PropTypes.string.isRequired,
    showBenchmarkingAflw: PropTypes.string
};

export default FeaturedHeadToHeadCardStats;
