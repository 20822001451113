import { useMemo } from 'react';

/**
 * Custom hook to filter leaderboard data based on a search query.
 *
 * @param {Array} initialData - The initial data array to be filtered.
 * @param {object} leaderboardFilter - The filter object containing the search query.
 * @returns {[Array, Function]} - The filtered data array and a setter function for filtered data.
 */
export const useFilteredData = (initialData, leaderboardFilter) => {
    const filteredData = useMemo(() => {
        let items = initialData;

        if (leaderboardFilter?.query && leaderboardFilter?.query !== '') {
            // Split query into separate words and convert to lowercase
            items = initialData.filter((item) =>
                // Check if any query word matches the player's first and last name
                `${item.player?.givenName?.toLowerCase() ?? ''} ${
                    item.player?.surname?.toLowerCase() ?? ''
                }`.includes(leaderboardFilter.query.toLowerCase())
            );
        }

        return items;
    }, [initialData, leaderboardFilter]);

    return filteredData;
};
