import React from 'react';
import PropTypes from 'prop-types';
import { StatsTableHead } from 'widgets/statspro/js/components/common/stats-table/StatsTableHead';

/**
 * Header cell component
 *
 * @param {object} column Column data object
 *
 * @returns
 */
const customCell = (column) => (
    <th
        className={`stats-table__header-cell stats-table__header-cell--${column.id}`}
        key={`brownlow-player-modal-table__header-${column.id}`}
    >
        <div className="brownlow-player-modal-table__header-cell">
            <span className="stats-table__header-cell-text">
                {column.render('Header')}
            </span>
        </div>
    </th>
);

/**
 * Table Head element of the Round by Round votes table
 *
 * @param {object} props
 * @param {object} props.headerGroup
 * @returns
 */
export const TableHead = ({ headerGroup }) => {
    return (
        // Fake thead Row
        <StatsTableHead
            headerGroup={headerGroup}
            customCells={{
                roundNumber: customCell,
                opponent: customCell,
                predictedPoints: customCell,
                votes: customCell
            }}
        />
    );
};

TableHead.propTypes = {
    headerGroup: PropTypes.object.isRequired
};
