import React from 'react';
import PropTypes from 'prop-types';

import { ToggleInput } from 'common/react/components/ToggleInput';
import { getTranslation } from 'common/react/utils/translations';

export const TAModeToggle = ({ viewMode, setViewMode }) => {
    return (
        <div className="team-announcements__toggle-wrapper">
            <ToggleInput
                id="data-type-toggle"
                value={viewMode}
                onChange={(value) => setViewMode(value)}
                options={[
                    {
                        label: getTranslation(
                            'label.teamannouncements.fieldView'
                        ),
                        value: 'field'
                    },
                    {
                        label: getTranslation(
                            'label.teamannouncements.listView'
                        ),
                        value: 'list'
                    }
                ]}
            />
        </div>
    );
};

TAModeToggle.propTypes = {
    viewMode: PropTypes.string,
    setViewMode: PropTypes.func
};
