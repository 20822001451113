import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';

export const MediaTag = ({
    iconName = '',
    iconUrl = '',
    label = '',
    cssClass = ''
}) => {
    return (
        <div className={`media-tag ${cssClass}`}>
            {iconName ? (
                <SvgIcon icon={iconName} className="media-tag__icon icon" />
            ) : iconUrl ? (
                <img
                    className="media-tag__icon"
                    src={iconUrl}
                    alt=""
                    aria-hidden="true"
                />
            ) : null}
            {label ? label : null}
        </div>
    );
};

MediaTag.propTypes = {
    iconName: PropTypes.string,
    iconUrl: PropTypes.string,
    label: PropTypes.string,
    cssClass: PropTypes.string,
    modifier: PropTypes.string
};
