import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useGetRegionsQuery } from 'common/store/modules/api/content-api';
import { SelectionCallout } from './SelectionCallout';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import { STATUS_KEYS } from '../utils/status-keys';
import { useCreateLocations } from '../hooks/hooks';
import { useSiteSettings } from 'common/react/hooks/use-site-settings';

export const SelectionCalloutBar = ({ title, setStatus }) => {
    const { siteSettings, setSiteSettings, settingsConfig } = useSiteSettings();
    const selectedRegion = siteSettings[settingsConfig.region.key];

    const { data = [] } = useGetRegionsQuery();

    const locations = useCreateLocations(data);

    // Event Handlers
    const handleDropdownChange = (value) => {
        setSiteSettings({
            [settingsConfig.region.key]: value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setStatus(STATUS_KEYS.BAR_CLOSED);
    };

    /**
     * Update the status to BAR_SEEN after rendering this, so that it doesn't
     * appear on subsequent page reloads
     */
    useEffect(() => {
        setStatus(STATUS_KEYS.BAR_SEEN);
    }, []);

    return (
        <div className="selection-callout">
            <div className="selection-callout__text-container">
                <SvgIcon className="selection-callout__icon" icon="globe" />
                <p className="selection-callout__text">{title}</p>
            </div>
            <div className="selection-callout__mobile-layout">
                {selectedRegion.id ? (
                    <SelectionCallout
                        handleDropdownChange={handleDropdownChange}
                        locations={locations}
                        regions={data}
                        selectedRegion={selectedRegion}
                    />
                ) : (
                    <></>
                )}
                <button
                    onClick={(event) => {
                        handleSubmit(event);
                    }}
                    className="selection-callout__submit"
                >
                    {getTranslation(`label.button.continue`)}
                </button>
            </div>
            <button
                className="selection-callout__close"
                onClick={() => setStatus(STATUS_KEYS.BAR_CLOSED)}
                aria-label={getTranslation('label.region-selector.close')}
            >
                <SvgIcon
                    className="icon selection-callout__close-icon"
                    icon="close"
                />
            </button>
        </div>
    );
};

SelectionCalloutBar.propTypes = {
    title: PropTypes.string.isRequired,
    setStatus: PropTypes.func.isRequired
};
