import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Loader } from 'common/react/components/Loader';
import { StatComparisonRow } from './StatComparisonRow';
import {
    statComparisonDataTypeSelector,
    statComparisonNavSelector
} from 'store/modules/statspro/stat-comparison/selectors';
import { composeData, composeRows } from '../utils';

import {
    useGetPlayerCareerSeasonStatsQuery,
    useGetPlayerProfileQuery
} from 'store/modules/api/statsPro-api';

export const StatComparisonMain = (props) => {
    const nav = useSelector(statComparisonNavSelector);

    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );

    const season = seasons.find((item) => item.id === nav.season.id);
    const seasonId = season?.providerId ?? props.seasonId;
    const dataType = useSelector(statComparisonDataTypeSelector);

    const [composedPlayersData, setComposedPlayersData] = useState(null);

    const isAFLW = props?.isAFLW || false;
    const isAFLM = props?.isAFLM || false;

    const { data: playerOneCareerData } = useGetPlayerProfileQuery(
        { seasonId, playerId: props.playerIds[1] },
        { skip: !props.playerIds[1] }
    );
    const { data: playerTwoCareerData } = useGetPlayerProfileQuery(
        { seasonId, playerId: props.playerIds[2] },
        { skip: !props.playerIds[2] }
    );

    const { data: playerOneSeasonData } = useGetPlayerCareerSeasonStatsQuery(
        { isAFLW: isAFLW, playerId: props.playerIds[1] },
        { skip: !props.playerIds[1] }
    );

    const { data: playerTwoSeasonData } = useGetPlayerCareerSeasonStatsQuery(
        { isAFLW: isAFLW, playerId: props.playerIds[2] },
        { skip: !props.playerIds[2] }
    );

    // This layer of processing is required to ensure that if a player is removed from the comparison widget
    // we do not pass the previously selected players data, it effectively resets the table.
    const playerOneCareerDataSelected =
        props?.playerIds?.[1] &&
        props?.playerIds?.[1] === playerOneCareerData?.playerId
            ? playerOneCareerData
            : null;
    const playerTwoCareerDataSelected =
        props?.playerIds?.[2] &&
        props?.playerIds?.[2] === playerTwoCareerData?.playerId
            ? playerTwoCareerData
            : null;

    const playerOneSeasonDataSelected =
        props?.playerIds?.[1] &&
        props?.playerIds?.[1] === playerOneSeasonData?.playerId
            ? playerOneSeasonData
            : null;
    const playerTwoSeasonDataSelected =
        props?.playerIds?.[2] &&
        props?.playerIds?.[2] === playerTwoSeasonData?.playerId
            ? playerTwoSeasonData
            : null;

    /**
     * Get rows for the selected category
     */
    const rows = useMemo(
        () => composeRows(nav.season.id, nav.category, isAFLM),
        [nav.category]
    );

    // Update the data when the nav changes
    useEffect(() => {
        if (nav.season.id === -1) {
            // career stats
            setComposedPlayersData([
                composeData(rows, playerOneCareerDataSelected, dataType.type),
                composeData(rows, playerTwoCareerDataSelected, dataType.type)
            ]);
        } else {
            // season stats
            setComposedPlayersData([
                composeData(
                    rows,
                    playerOneSeasonDataSelected?.yearlySeasonStats?.find(
                        (item) => item.seasonId === seasonId
                    ),
                    dataType.type
                ),
                composeData(
                    rows,
                    playerTwoSeasonDataSelected?.yearlySeasonStats?.find(
                        (item) => item.seasonId === seasonId
                    ),
                    dataType.type
                )
            ]);
        }
    }, [
        nav.season.id,
        rows,
        playerOneCareerDataSelected,
        playerTwoCareerDataSelected,
        playerOneSeasonDataSelected,
        playerTwoSeasonDataSelected,
        dataType.type
    ]);

    // Loader and empty state for data
    if (!composedPlayersData) {
        return (
            <div className="stat-comparison__loader">
                <Loader />
            </div>
        );
    }

    return (
        <div className="stat-comparison__stats-wrapper">
            {rows.map((row, i) => (
                <StatComparisonRow
                    key={i}
                    statName={row.name}
                    data={[
                        composedPlayersData[0].find(
                            (item) => item.name === row.name
                        ),
                        composedPlayersData[1].find(
                            (item) => item.name === row.name
                        )
                    ]}
                    showBenchmarking={props?.showBenchmarking}
                />
            ))}
        </div>
    );
};

StatComparisonMain.propTypes = {
    playerIds: PropTypes.object.isRequired,
    seasonId: PropTypes.string.isRequired,
    isAFLW: PropTypes.bool,
    isAFLM: PropTypes.bool,
    showBenchmarking: PropTypes.bool
};
