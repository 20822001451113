// eslint-disable-next-line no-unused-vars
import { StatComparisonState } from '../types';
import '../../types';

/**
 * @param {StatComparisonState} draft - Draft state.
 * @param {object} payload - Action payload.
 * @param payload.yearlySeasonStats
 * @param payload.playerId
 */
export const setStatComparison = (draft, { playerId, yearlySeasonStats }) => {
    draft.data = {
        ...draft.data,
        [playerId]: yearlySeasonStats
    };
};
