import React from 'react';
import PropTypes from 'prop-types';

export const ImageElement = ({ImageUrl, ImageTitle}) => {
    return (
            <picture className="object-fit-cover-picture">
                <source
                    className=""
                    src={`${ImageUrl}?width=344&amp;height=215, ${ImageUrl}?width=1032&amp;height=645 3x`}
                    media="(max-width: 640px)"
                /> 
                <source
                    className=""
                    src={`${ImageUrl}?width=808&amp;height=505, ${ImageUrl}?width=1616&amp;height=1010 2x, ${ImageUrl}?width=2424&amp;height=1515 3x`}
                    media="(max-width: 839px)"
                />
                <source
                    className=""
                    src={`${ImageUrl}?width=451&amp;height=268, ${ImageUrl}?width=902&amp;height=536 2x, ${ImageUrl}?width=1353&amp;height=804 3x`}
                    media="(min-width: 840px)"
                />
                <img
                    className="js-faded-image fade-in-on-load object-fit-cover-picture__img is-loaded"
                    src={`${ImageUrl}?width=451&amp;height=268`}
                    alt={`${ImageTitle} video thumbnail image`}
                />
            </picture>
    );
};

ImageElement.propTypes = {
    ImageUrl: PropTypes.string,
    ImageTitle: PropTypes.string
};