import React from 'react';
import PropTypes from 'prop-types';

export const OverviewTableCell = ({ value, suffix = '', displayFunction }) => {
    const valueToDisplay =
        typeof displayFunction === 'function' ? displayFunction(value) : value;

    return (
        <div role="cell" className="overview-table__data">
            {valueToDisplay ? `${valueToDisplay}${suffix}` : '-'}
        </div>
    );
};

OverviewTableCell.propTypes = {
    value: PropTypes.any,
    suffix: PropTypes.string,
    displayFunction: PropTypes.func
};
