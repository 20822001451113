export const numberCompare = (a, b) => a - b;

export const stringCompare = (a, b) => a.localeCompare(b);

export const formCompare = (a, b) => {
    const letterPointValue = {
        W: 4,
        D: 2,
        L: 0
    };

    let aValue = a.form.substring(a.form.length - 5, a.form.length);
    let bValue = b.form.substring(b.form.length - 5, b.form.length);

    let aPointValue = [...aValue].map((letter, index) => {
        return letterPointValue[letter];
    });

    let bPointValue = [...bValue].map((letter, index) => {
        return letterPointValue[letter];
    });

    aPointValue = aPointValue.reduce((a, b) => a + b, 0);
    bPointValue = bPointValue.reduce((a, b) => a + b, 0);

    return aPointValue - bPointValue;
};
