import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';

export const ModalButton = ({
    icon,
    label,
    description,
    onClick,
    btnLabel
}) => {
    return (
        <div className="modal-link modal-button">
            {icon ? (
                <SvgIcon icon={icon} className="icon modal-link__icon" />
            ) : null}
            {onClick ? (
                <button onClick={onClick} className="modal-link__label">
                    <span className="modal-link__text-container">
                        {label}
                        {description ? (
                            <span className="modal-link__description">
                                {description}
                            </span>
                        ) : null}
                    </span>
                    {btnLabel ? (
                        <span className="filter-button">
                            {btnLabel}
                            <SvgIcon
                                icon="arrow-right"
                                className="icon modal-link__icon"
                            />
                        </span>
                    ) : null}
                </button>
            ) : null}
        </div>
    );
};

ModalButton.propTypes = {
    icon: PropTypes.string,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    btnLabel: PropTypes.string
};
