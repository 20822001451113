import { getPremiershipWinProp } from './get-premiership-win-prop';

/**
 * @param {object[]} staticLadder - ladder
 * @param {object} bettingData - the betting data from the wagering api
 * @param {object[]} liveLadder - liveLadder
 * @returns {object[]} data - row data
 */
export const modelLadderData = (
    staticLadder,
    bettingData,
    liveLadder = false
) => {
    let result = [];

    if (liveLadder) {
        result = liveLadder?.map((item) => {
            const staticData = staticLadder?.entries || null;

            const {
                ladderPosition,
                changeSinceLastRound,
                teamId,
                gamesPlayed,
                thisSeasonRecord,
                pointsFor,
                pointsAgainst,
                playing
            } = item;

            const {
                percentage,
                winLossRecord,
                aggregatePoints,
                orderedBy,
                winRatio
            } = thisSeasonRecord;

            const { wins, losses, draws } = winLossRecord;

            const staticDataForTeam = staticData?.find(
                (entry) => entry?.team?.providerId === teamId
            );

            const form = staticDataForTeam?.form || '';
            const nextOpponent = staticDataForTeam?.nextOpponent || null;
            const club = staticDataForTeam?.team;

            return {
                position: ladderPosition || false,
                positionChange:
                    changeSinceLastRound === 'NO_CHANGE'
                        ? 'none'
                        : changeSinceLastRound,
                teamId: teamId,
                points: aggregatePoints,
                played: gamesPlayed,
                percentage: percentage.toString() || '0%',
                won: wins,
                lost: losses,
                drawn: draws,
                pointsFor: pointsFor,
                pointsAgainst: pointsAgainst,
                orderedBy,
                form: form || '',
                nextOpponent,
                club,
                isPlaying: playing ? playing : false,
                bettingOdds: getPremiershipWinProp(
                    bettingData,
                    club.providerId
                ),
                winRatio,
                staticData
            };
        });
    } else {
        result = staticLadder?.entries.map((item) => {
            const {
                position,
                positionChange,
                team,
                played,
                thisSeasonRecord,
                pointsFor,
                pointsAgainst,
                form,
                nextOpponent
            } = item;
            const {
                percentage,
                winLossRecord,
                aggregatePoints,
                orderedBy,
                winRatio
            } = thisSeasonRecord;
            const { wins, losses, draws } = winLossRecord;

            return {
                position: position || false,
                positionChange: positionChange,
                club: team,
                points: aggregatePoints,
                played: played,
                percentage: percentage.toString() || '0%',
                won: wins,
                lost: losses,
                drawn: draws,
                pointsFor: pointsFor,
                pointsAgainst: pointsAgainst,
                form: form || '',
                nextOpponent,
                orderedBy,
                winRatio,
                bettingOdds: getPremiershipWinProp(bettingData, team.providerId)
            };
        });
    }

    return result;
};
