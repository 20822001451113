import React from 'react';
import PropTypes from 'prop-types';

import { BioStatItem } from 'widgets/statspro/js/components/player-profile-bio/components/BioStatItem';

/**
 * @param root0
 * @param root0.data
 * @returns {JSX.Element} Bio Stats section.
 */
export const BioStats = ({ data }) => {
    const dateFormat = 'DD MMM YYYY';
    const bioStatsList = [
        {
            name: PULSE.I18N.lookup('label.stat.height'),
            value: data.height + 'cm'
        },
        {
            name: PULSE.I18N.lookup('label.stat.dateOfBirth'),
            value: PULSE.app.common.date.momentCustomFormat(
                data.dob,
                dateFormat,
                true
            )
        }
    ];

    return (
        <div className="pp-bio-stats">
            {bioStatsList.map((stat, index) => (
                <BioStatItem stat={stat} key={index}></BioStatItem>
            ))}
        </div>
    );
};

BioStats.propTypes = {
    data: PropTypes.object.isRequired
};
