import { put } from 'redux-saga/effects';
import { setCustomFields } from '../actions';

/**
 * @typedef {object} UpdateCustomFieldsAction
 * @property {string} type
 * @property {object} payload
 */

/**
 * @param {UpdateCustomFieldsAction} action - The Redux action.
 */
export function* updateCustomFields(action) {
    yield put(setCustomFields(action.payload));
}
