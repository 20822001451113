import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getPositionNameByKey } from '../../../../utils/positions';
import { useSeasonYear, useCompSeasonProvider } from '../../hooks';
import { TeamWatermarkBackground } from '../../../../../TeamWatermarkBackground';
import { getPlayerHeadshotSize } from 'widgets/statspro/js/components/common/utils';

const CLASS_BLOCK = 'player-stats-tooltip-header';

/**
 * @param {object} props - Props.
 * @param {object} props.player - Player.
 * @returns {JSX.Element} Component.
 */
export const PlayerStatsTooltipHeader = ({ player }) => {
    const seasonYear = useSeasonYear();
    const compSeasonProvider = useCompSeasonProvider();
    const ref = useRef();

    useEffect(() => {
        if (ref.current && typeof compSeasonProvider !== 'undefined') {
            // @ts-ignore
            new PULSE.app.PlayerHeadshot(ref.current, compSeasonProvider);
        }
    }, [ref, compSeasonProvider]);

    const teamAbbreviation = player.team.teamAbbr.toLowerCase();

    return (
        <div className={CLASS_BLOCK} ref={ref}>
            <TeamWatermarkBackground teamAbbreviation={teamAbbreviation} />
            <div className={`${CLASS_BLOCK}__content`}>
                <div className={`${CLASS_BLOCK}__details-wrapper`}>
                    <div>
                        <div className={`${CLASS_BLOCK}__first-name`}>
                            {player.details.givenName}
                        </div>
                        <div className={`${CLASS_BLOCK}__last-name`}>
                            {player.details.surname}
                        </div>
                    </div>
                    <div className={`${CLASS_BLOCK}__team-details`}>
                        <SvgIcon
                            icon={`aflc-${teamAbbreviation}-flag`}
                            className={`${CLASS_BLOCK}__flag`}
                        />
                        <span
                            className={`${CLASS_BLOCK}__jumper-number`}
                        >{`#${player.details.jumperNumber}`}</span>
                        <span>
                            {getPositionNameByKey(player.details.position)}
                        </span>
                    </div>
                </div>
                <div className={`${CLASS_BLOCK}__headshot-wrapper`}>
                    <div
                        className="js-player-image"
                        data-player={player.details.playerId.replace(
                            'CD_I',
                            ''
                        )}
                        data-size={getPlayerHeadshotSize(seasonYear)}
                        data-picture-in-view="false"
                        data-year={seasonYear}
                    />
                </div>
            </div>
        </div>
    );
};

PlayerStatsTooltipHeader.propTypes = {
    player: PropTypes.object.isRequired
};
