import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { DraftTrackerLeaderboardTable } from './table/DraftTrackerLeaderboardTable';
import {
    ClubNavItem,
    PositionsNavItem,
    RoundNavItem,
    Search
} from './nav-items';
import { Legend } from './table/components/Legend';
import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { SettingsButton } from 'common/react/components/SettingsButton';

export const DraftTrackerLeaderboard = ({ seasonPid, seasonId }) => {
    document.body.classList.add('remove-sticky-nav');
    const year = seasonPid.substring(4, 8);

    const windowSize = useWindowSize();

    const isResponsive = () =>
        windowSize.width <= PULSE.app.measurements.desktop;
    const [showResponsiveItems, setShowResponsiveItems] = useState(
        isResponsive()
    );

    useEffect(() => {
        setShowResponsiveItems(isResponsive());
    }, [windowSize.width]);

    return (
        <>
            <StatsSection.Main>
                <StatsSection.Nav>
                    <StatsSection.NavItems>
                        <RoundNavItem year={year} />
                        <ClubNavItem seasonId={seasonId} year={year} />
                        {!showResponsiveItems ? (
                            <PositionsNavItem
                                year={year}
                                classes={'u-margin-right-auto'}
                            />
                        ) : null}
                        {!showResponsiveItems ? (
                            <Search classes="search-filter-item" />
                        ) : null}
                        {showResponsiveItems ? (
                            <SettingsButton classes="settings-button-mobile" />
                        ) : null}
                    </StatsSection.NavItems>

                    {showResponsiveItems ? (
                        <StatsSection.NavFilterItems>
                            <PositionsNavItem year={year} />
                        </StatsSection.NavFilterItems>
                    ) : null}

                    {!showResponsiveItems ? (
                        <StatsSection.SettingsButton>
                            <SettingsButton />
                        </StatsSection.SettingsButton>
                    ) : null}

                    {showResponsiveItems ? (
                        <StatsSection.SearchBarMobile>
                            <Search />
                        </StatsSection.SearchBarMobile>
                    ) : null}
                </StatsSection.Nav>
                <StatsSection.Content>
                    <StatsSection.ContentBody>
                        <DraftTrackerLeaderboardTable year={year} />
                    </StatsSection.ContentBody>
                </StatsSection.Content>
            </StatsSection.Main>
            <Legend />
        </>
    );
};

DraftTrackerLeaderboard.propTypes = {
    seasonPid: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired
};
