import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

import { Loader } from 'common/react/components/Loader';

const BROWNLOW_PREDICTOR_PAGE_LIMIT = 20;

export const ShowMoreButton = ({
    canNextPage,
    pageSize,
    currentPageSize,
    totalRowCount,
    onClick,
    isBrownlowV2,
    isFetching
}) => {
    if (!canNextPage && !isBrownlowV2) {
        return <></>;
    }

    const pagesToLoadCount = Math.min(
        pageSize,
        totalRowCount - currentPageSize
    );

    if (isFetching) {
        // Loader for waiting for data
        return (
            <div className="stats-table-load-more-button">
                <div className="stats-table-load-more-button__loader">
                    <Loader />
                </div>
            </div>
        );
    }

    return (
        <button
            className="stats-table-load-more-button"
            onClick={() => {
                if (!canNextPage && !isBrownlowV2) {
                    return;
                }
                onClick();
            }}
        >
            <span>
                {getTranslation('label.statsLeaders.paginationNext').replace(
                    '{0}',
                    `${
                        isBrownlowV2
                            ? BROWNLOW_PREDICTOR_PAGE_LIMIT
                            : pagesToLoadCount
                    }`
                )}
            </span>
            <SvgIcon
                className="icon stats-table-load-more-button__icon"
                icon="arrow-right"
            />
        </button>
    );
};

ShowMoreButton.propTypes = {
    canNextPage: PropTypes.bool.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPageSize: PropTypes.number.isRequired,
    totalRowCount: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    isBrownlowV2: PropTypes.bool,
    isFetching: PropTypes.bool
};
