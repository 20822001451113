import { all, takeEvery } from 'redux-saga/effects';

import { updateNavItem } from './update-nav-item';
import { updateSort } from './update-sort';
import { updateDataType } from './update-data-type';
import {
    UPDATE_CUSTOM_FIELDS,
    UPDATE_MODAL,
    UPDATE_NAV_ITEM,
    UPDATE_TEMPORARY_FIELDS
} from '../actions';
import { UPDATE_SORT } from '../actions';
import { UPDATE_DATA_TYPE } from '../actions';
import { updateCustomFields } from './update-custom-fields';
import { updateTemporaryFields } from './update-temporary-fields';
import { updateModal } from './update-modal';

/**
 *
 */
export function* playerStatsRootSaga() {
    yield all([
        takeEvery(UPDATE_NAV_ITEM.REQUEST, updateNavItem),
        takeEvery(UPDATE_SORT.REQUEST, updateSort),
        takeEvery(UPDATE_DATA_TYPE.REQUEST, updateDataType),
        takeEvery(UPDATE_CUSTOM_FIELDS.REQUEST, updateCustomFields),
        takeEvery(UPDATE_TEMPORARY_FIELDS.REQUEST, updateTemporaryFields),
        takeEvery(UPDATE_MODAL.REQUEST, updateModal)
    ]);
}
