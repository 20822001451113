import React from 'react';
import PropTypes from 'prop-types';

import { SwitchInput } from 'common/react/components/SwitchInput';
import { ModalNote } from './ModalNote';

export const ModalToggle = ({
    icon,
    label,
    isChecked,
    isDisabled,
    onChange,
    notes,
    cssClass
}) => {
    return (
        <>
            <div
                className={`modal-toggle ${
                    notes ? 'modal-toggle--has-notes' : ''
                } ${cssClass ? `modal-toggle--` + cssClass : ''}`}
            >
                {label ? (
                    <SwitchInput
                        id={label}
                        label={label}
                        isChecked={isChecked}
                        isDisabled={isDisabled}
                        onChange={onChange}
                        icon={icon}
                    />
                ) : null}
            </div>
            {notes ? <ModalNote text={notes} isEmbedded={true} /> : null}
        </>
    );
};
// Note: Ideally, the CSS class should indicate if this component
//  is the first-of-stack, mid-of-stack, or last-of-stack
//  so that the correct styles can be applied
ModalToggle.propTypes = {
    icon: PropTypes.string,
    label: PropTypes.string,
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    notes: PropTypes.string,
    cssClass: PropTypes.string
};
