/**
 * 1. Get the initial rank value based on the index of the row in the list of
 *    all rows.
 * 2. Increase the rank (by decreasing the numeric value) each time we see that
 *    the sorted value of the current row matches that of a previous row. This
 *    caters for joint positioning (e.g. the ranks when two players are joint
 *    1st place: rowOne = 1, rowTwo = 1, rowThree = 3, ...).
 *
 * @param {any} rowsDescending - The rows in descending order.
 * @param {string} sortedColumnId - The ID of the sorted column.
 * @returns {object} Rank indexed by row ID.
 */
export const getRanks = (rowsDescending, sortedColumnId) => {
    return rowsDescending
        .map((row, index) => {
            // [1]
            let rank = index + 1;

            let prevRowIndex = index - 1;
            const value = row.values[sortedColumnId];

            // [2]
            while (
                prevRowIndex > -1 &&
                rowsDescending[prevRowIndex].values[sortedColumnId] === value
            ) {
                rank--;
                prevRowIndex--;
            }

            return { id: row.id, rank };
        })
        .reduce((prev, curr) => {
            return {
                ...prev,
                [curr.id]: curr.rank
            };
        }, {});
};
