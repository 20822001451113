// eslint-disable-next-line no-unused-vars
import { TooltipState } from '../types';
import '../../types';

/**
 * @param {PlayerModalState} draft - Draft state.
 * @param {object} data - Action payload data.
 */
export const setTooltipPlayerStats = (draft, data) => {
    draft.data = {
        ...draft.data,
        [data.player.seasonId]: {
            [data.player.playerId]: { ...data } ?? []
        }
    };
};
