/**
 * Combining data for player one and player two to use in the bar graph
 * Returning single array where the player 2 stats are mapped to `playerTwoValue` fields and so on
 *
 * @param {Array} playerOneComposedData
 * @param {Array} playerTwoComposedData
 * @param {Array} seasonArray
 * @returns {Array}
 */
export const composePlayerTwoSeasonBarData = (
    playerOneComposedData,
    playerTwoComposedData
) => {
    let composedData = [...playerOneComposedData];

    playerTwoComposedData.forEach((item) => {
        const exisitingItem = composedData.find(
            (composedItem) => composedItem.id === item.id
        );

        if (exisitingItem) {
            exisitingItem.playerTwoValue = item.playerOneValue;
            exisitingItem.playerTwoBenchmark = item.benchmark;
            exisitingItem.playerTwoSubtext = item.subtext;
        } else {
            composedData.push({
                ...item,
                playerOneValue: null,
                benchmark: null,
                playerTwoValue: item.playerOneValue,
                playerTwoBenchmark: item.benchmark,
                playerTwoSubtext: item.subtext
            });
        }
    });

    composedData = composedData.sort((a, b) => {
        // sorting by round number first
        // to avoid string sorting that would sort "10" < "2"
        if (a.roundNumber !== b.roundNumber) {
            return a.roundNumber - b.roundNumber;
        }

        // now if rounds are the same, sort by the id string
        return a.id < b.id ? -1 : 1;
    });

    return composedData;
};
