/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';

import { TooltipButton } from 'common/react/components/tooltip-button';
import { TableCellContent } from './TableCellContent';

/**
 * @param {object} props - Props.
 * @param {object[]} props.rows - Rows to render.
 * @param {Function} props.getTableBodyProps - Get table body props function.
 * @param {Function} props.prepareRow - Prepare row function.
 * @param {Function} props.primaryTooltipComponent
 * @returns {JSX.Element} Component.
 */
export const TableBody = ({
    rows,
    getTableBodyProps,
    prepareRow,
    primaryTooltipComponent
}) => {
    return (
        <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
                prepareRow(row);

                const bye = row?.original?.bye;

                const tooltipData = {
                    row: {
                        player: row.original.player.playerId,
                        seasonPid: row.original.player.seasonPid
                    },
                    cell: {
                        column: {
                            id: `${row.original.roundNumber}`
                        },
                        value: {
                            actual: row.original.votes
                        }
                    }
                };

                return (
                    <tr
                        {...row.getRowProps()}
                        className={`stats-table__body-row`}
                        key={`brownlow-player-modal-table__row-${rowIndex}`}
                    >
                        <td
                            className="stats-table__cell"
                            colSpan={row.cells.length}
                        >
                            {bye ? (
                                <div className="brownlow-player-modal-table__cell-inner">
                                    <TableCellContent
                                        row={row}
                                        rowIndex={rowIndex}
                                    />
                                </div>
                            ) : (
                                <TooltipButton
                                    id={`brownlow-player-modal-table__row-${rowIndex}-tooltip`}
                                    title={getCellTitle(row, tooltipData.cell)}
                                    hideArrow
                                    noPadding
                                    externalTooltip
                                    showOnHover={false}
                                    direction="auto"
                                    primaryTooltipContent={primaryTooltipComponent(
                                        tooltipData.row,
                                        tooltipData.cell
                                    )}
                                    classNames={{
                                        component:
                                            'brownlow-player-modal-table__button-wrapper',
                                        button: 'brownlow-player-modal-table__button',
                                        tooltip:
                                            'brownlow-player-modal-table__tooltip'
                                    }}
                                >
                                    <div className="brownlow-player-modal-table__cell-inner">
                                        <TableCellContent
                                            row={row}
                                            rowIndex={rowIndex}
                                        />
                                    </div>
                                </TooltipButton>
                            )}
                        </td>
                    </tr>
                );
            })}
        </tbody>
    );
};

TableBody.propTypes = {
    rows: PropTypes.array.isRequired,
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired,
    primaryTooltipComponent: PropTypes.func
};

const getCellTitle = (row, cell) =>
    `${row.original.player.player.givenName} ${row.original.player.player.surname} versus ${row.original.opponent.name}: ${cell.column.value} votes.`;
