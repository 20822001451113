import { put, call } from 'redux-saga/effects';
import { fetchWithMisToken } from '../../../../utils/fetch-with-mis-token';
import {
    FETCH_STAT_COMPARISON,
    removeStatComparisonRequest,
    setStatComparison,
    setStatComparisonRequest
} from '../actions';

/**
 * @typedef {object} FetchStatComparisonAction
 * @property {object} payload
 * @property {string} payload.type
 * @property {string} payload.playerId
 * @property {string} [payload.seasonId]
 */

/**
 * @param {FetchStatComparisonAction} action - The Redux action.
 */
export function* fetchStatComparison(action) {
    const { playerId } = action.payload;

    yield put(setStatComparisonRequest(action.payload));

    let data = {};

    data = yield call(
        fetchWithMisToken,
        `${PULSE.app.environment.statsPro}/playerCareerSeasonStats/${playerId}/benchmarked`
    );

    if (data.yearlySeasonStats) {
        yield put(setStatComparison(data));
    } else {
        // Error handling for when there is no data
        yield put(
            setStatComparison({
                yearlySeasonStats: ['no data available']
            })
        );
    }

    yield put(removeStatComparisonRequest(action.payload));

    yield put(FETCH_STAT_COMPARISON.success(data));
}
