import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { MatchStatusText } from './MatchStatusText';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const MatchStatus = ({ match, byes, isFinals, utcStartTime }) => {
    const matchStatuses = PULSE.app.common.CONSTANTS.MATCH_STATUS;
    return (
        <div
            className={classNames('fb-match__status fb-match__row', {
                'fb-match__status--byes': byes?.length
            })}
        >
            {match ? (
                <>
                    <span className="fb-match__status-text">
                        {match &&
                            PULSE.app.common.match.cancelledOrPostponed(
                                match.status
                            ) && (
                                <SvgIcon
                                    className={`fb-match__status-text-icon fb-match__status-text-icon--${match.status.toLowerCase()}`}
                                    icon="warning"
                                />
                            )}
                        {isFinals ? (
                            <span>
                                {luxon.DateTime.fromISO(match.utcStartTime, {
                                    zone: 'utc'
                                })
                                    .toLocal()
                                    .toFormat('ccc h:mm a')}
                            </span>
                        ) : (
                            <MatchStatusText
                                utcStartTime={utcStartTime}
                                match={match}
                                matchStatuses={matchStatuses}
                                byes={byes}
                            ></MatchStatusText>
                        )}
                    </span>
                    <span className="fb-match__status-dot">&middot;</span>

                    <span
                        className="fb-match__status-venue"
                        title={match.venue.name}
                    >
                        {match.venue.abbreviation}
                    </span>
                </>
            ) : byes ? (
                <span className="fb-match__status-text">
                    <MatchStatusText
                        utcStartTime={utcStartTime}
                        match={match}
                        matchStatuses={matchStatuses}
                        byes={byes}
                    ></MatchStatusText>
                </span>
            ) : null}
        </div>
    );
};

MatchStatus.propTypes = {
    match: PropTypes.object,
    byes: PropTypes.array,
    isFinals: PropTypes.bool,
    isCountdown: PropTypes.bool,
    utcStartTime: PropTypes.string
};
