const { translations } = PULSE.I18N;

/**
 * @param {string} label - A translation label key.
 * @returns {string} The translation.
 */
export const getTranslation = (label) => translations[label];

/**
 * @param {string} label - A translation label key.
 * @param {object} replaceMap - An object of strings to replace inside the translation
 * @returns {string} The translation.
 */
export const lookupTranslation = (label, replaceMap) =>
    PULSE.I18N.lookup(label, replaceMap);

/**
 * @typedef {(suffix: string) => string} CurriedGetTranslation
 */

/**
 * @param {string} prefix - The beginning of a translation label key.
 * @returns {CurriedGetTranslation} A function which will get the translation
 *    based on the provided prefix and suffix.
 */
export const composeGetTranslation = (prefix) => {
    return (suffix) => {
        return translations[prefix + suffix];
    };
};

/**
 * @param {string} text - A translation label key or text.
 * @param {string} fallback - A fallback translation label key or text.
 * @param {string} className - Places the translation in a span element with a class name.
 * @returns {string} The translation html.
 */
export const getFallbackTranslation = (text, fallback, className) => {
    // Get the translation
    const translation = lookupTranslation(text);

    let returnTranslation =
        translation === text ? lookupTranslation(fallback) : translation;
    if (returnTranslation.includes('|')) {
        const newClass = className ? className : '';
        let translationTextArray = returnTranslation.split('|');

        returnTranslation = `${translationTextArray[0]} <span class='${newClass}'>${translationTextArray[1]}</span>`;
    }

    return returnTranslation;
};
