import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { PlayerComparison } from '../components/player-comparison';

export const PlayerComparisonProvider = ({ store, data }) => (
    <Provider store={store}>
        <PlayerComparison data={data} />
    </Provider>
);

PlayerComparisonProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
};
