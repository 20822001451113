import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useGetContentQuery } from 'common/store/modules/api/content-api';
import { PlayerModalVideoPlayer } from './PlayerModalVideoPlayer';

export const PlayerModalVideo = ({
    pulsePlayerId,
    accountId,
    policyKey,
    playerId,
    anchorName,
    isModalOpen
}) => {
    const [activeVideo, setActiveItem] = useState(null);

    const { videoItem } = useGetContentQuery(
        {
            type: 'VIDEO',
            params: {
                pageSize: 1,
                references: `AFL_PLAYER:${pulsePlayerId}`,
                tagExpression: `(%"ProgramCategory:Draft")`
            }
        },
        {
            selectFromResult: ({ data: videoData }) => ({
                videoItem: videoData?.content[0]
            }),
            skip: !pulsePlayerId
        }
    );

    useEffect(() => {
        setActiveItem(null);

        if (videoItem) {
            setActiveItem(videoItem);
        }
    }, [videoItem]);

    return activeVideo ? (
        <PlayerModalVideoPlayer
            videoItem={activeVideo}
            accountId={accountId}
            policyKey={policyKey}
            playerId={playerId}
            anchorName={anchorName}
            isModalOpen={isModalOpen}
        />
    ) : null;
};

PlayerModalVideo.propTypes = {
    pulsePlayerId: PropTypes.number,
    accountId: PropTypes.string.isRequired,
    policyKey: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
    anchorName: PropTypes.string,
    isModalOpen: PropTypes.bool
};
