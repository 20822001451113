import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../../setup-react';

// ===== Signals ============================================================ //

// Player stats
export const FETCH_TOOLTIP_PLAYER_STATS = createSignalAction(
    'brownlow/tooltip',
    'FETCH_TOOLTIP_PLAYER_STATS'
);

// ===== Deltas ============================================================= //

// Player stats
export const SET_TOOLTIP_PLAYER_STATS = createDeltaActionType(
    'brownlow/tooltip',
    'SET_TOOLTIP_PLAYER_STATS'
);
export const setTooltipPlayerStats = createActionCreator(
    SET_TOOLTIP_PLAYER_STATS
);

export const SET_TOOLTIP_PLAYER_STATS_REQUEST = createDeltaActionType(
    'brownlow/tooltip',
    'SET_TOOLTIP_PLAYER_STATS_REQUEST'
);
export const setTooltipPlayerStatsRequest = createActionCreator(
    SET_TOOLTIP_PLAYER_STATS_REQUEST
);

export const REMOVE_TOOLTIP_PLAYER_STATS_REQUEST = createDeltaActionType(
    'brownlow/tooltip',
    'REMOVE_TOOLTIP_PLAYER_STATS_REQUEST'
);
export const removeTooltipPlayerStatsRequest = createActionCreator(
    REMOVE_TOOLTIP_PLAYER_STATS_REQUEST
);
