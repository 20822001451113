import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';

import { MatchCardEventOneTeam } from './MatchCardEventOneTeam';
import { MatchCardEventTwoTeams } from './MatchCardEventTwoTeams';

/**
 * @constant {number} EVENT_CUTOFF - How many seconds ago does an event happen to be display an animation
 */
const EVENT_CUTOFF = 15;
const ONE_SECOND = 1000;

/**
 * Check if a goal has happened recently enough to display an event for it
 *
 * @param {number} goalTime - Seconds when the goal happened
 * @param {number} latestTime - Latest time recorded from the match
 * @returns {boolean} - True if goal happened recently
 */
function between(goalTime, latestTime) {
    let below = latestTime.periodSeconds - EVENT_CUTOFF;

    if (goalTime > below) {
        return true;
    }

    return false;
}

/**
 * Checks when the next period will start then stops the end of quarter event
 * from firing 5 mins before the start of the next quarter.
 *
 * @param {object} period - The period clock object
 * @returns {boolean} - True if we want to show an event for the period
 */
function timeChecker(period) {
    const timeNow = Date.now();
    const nextPeriod = Date.parse(period.nextPeriodStart);
    const lastAnimationTime = nextPeriod - ONE_SECOND * EVENT_CUTOFF;
    if (timeNow > lastAnimationTime) {
        return false;
    }

    return true;
}

/**
 * The event card for a match event animation
 *
 * @param matchInfo.matchInfo
 * @param {object} matchInfo
 * @returns {JSX.Element} Component
 */
export const MatchCardEvent = ({ matchInfo }) => {
    const [event, setEvent] = useState([]);
    const [goal, setGoal] = useState([]);
    const [periodTrigger, setPeriodTrigger] = useState('');

    const matchId = matchInfo?.match?.matchId
        ? matchInfo.match.matchId
        : matchInfo.providerId;
    const wormData = useSelector((state) =>
        window.PULSE.app.redux.selectors.match.scoreWorm(state, matchId)
    );
    const matchTime = useSelector((state) =>
        window.PULSE.app.redux.selectors.match.matchClock(state, matchId)
    );
    const matchInfoStatus = useSelector((state) =>
        window.PULSE.app.redux.selectors.match.isActuallyLive(state, matchId)
    );

    if (wormData) {
        if (wormData.scoringEvents.length !== goal.length) {
            setGoal(wormData.scoringEvents);
        }
    }

    /*
        Adds the latest goal to the event array, once a new goal event has been added to the goal state via the scoreWorm.
    */
    useEffect(() => {
        if (matchTime && goal.length > 0) {
            let latestGoal = goal[goal.length - 1];
            let latestTime = matchTime.periods[0];
            if (
                between(latestGoal.periodSeconds, latestTime) &&
                latestTime.periodNumber === latestGoal.periodNumber
            ) {
                setEvent((prevState) => [
                    ...prevState,
                    { latestGoal, shown: false }
                ]);
            }
        }
    }, [goal]);

    /*
        Adds the latest quarter to the events array when a period has started or ended.
    */
    useEffect(() => {
        if (matchTime && periodTrigger !== '') {
            let latestTime = matchTime.periods[0];
            setEvent((prevState) => [
                ...prevState,
                { latestTime, shown: false }
            ]);
        }
    }, [periodTrigger]);

    if (matchTime) {
        let latestTime = matchTime.periods[0];
        // stops animations from happening if the game isn't live
        if (!matchInfoStatus) {
            return null;
        }
        if (
            latestTime.periodCompleted &&
            timeChecker(latestTime) &&
            periodTrigger !== 'end'
        ) {
            setPeriodTrigger('end');
        } else if (
            latestTime.periodSeconds < EVENT_CUTOFF + 1 &&
            periodTrigger !== 'start'
        ) {
            setPeriodTrigger('start');
        }
    }

    /**
     * Remove an event from the event state
     *
     * @param {object} eventToRemove - The event to remove
     */
    function handleRemove(eventToRemove) {
        let array = event;
        const index = array.indexOf(eventToRemove);
        if (index !== -1) {
            array.splice(index, 1);
            setEvent(array);
        }
    }

    return (
        <>
            {event.map((item, index) => (
                <ErrorBoundary key={index} fallback={<></>}>
                    {item.latestGoal ? (
                        <MatchCardEventOneTeam
                            key={index}
                            event={item}
                            removeEvent={handleRemove}
                        />
                    ) : (
                        <MatchCardEventTwoTeams
                            key={index}
                            event={item}
                            match={matchInfo}
                            removeEvent={handleRemove}
                        />
                    )}
                </ErrorBoundary>
            ))}
        </>
    );
};

MatchCardEvent.propTypes = {
    matchInfo: PropTypes.object
};
