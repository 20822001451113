import React from 'react';
import PropTypes from 'prop-types';

import { getTranslation } from 'common/react/utils/translations';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';

export const CardHeader = ({ status, fixture }) => {
    const selectedRegion = useSingleSiteSetting('region');

    const homeTeam = fixture.home.team;
    const awayTeam = fixture.away.team;

    const matchLink = PULSE.app.common.url.getMatchURL(fixture);

    return (
        <>
            <a
                className="broadcast-guide__header-mc-link u-hide-until-desktop"
                href={matchLink}
            >
                <SvgIcon
                    icon="football"
                    className="broadcast-guide__header-mc-link-icon"
                />
                <span>
                    {getTranslation('label.broadcastGuide.match-centre')}
                </span>
            </a>

            <a
                href={matchLink}
                className="broadcast-guide__header-mobile-link u-hide-from-desktop"
            ></a>

            <div className="broadcast-guide__header-wrapper">
                <div
                    className={`broadcast-guide__header-team broadcast-guide__header-team--home ${
                        homeTeam.abbreviation.toLowerCase() === 'wce'
                            ? 'broadcast-guide--team-theme-b'
                            : ''
                    }`}
                >
                    <TeamIcon
                        providerId={homeTeam.providerId}
                        theme={PULSE.app.common.team.getTeamLogoTheme(
                            homeTeam.abbreviation,
                            'b'
                        )}
                        className="broadcast-guide__header-badge"
                    />
                </div>

                <div className="broadcast-guide__header-meta">
                    <span className="broadcast-guide__header-versus">
                        {homeTeam.name} v {awayTeam.name}
                    </span>
                </div>

                <div
                    className={`broadcast-guide__header-team broadcast-guide__header-team--away ${
                        awayTeam.abbreviation.toLowerCase() === 'wce'
                            ? 'broadcast-guide--team-theme-b'
                            : ''
                    } `}
                >
                    <TeamIcon
                        providerId={awayTeam.providerId}
                        theme={PULSE.app.common.team.getTeamLogoTheme(
                            awayTeam.abbreviation,
                            'b'
                        )}
                        className="broadcast-guide__header-badge"
                    />
                </div>

                <div className="broadcast-guide__header-notch">
                    {status ===
                        PULSE.app.common.CONSTANTS.MATCH_STATUS.CANCELLED ||
                    status ===
                        PULSE.app.common.CONSTANTS.MATCH_STATUS.POSTPONED ||
                    status === PULSE.app.common.CONSTANTS.MATCH_STATUS.TBC ? (
                        <time className="broadcast-guide__header-notch-time">
                            {getTranslation('label.fixtures.tbc')}
                        </time>
                    ) : (
                        <>
                            <time
                                className="broadcast-guide__header-notch-time"
                                dateTime={luxon.DateTime.fromISO(
                                    fixture.utcStartTime,
                                    { zone: selectedRegion.timezone }
                                ).toISO()}
                            >
                                {luxon.DateTime.fromISO(fixture.utcStartTime, {
                                    zone: selectedRegion.timezone
                                }).toFormat(
                                    PULSE.app.common.CONSTANTS.MATCH_TIME_FORMAT
                                )}
                                <span className="broadcast-guide__header-notch-ampm">
                                    {luxon.DateTime.fromISO(
                                        fixture.utcStartTime,
                                        {
                                            zone: selectedRegion.timezone
                                        }
                                    ).toFormat('a')}
                                </span>
                            </time>
                            <span className="broadcast-guide__header-notch-timezone">
                                {PULSE.app.common.regionHelper.abbreviation(
                                    selectedRegion.timezone,
                                    moment(fixture.utcStartTime)
                                        .tz(selectedRegion.timezone)
                                        .format('z')
                                )}
                            </span>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

CardHeader.propTypes = {
    status: PropTypes.string.isRequired,
    fixture: PropTypes.object.isRequired
};
