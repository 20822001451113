import React from 'react';
import PropTypes from 'prop-types';
import { LadderNav } from './LadderNav';
import { LadderTable } from './LadderTable';

export const Ladder = (data) => {
    return (
        <div className="stats-section-wrapper wrapper pb-8 mb-0">
            <LadderNav {...data} />
            <LadderTable />
        </div>
    );
};

Ladder.propTypes = {
    data: PropTypes.object
};
