import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';

export const InformationCardFooter = ({
    selectedLocation,
    googlePlacesData
}) => {
    return (
        <section className="interactive-event-map__location-footer interactive-event-map__section">
            <div className="interactive-event-map__location-website">
                <SvgIcon icon="globe" className="icon" />
                <a
                    className="interactive-event-map__location-website-url"
                    href={
                        selectedLocation?.promoUrl
                            ? selectedLocation?.promoUrl
                            : googlePlacesData?.website
                    }
                    target="_blank"
                    rel="noreferrer"
                >
                    {selectedLocation?.linkText
                        ? selectedLocation?.linkText
                        : googlePlacesData?.name}
                </a>
            </div>
        </section>
    );
};

InformationCardFooter.propTypes = {
    selectedLocation: PropTypes.shape({
        promoUrl: PropTypes.string,
        linkText: PropTypes.string
    }),
    googlePlacesData: PropTypes.shape({
        website: PropTypes.string,
        name: PropTypes.string
    })
};
