import { STATS_COLUMNS_FOR_CATEGORY } from 'widgets/statspro/js/components/common/config/columns';

export const composeRows = (seasonNavId, category, isAFLM) => {
    return [
        ...STATS_COLUMNS_FOR_CATEGORY[category]
            .filter((col) => {
                if (!isAFLM) {
                    return col.id !== 'ratingPoints';
                }

                return col;
            })
            .map((col) => ({
                name: col.name,
                accessor: col.id
            }))
    ];
};
