import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { VideoHubViewByMatch } from '../components/ViewByMatch/VideoHubViewByMatch';

export const VideoHubViewByMatchProvider = ({ store, data }) => (
    <Provider store={store}>
        <VideoHubViewByMatch {...data} />
    </Provider>
);

VideoHubViewByMatchProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
