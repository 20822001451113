import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { roundByRoundNavSelector } from 'store/modules/brownlow-tracker/round-by-round/selectors/nav-selector';
import { useRoundMatches } from './hooks/use-round-matches';
import { MatchCardVoteFetcher } from './match-card-vote-fetcher';
import { Loader } from 'common/react/components/Loader';
import { EmptyState } from 'common/react/components/EmptyState';
import { matchByMatchSelector } from 'store/modules/brownlow-tracker/match-by-match/selectors';

export const RoundByRoundCards = () => {
    const nav = useSelector(roundByRoundNavSelector);
    const votesData = useSelector((state) =>
        matchByMatchSelector(state, nav?.season?.providerId)
    );

    const { isLoading, matches } = useRoundMatches({
        competitionId: nav.competition.id,
        compSeasonId: nav.season.id,
        roundNumber: nav.round
    });

    const filteredMatches = useMemo(() => {
        if (!matches?.length) {
            return [];
        }

        // Only display concluded matches to remove cancelled games
        return matches.filter(
            (match) =>
                match.status ===
                PULSE.app.common.CONSTANTS.MATCH_STATUS.CONCLUDED
        );
    }, [matches]);

    return isLoading ? (
        <div className="brownlow-round-by-round__loader">
            <Loader />
        </div>
    ) : filteredMatches.length ? (
        <div className="brownlow-round-by-round__content">
            {filteredMatches.map((match) => (
                <MatchCardVoteFetcher
                    key={match.id}
                    matchData={match}
                    totalVotes={votesData?.totalVotes}
                    seasonPid={nav.season.providerId}
                    seasonId={nav.season.id}
                    modifier="round-by-round"
                    playerModalEnabled={true}
                />
            ))}
        </div>
    ) : (
        <div className="brownlow-round-by-round__empty-state">
            <EmptyState
                titleTranslation="label.brownlow.rbr.error.noDataAvailable"
                summaryTranslation="label.brownlow.tracker.error.noDataAvailable.summary"
            />
        </div>
    );
};
