import { useSelector } from 'react-redux';

import { leaderboardNavSelector } from 'common/store/modules/brownlow-tracker/leaderboard/selectors';
import { compSeasonSelector } from 'common/store/modules/brownlow-tracker/leaderboard/selectors/compseason-selector';

/**
 * Using the nav selector to target elements in the store, return the
 * competitionId, compseason object and round object that we should be using
 * when rendering information based upon the competition nav's current set
 * filters
 *
 * @returns {object} - Contains the comptitionId, competitionPid and compSeason object
 */
export function useGetCurrentCompFilters() {
    const nav = useSelector(leaderboardNavSelector);
    const compSeason = useSelector(compSeasonSelector);

    return {
        competitionId: nav?.competition?.id,
        competitionPid: nav?.competition?.providerId,
        compSeason
    };
}
