import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { EventsListingByDate } from './EventsListingByDate';

export const EventsListingByFilter = ({
    eventsArray,
    localTimezone,
    compseasonId
}) => {
    const sortedFilterByDate = useMemo(() => {
        return groupEventsByEndDate(eventsArray, localTimezone);
    }, [eventsArray, localTimezone]);

    return sortedFilterByDate.map((dateGroup, index) => (
        <EventsListingByDate
            eventsArray={dateGroup}
            localTimezone={localTimezone}
            compseasonId={compseasonId}
            activeStartDate={dateGroup[0].startDate}
            key={dateGroup[0].startDate}
            showTimeDisclaimer={index === 0}
        />
    ));
};

const groupEventsByEndDate = (sortedEvents, localTimezone) => {
    const groups = {};

    sortedEvents.forEach((event) => {
        const endDate = moment(event.endDate)
            .tz(localTimezone)
            .format('YYYY-MM-DD 00:00:00');

        if (!groups[endDate]) {
            groups[endDate] = [];
        }

        groups[endDate].push(event);
    });

    // Sort the groups by date
    const result = [];
    Object.keys(groups)
        .sort((a, b) => (moment(a).isBefore(b) ? -1 : 1))
        .forEach((key) => {
            result.push(groups[key]);
        });

    return result;
};

EventsListingByFilter.propTypes = {
    eventsArray: PropTypes.array.isRequired,
    localTimezone: PropTypes.string,
    compseasonId: PropTypes.string
};
