import React from 'react';
import PropTypes from 'prop-types';
import { BenchmarkLabel } from 'common/react/components/benchmarking';
import { BENCHMARKING } from 'common/react/components/benchmarking/config/benchmarking';

/**
 * @param {object} props - Props.
 * @param {string} props.title - Title.
 * @param {number} props.averageValue - Average value.
 * @param {string} props.benchmark - Benchmark.
 * @returns {JSX.Element} Component.
 */
export const KeyStatsAndPerformanceAverage = ({
    title,
    averageValue,
    benchmark
}) => (
    <div className="key-stats-and-performance__average-stats-details">
        <p className="key-stats-and-performance__average-stats-title">
            {title}
        </p>
        <p className="key-stats-and-performance__average-stats-value">
            {averageValue}
        </p>
        {benchmark && (
            <BenchmarkLabel benchmarkKey={BENCHMARKING[benchmark].KEY} />
        )}
    </div>
);

KeyStatsAndPerformanceAverage.propTypes = {
    title: PropTypes.string.isRequired,
    averageValue: PropTypes.number.isRequired,
    benchmark: PropTypes.string
};
