import { combineReducers } from 'redux';
import { roundByRoundRootSaga } from './round-by-round/sagas';
import { roundByRoundReducer } from './round-by-round/reducer';
import { playerModalReducer } from './player-modal/reducer';
import { playerModalRootSaga } from './player-modal/sagas';
import { matchByMatchReducer } from './match-by-match/reducer';
import { matchByMatchRootSaga } from './match-by-match/sagas';
import { leaderboardRootSaga } from './leaderboard/sagas';
import { leaderboardReducer } from './leaderboard/reducer';
import { tooltipRootSaga } from './tooltip/sagas';
import { tooltipReducer } from './tooltip/reducer';

const sagas = [
    matchByMatchRootSaga,
    roundByRoundRootSaga,
    playerModalRootSaga,
    leaderboardRootSaga,
    tooltipRootSaga
];

const rootReducer = combineReducers({
    matchByMatch: matchByMatchReducer,
    roundByRound: roundByRoundReducer,
    playerModal: playerModalReducer,
    leaderboard: leaderboardReducer,
    tooltip: tooltipReducer
});

// @ts-expect-error
if (window.PULSE.app.redux) {
    // @ts-expect-error
    window.PULSE.app.redux.sagas.brownlowtracker = { root: sagas };
    // @ts-expect-error
    window.PULSE.app.redux.reducers.brownlowtracker = { root: rootReducer };
} else {
    // @ts-expect-error
    window.PULSE.app.redux = {
        reducers: {
            brownlowtracker: {
                root: rootReducer
            }
        },
        sagas: {
            brownlowtracker: {
                root: sagas
            }
        }
    };
}
