import React from 'react';
import PropTypes from 'prop-types';

export const OverviewTableRow = ({ children }) => {
    return (
        <div role="row" className="overview-table__row">
            {children}
        </div>
    );
};

OverviewTableRow.propTypes = {
    children: PropTypes.any
};
