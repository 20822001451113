import React, { useEffect, useRef } from 'react';
import { SvgIcon } from './SvgIcon';
import { getTranslation } from '../utils/translations';
import PropTypes from 'prop-types';

export const PlayerSearchInput = ({
    setDebouncedQuery,
    isActive,
    onClick,
    onClear,
    query
}) => {
    useEffect(() => {
        if (isActive) {
            if (query) {
                inputRef.current.value = query;
            }
        }
    }, [isActive]);

    const inputRef = useRef(null);

    // Clears the player search input element
    const clearPlayerSearchInput = () => {
        inputRef.current.value = '';
        if (typeof onClear === 'function') {
            onClear();
        }
    };

    return (
        <div
            className={
                'player-search__input-wrap ' + (isActive ? 'is-active' : '')
            }
        >
            <div className="player-search__input-wrap-inner">
                <label
                    htmlFor="statsPlayerSearch"
                    className="player-search__label"
                >
                    <span className="u-screen-reader">
                        {getTranslation(
                            'label.brownlow.predictor.searchPlayers'
                        )}
                    </span>
                    <SvgIcon
                        icon={`search`}
                        className={`player-search__icon`}
                    />
                </label>
                <input
                    className="player-search__input"
                    type="search"
                    autoComplete="off"
                    placeholder={getTranslation(
                        'label.brownlow.predictor.searchPlayers'
                    )}
                    id="statsPlayerSearch"
                    onChange={(event) => setDebouncedQuery(event.target.value)}
                    ref={inputRef}
                />
                <button
                    className="player-search__input-close"
                    aria-label={getTranslation('label.button.cancel')}
                    onClick={(event) => {
                        setDebouncedQuery('');
                        clearPlayerSearchInput(event);
                    }}
                >
                    <SvgIcon
                        icon={`close-hover`}
                        className={`player-search__close-icon`}
                    />
                </button>
            </div>

            <button onClick={onClick} className="player-search__cancel">
                {getTranslation('label.button.cancel')}
            </button>
        </div>
    );
};

PlayerSearchInput.propTypes = {
    setDebouncedQuery: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    onClear: PropTypes.func,
    query: PropTypes.string
};
