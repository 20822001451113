import { useMemo } from 'react';

/**
 * @param {Array} data - The matches data array.
 * @param {number} matchesVisibleNumber - The number of matches to render.
 * @returns {Array} matchesToRender - An array of matches.
 */
export const useFilterMatches = (data, matchesVisibleNumber) => {
    const matchItems = useMemo(() => {
        let matchesToRender;

        // Group results based on start day/month
        if (data) {
            const matchStatuses = data.map((match) =>
                PULSE.app.common.match.getMatchStatus(match.status)
            );

            const numberMatchesToRender = Math.min(
                matchesVisibleNumber,
                data.length
            );

            const countLive = matchStatuses.filter(
                (status) => status === 'LIVE'
            ).length;
            const countUpcoming = matchStatuses.filter(
                (status) => status === 'UPCOMING'
            ).length;
            const firstIndexLiveUpcoming = data.findIndex((match) => {
                return match.status !== 'CONCLUDED';
            });

            /**
             * Render less matches than there are live/upcoming matches: display the first (numberMatchesToRender)
             * of live/upcoming group
             */
            if (numberMatchesToRender < countLive + countUpcoming) {
                matchesToRender = data.slice(
                    firstIndexLiveUpcoming,
                    firstIndexLiveUpcoming + numberMatchesToRender
                );

                /**
                 * Render more matches than there are live/upcoming matches: in addition to rendering all live/upcoming
                 * matches, start rendering completed matches (starting with the most recent)
                 */
            } else {
                matchesToRender = data.slice(`-${numberMatchesToRender}`);
            }
        }

        return matchesToRender;
    }, [data, matchesVisibleNumber]);

    return matchItems;
};
