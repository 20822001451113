import React from 'react';
import PropTypes from 'prop-types';
import { getTranslation } from 'common/react/utils/translations';

/**
 * @param {object} props - Props.
 * @param {object} props.category - Players stat category.
 * @returns {JSX.Element} Component.
 */
export const FeaturedHeadToHeadStatTitle = ({ category }) => (
    <div className="stats-card-table__stat-title">
        {category.type === 'average' ? (
            <span>
                <span className="stats-card-table__stat-title-avg">
                    {getTranslation('label.average.abbr')}
                </span>
                {' ' +
                    getTranslation(
                        'label.statsLeaders.' + category.key + '.name'
                    )}
            </span>
        ) : (
            getTranslation('label.statsLeaders.' + category.key + '.name')
        )}
    </div>
);

FeaturedHeadToHeadStatTitle.propTypes = {
    category: PropTypes.object.isRequired
};
