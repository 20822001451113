import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';

import { FixtureLiveTime } from './FixtureLiveTime';
import { FixtureSummaryCountdown } from '../fixture-details/FixtureSummaryCountdown';

/*  If LIVE
        Display Match In-Progress Time
    If UPCOMING
        Display Match Start Time
    If COMPLETED
        Display Full Time translation string
    Else (TBC, CANCELLED, POSTPONED)
        Display status translation string
*/

export const FixtureStatus = ({ status, match, liveMatch }) => {
    const startTime = moment(match.utcStartTime);
    const selectedRegion = useSingleSiteSetting('region');

    const matchId = liveMatch?.match.matchId
        ? liveMatch.match.matchId
        : match.providerId;

    const matchTime = useSelector((state) =>
        window.PULSE.app.redux.selectors.match.timeString(state, matchId)
    );

    const renderStatus = () => {
        if (status === PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE) {
            return (
                <div
                    className={`fixtures__status-label ${
                        matchTime.secondaryStatus.useClock
                            ? 'fixtures__status-label-countdown'
                            : ''
                    }`}
                >
                    {matchTime.secondaryStatus.useClock ? (
                        <FixtureSummaryCountdown
                            match={match}
                            liveMatch={liveMatch}
                        />
                    ) : null}
                    <FixtureLiveTime match={match} liveMatch={liveMatch} />
                </div>
            );
        }

        if (status === PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING) {
            return (
                <div className="fixtures__status-label">
                    <div>
                        {startTime
                            .tz(selectedRegion.timezone)
                            .format(
                                PULSE.app.common.CONSTANTS.MATCH_TIME_FORMAT
                            )}
                        <span className="fixtures__scheduled-time-ampm">
                            {startTime.tz(selectedRegion.timezone).format('a')}
                        </span>
                    </div>
                    <span className="fixtures__status-timezone">
                        {PULSE.app.common.regionHelper.abbreviation(
                            selectedRegion.timezone,
                            startTime.tz(selectedRegion.timezone).format('z')
                        )}
                    </span>
                </div>
            );
        }

        if (status === PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED) {
            return (
                <div className="fixtures__status-label">
                    {getTranslation(`label.fixtures.full-time`)}
                </div>
            );
        }

        if (
            status === PULSE.app.common.CONSTANTS.MATCH_STATUS.TBC ||
            status === PULSE.app.common.CONSTANTS.MATCH_STATUS.POSTPONED ||
            status === PULSE.app.common.CONSTANTS.MATCH_STATUS.CANCELLED
        ) {
            return (
                <div className="fixtures__status-label">
                    <SvgIcon
                        className="icon fixtures__status-warning"
                        icon="warning"
                    />
                    {getTranslation(`label.fixtures.${status.toLowerCase()}`)}
                </div>
            );
        }

        return;
    };

    return (
        <div
            className={`fixtures__status-wedge fixtures__status-wedge--${status}`}
        >
            <div className="fixtures__status-wedge-inner">{renderStatus()}</div>
        </div>
    );
};

FixtureStatus.propTypes = {
    status: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    liveMatch: PropTypes.object
};
