import { all, takeEvery } from 'redux-saga/effects';
import {
    FETCH_PLAYER_MODAL_LEADERBOARD_DATA,
    FETCH_PLAYER_MODAL_DATA,
    UPDATE_PLAYER_MODAL,
    FETCH_PREDICTED_DATA
} from '../actions';
import { fetchModalLeaderboardData } from './fetch-modal-leaderboard-data';
import { fetchPlayerModalData } from './fetch-player-modal-data';
import { updatePlayerModal } from './update-player-modal';
import { fetchPredictedData } from './fetch-modal-predicted-data';

/**
 *
 */
export function* playerModalRootSaga() {
    yield all([
        takeEvery(FETCH_PLAYER_MODAL_DATA.REQUEST, fetchPlayerModalData),
        takeEvery(UPDATE_PLAYER_MODAL.REQUEST, updatePlayerModal),
        takeEvery(
            FETCH_PLAYER_MODAL_LEADERBOARD_DATA.REQUEST,
            fetchModalLeaderboardData
        ),
        takeEvery(FETCH_PREDICTED_DATA.REQUEST, fetchPredictedData)
    ]);
}
