import React from 'react';
import PropTypes from 'prop-types';

export const ImageElement = ({ ImageUrl, ImageTitle }) => {
    return (
        <picture className="object-fit-cover-picture">
            <source
                className=""
                src={`${ImageUrl}?width=174&amp;height=100, ${ImageUrl}?width=348&amp;height=200 2x, ${ImageUrl}?width=522&amp;height=300 3x`}
                media="(min-width: 174px)"
            />
            <img
                className="js-faded-image fade-in-on-load object-fit-cover-picture__img is-loaded"
                src={`${ImageUrl}?width=174&amp;height=100`}
                alt={`${ImageTitle} video thumbnail image`}
            />
        </picture>
    );
};

ImageElement.propTypes = {
    ImageUrl: PropTypes.string,
    ImageTitle: PropTypes.string
};
