/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react';

import { getTranslation } from 'common/react/utils/translations';
import { getPlayerHeadshotSize } from 'widgets/statspro/js/components/common/utils';
import { useSeasonYear, useCompSeasonProvider } from '../hooks';
import { STATS_CATEGORY_IDS } from 'widgets/statspro/js/components/common/config/performance-stats';

const CLASS_BLOCK = 'performance-chart-tooltip';

export const CustomTooltip = ({ active, payload, player, stat }) => {
    const seasonYear = useSeasonYear();
    const compSeasonProvider = useCompSeasonProvider();
    const ref = useRef();
    const statTranslation = getTranslation(
        'label.performanceChart.' + STATS_CATEGORY_IDS[stat]
    );

    useEffect(() => {
        if (ref.current && typeof compSeasonProvider !== 'undefined') {
            new PULSE.app.PlayerHeadshot(ref.current, compSeasonProvider);
        }
    }, [ref, active, compSeasonProvider]);

    if (active && payload.length) {
        return (
            <div className={CLASS_BLOCK} ref={ref}>
                <div className={`${CLASS_BLOCK}__row`}>
                    <div>
                        <div className={`${CLASS_BLOCK}__name`}>
                            {' '}
                            {`${player.playerDetails.givenName} ${player.playerDetails.surname}`}
                        </div>
                        {payload[0].payload.subtext && (
                            <div className={`${CLASS_BLOCK}__subtext`}>
                                {payload[0].payload.subtext}
                            </div>
                        )}
                    </div>
                    <div className={`${CLASS_BLOCK}__headshot-wrapper`}>
                        <div
                            className="js-player-image"
                            data-player={player.playerId.replace('CD_I', '')}
                            data-size={getPlayerHeadshotSize(seasonYear)}
                            data-picture-in-view="false"
                            data-year={seasonYear}
                        />
                    </div>
                </div>

                <div
                    className={`${CLASS_BLOCK}__row ${CLASS_BLOCK}__row--align-end`}
                >
                    <div className={`${CLASS_BLOCK}__stat`}>
                        <span>{payload[0].value} </span>
                        <span>
                            {STATS_CATEGORY_IDS[stat] === 'dreamTeamPoints'
                                ? getTranslation(
                                      'label.performanceChart.pointsAbbreviation'
                                  )
                                : statTranslation}
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};
