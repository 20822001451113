import React from 'react';
import PropTypes from 'prop-types';

import { getTranslation } from 'common/react/utils/translations';

export const MobileTablePredictedRow = ({ rowData, cssClass }) => {
    return (
        <div className={`${cssClass}__body-row`}>
            {rowData[0]?.row.original.totalPredictedVotes ? (
                <div className={`${cssClass}__row-header`}>
                    {getTranslation('label.brownlow.predictor.predicted')}
                </div>
            ) : (
                ''
            )}

            {rowData.map((cell, cellIndex) => {
                return cell.value.predicted ||
                    cell.row.original.totalPredictedVotes ? (
                    <div
                        key={cellIndex}
                        className={`${cssClass}__predicted-cell ${
                            !cell.value.predicted
                                ? `${cssClass}__predicted-cell--hidden`
                                : ''
                        }`}
                    >
                        {cell.value.predicted}
                    </div>
                ) : (
                    ''
                );
            })}
        </div>
    );
};

MobileTablePredictedRow.propTypes = {
    rowData: PropTypes.array,
    cssClass: PropTypes.string
};
