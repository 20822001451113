import React from 'react';

import DefaultCellRendererContainer from './DefaultCellRendererContainer';

/*
    This is the default component to use when rendering a table cell, if no custom renderer is defined.
    It can take a custom className and a value to render, any additional props will also be passed onto the
    <td> element.
*/

const DefaultCellRenderer = ({ className, value, ...additionalProps }) => {
    return (
        <DefaultCellRendererContainer
            className={className}
            {...additionalProps}
        >
            {value}
        </DefaultCellRendererContainer>
    );
};

export default DefaultCellRenderer;
