import React from 'react';
import { PropTypes } from 'prop-types';

import { ImageElement } from './ImageElement';
import { PictureElement } from './PictureElement';

const ONE_HUNDRED = 100;

export const LazyImage = ({
    image = false,
    mediaQueryConfig = false,
    useImg = false,
    cssClass = '',
    wrapperCssClass = '',
    objectFit = true,
    url = '',
    renderAsPortrait = false,
    style = {},
    lazyload = 'lazy'
}) => {
    if (image || url) {
        let objectFitVal = objectFit;
        let styleObj = { ...style };

        if (image && mediaQueryConfig) {
            if (!objectFitVal) {
                const aspectRatio = image.aspectRatio;
                objectFitVal = true;
                const paddingTop = (ONE_HUNDRED / aspectRatio).toString();
                styleObj['padding-top'] = `${paddingTop}%`;
            }

            const imageUrl = PULSE.app.common.image.getOnDemandImageUrl(
                image,
                mediaQueryConfig[mediaQueryConfig.length - 1].size
            );

            const imagePictureElement =
                imageUrl && useImg ? (
                    <ImageElement
                        url={imageUrl}
                        title={image.title}
                        cssClass={cssClass}
                        lazyload={lazyload}
                    />
                ) : (
                    <PictureElement
                        image={image}
                        mediaQueryConfig={mediaQueryConfig}
                        pictureCssClass={cssClass}
                        objectFit={objectFitVal}
                        renderAsPortrait={renderAsPortrait}
                        lazyload={lazyload}
                    />
                );

            return (
                <div
                    className={`js-lazy-load u-observed lazy-image-wrapper ${wrapperCssClass}`}
                    style={styleObj}
                >
                    {imagePictureElement}
                </div>
            );
        } else if (url) {
            return (
                <ImageElement
                    url={url}
                    title={image?.title}
                    cssClass={cssClass}
                    lazyload={lazyload}
                    objectFit={objectFit}
                />
            );
        }
    }
};

LazyImage.propTypes = {
    image: PropTypes.any,
    mediaQueryConfig: PropTypes.any,
    useImg: PropTypes.bool,
    cssClass: PropTypes.string,
    wrapperCssClass: PropTypes.string,
    objectFit: PropTypes.bool,
    url: PropTypes.string,
    renderAsPortrait: PropTypes.bool,
    style: PropTypes.object,
    lazyload: PropTypes.string
};
