import React from 'react';
import PropTypes from 'prop-types';

import { FixturesDateHeader } from 'widgets/fixtures/js/components/fixtures-list/FixturesDateHeader';
import { Card } from './Card';

const DIGIT_ONLY_REGEX = /^[0-9]*$/;

export const CardItem = ({ item, location, competitionId, seasonId }) => {
    if (!item) {
        return <></>;
    }

    const itemType =
        typeof item === 'string'
            ? item.match(DIGIT_ONLY_REGEX) || item === 'TBCPC'
                ? 'date'
                : 'round'
            : 'match';

    switch (itemType) {
        case 'date':
            return <FixturesDateHeader dateKey={item} />;
        case 'round':
            return null;
        default:
            return (
                <Card
                    location={location}
                    fixture={item}
                    competitionId={competitionId}
                    seasonId={seasonId}
                />
            );
    }
};

CardItem.propTypes = {
    item: PropTypes.any.isRequired,
    location: PropTypes.object,
    competitionId: PropTypes.string,
    seasonId: PropTypes.string
};
