// eslint-disable-next-line no-unused-vars
import { MatchByMatchState } from '../types';
import '../../types';

/**
 * Takes the array of all players and creates an object with a key of a players
 * providerId and a value of their total votes. This is quicker to do now, so
 * each MatchCardTable component doesn't need to array.find through the whole
 * array everytime.
 *
 * @param {Array} players - An array of all the players in the brownlow
 * @returns {object} - An object of all players, key of provider id and value of
 * their total votes
 */
function createTotalVotesObject(players = []) {
    const totalVotes = {};

    for (const player of players) {
        totalVotes[player.player.playerId] = player.votes;
    }

    return totalVotes;
}

/**
 * @param {MatchByMatchState} draft - Draft state.
 * @param {object} payload - Action payload.
 * @param payload.seasonPid - The seasonId of the match by match data
 * @param payload.data - The response of the match by match season request
 */
export const setMatchByMatchData = (draft, { seasonPid, data }) => {
    data.totalVotes = createTotalVotesObject(data?.players);
    draft[seasonPid] = data ?? null;
};
