import { put } from 'redux-saga/effects';
import {
    CHANGE_GLOSSARY_MODAL_VISIBILITY,
    setGlossaryModalVisible
} from '../actions';

/**
 *
 * @param action
 */
export function* changeGlossaryModalVisibility(action) {
    const { isVisible } = action.payload;

    yield put(setGlossaryModalVisible(isVisible));
    yield put(CHANGE_GLOSSARY_MODAL_VISIBILITY.success());
}
