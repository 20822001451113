import React from 'react';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const ToggleSearch = ({ onClick }) => {
    return (
        <button onClick={onClick} className="stats-search__toggle-search">
            <span className="u-screen-reader">Toggle player search</span>
            <SvgIcon icon={`search`} className={`stats-search__icon`} />
        </button>
    );
};
