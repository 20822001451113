import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { PlayerPhoto } from 'common/react/components/PlayerPhoto';
import { getTranslation } from 'common/react/utils/translations';
import { PlayerModalPickBar } from './PlayerModalPickBar';

export const PlayerModalHeader = ({
    playerName,
    photoUrl,
    profileUrl,
    pickOrder,
    draftedBy,
    roundPicked
}) => {
    const dispatch = useDispatch();
    const firstName = playerName?.split(' ').slice(0, -1).join(' ');
    const lastName = playerName?.split(' ').slice(-1).join(' ');
    const lastNameHyphenated = lastName?.includes('-') ? true : false;

    useEffect(() => {
        document.addEventListener('keydown', handleEsc);

        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, []);

    return (
        <section
            className={`modal-centered__draft-player-header 
                ${
                    pickOrder
                        ? 'modal-centered__draft-player-header--large-margin'
                        : ''
                }
                ${
                    lastNameHyphenated
                        ? 'modal-centered__draft-player-header--hyphenated'
                        : ''
                }
            `}
        >
            <button
                className="modal-title__close-btn"
                aria-label="Close draft prospect modal"
                onClick={() => {
                    dispatch(
                        PULSE.app.redux.actions.draftTracker.updateModal({
                            visible: false,
                            data: null
                        })
                    );
                }}
            >
                <SvgIcon icon="close" className="icon" />
            </button>
            <div className="modal-centered__draft-player-header-left-container">
                {playerName ? (
                    <h3 className="modal-centered__draft-player-header-name">
                        <span className="modal-centered__draft-player-header-name--first-name">
                            {firstName}
                        </span>
                        <span className="modal-centered__draft-player-header-name--last-name">
                            {lastName}
                        </span>
                    </h3>
                ) : null}

                {profileUrl ? (
                    <a
                        className="modal-centered__draft-player-header-profile-btn"
                        href={profileUrl}
                        onClick={() => {
                            PULSE.app.tracking.recordEvent(
                                PULSE.app.common.CONSTANTS.TRACKING.EVENTS
                                    .CLICK,
                                {
                                    eventData: {
                                        /* eslint-disable camelcase */
                                        component_name: getTranslation(
                                            'label.draftTracker.modal'
                                        ),
                                        click_text: getTranslation(
                                            'label.player.profile'
                                        )
                                        /* eslint-enable camelcase */
                                    }
                                }
                            );
                        }}
                    >
                        <SvgIcon
                            icon="person"
                            className="icon"
                            folder="elements"
                            subfolder="stats"
                        />
                        {getTranslation('label.stats.playerProfile')}
                    </a>
                ) : null}
            </div>

            <div className="modal-centered__draft-player-header-right-container">
                {photoUrl ? (
                    <PlayerPhoto
                        className="modal-centered__draft-player-header-photo"
                        photoUrl={photoUrl}
                        scale="0.5"
                        alt={playerName}
                    />
                ) : null}
            </div>
            {pickOrder ? (
                <PlayerModalPickBar
                    pickOrder={pickOrder}
                    draftedBy={draftedBy}
                    roundPicked={roundPicked}
                />
            ) : null}
        </section>
    );
};

PlayerModalHeader.propTypes = {
    playerName: PropTypes.string,
    photoUrl: PropTypes.string,
    profileUrl: PropTypes.string,
    pickOrder: PropTypes.number,
    draftedBy: PropTypes.string,
    roundPicked: PropTypes.number
};

const handleEsc = function (evt) {
    const redux = PULSE.app.redux;
    const { store, actions } = redux;

    evt || window.event;
    let isEscape = false;
    if ('key' in evt) {
        isEscape = evt.key === 'Escape' || evt.key === 'Esc';
    } else {
        isEscape = evt.keyCode === PULSE.app.common.CONSTANTS.KEY_CODES.ESCAPE;
    }
    if (isEscape) {
        store.dispatch(
            actions.draftTracker.updateModal({
                visible: false,
                data: null
            })
        );
    }
};
