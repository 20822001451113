const DIGIT_ONLY_REGEX = /^[0-9]*$/;

/**
 * Finds the first live or upcoming fixture from the provided matches data.
 *
 * @param {Array} matchesData - An array of match objects. Each match object should have a `status` property.
 *
 * @returns {object} - The first live or upcoming match object. If no such match is found, returns an empty object.
 */

export const findFirstLiveOrUpcomingFixture = (matchesData) => {
    let firstLiveOrUpcomingFixture = {};
    firstLiveOrUpcomingFixture = [...matchesData]
        .reverse()
        .find(
            (match) =>
                PULSE.app.common.match.getMatchStatus(match.status) ===
                PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE
        );
    if (!firstLiveOrUpcomingFixture) {
        firstLiveOrUpcomingFixture = matchesData.find(
            (match) =>
                PULSE.app.common.match.getMatchStatus(match.status) ===
                PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING
        );
    }
    return firstLiveOrUpcomingFixture;
};

/**
 * Finds the last occurrence of a specific round name in the array and inserts a new object.
 * If the round is not present, inserts the new round in the appropriate position based on its round number.
 *
 * @param {Array} array - The array to search and insert into. It contains a mix of strings and objects.
 * @param {object} newObject - The object to insert. It should have the properties:
 *  - `name` {string}: The name of the round to find or insert.
 *  - `roundNumber` {number}: The number of the round for sorting.
 *
 * @returns {Array} - The modified array with the new object inserted.
 */

export const findLastInstanceAndInsert = (array, newObject) => {
    let lastIndex = -1;
    const newRoundNumber = newObject.roundNumber;

    // Find the last index of the round
    for (let i = 0; i < array.length; i++) {
        if (typeof array[i] === 'string' && array[i] === newObject.name) {
            lastIndex = i;
        }
    }

    if (lastIndex === -1) {
        // The round is missing; find where it should be inserted
        let insertIndex = -1;

        for (let i = 0; i < array.length; i++) {
            if (
                typeof array[i] === 'string' &&
                (array[i].startsWith('Round') || array[i].startsWith('Week')) &&
                parseInt(array[i].split(' ')[1], 10) > newRoundNumber
            ) {
                insertIndex = i - 1;
                break;
            }
        }

        if (insertIndex === -1) {
            insertIndex = array.length;
        }

        // Create and insert the new round
        array.splice(insertIndex, 0, newObject);

        lastIndex = insertIndex;
    } else {
        // Find the index of the next date string/number after the last occurrence of the round
        let nextDateIndex = -1;
        for (let i = lastIndex + 1; i < array.length; i++) {
            if (
                typeof array[i] === 'string' &&
                (DIGIT_ONLY_REGEX.test(array[i]) || array[i] === 'TBCPC')
            ) {
                nextDateIndex = i;
                break;
            }
        }

        if (nextDateIndex === -1) {
            array.push(newObject);
        } else {
            array.splice(nextDateIndex, 0, newObject);
        }
    }

    return array;
};

/**
 * Checks if the teams that have a bye on the round are included
 * in the filters.
 *
 * @param {object} item - The round that gets checked
 * @param {Array} filters - The filters we are checking to see if the item is included in.
 * @returns {Array} - Returns an array with any filtered items that match the round's byes
 */
export const filteredTeams = (item, filters) => {
    const teamIds = filters.teams.map((id) => parseInt(id));
    const byes = item.byes || (item.round ? item.round.byes : []);
    return byes.filter((bye) => teamIds.includes(bye.id));
};
