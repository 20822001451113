import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'common/react/components/select';
import { getTranslation } from 'common/react/utils/translations';
import { useGetTeamsQuery } from 'store/modules/api/afl-api';
import { UPDATE_NAV_ITEM } from 'store/modules/brownlow-tracker/leaderboard/actions';
import { leaderboardNavSelector } from 'store/modules/brownlow-tracker/leaderboard/selectors/nav-selector';

export const ClubNavItem = () => {
    const dispatch = useDispatch();
    const nav = useSelector(leaderboardNavSelector);
    const { data: [...teamsList] = [] } = useGetTeamsQuery(nav.season.id, {
        skip: !nav.season.id ? true : false
    });

    const teams = [
        { id: -1, name: getTranslation('label.stats.allClubs') },
        ...teamsList.sort((a, b) => a.name.localeCompare(b.name))
    ];

    return (
        <Select
            items={teams}
            itemToString={(team) => team?.name}
            initialSelectedItem={
                teams.length && nav.team.id
                    ? teams.find((t) => t.id === nav.team.id)
                    : ''
            }
            activeItem={
                teams.length && nav.team.id
                    ? teams.find((s) => s.id === nav.team.id)
                    : ''
            }
            onSelectedItemChange={({ selectedItem }) => {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'team',
                        value: { id: selectedItem.id }
                    })
                );
            }}
            isReadyToRender={() => teams?.length}
            label={getTranslation('label.filters.club')}
        />
    );
};
