import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';

import { SharePopup } from '.';
import { getTranslation } from 'common/react/utils/translations';

export const ShareButton = ({
    eventId,
    activeId,
    setActiveSharePopup,
    shareLink,
    ticketsLink
}) => {
    const isActive = eventId === activeId;

    const containerRef = useRef(null);

    const handleClickOutside = (event) => {
        if (
            containerRef.current &&
            !containerRef.current.contains(event.target)
        ) {
            setActiveSharePopup('');
        }
    };

    const handleBlur = (event) => {
        if (
            !event.relatedTarget?.classList.contains(
                'events-listing__share-popup-inner'
            )
        ) {
            setActiveSharePopup('');
        }
    };

    useEffect(() => {
        if (isActive) {
            document.addEventListener('mousedown', handleClickOutside);

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [containerRef, isActive]);

    return (
        <div
            ref={containerRef}
            className={`events-listing__share-container ${
                isActive ? 'events-listing__share-container--active' : ''
            }`}
        >
            {(shareLink || ticketsLink) && (
                <>
                    <button
                        className="events-listing__share-button"
                        onClick={() => setActiveSharePopup(eventId)}
                        aria-label={getTranslation('label.options-button')}
                        onBlur={handleBlur}
                    >
                        <SvgIcon className="icon" icon="options" />
                    </button>

                    {isActive && (
                        <SharePopup
                            onBlur={handleBlur}
                            shareLink={shareLink}
                            ticketsLink={ticketsLink}
                        />
                    )}
                </>
            )}
        </div>
    );
};

ShareButton.propTypes = {
    eventId: PropTypes.number,
    activeId: PropTypes.any,
    setActiveSharePopup: PropTypes.func,
    shareLink: PropTypes.string,
    ticketsLink: PropTypes.string
};
