import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders the ins/outs data.
 *
 * @param {object} props - The props
 * @param {Array} props.playersInHome - The players in home data.
 * @param {Array} props.playersOutHome - The players out home data.
 * @param {Array} props.playersInAway - The players in away data.
 * @param {Array} props.playersOutAway - The players out away data.
 * @param {string} props.teamDisplay - Which team to display (both, home or away).
 * @returns {React.JSX.Element} - The rendered component
 */
export const TeamAnnouncementsFieldInsOuts = ({
    playersInHome,
    playersOutHome,
    playersInAway,
    playersOutAway,
    teamDisplay
}) => {
    const inArray =
        teamDisplay === 'home'
            ? playersInHome
            : teamDisplay === 'away'
            ? playersInAway
            : null;
    const outArray =
        teamDisplay === 'home'
            ? playersOutHome
            : teamDisplay === 'away'
            ? playersOutAway
            : null;

    return (
        <>
            <div
                className={
                    'team-announcements-field__footer-section-wrapper team-announcements-field__footer-section-wrapper--in-out'
                }
            >
                <div className="team-announcements-field__footer-section">
                    {(teamDisplay === 'home' || teamDisplay === 'away') && (
                        <span className="team-announcements-field__footer-section-label">
                            <span className="team-announcements-field__ins-outs-tag team-announcements-field__ins-outs-tag--in content-tag">
                                {PULSE.I18N.lookup(
                                    'label.teamlineups.playerIn'
                                )}
                            </span>
                        </span>
                    )}

                    {(teamDisplay === 'home' || teamDisplay === 'away') && (
                        <span className="team-announcements-field__footer-section-values">
                            {inArray?.length > 0 ? (
                                inArray.map((playerIn, index) => (
                                    <span key={`subIn_${index}`}>
                                        {
                                            playerIn?.player?.playerName
                                                ?.givenName
                                        }{' '}
                                        {playerIn?.player?.playerName?.surname}
                                        {playerIn?.reason
                                            ? ` (${playerIn?.reason})`
                                            : null}
                                        {index + 1 !== inArray.length
                                            ? `, `
                                            : null}{' '}
                                    </span>
                                ))
                            ) : (
                                <span>-</span>
                            )}
                        </span>
                    )}

                    {teamDisplay === 'both' && (
                        <>
                            <span className="team-announcements-field__footer-section-values team-announcements-field__footer-section-values--home">
                                {playersInHome?.length > 0 ? (
                                    playersInHome.map((playerIn, index) => (
                                        <span key={`subIn_${index}`}>
                                            {
                                                playerIn?.player?.playerName
                                                    ?.givenName
                                            }{' '}
                                            {
                                                playerIn?.player?.playerName
                                                    ?.surname
                                            }
                                            {playerIn?.reason
                                                ? ` (${playerIn?.reason})`
                                                : null}
                                            {index + 1 !== playersInHome.length
                                                ? `, `
                                                : null}{' '}
                                        </span>
                                    ))
                                ) : (
                                    <span>-</span>
                                )}
                            </span>

                            <span className="team-announcements-field__footer-section-label team-announcements-field__footer-section-label--in-out">
                                <span className="team-announcements-field__ins-outs-tag team-announcements-field__ins-outs-tag--in content-tag">
                                    {PULSE.I18N.lookup(
                                        'label.teamlineups.playerIn'
                                    )}
                                </span>
                            </span>

                            <span className="team-announcements-field__footer-section-values team-announcements-field__footer-section-values--away">
                                {playersInAway?.length > 0 ? (
                                    playersInAway.map((playerIn, index) => (
                                        <span key={`subIn_${index}`}>
                                            {
                                                playerIn?.player?.playerName
                                                    ?.givenName
                                            }{' '}
                                            {
                                                playerIn?.player?.playerName
                                                    ?.surname
                                            }
                                            {playerIn?.reason
                                                ? ` (${playerIn?.reason})`
                                                : null}
                                            {index + 1 !== playersInAway.length
                                                ? `, `
                                                : null}{' '}
                                        </span>
                                    ))
                                ) : (
                                    <span>-</span>
                                )}
                            </span>
                        </>
                    )}
                </div>

                <div className="team-announcements-field__footer-section">
                    {(teamDisplay === 'home' || teamDisplay === 'away') && (
                        <span className="team-announcements-field__footer-section-label team-announcements-field__footer-section-label--in-out">
                            <span className="team-announcements-field__ins-outs-tag team-announcements-field__ins-outs-tag--out content-tag">
                                {PULSE.I18N.lookup(
                                    'label.teamlineups.playerOut'
                                )}
                            </span>
                        </span>
                    )}

                    {(teamDisplay === 'home' || teamDisplay === 'away') && (
                        <span className="team-announcements-field__footer-section-values team-announcements-field__footer-section-values">
                            {outArray?.length > 0 ? (
                                outArray.map((playerOut, index) => (
                                    <span key={`subOut_${index}`}>
                                        {
                                            playerOut?.player?.playerName
                                                ?.givenName
                                        }{' '}
                                        {playerOut?.player?.playerName?.surname}
                                        {playerOut?.reason
                                            ? ` (${playerOut?.reason})`
                                            : null}
                                        {index + 1 !== outArray.length
                                            ? `, `
                                            : null}{' '}
                                    </span>
                                ))
                            ) : (
                                <span>-</span>
                            )}
                        </span>
                    )}

                    {teamDisplay === 'both' && (
                        <>
                            <span className="team-announcements-field__footer-section-values team-announcements-field__footer-section-values--home">
                                {playersOutHome?.length > 0 ? (
                                    playersOutHome.map((playerOut, index) => (
                                        <span key={`subOut_${index}`}>
                                            {
                                                playerOut?.player?.playerName
                                                    ?.givenName
                                            }{' '}
                                            {
                                                playerOut?.player?.playerName
                                                    ?.surname
                                            }
                                            {playerOut?.reason
                                                ? ` (${playerOut?.reason})`
                                                : null}
                                            {index + 1 !== playersOutHome.length
                                                ? `, `
                                                : null}{' '}
                                        </span>
                                    ))
                                ) : (
                                    <span>-</span>
                                )}
                            </span>

                            <span className="team-announcements-field__footer-section-label team-announcements-field__footer-section-label--in-out">
                                <span className="team-announcements-field__ins-outs-tag team-announcements-field__ins-outs-tag--out content-tag">
                                    {PULSE.I18N.lookup(
                                        'label.teamlineups.playerOut'
                                    )}
                                </span>
                            </span>

                            <span className="team-announcements-field__footer-section-values team-announcements-field__footer-section-values--away">
                                {playersOutAway?.length > 0 ? (
                                    playersOutAway.map((playerOut, index) => (
                                        <span key={`subOut_${index}`}>
                                            {
                                                playerOut?.player?.playerName
                                                    ?.givenName
                                            }{' '}
                                            {
                                                playerOut?.player?.playerName
                                                    ?.surname
                                            }
                                            {playerOut?.reason
                                                ? ` (${playerOut?.reason})`
                                                : null}
                                            {index + 1 !== playersOutAway.length
                                                ? `, `
                                                : null}{' '}
                                        </span>
                                    ))
                                ) : (
                                    <span>-</span>
                                )}
                            </span>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

TeamAnnouncementsFieldInsOuts.propTypes = {
    playersInHome: PropTypes.array,
    playersOutHome: PropTypes.array,
    playersInAway: PropTypes.array,
    playersOutAway: PropTypes.array,
    teamDisplay: PropTypes.oneOf(['both', 'home', 'away'])
};
