import reduxQuerySync from 'redux-query-sync';
import { ADD_PLAYER } from 'store/modules/statspro/player-comparison/actions';
import { playersSelector } from 'store/modules/statspro/player-comparison/selectors';
import {
    UPDATE_BENCHMARKING,
    UPDATE_DATA_TYPE,
    UPDATE_FILTER_ITEM,
    UPDATE_NAV_ITEM,
    UPDATE_SORT
} from 'store/modules/statspro/stats-leaders/actions';
import {
    statsLeadersBenchmarkingSelector,
    statsLeadersDataTypeSelector,
    statsLeadersFiltersSelector,
    statsLeadersNavSelector,
    statsLeadersSortSelector
} from 'store/modules/statspro/stats-leaders/selectors';
import { STATS_CATEGORY_ARRAY } from '../components/common/config/categories';

export const configureReduxQuerySync = (store) => {
    return reduxQuerySync({
        store,
        params: {
            category: {
                selector: (state) => {
                    return (
                        statsLeadersNavSelector(state).category ??
                        STATS_CATEGORY_ARRAY[0]
                    );
                },
                action: (id) => {
                    if (!id) {
                        return { type: '' };
                    }

                    return UPDATE_NAV_ITEM.request({
                        navItem: 'category',
                        value: id
                    });
                }
            },
            seasonId: {
                selector: (state) => {
                    return (
                        statsLeadersNavSelector(state).season.id?.toString() ??
                        ''
                    );
                },
                action: (id) => {
                    if (!id) {
                        return { type: '' };
                    }

                    return UPDATE_NAV_ITEM.request({
                        navItem: 'season',
                        value: { id }
                    });
                },
                stringToValue: (seasonId) => parseInt(seasonId) || null
            },
            roundId: {
                selector: (state) => {
                    return (
                        statsLeadersNavSelector(state).round.id?.toString() ??
                        '-1'
                    );
                },
                action: (id) => {
                    if (!id) {
                        return { type: '' };
                    }
                    return UPDATE_NAV_ITEM.request({
                        navItem: 'round',
                        value: { id }
                    });
                },
                stringToValue: (id) => parseInt(id) || null
            },
            roundNumber: {
                selector: (state) => {
                    return (
                        statsLeadersNavSelector(
                            state
                        ).round.number?.toString() ?? '0'
                    );
                },
                action: (num) => {
                    if (!num) {
                        return { type: '' };
                    }
                    return UPDATE_NAV_ITEM.request({
                        navItem: 'round',
                        value: { roundNumber: num }
                    });
                },
                stringToValue: (num) => parseInt(num) || null
            },
            sortColumn: {
                selector: (state) => statsLeadersSortSelector(state).columnId,
                action: (columnId) => {
                    if (!columnId) {
                        return { type: '' };
                    }
                    return UPDATE_SORT.request({ columnId });
                }
            },
            sortDirection: {
                selector: (state) =>
                    statsLeadersSortSelector(state).sortDirection,
                action: (sortDirection) => {
                    if (!sortDirection) {
                        return { type: '' };
                    }
                    return UPDATE_SORT.request({ sortDirection });
                }
            },
            positions: {
                selector: (state) =>
                    statsLeadersFiltersSelector(state).positions,
                action: (value) => {
                    if (!value) {
                        return { type: '' };
                    }
                    return UPDATE_FILTER_ITEM.request({
                        filterItem: 'positions',
                        value
                    });
                },
                stringToValue: (positions) => {
                    if (positions === 'All') {
                        return [];
                    }
                    return positions.trim().length ? positions.split(',') : [];
                },
                valueToString: (positions) => {
                    if (!positions.length) {
                        return 'All';
                    }
                    return positions.join(',');
                }
            },
            teams: {
                selector: (state) => statsLeadersFiltersSelector(state).teams,
                action: (value) => {
                    if (!value) {
                        return { type: '' };
                    }
                    return UPDATE_FILTER_ITEM.request({
                        filterItem: 'teams',
                        value
                    });
                },
                stringToValue: (teams) => {
                    if (teams === 'All') {
                        return [];
                    }
                    return teams.trim().length ? teams.split(',') : [];
                },
                valueToString: (teams) => {
                    if (!teams.length) {
                        return 'All';
                    }
                    return teams.join(',');
                }
            },
            benchmarking: {
                selector: (state) =>
                    statsLeadersBenchmarkingSelector(state).isActive,
                action: (isActive) => {
                    if (isActive === undefined) {
                        return { type: '' };
                    }
                    return UPDATE_BENCHMARKING.request({ isActive });
                },
                stringToValue: (isActive) => isActive === 'true'
            },
            dataType: {
                selector: (state) => statsLeadersDataTypeSelector(state).type,
                action: (type) => {
                    if (!type) {
                        return { type: '' };
                    }
                    return UPDATE_DATA_TYPE.request({ type });
                }
            },
            playerOneId: {
                selector: (state) => playersSelector(state)[1],
                action: (player) => {
                    if (!player) {
                        return { type: '' };
                    }
                    return ADD_PLAYER.request({ id: player, key: 1 });
                }
            },
            playerTwoId: {
                selector: (state) => playersSelector(state)[2],
                action: (player) => {
                    if (!player) {
                        return { type: '' };
                    }
                    return ADD_PLAYER.request({ id: player, key: 2 });
                }
            }
        },

        // Initially set the store's state to the current location.
        initialTruth: 'location'
    });
};
