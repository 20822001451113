import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from './SvgIcon';

/**
 * @param {object} props - Props.
 * @param {string} props.code - ProviderId of the team you want to display
 * @param {string} [props.className] - Class(es).
 * @returns {JSX.Element} Component.
 */
export const CompIcon = ({ code, className = '' }) => {
    let compLogo = '';
    switch (code.toUpperCase()) {
        case 'AFL':
            compLogo = 'afl-logo';
            break;
        case 'AFLW':
            compLogo = 'aflw-logo-white';
            break;
        case 'AFLW-M':
            compLogo = 'aflw-logo';
            break;
        case 'VFL':
            compLogo = 'logo-vfl';
            break;
        case 'VFLW':
            compLogo = 'logo-vflw';
            break;
        case 'WAFL':
            compLogo = 'logo-wafl';
            break;
        case 'SANFL':
            compLogo = 'logo-sanfl';
            break;
        case 'U18CB':
            compLogo = 'logo-u18';
            break;
        case 'U18CG':
            compLogo = 'logo-u18';
            break;
        default:
            compLogo = 'afl-logo';
            break;
    }

    return <SvgIcon className={className} icon={compLogo} />;
};

CompIcon.propTypes = {
    code: PropTypes.string,
    className: PropTypes.string
};
