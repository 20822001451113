import { all, takeEvery } from 'redux-saga/effects';
import {
    UPDATE_BENCHMARKING,
    UPDATE_DATA_TYPE,
    UPDATE_NAV_ITEM,
    UPDATE_SORT
} from '../actions';
import { updateNavItem } from './update-nav-item';
import { updateSort } from './update-sort';
import { updateBenchmarking } from './update-benchmarking';
import { updateDataType } from './update-data-type';

/**
 *
 */
export function* careerAndSeasonStatsRootSaga() {
    yield all([
        takeEvery(UPDATE_NAV_ITEM.REQUEST, updateNavItem),
        takeEvery(UPDATE_SORT.REQUEST, updateSort),
        takeEvery(UPDATE_BENCHMARKING.REQUEST, updateBenchmarking),
        takeEvery(UPDATE_DATA_TYPE.REQUEST, updateDataType)
    ]);
}
