import React from 'react';
import PropTypes from 'prop-types';

import { BroadcasterItem } from './BroadcasterItem';
import { useGetMatchEventsQuery } from 'common/store/modules/api/content-api';
import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';

// in order of display
const BROADCASTERS = ['Channel 7', 'Foxtel', 'Kayo'];

const filterAndSort = (items) => {
    const filtered = items.filter((item) => BROADCASTERS.includes(item.name));
    const sorted = filtered.sort(
        (a, b) => BROADCASTERS.indexOf(a.name) - BROADCASTERS.indexOf(b.name)
    );
    return sorted;
};

export const Broadcasters = ({ matchId, compseasonId, roundNumber }) => {
    const selectedRegion = useSingleSiteSetting('region');

    const { data } = useGetMatchEventsQuery({
        compseason: compseasonId,
        round: roundNumber,
        pageSize: 15
    });

    const matchEvent = data?.content?.find(
        (item) => matchId === item.contentReference.id
    );
    let broadcasters = [];
    let orderedBroadcasters = [];

    if (matchEvent) {
        broadcasters =
            PULSE.app.common.broadcast.extractBroadcastersFromMatchEvent(
                matchEvent,
                selectedRegion?.id
            );
    }

    if (broadcasters) {
        orderedBroadcasters = filterAndSort(broadcasters);
    }

    return orderedBroadcasters?.length ? (
        <div className="fixtures__broadcaster-container">
            {orderedBroadcasters.map((broadcaster) => {
                return (
                    <BroadcasterItem
                        broadcaster={broadcaster}
                        key={broadcaster.id}
                    />
                );
            })}
        </div>
    ) : (
        <></>
    );
};

Broadcasters.propTypes = {
    matchId: PropTypes.any.isRequired,
    compseasonId: PropTypes.number.isRequired,
    roundNumber: PropTypes.number.isRequired
};
