import { STATS_CATEGORY_IDS } from 'widgets/statspro/js/components/common/config/performance-stats';

/**
 * Groups season stats into format needed by the line graph
 *
 * @param {Array} seasonArray
 * @param {object} nav
 * @param {string} type
 * @returns {Array} Array of line graph entries
 */
export const composeSeasonLineData = (seasonArray, nav, type) => {
    const cumulatives = [];
    let composedData = [];

    if (seasonArray[0]?.benchmarkedStats) {
        seasonArray
            .sort((a, b) => {
                if (a.roundNumber < b.roundNumber) {
                    return -1;
                } else if (a.roundNumber > b.roundNumber) {
                    return 1;
                }

                return a.matchId < b.matchId ? -1 : 1;
            })
            .forEach((item, index) => {
                cumulatives[index] =
                    index > 0
                        ? item.benchmarkedStats[
                              STATS_CATEGORY_IDS[nav.category]
                          ].value + cumulatives[index - 1]
                        : item.benchmarkedStats[
                              STATS_CATEGORY_IDS[nav.category]
                          ].value;

                const roundsRepeats = composedData.filter(
                    (round) => round.roundNumber === item.roundNumber
                )?.length;

                composedData.push({
                    id: `${item.roundNumber}_${roundsRepeats}`,
                    roundNumber: item.roundNumber,
                    name: item.roundNumber ?? '',
                    playerOneValue: cumulatives[index],
                    playerTwoValue: null,
                    subtext:
                        type !== 'comparison'
                            ? `${item.roundName} v ${item.opponent.teamAbbr}`
                            : item.roundName,
                    playerTwoSubtext: null
                });
            });
    }

    return composedData;
};
