import { STATS_COLUMN_IDS } from 'widgets/statspro/js/components/common/config/columns';
import { STATS_CATEGORY } from '../../common/config/categories';

export const STATS_LEADERS_CATEGORY_COLUMN_MAP = {
    aflFantasyPoints: {
        category: STATS_CATEGORY.KEY_STATS,
        column: STATS_COLUMN_IDS.AFL_FANTASY_POINTS
    },
    goals: {
        category: STATS_CATEGORY.SCORING,
        column: STATS_COLUMN_IDS.GOALS
    },
    disposals: {
        category: STATS_CATEGORY.DISPOSALS,
        column: STATS_COLUMN_IDS.DISPOSALS
    },
    marks: {
        category: STATS_CATEGORY.MARKS,
        column: STATS_COLUMN_IDS.MARKS
    },
    tackles: {
        category: STATS_CATEGORY.KEY_STATS,
        column: STATS_COLUMN_IDS.TACKLES
    },
    clearances: {
        category: STATS_CATEGORY.STOPPAGES,
        column: STATS_COLUMN_IDS.CLEARANCES
    },
    kicks: {
        category: STATS_CATEGORY.KEY_STATS,
        column: STATS_COLUMN_IDS.KICKS
    },
    handballs: {
        category: STATS_CATEGORY.KEY_STATS,
        column: STATS_COLUMN_IDS.HANDBALLS
    },
    hitouts: {
        category: STATS_CATEGORY.KEY_STATS,
        column: STATS_COLUMN_IDS.HIT_OUTS
    }
};
