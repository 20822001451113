import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const POSITION_ROWS = [
    'backs',
    'halfBacks',
    'centres',
    'halfForwards',
    'forwards',
    'followers',
    'interchanges',
    'substitutes',
    'emergencies'
];

// This needs to match the key in the PlayersModel in TeamAnnouncements.jsx
const SUBS_KEY = 'substitutes';

/**
 * Renders the jumper number and player name.
 *
 * @param {object} props - The props
 * @param {object} props.players - The players to display
 * @param {string} props.teamAbbr - The abbreviation of the team
 * @param {string} props.teamLocation - Home or away
 * @param {string} props.matchStatus - The match status
 * @returns {React.JSX.Element}    - The rendered jumper number and player name.
 */
export const TeamAnnouncementsList = ({
    players,
    teamAbbr,
    teamLocation,
    matchStatus
}) => {
    return POSITION_ROWS.map((item, index) => {
        // Only show subs row for certain match statuses
        if (
            item === SUBS_KEY &&
            !PULSE.app.common.CONSTANTS.DISPLAY_MATCH_STATUSES.includes(
                matchStatus
            )
        ) {
            return null;
        }

        const playerDetails = [];
        const playingPositionGroupTranslation =
            'label.playingposition.group.' + item;

        if (players[item]) {
            for (const pl of players[item]) {
                playerDetails.push(
                    <Fragment key={`player_${index}`}>
                        <span className="team-announcements__player-info">
                            <span
                                key={`player_title_${index}`}
                                className={`team-announcements__jumper-number team-lineups__position-meta-label team-lineups__position-meta-label--${teamLocation} ${teamAbbr.toLowerCase()}`}
                            >
                                {pl.jumperNumber}
                            </span>
                            <span
                                key={`player_value_${index}`}
                                className="team-announcements__player-name"
                            >
                                {pl.player.player.playerName.givenName}{' '}
                                {pl.player.player.playerName.surname}
                            </span>
                        </span>
                    </Fragment>
                );
            }

            return (
                <div
                    key={`${item}-${index}`}
                    className="team-announcements__list-title team-announcements__position-group"
                >
                    <div className="team-announcements__list-title">
                        <span className="team-announcements__position-title">
                            {PULSE.I18N.lookup(playingPositionGroupTranslation)}
                        </span>
                    </div>

                    <div className="team-announcements__list-values">
                        {playerDetails.map((position, detailsIndex) => (
                            <Fragment key={`player-position_${detailsIndex}`}>
                                {position}
                            </Fragment>
                        ))}
                    </div>
                </div>
            );
        }

        return null;
    });
};

TeamAnnouncementsList.propTypes = {
    players: PropTypes.object.isRequired,
    teamAbbr: PropTypes.string,
    teamLocation: PropTypes.string.isRequired,
    matchStatus: PropTypes.string
};
