import React, { useState, useEffect, useMemo, createContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    leaderboardNavSelector,
    leaderboardDataSelector,
    leaderboardFilterSelector,
    leaderboardFavouriteSelector
} from 'store/modules/brownlow-tracker/leaderboard/selectors';
import { Table } from './components/Table';
import { Loader } from 'common/react/components/Loader';
import { EmptyState } from 'common/react/components/EmptyState';
import {
    FETCH_LEADERBOARD_DATA,
    UPDATE_FAVOURITE_PLAYER
} from 'store/modules/brownlow-tracker/leaderboard/actions';
import { composeRowData, composeColumnData, assignPositions } from './utils';
import { useInterval } from 'common/react/hooks/use-interval';
import {
    useGetBrownlowPredictorQuery,
    useGetTeamsQuery
} from 'store/modules/api/afl-api';
import { useGetBrownlowBettingOddsQuery } from 'store/modules/api/cfs-api';

import { useBettingVis } from 'common/react/hooks/use-betting-vis';
import { sortFavourites } from 'widgets/brownlow-predictor-v2/js/utils/utils';

import { useFetchTableData } from '../../../hooks/use-fetch-table-data';
import { useFilteredData } from '../../../hooks/use-search-filter';
import { usePagination } from '../../../hooks/use-fetch-more-data';

import { TooltipContent } from '../../../Tooltip';

import FocusableTooltipArea from 'common/react/components/tooltip-button/focusable-tooltip/FocusableTootlipArea';

const PAGE_SIZE = 15;
const POLLING_DELAY = 30000;
/**
 * @constant {number} LEADER_ROUND_DELAY How many rounds until we start
 * to higlight the leader
 */
const LEADER_ROUND_DELAY = 3;
export const SeasonContext = createContext('');
export const CurrentRoundContext = createContext('');

export const LeaderboardTable = () => {
    const dispatch = useDispatch();

    const nav = useSelector(leaderboardNavSelector);

    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );
    const rounds = useSelector(
        (state) => state.rounds[`season_${nav.season.id}`]?.list ?? []
    );
    const { data: teamsData } = useGetTeamsQuery(nav.season.id, {
        skip: !nav.season.id ? true : false
    });
    const teams = teamsData || [];

    const season = seasons.find((item) => item.id === nav.season.id);
    const seasonPid = season?.providerId ?? '';

    const team = teams.find((item) => item.id === nav.team.id);
    const teamId = team?.providerId ?? '';

    const leaderboardData = useSelector((state) =>
        leaderboardDataSelector(state, seasonPid)
    );

    const favourites = useSelector(leaderboardFavouriteSelector);
    const leaderboardFilter = useSelector(leaderboardFilterSelector);

    const { data: bettingOddsData } = useGetBrownlowBettingOddsQuery(null, {
        pollingInterval: PULSE.app.common.CONSTANTS.TIME.ONE_MINUTE_IN_MS
    });

    const [noDataAvailable, setNoDataAvailable] = useState(false);
    const [isLoading, setIsLoading] = useState();

    const [data, setData] = useState([]);
    let filteredData = useFilteredData(data, leaderboardFilter);

    const [sortByFavourites, setSortByFavourites] = useState(false);

    const [
        shownData,
        fetchMoreData,
        currentPage,
        setShownData,
        setCurrentPage
    ] = usePagination(data, PAGE_SIZE);

    const status = leaderboardData?.status
        ? PULSE.app.common.match.getMatchStatus(leaderboardData.status)
        : '';

    const shouldShowBettingOdds =
        useBettingVis() &&
        status !== PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED &&
        bettingOddsData?.displayInApp === true &&
        bettingOddsData?.outcomes?.length > 0;

    const showPredictedData = nav?.showPredicted; // set as dynamic variable when available
    const currentRoundNumber = season?.currentRoundNumber;

    const [currentPlayerIds, setCurrentPlayerIds] = useState([]);

    // Fetch leaderboard data
    useFetchTableData(seasonPid, teamId, setCurrentPage);

    useEffect(() => {
        setIsLoading(true);
    }, [nav.season, nav.team, nav.competition]);

    /**
     * On Player Favourite Click
     * Modify favourites on player add/remove
     *
     * @param {*} playerId
     */
    const onPlayerFavouriteClick = (playerId) => {
        dispatch(
            UPDATE_FAVOURITE_PLAYER.request({
                id: null,
                providerId: playerId
            })
        );
    };

    // set row data
    useEffect(() => {
        setNoDataAvailable(false);

        if (!leaderboardData?.leaderboard?.length) {
            setNoDataAvailable(true);
            filteredData = [];
            setShownData([]);
            setIsLoading(false);
            return;
        }

        let items = leaderboardData.leaderboard.map((item) => ({
            eligible: item.eligible ?? false,
            player: {
                ...item.player,
                winner: item.winner ?? false
            },
            roundByRoundVotes: item.roundByRoundVotes ?? [],
            team: { ...item.team },
            totalVotes: item.totalVotes ?? '',
            bettingOdds: shouldShowBettingOdds
                ? bettingOddsData?.outcomes?.find(
                      (outcome) => item.player.playerId === outcome.playerId
                  )?.price ?? null
                : null
        }));

        items = assignPositions(items);

        if (favourites.length && sortByFavourites) {
            items = sortFavourites(items, favourites, teamId);
        }

        setData(items);
        setIsLoading(false);
    }, [
        leaderboardData,
        favourites,
        sortByFavourites,
        status,
        teamId,
        bettingOddsData?.outcomes,
        shouldShowBettingOdds,
        setShownData
    ]);

    // set the shown data based on the filtered results
    useEffect(() => {
        setShownData(filteredData.slice(0, PAGE_SIZE * currentPage));
    }, [filteredData, setShownData, currentPage]);

    // predictor RTK hook
    const { data: predicted } = useGetBrownlowPredictorQuery(
        {
            compSeasonId: season?.id,
            players: currentPlayerIds?.join(',')
        },
        {
            skip: !(currentPlayerIds.length && season?.id)
        }
    );

    /**
     * Get columns
     */
    const columns = useMemo(() => composeColumnData(rounds), [rounds]);

    /**
     * Get rows
     */
    const rowData = useMemo(
        () =>
            shownData.map((item) =>
                composeRowData(
                    item,
                    columns.filter(
                        (col) =>
                            !['player', 'totalVotes', 'bettingOdds'].includes(
                                col.accessor
                            )
                    ),
                    rounds,
                    false,
                    predicted?.results
                )
            ),
        [columns, shownData, rounds, predicted?.results]
    );

    // ===== Fetch Predictor data ============================================= //
    useEffect(() => {
        // only request the new items
        const playerDataItems = [...rowData].splice(
            PAGE_SIZE * (currentPage - 1)
        );

        const playerPulseIdPromises = playerDataItems.map((row) => {
            return PULSE.app.common.pulseId
                .getPlayerPulseId(row.player.playerId)
                .then((res) => {
                    return res;
                });
        });

        Promise.all(playerPulseIdPromises).then((res) => {
            setCurrentPlayerIds(res);
        });
    }, [rowData]);

    // ===== Poll when not concluded ========================================== //

    useInterval(() => {
        if (status !== PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED) {
            dispatch(
                FETCH_LEADERBOARD_DATA.request({
                    type: 'season',
                    seasonPid: seasonPid,
                    teamId: teamId ?? ''
                })
            );
        }
    }, POLLING_DELAY);

    // ===== Leader Total Highlighting ====================================== //

    const [showLeader, setShowLeader] = useState(false);

    useEffect(() => {
        if (!team) {
            if (status === PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE) {
                // Check if more than 3 rounds
                if (
                    leaderboardData?.leaderboard?.[0]?.roundByRoundVotes
                        ?.length >= LEADER_ROUND_DELAY
                ) {
                    setShowLeader(true);
                }
            }
        }
    }, [status, team, leaderboardData]);

    // ===== Render ============================================== //

    if (isLoading) {
        // Loader for waiting for data
        return (
            <div className="career-and-season-stats__loader">
                <Loader />
            </div>
        );
    } else if (noDataAvailable) {
        // Error handling for when there is no data
        return (
            <div className="career-and-season-stats__empty-state">
                <EmptyState
                    titleTranslation="label.brownlow.tracker.error.noDataAvailable"
                    summaryTranslation="label.brownlow.tracker.error.noDataAvailable.summary"
                />
            </div>
        );
    }

    return (
        <SeasonContext.Provider value={season}>
            <CurrentRoundContext.Provider value={currentRoundNumber}>
                <FocusableTooltipArea>
                    <Table
                        columns={columns}
                        data={rowData}
                        fullData={filteredData}
                        modifier={`brownlow-tracker-leaderboard ${
                            shouldShowBettingOdds
                                ? 'stats-table--brownlow-tracker-leaderboard--show-odds'
                                : ''
                        }`}
                        update={fetchMoreData}
                        key={rowData[0]?.player.playerId}
                        seasonPid={seasonPid}
                        showLeader={showLeader}
                        favourites={favourites}
                        sortByFavourites={sortByFavourites}
                        setSortByFavourites={setSortByFavourites}
                        onPlayerFavouriteClick={onPlayerFavouriteClick}
                        filteredResults={leaderboardFilter?.query?.length > 0}
                        showPredictedData={showPredictedData}
                        isBrownlowTracker={true}
                        primaryTooltipComponent={(row, cell) => (
                            <TooltipContent
                                row={row.original}
                                cell={cell}
                                player={row.original.player.playerId}
                                seasonPid={seasonPid}
                            />
                        )}
                    />
                </FocusableTooltipArea>
            </CurrentRoundContext.Provider>
        </SeasonContext.Provider>
    );
};

LeaderboardTable.propTypes = {
    cssClass: PropTypes.string
};
