import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { TeamIcon } from 'common/react/components/TeamIcon';
import { MatchStatus } from '../MatchStatus';
import { MatchStatusTime } from '../MatchStatusTime';
import { MatchStatusCountdown } from '../MatchStatusCountdown';
import { MatchStatusText } from '../MatchStatusText';
import { MatchCardEvent } from './MatchCardEvent';
import { useAutoPollingMatchItem } from 'store/modules/api/hooks/useAutoPollingMatchItem';

const matchStatuses = PULSE.app.common.CONSTANTS.MATCH_STATUS;

export const MatchCardFinal = ({ matchInfo }) => {
    const [isCountdown, setIsCountdown] = useState(false);
    const [fetchLiveMatch, setFetchLiveMatch] = useState(false);
    const { data: liveMatch } = useAutoPollingMatchItem(matchInfo.providerId, {
        skip: !fetchLiveMatch
    });

    useEffect(() => {
        const matchInfoStatus = PULSE.app.common.match.getMatchStatus(
            matchInfo.status
        );

        // to be set up only for live matches, matches starting soon, or past start time but not yet complete
        if (
            matchInfoStatus === matchStatuses.LIVE ||
            PULSE.app.common.match.startsWithinAnHour(
                matchInfoStatus,
                matchInfo.utcStartTime
            ) ||
            (PULSE.app.common.match.isPastStartTime(matchInfo.utcStartTime) &&
                matchInfoStatus !== matchStatuses.COMPLETED)
        ) {
            setFetchLiveMatch(true);
        }
    }, [matchInfo.status, matchInfo.utcStartTime]);

    // Model either the liveMatch or matchInfo to have the same properties, no matter where it came from
    let match = !liveMatch
        ? {
              ...matchInfo,
              abbr: `${matchInfo.home.team.nickname} v ${matchInfo.away.team.nickname}`,
              homeScore: matchInfo.home.score?.totalScore,
              awayScore: matchInfo.away.score?.totalScore,
              homeTeamAbbr: matchInfo.home.team.abbreviation.toLowerCase(),
              homeTeamPid: matchInfo.home.team.providerId,
              awayTeamAbbr: matchInfo.away.team.abbreviation.toLowerCase(),
              awayTeamPid: matchInfo.away.team.providerId
          }
        : {
              ...liveMatch,
              homeScore: liveMatch.score?.homeTeamScore.matchScore.totalScore,
              awayScore: liveMatch.score?.awayTeamScore.matchScore.totalScore,
              homeTeamAbbr: liveMatch.match.homeTeam.abbr.toLowerCase(),
              homeTeamPid: liveMatch.match.homeTeamId,
              awayTeamAbbr: liveMatch.match.awayTeam.abbr.toLowerCase(),
              awayTeamPid: liveMatch.match.awayTeamId,
              utcStartTime: liveMatch.match.utcStartTime,
              status: liveMatch.match.status
          };

    const genericStatus = PULSE.app.common.match.getMatchStatus(match.status);
    const isFullTime = genericStatus === matchStatuses.COMPLETED;
    const isCanceledOrPostponed = PULSE.app.common.match.cancelledOrPostponed(
        match.status
    );

    // Watch the generic status, when it changes decide to show the countdown or not
    useEffect(() => {
        if (genericStatus === matchStatuses.UPCOMING && !isCountdown) {
            setIsCountdown(true);
        } else if (isCountdown === true) {
            setIsCountdown(false);
        }
    }, [genericStatus]);

    return (
        <a
            href={PULSE.app.common.url.getMatchURL(matchInfo)}
            className="fb-match fb-match--final"
            aria-label={match.abbr}
        >
            <div className="fb-match__logo fb-match__col">
                <TeamIcon
                    className="fb-match__logo-icon"
                    providerId={match.homeTeamPid}
                />
            </div>
            <div className="fb-match__col fb-match__info-wrapper">
                <div className="fb-match__title">{matchInfo.round.name}</div>
                {!isCountdown && (
                    <div className="fb-match__score-container">
                        <div className="fb-match__score-item fb-match__score-item--score">
                            {match.homeScore}
                        </div>
                        <div
                            className={`fb-match__score-item fb-match__score-item--clock ${
                                isFullTime
                                    ? 'fb-match__score-item--fulltime'
                                    : ''
                            }`}
                        >
                            {genericStatus !== matchStatuses.COMPLETED &&
                            !isCanceledOrPostponed ? (
                                <MatchStatusTime
                                    matchInfo={match}
                                ></MatchStatusTime>
                            ) : (
                                <MatchStatusText
                                    match={match}
                                    matchStatuses={matchStatuses}
                                />
                            )}
                        </div>
                        <div className="fb-match__score-item fb-match__score-item--score">
                            {match.awayScore}
                        </div>
                    </div>
                )}
                {isCountdown && (
                    <MatchStatusCountdown match={match}></MatchStatusCountdown>
                )}
                <MatchStatus
                    utcStartTime={matchInfo.utcStartTime}
                    match={match}
                    isFinals={true}
                    isCountdown={isCountdown}
                ></MatchStatus>
            </div>
            <div className="fb-match__logo fb-match__col">
                <TeamIcon
                    className="fb-match__logo-icon"
                    providerId={match.awayTeamPid}
                />
            </div>
            {matchInfo && (
                <MatchCardEvent matchInfo={matchInfo}></MatchCardEvent>
            )}
        </a>
    );
};

MatchCardFinal.propTypes = {
    matchInfo: PropTypes.object.isRequired
};
