import { Navigation, A11y } from 'swiper';

export const sliderSettings = (name) => {
    return {
        modules: [Navigation, A11y],
        slidesPerView: 1.15,
        navigation: {
            nextEl: '.swiper-button-next-unique-' + name,
            prevEl: '.swiper-button-prev-unique-' + name
        },

        centerInsufficientSlides: true,
        watchSlidesProgress: true,
        initialSlide: 15
    };
};

export const BREAKPOINTS_FOR_SIX_SLIDES = {
    breakpoints: {
        640: {
            slidesPerView: 2.16,
            slidesPerGroup: 2
        },
        840: {
            slidesPerView: 3,
            slidesPerGroup: 3
        },
        1024: {
            slidesPerView: 4,
            slidesPerGroup: 4
        },
        1300: {
            slidesPerView: 6,
            slidesPerGroup: 6
        }
    }
};

export const BREAKPOINTS_FOR_EMBEDDABLE = {
    breakpoints: {
        0: {
            slidesPerView: 1.16,
            slidesPerGroup: 1
        },
        410: {
            slidesPerView: 1.65,
            slidesPerGroup: 1
        },
        1023: {
            slidesPerView: 2.65,
            slidesPerGroup: 2
        },
        1300: {
            slidesPerView: 3.16,
            slidesPerGroup: 3
        }
    }
};
