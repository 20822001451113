import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { navSelector } from 'common/store/modules/competition-nav/selectors';
import { useGetMatchesQuery } from 'common/store/modules/api/afl-api';
import { CardItem } from './CardItem';
import { MatchSkeleton } from './MatchSkeleton';
import { useGroupMatches } from 'common/react/hooks/use-group-matches';
import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';

export const BroadcastGuideCards = ({ seasonId, competitionId }) => {
    const nav = useSelector(navSelector);
    const selectedRegion = useSingleSiteSetting('region');
    const round = nav?.round.roundNumber;
    const location = nav?.location;

    const params = {
        competition: competitionId,
        compSeasonId: seasonId,
        roundNumber: round,
        pageSize: 100
    };

    const { data: matches = [], isFetching: isMatchesFetching } =
        useGetMatchesQuery(params);

    const matchItems = useGroupMatches(matches, selectedRegion.timezone);

    if (isMatchesFetching) {
        return <MatchSkeleton />;
    }

    return (
        <>
            {matchItems?.length
                ? matchItems.map((item, index) => (
                      <CardItem
                          key={
                              typeof item === 'string'
                                  ? `${item}-${index}`
                                  : item.id
                          }
                          item={item}
                          location={location}
                          competitionId={competitionId}
                          seasonId={seasonId}
                      />
                  ))
                : ''}
        </>
    );
};

BroadcastGuideCards.propTypes = {
    seasonId: PropTypes.string,
    competitionId: PropTypes.string
};
