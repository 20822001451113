import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { statsLeadersFiltersSelector } from 'store/modules/statspro/stats-leaders/selectors/filters-selector';
import { UPDATE_FILTER_ITEM } from 'store/modules/statspro/stats-leaders/actions';
import { statsLeadersNavSelector } from 'store/modules/statspro/stats-leaders/selectors';
import { getTranslation } from 'common/react/utils/translations';
import { SelectMultiple } from 'common/react/components/select-multiple';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { useGetTeamsQuery } from 'store/modules/api/afl-api';

export const StatsLeadersTeamsFilters = ({ addOnResetCallback }) => {
    const dispatch = useDispatch();

    const filters = useSelector(statsLeadersFiltersSelector);
    const nav = useSelector(statsLeadersNavSelector);
    const { data: teamsList } = useGetTeamsQuery(nav.season.id, {
        skip: !nav.season.id ? true : false
    });

    const teams = useMemo(
        () =>
            teamsList
                ? [...teamsList].sort((a, b) => a.name.localeCompare(b.name))
                : null,
        [teamsList]
    );

    const updateFilterItem = (filterItem, value) => {
        dispatch(UPDATE_FILTER_ITEM.request({ filterItem, value }));
    };

    return (
        <SelectMultiple
            items={teams}
            getInitialSelectedItems={() => {
                return teams.filter((t) =>
                    filters.teams.includes(t.providerId)
                );
            }}
            label={getTranslation('label.statsLeaders.filters.team')}
            itemToString={(team) => team.name}
            onSelectedItemsChange={(team) => {
                updateFilterItem(
                    'teams',
                    team.map((t) => t.providerId)
                );
            }}
            addOnResetCallback={addOnResetCallback}
            getItemText={(item) => item.name}
            getItemLabel={(item) => (
                <span
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        lineHeight: 1,
                        width: '100%'
                    }}
                >
                    <span style={{ marginRight: '1rem' }}>{item.name}</span>
                    <SvgIcon
                        icon={`aflc-${item.abbreviation.toLowerCase()}-flag`}
                        style={{ width: '3.1rem', maxHeight: '3rem' }}
                    />
                </span>
            )}
        />
    );
};

StatsLeadersTeamsFilters.propTypes = {
    addOnResetCallback: PropTypes.func
};
