import React from 'react';
import { useSelector } from 'react-redux';
import { composeGetTranslation } from 'common/react/utils/translations';
import {
    statComparisonDataTypeSelector,
    statComparisonNavSelector
} from 'store/modules/statspro/stat-comparison/selectors';
import { STATS_CATEGORY_LABELS } from '../../../common/config/categories';

/**
 * @returns {JSX.Element} Component.
 */
export const Heading = () => {
    const nav = useSelector(statComparisonNavSelector);
    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );
    const season = seasons.find((item) => item.id === nav.season.id);
    const dataType = useSelector(statComparisonDataTypeSelector);
    const getTranslation = composeGetTranslation('label.stats.');

    return (
        <>
            <strong>
                {getTranslation(STATS_CATEGORY_LABELS[nav.category])}
            </strong>
            {' - '}
            {nav.season.id === -1 ? getTranslation('career') : season.name}{' '}
            {dataType.type === 'averages' ||
            dataType.type === 'benchmarkedAverages'
                ? getTranslation('averages')
                : getTranslation('totals')}
        </>
    );
};
