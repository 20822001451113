import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FETCH_LEADERBOARD_DATA } from 'common/store/modules/brownlow-tracker/leaderboard/actions';

/**
 * Custom hook to fetch leaderboard data for a specific season and team.
 *
 * @param {string} seasonPrId - The provider ID of the season for which the leaderboard data is being fetched.
 * @param {string} teamId - The provider ID of the team for which the leaderboard data is being fetched.
 * @param {Function} setCurrentPage - Function to reset the current page state.
 */

export const useFetchTableData = (seasonPrId, teamId, setCurrentPage) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            FETCH_LEADERBOARD_DATA.request({
                type: 'season',
                seasonPid: seasonPrId,
                teamId: teamId ?? ''
            })
        );

        // Reset page
        setCurrentPage(1);
    }, [dispatch, seasonPrId, teamId, setCurrentPage]);
};
