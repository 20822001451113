/**
 * Combining data for player one and player two to use in the line graph
 * Returning single array where the player 2 stats are mapped to `playerTwoValue` fields and so on
 *
 * @param {Array} playerOneComposedData
 * @param {Array} playerTwoComposedData
 * @param {Array} seasonArray
 * @returns {Array}
 */
export const composePlayerTwoSeasonLineData = (
    playerOneComposedData = [],
    playerTwoComposedData = [],
    seasonArray = []
) => {
    let composedData = [...playerOneComposedData];

    playerTwoComposedData.forEach((item) => {
        const exisitingItem = composedData.find(
            (composedItem) => composedItem.id === item.id
        );
        if (exisitingItem) {
            exisitingItem.playerTwoValue = item.playerOneValue;
            exisitingItem.playerTwoSubtext = item.playerOneSubtext;
        } else {
            composedData.push({
                ...item,
                playerOneValue: null,
                playerTwoValue: item.playerOneValue,
                playerTwoSubtext: item.subtext
            });
        }
    });

    composedData = composedData.sort((a, b) => {
        // sorting by round number first
        // to avoid string sorting that would sort "10" < "2"
        if (a.roundNumber !== b.roundNumber) {
            return a.roundNumber - b.roundNumber;
        }

        // now if rounds are the same, sort by the id string
        return a.id < b.id ? -1 : 1;
    });

    // Check for empty rounds in player two data and set it to the value from the previous round
    composedData.forEach((composedItem, index) => {
        if (!composedItem.playerOneValue) {
            composedItem.playerOneValue =
                composedData[index - 1]?.playerOneValue;
            composedItem.subtext = seasonArray.find(
                (item) => item.roundNumber === composedItem.roundNumber
            )?.roundName;
        } else if (!composedItem.playerTwoValue) {
            composedItem.playerTwoValue =
                composedData[index - 1]?.playerTwoValue;
            composedItem.playerTwoSubtext = seasonArray.find(
                (item) => item.roundNumber === composedItem.roundNumber
            )?.roundName;
        }
    });

    return composedData;
};
