import { put, call } from 'redux-saga/effects';
import { fetchWithMisToken } from '../../../../utils/fetch-with-mis-token';
import { FETCH_SEARCH_RESULTS, setSearchResults } from '../actions';

/**
 * @typedef {object} FetchSearchResultsAction
 * @property {object} payload
 * @property {string} payload.compseason
 * @property {string} payload.query
 */

/**
 * @param {FetchSearchResultsAction} action - The Redux action.
 */

/**
 *
 * @param action
 */
export function* fetchSearchResults(action) {
    const data = yield call(
        fetchWithMisToken,
        // @ts-ignore
        `${PULSE.app.environment.statsPro}/playersStats/seasons/${action.payload.compseason}?playerNameLike=${action.payload.newQuery}`
    );

    yield put(setSearchResults(data));

    yield put(FETCH_SEARCH_RESULTS.success(data));
}
