import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useGetLeadingPlayerMatchRoundTotalsQuery } from 'common/store/modules/api/statsPro-api';
import { LastRoundHighsSlider } from './LastRoundHighsSlider';

export const RoundHighDataFetcher = ({ isActive, roundPid, isAFLW }) => {
    const { data } = useGetLeadingPlayerMatchRoundTotalsQuery(roundPid);

    return (
        <div
            className={classNames(
                'last-round-highs__slider stats-slider fade-in-on-load',
                {
                    'is-loaded': !!data,
                    'is-active': isActive
                }
            )}
        >
            <LastRoundHighsSlider data={data} type={'round'} isAFLW={isAFLW} />
        </div>
    );
};

RoundHighDataFetcher.propTypes = {
    isActive: PropTypes.bool.isRequired,
    roundPid: PropTypes.string.isRequired,
    isAFLW: PropTypes.bool
};
