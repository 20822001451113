import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { PlayerStats } from '../components/PlayerStats';

export const PlayerStatsProvider = ({ store, data }) => (
    <Provider store={store}>
        <PlayerStats {...data} />
    </Provider>
);

PlayerStatsProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
