import React from 'react';

const TableTitle = ({ title }) => {
    if (!title || typeof title !== 'string') {
        return null;
    }

    return (
        <header className="widget-header widget-header__no-link-to">
            <div className="widget-header__content">
                <h2 className="widget-header__title">{`${title}`}</h2>
            </div>
        </header>
    );
};

export default TableTitle;
