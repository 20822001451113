import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SwitchInput } from 'common/react/components/SwitchInput';
import { statsLeadersBenchmarkingSelector } from 'store/modules/statspro/stats-leaders/selectors';
import { getTranslation } from 'common/react/utils/translations';
import { UPDATE_BENCHMARKING } from 'store/modules/statspro/stats-leaders/actions';

export const BenchmarkingSwitch = () => {
    const dispatch = useDispatch();
    const benchmarking = useSelector(statsLeadersBenchmarkingSelector);

    return (
        <SwitchInput
            id="stats-leaders-benchmarking-toggle"
            label={getTranslation(
                'label.statsLeaders.benchmarking.toggleText'
            ).toUpperCase()}
            isChecked={benchmarking.isActive}
            onChange={(isActive) =>
                dispatch(UPDATE_BENCHMARKING.request({ isActive }))
            }
        />
    );
};
