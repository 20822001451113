import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';

const { app } = PULSE;

const baseQueryWithAuth = fetchBaseQuery({
    baseUrl: app.environment.salesforceSitApi,
    prepareHeaders: (headers) => {
        const token = app.authClient.getAccessToken();
        if (token) {
            headers.set(`authorization`, `Bearer ${token}`);
            return headers;
        }
        throw new Error('Token not available, cancelling request');
    }
});

export const bearerTokenSalesForceQuery = async (args, api, extraOptions) => {
    let result = await baseQueryWithAuth(args, api, extraOptions);

    // If unauthorised, renew token then retry
    if (
        result.error &&
        result.error.status ===
            PULSE.app.common.CONSTANTS.STATUS_CODES.UNAUTHORIZED
    ) {
        // try to get a new token
        const newToken = await PULSE.app.authClient.tokenManager.renew(
            'accessToken'
        );
        if (newToken) {
            // retry the initial query
            result = await baseQueryWithAuth(args, api, extraOptions);
        }
    }

    return result;
};
