import {
    STATS_COLUMNS,
    STATS_COLUMN_IDS
} from 'widgets/statspro/js/components/common/config/columns';

/**
 * NOTE: Most of the time we can look up the value of a stat directly from the
 * contents of the API response but sometimes they have to be calculated (e.g.
 * Marks > Uncontested Marks).
 *
 * @type {{ [x: string]: (string|function)[]; }}
 */
export const RELATED_STATS = {
    [STATS_COLUMN_IDS.AFL_FANTASY_POINTS]: [
        STATS_COLUMN_IDS.KICKS,
        STATS_COLUMN_IDS.HANDBALLS,
        STATS_COLUMN_IDS.MARKS
    ],
    [STATS_COLUMN_IDS.GOALS]: [
        STATS_COLUMN_IDS.BEHINDS,
        STATS_COLUMN_IDS.SHOTS_AT_GOAL,
        STATS_COLUMN_IDS.GOAL_ACCURACY
    ],
    [STATS_COLUMN_IDS.GOAL_ASSIST]: [
        STATS_COLUMN_IDS.GOALS,
        STATS_COLUMN_IDS.INSIDE_50S,
        STATS_COLUMN_IDS.SCORE_INVOLVEMENTS
    ],
    [STATS_COLUMN_IDS.GOAL_ACCURACY]: [
        STATS_COLUMN_IDS.GOALS,
        STATS_COLUMN_IDS.BEHINDS,
        STATS_COLUMN_IDS.SHOTS_AT_GOAL
    ],
    [STATS_COLUMN_IDS.SHOTS_AT_GOAL]: [
        STATS_COLUMN_IDS.GOALS,
        STATS_COLUMN_IDS.BEHINDS,
        STATS_COLUMN_IDS.GOAL_ACCURACY
    ],
    [STATS_COLUMN_IDS.BEHINDS]: [
        STATS_COLUMN_IDS.GOALS,
        STATS_COLUMN_IDS.GOAL_ACCURACY
    ],
    [STATS_COLUMN_IDS.DISPOSALS]: [
        STATS_COLUMN_IDS.KICKS,
        STATS_COLUMN_IDS.HANDBALLS,
        STATS_COLUMN_IDS.DISPOSAL_EFFICIENCY
    ],
    [STATS_COLUMN_IDS.MARKS]: [
        STATS_COLUMN_IDS.CONTESTED_MARKS,
        (stats) => {
            const { id, name } =
                STATS_COLUMNS[STATS_COLUMN_IDS.UNCONTESTED_MARKS];
            const marks = stats[STATS_COLUMN_IDS.MARKS];
            const contestedMarks = stats[STATS_COLUMN_IDS.CONTESTED_MARKS];
            const uncontestedMarks =
                (marks.value ?? marks) -
                (contestedMarks.value ?? contestedMarks);

            return {
                id,
                name,
                value: Math.round(uncontestedMarks * 10) / 10
            };
        },
        STATS_COLUMN_IDS.INTERCEPT_MARKS
    ],
    [STATS_COLUMN_IDS.CONTESTED_MARKS]: [
        STATS_COLUMN_IDS.MARKS,
        STATS_COLUMN_IDS.INTERCEPT_MARKS,
        STATS_COLUMN_IDS.MARKS_INSIDE_50
    ],
    [STATS_COLUMN_IDS.MARKS_INSIDE_50]: [
        STATS_COLUMN_IDS.MARKS,
        STATS_COLUMN_IDS.MARKS_ON_LEAD
    ],
    [STATS_COLUMN_IDS.MARKS_ON_LEAD]: [
        STATS_COLUMN_IDS.MARKS,
        STATS_COLUMN_IDS.MARKS_INSIDE_50
    ],
    [STATS_COLUMN_IDS.INTERCEPT_MARKS]: [
        STATS_COLUMN_IDS.MARKS,
        STATS_COLUMN_IDS.SPOILS,
        STATS_COLUMN_IDS.CONTESTED_DEFENSIVE_ONE_ON_ONES
    ],
    [STATS_COLUMN_IDS.TACKLES]: [
        STATS_COLUMN_IDS.TACKLES_INSIDE_50,
        STATS_COLUMN_IDS.PRESSURE_ACTS
    ],
    [STATS_COLUMN_IDS.TACKLES_INSIDE_50]: [
        STATS_COLUMN_IDS.TACKLES,
        STATS_COLUMN_IDS.PRESSURE_ACTS
    ],
    [STATS_COLUMN_IDS.PRESSURE_ACTS]: [
        STATS_COLUMN_IDS.TACKLES,
        STATS_COLUMN_IDS.ONE_PERCENTERS
    ],
    [STATS_COLUMN_IDS.DEFENSIVE_HALF_PRESSURE_ACTS]: [
        STATS_COLUMN_IDS.PRESSURE_ACTS,
        STATS_COLUMN_IDS.TACKLES,
        STATS_COLUMN_IDS.SPOILS
    ],
    [STATS_COLUMN_IDS.CONTESTED_DEFENSIVE_ONE_ON_ONES]: [
        STATS_COLUMN_IDS.CONTESTED_DEFENSIVE_LOSSES,
        STATS_COLUMN_IDS.CONTESTED_DEFENSIVE_LOSS_PERCENT,
        STATS_COLUMN_IDS.SPOILS
    ],
    [STATS_COLUMN_IDS.CONTESTED_DEFENSIVE_LOSSES]: [
        STATS_COLUMN_IDS.CONTESTED_DEFENSIVE_ONE_ON_ONES,
        STATS_COLUMN_IDS.CONTESTED_DEFENSIVE_LOSS_PERCENT,
        STATS_COLUMN_IDS.SPOILS
    ],
    [STATS_COLUMN_IDS.CONTESTED_DEFENSIVE_LOSS_PERCENT]: [
        STATS_COLUMN_IDS.CONTESTED_DEFENSIVE_ONE_ON_ONES,
        STATS_COLUMN_IDS.CONTESTED_DEFENSIVE_LOSS_PERCENT,
        STATS_COLUMN_IDS.SPOILS
    ],
    [STATS_COLUMN_IDS.CLEARANCES]: [
        STATS_COLUMN_IDS.CENTRE_CLEARANCES,
        STATS_COLUMN_IDS.STOPPAGE_CLEARANCES
    ],
    [STATS_COLUMN_IDS.KICKS]: [
        STATS_COLUMN_IDS.EFFECTIVE_KICKS,
        STATS_COLUMN_IDS.KICK_EFFICIENCY,
        STATS_COLUMN_IDS.KICK_TO_HANDBALL_RATIO
    ],
    [STATS_COLUMN_IDS.HANDBALLS]: [STATS_COLUMN_IDS.KICK_TO_HANDBALL_RATIO],
    [STATS_COLUMN_IDS.HIT_OUTS]: [
        STATS_COLUMN_IDS.RUCK_CONTESTS,
        STATS_COLUMN_IDS.HITOUTS_TO_ADVANTAGE,
        STATS_COLUMN_IDS.HITOUT_WIN_PERCENTAGE
    ],
    [STATS_COLUMN_IDS.FREES_FOR]: [
        STATS_COLUMN_IDS.TACKLES,
        STATS_COLUMN_IDS.PRESSURE_ACTS
    ],
    [STATS_COLUMN_IDS.FREES_AGAINST]: [],
    [STATS_COLUMN_IDS.BOUNCES]: [STATS_COLUMN_IDS.METRES_GAINED],
    [STATS_COLUMN_IDS.ONE_PERCENTERS]: [
        STATS_COLUMN_IDS.TACKLES,
        STATS_COLUMN_IDS.PRESSURE_ACTS,
        STATS_COLUMN_IDS.SPOILS
    ],
    [STATS_COLUMN_IDS.TIME_ON_GROUND]: [],
    [STATS_COLUMN_IDS.INSIDE_50S]: [
        STATS_COLUMN_IDS.SCORE_INVOLVEMENTS,
        STATS_COLUMN_IDS.GOAL_ASSIST,
        STATS_COLUMN_IDS.SHOTS_AT_GOAL
    ],
    [STATS_COLUMN_IDS.DISPOSAL_EFFICIENCY]: [
        STATS_COLUMN_IDS.KICK_EFFICIENCY,
        STATS_COLUMN_IDS.KICK_TO_HANDBALL_RATIO
    ],
    [STATS_COLUMN_IDS.CLANGERS]: [],
    [STATS_COLUMN_IDS.REBOUND_50S]: [
        STATS_COLUMN_IDS.BOUNCES,
        STATS_COLUMN_IDS.METRES_GAINED,
        STATS_COLUMN_IDS.INTERCEPT_POSSESSIONS
    ],
    [STATS_COLUMN_IDS.EFFECTIVE_KICKS]: [
        STATS_COLUMN_IDS.KICKS,
        STATS_COLUMN_IDS.KICK_EFFICIENCY
    ],
    [STATS_COLUMN_IDS.KICK_EFFICIENCY]: [
        STATS_COLUMN_IDS.KICKS,
        STATS_COLUMN_IDS.EFFECTIVE_KICKS
    ],
    [STATS_COLUMN_IDS.KICK_TO_HANDBALL_RATIO]: [
        STATS_COLUMN_IDS.KICKS,
        STATS_COLUMN_IDS.HANDBALLS,
        STATS_COLUMN_IDS.DISPOSAL_EFFICIENCY
    ],
    [STATS_COLUMN_IDS.EFFECTIVE_DISPOSALS]: [
        STATS_COLUMN_IDS.EFFECTIVE_KICKS,
        STATS_COLUMN_IDS.KICK_TO_HANDBALL_RATIO
    ],
    [STATS_COLUMN_IDS.SPOILS]: [
        STATS_COLUMN_IDS.INTERCEPT_MARKS,
        STATS_COLUMN_IDS.CONTESTED_DEFENSIVE_ONE_ON_ONES,
        STATS_COLUMN_IDS.CONTESTED_DEFENSIVE_LOSS_PERCENT
    ],
    [STATS_COLUMN_IDS.CONTESTED_POSSESSIONS]: [
        STATS_COLUMN_IDS.DISPOSALS,
        STATS_COLUMN_IDS.CONTESTED_POSSESSION_RATE
    ],
    [STATS_COLUMN_IDS.UNCONTESTED_POSSESSIONS]: [
        STATS_COLUMN_IDS.DISPOSALS,
        STATS_COLUMN_IDS.CONTESTED_POSSESSION_RATE
    ],
    [STATS_COLUMN_IDS.INTERCEPT_POSSESSIONS]: [
        STATS_COLUMN_IDS.INTERCEPT_MARKS
    ],
    [STATS_COLUMN_IDS.TURNOVERS]: [],
    [STATS_COLUMN_IDS.CONTESTED_POSSESSION_RATE]: [
        STATS_COLUMN_IDS.DISPOSALS,
        STATS_COLUMN_IDS.CONTESTED_POSSESSIONS
    ],
    [STATS_COLUMN_IDS.GROUND_BALL_GETS]: [
        STATS_COLUMN_IDS.FWD_50_GROUND_BALL_GETS,
        STATS_COLUMN_IDS.GOALS
    ],
    [STATS_COLUMN_IDS.FWD_50_GROUND_BALL_GETS]: [
        STATS_COLUMN_IDS.GROUND_BALL_GETS,
        STATS_COLUMN_IDS.GOALS
    ],
    [STATS_COLUMN_IDS.CENTRE_CLEARANCES]: [
        STATS_COLUMN_IDS.CENTRE_BOUNCE_ATTENDANCES
    ],
    [STATS_COLUMN_IDS.STOPPAGE_CLEARANCES]: [
        STATS_COLUMN_IDS.TOTAL_CLEARANCES,
        STATS_COLUMN_IDS.CENTRE_CLEARANCES
    ],
    [STATS_COLUMN_IDS.TOTAL_CLEARANCES]: [
        STATS_COLUMN_IDS.CENTRE_CLEARANCES,
        STATS_COLUMN_IDS.STOPPAGE_CLEARANCES
    ],
    [STATS_COLUMN_IDS.HITOUTS_TO_ADVANTAGE]: [
        STATS_COLUMN_IDS.RUCK_CONTESTS,
        STATS_COLUMN_IDS.HIT_OUTS,
        STATS_COLUMN_IDS.HITOUTS_TO_ADVANTAGE_PERCENTAGE
    ],
    [STATS_COLUMN_IDS.HITOUTS_TO_ADVANTAGE_PERCENTAGE]: [
        STATS_COLUMN_IDS.RUCK_CONTESTS,
        STATS_COLUMN_IDS.HIT_OUTS,
        STATS_COLUMN_IDS.HITOUTS_TO_ADVANTAGE
    ],
    [STATS_COLUMN_IDS.HITOUT_WIN_PERCENTAGE]: [
        STATS_COLUMN_IDS.RUCK_CONTESTS,
        STATS_COLUMN_IDS.HIT_OUTS,
        STATS_COLUMN_IDS.HITOUTS_TO_ADVANTAGE
    ],
    [STATS_COLUMN_IDS.RUCK_CONTESTS]: [
        STATS_COLUMN_IDS.HIT_OUTS,
        STATS_COLUMN_IDS.HITOUTS_TO_ADVANTAGE,
        STATS_COLUMN_IDS.HITOUTS_TO_ADVANTAGE_PERCENTAGE
    ],
    [STATS_COLUMN_IDS.SCORE_INVOLVEMENTS]: [
        STATS_COLUMN_IDS.GOALS,
        STATS_COLUMN_IDS.GOAL_ASSIST
    ],
    [STATS_COLUMN_IDS.SCORE_LAUNCHES]: [
        STATS_COLUMN_IDS.GOALS,
        STATS_COLUMN_IDS.SCORE_INVOLVEMENTS,
        STATS_COLUMN_IDS.GOAL_ASSIST
    ],
    [STATS_COLUMN_IDS.RATING_POINTS]: [
        STATS_COLUMN_IDS.GOALS,
        STATS_COLUMN_IDS.BEHINDS,
        STATS_COLUMN_IDS.DISPOSALS,
        STATS_COLUMN_IDS.SCORE_INVOLVEMENTS,
        STATS_COLUMN_IDS.MARKS,
        STATS_COLUMN_IDS.INSIDE_50S,
        STATS_COLUMN_IDS.CONTESTED_MARKS
    ]
};
