import React from 'react';
import PropTypes from 'prop-types';

import { getTranslation } from 'common/react/utils/translations';

export const OverviewTableDraftCell = ({ player }) => {
    return (
        <div role="cell" className="overview-table__data">
            {player ? (
                <>
                    <span className="overview-table__draft">
                        #{player?.playerDetails?.draftPosition},{' '}
                        {player?.playerDetails?.draftYear}{' '}
                        {getTranslation(
                            `label.stat.${player?.playerDetails?.draftType}`
                        )}
                    </span>
                    <span className="overview-table__recruited-from">
                        {player?.playerDetails?.recruitedFrom}
                    </span>
                </>
            ) : (
                '-'
            )}
        </div>
    );
};

OverviewTableDraftCell.propTypes = {
    player: PropTypes.object
};
