import React from 'react';
import { SvgIcon } from 'common/react/components/SvgIcon';
import PropTypes from 'prop-types';

const CarouselButton = ({ isNext, isFinals, isDisabled }) => {
    return (
        <button
            type="button"
            disabled={isFinals || isDisabled}
            className={`fixture-bar__carousel-button swiper-button-${
                isNext ? 'next' : 'prev'
            }-unique-fixture-bar fixture-bar__carousel-button--${
                isNext ? 'next' : 'prev'
            }  ${!isFinals ? 'fixture-bar__carousel-button--active' : ''}`}
        >
            <SvgIcon
                icon={'chevron'}
                className="fixture-bar__carousel-button-icon"
            />
        </button>
    );
};

CarouselButton.propTypes = {
    isNext: PropTypes.bool.isRequired,
    isFinals: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired
};

export default CarouselButton;
