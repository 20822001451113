import React from 'react';
import PropTypes from 'prop-types';

export const FixturesRoundHeader = ({ roundLabel }) => {
    return <h3 className="fixtures__round-header">{roundLabel}</h3>;
};

FixturesRoundHeader.propTypes = {
    roundLabel: PropTypes.string.isRequired
};
