import React from 'react';
import PropTypes from 'prop-types';

import { GlossaryTableSection } from './GlossaryTableSection';

export const GlossaryTable = ({ glossary, sectionRefs }) => {
    return (
        <table className="stats-glossary__items">
            {Object.keys(glossary)
                .sort()
                .map((key) => {
                    const terms = glossary ? glossary[key] : [];
                    return (
                        <GlossaryTableSection
                            key={`glossary-table-section-${key}`}
                            sectionKey={key}
                            sectionRef={sectionRefs[key]}
                            glossaryTerms={terms}
                        />
                    );
                })}
        </table>
    );
};

GlossaryTable.propTypes = {
    glossary: PropTypes.object.isRequired,
    sectionRefs: PropTypes.object.isRequired
};
