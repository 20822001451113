import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { NavItem } from './NavItem';

export const Nav = ({
    currentElementIndex,
    glossary,
    navItems,
    onNavItemClick
}) => {
    const glossaryKeys = Object.keys(glossary).sort();

    const navContainerRef = createRef();
    const navItemsRefs = navItems.map(() => createRef());

    /*
        when we need to highlight a new nav item, due to the user scrolling through the glossary,
        make sure its visible - scroll it into view if not already visible.
        There are potenitally some devices that won't display the entire nav at mobile breakpoints.
    */
    useEffect(() => {
        if (
            navItemsRefs[currentElementIndex]?.current &&
            navContainerRef?.current
        ) {
            const isVisible = function (ele, container) {
                const { top, bottom } =
                    navItemsRefs[
                        currentElementIndex
                    ].current.getBoundingClientRect();
                const eleTop = top;
                const eleBottom = bottom;

                const containerTop =
                    navItemsRefs[currentElementIndex].current.offsetParent
                        .scrollTop;
                const containerBottom = containerTop + container.clientHeight;

                // The element is fully visible in the container
                return eleTop > containerTop && eleBottom < containerBottom;
            };

            if (
                !isVisible(
                    navItemsRefs[currentElementIndex].current,
                    navContainerRef.current
                )
            ) {
                navItemsRefs[currentElementIndex].current.scrollIntoView();
            }
        }
    }, [currentElementIndex]);

    return (
        <div className="stats-glossary__nav-container" ref={navContainerRef}>
            <ul className="stats-glossary__modal-nav">
                {navItems.map((char, index) => (
                    <NavItem
                        key={`glossary-nav-item-${char}`}
                        isActive={
                            glossaryKeys.indexOf(char) === currentElementIndex
                        }
                        isLink={Object.prototype.hasOwnProperty.call(
                            glossary,
                            char
                        )}
                        label={char}
                        onClick={onNavItemClick}
                        navItemRef={navItemsRefs[index]}
                    />
                ))}
            </ul>
        </div>
    );
};

Nav.propTypes = {
    currentElementIndex: PropTypes.number.isRequired,
    glossary: PropTypes.object.isRequired,
    navItems: PropTypes.array.isRequired,
    onNavItemClick: PropTypes.func.isRequired
};
