import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CurrentRoundContext } from '../../LeaderboardTable';

import { MobileVotes } from 'widgets/brownlow-predictor-v2/js/components/MobileTable/MobileVotes';
import { getTranslation } from 'common/react/utils/translations';

export const MobileTableVotesRow = ({
    cssClass,
    rowData,
    rounds,
    isBrownlowV2
}) => {
    const currentRoundNumber = useContext(CurrentRoundContext);

    return (
        <div className={`${cssClass}__body-row`}>
            {isBrownlowV2 ? (
                <MobileVotes data={rowData} rounds={rounds} />
            ) : (
                <>
                    <div className={`${cssClass}__row-header`}>
                        {getTranslation('label.brownlow.predictor.votes')}
                    </div>

                    {rowData.map((cell) => {
                        const cellProps = cell.getCellProps();
                        let value = cell.value.actual ?? 0;

                        const isUpcoming =
                            parseInt(cell.column.Header) > currentRoundNumber;

                        return (
                            /* Fake cell */
                            <div
                                {...cellProps}
                                key={cell.column.id}
                                className={classNames(
                                    !value
                                        ? `${cssClass}__body-cell ${cssClass}__body-cell--empty`
                                        : `${cssClass}__body-cell`,
                                    value === 'B'
                                        ? `${cssClass}__body-cell--bye`
                                        : '',
                                    value === 'NP'
                                        ? `${cssClass}__body-cell--not-played`
                                        : '',
                                    isUpcoming
                                        ? `${cssClass}__body-cell--upcoming`
                                        : ''
                                )}
                            >
                                {!isUpcoming && value !== 'NP' && value}
                            </div>
                        );
                    })}
                </>
            )}
        </div>
    );
};

MobileTableVotesRow.propTypes = {
    cssClass: PropTypes.string,
    rowData: PropTypes.array,
    rounds: PropTypes.array,
    isBrownlowV2: PropTypes.bool
};
