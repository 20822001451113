import React, { useRef, useEffect, useState } from 'react';
import { FocusableTooltipContext } from './focusable-tooltip-context';
import PropTypes from 'prop-types';

/**
 * Element providing focusable elements context to the nested FocusableTooltipWrapper.
 * The FocusableTooltipWrapper must be placed somewhere in the tree inside this component.
 *
 * The FocusableTooltipArea is basically querying DOM inside itself to find all elements
 * that are focusable (a, button or enything with tabindex attribute).
 * Then, provides the elements list in the context. FocusableTooltipWrapper components placed
 * down the tree are using the provided list to find the next focusable elements from the tooltip trigger
 *
 * @param {object} props
 * @param {object} props.children
 * @returns
 */
const FocusableTooltipArea = ({ children }) => {
    const focusableTooltipAreaRef = useRef();
    const [focusableElements, setFocusableElements] = useState([]);

    /**
     * when the widget renders and children are definded
     * query dom to get all focusable elements
     * set the list as the context value
     */
    useEffect(() => {
        if (focusableTooltipAreaRef.current && children) {
            const tabbableElements =
                focusableTooltipAreaRef.current.querySelectorAll(
                    'a:not(:disabled), button:not(:disabled), *[tabindex]:not(disabled)'
                );
            setFocusableElements([...tabbableElements]);
        }
    }, [focusableTooltipAreaRef, children]);

    return (
        <div ref={focusableTooltipAreaRef}>
            <FocusableTooltipContext.Provider value={focusableElements}>
                {children}
            </FocusableTooltipContext.Provider>
        </div>
    );
};

FocusableTooltipArea.propTypes = {
    children: PropTypes.object
};

export default FocusableTooltipArea;
