import React from 'react';
import cx from 'classnames';

/*
    We can use this as a default way to render the <td> and still allow a custom component inside it.
    Useful for reducing duplication.
*/

const DefaultCellRendererContainer = ({
    className,
    children,
    ...additionalProps
}) => {
    return <td className={cx(className, 'custom-table__cell')}>{children}</td>;
};

export default DefaultCellRendererContainer;
