import React from 'react';
import PropTypes from 'prop-types';

import { POSITION_ABBREVIATIONS } from '../../../config/positions';
import {
    useSeasonYear,
    useCompSeasonProvider,
    useCompAbbrProvider
} from '../hooks';
import { getPlayerHeadshotSize } from 'widgets/statspro/js/components/common/utils';
import { StatsTableRowHeader } from 'widgets/statspro/js/components/common/stats-table/StatsTableRowHeader';
import { PlayerProfileLink } from 'widgets/statspro/js/components/common/PlayerProfileLink';

export const StatsLeadersPlayerCell = ({ player, rank, ...cellProps }) => {
    const seasonYear = useSeasonYear();
    const compSeasonProvider = useCompSeasonProvider();
    const compAbbr = useCompAbbrProvider();
    const isAFLW = compAbbr === 'AFLW';

    const onCellRefChange = (ref) => {
        if (typeof compSeasonProvider !== 'undefined') {
            new PULSE.app.PlayerHeadshot(ref, compSeasonProvider);
        }
    };

    return (
        <StatsTableRowHeader
            cellProps={cellProps}
            onCellRefChange={onCellRefChange}
            className="u-text-left u-bold"
        >
            <div className="stats-leaders-table-player">
                <div
                    className="stats-leaders-table-player__headshot u-hide-until-desktop"
                    data-widget="player-headshot"
                >
                    <div
                        className="js-player-image"
                        data-player={player.playerId.replace('CD_I', '')}
                        data-picture-classes=""
                        data-wrapper-class=""
                        data-size={getPlayerHeadshotSize(seasonYear)}
                        data-picture-in-view="false"
                        data-year={seasonYear}
                    />
                </div>
                <span className="stats-leaders-table-player__rank u-hide-from-desktop">
                    {rank}
                </span>
                {player?.playerId ? (
                    <PlayerProfileLink
                        classes="stats-leaders-table-player__name"
                        player={{
                            playerId: player.playerId,
                            firstName: player.givenName,
                            surname: player.surname
                        }}
                        isAFLW={isAFLW}
                    >
                        {`${player.givenName} ${player.surname}`}
                    </PlayerProfileLink>
                ) : (
                    `${player.givenName} ${player.surname}`
                )}

                <span className="stats-leaders-table-player__position">
                    <span className="stats-leaders-table-position-badge u-hide-until-desktop">
                        {POSITION_ABBREVIATIONS[player.position]}
                    </span>
                </span>
            </div>
        </StatsTableRowHeader>
    );
};

StatsLeadersPlayerCell.propTypes = {
    player: PropTypes.object.isRequired,
    rank: PropTypes.number.isRequired
};
