// eslint-disable-next-line no-unused-vars
import { CareerAndSeasonStatsState } from '../types';
// eslint-disable-next-line no-unused-vars
import { ReduxState } from '../../../../types';

/**
 * @param {ReduxState} state - The Redux state.
 * @returns {CareerAndSeasonStatsState} The Stats Leaders state.
 */
export const careerAndSeasonStatsSelector = (state) =>
    state.statspro.careerAndSeasonStats;
