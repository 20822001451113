import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param props.children
 * @returns {JSX.Element} Component.
 */
export const StatsSectionContentBody = ({ children }) => (
    <>{children && <div className="stats-section-body">{children}</div>}</>
);

StatsSectionContentBody.defaultProps = {
    name: 'StatsSectionContentBody'
};

StatsSectionContentBody.propTypes = {
    children: PropTypes.any
};
