import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from './SvgIcon';

/**
 * @callback onChange
 * @param {boolean} isChecked - True if the checkbox is checked, otherwise
 *    false.
 */

/**
 * A checkbox UI component for a single checkbox.
 *
 * @param {object} props - The react component props
 * @param {string} props.id - ID.
 * @param {string} props.label - Label.
 * @param {boolean} props.isChecked - True if checkbox is checked, otherwise
 * false.
 * @param {boolean} props.isDisabled - True if checkbox is checked, otherwise
 * false.
 * @param {onChange} props.onChange - Callback function executed when the
 * checked state of the checkbox changes.
 * @returns {React.JSX.Element} Component.
 */
export const CheckboxInput = ({
    id,
    label,
    isChecked,
    isDisabled,
    onChange
}) => {
    return (
        <div
            className={classNames('checkbox-input', {
                'checkbox-input--checked': isChecked,
                'checkbox-input--disabled': isDisabled
            })}
        >
            <label
                className="checkbox-input__label"
                htmlFor={id}
                tabIndex={0}
                onKeyPress={(evt) => {
                    if ([' ', 'Enter'].includes(evt.key)) {
                        evt.preventDefault();
                        onChange(!isChecked);
                    }
                }}
            >
                {isChecked ? (
                    <SvgIcon
                        className="select-multiple__checkmark"
                        icon="checkmark-square"
                    />
                ) : (
                    <SvgIcon
                        className="select-multiple__checkmark-empty"
                        icon="checkmark-square-empty"
                    />
                )}
                <span dangerouslySetInnerHTML={{ __html: label }}></span>

                <input
                    className="checkbox-input__input"
                    type="checkbox"
                    id={id}
                    checked={isChecked}
                    onChange={(evt) => onChange(evt.target.checked)}
                    disabled={isDisabled}
                />
            </label>
        </div>
    );
};

CheckboxInput.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func
};
