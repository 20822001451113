import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const ModalTitle = ({ icon, title, closeBtn = true }) => {
    return (
        <section className="modal-title">
            {icon ? (
                <SvgIcon icon={icon} className="icon modal-title__icon" />
            ) : null}
            {title ? <h2 className="modal-title__text">{title}</h2> : null}
            {closeBtn ? (
                <button
                    className="modal-title__close-btn js-close-modal"
                    aria-label="Close"
                    onClick={() =>
                        PULSE.app.common.siteSettings.closeSettingsModal()
                    }
                >
                    <SvgIcon icon="close" className="icon" />
                </button>
            ) : null}
        </section>
    );
};

ModalTitle.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    closeBtn: PropTypes.bool
};
