/**
 * @param {object} item - Item
 * @param {object[]} columns - Column data
 * @param {number[]} number - qualifierRow
 * @param qualiferRow
 * @param conferenceLabel
 * @param premWinner
 * @param competitionId
 * @param compSeasonId
 * @param roundId
 * @param roundNumber
 * @param competitionType
 * @returns {object[]} qualifierRow - row data
 */
export const composeRowData = (
    item,
    columns,
    qualiferRow,
    conferenceLabel,
    premWinner,
    competitionId,
    compSeasonId,
    roundNumber,
    competitionType
) => {
    let rowData = {};
    columns.forEach((column) => {
        const accessor = column.accessor;
        rowData[accessor] = item[accessor];
    });

    rowData.clubName = item.club;
    rowData.qualiferRow = qualiferRow;
    rowData.isConference = conferenceLabel ? true : false;
    rowData.premWinner = premWinner || false;
    rowData.competitionId = competitionId;
    rowData.compSeasonId = compSeasonId;
    rowData.roundNumber = roundNumber;
    rowData.competitionType = competitionType;

    return rowData;
};
