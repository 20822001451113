// eslint-disable-next-line no-unused-vars
import { PlayerStatsTemporaryFieldsState } from '../../types';
import '../../types';

/**
 * @param {PlayerStatsTemporaryFieldsState} draft - Draft state.
 * @param {object} payload - Action payload.
 * @param payload.data
 */
export const setTemporaryFields = (draft, { data }) => {
    draft.temporaryFields = data ?? [];
};
