import React from 'react';
import PropTypes from 'prop-types';

export const RenderQuarterText = ({ period }) => {
    if (period.periodNumber === 2 && period.periodCompleted) {
        return PULSE.I18N.lookup('label.fixtures.halfTime');
    } else if (period.periodNumber === 4 && period.periodCompleted) {
        return PULSE.I18N.lookup('label.fixtures.fullTime');
    }
    return (
        <>
            <div className="fb-match__event__time-text-small">
                {period.periodCompleted
                    ? PULSE.I18N.lookup('label.fixtureBar.endOf')
                    : PULSE.I18N.lookup('label.fixtureBar.startOf')}
            </div>
            <div className="fb-match__event__time-text-quarter">
                <span>
                    {PULSE.I18N.lookup(
                        `label.${
                            [
                                'first',
                                'second',
                                'third',
                                'fourth',
                                'et1',
                                'et2'
                            ][period.periodNumber - 1]
                        }Quarter`
                    )}
                </span>
            </div>
        </>
    );
};

RenderQuarterText.propTypes = { period: PropTypes.object.isRequired };
