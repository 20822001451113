import React from 'react';
import { useSelector } from 'react-redux';

import { getTranslation } from 'common/react/utils/translations';
import { playerStatsNavSelector } from 'store/modules/match-centre/player-stats/selectors';

/**
 * @returns {JSX.Element} Component.
 */
export const Heading = () => {
    const nav = useSelector(playerStatsNavSelector);

    return (
        <>
            <strong>{nav.stats} -</strong>{' '}
            {nav.teams +
                (nav.teams === 'Both'
                    ? ' ' + getTranslation('label.teams')
                    : '')}
        </>
    );
};
