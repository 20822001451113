import produce from 'immer';

// eslint-disable-next-line no-unused-vars
import { StatComparisonState } from '../types';
// eslint-disable-next-line no-unused-vars
import { Action } from '../../../../types';
import {
    SET_STAT_COMPARISON,
    SET_NAV_ITEM,
    SET_STAT_COMPARISON_REQUEST,
    REMOVE_STAT_COMPARISON_REQUEST,
    SET_DATA_TYPE
} from '../actions';
import {
    setNavItem,
    setDataType,
    setRequest,
    removeRequest
} from '../../shared/reducer';
import {} from './';
import { DATA_TYPE } from 'widgets/statspro/js/components/common/config/data-type';
import { setStatComparison } from './set-stat-comparison';

/**
 * @type {StatComparisonState}
 */
const initialState = {
    nav: {
        category: 'Key Stats',
        competition: {
            id: null
        },
        season: {
            id: -1
        }
    },
    dataType: {
        type: DATA_TYPE.BENCHMARKED_AVERAGES
    },
    requests: [],
    data: []
};

/**
 * @param {StatComparisonState} state - The Redux state.
 * @param {Action} action - The Redux action.
 * @returns {StatComparisonState} The updated Redux state.
 */
export const statComparisonReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_STAT_COMPARISON:
                setStatComparison(draft, action.payload);
                break;
            case SET_STAT_COMPARISON_REQUEST:
                setRequest(draft, action.payload);
                break;
            case REMOVE_STAT_COMPARISON_REQUEST:
                removeRequest(draft, action.payload);
                break;
            case SET_NAV_ITEM:
                setNavItem(draft, action.payload);
                break;
            case SET_DATA_TYPE:
                setDataType(draft, action.payload);
                break;
            default:
                draft;
        }
    });
};
