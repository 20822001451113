import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BenchmarkBar } from '../../common/BenchmarkBar';
import { BenchmarkLabel } from 'common/react/components/benchmarking';
import { BENCHMARKING } from 'common/react/components/benchmarking/config/benchmarking';
import { composeGetTranslation } from 'common/react/utils/translations';
import { useSelector } from 'react-redux';
import { statComparisonDataTypeSelector } from 'store/modules/statspro/stat-comparison/selectors';

/**
 * @param {object} props - Props.
 * @param {string} props.statName - The stat name.
 * @param {Array} props.data - Data for the player stats.
 * @param {boolean} props.showBenchmarking - should we show benchmarking
 * @returns {JSX.Element} Component.
 */
export const StatComparisonRow = ({ statName, data, showBenchmarking }) => {
    const dataType = useSelector(statComparisonDataTypeSelector);
    const getTranslation = composeGetTranslation('label.stats.');

    useEffect(() => {
        document
            .querySelectorAll('.js-stat-comparison-container')
            .forEach((item) => {
                item.classList.add('is-loaded');
            });
    }, [data]);

    if (!data[0]) {
        return <></>;
    }

    return (
        <div className="stat-comparison__stat-container js-stat-comparison-container fade-in-on-load">
            <div className="stat-comparison__stat-title">{statName}</div>
            {data.map((player, i) => (
                <div key={i} className="stat-comparison__stat-values">
                    {player.stat.value ? (
                        <>
                            <BenchmarkBar
                                stat={player.stat.value}
                                benchmark={player.stat.benchmark}
                                compareValues={[
                                    data[0].stat.value,
                                    data[1].stat.value
                                ]}
                                teamAbbr={
                                    dataType.type === 'totals' ||
                                    !showBenchmarking
                                        ? player.teamAbbr
                                        : ''
                                }
                                index={i}
                                showBenchmarking={showBenchmarking}
                            />
                            <div className="stat-comparison__stat">
                                {player.stat.value}
                                {player.stat.benchmark && showBenchmarking ? (
                                    <BenchmarkLabel
                                        benchmarkKey={
                                            BENCHMARKING[player.stat.benchmark]
                                                .KEY
                                        }
                                    />
                                ) : (
                                    dataType.type !== 'totals' &&
                                    showBenchmarking && (
                                        <span className="benchmarking-label benchmarking-label--na">
                                            {getTranslation(
                                                'notAvailable.abbr'
                                            )}
                                        </span>
                                    )
                                )}
                            </div>
                        </>
                    ) : (
                        <div className="stat-comparison__stat stat-comparison__stat--na">
                            -
                            {dataType.type !== 'totals' && showBenchmarking && (
                                <span className="benchmarking-label benchmarking-label--na">
                                    {getTranslation('notAvailable.abbr')}
                                </span>
                            )}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

StatComparisonRow.propTypes = {
    statName: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    showBenchmarking: PropTypes.bool
};
