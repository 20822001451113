import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { CareerAndSeasonStats } from '../components/career-and-season-stats';

export const CareerAndSeasonStatsProvider = ({ store, data }) => (
    <Provider store={store}>
        <CareerAndSeasonStats {...data} />
    </Provider>
);

CareerAndSeasonStatsProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
