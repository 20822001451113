import React from 'react';

import { getTranslation } from 'common/react/utils/translations';

export const Legend = () => (
    <div className="draft-tracker-leaderboard__legend-container stats-section-wrapper wrapper pb-8 mb-0">
        <div className="draft-tracker-leaderboard__legend">
            <span className="draft-tracker-leaderboard__legend-badge draft-tracker-leaderboard__academy">
                {getTranslation('label.draftTracker.academy.abbreviation')}
            </span>
            {getTranslation('label.draftTracker.academy')}
        </div>
        <div className="draft-tracker-leaderboard__legend">
            <span className="draft-tracker-leaderboard__legend-badge draft-tracker-leaderboard__father-son">
                {getTranslation('label.draftTracker.fatherSon.abbreviation')}
            </span>
            {getTranslation('label.draftTracker.fatherSon')}
        </div>
    </div>
);
