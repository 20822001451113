import React from 'react';
import PropTypes from 'prop-types';

import { useGetMatchQuery } from 'store/modules/api/afl-api';
import { EmptyState } from 'common/react/components/EmptyState';
import { FixturesItem } from './FixturesItem';
import { FixturesSkeleton } from './FixturesSkeleton';
import { FixturesLegend } from './FixturesLegend';
import { getTranslation } from 'common/react/utils/translations';
import { useGroupMatches } from 'common/react/hooks/use-group-matches';
import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';
import { useFilterMatches } from 'common/react/hooks/use-filter-matches';

const checkIfBroadcasterGuide = (providerId) => {
    const translationString = getTranslation(
        `label.broadcastGuideURL.${providerId}`
    );

    if (translationString !== `label.broadcastGuideURL.${providerId}`) {
        return translationString;
    }

    return false;
};

export const FixturesListCustom = ({
    competitionPid,
    compSeason,
    customMatchId,
    roundNumber,
    broadcasterPromo,
    specialRound
}) => {
    const selectedRegion = useSingleSiteSetting('region');

    const broadcastGuideUrl =
        checkIfBroadcasterGuide(compSeason.providerId) ?? '';

    const { data: customMatch = [], isFetching: isMatchesFetching } =
        useGetMatchQuery({ id: customMatchId });

    const matchItems = useGroupMatches(
        useFilterMatches(customMatch),
        selectedRegion.timezone
    );

    const isConferenceSeason =
        customMatch?.home?.conference || customMatch?.away?.conference;

    if (isMatchesFetching) {
        return <FixturesSkeleton />;
    }

    if (!customMatch || !customMatch.length) {
        return <EmptyState cssClass="competition-nav__empty-state" />;
    }

    return (
        <>
            {matchItems?.length
                ? matchItems.map((item, index) => (
                      <FixturesItem
                          key={
                              typeof item === 'string'
                                  ? `${item}-${index}`
                                  : item.id
                          }
                          item={item}
                          broadcastGuideUrl={broadcastGuideUrl}
                          broadcasterPromo={broadcasterPromo}
                          specialRound={specialRound}
                          competitionPid={competitionPid}
                          compseasonId={compSeason.id}
                          roundNumber={roundNumber}
                      />
                  ))
                : null}

            {isConferenceSeason && <FixturesLegend />}
        </>
    );
};

FixturesListCustom.propTypes = {
    competitionPid: PropTypes.string.isRequired,
    compSeason: PropTypes.object,
    customMatchId: PropTypes.string,
    roundNumber: PropTypes.number,
    broadcasterPromo: PropTypes.object,
    scrollToRound: PropTypes.number,
    setScrollToRound: PropTypes.func,
    specialRound: PropTypes.string
};
