import { call, delay, put } from 'redux-saga/effects';
import { fetchWithMisToken } from '../../../../utils/fetch-with-mis-token';
import {
    setMatchByMatchData,
    FETCH_MATCH_BY_MATCH_DATA,
    setMatchByMatchDataRequest,
    removeMatchByMatchDataRequest,
    POLL_MATCH_BY_MATCH_DATA
} from '../actions';

/**
 * @typedef {object} FetchMatchByMatchDataAction
 * @property {object} payload
 * @property {string} payload.type
 * @property {string} payload.playerId
 * @property {string} [payload.seasonPid]
 * @property {boolean} [payload.benchmarking]
 */

/**
 * @param {FetchMatchByMatchDataAction} action - The Redux action.
 */
export function* fetchMatchByMatchData(action) {
    const { seasonPid } = action.payload;

    yield put(setMatchByMatchDataRequest(action.payload));

    const data = yield call(
        fetchWithMisToken,
        `${PULSE.app.environment.cfsApi}/brownlow/season/${seasonPid}`
    );

    if (data) {
        yield put(setMatchByMatchData({ seasonPid, data }));
        // trigger poll
        if (data.status !== 'CONCLUDED') {
            yield put(
                POLL_MATCH_BY_MATCH_DATA.request({ seasonPid: seasonPid })
            );
        }
    } else {
        // Error handling for when there is no data
        yield put(
            setMatchByMatchData({
                seasonPid,
                error: 'no data available'
            })
        );
    }

    yield put(removeMatchByMatchDataRequest(action.payload));

    yield put(FETCH_MATCH_BY_MATCH_DATA.success({ seasonPid, data }));
}

/**
 * @param action
 */
export function* pollMatchByMatchData(action) {
    const { seasonPid } = action.payload;

    yield delay(PULSE.app.common.CONSTANTS.MATCH_POLL_DELAY);
    // fire API call
    yield put(FETCH_MATCH_BY_MATCH_DATA.request({ seasonPid: seasonPid }));
}
