import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

export const PlayerPhoto = ({ className, photoUrl, alt, scale = '0.2' }) => {
    const [imageSrc, setImageSrc] = useState(
        changeImageUrlScale(photoUrl, scale)
    );
    const [imageClass, setImageClass] = useState(className);
    const [errored, setErrored] = useState(false);

    useEffect(() => {
        if (!errored) {
            const newImageUrl = changeImageUrlScale(photoUrl, scale);
            if (newImageUrl !== imageSrc) {
                setImageSrc(changeImageUrlScale(photoUrl, scale));
            }
        }
    }, [imageSrc, photoUrl, scale, errored]);

    const onError = useCallback(() => {
        if (!errored) {
            setErrored(true);
            setImageSrc(
                `${PULSE.app.environment.cdn}i/elements/player-headshot-placeholder-light-v2.svg`
            );
            setImageClass(`${className} is-placeholder`);
        }
    }, [className]);

    return (
        <img
            className={imageClass}
            src={imageSrc}
            alt={alt}
            width="1"
            height="1"
            onError={onError}
            loading="lazy"
        />
    );
};

const changeImageUrlScale = (imageUrl, scale) =>
    PULSE.app.common.image.migratePlayerPhotoURL(imageUrl, scale);

PlayerPhoto.propTypes = {
    className: PropTypes.string,
    photoUrl: PropTypes.string,
    alt: PropTypes.string.isRequired,
    scale: PropTypes.string
};
