export const STAT_CATEGORIES = {
    defenders: [
        {
            type: 'average',
            key: 'disposals'
        },
        {
            type: 'average',
            key: 'intercepts'
        },
        {
            type: 'average',
            key: 'interceptMarks'
        },
        {
            type: 'average',
            key: 'metresGained'
        },
        {
            type: 'average',
            key: 'contestDefOneOnOnes'
        }
    ],
    midfielders: [
        {
            type: 'average',
            key: 'disposals'
        },
        {
            type: 'average',
            key: 'contestedPossessions'
        },
        {
            type: 'average',
            key: 'totalClearances'
        },
        {
            type: 'average',
            key: 'metresGained'
        },
        {
            type: 'average',
            key: 'scoreInvolvements'
        }
    ],
    forwards: [
        {
            type: 'average',
            key: 'disposals'
        },
        {
            type: 'average',
            key: 'marksInside50'
        },
        {
            type: 'average',
            key: 'contestedMarks'
        },
        {
            type: 'average',
            key: 'goals'
        },
        {
            type: 'average',
            key: 'goalAccuracy'
        }
    ]
};
