export const sortByDate = (data) => {
    if (!data) {
        return [];
    }
    const events = data.map((event) => {
        let detailsModule =
            event.modulesInContent?.find(
                (item) =>
                    item.title.toLowerCase().replace(' ', '') === 'details'
            ) ?? null;

        let date = detailsModule
            ? moment(
                  PULSE.app.common.events.getModuleItem(
                      detailsModule,
                      'Date & Time'
                  )
              )
            : null;

        return { ...event, date: date };
    });

    return events.sort((a, b) => {
        return moment(a.date).diff(b.date);
    });
};
