import produce from 'immer';

// eslint-disable-next-line no-unused-vars
import { ViewByMatchState } from '../types';
// eslint-disable-next-line no-unused-vars
import { Action } from '../../../../types';
import { SET_ITEM } from '../actions';
import { setItem } from './set-item';

/**
 * @type {ViewByMatchState}
 */
const initialState = {
    activeIndex: -1,
    matchId: ''
};

/**
 * @param {ViewByMatchState} state - The Redux state.
 * @param {Action} action - The Redux action.
 * @returns {ViewByMatchState} The updated Redux state.
 */
export const viewByMatchReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_ITEM:
                setItem(draft, action.payload);
                break;
            default:
                draft;
        }
    });
};
