import { getTranslation } from 'common/react/utils/translations';

export const composeColumnData = (windowWidth, isLive) => {
    const isWebView =
        PULSE.core.url.getParam('webview') === 'true' ||
        window.IS_ARTICLE_API === true;

    if (!isLive) {
        return [
            {
                Header: getTranslation('label.player'),
                accessor: 'player',
                disableSortBy: true
            },
            {
                Header: getTranslation('label.position'),
                accessor: 'position',
                disableSortBy: true
            },
            {
                Header: getTranslation('label.stat.height'),
                accessor: 'height',
                disableSortBy: true
            },
            {
                Header: getTranslation('label.stat.dateOfBirth'),
                accessor: 'dob',
                disableSortBy: true
            },
            {
                Header: getTranslation('label.from'),
                accessor: 'from',
                disableSortBy: true
            }
        ];
    }

    // display less columns in webview and mobile
    if (
        isWebView ||
        (windowWidth ?? innerWidth) < PULSE.app.measurements.desktop
    ) {
        return [
            {
                Header: getTranslation('label.draftTracker.pick'),
                accessor: 'pick',
                sortDescFirst: true,
                disableSortBy: true
            },
            {
                Header: getTranslation('label.club'),
                accessor: 'club',
                disableSortBy: true
            },
            {
                Header: getTranslation('label.player'),
                accessor: 'player',
                disableSortBy: true
            },
            {
                Header: getTranslation('label.position'),
                accessor: 'position',
                disableSortBy: true
            }
        ];
    }

    return [
        {
            Header: getTranslation('label.draftTracker.pick'),
            accessor: 'pick',
            sortDescFirst: true,
            disableSortBy: true
        },
        {
            Header: getTranslation('label.club'),
            accessor: 'club',
            disableSortBy: true
        },
        {
            Header: getTranslation('label.player'),
            accessor: 'player',
            disableSortBy: true
        },
        {
            Header: getTranslation('label.position'),
            accessor: 'position',
            disableSortBy: true
        },
        {
            Header: getTranslation('label.stat.height'),
            accessor: 'height',
            disableSortBy: true
        },
        {
            Header: getTranslation('label.stat.dateOfBirth'),
            accessor: 'dob',
            disableSortBy: true
        },
        {
            Header: getTranslation('label.from'),
            accessor: 'from',
            disableSortBy: true
        }
    ];
};
