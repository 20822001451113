const { DateTime } = luxon;

/**
 * Checks a rounds utcEndTime to see if it's finished
 *
 * @param {object} round - A round object from a rounds api response
 * @returns {boolean} - True if the round is over (past it's end time)
 */
export function hasRoundFinished(round) {
    if (round && DateTime.fromISO(round.utcEndTime) < DateTime.utc()) {
        return true;
    }

    return false;
}
