import React from 'react';
import PropTypes from 'prop-types';

import { ImageElement } from './ImageElement';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const BroadcasterPromo = ({ promo }) => {
    const bgColour = promo.metadata.background_colour ?? '';
    const accentColour = promo.metadata.accent_colour ?? '';

    const link = promo.links ? promo.links[0] : false;

    return link ? (
        <a
            className="fixtures__broadcaster-promo"
            style={{ backgroundColor: bgColour }}
            href={link.promoUrl}
            aria-label={link.linkText}
            target={link.external ? '_blank' : ''}
            rel="noreferrer"
        >
            <div className={`fixtures__broadcaster-promo-image-container`}>
                <ImageElement
                    ImageUrl={promo.promoItem.onDemandUrl}
                    ImageTitle={promo.title}
                />
            </div>
            <p className="fixtures__broadcaster-promo-title">{promo.title}</p>
            <SvgIcon
                icon="link-arrow"
                className="icon fixtures__broadcaster-promo-icon"
                style={{
                    fill: accentColour
                }}
            />
        </a>
    ) : (
        <div
            className="fixtures__broadcaster-promo"
            style={{ backgroundColor: bgColour }}
        >
            <div className={`fixtures__broadcaster-promo-image-container`}>
                <ImageElement
                    ImageUrl={promo.promoItem.onDemandUrl}
                    ImageTitle={promo.title}
                />
            </div>
            <p className="fixtures__broadcaster-promo-title">{promo.title}</p>
        </div>
    );
};

BroadcasterPromo.propTypes = {
    promo: PropTypes.object
};
