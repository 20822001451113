import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { Glossary } from '../components/Glossary';

export const GlossaryProvider = ({ store }) => {
    return (
        <Provider store={store}>
            <Glossary />
        </Provider>
    );
};

GlossaryProvider.propTypes = {
    store: PropTypes.object.isRequired
};
