import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';

export const InformationCategoryHeader = ({
    selectedCategory,
    selectedLocation
}) => {
    return (
        <section
            className={`interactive-event-map__information-category-container interactive-event-map__section js-interactive-event-map__information-category-container ${
                selectedCategory !== null && selectedLocation === null
                    ? ''
                    : 'u-hide'
            }`}
        >
            <h2 className="interactive-event-map__information-category-title">
                {selectedCategory}
            </h2>
            <button
                value="Reset filter"
                className="interactive-event-map__filter-reset interactive-event-map__information-category-filter-reset js-interactive-event-map__information-category-filter-reset"
            >
                <SvgIcon icon="close" className="icon" />
            </button>
        </section>
    );
};

InformationCategoryHeader.propTypes = {
    selectedCategory: PropTypes.string,
    selectedLocation: PropTypes.object
};
