/**
 * Sort Favourites
 *
 * Sorts the leaderboard data, by adding the favourites to the front
 * of the data array
 *
 * @param {*} array - Input list of players
 * @param {*} favouritesArray - Array of a users favourite players
 * @param {*} teamFilterId - The providerId of any active team filter
 * @returns {Array} An array of players, sorted by the leader then favourites
 */
export const sortFavourites = (array, favouritesArray, teamFilterId) => {
    /**
     * If there is a winner and no team filter or if there is a winner and team
     * filter and the winner is a member of that team then remove them so they
     * can be placed at the front of sorted array
     */
    let winner = null;
    if (
        array?.[0].player.winner === true &&
        ((teamFilterId && array?.[0].team.teamId === teamFilterId) ||
            teamFilterId === '')
    ) {
        winner = array.shift();
    }

    // Split favourites and remaining players into their own array
    const favouritePlayers = [];
    const remainingPlayers = array.filter((player) => {
        if (
            favouritesArray.some(
                (favourite) =>
                    favourite.providerId === player.player.playerId ||
                    favourite.providerId === player.player.providerId
            )
        ) {
            favouritePlayers.push(player);
            return false;
        }

        return true;
    });

    /**
     * Construct the full list with winner first (if there is one), then
     * favourites and then the rest
     */
    if (winner) {
        return [winner, ...favouritePlayers, ...remainingPlayers];
    }

    return [...favouritePlayers, ...remainingPlayers];
};
