import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Loader } from 'common/react/components/Loader';
import {
    statsTypeSelector,
    roundSelector,
    seasonSelector,
    statsLeadersBenchmarkingSelector,
    statsLeadersDataTypeSelector
} from 'store/modules/statspro/stats-leaders/selectors';
import { StatsLeadersTableFetcher } from './StatsLeadersTableFetcher';

export const StatsLeadersTable = ({ isAFLW, isAFLM }) => {
    const benchmarking = useSelector(statsLeadersBenchmarkingSelector);
    const dataType = useSelector(statsLeadersDataTypeSelector);
    const season = useSelector(seasonSelector);
    const round = useSelector(roundSelector);
    const statsType = useSelector(statsTypeSelector);

    const requestId = (statsType === 'seasons' ? season : round)?.providerId;

    if (!requestId || !statsType || !dataType) {
        return (
            <div style={{ padding: '3.2rem 1.6rem' }}>
                <Loader />
            </div>
        );
    }

    return (
        <StatsLeadersTableFetcher
            id={requestId}
            type={statsType}
            benchmarking={benchmarking.isActive}
            dataType={dataType}
            isAFLW={isAFLW}
            isAFLM={isAFLM}
        />
    );
};

StatsLeadersTable.propTypes = {
    isAFLW: PropTypes.bool,
    isAFLM: PropTypes.bool
};
