import React from 'react';
import PropTypes from 'prop-types';

import { ModalTitle } from './ModalTitle';
import { ModalAflIdPromo } from './ModalAflIdPromo';
import { ModalToggle } from './ModalToggle';
import { ModalLink } from './ModalLink';

import { getTranslation } from 'common/react/utils/translations';

export const LoggedOutModal = ({
    setSiteSettings,
    settingsConfig,
    selectedRegion,
    displayWagering,
    regionsPage
}) => {
    const pageHasAflIdLoginButton = document.querySelector('.js-aflid-user');

    return (
        <>
            <ModalTitle
                icon="settings"
                title={getTranslation('label.settings')}
            />
            {pageHasAflIdLoginButton ? (
                <ModalAflIdPromo
                    text={getTranslation('settingsModal.aflId.text')}
                    buttons={[
                        {
                            label: getTranslation('label.aflid.create'),
                            onClick: () =>
                                PULSE.app.authClient.signInWithRedirect()
                        },
                        {
                            label: getTranslation('label.aflid.login'),
                            onClick: () =>
                                PULSE.app.authClient.signInWithRedirect()
                        }
                    ]}
                />
            ) : null}
            <ModalToggle
                icon="sportsbet"
                label={getTranslation('label.hideBettingOdds')}
                isChecked={displayWagering}
                onChange={(checked) =>
                    setSiteSettings({
                        [settingsConfig.displayWagering.key]: !checked
                    })
                }
                notes={getTranslation('settingsModal.bettingOdds.note')}
            />
            <ModalLink
                icon="globe"
                label={getTranslation('label.filters.region')}
                onClick={(event) => {
                    /**
                     * Close the modal before navigating, so it isn't open on
                     * the select region page
                     */
                    event.preventDefault();
                    PULSE.app.common.siteSettings.closeSettingsModal();
                    window.location.href = event.currentTarget.href;
                }}
                link={`${window.location.origin}${regionsPage}`}
                btnLabel={selectedRegion.name}
            />
        </>
    );
};

LoggedOutModal.propTypes = {
    setSiteSettings: PropTypes.func.isRequired,
    settingsConfig: PropTypes.object.isRequired,
    selectedRegion: PropTypes.object.isRequired,
    displayWagering: PropTypes.bool.isRequired,
    regionsPage: PropTypes.string.isRequired
};
