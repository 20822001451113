import { all, takeEvery } from 'redux-saga/effects';
import {
    FETCH_MATCH_BY_MATCH_DATA,
    POLL_MATCH_BY_MATCH_DATA
} from '../actions';
import {
    fetchMatchByMatchData,
    pollMatchByMatchData
} from './fetch-match-by-match-data';

/**
 *
 */
export function* matchByMatchRootSaga() {
    yield all([
        takeEvery(FETCH_MATCH_BY_MATCH_DATA.REQUEST, fetchMatchByMatchData),
        takeEvery(POLL_MATCH_BY_MATCH_DATA.REQUEST, pollMatchByMatchData)
    ]);
}
