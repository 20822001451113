import React from 'react';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

/**
 * @returns {JSX.Element} Component.
 */
export const KeyStatsAndPerformanceButton = () => {
    const toggleTopPerformances = (event) => {
        const target = event.target;
        const container = target.closest('.js-top-performance-wrapper');
        const isActive = container.classList.contains('active');

        container.classList.toggle('active');
        container.querySelector(
            '.js-top-performance-more-button-text'
        ).innerText = isActive
            ? getTranslation('label.stats.more')
            : getTranslation('label.stats.less');
    };

    return (
        <div className="key-stats-and-performance__top-performance-more-button-wrapper">
            <button
                className="key-stats-and-performance__top-performance-more-button u-hide-from-desktop"
                onClick={(event) => toggleTopPerformances(event)}
            >
                <span className="js-top-performance-more-button-text">
                    {getTranslation('label.stats.more')}
                </span>
                <SvgIcon icon="chevron" />
            </button>
        </div>
    );
};
