import { put } from 'redux-saga/effects';
import { SETUP_GLOSSARY, setGlossaryTerms } from '../actions';

const entriesPrefix = 'label.statspro.glossary.entries.';
const descriptionPrefix = 'label.statspro.glossary.description.';

/**
 *
 */
export function* setupGlossaryTerms() {
    const { translations } = PULSE.I18N;

    const glossaryTerms = Object.keys(translations).reduce(
        (terms, currentTranslation) => {
            if (currentTranslation.startsWith(entriesPrefix)) {
                const key = currentTranslation.split(entriesPrefix)[1];
                const entry = translations[currentTranslation];
                const description = translations[`${descriptionPrefix}${key}`];
                const group = entry.charAt(0).toUpperCase();

                if (Object.prototype.hasOwnProperty.call(terms, group)) {
                    const { [group]: groupForTerm, ...otherTerms } = terms;
                    const newTerm = {
                        entry,
                        description
                    };
                    const sortedIndex = _.sortedIndex(
                        groupForTerm,
                        newTerm,
                        'entry'
                    );
                    return {
                        [group]: [
                            ...groupForTerm.slice(0, sortedIndex),
                            newTerm,
                            ...groupForTerm.slice(sortedIndex)
                        ],
                        ...otherTerms
                    };
                }

                return {
                    [group]: [
                        {
                            entry,
                            description
                        }
                    ],
                    ...terms
                };
            }

            return terms;
        },
        {}
    );

    yield put(setGlossaryTerms(glossaryTerms));
    yield put(SETUP_GLOSSARY.success());
}
