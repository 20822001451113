import { call } from 'redux-saga/effects';
import '../types';

const { app } = window.PULSE;

/**
 * @param {string} url - URL to fetch.
 * @returns {object} Json response.
 */
export function* fetchWithAccount(url) {
    return yield call(runFetch, url);
}

const runFetch = (url) => {
    return fetch(url, {
        headers: { [app.accountHeader.label]: app.accountHeader.value }
    })
        .then((res) => res.json())
        .then((data) => data)
        .catch((error) => ({ error }));
};
