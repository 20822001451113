import React from 'react';
import { Provider } from 'react-redux';
import DefaultTable from 'custom-table/js/components/DefaultTable/container';
import { DndProvider } from 'react-dnd';
import TouchBackend from 'react-dnd-touch-backend';

/**
 * A Provider is used to wrap the "entry point" component for a React widget with a Redux Provider,
 * which is used to provide access to the Redux store.
 *
 * The "data" prop will contain any "data-" attributes added to the DOM element that the React widget will be rendered into.
 *
 * The Provider can also contain any "react-router" logic that may be required by a component.
 *
 * This can be a reusable component that contains any elements that will always need to be used,
 * that we don't require any consuming implementations to know about. Reduces duplication of code.
 * e.g. DndProvider will always be required, but we don't want to re-implement it in every Table Provider.
 */

const backendOptions = {
    enableMouseEvents: true
};

const CustomTableProvider = ({ store, data, children }) => {
    return (
        <Provider store={store}>
            <DndProvider
                store={store}
                backend={TouchBackend}
                options={backendOptions}
            >
                {children}
            </DndProvider>
        </Provider>
    );
};

export default CustomTableProvider;
