import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { playersSelector } from 'store/modules/statspro/player-comparison/selectors';
import { OverviewTable } from './components/OverviewTable';

/**
 * @param root0
 * @param root0.data
 * @returns {JSX.Element} Component.
 */
export const OverviewComparison = ({ data }) => {
    const playerIds = useSelector(playersSelector);

    const { WOMENS } = PULSE.app.common.CONSTANTS.PROVIDER_IDS;
    const isAFLW = data?.competitionPid === WOMENS;

    return (
        <div
            className={classNames(
                'fade-in-on-load fade-in-on-load--stats widget--stats',
                {
                    'is-loaded': playerIds[1] || playerIds[2]
                }
            )}
        >
            {playerIds[1] || playerIds[2] ? (
                <div className="fade-in-on-load is-loaded">
                    <StatsSection.Main>
                        <StatsSection.Content>
                            <StatsSection.ContentHeader />
                            <StatsSection.ContentBody>
                                <OverviewTable
                                    seasonId={data.seasonId}
                                    playerIds={playerIds}
                                    isAFLW={isAFLW}
                                />
                            </StatsSection.ContentBody>
                        </StatsSection.Content>
                    </StatsSection.Main>
                </div>
            ) : null}
        </div>
    );
};

OverviewComparison.propTypes = {
    data: PropTypes.object.isRequired
};
