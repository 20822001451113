import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    CHANGE_PLAYER_SELECTION_MODAL_VISIBILITY,
    ADD_PLAYER,
    REMOVE_PLAYER,
    UPDATE_FILTER_ITEM
} from '../actions';
import { changePlayerSelectionModalVisibility } from './change-modal-visibility';
import { updateFilterItem } from './update-filter-item';
import { addPlayer, removePlayer } from './add-remove-player';

/**
 *
 */
export function* playerComparisonRootSaga() {
    yield all([
        takeEvery(
            CHANGE_PLAYER_SELECTION_MODAL_VISIBILITY.REQUEST,
            changePlayerSelectionModalVisibility
        ),
        takeLatest(ADD_PLAYER.REQUEST, addPlayer),
        takeEvery(REMOVE_PLAYER.REQUEST, removePlayer),
        takeEvery(UPDATE_FILTER_ITEM.REQUEST, updateFilterItem)
    ]);
}
