import React from 'react';
import PropTypes from 'prop-types';

import { FixtureLinkButton } from './FixtureLinkButton';
import { getTranslation } from 'common/react/utils/translations';

export const UpcomingFixtureLinks = ({
    metadata,
    hasFreeEntry,
    isSoldOut,
    broadcastGuideUrl,
    shouldShowBroadcaster,
    streamUrl,
    gatherRound
}) => {
    return (
        <>
            {metadata.ticket_link && !hasFreeEntry && !isSoldOut && (
                <FixtureLinkButton
                    icon="tickets-new"
                    label={getTranslation('label.fixtures.buy-tickets')}
                    href={metadata.ticket_link}
                    target="_blank"
                />
            )}

            {/* only show a broadcast guide link if the match is assigned to a broadcaster match-event */}
            {broadcastGuideUrl && shouldShowBroadcaster && !streamUrl && (
                <FixtureLinkButton
                    icon={gatherRound ? 'broadcast' : 'live-broadcast'}
                    label={
                        gatherRound
                            ? getTranslation('label.fixtures.whereToWatch')
                            : getTranslation('label.fixtures.broadcastGuide')
                    }
                    href={broadcastGuideUrl}
                    target="_blank"
                />
            )}

            {metadata.travel_link && (
                <FixtureLinkButton
                    icon="plane"
                    label={getTranslation('label.fixtures.travel')}
                    href={metadata.travel_link}
                    target="_blank"
                />
            )}
        </>
    );
};

UpcomingFixtureLinks.propTypes = {
    metadata: PropTypes.object,
    hasFreeEntry: PropTypes.bool,
    isSoldOut: PropTypes.bool,
    broadcastGuideUrl: PropTypes.string,
    shouldShowBroadcaster: PropTypes.bool,
    streamUrl: PropTypes.string,
    gatherRound: PropTypes.bool
};
