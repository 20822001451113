export const getLadderPosition = (data, teamProviderId) => {
    let position;

    data.forEach((item) => {
        if (item.team.providerId === teamProviderId) {
            position = item.thisSeasonRecord?.ladderPosition;
        }
    });

    return position;
};
