/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param {any} props.children - Child component(s).
 * @returns {JSX.Element} Component.
 */
export const StatsTableFoot = ({ children }) => (
    <tfoot>
        <tr role="row">{children.map((col) => col)}</tr>
    </tfoot>
);

StatsTableFoot.propTypes = {
    children: PropTypes.any.isRequired
};
