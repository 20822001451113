// eslint-disable-next-line no-unused-vars
import { LeaderboardState } from '../types';
import '../../types';

/**
 * @param {LeaderboardState} draft - Draft state.
 * @param {object} payload - Action payload.
 * @param payload.query
 */
export const setLeaderboardFilter = (draft, payload) => {
    draft.filter.query = payload.query;
};
