import React from 'react';
import { getTranslation } from 'common/react/utils/translations';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const FixturesLegend = () => {
    return (
        <dl className="fixtures-legend">
            {/* Conference A */}
            <dl className="fixtures-legend__item fixtures-legend__item--key">
                <SvgIcon icon={'conference-a'} className={'icon'} />
            </dl>

            <dd className="fixtures-legend__item">
                {getTranslation(`label.fixtures.conferenceA`)}
            </dd>

            {/* Conference B */}
            <dl className="fixtures-legend__item fixtures-legend__item--key">
                <SvgIcon icon={'conference-b'} className={'icon'} />
            </dl>

            <dd className="fixtures-legend__item">
                {getTranslation(`label.fixtures.conferenceB`)}
            </dd>
        </dl>
    );
};
