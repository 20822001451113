import React from 'react';

export const PlaceholderImage = () => {
    return (
        <div className="compare-header__placeholder-circle">
            <div className="compare-header__placeholder-inner">
                <div className="compare-header__placeholder-holder">
                    <img
                        src={`${PULSE.app.environment.cdn}i/elements/player-headshot-placeholder-light-v2.svg`}
                        className="compare-header__placeholder-image"
                        draggable="false"
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
};
