import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { FavouriteButton } from 'widgets/brownlow-tracker/js/common/FavouriteButton';
import { StatsTableRowHeader } from 'widgets/statspro/js/components/common/stats-table/StatsTableRowHeader';
import { getTranslation } from 'common/react/utils/translations';
import {
    leaderboardDataSelector,
    leaderboardNavSelector
} from 'store/modules/brownlow-tracker/leaderboard/selectors';
import { UPDATE_PLAYER_MODAL } from 'store/modules/brownlow-tracker/player-modal/actions';
import { PlayerPhoto } from 'common/react/components/PlayerPhoto';

export const PlayerCell = ({
    player,
    onPlayerFavouriteClick,
    favourites,
    seasonPid,
    ...cellProps
}) => {
    const dispatch = useDispatch();
    const leaderboardData = useSelector((state) =>
        leaderboardDataSelector(state, seasonPid)
    );
    const nav = useSelector(leaderboardNavSelector);

    const status = leaderboardData?.status
        ? PULSE.app.common.match.getMatchStatus(leaderboardData.status)
        : '';

    // Activate the player modal
    const activatePlayerModal = (event) => {
        if (
            event.keyCode &&
            event.keyCode !== PULSE.app.common.CONSTANTS.KEY_CODES.ENTER &&
            event.keyCode !== PULSE.app.common.CONSTANTS.KEY_CODES.SPACE
        ) {
            return;
        }

        dispatch(
            UPDATE_PLAYER_MODAL.request({
                visible: true,
                playerId: player.playerId,
                seasonPid: seasonPid,
                seasonId: nav.season.id,
                player
            })
        );

        PULSE.app.tracking.recordEvent(
            PULSE.app.common.CONSTANTS.TRACKING.EVENTS.MODAL.VIEW,
            {
                eventData: {
                    name: getTranslation(
                        'label.brownlow.tracker.leaderboard.full'
                    )
                }
            }
        );
    };

    return (
        <StatsTableRowHeader
            cellProps={cellProps}
            className="stats-table__row-header-cell"
        >
            <div className="stats-table__row-brownlow-player-cell">
                <FavouriteButton
                    player={player}
                    onPlayerFavouriteClick={onPlayerFavouriteClick}
                    favourites={favourites}
                />

                <PlayerPhoto
                    className={
                        'stats-leaders-table-player__headshot u-hide-until-desktop'
                    }
                    photoUrl={player?.photoURL}
                    scale={'0.2'}
                    alt={`${getTranslation('label.stats.headshot.alt')} ${
                        player.givenName ?? ''
                    } ${player.surname ?? ''}`}
                />

                <div>
                    <button
                        onClick={(event) => activatePlayerModal(event)}
                        onKeyDown={(event) => activatePlayerModal(event)}
                        className={`stats-table__row-brownlow-player-name ${
                            !player.eligible
                                ? 'stats-table__row-brownlow-player-name-ineligible'
                                : ''
                        }`}
                    >
                        <p>
                            {player.givenName} {player.surname}
                        </p>
                    </button>

                    {!player.eligible && (
                        <span className="stats-table__row-brownlow-ineligible">
                            {getTranslation(
                                'label.brownlow.predictor.ineligible'
                            )}
                        </span>
                    )}
                </div>

                {status &&
                    status ===
                        PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED &&
                    player.winner && (
                        <img
                            className="stats-table__player-cell-brownlow-badge"
                            src={`/resources/${window.RESOURCES_VERSION}/i/elements/brownlow-gold.svg`}
                            alt={`${getTranslation(
                                'label.stat.brownlowMedal'
                            )}`}
                        />
                    )}
            </div>
        </StatsTableRowHeader>
    );
};

PlayerCell.propTypes = {
    player: PropTypes.object.isRequired,
    onPlayerFavouriteClick: PropTypes.func,
    favourites: PropTypes.array,
    seasonPid: PropTypes.string
};
