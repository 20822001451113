/* eslint-disable id-length */
/* eslint-disable no-magic-numbers */
import { getValueWithinBounds } from '../../../utils/get-value-within-bounds';

const TOOLTIP_WIDTH = 300; // 30rem wide as defined in CSS.
const TOOLTIP_CENTRE = TOOLTIP_WIDTH / 2;
const PADDING = 15;

/**
 * Handle the horizontal positioning of the wrapper.
 *
 * NOTE: There may be a future need to adjust the primary tooltip and arrow
 * positioning within the wrapper. If so, the logic in
 * `positionOutsideTooltipElements()` will be able to help with this.
 *
 * 1. Calculate the desired x position of the tooltip wrapper to ensure it's
 *    within the viewport.
 * 2. If it's already fully within the viewport, there's nothing more to do.
 * 3. Add an inline style to adjust the position of the tooltip wrapper to
 *    ensure it's fully within the viewport.
 *
 * @param { HTMLElement } wrapper - The tooltip wrapper.
 */
export const positionTooltipElements = (wrapper) => {
    if (wrapper) {
        const { width, x } = wrapper.getBoundingClientRect();

        // [1]
        const desiredX = getValueWithinBounds(
            x,
            PADDING,
            window.innerWidth - width - PADDING
        );

        // [2]
        if (x === desiredX) {
            return;
        }

        // [3]
        wrapper.style.transform = `translateX(${
            desiredX - x - TOOLTIP_CENTRE
        }px)`;
    }
};
