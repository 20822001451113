import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../../setup-react';

// ===== Signals ============================================================ //

// Glossary
export const SETUP_GLOSSARY = createSignalAction('statspro', 'SETUP_GLOSSARY');

export const CHANGE_GLOSSARY_MODAL_VISIBILITY = createSignalAction(
    'statspro',
    'CHANGE_GLOSSARY_MODAL_VISIBILITY'
);

// ===== Deltas ============================================================= //

// Glossary
export const SET_GLOSSARY_TERMS = createDeltaActionType(
    'statspro',
    'SET_GLOSSARY_TERMS'
);
export const setGlossaryTerms = createActionCreator(SET_GLOSSARY_TERMS);

export const SET_GLOSSARY_MODAL_VISIBLE = createDeltaActionType(
    'statspro',
    'SET_GLOSSARY_MODAL_VISIBLE'
);
export const setGlossaryModalVisible = createActionCreator(
    SET_GLOSSARY_MODAL_VISIBLE
);
