import React from 'react';
import PropTypes from 'prop-types';

export const PosMarker = ({ pos = '', homeOrAway = 'home', labelKey = '' }) => {
    return (
        <p
            className={`team-announcements-field__postion-marker position-marker position-marker--${homeOrAway.toLowerCase()} position-marker--${homeOrAway.toLowerCase()}-${pos.toLowerCase()}-posmarker`}
        >
            <span className="team-announcements__position-marker-title">
                {PULSE.I18N.lookup(labelKey)}
            </span>
        </p>
    );
};

PosMarker.propTypes = {
    pos: PropTypes.string.isRequired,
    homeOrAway: PropTypes.oneOf(['home', 'away']).isRequired,
    labelKey: PropTypes.string.isRequired
};
