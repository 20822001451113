import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param props.children
 * @returns {JSX.Element} Component.
 */
export const StatsSectionNavItems = ({ children }) => {
    return (
        <>
            {React.Children.map(children, (child) => {
                if (child) {
                    return (
                        <div
                            className={`stats-leaders-nav__item-wrapper ${
                                child?.props?.classes ? child.props.classes : ''
                            }`}
                        >
                            {child}
                        </div>
                    );
                }
            })}
        </>
    );
};

StatsSectionNavItems.defaultProps = {
    name: 'StatsSectionNavItems'
};

StatsSectionNavItems.propTypes = {
    children: PropTypes.any.isRequired
};
