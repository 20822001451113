import React from 'react';
import PropTypes from 'prop-types';

import { getTranslation } from 'common/react/utils/translations';

export const FixturesDateHeader = ({ dateKey }) => {
    const label =
        dateKey === 'TBCPC'
            ? getTranslation('label.fixtures.tbc-postponed-cancelled')
            : moment(dateKey, 'YYYYMMDD')
                  // We don't need to adjust timezone here as it was already adjusted when grouping
                  .format('dddd MMMM D');

    return <h2 className="fixtures__date-header">{label}</h2>;
};

FixturesDateHeader.propTypes = {
    dateKey: PropTypes.string.isRequired
};
