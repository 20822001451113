import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { StatsLeadersTable as Table } from './components/StatsLeadersTable';
import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { Loader } from 'common/react/components/Loader';
import { statsLeadersNavSelector } from 'store/modules/statspro/stats-leaders/selectors';
import { composeRowDataForPlayer, composeColumnData } from './utils';
import { sortPlayersBySurname } from '../../../common/utils';
import { getTranslation } from 'common/react/utils/translations';
import { roundSelector } from 'store/modules/statspro/stats-leaders/selectors';

import { useGetStatsLeadersQuery } from 'store/modules/api/statsPro-api';
import FocusableTooltipArea from 'common/react/components/tooltip-button/focusable-tooltip/FocusableTootlipArea';

export const StatsLeadersTableFetcher = ({
    id,
    type,
    benchmarking,
    dataType,
    isAFLW,
    isAFLM
}) => {
    const nav = useSelector(statsLeadersNavSelector);

    const {
        data: players,
        isFetching,
        isError
    } = useGetStatsLeadersQuery({
        id,
        type,
        benchmarking
    });

    const round = useSelector(roundSelector);

    /**
     * Get column details.
     */
    const columns = useMemo(
        () => composeColumnData(nav.category, isAFLM, round),
        [nav.category, round]
    );

    /**
     * Get row data.
     */
    const data = useMemo(() => {
        if (!benchmarking && !playersHaveStandardData(players)) {
            return [];
        }

        if (benchmarking && !playersHaveBenchmarkingData(players)) {
            return [];
        }

        const dataColumns = columns.filter((col) => {
            return !['rank', 'player', 'position', 'team', 'opponent'].includes(
                col.accessor
            );
        });

        return [
            ...players
                .map((player) =>
                    composeRowDataForPlayer(player, dataColumns, dataType.type)
                )
                .sort(sortPlayersBySurname)
        ];
    }, [benchmarking, columns, dataType.type, players]);

    if ((isError || data.length === 0) && !isFetching) {
        return (
            <div style={{ padding: '3.2rem 1.6rem' }}>
                <StatsSection.ContentError
                    message={getTranslation(
                        'label.statspro.statsleaders.emptyTable'
                    )}
                />
            </div>
        );
    }

    if (isFetching || !data || !players) {
        return (
            <div style={{ padding: '3.2rem 1.6rem' }}>
                <Loader />
            </div>
        );
    }

    return (
        <FocusableTooltipArea>
            <Table
                columns={columns}
                data={data}
                players={players}
                modifier="stats-leaders"
                isAFLW={isAFLW}
                isAFLM={isAFLM}
            />
        </FocusableTooltipArea>
    );
};

const playersHaveStandardData = (players) =>
    players && players.length && players[0]?.stats?.totals;

const playersHaveBenchmarkingData = (players) =>
    players && players.length && players[0]?.stats?.benchmarkedTotals;

StatsLeadersTableFetcher.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    benchmarking: PropTypes.bool,
    dataType: PropTypes.object.isRequired,
    isAFLW: PropTypes.bool,
    isAFLM: PropTypes.bool
};
