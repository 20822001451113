import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { filtersSelector } from 'common/store/modules/events-listing/selectors';
import { SelectMultiple } from 'common/react/components/select-multiple';
import { getTranslation } from 'common/react/utils/translations';
import { UPDATE_FILTER_ITEM } from 'common/store/modules/events-listing/actions';

export const EventLocationFilter = ({ tags, addOnResetCallback }) => {
    const dispatch = useDispatch();
    const filters = useSelector(filtersSelector);

    const updateFilterItem = (filterItem, value) => {
        dispatch(UPDATE_FILTER_ITEM.request({ filterItem, value }));
    };

    return (
        <SelectMultiple
            getInitialSelectedItems={() => {
                return tags.filter((t) => filters.eventTypes.includes(t.value));
            }}
            items={tags}
            label={getTranslation('label.filters.eventLocation')}
            itemToString={(tag) => tag.name}
            onSelectedItemsChange={(tag) => {
                updateFilterItem(
                    'eventLocations',
                    tag.map((t) => t.value)
                );
            }}
            addOnResetCallback={addOnResetCallback}
            getItemText={(tag) => tag.name}
            getItemLabel={(tag) => (
                <span
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        lineHeight: 1,
                        width: '100%'
                    }}
                >
                    <span>{tag.name}</span>
                </span>
            )}
            icon="location"
        />
    );
};

EventLocationFilter.propTypes = {
    tags: PropTypes.array,
    addOnResetCallback: PropTypes.func
};
