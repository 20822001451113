import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { KeyStatsAndPerformance } from '../components/key-stats-and-performance';

/**
 * @param {any} props - Props.
 * @returns {JSX.Element} Component.
 */
export const KeyStatsAndPerformanceProvider = ({ store, data }) => (
    <Provider store={store}>
        <KeyStatsAndPerformance {...data} />
    </Provider>
);

KeyStatsAndPerformanceProvider.propTypes = {
    store: PropTypes.object,
    data: PropTypes.object
};
