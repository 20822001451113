import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { ClubNews } from '../components/ClubNews';

export const ClubNewsProvider = ({ store, data }) => {
    return (
        <Provider store={store}>
            <ClubNews {...data} />
        </Provider>
    );
};

export default ClubNewsProvider;

ClubNewsProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
