/**
 * @param {any} draft - Draft state.
 * @param {any} payload - Action payload.
 */
export const setBettingOdds = (draft, payload) => {
    draft.bettingOdds = {
        ...draft.bettingOdds,
        ...payload
    };
};
