import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'common/react/components/select';
import { getTranslation } from 'common/react/utils/translations';
import { playerStatsNavSelector } from 'store/modules/match-centre/player-stats/selectors';
import { UPDATE_NAV_ITEM } from 'store/modules/match-centre/player-stats/actions';
import { STATS_CATEGORY } from '../table/config/categories';

export const StatsNavItem = () => {
    const dispatch = useDispatch();
    const nav = useSelector(playerStatsNavSelector);

    return (
        <Select
            items={Object.keys(STATS_CATEGORY).map(
                (key) => STATS_CATEGORY[key]
            )}
            initialSelectedItem={nav.stats}
            activeItem={nav.stats}
            label={getTranslation('label.stats')}
            onSelectedItemChange={({ selectedItem }) => {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'stats',
                        value: selectedItem
                    })
                );
            }}
        />
    );
};
