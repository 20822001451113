import React from 'react';
import PropTypes from 'prop-types';

import { LazyImage } from 'common/react/components/LazyImage';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

export const FixtureArticleThumbnail = ({ match, article }) => {
    const previewLabel = PULSE.app.common.url.urlify(
        getTranslation('label.mc.previewTab')
    );
    const articleLink = PULSE.app.common.url.getMatchURL(match, previewLabel);

    return (
        <article className={`media-thumbnail`}>
            <div className="media-thumbnail__link">
                <a
                    className={`media-thumbnail__absolute-link`}
                    href={articleLink}
                >
                    <span className="u-screen-reader">{article.title}</span>
                </a>
                <header
                    className={`media-thumbnail__image-container placeholder placeholder--small placeholder--dark`}
                >
                    <LazyImage
                        image={article.leadMedia}
                        mediaQueryConfig={article.mediaQueryConfig}
                    />

                    <div className={`media-thumbnail__video-duration`}>
                        <SvgIcon
                            icon={'news'}
                            className={`icon media-thumbnail__duration-icon`}
                        />
                    </div>
                </header>
            </div>
        </article>
    );
};

FixtureArticleThumbnail.propTypes = {
    match: PropTypes.object.isRequired,
    article: PropTypes.object.isRequired
};
