export const getCurrentDateBounds = () => {
    const currentTime = new Date(Date.now());
    const nextYearDate = new Date(
        new Date(currentTime).setFullYear(currentTime.getFullYear() + 1)
    );

    return {
        lowerBound: currentTime.toISOString(),
        upperBound: nextYearDate.toISOString()
    };
};
