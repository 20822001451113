import React from 'react';
import PropTypes from 'prop-types';

export const MobileTableAccordionContent = ({
    cssClass,
    isActive,
    children
}) => {
    return (
        <div
            className={`${cssClass}__body-row-bottom ${
                isActive ? cssClass + '__body-row-bottom--active' : ''
            }`}
        >
            {children}
        </div>
    );
};

MobileTableAccordionContent.propTypes = {
    cssClass: PropTypes.string,
    isActive: PropTypes.bool,
    children: PropTypes.node
};
