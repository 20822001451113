import './types';

/**
 * @param {string} type - The action type.
 * @returns {ActionCreator} An action creator.
 */
export const createActionCreator = (type) => {
    return (payload) => ({ type, payload });
};

/**
 * @param {string} reducerName - The name of the reducer.
 * @param {string} base - The name/identifier of the Signal action.
 * @returns {SignalActionObject} A Signal action object.
 */
export const createSignalAction = (reducerName, base) => ({
    REQUEST: `SIGNAL/${reducerName}/${base}/REQUEST`,
    SUCCESS: `SIGNAL/${reducerName}/${base}/SUCCESS`,
    FAILURE: `SIGNAL/${reducerName}/${base}/FAILURE`,
    request: createActionCreator(`SIGNAL/${reducerName}/${base}/REQUEST`),
    success: createActionCreator(`SIGNAL/${reducerName}/${base}/SUCCESS`),
    failure: createActionCreator(`SIGNAL/${reducerName}/${base}/FAILURE`)
});

/**
 * @param {string} reducerName - The name of the reducer.
 * @param {string} base - The name/identifier of the Delta action.
 * @returns {string} The Delta action type.
 */
export const createDeltaActionType = (reducerName, base) =>
    `DELTA/${reducerName}/${base}`;
