import { useState, useEffect } from 'react';

import { useGetMatchItemQuery } from '../cfs-api';

const { common } = PULSE.app;
const { CONSTANTS } = common;

/**
 * A wrapper for useGetMatchItemQuery that fetches a match and if it needs to
 * starts it polling
 *
 * @param {string} providerId - The providerId of the match you want to fetch
 * @param {object} options - Query options to pass to RTK see UseQueryOptions
 * @returns {object} - The output from the useGetMatchItemQuery hook see UseQueryResult
 */
export function useAutoPollingMatchItem(providerId, options) {
    const [pollingInterval, setPollingInterval] = useState(0);

    // Setup the RTK hook and store what it returns
    const hookOutput = useGetMatchItemQuery(providerId, {
        ...options,
        pollingInterval
    });

    useEffect(() => {
        const status = hookOutput?.data?.match?.status;
        const utcStartTime = hookOutput?.data?.match?.utcStartTime;

        // If we have a response with a status, let's look if we want to poll
        if (status && utcStartTime) {
            const shouldMatchPoll = common.match.shouldMatchPoll(
                status,
                utcStartTime
            );

            if (shouldMatchPoll && pollingInterval === 0) {
                setPollingInterval(CONSTANTS.MATCH_POLL_DELAY);
            } else if (!shouldMatchPoll && pollingInterval !== 0) {
                setPollingInterval(0);
            }
        }
    }, [
        hookOutput?.data?.match?.status,
        hookOutput?.data?.match?.utcStartTime,
        pollingInterval
    ]);

    return hookOutput;
}
