import { useDispatch } from 'react-redux';

import { UPDATE_NAV_ITEM } from 'common/store/modules/brownlow-tracker/leaderboard/actions';
const TEAMS_RESET_VALUE = -1;

/**
 *
 */
export function useResetTeamFilter() {
    const dispatch = useDispatch();

    dispatch(
        UPDATE_NAV_ITEM.request({
            navItem: 'team',
            value: {
                id: Number(TEAMS_RESET_VALUE),
                providerId: ''
            }
        })
    );
}
