import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CarouselButton from './CarouselButton';
import { MatchContainer } from './MatchContainer';
import { getTranslation } from 'common/react/utils/translations';

/**
 *
 * @param root0
 * @param root0.data
 */
export default function FixtureBar({ data }) {
    const [isLoading, setIsLoading] = useState(true);
    const [matches, setMatches] = useState([]);
    const [isError, setIsError] = useState(false);
    const [prevNextDisabled, setPrevNextDisabled] = useState(false);
    const [staticButton, setStaticButton] = useState(false);

    const roundText = data.roundName.startsWith('Week')
        ? PULSE.I18N.lookup('label.fixtureBar.weekTitle')
        : PULSE.I18N.lookup('label.fixtureBar.roundTitle');
    const container = document.querySelector('.js-react-fixture-bar-provider');
    const pageSize = 50;
    const isFinals = data.isFinalsMode === 'true';
    const roundNumber = data.overrideRoundNumber
        ? data.overrideRoundNumber
        : data.roundNumber;

    const setMatchData = function setMatchData(matchesDataRes) {
        if (matchesDataRes.matches && matchesDataRes.matches.length) {
            setIsLoading(false);
            setMatches(matchesDataRes.matches);
        } else {
            setIsLoading(false);
            setIsError(true);
        }
    };

    const checkSliderWidth = () => {
        // checks the total width of the slides to fix the all matches button
        // to the right side of the container
        const wrapper = container.querySelector('.swiper-wrapper');
        const slides = container.querySelectorAll('.swiper-slide');

        if (wrapper && slides.length) {
            let totalWidth = 0;

            slides.forEach((slide) => {
                totalWidth = totalWidth + slide.offsetWidth;
            });

            if (totalWidth < wrapper.offsetWidth) {
                setStaticButton(true);
            } else {
                setStaticButton(false);
            }
        }
    };

    useEffect(() => {
        //disable carousel next and prev buttons if tabbing with keyboard
        container.addEventListener('keydown', () => {
            container.contains(document.activeElement)
                ? setPrevNextDisabled(true)
                : setPrevNextDisabled(false);
        });

        const params = {
            competitionId: data.comp,
            compSeasonId: data.compseason,
            pageSize: pageSize
        };

        let url = `${
            PULSE.app.environment.api
        }matches${PULSE.app.common.prepareParams(params)}`;

        // Handle a comma seperated list of round numbers and add a param for each one
        const roundNumberArr = roundNumber
            .replace(' ', '')
            .split(',')
            .filter((item) => item !== '');
        roundNumberArr.forEach((round) => {
            url += `&roundNumber=${round}`;
        });

        const config = {
            url,
            method: 'GET',
            callback: setMatchData.bind(this),
            target: this
        };

        PULSE.core.data.request(config.url, config.method, config.callback);

        // check slider width for the all matches button
        if (isFinals) {
            const observerConfig = {
                attributes: true,
                childList: true,
                subtree: true
            };
            const observer = new MutationObserver(checkSliderWidth);

            observer.observe(container, observerConfig);
        }
    }, []);

    return (
        <div className="fixture-bar__container">
            {!isError && (
                <CarouselButton
                    isNext={false}
                    isFinals={isFinals}
                    isDisabled={prevNextDisabled}
                />
            )}
            {!isFinals && (
                <div className="fixture-bar__round">
                    {!roundNumber.includes(',') && (
                        <>
                            <div className="fixture-bar__round-name">
                                {roundText}
                            </div>
                            <div className="fixture-bar__round-number">
                                {roundNumber === '0'
                                    ? getTranslation('label.rounds.OR')
                                    : roundNumber}
                            </div>
                        </>
                    )}
                </div>
            )}
            {isLoading && <div className="fixture-bar__is-loading"></div>}
            {!isError && !isLoading && matches.length && (
                <MatchContainer
                    matches={matches}
                    isFinals={isFinals}
                    allMatchesLink={data.allMatchesLink}
                    isStaticButton={staticButton}
                />
            )}
            {isError && (
                <div className="fixture-bar__is-error">
                    {PULSE.I18N.lookup('label.fixtureBar.noMatchesMessage')}
                </div>
            )}
            {!isError && (
                <CarouselButton
                    isNext={true}
                    isFinals={isFinals}
                    isDisabled={prevNextDisabled}
                />
            )}
        </div>
    );
}

FixtureBar.propTypes = {
    data: PropTypes.object.isRequired
};
