import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { LiveTrackerSlider } from './live-tracker-slider';

export const LiveTrackerEmbeddable = ({
    title,
    subtitle,
    linkTo,
    linkToLabel,
    seasonPid,
    seasonId,
    anchorName
}) => {
    const isWebView =
        PULSE.core.url.getParam('webview') === 'true'
            ? true
            : false || window.IS_ARTICLE_API === true;

    return (
        <div
            className={
                isWebView ? 'brownlow-embeddable--static-dimensions' : ''
            }
        >
            <div className="brownlow-embeddable__header">
                <div className="brownlow-embeddable__logo"></div>
                <div className="brownlow-embeddable__header-text-container">
                    <h3 className="brownlow-embeddable__title">
                        {title ?? ''}
                    </h3>
                    <p className="brownlow-embeddable__subtitle">
                        {subtitle ?? ''}
                    </p>
                </div>
            </div>
            <div className="brownlow-match-by-match">
                <LiveTrackerSlider
                    seasonPid={seasonPid}
                    seasonId={seasonId}
                    embeddable="true"
                    linkTo={linkTo}
                    anchorName={anchorName}
                />
            </div>
            {linkTo && linkToLabel && (
                <div className="brownlow-embeddable__footer">
                    <a
                        href={linkTo}
                        className="brownlow-embeddable__footer-link"
                    >
                        {linkToLabel}
                        <SvgIcon icon="arrow-right" className="icon" />
                    </a>
                </div>
            )}
        </div>
    );
};

LiveTrackerEmbeddable.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    linkTo: PropTypes.string,
    linkToLabel: PropTypes.string,
    seasonPid: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    anchorName: PropTypes.string
};
