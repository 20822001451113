import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { ToggleSearch } from './components/ToggleSearch';
import { PlayerSearchInput } from 'common/react/components/PlayerSearchInput';
import { SearchResultContainer } from './components/SearchResultContainer';

import { useGetSearchResultsQuery } from 'store/modules/api/statsPro-api';

export const Search = ({ compseason }) => {
    const [query, setQuery] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState(query);
    const [focused, setFocused] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const { data: resultsFetch, isFetching } = useGetSearchResultsQuery(
        { compseason, query },
        { skip: !query }
    );

    const results = resultsFetch?.players ? resultsFetch?.players : [];

    const toggleActive = () => {
        if (isActive) {
            setIsActive(false);
            setFocused(false);
        } else {
            setIsActive(true);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => setQuery(debouncedQuery.trim()), 1000);
        return () => clearTimeout(timer);
    }, [debouncedQuery]);

    const useOutsideChecker = (ref) => {
        useEffect(() => {
            /**
             *
             * @param event
             */
            function handleClickOutside(event) {
                if (
                    (event.type === 'keyup' && event.keyCode === 9) ||
                    event.type === 'mousedown'
                ) {
                    if (ref.current && !ref.current.contains(event.target)) {
                        setFocused(false);
                    } else {
                        setFocused(true);
                    }
                }
            }
            // Bind the event listeners
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keyup', handleClickOutside);
            return () => {
                // Unbind the event listeners on clean up
                document.removeEventListener('mousedown', handleClickOutside);
                document.removeEventListener('keyup', handleClickOutside);
            };
        }, [ref]);
    };

    const searchRef = useRef(null);
    useOutsideChecker(searchRef);

    return (
        <div className="stats-search" ref={searchRef}>
            {/* Mobile display */}
            <ToggleSearch onClick={toggleActive} />

            <PlayerSearchInput
                setDebouncedQuery={setDebouncedQuery}
                isActive={isActive}
                onClick={toggleActive}
            />

            {query.length > 2 && (
                <SearchResultContainer
                    isLoading={isFetching}
                    focused={focused}
                    query={query}
                    results={results}
                    compseason={compseason}
                />
            )}
        </div>
    );
};

Search.propTypes = {
    compseason: PropTypes.string.isRequired
};
