import React from 'react';

import { useGetContentItemQuery } from 'common/store/modules/api/content-api';
import { LazyImage } from 'common/react/components/LazyImage';

export const MapSponsor = () => {
    const eventSponsor = useGetContentItemQuery({
        type: PULSE.venueSponsor?.contentReference?.contentType,
        id: PULSE.venueSponsor?.contentReference?.reference
    });
    const sponsorLogoUrl = `${eventSponsor.data?.promoItem?.onDemandUrl}?width=100`;

    return (
        <a
            href={`${eventSponsor.data?.promoUrl}`}
            target="_blank"
            rel="noreferrer"
        >
            <LazyImage
                url={sponsorLogoUrl}
                useImg={true}
                objectFit={false}
                cssClass="interactive-event-map__sponsor-logo-image"
                title={`${eventSponsor.data?.linkText}`}
            />
        </a>
    );
};
