/**
 * Columns definition for the Round By Round votes table
 *
 * @returns {Array} Columns list
 */
export const composeColumnData = () => {
    let columns = [
        {
            Header: 'Rd',
            accessor: 'roundNumber'
        },
        {
            Header: 'Opponent',
            accessor: 'opponent'
        },
        {
            Header: 'Predicted',
            accessor: 'predictedPoints'
        },
        {
            Header: 'Votes',
            accessor: 'votes'
        }
    ];

    return columns;
};
