import {
    STATS_COLUMNS,
    STATS_COLUMN_IDS
} from 'widgets/statspro/js/components/common/config/columns';
import { STATS_CATEGORY } from './categories';

export const FIRST_SORTABLE_COLUMN_INDEX = 0;

/**
 * @typedef {object} Stat
 * @property {string} id
 * @property {string} name
 * @property {string} abbreviation
 */

export const STATS_COLUMNS_FOR_CATEGORY = {
    [STATS_CATEGORY.BASIC]: [
        STATS_COLUMNS[STATS_COLUMN_IDS.AFL_FANTASY_POINTS],
        STATS_COLUMNS[STATS_COLUMN_IDS.GOALS],
        STATS_COLUMNS[STATS_COLUMN_IDS.BEHINDS],
        STATS_COLUMNS[STATS_COLUMN_IDS.DISPOSALS],
        STATS_COLUMNS[STATS_COLUMN_IDS.KICKS],
        STATS_COLUMNS[STATS_COLUMN_IDS.HANDBALLS],
        STATS_COLUMNS[STATS_COLUMN_IDS.MARKS],
        STATS_COLUMNS[STATS_COLUMN_IDS.TACKLES],
        STATS_COLUMNS[STATS_COLUMN_IDS.HIT_OUTS],
        STATS_COLUMNS[STATS_COLUMN_IDS.CLEARANCES],
        STATS_COLUMNS[STATS_COLUMN_IDS.METRES_GAINED],
        STATS_COLUMNS[STATS_COLUMN_IDS.GOAL_ASSIST],
        STATS_COLUMNS[STATS_COLUMN_IDS.TIME_ON_GROUND]
    ],
    [STATS_CATEGORY.ADVANCED]: [
        STATS_COLUMNS[STATS_COLUMN_IDS.AFL_FANTASY_POINTS],
        STATS_COLUMNS[STATS_COLUMN_IDS.CONTESTED_MARKS],
        STATS_COLUMNS[STATS_COLUMN_IDS.INSIDE_50S],
        STATS_COLUMNS[STATS_COLUMN_IDS.FREES_FOR],
        STATS_COLUMNS[STATS_COLUMN_IDS.FREES_AGAINST],
        STATS_COLUMNS[STATS_COLUMN_IDS.CENTRE_CLEARANCES],
        STATS_COLUMNS[STATS_COLUMN_IDS.SCORE_INVOLVEMENTS],
        STATS_COLUMNS[STATS_COLUMN_IDS.INTERCEPT_POSSESSIONS],
        STATS_COLUMNS[STATS_COLUMN_IDS.TACKLES_INSIDE_50],
        STATS_COLUMNS[STATS_COLUMN_IDS.DISPOSAL_EFFICIENCY],
        STATS_COLUMNS[STATS_COLUMN_IDS.CONTESTED_POSSESSIONS],
        STATS_COLUMNS[STATS_COLUMN_IDS.UNCONTESTED_POSSESSIONS]
    ],
    [STATS_CATEGORY.MY_STATS]: []
};

Object.freeze(STATS_COLUMNS_FOR_CATEGORY);
