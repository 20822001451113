export const filterEvents = (events, filters) => {
    return events.filter((event) => {
        const eventTagLabels = event.tags.map((tag) => tag.label);
        const eventReferences = event.references.map((reference) =>
            parseInt(reference.reference)
        );

        let filteredEventTypes =
            filters.eventTypes.length === 0 ||
            filters.eventTypes.some((filterType) => {
                return eventTagLabels.includes(filterType);
            });

        let filteredEventLocations =
            filters.eventLocations.length === 0 ||
            filters.eventLocations.some((filterLocation) => {
                return eventTagLabels.includes(filterLocation);
            });

        let filteredTeams =
            filters.teams.length === 0 ||
            filters.teams.some((filterTeam) => {
                return eventReferences.includes(parseInt(filterTeam));
            });

        return filteredEventTypes && filteredEventLocations && filteredTeams;
    });
};
