import React from 'react';
import PropTypes from 'prop-types';

export const FixtureVenue = ({ venue }) => {
    if (venue.name === 'TBC') {
        return <></>;
    }

    return (
        <div className="fixtures__match-venue">
            {venue.name}, {venue.location}{' '}
            {venue.landOwner ? `\u2022 ${venue.landOwner}` : ''}
        </div>
    );
};

FixtureVenue.propTypes = {
    venue: PropTypes.object.isRequired
};
