/**
 * @param {any} props.condition - the condition.
 * @param {Function} props.wrapper - the wrapper component.
 * @param {ReactElement} props.children - the child components
 * @returns {JSX.Element} Component.
 *
 * @info used to conditionally wrap an element(s) based on a condition.
 *
 * @example
 * <ConditionalWrapper
 *      condition={true}
 *      wrapper={(children) => (
 *          <Wrapper>
 *              {children}
 *          </Wrapper>
 *      )}
 * >
 *      <Child />
 * </ConditionalWrapper>
 */

export const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;
