import { all, takeEvery } from 'redux-saga/effects';

import { UPDATE_ITEM } from '../actions';
import { updateItem } from './update-item';

/**
 *
 */
export function* viewByMatchRootSaga() {
    yield all([takeEvery(UPDATE_ITEM.REQUEST, updateItem)]);
}
